import { useState } from 'react';
import { Alert, Box, Button, Text } from '@mantine/core';
import { useStyles } from '../styles';
import { useCreateDepositAccount } from '@queries/use-deposit-accounts';
import { usePlaidBankingComponent } from '@utilities/custom-hooks/plaid-banking-component';
import { PiXCircleBold } from 'react-icons/pi';

type Props = {
  onCreateAccount: () => Promise<void>;
  onLinkSuccess: () => Promise<void>;
  onLinkError: () => void;
};

const LinkAccountStep = ({
  onLinkSuccess,
  onLinkError,
  onCreateAccount,
}: Props) => {
  const { classes } = useStyles();
  const { mutate, isPending } = useCreateDepositAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const { open } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => onLinkError(),
    setLoading: (loading) => setIsLoading(loading),
  });

  const addDepositAccount = () => {
    setErrorMessage(null);
    mutate(
      {
        isDefault: false,
        nickName: undefined,
      },
      {
        onSuccess: () => onCreateAccount(),
        onError: () =>
          setErrorMessage(
            'Something went wrong when creating your account. Try again.',
          ),
      },
    );
  };

  return (
    <Box c="primary.8" w={480} className={classes.card}>
      <Text fw={500} fz={28}>
        Transfer between accounts
      </Text>
      <Text mt="sm">
        To make transfers between accounts, ensure you have at least one deposit
        account or at least one linked external account.
      </Text>

      <Box className={classes.card} my="md">
        <Text fw={500} c="primary.8">
          Open a new Flex bank account
        </Text>
        <Text c="neutral.7" size="sm">
          Your account will be immediately created and ready for use.
        </Text>
        <Button
          variant="primary-light"
          mt="md"
          loading={isPending}
          onClick={addDepositAccount}
        >
          Open account
        </Button>
        {errorMessage && (
          <Alert
            mt="md"
            color="red"
            icon={<PiXCircleBold size={'1.25rem'} />}
            withCloseButton={false}
          >
            {errorMessage}
          </Alert>
        )}
      </Box>

      <Box className={classes.card}>
        <Text fw={500} c="primary.8">
          Link an external account
        </Text>
        <Text c="neutral.7" size="sm">
          Securely connect your business bank account to move money.
        </Text>
        <Button
          variant="primary-light"
          mt="md"
          onClick={() => open()}
          loading={isLoading}
        >
          Connect a bank account
        </Button>

        <Text mt="sm" c="neutral.6" size="xs">
          Flex uses Plaid Inc. (“Plaid”) to gather your data from financial
          institutions for bank account and fraud verification. By using the
          site, you grant Flex and Plaid the right, power, and authority to act
          on your behalf to access and transmit your personal and financial
          information from your relevant financial institution. You agree to
          your personal and financial information being transferred, stored, and
          processed by Plaid in accordance with the Plaid end user privacy
          policy.
        </Text>
      </Box>
    </Box>
  );
};

export default LinkAccountStep;
