import OnboardingStep from '../components/onboarding-step';
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  Group,
  Image,
  Stack,
  Text,
  UnstyledButton,
  rem,
} from '@mantine/core';
import { PiCalculatorBold, PiLinkBreakBold } from 'react-icons/pi';
import {
  useCreateRutterConnection,
  useIntegrationLinks,
} from '@utilities/integrations';
import { AccountingPlatforms } from 'constants/accounting-integrations';
import { getIntegrationCards } from '@utilities/integrations/list-integrations';
import { useDisconnectIntegration } from '@utilities/integrations/use-disconnect-integration';
import QuickbooksGreen from 'assets/images/quickbooks.png';
import Netsuite from 'assets/images/oracle-netsuite.png';
import Xero from 'assets/images/xero.png';
import { useApplicationFlowContext } from '../onboarding-hooks';
import { createStyles } from '@mantine/emotion';
import { ApplicationEvents } from '@services/analytics/events';
import { Analytics } from '@services/analytics/analytics';

const INTEGRATIONS = getIntegrationCards();

const useStyles = createStyles((theme) => ({
  iconContainer: {
    borderRadius: 50,
    height: rem(28),
    width: rem(28),
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.primary[0],
  },
  accountContainer: {
    borderRadius: 8,
    border: `1px solid ${theme.colors.neutral[4]}`,
  },
}));

const ConnectAccountingTool = () => {
  const { classes, theme } = useStyles();
  const [error, setError] = useState('');
  const { goBack, navigateToNextProductStep, refreshProductOnboardingStatus } =
    useApplicationFlowContext();

  const { openRutterLink, isExchangingToken, isRutterConnecting } =
    useCreateRutterConnection();
  const { accountingLinks, isPending } = useIntegrationLinks();
  const { disconnectIntegration, isPending: isDisconnecting } =
    useDisconnectIntegration();

  const isLoading =
    isPending || isExchangingToken || isRutterConnecting || isDisconnecting;

  const mappedAccountingLinks = useMemo(() => {
    return accountingLinks.map((link) => {
      const integration = INTEGRATIONS.find(
        (i) => i.platform === link.platform,
      );

      return {
        ...link,
        ...integration,
      };
    });
  }, [accountingLinks]);

  useEffect(() => {
    if (accountingLinks.length) {
      refreshProductOnboardingStatus();
    }
  }, [accountingLinks.length]);

  const handleAccountingToolClick = (platform: AccountingPlatforms) => {
    setError('');
    openRutterLink(platform);
    Analytics.track(ApplicationEvents.ACCOUNTING_TOOL_CONNECT_CLICKED, {
      platform,
    });
  };

  const handleDisconnectClick = (
    platform: (typeof mappedAccountingLinks)[number],
  ) => {
    setError('');
    disconnectIntegration({
      platformLabel: platform.title || '',
      connectionId: platform.connectionId,
    });
    Analytics.track(ApplicationEvents.ACCOUNTING_TOOL_DISCONNECT_CLICKED, {
      platform,
    });
  };

  const handleNextClick = async () => {
    Analytics.track(
      mappedAccountingLinks.length
        ? ApplicationEvents.ACCOUNTING_VERIFICATION_CONTINUE_CLICKED
        : ApplicationEvents.ACCOUNTING_VERIFICATION_SKIP_CLICKED,
    );
    navigateToNextProductStep();
  };

  const onBack = () => {
    goBack();
  };

  return (
    <OnboardingStep
      title="Connect Accounting Tool"
      stepId="connect-accounting-tool"
      subtitle="Connect your accounting software to help us better understand your business's financial health and potentially increase your credit limit."
      showContinueSpinner={isLoading}
      error={error}
      onNextClick={handleNextClick}
      onBackClick={onBack}
      nextLabel={mappedAccountingLinks.length ? 'Continue' : 'Skip'}
    >
      <Stack px={{ base: 'xxxl', sm: 0 }}>
        <Stack
          bg="white"
          p="md"
          pb="xl"
          bd={`1px solid ${theme.colors.neutral[4]}`}
          gap="lg"
        >
          <Group gap="xs" align="center">
            <Flex className={classes.iconContainer}>
              <PiCalculatorBold
                color={theme.colors.primary[6]}
                height={16}
                width={16}
              />
            </Flex>
            <Stack flex={1} gap={rem(2)}>
              <Text lh="normal" fw="500">
                Bookkeeping
              </Text>
              <Text lh="normal" fz="sm" c={theme.colors.neutral[8]}>
                This gives us a fuller picture of your company&apos;s financial
                history.
              </Text>
            </Stack>
          </Group>
          <Stack gap="sm" pl="xxxl">
            {mappedAccountingLinks.map((accountingLink) => (
              <UnstyledButton
                key={accountingLink.id}
                onClick={() => handleDisconnectClick(accountingLink)}
              >
                <Group
                  className={classes.accountContainer}
                  p="xs"
                  align="center"
                >
                  <Group gap={rem(2)} flex={1}>
                    <Text size="sm">{accountingLink.title}</Text>
                  </Group>
                  <Flex justify="center" align="center">
                    <PiLinkBreakBold />
                  </Flex>
                </Group>
              </UnstyledButton>
            ))}
            {!mappedAccountingLinks.length && (
              <Group>
                <Button
                  loading={isLoading}
                  onClick={() => handleAccountingToolClick('QUICKBOOKS')}
                  variant="primary-outline"
                  leftSection={
                    <Image
                      src={QuickbooksGreen}
                      height={20}
                      width={20}
                      radius={200}
                    />
                  }
                >
                  QuickBooks Online
                </Button>
                <Button
                  loading={isLoading}
                  onClick={() => handleAccountingToolClick('NETSUITE')}
                  variant="primary-outline"
                  leftSection={
                    <Image src={Netsuite} height={20} width={20} radius={200} />
                  }
                >
                  NetSuite
                </Button>
                <Button
                  loading={isLoading}
                  onClick={() => handleAccountingToolClick('XERO')}
                  variant="primary-outline"
                  leftSection={
                    <Image src={Xero} height={20} width={20} radius={200} />
                  }
                >
                  Xero
                </Button>
              </Group>
            )}
          </Stack>
        </Stack>
      </Stack>
    </OnboardingStep>
  );
};

export default ConnectAccountingTool;
