import { useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import {
  useMantineTheme,
  Text,
  Button,
  Box,
  rem,
  TextInput,
} from '@mantine/core';
import { FlexbaseTable } from '@common/table';
import useModal from '@common/composites/modal/modal-hook';
import BadgeStatus from '../../components/badge-status';
import { getMonthDayYear } from '@utilities/dates';
import GenericError from '@common/generic-error';
import TabTableLayout from '@common/layouts/tab-table-layout';
import { useMatchMedia } from '@utilities/url/window-helpers';
import BillpayPaymentDetails from './bill-pay-payment-details';
import { useCreditPayments } from '@queries/use-credit-payments';
import { MoneyMovement } from '@services/flexbase/banking.model';
import TableEmptyState from '../../components/table-empty-state';
import { formatApiStrings } from '@utilities/formatters/format-api-strings';
import { PiMagnifyingGlass } from 'react-icons/pi';

const BillpayCreditPaymentsTable = () => {
  const theme = useMantineTheme();
  const [searchValue, setSearchValue] = useState('');
  const { openRightModal } = useModal();
  const useMobileView = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);
  const { data, isLoading, isError, refetch } = useCreditPayments('billpay');
  const payments = data?.payments ?? [];

  const handleRowClick = (row: MoneyMovement) => {
    openRightModal(<BillpayPaymentDetails payment={row} />);
  };

  const columns: TableColumn<MoneyMovement>[] = [
    {
      id: 'createdAt',
      name: 'Date created',
      selector: (row) => row.createdAt,
      format: (row) => getMonthDayYear(row.createdAt),
      sortable: true,
    },
    {
      name: 'Date posted',
      selector: (row) => row.asOf,
      format: (row) => getMonthDayYear(row.asOf),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.payAmount,
      sortable: true,
    },
    {
      name: 'Payment type',
      selector: (row) => formatApiStrings(row.type),
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => formatApiStrings(row.status),
      cell: (row) => <BadgeStatus status={row.status} />,
      sortable: true,
    },
  ];

  const filteredData =
    payments.filter((payment) => {
      if (searchValue === '') {
        return payment;
      } else {
        const datePosted = getMonthDayYear(payment.asOf);
        const dateCreated = getMonthDayYear(payment.createdAt);
        const paymentAmount = Number(payment.payAmountCents) / 100;
        return (
          datePosted.toLowerCase().includes(searchValue) ||
          dateCreated.toLowerCase().includes(searchValue) ||
          payment.type.toLowerCase().includes(searchValue) ||
          payment.status.toLowerCase().includes(searchValue) ||
          paymentAmount.toString().includes(searchValue)
        );
      }
    }) ?? [];

  if (isError) {
    return (
      <Box bg="neutral.0" p="md" sx={{ borderRadius: theme.defaultRadius }}>
        <GenericError>
          <Text>An error occurred while fetching the bill payments list.</Text>
          <Button variant="primary-light" onClick={() => refetch()} mt={20}>
            Reload data
          </Button>
        </GenericError>
      </Box>
    );
  }

  return (
    <TabTableLayout
      leftHeaderContent={undefined}
      borderBottomSolid={false}
      rightHeaderContent={
        <TextInput
          placeholder="Search payments"
          w={useMobileView ? '100%' : rem(320)}
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              color={theme.colors.neutral[6]}
            />
          }
          onChange={(e) => setSearchValue(e.target.value)}
        />
      }
      table={
        <FlexbaseTable
          data={filteredData}
          columns={columns}
          defaultSortAsc={false}
          isFetchingData={isLoading}
          onRowClicked={handleRowClick}
          defaultSortFieldId="createdAt"
          pagination={filteredData && filteredData.length > 10}
          noDataComponent={
            <TableEmptyState message="There are no payments to display" />
          }
        />
      }
    />
  );
};

export default BillpayCreditPaymentsTable;
