import { baseTableStyles } from '@common/styles';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import {
  Avatar,
  Box,
  LoadingOverlay,
  Menu,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  useDeleteUser,
  useGetUsers,
  useListPlatformInvites,
  useResendPlatformInviteMutation,
  useUpdatePerson,
  useUpdateUserStatus,
} from '@queries/use-users';
import { UserIdState } from 'recoil-state/application/onboarding-form.state';
import FlexDefaultHeader from '@common/composites/header/flex-default-header';
import useModal from '@common/composites/modal/modal-hook';
import { FlexbaseTable } from 'components/table';
import { ReactNode, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAdmin,
  IsFullAdmin,
} from 'recoil-state/application/product-onboarding';
import { Employee } from 'types/employee';
import { roleDescriptions } from 'constants/index';
import { getInitialsOfNames } from '@utilities/object';
import RoleDetails from './role-details/role-details';
import TeamMembersHeader from './team-members-header/team-members-header';
import { customMantineStyles, useStyles } from './team-members.styles';
import { without } from 'underscore';
import { PlatformInvite } from '@services/platform/models/identity.model';
import { StatusTag } from './status-tag';
import { PiCaretDown, PiDotsThreeVertical } from 'react-icons/pi';
import { UserRole } from 'types/user-info';

type RoleProps = {
  id: string;
  value: Role;
  label: string | ReactNode;
};

/**
 * Ensures that at least one full admin remains in the system.
 * Returns true if the operation can proceed, false if it would remove the last full admin.
 */
const ensureAtLeastOneFullAdmin = (
  userToUpdate: TeamMembersTableRow,
  newRoles: (Role | UserRole)[],
  allUsers?: Employee[],
): boolean => {
  const isCurrentlyFullAdmin =
    userToUpdate.roles.includes('COMPTROLLER') &&
    userToUpdate.roles.includes('ADMIN');

  const willRemainFullAdmin =
    newRoles.includes('COMPTROLLER') && newRoles.includes('ADMIN');

  if (!isCurrentlyFullAdmin || willRemainFullAdmin) {
    return true;
  }

  const fullAdminsThatHaveCompletedOnboarding = allUsers?.filter(
    (u) =>
      u.completedOnboarding &&
      u.roles?.includes('COMPTROLLER') &&
      u.roles?.includes('ADMIN'),
  );

  // If there's only one full admin and we're trying to demote them, prevent it
  if (fullAdminsThatHaveCompletedOnboarding?.length === 1) {
    showNotification({
      color: 'red',
      title: 'Error',
      message: 'You must have at least one full admin',
    });
    return false;
  }

  return true;
};

const determineRoleUpdatePrivileges = (isFullAdmin: boolean) => {
  const ROLE_SELECT_VALUES: RoleProps[] = [
    {
      value: 'ADMIN',
      label: (
        <>
          <Text fw="500">Admin - Limited</Text>
          <Text>{roleDescriptions.adminLimited}</Text>
        </>
      ),
      id: '2',
    },
    {
      value: 'EMPLOYEE',
      label: (
        <>
          <Text fw="500">Employee</Text>
          <Text>{roleDescriptions.employee}</Text>
        </>
      ),
      id: '3',
    },
    {
      value: 'ACCOUNTANT',
      label: (
        <>
          <Text fw="500">Bookkeeper</Text>
          <Text>{roleDescriptions.bookKeeper}</Text>
        </>
      ),
      id: '4',
    },
  ];
  if (isFullAdmin) {
    ROLE_SELECT_VALUES.push({
      value: 'COMPTROLLER',
      label: (
        <>
          <Text fw="500">Admin - Full</Text>
          <Text>{roleDescriptions.adminFull}</Text>
        </>
      ),
      id: '1',
    });
  }
  ROLE_SELECT_VALUES.sort((a, b) => {
    return Number(a.id) - Number(b.id);
  });
  return ROLE_SELECT_VALUES;
};

export type Role =
  | 'ADMIN'
  | 'EMPLOYEE'
  | 'ACCOUNTANT'
  | 'COMPTROLLER'
  | 'NO-LOGINS';

const MAIN_ROLES: Role[] = ['ADMIN', 'EMPLOYEE', 'ACCOUNTANT', 'COMPTROLLER'];

type MenuItemWithIconProps = {
  id: string;
  label?: string | ReactNode;
  onClick?: () => void;
  icon?: ReactNode;
};

const MenuItemWithIcon = ({
  onClick,
  label,
  icon,
  id,
}: MenuItemWithIconProps) => (
  <Menu.Item
    fz="sm"
    leftSection={icon}
    onClick={onClick}
    id={id}
    data-testid={id}
  >
    {label}
  </Menu.Item>
);

type DisplayStatus = 'invited' | 'active' | 'suspended' | 'in progress';

export type TeamMembersTableRow = {
  id: string;
  name: string;
  initials: string;
  displayStatus: 'invited' | 'active' | 'suspended' | 'in progress';
  email: string;
  roles: Role[];
  completedOnboarding: string;
  platformInvite?: PlatformInvite;
};

/**
 * Map a user's state to a more granular status.
 */
function mapToDisplayStatus(
  user: Employee,
  invite?: PlatformInvite,
): DisplayStatus {
  if (user.status === 'suspended') {
    return 'suspended';
  }

  if (user.completedOnboarding) {
    return 'active';
  }

  // best guess heuristic whether a user has started an application
  // If they have a Platform invite, we can use the status from it.
  // If they do not have a Platform invite, fall back to the NO-LOGINS role
  if (invite?.accepted) {
    return 'in progress';
  } else if (invite?.accepted === false) {
    return 'invited';
  } else if (!user.roles?.includes('NO-LOGINS')) {
    return 'in progress';
  }

  return 'invited';
}

const TeamMembers = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { classes } = useStyles();
  const {
    openConfirmationModal,
    closeAllModals,
    openRightModalNoOpacity,
    openFullModal,
  } = useModal();

  const userId = useRecoilValue(UserIdState);
  const isAdmin = useRecoilValue(IsAdmin);
  const isFullAdmin = useRecoilValue(IsFullAdmin);
  const { accountId } = useRecoilValue(ApplicationState);

  const [filterText, setFilterText] = useState('');
  const [pendingInvitationsClicked, setPendingInvitationsClicked] =
    useState(false);
  const [checkPending, setCheckPending] = useState(false);

  const {
    data: users,
    isPending: isPendingUsers,
    refetch: refetchUsers,
  } = useGetUsers();
  const { data: platformInvites, refetch: refreshInvites } =
    useListPlatformInvites(accountId);
  const { mutate: updatePerson, isPending: isPendingUpdatePerson } =
    useUpdatePerson();
  const { mutate: deleteUser, isPending: isPendingDeleteUser } =
    useDeleteUser();
  const { mutate: updateUser, isPending: isUpdatingUser } =
    useUpdateUserStatus();
  const { mutate: resendUserInvite, isPending: isPendingResendingInvite } =
    useResendPlatformInviteMutation();

  const handleUpdateCheckingPending = () => {
    setCheckPending((prev) => !prev);
  };

  const handleUpdatePendingInvitationClicked = () => {
    setPendingInvitationsClicked((prev) => !prev);
  };

  const handleRowClicked = (user: TeamMembersTableRow) => {
    isMobile
      ? openFullModal(<RoleDetails user={user} closeModal={closeAllModals} />)
      : openRightModalNoOpacity(
          <RoleDetails user={user} closeModal={closeAllModals} />,
        );
  };

  const filterColumn = (column: string, _filterText: string) => {
    return (column && column.toLowerCase()).includes(_filterText.toLowerCase());
  };

  const tableData = useMemo<TeamMembersTableRow[]>(() => {
    if (!users) {
      return [];
    }

    const currentUser = users?.findIndex((item) => item.id === userId);

    const userToInsert = users?.splice(currentUser || 0, 1)[0];
    users?.unshift(userToInsert || {});

    /**
     * Prep/format data to be passed into the FlexbaseTable component
     */
    const parsedTableData: TeamMembersTableRow[] = users?.map((user) => {
      const platformInvite = platformInvites?.data.find(
        (inv) => inv.personId === user.id,
      );
      return {
        id: user.id || '',
        name:
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : 'Not provided',
        initials:
          getInitialsOfNames(
            `${user.firstName} ${user.lastName}`,
          )?.toUpperCase() || '',
        displayStatus: mapToDisplayStatus(user, platformInvite) || '',
        email: user.email || 'N/A',
        roles: (user.roles as Role[]) ?? [],
        completedOnboarding: user.completedOnboarding || '',
        platformInvite,
      };
    });

    return parsedTableData.filter((item) => {
      if (checkPending) {
        return item.displayStatus === 'invited';
      } else {
        return (
          filterColumn(item.name, filterText) ||
          filterColumn(item.email, filterText) ||
          item.roles.some((r) =>
            r.toLowerCase().startsWith(filterText.toLowerCase()),
          )
        );
      }
    });
  }, [users, platformInvites, checkPending, filterText]);

  const handleUpdateRole = (row: TeamMembersTableRow, value: Role) => {
    const existingUneditableRoles = without(
      users?.find((u) => u.id === row.id)?.roles ?? [],
      'COMPTROLLER',
      'ADMIN',
      'EMPLOYEE',
      'ACCOUNTANT',
    );

    const isComptroller = row.roles.includes('COMPTROLLER');
    let updatedRoles: Role[] = [];

    if (value === 'COMPTROLLER') {
      updatedRoles = [value, 'ADMIN'];
    } else if (value === 'ADMIN' && isComptroller) {
      updatedRoles = ['ADMIN'];
    } else {
      updatedRoles = [value];
    }

    const finalRoles = Array.from(
      new Set([...existingUneditableRoles, ...updatedRoles]),
    );

    // Prevent a user from bricking their account by removing the last full admin (DO NOT REMOVE)
    if (!ensureAtLeastOneFullAdmin(row, finalRoles, users)) {
      return;
    }

    updatePerson({
      id: row.id,
      person: {
        roles: Array.from(
          new Set([...existingUneditableRoles, ...updatedRoles]),
        ),
      },
    });
  };

  const returnMenuItem = (
    title: string,
    modalContent: string,
    modalConfirmContent: string,
    onConfirm: () => void,
    menuItemText: string,
  ) => {
    return (
      <Menu.Item
        style={{
          fontSize: '14px',
          width: 150,
          color: theme.primaryColor[1],
        }}
        onClick={() => {
          openConfirmationModal({
            title: title,
            onConfirm: onConfirm,
            onCancel: () => closeAllModals(),
            content: modalContent,
            confirmText: modalConfirmContent,
            cancelText: 'Cancel',
          });
        }}
      >
        {menuItemText}
      </Menu.Item>
    );
  };

  const columns: TableColumn<TeamMembersTableRow>[] = [
    {
      name: 'Name',
      cell: (row) => (
        <div className={classes.who}>
          <Avatar radius="md" size="32px" className={classes.whoIcon}>
            {row.initials.toUpperCase()}
          </Avatar>
          {row.name}
        </div>
      ),
      minWidth: isMobile ? '150px' : '',
      selector: (row) => row.name,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Status',
      cell: (row) => {
        let tagState: string;

        switch (row.displayStatus.toLowerCase()) {
          case 'suspended':
            tagState = 'error';
            break;
          case 'in progress':
            tagState = 'secondary';
            break;
          case 'invited':
            tagState = 'warning';
            break;
          case 'active':
            tagState = 'success';
            break;
          default:
            tagState = 'zero';
        }

        return (
          <StatusTag size="sm" variant="border" state={tagState}>
            {capitalizeOnlyFirstLetter(row.displayStatus)}
          </StatusTag>
        );
      },
      minWidth: isMobile ? '125px' : '',
      selector: (row) => row.displayStatus,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      minWidth: isMobile ? '270px' : '',
      grow: 2,
    },
    {
      name: 'Role',
      cell: (row) => {
        const [opened, setOpened] = useState(false);
        const foundRole = row.roles.find((r) => MAIN_ROLES.includes(r));
        const getRoleLabel = (roles: string[]) => {
          const labels = [];

          if (roles.includes('OFFICER')) {
            labels.push('Officer');
          } else if (roles.includes('OWNER')) {
            labels.push('Owner');
          }

          if (roles.includes('ADMIN') && roles.includes('COMPTROLLER')) {
            labels.push('Admin - Full');
          } else if (roles.includes('ADMIN')) {
            labels.push('Admin - Limited');
          } else if (roles.includes('ACCOUNTANT')) {
            labels.push('Bookkeeper');
          } else if (foundRole) {
            labels.push(capitalizeOnlyFirstLetter(foundRole));
          }

          return labels.join(', ');
        };

        return row.id === userId ? (
          <Text fz="sm">{getRoleLabel(row.roles)}</Text>
        ) : (
          <Menu
            width="22rem"
            opened={opened}
            onChange={setOpened}
            position="bottom-end"
            disabled={!isAdmin}
            classNames={{ dropdown: classes.dropdown }}
          >
            <Menu.Target>
              <Box className={classes.customSelect}>
                <Box className={classes.selectOption} h={20}>
                  <Text fz="sm">{getRoleLabel(row.roles)}</Text>
                  <PiCaretDown
                    style={{
                      transition: '0.2s ease-out',
                      transform: opened ? 'rotate(-180deg)' : 'rotate(0deg)',
                    }}
                    fill={theme.colors.neutral[6]}
                    width={18}
                    className={classes.icon}
                  />
                </Box>
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              {determineRoleUpdatePrivileges(isFullAdmin).map((option) => (
                <MenuItemWithIcon
                  key={option.id}
                  id={option.id}
                  label={option.label}
                  onClick={() => handleUpdateRole(row, option.value)}
                />
              ))}
            </Menu.Dropdown>
          </Menu>
        );
      },
      minWidth: '26.4rem',
      selector: (row) => row.roles[0],
      sortable: true,
      grow: 2,
    },
    {
      cell: (row) =>
        row.id !== userId && (
          <Menu
            position="bottom-end"
            classNames={{ dropdown: classes.dropdown }}
            styles={customMantineStyles(theme.primaryColor)}
          >
            <Menu.Target>
              <div style={{ width: '30px' }}>
                <PiDotsThreeVertical
                  size={'1.5rem'}
                  color={theme.colors.neutral[6]}
                />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {row.displayStatus === 'suspended' &&
                returnMenuItem(
                  'Re-activate team member?',
                  'The user account will be re-activated and they will be able to log in and use their issued cards. This action is reversible.',
                  'Yes, re-activate',
                  () => {
                    updateUser({
                      id: row.id,
                      status: 'active',
                      successMessage: 'The user has been re-activated.',
                    });
                  },
                  'Re-activate user',
                )}

              {row.displayStatus === 'invited' &&
                !row.platformInvite?.accepted &&
                !row.completedOnboarding &&
                returnMenuItem(
                  'Un-invite team member?',
                  'The user invite will be cancelled and their account will be suspended. This action is reversible.',
                  'Yes, un-invite',
                  () => {
                    updateUser({
                      id: row.id,
                      status: 'suspended',
                      successMessage: 'The user has been un-invited.',
                    });
                  },
                  'Un-invite',
                )}

              {row.displayStatus === 'invited' &&
                row.platformInvite?.accepted === false &&
                returnMenuItem(
                  'Re-send invite?',
                  'The user will be sent an invitation email',
                  'Yes, re-send',
                  () => {
                    resendUserInvite({ accountId, personId: row.id });
                  },
                  'Re-send invite',
                )}

              {row.displayStatus === 'invited' && !row.platformInvite && (
                <Menu.Item>
                  <Tooltip label="Cannot reinvite user. They must reset their password">
                    <Box>Re-invite</Box>
                  </Tooltip>
                </Menu.Item>
              )}

              {row.displayStatus === 'active' &&
                returnMenuItem(
                  'Suspend team member?',
                  'Suspending this team member will prevent them from logging in or using their issued cards. This action is reversible.',
                  'Yes, suspend',
                  () => {
                    updateUser({
                      id: row.id,
                      status: 'suspended',
                      successMessage: 'The user has been suspended.',
                    });
                  },
                  'Suspend user',
                )}
              {row.completedOnboarding && (
                <Menu.Item
                  style={{ fontSize: '14px', width: 150, color: 'red' }}
                  onClick={() => {
                    if (!row.completedOnboarding) {
                      showNotification({
                        color: 'red',
                        title: 'Failure',
                        message: `${row.name} is not fully onboarded and cannot be deleted.`,
                      });
                    } else {
                      openConfirmationModal({
                        title: 'Delete team member?',
                        onConfirm: () => deleteUser(row.id),
                        onCancel: () => closeAllModals(),
                        content:
                          'Deleting this team member will remove them from Flex. This action cannot be undone.',
                        confirmText: 'Yes, delete',
                        cancelText: 'Cancel',
                      });
                    }
                  }}
                >
                  Remove user
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        ),
      compact: true,
      width: '25px',
    },
  ];

  const pending =
    isPendingUpdatePerson ||
    isPendingDeleteUser ||
    isUpdatingUser ||
    isPendingResendingInvite;

  return (
    <div className={classes.baseContainer}>
      <LoadingOverlay visible={pending} />
      <FlexDefaultHeader title={'Team'} />
      <div className={classes.container}>
        <div className={classes.widgetContainer}>
          <TeamMembersHeader
            onFilter={(e) => setFilterText(e.target.value)}
            filterText={filterText}
            pendingInvitationsClicked={pendingInvitationsClicked}
            updatePendingInvitationClicked={
              handleUpdatePendingInvitationClicked
            }
            updateCheckingPending={handleUpdateCheckingPending}
            getAllUsers={() => {
              refetchUsers();
              refreshInvites();
            }}
          />
          <FlexbaseTable
            columns={columns}
            data={tableData}
            onRowClicked={handleRowClicked}
            isFetchingData={isPendingUsers}
            customStyles={{
              ...baseTableStyles(),
              table: {
                style: {
                  ...baseTableStyles().table.style,
                  button: {
                    ...baseTableStyles().table.style.button,
                    backgroundColor: 'initial',
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
