import {
  rem,
  Flex,
  Text,
  Button,
  Avatar,
  useMantineTheme,
} from '@mantine/core';
import { PiWarningCircle, PiArrowLeftBold } from 'react-icons/pi';

type BillPayCreditPaymentErrorProps = {
  errorMessage: string;
  onTryAgain: () => void;
  onBackToPayments: () => void;
};

const BillPayCreditPaymentError = ({
  errorMessage,
  onTryAgain,
  onBackToPayments,
}: BillPayCreditPaymentErrorProps) => {
  const theme = useMantineTheme();

  return (
    <Flex
      p="xl"
      gap="md"
      bg="neutral.0"
      align="center"
      direction="column"
      bd="1px solid neutral.4"
    >
      <Avatar p="lg" size={rem(78)} bg="critical.0" bd="2px solid critical.3">
        <PiWarningCircle size="2.5rem" color={theme.colors.critical[6]} />
      </Avatar>
      <Text ta="center" size={rem(32)} ff="heading">
        {errorMessage}
      </Text>
      <Flex mt={rem(48)} gap="lg" justify="space-between">
        <Button
          variant="neutral-outline"
          onClick={onBackToPayments}
          leftSection={<PiArrowLeftBold />}
        >
          Back to payments
        </Button>
        <Button onClick={onTryAgain}>Try again</Button>
      </Flex>
    </Flex>
  );
};

export default BillPayCreditPaymentError;
