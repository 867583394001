import { createStyles } from '@mantine/emotion';

export const useSpendPlanLimitsStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
    borderRadius: theme.defaultRadius,
    marginTop: theme.spacing.lg,
    padding: theme.spacing.xl,
  },
}));
