import { queryOptions, useQuery } from '@tanstack/react-query';
import { Line } from '@services/flexbase/credit.model';
import { platformClient } from '@services/platform/platform-client';

const creditLinesOptions = (businessId: string) => {
  return queryOptions({
    queryKey: ['credit', 'lines', 'list', businessId],
    queryFn: async (): Promise<Line[]> => {
      const response = await platformClient.getLinesOfCredit(businessId);

      return response.data;
    },
    meta: {
      errorMessage: 'Could not retrieve card programs at this time.',
    },
    enabled: !!businessId,
  });
};

export const useCreditLines = (businessId: string) => {
  return useQuery(creditLinesOptions(businessId));
};

export const useUnitCreditLines = (businessId: string) => {
  return useQuery({
    ...creditLinesOptions(businessId),
    select: (lines) => lines.filter((line) => line.issuer === 'unit'),
  });
};

export const useBillpayCreditLines = (businessId: string) => {
  return useQuery({
    ...creditLinesOptions(businessId),
    select: (lines) => lines.filter((line) => line.issuer === 'billpay'),
  });
};

export const useMarqetaCreditLines = (businessId: string) => {
  return useQuery({
    ...creditLinesOptions(businessId),
    select: (lines) => lines.filter((line) => line.issuer === 'marqeta'),
  });
};
