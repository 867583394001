import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Group, Menu, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import {
  PiArrowsLeftRight,
  PiCaretDown,
  PiHandCoins,
  PiScroll,
  PiWallet,
} from 'react-icons/pi';
import useModal from '@common/composites/modal/modal-hook';
import MoveFunds from 'areas/banking/move-funds/move-funds';
import AddFunds from 'areas/banking/add-funds/add-funds';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';

type MenuItemWithIconProps = {
  id: string;
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
  badgeLabel?: string;
};

const MenuItemWithIcon = ({
  onClick,
  label,
  icon,
  id,
  badgeLabel,
}: MenuItemWithIconProps) => (
  <Menu.Item leftSection={icon} onClick={onClick} id={id} data-testid={id}>
    <Group gap="xs">
      <Text size="sm">{label}</Text>
      {badgeLabel && (
        <Badge bg="primary.3" size="xs">
          <Text c="neutral.0">{badgeLabel}</Text>
        </Badge>
      )}
    </Group>
  </Menu.Item>
);

const BankingActionButton = () => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const { classes, theme } = useStyles(opened);
  const { openRightModal } = useModal();
  const { productStatus } = useRecoilValue(ApplicationState);

  const openMoveFundsModal = () => {
    openRightModal(<MoveFunds />);
  };

  const openAddFundsModal = () => {
    openRightModal(<AddFunds />);
  };

  const menuOptions = [
    {
      onClick: () => navigate('/payments/outgoing/recipient'),
      icon: <PiHandCoins size={'1.25rem'} />,
      label: 'Send payment',
      id: 'send-payment',
    },
    {
      onClick: () => openAddFundsModal(),
      icon: <PiWallet size={'1.25rem'} />,
      label: 'Add or receive funds',
      id: 'add-funds',
    },
    {
      onClick: () => openMoveFundsModal(),
      icon: <PiArrowsLeftRight size={'1.25rem'} />,
      label: 'Transfer between your accounts',
      id: 'transfer-between-accounts',
    },
    {
      onClick: () => navigate('/bill-pay/new'),
      icon: <PiScroll size={'1.25rem'} />,
      label: 'Pay a bill',
      id: 'bill-pay',
      badgeLabel: 'New',
      disabled: productStatus.banking.appStatus !== 'approved',
    },
  ];

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      id="banking-button-header"
      data-testid="banking-button-header"
      classNames={{ item: classes.item }}
    >
      <Menu.Target>
        <Button
          size="sm"
          variant="primary-light"
          rightSection={
            <PiCaretDown
              fill={theme.colors.neutral[0]}
              size={'1.25rem'}
              className={classes.icon}
            />
          }
        >
          Move Money
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {menuOptions
          .filter((item) => !item.disabled)
          .map((option) => (
            <MenuItemWithIcon
              id={option.id}
              key={option.id}
              icon={option.icon}
              label={option.label}
              onClick={option.onClick}
              badgeLabel={option.badgeLabel}
            />
          ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default BankingActionButton;

const useStyles = createStyles((theme, opened: boolean) => ({
  item: {
    fill: theme.primaryColor,
    color: theme.primaryColor,
    '&:hover': {
      fill: theme.colors.primary[2],
      color: theme.colors.primary[2],
    },
  },

  icon: {
    transition: '0.2s ease-out',
    transform: opened ? 'rotate(-180deg)' : 'rotate(0deg)',
  },
}));
