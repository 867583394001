import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flexbaseBankingClient } from '../services/flexbase-client';

type AccountDetails = { isDefault: boolean; nickName: undefined };

const mutationFn = async (accountDetails: AccountDetails) => {
  return await flexbaseBankingClient.createDepositAccount(accountDetails);
};

export const useCreateDepositAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DEPOSIT_ACCOUNTS_QUERY_KEY],
      });
      showNotification({
        color: 'sage.4',
        title: 'Success',
        message: 'Successfully added a new bank account!',
      });
    },
    onError: (error) => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `We were unable to add a new bank account at this time. Error: ${error}`,
      });
    },
  });
};

const DEPOSIT_ACCOUNTS_QUERY_KEY = 'bankingDepositAccounts';

export const useGetDepositAccounts = (enabled?: boolean) => {
  return useQuery({
    queryKey: [DEPOSIT_ACCOUNTS_QUERY_KEY],
    queryFn: async () => {
      return await flexbaseBankingClient.getDepositList();
    },
    meta: {
      errorMessage: 'Unable to retrieve banking accounts at this time.',
    },
    enabled: enabled ?? true,
  });
};

type DepositStateUpdateFn = {
  companyId: string;
  depositId: string;
  status: 'closed';
};
const closeDepostiAccountStatusFn = async ({
  companyId,
  depositId,
  status,
}: DepositStateUpdateFn) => {
  return await flexbaseBankingClient.closeDepositAccount(companyId, depositId, {
    status,
    reason: 'ByCustomer',
  });
};

export const useCloseDepositAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: closeDepostiAccountStatusFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DEPOSIT_ACCOUNTS_QUERY_KEY],
      });
      showNotification({
        color: 'sage.4',
        title: 'Success',
        message: 'The account has been closed!',
      });
    },
    onError: (error) => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `An error occurred while trying to close the account. Error: ${error.message}`,
      });
    },
  });
};
