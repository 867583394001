import { Fragment, useEffect, useState } from 'react';
import { useTermsFormContext } from './terms-form-context';
import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Group,
  Stack,
  Text,
  useMantineTheme,
  ActionIcon,
  Flex,
  rem,
  Skeleton,
} from '@mantine/core';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';
import { PersonalGuarantyResponse } from '@services/flexbase/agreements.model';
import { UseHasTermsReturnType } from '../../../../../utilities/custom-hooks/use-has-terms';
import {
  TermsOfServiceDocWithContents,
  useGetTermsOfServiceDocsNeededForUser,
} from '@queries/use-terms-of-service';
import useModal from '@common/composites/modal/modal-hook';
import { PiCheckCircle, PiX } from 'react-icons/pi';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';
import ViewAndAgreeTermsModal from './view-and-agree-terms-modal';
import { OnboardingUser } from 'types/onboarding-info';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

type Props = {
  showTextAgreements: boolean;
  /** Configuration object specifying which terms need to be shown/accepted. */
  termsConfig: Omit<
    UseHasTermsReturnType,
    | 'hasActiveBanking'
    | 'hasActiveTreasury'
    | 'hasActiveCredit'
    | 'hasTermsForActiveProduct'
    | 'hasActiveIntnlPayments'
  >;
};

/**
 * An encapsulated "widget" for doing all things terms on the summary screen.
 */
export const TermsinatorWithModal = ({
  showTextAgreements,
  termsConfig,
}: Props) => {
  const theme = useMantineTheme();
  const formContext = useTermsFormContext();
  const modals = useModal();
  const { user } = useRecoilValue(ApplicationState);
  const isMobile = useIsMobile();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [personalG, setPersonalG] = useState<PersonalGuarantyResponse>();
  const [savingTermsAgreement, setSavingTermsAgreement] = useState(false);

  const { termsNeeded, isPending } = useGetTermsOfServiceDocsNeededForUser({
    termsConfig,
    showTextAgreements,
  });

  const getPersonalGuaranty = async () => {
    try {
      const response = await flexbaseOnboardingClient.getPersonalGuaranty();
      setPersonalG(response);
      if (response.status === 'accepted') {
        formContext.setFieldValue('personalGuarantySigned', true);
      }
    } catch (e) {
      console.error('squashed-error', e);
    }
  };

  useEffect(() => {
    /**
     * Personal Guaranty is only available after the user accepts terms. So we need to
     * wait until the user has accepted terms before we can fetch it, and display it as a separate checkbox.
     */
    getPersonalGuaranty();
  }, [termsAccepted]);

  const personalGReady =
    personalG?.status === 'pending' && !!personalG?.personalGuaranty?.contents;
  const disablePG = !personalGReady;

  const handleAcceptTerms = async () => {
    setSavingTermsAgreement(true);
    try {
      const values = formContext.getValues();
      const request: Partial<OnboardingUser> = { id: user.id };
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          request[key as keyof OnboardingUser] = value as any;
        }
      });
      await flexbaseOnboardingClient.updateUser(request);
      setTermsAccepted(true);
    } catch (e) {
      console.error('terminator.tsx', e);
    } finally {
      setSavingTermsAgreement(false);
    }
  };

  const openViewAndAcceptTermsModal = () => {
    if (isMobile) {
      modals.openFullModal(
        <ViewAndAgreeTermsModal
          termsForm={formContext}
          termsDocuments={termsNeeded}
          onAccept={handleAcceptTerms}
        />,
      );
    } else {
      modals.openCenterModalUnstyled(
        <ViewAndAgreeTermsModal
          termsForm={formContext}
          termsDocuments={termsNeeded}
          onAccept={handleAcceptTerms}
        />,
        '85%',
      );
    }
  };

  const openPersonGuarantyModal = (contents: string) => {
    if (isMobile) {
      modals.openFullModal(
        <Stack pb="lg">
          <Group justify="right" p="md">
            <ActionIcon variant="subtle" onClick={modals.closeAllModals}>
              <PiX size={24} />
            </ActionIcon>
          </Group>
          <Box
            pr="lg"
            pb="lg"
            w="100%"
            dangerouslySetInnerHTML={{
              __html: contents,
            }}
          ></Box>
          <Group justify="center">
            <Button onClick={modals.closeAllModals}>Close</Button>
          </Group>
        </Stack>,
      );
    } else {
      modals.openCenterModalUnstyled(
        <Stack pb="lg">
          <Group justify="right" p="md">
            <ActionIcon
              variant="subtle"
              onClick={() => modals.closeAllModals()}
            >
              <PiX size={24} />
            </ActionIcon>
          </Group>
          <Box
            pr="lg"
            pb="lg"
            w="100%"
            dangerouslySetInnerHTML={{
              __html: contents,
            }}
          ></Box>
          <Group justify="right" p="md">
            <Button onClick={() => modals.closeAllModals()}>Close</Button>
          </Group>
        </Stack>,
        '85%',
      );
    }
  };

  const openWindowWithContent = (htmlContent: string) => {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const newWindow = window.open(url, '_blank');

    // Clean up the URL object once the window is loaded
    if (newWindow) {
      newWindow.onload = () => {
        URL.revokeObjectURL(url);
      };
    }
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    term: TermsOfServiceDocWithContents,
  ) => {
    if (term.contents) {
      /**
       * Terms like the FL and NY agreements don't have a url, instead serve the actual content of the agreement.
       * So we need to open the actual content in a separate window.
       */
      e.preventDefault();
      e.stopPropagation();
      openWindowWithContent(term.contents);
    }
  };

  if (isPending) {
    return (
      <Flex
        bg={theme.colors.neutral[0]}
        bd={`1px solid ${theme.colors.neutral[4]}`}
        p="md"
        sx={{ borderRadius: 4 }}
        align="center"
        gap="md"
      >
        <Group flex={1} justify="space-between">
          <Text fw="500">Terms</Text>
          <Skeleton height={rem(8)} maw={rem(150)} radius="xs" />
        </Group>
      </Flex>
    );
  }

  return (
    <>
      {!!termsNeeded?.length && (
        <Flex
          bg={theme.colors.neutral[0]}
          bd={`1px solid ${theme.colors.neutral[4]}`}
          p="md"
          sx={{ borderRadius: 4 }}
          direction={termsAccepted ? 'row' : { base: 'column', sm: 'row' }}
          align="center"
          gap="md"
        >
          {termsAccepted && (
            <PiCheckCircle size={32} color={theme.colors.primary[2]} />
          )}
          <Stack flex={1} gap={0}>
            <Text fw="500">Terms</Text>
            <Box>
              {termsNeeded
                ?.map((term) => (
                  <Anchor
                    key={term.type}
                    td="underline"
                    href={term.url || '#'}
                    onClick={(e) => {
                      handleLinkClick(e, term);
                    }}
                    target="_blank"
                    size="sm"
                    c={theme.colors.primary[2]}
                  >
                    {term.label}
                  </Anchor>
                ))
                .map((element, index, array) => (
                  <Fragment key={`fragment-${index}`}>
                    {element}
                    {index < array.length - 1 && (
                      <Text component="span" mr={4}>
                        ,
                      </Text>
                    )}
                  </Fragment>
                ))}
            </Box>
          </Stack>
          {!termsAccepted && (
            <Button
              onClick={openViewAndAcceptTermsModal}
              variant="primary-light"
              loading={savingTermsAgreement}
              id="button-view-and-accept-terms"
            >
              View and accept
            </Button>
          )}
        </Flex>
      )}
      {termsConfig.hasPersonalGuaranty && (
        <Checkbox
          disabled={disablePG}
          label={
            <Text fz={12}>
              I have read and accepted the{' '}
              <Anchor
                fz={12}
                c={theme.colors.primary[2]}
                td="underline"
                sx={{
                  ...(disablePG && {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                    pointerEvents: 'none',
                  }),
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (personalGReady) {
                    openPersonGuarantyModal(
                      personalG.personalGuaranty.contents,
                    );
                  }
                }}
              >
                Personal Guaranty
              </Anchor>
            </Text>
          }
          {...formContext.getInputProps('personalGuarantySigned')}
          id="checkbox-agree-personal-guaranty"
        />
      )}
    </>
  );
};
