import { useState, useEffect } from 'react';
import { Button, Group, Image, rem, useMantineTheme } from '@mantine/core';

import { useStyles } from '../../styles';
import errorPendingTransfer from 'assets/images/watch-banking.png';
import SuccessTransfer from 'assets/images/transferred-banking.png';
import { setPaymentRejectedError } from 'utilities/formatters/payment-rejected-message';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  title: string;
  backTo: string;
  reason?: string;
  status?: string;
  textToStart?: string;
  onClick?: () => void;
  closeModal: () => void;
};

const ModalSuccess = ({
  title,
  reason,
  backTo,
  onClick,
  closeModal,
  textToStart,
  status = 'Sent',
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [message, setMessage] = useState<string>();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  let transactionStatus: {
    title: string;
    color: string;
    description?: string;
    sketch?: string;
  };

  switch (status) {
    case 'Pending':
    case 'PendingReview':
      transactionStatus = {
        title: 'Success!',
        color: theme.colors.warning[1],
        description:
          'We are processing your deposit request. Funds should be available in 3 business days.',
        sketch: errorPendingTransfer,
      };
      break;

    case 'Rejected':
      transactionStatus = {
        description: message,
        title: 'Your transaction has failed',
        color: theme.colors.critical[1],
        sketch: errorPendingTransfer,
      };
      break;

    default:
      transactionStatus = {
        title: title,
        color: theme.colors.primary[1],
        sketch: SuccessTransfer,
      };
      break;
  }

  const getPaymentError = (msg: string) => {
    const newMessage = setPaymentRejectedError(msg);
    setMessage(newMessage);
  };

  useEffect(() => {
    if (reason) {
      getPaymentError(reason);
    }
  }, []);

  const handleClose = () => {
    closeModal();
  };

  return (
    <div className={classes.modalSuccess}>
      <Image src={transactionStatus.sketch} width={rem(260)} />

      <span data-testid={'message'}>{transactionStatus.title}</span>
      <p style={{ fontFamily: 'PP Neue Montreal' }}>
        {transactionStatus.description}
      </p>

      <Group justify={useMobileView ? 'center' : 'apart'}>
        <Button
          variant="neutral-outline"
          onClick={handleClose}
          data-testid={'go-back-button'}
        >
          Back to {backTo}
        </Button>

        {onClick && textToStart && (
          <Button
            variant="primary-light"
            onClick={onClick}
            data-testid={'make-another-transfer-button'}
          >
            {textToStart}
          </Button>
        )}
      </Group>
    </div>
  );
};

export default ModalSuccess;
