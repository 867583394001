import { useForm } from '@mantine/form';
import { formatCurrency } from '@utilities/formatters';
import { RequiredFieldValidator } from '@utilities/validators';
import { useEffect, useState } from 'react';
import { LineItemInput, useInvoiceWizard } from '../../invoice-wizard';
import { useParams } from 'react-router-dom';
import {
  useCreateBillpayInvoice,
  useUpdateBillpayInvoice,
} from '@queries/use-bill-pay';
import WizardErrorBanner from 'areas/billpay/wizard-error-banner';
import { DateInput } from '@mantine/dates';
import { PiCalendarLight } from 'react-icons/pi';
import {
  Box,
  Center,
  Loader,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useGetPlatformBusinessAccountsPayable } from '@queries/use-platform-business';
import { isEmpty } from 'underscore';
import LineItemAccountingInfo from '../add-line-items/line-item-accounting-info';
import { BillpayLineItemAccountingInfo, LineItemStatus } from 'types/bill-pay';
import { mapLineItemInputsToApiFormat } from '../add-line-items/add-line-items';
import { DateTime } from 'luxon';

const useHasAccountsPayable = () => {
  const { businessId } = useRecoilValue(ApplicationState);
  const { data: accountsPayableData, isLoading: isLoadingAccountsPayable } =
    useGetPlatformBusinessAccountsPayable(businessId);
  return {
    hasAccountsPayable: !isEmpty(accountsPayableData),
    isLoadingAccountsPayable,
  };
};

const ConfirmTotal = () => {
  const { hasAccountsPayable, isLoadingAccountsPayable } =
    useHasAccountsPayable();
  const { state, setState, onNext, goToNextStep } = useInvoiceWizard();
  const [showWireAmountError, setShowWireAmountError] = useState(false);
  const { id: existingInvoiceIdFromParams } = useParams();
  const existingInvoiceId =
    existingInvoiceIdFromParams || state.existingInvoiceId;
  const form = useForm({
    initialValues: {
      total: state.invoiceTotal?.cents ? state.invoiceTotal?.cents / 100 : 0,
      dueDate: state.invoiceDetails?.dueDate,
      lineItems: !isEmpty(state.invoiceDetails?.lineItems)
        ? (state.invoiceDetails?.lineItems as LineItemInput[])
        : [
            {
              description: undefined,
              quantity: 1,
              timestamp: Date.now(),
              status: 'active' as LineItemStatus,
              accountingInfo: {},
            },
          ],
    },
    validate: {
      total: RequiredFieldValidator('Total is required'),
      dueDate: RequiredFieldValidator('Due date is required'),
    },
  });

  const { mutate: updateBillpayInvoice, error: updateBillpayInvoiceError } =
    useUpdateBillpayInvoice();
  const { mutate: createBillpayInvoice, error: createBillpayInvoiceError } =
    useCreateBillpayInvoice();

  const wireAmountError = showWireAmountError
    ? 'To send a wire to this recipient, this payment must meet the minimum wire amount of $50.00.'
    : undefined;
  const invoiceCreationError =
    'There was an error saving the invoice, please try again.';
  const showWizardErrorBanner =
    createBillpayInvoiceError || updateBillpayInvoiceError || wireAmountError;

  const { isInvoiceDraft, isActionDisabled } = state;
  const isDisabledDueDate = !isInvoiceDraft || isActionDisabled;

  onNext(async () => {
    form.validate();

    const invoiceTotalError =
      state.recipientAccount?.type === 'wire' &&
      state.invoiceTotal?.cents &&
      state.invoiceTotal.cents < 5000;

    if (!form.isDirty() && !!existingInvoiceId) {
      if (invoiceTotalError) {
        setShowWireAmountError(true);
        return;
      }
      goToNextStep();
      return;
    }

    if (form.isValid() && form.values.dueDate && state.existingDocumentId) {
      if (invoiceTotalError) {
        setShowWireAmountError(true);
        return;
      }

      const shouldUpdateLineItems =
        hasAccountsPayable &&
        form.values.lineItems &&
        form.values.lineItems.length > 0;

      const conditionalLineItems = hasAccountsPayable
        ? mapLineItemInputsToApiFormat(form.values.lineItems)
        : [];

      if (existingInvoiceId) {
        const updateInvoiceBody = {
          id: existingInvoiceId,
          recipientId: state.recipient?.id,
          dueDate: form.values.dueDate,
          documentId: state.existingDocumentId,
          total: state.invoiceTotal?.cents,
          lineItems: conditionalLineItems,
        };

        if (shouldUpdateLineItems) {
          updateInvoiceBody.lineItems = mapLineItemInputsToApiFormat(
            form.values.lineItems,
            true,
          );
        }

        updateBillpayInvoice(updateInvoiceBody, {
          onSuccess: () => {
            goToNextStep();
            setState({
              invoiceTotal: {
                cents: Math.round(form.values.total * 100),
                formatted: formatCurrency(form.values.total),
              },
              existingInvoiceId,
              invoiceDetails: {
                dueDate: form.values.dueDate,
                lineItems: form.values.lineItems,
              },
            });
          },
        });
      } else {
        // Math.round because we're seeing floating-point precision issues (eg. 35819.48 * 100 = 3581948.0000000005) on submit
        const totalCents = Math.round(form.values.total * 100);

        const createInvoiceBody = {
          recipientId: state.recipient?.id,
          dueDate: form.values.dueDate,
          total: totalCents,
          documentId: state.existingDocumentId,
          lineItems: conditionalLineItems,
        };

        if (shouldUpdateLineItems) {
          createInvoiceBody.lineItems = mapLineItemInputsToApiFormat(
            form.values.lineItems,
            true,
          );
        }

        createBillpayInvoice(createInvoiceBody, {
          onSuccess: (invoiceResponse) => {
            setState({
              invoiceTotal: {
                cents: totalCents,
                formatted: formatCurrency(form.values.total),
              },
              existingInvoiceId: invoiceResponse.invoice.id,
              invoiceDetails: {
                dueDate: form.values.dueDate,
                lineItems: form.values.lineItems,
              },
            });
            goToNextStep();
          },
        });
      }
    }
  });

  const handleAccountingInfoChange = (
    field: keyof BillpayLineItemAccountingInfo,
    value: string | null,
  ) => {
    form.setFieldValue(
      `lineItems.0.accountingInfo.${field}`,
      value || undefined,
    );
  };

  useEffect(() => {
    setState({
      invoiceTotal: {
        cents: form.values.total * 100,
        formatted: formatCurrency(form.values.total),
      },
    });
    if (
      hasAccountsPayable &&
      form.values.lineItems &&
      form.values.lineItems.length > 0
    ) {
      form.setFieldValue('lineItems.0.total', form.values.total);
    }
  }, [form.values.total, hasAccountsPayable]);

  if (isLoadingAccountsPayable) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <NumberInput
        hideControls
        data-testid="total-input"
        label="Total"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        leftSection="$"
        placeholder="0.00"
        variant="unstyled"
        {...form.getInputProps('total')}
        onChange={() => null}
        onValueChange={(value) => {
          form.setFieldValue('total', value.floatValue ?? 0);
        }}
      />

      {hasAccountsPayable && (
        <>
          <TextInput
            label="Description"
            description="Optional"
            placeholder="Description"
            mb="md"
            {...form.getInputProps('lineItems.0.description')}
          />
          <Box>
            <Text size="sm" c="neutral.7">
              Accounting information
            </Text>
            <Text size="xs" c="neutral.7" mb="xs">
              Optional
            </Text>
            <LineItemAccountingInfo
              index={0}
              value={form.values.lineItems?.[0]?.accountingInfo}
              onValueChange={handleAccountingInfoChange}
            />
          </Box>
        </>
      )}

      <DateInput
        labelProps={{
          c: 'neutral.7',
        }}
        data-testid="due-date-datepicker"
        disabled={isDisabledDueDate}
        label="Due date"
        leftSection={<PiCalendarLight size={'1.25rem'} />}
        placeholder="Select due date"
        {...form.getInputProps('dueDate')}
        value={
          form.values.dueDate
            ? DateTime.fromISO(form.values.dueDate).toJSDate()
            : null
        }
        onChange={(date) => form.setFieldValue('dueDate', date?.toISOString())}
      />

      {showWizardErrorBanner && (
        <WizardErrorBanner message={wireAmountError || invoiceCreationError} />
      )}

      <Text size="xs" c="neutral.8">
        You are responsible for the accuracy of any data extracted from your
        invoices. You understand and acknowledge that the OCR feature of Flex’s
        bill pay features are for convenience and final accuracy of any
        information, including but not limited to, descriptions, quantities,
        amounts, credits, taxes, and invoice totals lies with you. Flex
        disclaims any liability for any inaccuracies in the data captured via
        its bill pay feature, and shall have no liability to you for any losses
        or damages for inaccurate information.
      </Text>
    </Stack>
  );
};

export default ConfirmTotal;
