import { ApplicationConfigBuilder } from './application-config-builder';

const configBuilder = new ApplicationConfigBuilder([
  'BANKING',
]).forRequirementArray('requiredBanking');

configBuilder
  .addStep('change-password', true)
  .withRequirement('user.changePassword');

configBuilder
  .addStep('verify-phone')
  .withRequirement('user.phone')
  .withRequirement('user.cellPhone')
  .withRequirement('user.verifyPhone');

configBuilder
  .addStep('business-type')
  .withRequirement('company.legalStructure');

configBuilder.addStep('user-address').withRequirement('user.address');

configBuilder
  .addStep('verify-identity')
  .withRequirement('user.taxId')
  .withRequirement('user.birthDate')
  .withRequirement('user.ficoPullSigned')
  .withRequirement('user.patriotActSigned');

configBuilder.addStep('job-title').withRequirement('user.jobTitle');

configBuilder
  .addStep('business-activity')
  .withRequirement('company.companyName')
  .withRequirement('company.website')
  .withRequirement('company.annualRevenue')
  .withRequirement('company.businessPurpose')
  .withRequirement('company.category')
  .withRequirement('company.businessVertical');

configBuilder
  .addStep('verify-business')
  .withRequirement('company.taxId')
  .withRequirement('company.formationDate')
  .withRequirement('company.phone')
  .withRequirement('company.address');

configBuilder.addStep('business-owners').withRequirement('company.owners');

configBuilder
  .addStep('control-person')
  .withRequirement('company.controlPerson');

configBuilder
  .addStep('summary')
  .withRequirement('user.termsOfServiceSigned')
  .withRequirement('user.bankingTermsOfServiceSigned');

export const BankingApplicationConfig = configBuilder.build();
