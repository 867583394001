import { stylesPDF } from './styles';
import { MantineTheme } from '@mantine/core';
import { Text, View } from '@react-pdf/renderer';
import Country from 'country-state-city/lib/country';
import {
  IntlPayment,
  IntlPaymentStatus,
  MoneyMovementStatus,
} from '@services/flexbase/banking.model';
import { formatCurrency } from '@utilities/formatters';
import { ParsedBeneficiary } from '@utilities/custom-hooks/use-beneficiaries';

type IntlPaymentProps = {
  usdAmount: string;
  rateAmount: number;
  theme: MantineTheme;
  payment: IntlPayment;
  beneficiary: ParsedBeneficiary;
  paymentStatus: MoneyMovementStatus | IntlPaymentStatus;
};

const IntlWireReceipt = ({
  theme,
  payment,
  usdAmount,
  rateAmount,
  beneficiary,
  paymentStatus,
}: IntlPaymentProps) => {
  const styles = stylesPDF({
    primaryDark: theme.colors.primary[8],
    secondaryDark: theme.colors.primary[2],
    tertiary: theme.colors.warning[2],
    neutral: theme.colors.neutral[3],
    textColor: theme.colors.neutral[7],
  });

  const currency = payment?.ccResponse?.currency || 'USD';
  const country = Country.getCountryByCode(beneficiary.ccResponse.bankCountry);
  const displayConversionRate =
    ['ready_to_send', 'completed'].includes(paymentStatus) &&
    currency !== 'USD';

  return (
    <>
      <View style={{ ...styles.card, marginTop: 20 }}>
        <Text style={styles.greenText}>Recipient’s bank details</Text>
        <View style={styles.containerData}>
          <Text style={styles.text}>Bank</Text>
          <Text style={styles.text}>{beneficiary.bank}</Text>
        </View>
        <View style={styles.containerData}>
          <Text style={styles.text}>Country</Text>
          <Text style={styles.text}>{country?.name}</Text>
        </View>
        <View style={styles.containerData}>
          <Text style={styles.text}>Address</Text>
          <Text style={{ ...styles.text, textAlign: 'right' }}>
            {beneficiary?.ccResponse?.beneficiaryAddress.length > 0
              ? beneficiary?.ccResponse?.beneficiaryAddress.join(', ')
              : 'No bank address available'}
          </Text>
        </View>
      </View>

      <View style={{ ...styles.card, marginTop: 20 }}>
        <Text style={styles.greenText}>Payment details</Text>
        <View style={styles.containerData}>
          <Text style={styles.text}>Memo</Text>
          <Text style={styles.text}>{payment.memo}</Text>
        </View>
        <View style={styles.containerData}>
          <Text style={styles.text}>Source of funds</Text>
          <Text style={styles.text}>{payment.sourceOfFunds}</Text>
        </View>
      </View>

      <View style={{ ...styles.card, marginTop: 20 }}>
        <Text style={styles.greenText}>Cost breakdown</Text>
        <View style={styles.containerData}>
          <Text style={styles.text}>Recipient name</Text>
          <Text style={styles.text}>{beneficiary.accountHolder}</Text>
        </View>
        {displayConversionRate ? (
          <>
            <View style={styles.containerData}>
              <Text style={styles.text}>{currency} amount</Text>
              <Text style={styles.text}>
                {formatCurrency(payment.amount, currency)}
              </Text>
            </View>

            <View style={styles.containerData}>
              <Text style={styles.text}>USD amount</Text>
              <View style={{ alignItems: 'flex-end' }}>
                <Text style={styles.text}>{formatCurrency(usdAmount)}</Text>
                <Text style={{ fontSize: 10 }}>
                  1 USD = {rateAmount} {currency}
                </Text>
              </View>
            </View>
          </>
        ) : (
          <View style={styles.containerData}>
            <Text style={styles.text}>Amount</Text>
            <Text style={styles.text}>{formatCurrency(usdAmount)}</Text>
          </View>
        )}
        <View style={{ ...styles.containerData, marginTop: 2 }}>
          <Text style={styles.text}>Total paid</Text>
          <Text style={styles.text}>{formatCurrency(usdAmount)}</Text>
        </View>
      </View>
    </>
  );
};

export default IntlWireReceipt;
