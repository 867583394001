import { Route, Routes, useParams } from 'react-router';
import { getEnvironment } from './utilities/url/window-helpers';
import OnboardingStepperPage2 from './areas/onboarding-v2/pages/onboarding-stepper.page';
import LegalPage from 'areas/legal/legal.page';
import CompanySettings from 'areas/company-settings/company-settings';
import NavbarAppShell from '@common/utilities/navbar/navbar-app-shell';
import Banking from '@common/banking';
import { LoadingOverlay } from '@mantine/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PasswordResetPage from 'areas/login/password-reset.page';
import MagicLinkPage from 'areas/login/magic-link.page';
import Credit from 'areas/credit/credit';
import Overview from 'areas/credit/tabs/overview/overview';
import { Net60CreditCardsTable } from 'areas/cards/credit-cards/credit-cards-table';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import Statements from 'areas/cards/statements/credit-statements';
import OnboardedRoutes from '@common/utilities/onboarded-routes';
import Notifications from 'areas/company-settings/notifications/notifications';
import ChangePasswordPage from 'areas/login/change-password.page';
import Integrations from 'areas/company-settings/integrations/integrations';
import TeamMembers from 'areas/team-members/team-members';
import ConfirmationReceipt from 'areas/credit/repayment/confirmation-receipt-pdf';
import UserSettingsList from 'areas/company-settings/personal-profile/user-settings-list';
import CompanySettingsList from 'areas/company-settings/company-settings-list';
import PaymentDashboard from 'areas/payments/pages/dashboard';
import OutgoingPayments from 'areas/payments/pages/outgoing-payments';
import Recipients from 'areas/payments/pages/recipients';
import IncomingPayments from 'areas/payments/pages/incoming-payments';
import SendPaymentPage from 'areas/payments/components/send-payment/send-payment.page';
import PaymentRequestPDF from 'areas/payments/components/payment-request-pdf/payment-request-pdf';
import {
  ProductApplicationRoutes,
  ProductOnboardingRoutes,
} from './constants/onboarding.constants';
import EndPage from 'areas/onboarding-v2/pages/end.page';
import UserAgreements from 'areas/company-settings/terms-of-service/user-agreements';
import ErrorEndScreen from './areas/onboarding-v2/pages/end-states/error-end-screen';
import DeclineShortCircuit from 'areas/onboarding-v2/pages/end-states/declined/declined-short-circuit';

import DeclinedBadFico from './areas/onboarding-v2/pages/end-states/declined/declined-bad-fico';
import DeclinedSoleProp from './areas/onboarding-v2/pages/end-states/declined/declined-sp';
import PDFPaymentReceiptViewer from 'areas/payments/components/payment-receipt/payment-receipt-pdf';
import { ReferralRoute } from '@common/utilities/referral-route';
import { analytics } from './app';
import ProductNavigator from '@common/utilities/product-navigator';
import PendingPayments from 'areas/payments/pages/pending-payments';
import SchedulePayments from 'areas/payments/pages/schedule-payments';
import InvalidPromoCodeShortCircuit from 'areas/onboarding-v2/pages/end-states/declined/invalid-referral-code';
import { InternationPaymentApplicationSuccessPage } from './areas/onboarding-v2/pages/international-payment-application-success.page';
import { ApplicationFlowProvider } from './areas/onboarding-v2/onboarding-hooks';
import ProductApplicationErrorBoundary from './areas/onboarding-v2/pages/onboarding-host.page';
import { BankingApplicationConfig } from 'application/banking-application.config';
import { ApplicationFlowAutoNavigator } from './areas/onboarding-v2/steps/application-flow-auto-navigator';
import { CreditApplicationConfig } from 'application/credit-application.config';
import { AllProductsApplicationConfig } from 'application/all-application.config';
import { InternationalPaymentsApplicationConfig } from 'application/international-payments-application.config';
import Transactions from 'areas/spend-plans/details/tabs/transactions/transactions';
import SpendCards from 'areas/spend-plans/details/tabs/cards/cards';
import { SpendPlanLimits } from 'areas/spend-plans/details/tabs/limits/spend-plan-limits';
import { SpendPlans } from 'areas/spend-plans/spend-plans';
import SpendPlansActive from 'areas/spend-plans/tabs/active';
import SpendPlansInactive from 'areas/spend-plans/tabs/inactive';
import SelfServiceDashboardIntlPayments from 'areas/international-payments/self-service-dashboard-intl-payments';
import { CreateSpendPlan } from 'areas/spend-plans/create/create-spend-plan';
import BankAccountDetails from 'areas/banking/bank-account-details';
import BankingStatements from 'areas/banking/statements/statements';
import IntegrationMappings from './areas/company-settings/integrations/mappings/integration-mappings';
import DebitCards from 'areas/banking/debit-cards/debit-cards';
import BankingTransactions from 'areas/banking/banking-transactions/banking-transactions';
import PaymentsTable from 'areas/payments/components/payments-table/payments-table';
import AccountInformation from 'areas/banking/account-details/account-information';
import WireInstructionsViewer from 'areas/banking/components/wire-instructions/wire-instructions-viewer';
import StartScreen from './areas/onboarding-v2/steps/start-screen/start-screen';
import { PrefillPage } from './areas/onboarding-v2/pages/prefill/prefill.page';
import { PrefillConfirmPhoneStep } from './areas/onboarding-v2/steps/prefill/prefill-confirm-phone.step';
import { PrefillVerifyPhoneStep } from './areas/onboarding-v2/steps/prefill/prefill-verify-phone.step';
import { PrefillConfirmBirthdayStep } from './areas/onboarding-v2/steps/prefill/prefill-confirm-birthday.step';
import { PrefillPersonStep } from './areas/onboarding-v2/steps/prefill/prefill-person.step';
import { PrefillBusinessStep } from './areas/onboarding-v2/steps/prefill/prefill-business.step';
import { ContinueWithApplicationStep } from './areas/onboarding-v2/steps/prefill/continue-with-application.step';
import { PlatformPersonProvider } from './providers/platform-person.context';
import { OnboardingStatusProvider } from './providers/onboarding-status-context';
import { PlatformPrefillProvider } from './providers/platform-prefill.context';
import SpendPlanDetailsView from './areas/spend-plans/details/spend-plan-details';
import CheckDeposits from 'areas/banking/check-deposits/check-deposits';
import { LoginPage } from './areas/login/login.page';
import { refreshAndStoreTokenFromUser } from './utilities/auth/refresh-token';
import { AddFactorsProvider } from './providers/add-factors.context';
import ReserveAccountDetails from 'areas/banking/reserve-account/reserve-account-details';
import { AddAuthenticationFactorPage } from './areas/security-center/pages/factor-setup/add-authentication-factor.page';
import RecipientDetails from 'areas/payments/pages/recipient-details/recipient-details';
import RecipientAccountInformation from 'areas/payments/pages/recipient-details/recipient-account-information';
import ReserveAccountInformation from 'areas/banking/reserve-account/account-information';
import { EditSpendPlan } from 'areas/spend-plans/edit/edit-spend-plan';
import { CallbackPage } from './areas/login/callback.page';
import { InvalidMagicLinkPage } from './areas/login/invalid-magic-link.page';
import { MagicLinkContextProvider } from './areas/login/use-magic-link';
import { LinkLogin } from './areas/login/link-login';
import MoveFunds from 'areas/banking/move-funds/move-funds';
import { platformClient } from './services/platform/platform-client';
import RecipientInternationalAccounts from 'areas/payments/pages/recipient-details/recipient-international-accounts';
import {
  AccountingIntegrationDetails,
  AdsIntegrationDetails,
  CommerceIntegrationDetails,
} from 'areas/company-settings/integrations/details/integration-details';
import AddRecipient from 'areas/payments/pages/add-recipient/add-recipient';
import IntegrationModal from 'areas/company-settings/integrations/components/integration-modal/integration-modal';
import Billpay from 'areas/billpay/billpay';
import Bills from 'areas/billpay/bills/bills';
import BillpayVendors from 'areas/billpay/vendors/vendors';
import CreateInvoice from 'areas/billpay/create-invoice/create-invoice';
import BillpayPayments from 'areas/billpay/payments/payments';
import ChargeCardsOverview from 'areas/charge-account/overview/charge-overview';
import ChargeCardAccount from 'areas/charge-account/charge-account';
import { ChargeCardsTable } from 'areas/charge-account/charge-cards/charge-cards-table';
import ActionRequired from 'areas/billpay/action-required/action-required';
import Playground from 'areas/playground';
import ChargeCardsStatements from 'areas/charge-account/charge-cards-statements/charge-cards-statements';
import ChargePayments from 'areas/charge-account/charge-payments/charge-payments';
import { useRouteSectionContext } from './providers/route-context';
import useAnalyticsPageView from '@services/analytics/use-analytics-page-view';
import { usePolling } from './utilities/polling';
import { useEffect, useState } from 'react';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from './recoil-state/application/product-onboarding';
import BenefitsAndRewardsDashboard from 'areas/benefits-and-rewards/benefits-and-rewards-dashboard';
import Concierge from 'areas/benefits-and-rewards/concierge/concierge';
import { Redemption } from 'areas/benefits-and-rewards/redemption/redemption';
import { RedemptionAccount } from 'areas/benefits-and-rewards/redemption/redemption-steps/redemption-account';
import { RedemptionAmount } from 'areas/benefits-and-rewards/redemption/redemption-steps/redemption-amount';
import { RedemptionReview } from 'areas/benefits-and-rewards/redemption/redemption-steps/redemption-review';
import { RedemptionSuccess } from 'areas/benefits-and-rewards/redemption/redemption-steps/redemption-success';
import BillpayCredit from 'areas/billpay-credit/bill-pay-credit';
import BillpayCreditOverview from 'areas/billpay-credit/tabs/overview/billpay-credit-overview';
import BillPayCreditStatements from 'areas/billpay-credit/tabs/statements/billpay-credit-statements';
import { IsIntegrationsAuthorized } from 'recoil-state/integrations/integrations';
import {
  useSpendPlansFeatureFlag,
  useUnbundledApplicationFeatureFlag,
} from '@utilities/feature-flags';
import BillpayCreditPayments from 'areas/billpay-credit/tabs/credit-payments/bill-pay-credit-payments';
import { upperFirst } from '@mantine/hooks';
import BillpayPaymentReceipt from 'areas/billpay-credit/tabs/credit-payments/payments-table/bill-pay-payment-receipt';
import ChargePaymentReceipt from 'areas/charge-account/charge-payment-receipt/charge-payment-receipt';
import { useAuthToken } from 'providers/auth.provider';
import DebitCardWizard from 'areas/banking/debit-cards/issue-debit-card-wizard/wizard';
import { CreditLineProvider } from 'providers/credit-line-provider';
import { MarqetaCreditCardsTable } from 'areas/cards/credit-cards/marqeta-credit-cards-table';
import { MarqetaOverview } from 'areas/credit/tabs/overview/marqeta-overview';
import QualificationStartPage from 'areas/onboarding-v2/steps/qualification/qualification-start-page';
import QualificationRegisterPage from 'areas/onboarding-v2/steps/qualification/qualification-register-page';
import QualificationCompanyLocationPage from 'areas/onboarding-v2/steps/qualification/qualification-company-location-page';
import QualificationCompanyRevenuePage from 'areas/onboarding-v2/steps/qualification/qualification-company-revenue-page';
import QualificationConnectBankPage from 'areas/onboarding-v2/steps/qualification/qualification-connect-bank-page';
import QualificationConnectAccountToolPage from 'areas/onboarding-v2/steps/qualification/qualification-connect-accounting-tool-page';
import QualificationVerifyPhonePage from 'areas/onboarding-v2/steps/qualification/qualification-verify-phone-page';
import PaymentControlContainer from 'areas/credit/tabs/credit-payment/payment-control-container';
import QualificationCompletedPage from 'areas/onboarding-v2/steps/qualification/qualification-completed-page';
import QualificationApplicationProvider from 'areas/onboarding-v2/qualification-application-provider';
import { QualificationApplicationConfig } from 'application/qualification-application.config';
import { useGetQualificationApplication } from 'areas/onboarding-v2/steps/qualification/use-qualification-application';
import CreditPaymentTab from 'areas/credit/tabs/credit-payment/credit-payment';
import BillpayCreditTabs from 'areas/credit/tabs/credit-payment/billpay-credit-tabs/billpay-credit-tabs';
import OutstandingTable from 'areas/credit/components/outstanding-table/outstanding-table';
import SupportCode from 'areas/company-settings/security/support-code';
import ApplicationUnbundledLandingPage from 'areas/application-unbundled/pages/application-unbundled-landing-page';

const useLocationAwareDocumentTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const splitUrl = location.pathname
      .split('/')
      .filter((p) => !!p)
      .map((p) =>
        p.includes('-')
          ? p
              .split('-')
              .filter((s) => !!s)
              .map(upperFirst)
              .join(' ')
          : upperFirst(p),
      );
    document.title =
      splitUrl.length === 0
        ? 'Flex | Finance Super App'
        : `Flex | ${splitUrl.join(' - ')}`;
  }, [location]);
};

const SpendPlansGuard = () => {
  const isFeatureFlagEnabled = useSpendPlansFeatureFlag();

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

const SettingsIntegrationsGuard = () => {
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);

  if (!isAuthorized) {
    return <Navigate to="/settings" />;
  }

  return <Outlet />;
};
const ApplicationUnbundledGuard = () => {
  const applicationUnbundledFeatureFlag = useUnbundledApplicationFeatureFlag();

  if (!applicationUnbundledFeatureFlag.treatmentReady) {
    return <LoadingOverlay visible />;
  }

  if (!applicationUnbundledFeatureFlag.treatmentOn) {
    return <Navigate to="/" />;
  }

  return <ApplicationUnbundledLandingPage />;
};

const OnboardingRequiredRoutes = () => {
  return (
    <Route element={<OnboardedRoutes />}>
      <Route
        path="/banking/wire-instructions/:accountId"
        element={<WireInstructionsViewer />}
      />
      <Route
        path="/payment/receipt/:paymentId"
        element={<PDFPaymentReceiptViewer />}
      />
      <Route
        path="/bill-pay/receipt/:paymentId"
        element={<BillpayPaymentReceipt />}
      />
      <Route
        path="/charge-card/receipt/:paymentId"
        element={<ChargePaymentReceipt />}
      />
      <Route
        path="/payment/card-receipt/:paymentId"
        element={<ConfirmationReceipt />}
      />
      <Route path="/payment-request-pdf" element={<PaymentRequestPDF />} />
      <Route path="/banking/move-funds" element={<MoveFunds />} />
      <Route element={<NavbarAppShell />}>
        <Route path="/" element={<ProductNavigator />} />
        <Route path="/payments" element={<PaymentDashboard />}>
          <Route index element={<Navigate to="outgoing" />} />
          <Route path="outgoing" element={<OutgoingPayments />} />
          <Route path="outgoing/recipient" element={<SendPaymentPage />} />
          <Route path="outgoing/:paymentId" element={<OutgoingPayments />} />
          <Route path="incoming" element={<IncomingPayments />} />
          <Route path="approvals" element={<PendingPayments />} />
          <Route path="approvals/:id" element={<PendingPayments />} />
          <Route path="recipients" element={<Recipients />} />
          <Route path="scheduled" element={<SchedulePayments />} />
          <Route path="scheduled/:id" element={<SchedulePayments />} />
        </Route>
        <Route
          path="recipient-details/:recipientId"
          element={<RecipientDetails />}
        >
          <Route index element={<Navigate to="payments" replace />} />
          <Route path="payments" element={<OutgoingPayments />} />
          <Route path="payments/:paymentId" element={<PaymentsTable />} />
          <Route path="transactions" element={<BankingTransactions />} />
          <Route
            path="account-information"
            element={<RecipientAccountInformation />}
          />
          <Route
            path="international-accounts"
            element={<RecipientInternationalAccounts />}
          />
          <Route path="bills" element={<Bills />} />
        </Route>
        <Route
          path="/spend-plans"
          element={
            <PlatformPersonProvider>
              <SpendPlansGuard />
            </PlatformPersonProvider>
          }
        >
          <Route element={<SpendPlans />}>
            <Route index element={<Navigate to="active" replace />} />
            <Route path="active" element={<SpendPlansActive />} />
            <Route path="inactive" element={<SpendPlansInactive />} />
          </Route>
          <Route path=":id/details" element={<SpendPlanDetailsView />}>
            <Route index element={<Navigate to="limits" replace />} />
            <Route path="limits" element={<SpendPlanLimits />} />
            <Route path="cards" element={<SpendCards />} />
            <Route path="transactions" element={<Transactions />} />
          </Route>
        </Route>

        <Route
          path="/benefits-and-rewards"
          element={<BenefitsAndRewardsDashboard />}
        ></Route>
        <Route path="/concierge" element={<Concierge />} />

        {/* marqeta */}
        <Route
          path="/credit"
          element={
            <CreditLineProvider issuer="marqeta">
              <Credit />
            </CreditLineProvider>
          }
        >
          <Route index element={<Navigate to="/credit/overview" />} />
          <Route path="overview" element={<MarqetaOverview />} />
          <Route path="details" element={<MarqetaCreditCardsTable />} />
          <Route path="pay" element={<PaymentControlContainer />} />
          <Route path="statements" element={<Statements />} />
        </Route>

        {/* legacy/lithic */}
        <Route path="/cards" element={<Credit />}>
          <Route index element={<Navigate to="/cards/overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route path="details" element={<Net60CreditCardsTable />} />
          <Route path="pay" element={<PaymentControlContainer />}>
            <Route index element={<Navigate to="/cards/pay/credit" />} />
            <Route path="credit" element={<CreditPaymentTab />} />
            <Route path="bill-pay-credit" element={<BillpayCreditTabs />}>
              <Route path="outstanding" element={<OutstandingTable />} />
              <Route
                path="outstanding/:paymentId"
                element={<OutstandingTable />}
              />
            </Route>
          </Route>
          <Route path="statements" element={<Statements />} />
        </Route>

        <Route path="/redemption" element={<Redemption />}>
          <Route path="amount" element={<RedemptionAmount />} />
          <Route path="account" element={<RedemptionAccount />} />
          <Route path="review" element={<RedemptionReview />} />
          <Route path="success" element={<RedemptionSuccess />} />
        </Route>
        <Route path="/charge-card" element={<ChargeCardAccount />}>
          <Route index element={<Navigate to="/charge-card/overview" />} />
          <Route path="overview" element={<ChargeCardsOverview />} />
          <Route path="details" element={<ChargeCardsTable />} />
          <Route path="pay" element={<ChargePayments />} />
          <Route path="statements" element={<ChargeCardsStatements />} />
        </Route>
        <Route path="/team" element={<TeamMembers />} />
        <Route path="/banking">
          <Route index element={<Navigate to={'/banking/accounts'} />} />
          <Route path=":tab" element={<Banking />} />
          <Route
            path="actions-required"
            element={<SelfServiceDashboardIntlPayments />}
          />
          <Route path="account-details/:id" element={<BankAccountDetails />}>
            <Route index element={<Navigate to="transactions" replace />} />
            <Route path="transactions" element={<BankingTransactions />} />
            <Route path="statements" element={<BankingStatements />} />
            <Route path="payments" element={<PaymentsTable />} />
            <Route path="payments/:paymentId" element={<PaymentsTable />} />
            <Route path="payments/recipient" element={<SendPaymentPage />} />
            <Route path="debit-cards" element={<DebitCards />} />
            <Route path="checks" element={<CheckDeposits />} />
            <Route
              path="account-information"
              element={<AccountInformation />}
            />
          </Route>
          <Route path="reserve-account/:id" element={<ReserveAccountDetails />}>
            <Route
              index
              element={<Navigate to="account-information" replace />}
            />
            <Route
              path="account-information"
              element={<ReserveAccountInformation />}
            />
          </Route>
        </Route>
        <Route path="/settings">
          {/* This integrations route is intentionally outside the <CompanySettings /> layout route because we don't want it nested in the tabs */}
          <Route path="integrations" element={<SettingsIntegrationsGuard />}>
            <Route path="mappings" element={<IntegrationMappings />} />
          </Route>
          <Route element={<CompanySettings />}>
            <Route index element={<Navigate to="/settings/profile" />} />
            <Route path="company-profile" element={<CompanySettingsList />} />
            <Route path="profile" element={<UserSettingsList />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="integrations" element={<SettingsIntegrationsGuard />}>
              <Route index element={<Integrations />} />
              <Route
                path="accounting/:connectionId"
                element={<AccountingIntegrationDetails />}
              />
              <Route
                path="ads/:connectionId"
                element={<AdsIntegrationDetails />}
              />
              <Route
                path="ecommerce/:connectionId"
                element={<CommerceIntegrationDetails />}
              />
            </Route>
            <Route path="user-agreements" element={<UserAgreements />} />
            <Route path="security" element={<SupportCode />} />
          </Route>
        </Route>
        <Route path="bill-pay" element={<Billpay />}>
          <Route index element={<Navigate to={'/bill-pay/bills'} />} />
          <Route path="bills" element={<Bills />} />
          <Route path="vendors" element={<BillpayVendors />} />
          <Route path="action-required" element={<ActionRequired />} />
          <Route path="action-required/:id" element={<ActionRequired />} />
          <Route path="payments" element={<BillpayPayments />} />
          <Route path="payments/:paymentId" element={<BillpayPayments />} />
        </Route>

        <Route path="/bill-pay-credit" element={<BillpayCredit />}>
          <Route
            index
            element={<Navigate to={'/bill-pay-credit/overview'} />}
          />
          <Route path="overview" element={<BillpayCreditOverview />} />
          <Route path="credit-payments" element={<BillpayCreditPayments />} />
          <Route path="statements" element={<BillPayCreditStatements />} />
          <Route path="credit-payments" element={<BillpayCreditPayments />} />
        </Route>
      </Route>
      <Route path="emerald/*" element={<Navigate to="/playground" />} />
      <Route path="playground/*" element={<Playground />} />

      {/* These routes intentionally live outside of the app shell */}
      <Route path="bill-pay/new" element={<CreateInvoice />} />
      <Route path="banking/debit-cards/new" element={<DebitCardWizard />} />
      <Route path="bill-pay/:id/edit" element={<CreateInvoice />} />
      <Route
        path="bill-pay/:id/approve"
        element={<CreateInvoice approving />}
      />
      <Route path="recipients/new" element={<AddRecipient />} />
      <Route path="/spend-plans" element={<SpendPlansGuard />}>
        <Route path="new" element={<CreateSpendPlan />} />
        <Route
          path=":id/edit"
          element={
            <PlatformPersonProvider>
              <EditSpendPlan />
            </PlatformPersonProvider>
          }
        />
      </Route>
      <Route
        path="/security/factors"
        element={
          <PlatformPersonProvider>
            <AddFactorsProvider>
              <AddAuthenticationFactorPage />
            </AddFactorsProvider>
          </PlatformPersonProvider>
        }
      />
      <Route path="*" element={<Navigate to={'/'} replace={true} />} />
    </Route>
  );
};

const ApplicationRoutes = () => {
  return (
    <>
      <Route index element={<ApplicationFlowAutoNavigator />} />
      <Route path=":step" element={<OnboardingStepperPage2 />} />
      {/* NOTE: These end states are outside the <EndPage/> because that currently runs a bunch of logic to finish an app*/}
      <Route
        path={`${ProductOnboardingRoutes.END}/declined-short-circuit`}
        element={<DeclineShortCircuit />}
      />
      <Route
        path={`${ProductOnboardingRoutes.END}/declined-fs`}
        element={<DeclinedBadFico />}
      />
      <Route
        path={`${ProductOnboardingRoutes.END}/declined-sp`}
        element={<DeclinedSoleProp />}
      />
      <Route path={`${ProductOnboardingRoutes.END}`} element={<EndPage />} />
      <Route
        path="international-payments-success"
        element={<InternationPaymentApplicationSuccessPage />}
      />
    </>
  );
};

const QualificationApplicationRoutes = () => {
  const { step } = useParams();
  const location = useLocation();
  switch (step) {
    case 'verify-phone':
      return (
        <AddFactorsProvider>
          <QualificationVerifyPhonePage />
        </AddFactorsProvider>
      );
    case 'company-location':
      return <QualificationCompanyLocationPage />;
    case 'company-revenue':
      return <QualificationCompanyRevenuePage />;
    case 'connect-bank':
      return <QualificationConnectBankPage />;
    case 'connect-accounting-tool':
      return <QualificationConnectAccountToolPage />;
    case 'completed':
      return <QualificationCompletedPage />;
    default:
      return (
        <Navigate
          to={{ pathname: 'verify-phone', search: location.search }}
          replace={true}
        />
      );
  }
};

const AuthorizationRequiredRoutes = ({
  location,
  hasIncompleteOrRejectedQualificationApplication,
}: {
  location: Location;
  hasIncompleteOrRejectedQualificationApplication?: boolean;
}) => {
  return (
    <Route element={<ProductApplicationErrorBoundary />}>
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname: hasIncompleteOrRejectedQualificationApplication
                ? '/qualification'
                : ProductApplicationRoutes.COMBINED_CREDIT_BANKING,
              search: location.search,
            }}
            replace={true}
          />
        }
      />
      <Route
        path="/prefill"
        element={
          <PlatformPrefillProvider>
            <OnboardingStatusProvider>
              <AddFactorsProvider>
                <PrefillPage />
              </AddFactorsProvider>
            </OnboardingStatusProvider>
          </PlatformPrefillProvider>
        }
      >
        <Route path="phone" element={<PrefillConfirmPhoneStep />} />
        <Route path="verify-phone" element={<PrefillVerifyPhoneStep />} />
        <Route path="birthday" element={<PrefillConfirmBirthdayStep />} />
        <Route path="identity" element={<PrefillPersonStep />} />
        <Route path="business" element={<PrefillBusinessStep />} />
        <Route path="continue" element={<ContinueWithApplicationStep />} />
      </Route>
      <Route path="/get-started" element={<ApplicationUnbundledGuard />} />
      <Route
        path="/onboarding"
        element={
          <Navigate
            to={{
              pathname: ProductApplicationRoutes.COMBINED_CREDIT_BANKING,
              search: location.search,
            }}
            replace={true}
          />
        }
      />
      <Route
        path={ProductApplicationRoutes.COMBINED_CREDIT_BANKING}
        element={
          <ApplicationFlowProvider config={AllProductsApplicationConfig} />
        }
      >
        {ApplicationRoutes()}
      </Route>
      <Route
        path={ProductApplicationRoutes.BANKING_ONLY}
        element={<ApplicationFlowProvider config={BankingApplicationConfig} />}
      >
        {ApplicationRoutes()}
      </Route>
      <Route
        path={ProductApplicationRoutes.CREDIT_ONLY}
        element={<ApplicationFlowProvider config={CreditApplicationConfig} />}
      >
        {ApplicationRoutes()}
      </Route>
      <Route
        path={ProductApplicationRoutes.INTL_PAYMENTS_ONLY}
        element={
          <ApplicationFlowProvider
            config={InternationalPaymentsApplicationConfig}
          />
        }
      >
        {ApplicationRoutes()}
      </Route>

      <Route
        path="/qualification"
        element={
          <QualificationApplicationProvider
            config={QualificationApplicationConfig}
          />
        }
      >
        <Route
          index
          path="*"
          element={
            <Navigate
              to={{
                pathname: 'verify-phone',
                search: location.search,
              }}
              replace={true}
            />
          }
        />
        <Route path=":step" element={<QualificationApplicationRoutes />} />
      </Route>
    </Route>
  );
};

const NoAuthRoutes = ({
  noAuthUrl,
  location,
}: {
  noAuthUrl: string;
  location: Location;
}) => {
  return (
    <>
      <Route
        path="/qualification"
        element={
          <QualificationApplicationProvider
            config={QualificationApplicationConfig}
          />
        }
      >
        <Route index element={<QualificationStartPage />} />
        <Route path="register" element={<QualificationRegisterPage />} />
      </Route>
      <Route path="/register" element={<StartScreen />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/error" element={<ErrorEndScreen />} />
      <Route path="/forgot-password" element={<MagicLinkPage />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route
        path="/"
        element={
          <Navigate
            to={{ pathname: noAuthUrl, search: location.search }}
            replace={true}
          />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={{ pathname: noAuthUrl, search: location.search }}
            replace={true}
          />
        }
      />
      <Route path="/declined-short-circuit" element={<DeclineShortCircuit />} />
      <Route path="/referral/:code" element={<ReferralRoute />} />
      <Route
        path="/referral/missing"
        element={<InvalidPromoCodeShortCircuit />}
      />
      <Route
        path="/link"
        element={
          <MagicLinkContextProvider>
            <Outlet />
          </MagicLinkContextProvider>
        }
      >
        <Route path="callback" element={<CallbackPage />} />
        <Route path="reset-password" element={<PasswordResetPage />} />
        <Route path="magic-login" element={<PasswordResetPage />} />
        <Route path="login" element={<LinkLogin />} />
        <Route path="invite" element={<LinkLogin />} />
      </Route>
      <Route path="/link/error" element={<InvalidMagicLinkPage />} />
    </>
  );
};

function FlexbaseRoutes() {
  // analytics - DONT REMOVE
  const hostEnv = getEnvironment();
  useAnalyticsPageView(hostEnv);
  useLocationAwareDocumentTitle();
  const [searchParams] = useSearchParams();
  const segmentAnon = searchParams.get('segment_anon');
  if (segmentAnon) {
    analytics.setAnonymousId(segmentAnon).catch((err) => {
      console.error(`segment anon error ${err}`);
    });
  }
  const [loading, setLoading] = useState(true);
  const setUserAndCompanyInfo = useSetRecoilState(ApplicationState);
  const {
    data: qualificationApplication,
    isLoading: isQualificationApplicationLoading,
  } = useGetQualificationApplication({ refetchOnWindowFocus: true });

  const isLoading = isQualificationApplicationLoading || loading;
  const hasIncompleteOrRejectedQualificationApplication =
    qualificationApplication?.status === 'Incomplete' ||
    qualificationApplication?.status === 'Rejected';
  const { logout, clearToken, userIsAuthorized, user } = useAuthToken();
  const { showRoutesFor, setShowRoutesFor } = useRouteSectionContext();

  const { startPolling, hasExistingPoll } = usePolling();

  const loadState = async () => {
    if (userIsAuthorized) {
      try {
        const status = await getProductOnboardingStatus(false);
        setUserAndCompanyInfo(status);
        if (status.completedOnboarding) {
          // The following line is purely to see if MFA is valid. It is under the above if check because if a user has not completed onboarding, the MFA check may always fail depending on where they got in the application
          await platformClient.getMe();
          setShowRoutesFor('main');
        } else {
          setShowRoutesFor('application');
        }
      } catch (e) {
        setShowRoutesFor('auth');
        logout();
      } finally {
        setLoading(false);
      }
    } else {
      clearToken();
      setShowRoutesFor('auth');
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    loadState();
  }, [userIsAuthorized]);

  useEffect(() => {
    if (
      showRoutesFor !== 'auth' &&
      userIsAuthorized &&
      !hasExistingPoll('REFRESH_TOKEN')
    ) {
      startPolling(
        'REFRESH_TOKEN',
        async () => {
          // TODO We should likely do this from use-auth-token hook so that auth state values remain fresh
          const result = await refreshAndStoreTokenFromUser(true);
          if (!result) {
            logout();
          }
        },
        60 * 1000,
      );
    }
  }, [userIsAuthorized, showRoutesFor]);

  return (
    <div>
      <LoadingOverlay visible={isLoading} style={{ position: 'fixed' }} />
      {!isLoading && (
        <Routes>
          <Route path="/oauth">
            <Route path="intuit" element={<IntegrationModal />} />
            <Route index element={<Navigate to="/" />} />
          </Route>
          {showRoutesFor === 'main' && OnboardingRequiredRoutes()}
          {showRoutesFor === 'application' &&
            AuthorizationRequiredRoutes({
              hasIncompleteOrRejectedQualificationApplication,
              location,
            })}
          {showRoutesFor === 'auth' &&
            NoAuthRoutes({
              noAuthUrl:
                user.remember || sessionStorage.getItem('logout')
                  ? '/login'
                  : '/register',
              location,
            })}
          <Route path="/legal" element={<LegalPage />} />
        </Routes>
      )}
    </div>
  );
}

export default FlexbaseRoutes;
