import { FileWithPath } from '@mantine/dropzone';
import { platformClient } from '../services/platform/platform-client';
import { useMutation, useQuery } from '@tanstack/react-query';

type CreateInvoiceDocumentArgs = {
  type: string;
  file: FileWithPath;
  runOcr: boolean;
  formatType: string;
};

export function useCreateInvoiceDocument() {
  return useMutation({
    mutationFn: (args: CreateInvoiceDocumentArgs) => {
      return platformClient.createInvoiceDocument(args);
    },
  });
}

const QUERY_KEY = 'getDocument';

export const useGetDocument = (docId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, docId],
    queryFn: async () => {
      return await platformClient.getDocument(docId);
    },
    meta: {
      errorMessage: 'We are unable to get the document at the moment.',
    },
    enabled: !!docId,
  });
};
