import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type Props = {
  credit?: boolean;
};

export const useStyles = createStyles((theme, { credit }: Props) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      margin: `0 0 ${rem(18)} 0`,
    },
  },
  creditPaymentContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    display: 'flex',
    backgroundColor: theme.colors.neutral[0],
    height: rem(200),
    width: '100%',
    minWidth: rem(420),
    maxWidth: 'initial',
    flexDirection: 'column',
    padding: rem(24),
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
      height: 'fit-content', // For whatever reason, mobile 200px is ignored?
    },
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amountDueLabel: {
    fontSize: rem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(19),
    color: theme.colors.neutral[8],
  },
  amountDue: {
    flexGrow: 1,
    fontSize: rem(26),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(31),
    color: theme.colors.primary[8],
    marginBottom: rem(34),
    fontFamily: 'PP Neue Montreal',
  },
  currentBalance: {
    fontSize: rem(14),
    fontWeight: 400,
    color: theme.colors.neutral[7],
  },
  daysTilDueContainer: {
    background: theme.colors.warning[1],
    width: rem(119),
    height: rem(28),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.defaultRadius,
  },
  aprContainer: {
    background: theme.colors.neutral[2],
    width: rem(83),
    height: rem(28),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.defaultRadius,
    marginRight: theme.spacing.sm,
  },
  daysTilDue: {
    fontSize: rem(14),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(16),
    color: 'rgba(7, 28, 20, 0.80)',
  },
  actionButtonsContainer: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: credit ? 'normal' : 'space-between',
  },
  payEarly: {
    height: rem(40),
    width: rem(100),
    padding: `${rem(12)} ${rem(20)} ${rem(12)} ${rem(20)}`,
  },
  payEarlyText: {
    color: 'rgb(252,239,237)',
    fontSize: rem(14),
    fontWeight: 500,
    fontStyle: 'medium',
    lineHeight: rem(16),
  },
  autopay: {
    display: 'flex',
    justifyContent: 'space-between',
    width: rem(155),
  },
  autopayLabel: {
    padding: rem(3),
  },
  autopayLabelText: {
    color: theme.colors.neutral[8],
    fontSize: rem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(16),
    textAlign: 'end',
  },
  autopaySchedule: {
    color: '#979797',
    fontSize: rem(14),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: rem(16),
  },
  learnAboutRewards: {
    background: theme.colors.neutral[0],
    width: rem(171),
    height: rem(40),
    border: `1px solid #979797`,
    borderRadius: rem(8),
    marginLeft: rem(10),
    color: theme.colors.neutral[8],
    fontSize: rem(14),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: rem(16),
    '&:hover': {
      border: 'none',
      background: 'lightgray',
    },
  },
  learnAboutRewardsText: {
    color: theme.colors.neutral[8],
    fontSize: rem(14),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: rem(16),
  },
  billDate: {
    color: theme.colors.neutral[7],
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    fontSize: rem(14),
    marginTop: theme.spacing.xs,
  },
}));
