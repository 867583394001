import { Select } from '@mantine/core';
import { SummaryEditInput } from './summary-edit-input';
import { CompanyInfoFormValues } from '../summary';
import { UseFormReturnType } from '@mantine/form';
import { BUSINESS_ANNUAL_REVENUE } from 'constants/constants';

type Props = {
  value: string;
  form: UseFormReturnType<CompanyInfoFormValues>;
  isEditMode: boolean;
};

const SummaryEstimatedRevenueForm = ({ value, form, isEditMode }: Props) => {
  return (
    <SummaryEditInput
      label="Estimated annual revenue"
      value={value}
      isEditMode={isEditMode}
      editInputs={
        <Select
          label="Annual revenue"
          id="business-revenue"
          placeholder="Select range"
          {...form.getInputProps('annualRevenue')}
          data={BUSINESS_ANNUAL_REVENUE}
        />
      }
    />
  );
};

export default SummaryEstimatedRevenueForm;
