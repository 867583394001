import { CreditTransactions } from '@common/charge-and-credit-cards/credit-transactions';
import CreditTransactionsSm from '@common/charge-and-credit-cards/credit-transactions-sm';
import { useNet60MarqetaTransactions } from '@queries/use-credit-transactions';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { CreditActionAlertBar } from 'areas/credit/components/credit-action-alert-bar/credit-action-alert-bar';
import {
  useMarqetaTransactionsFilters,
  useMarqetaTransactionsModalFilters,
} from 'areas/credit/components/credit-transactions/credit-transactions-header/use-credit-transaction-filters';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAccountant,
  IsAdmin,
} from 'recoil-state/application/product-onboarding';
import { useStyles } from './overview.styles';
import LastTransaction from 'areas/credit/components/last-transaction/last-transaction';
import SpentWeek from 'areas/credit/components/spent-this-week/spent-this-week';
import { isGraceDayEarlyPayConfig } from 'types/onboarding-info';
import { EarlyPayProjectedDueCard } from '@common/charge-and-credit-cards/early-pay-projected-due-card';
import { useEffect, useState } from 'react';
import CreditLimit from 'areas/credit/components/credit-limit/credit-limit';
import CreditPaymentCard from 'areas/credit/components/credit-payment/credit-payment';
import { useCreditLineContext } from 'providers/credit-line-provider';
import { Box } from '@mantine/core';

export const MarqetaOverview = () => {
  const { classes, cx } = useStyles();
  const { accountId, company, user } = useRecoilValue(ApplicationState);
  const isMobile = useMatchMedia('(max-width: 767px)');
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const isOwner = user.roles.some((role) => role === 'OWNER');

  const { line } = useCreditLineContext();
  const dataQuery = useNet60MarqetaTransactions(accountId, {
    params: {
      lineOfCreditId: line?.id,
    },
  });
  const filterHook = useMarqetaTransactionsFilters();
  const modalFilterHook = useMarqetaTransactionsModalFilters();

  const [hasMadePayment, setHasMadePayment] = useState(false);

  const userIsElevated = isAdmin || isAccountant || isOwner;

  useEffect(() => {
    // TODO: handle this whenever min due is implemented
    const getCompanyBalance = async () => {
      // const result = await flexbaseOnboardingClient.getMinimumDue(company.id);
      // setHasMadePayment();
      setHasMadePayment(false);
    };
    getCompanyBalance();
  }, []);

  return (
    <>
      <CreditActionAlertBar />

      <div className={classes.creditContainer}>
        {userIsElevated ? (
          <Box className={classes.widgetContainer}>
            <Box className={classes.widget}>
              <CreditLimit hasPendingPayment={hasMadePayment} />
            </Box>
            {isGraceDayEarlyPayConfig(company.earlypay) && (
              <Box className={cx(classes.monaySpentContainer, classes.widget)}>
                <EarlyPayProjectedDueCard earlyPayConfig={company.earlypay} />
              </Box>
            )}
            <Box className={classes.widget}>
              <CreditPaymentCard disablePayNowBtn={false} />
            </Box>
          </Box>
        ) : (
          <>
            <LastTransaction />
            <SpentWeek />
          </>
        )}
      </div>

      {isMobile ? (
        <CreditTransactionsSm lineOfCredit="marqeta" dataQuery={dataQuery} />
      ) : (
        <CreditTransactions
          lineOfCredit="marqeta"
          dataQuery={dataQuery}
          filterHook={filterHook}
          modalFilterHook={modalFilterHook}
        />
      )}
    </>
  );
};
