import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Text, rem } from '@mantine/core';
import { getMonthDayYear, sortDate } from '@utilities/dates';
import GenericError from '@common/generic-error';
import TabTableLayout from '@common/layouts/tab-table-layout';
import CheckDepositsTable from './components/check-deposits-table';
import { useGetCheckDeposits } from '@queries/use-checks-deposits';
import { formatCents } from '@utilities/formatters';
import { formatCheckStatus } from '@utilities/formatters/format-checks-status';
import CheckDepositLeftHeader from './components/check-deposits-filters';
import { useCheckDepositFilters } from './components/filters/use-check-deposits-filters';

export type ChecksTable = {
  id: string;
  status: string;
  amount: number | string;
  createdAt: string;
  description: string;
  counterparty: string;
};

const CheckDeposits = () => {
  const { id: depositAccountId } = useParams();
  const { activeFiltersArray } = useCheckDepositFilters();

  const {
    data: checkDepositsData,
    isLoading,
    isError,
    refetch,
  } = useGetCheckDeposits({ depositAccountId });

  const filteredData = useMemo<ChecksTable[]>(() => {
    return (
      checkDepositsData
        ?.map((check) => ({
          id: check.id,
          createdAt: getMonthDayYear(check?.createdAt),
          description: check.description,
          amount: formatCents(check.amount).toFixed(2),
          status: formatCheckStatus(check.status),
          counterparty: check.counterparty?.name ?? 'N/A',
        }))
        .filter((t) => activeFiltersArray.every((f) => f.fn(t)))
        .sort((a, b) => sortDate(a, b, false)) ?? []
    );
  }, [checkDepositsData, activeFiltersArray]);

  if (isError) {
    return (
      <GenericError>
        <Text ta="center">
          An unhandled error occurred getting the checks list.
        </Text>
        <Button variant="primary-light" onClick={() => refetch()} mt={rem(20)}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <TabTableLayout
      leftHeaderContent={<CheckDepositLeftHeader />}
      borderBottomSolid={false}
      rightHeaderContent={undefined}
      table={
        <CheckDepositsTable
          checkDeposits={filteredData}
          isLoading={isLoading}
        />
      }
    />
  );
};

export default CheckDeposits;
