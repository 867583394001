import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  headerRow1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: rem(35),
    backgroundColor: theme.colors.primary[8],
    '> div:last-of-type': {
      marginRight: rem(-11),
    },
    flexWrap: 'nowrap',
  },
  title: {
    height: rem(44),
    color: theme.colors.neutral[0],
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(32),
    '@media(max-width: 767px)': {
      fontSize: rem(22.88),
    },
  },
  closeButton: {
    display: 'flex',
    width: rem(33.92),
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: '#979797',
    cursor: 'pointer',
    borderRadius: rem(80),
    alignItems: 'center',
    backgroundColor: 'unset',
    border: `1px solid transparent`,
    '&:focus': {
      outline: 'none',
    },
    svg: {
      width: rem(33.92),
      height: rem(33.92),
    },
    '@media(max-width: 767px)': {
      display: 'flow',
      svg: {
        width: rem(25.92),
        height: rem(25.92),
      },
    },
  },
  select: {
    background: theme.colors.neutral[3],
    borderRadius: theme.radius.md,
  },
  selectContainer: {
    background: theme.colors.neutral[0],
    borderRadius: theme.radius.md,
  },
  addressInput: { marginTop: theme.spacing.md },
  buttonGroup: {
    justifyContent: 'right',
  },
}));
