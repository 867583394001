import { Group, rem, TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { PropsWithChildren, useState } from 'react';
import ExternalAccountsTable from './external-accounts-table';
import { useStyles } from './external-accounts.styles';
import { PiMagnifyingGlass } from 'react-icons/pi';

type Props = PropsWithChildren & {
  tableData: any;
  primaryButton: JSX.Element;
};

const ExternalAccountsContent = ({
  tableData,
  primaryButton,
  children,
}: Props) => {
  const { classes, theme } = useStyles();
  const [filterText, setFilterText] = useState('');
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className={classes.container}>
      {children}
      <Group
        justify="space-between"
        style={{ flexDirection: isMobile ? 'column' : 'initial' }}
      >
        <TextInput
          w={isMobile ? '100%' : rem(500)}
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              color={theme.colors.neutral[8]}
            />
          }
          placeholder="Search external accounts"
          onChange={(e) => setFilterText(e.target.value)}
        />
        {primaryButton}
      </Group>
      <div className={classes.tableContainer}>
        <ExternalAccountsTable searchText={filterText} accounts={tableData} />
      </div>
    </div>
  );
};

export default ExternalAccountsContent;
