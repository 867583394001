import { createStyles } from '@mantine/emotion';

export const useOverviewStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    padding: theme.spacing.xl,
    borderRadius: theme.defaultRadius,
    color: theme.colors.neutral[2],
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
}));
