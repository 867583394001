import { createStyles } from '@mantine/emotion';

export const useVerifyPhoneStyles = createStyles((theme) => ({
  txtPhoneNumber: {
    color: theme.colors.neutral[10],
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    marginBottom: '4px',
  },

  txtApplicationAgree: {
    textAlign: 'center',
    color: '#8D8D8D',
    fontSize: '10px',
    marginTop: '1rem',
  },

  description: {
    fontFamily: 'PP Neue Montreal',
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 400,
  },

  containerSelected: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #D1D5DB',
    padding: 16,
    borderRadius: '6px',
    height: 50,
    cursor: 'pointer',
    position: 'relative',
  },

  containerList: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    border: '1px solid #D1D5DB',
    padding: 5,
    borderRadius: '8px',
    background: theme.colors.neutral[0],
  },

  containerOptions: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    cursor: 'pointer',
    ':hover': {
      background: '#FCFCFC',
    },
  },
  phoneInputRoot: {
    flex: 1,
    height: 42,
  },
  phoneInputInput: {
    fontSize: '16px',
    borderRadius: '6px',
    fontWeight: 400,
  },
  descriptionLabel: {
    fontFamily: 'PP Neue Montreal',
    color: '#5F5F5F',
    fontSize: 14,
    fontWeight: 400,
  },

  inputContainer: {
    display: 'flex',
    gap: '12px',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  sendCodeButton: {
    width: 170,
    height: 42,
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
}));
