import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  frozen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    borderRadius: theme.defaultRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(48, 44, 255, 0.03)',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  unfreezeButton: {
    width: rem(242),
    lineHeight: rem(28),
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    fontSize: theme.spacing.sm,
    backgroundColor: theme.colors.promote[1],
  },
  payNow: {
    cursor: 'pointer',
    textDecoration: 'underline',
    background: 'transparent',
    border: 0,
  },
  unfreezePlaidBtn: {
    textAlign: 'center',
    color: theme.colors.neutral[0],
    width: rem(93),
    padding: theme.spacing.xs,
    lineHeight: rem(28),
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.primaryColor,
    fontSize: rem(14),
    fontFamily: theme.fontFamily,
  },
}));
