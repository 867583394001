import { ApplicationConfigBuilder } from './application-config-builder';

const configBuilder = new ApplicationConfigBuilder(['CREDIT', 'BANKING'])
  .forRequirementArray('requiredCredit')
  .forRequirementArray('requiredBanking');

configBuilder.addStep('change-password').withRequirement('user.changePassword');

configBuilder
  .addStep('verify-phone')
  .withRequirement('user.phone')
  .withRequirement('user.cellPhone')
  .withRequirement('user.verifyPhone');

configBuilder
  .addStep('business-type', true)
  .withRequirement('company.legalStructure');

configBuilder.addStep('user-address').withRequirement('user.address');

configBuilder
  .addStep('verify-identity')
  .withRequirement('user.taxId')
  .withRequirement('user.birthDate')
  .withRequirement('user.ficoPullSigned')
  .withRequirement('user.patriotActSigned');

configBuilder.addStep('job-title').withRequirement('user.jobTitle');

configBuilder
  .addStep('business-activity', true)
  .withRequirement('company.companyName')
  .withRequirement('company.website')
  .withRequirement('company.annualRevenue')
  .withRequirement('company.businessPurpose')
  .withRequirement('company.category')
  .withRequirement('company.businessVertical');

configBuilder
  .addStep('verify-business', true)
  .withRequirement('company.taxId')
  .withRequirement('company.formationDate')
  .withRequirement('company.phone')
  .withRequirement('company.address');

configBuilder
  .addStep('business-owners', true)
  .withRequirement('company.owners');

configBuilder
  .addStep('control-person', true)
  .withRequirement('company.controlPerson');

configBuilder
  .addStep('verify-bank', true)
  .withRequirement('user.plaidConnection')
  .withRequirement('company.financialInstitutions');

configBuilder.addStep('connect-accounting-tool', true);

configBuilder
  .addStep('summary')
  .withRequirement('user.termsOfServiceSigned')
  .withRequirement('user.creditTermsOfServiceSigned')
  .withRequirement('user.bankingTermsOfServiceSigned')
  .withRequirement('user.personalGuarantySigned');

export const AllProductsApplicationConfig = configBuilder.build();
