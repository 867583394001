import { CreateSpendPlanFrequencyEnum } from '@flexbase-eng/types/dist/accounting';
import { ComboboxItem } from '@mantine/core';

export const RecurringSelectItems: ComboboxItem[] = [
  {
    label: 'Daily',
    value: CreateSpendPlanFrequencyEnum.Daily,
  },
  {
    label: 'Monthly',
    value: CreateSpendPlanFrequencyEnum.Monthly,
  },
  {
    label: 'Annually',
    value: CreateSpendPlanFrequencyEnum.Annually,
  },
] satisfies (ComboboxItem & {
  value: Exclude<CreateSpendPlanFrequencyEnum, 'onetime'>;
})[];
