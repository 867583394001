import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { PiArrowRightBold, PiXCircleBold } from 'react-icons/pi';
import {
  Box,
  rem,
  Flex,
  Text,
  Alert,
  Button,
  Select,
  TextInput,
  ComboboxData,
} from '@mantine/core';
import { ModifiedLineTransaction } from '../outstanding-table';
import { getUTCDateFormatFromISO } from '@utilities/dates';

type BillPayCreditPaymentInfoProps = {
  formattedTotal: string;
  paymentAccountId?: string;
  hasSufficientFunds: boolean;
  paymentAccounts: ComboboxData;
  transaction: ModifiedLineTransaction;
  onNextStep: () => void;
  onPaymentAccountChange: (accountId: string) => void;
};

const BillPayCreditPaymentInfo = ({
  formattedTotal,
  transaction,
  paymentAccounts,
  paymentAccountId,
  hasSufficientFunds,
  onNextStep,
  onPaymentAccountChange,
}: BillPayCreditPaymentInfoProps) => {
  const { paymentDate, billpayInvoiceId } = transaction;
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      paymentAccountId: paymentAccountId ?? '',
    },
    validate: {
      paymentAccountId: (value) =>
        value === '' ? 'Please select a payment account' : null,
    },
  });

  const formValues = form.values;
  const insufficientFundsError =
    formValues.paymentAccountId && !hasSufficientFunds;

  const detailsData = [
    {
      label: 'Payment date',
      value: getUTCDateFormatFromISO(paymentDate ?? ''),
    },
    {
      label: 'Invoice details',
      value: 'View invoice details',
    },
  ];

  const handlePaymentAccountChange = (value: string | null) => {
    if (value) {
      onPaymentAccountChange(value);
      form.setFieldValue('paymentAccountId', value);
    }
  };

  const isButtonDisabled =
    insufficientFundsError || !!form.errors.paymentAccountId;

  const handleNextStep = () => {
    const validateForm = form.validate();
    if (insufficientFundsError || validateForm.hasErrors) {
      return;
    }
    onNextStep();
  };

  const handleInvoiceDetailsClick = () => {
    navigate(`/bill-pay/${billpayInvoiceId}/edit?step=review-pay`);
  };

  return (
    <>
      <Box>
        <Text mb="xl" size="lg">
          Payment information
        </Text>
        <Flex justify="space-between" gap="xl">
          <TextInput
            w="100%"
            label="Amount due"
            readOnly
            value={formattedTotal}
          />
          <Select
            w="100%"
            label="Payment method"
            placeholder="Select a payment account"
            data={paymentAccounts}
            {...form.getInputProps('paymentAccountId')}
            onChange={handlePaymentAccountChange}
            error={form.errors.paymentAccountId || insufficientFundsError}
          />
        </Flex>
        <Text my="xl" size="lg">
          Payment details
        </Text>
        <Flex
          p="xl"
          gap="md"
          bg="neutral.0"
          direction="column"
          sx={(theme) => ({
            borderRadius: theme.defaultRadius,
            border: `1px solid ${theme.colors.neutral[4]}`,
          })}
        >
          {detailsData.map((item) => (
            <Flex key={item.label} justify="space-between" align="center">
              <Text size="sm" c="neutral.8">
                {item.label}
              </Text>
              {item.label === 'Invoice details' ? (
                <Text
                  size="sm"
                  c="primary.6"
                  td="underline"
                  onClick={handleInvoiceDetailsClick}
                >
                  {item.value}
                </Text>
              ) : (
                <Text size="sm">{item.value}</Text>
              )}
            </Flex>
          ))}
        </Flex>
      </Box>
      {insufficientFundsError && (
        <Alert
          mt="md"
          color="red"
          withCloseButton={false}
          icon={<PiXCircleBold size="1.5rem" />}
        >
          This account does not have enough funds to cover the payment
        </Alert>
      )}
      <Flex mt={rem(40)} justify="flex-end">
        <Button
          variant="primary-filled"
          onClick={handleNextStep}
          disabled={isButtonDisabled}
          rightSection={<PiArrowRightBold />}
        >
          Review payment
        </Button>
      </Flex>
    </>
  );
};

export default BillPayCreditPaymentInfo;
