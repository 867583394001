import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { BillpayCreditPaymentsTable } from './billpay-credit-overview';
import { formatCents, formatCurrency } from '@utilities/formatters';
import {
  Avatar,
  Badge,
  Box,
  Group,
  LoadingOverlay,
  rem,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { getBillPayStatusDisplay } from '@utilities/get-bill-pay-status-display';
import { BillPayStatus } from 'types/bill-pay';
import { getInitialsOfNames } from '@utilities/object';
import { useGetPlatformDocument } from '@queries/use-get-platform-document';
import DocumentViewer from '@common/document-viewer';
import { DateTime } from 'luxon';
import { createStyles } from '@mantine/emotion';
import { PiXLight } from 'react-icons/pi';

const formatDate = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
};

type Props = {
  details: BillpayCreditPaymentsTable;
  closeModal: () => void;
};

const DrawdownDetailsModal = ({ details, closeModal }: Props) => {
  const { classes } = useStyles();
  const {
    data: documentData,
    isLoading: isDocumentLoading,
    error: documentError,
  } = useGetPlatformDocument(details?.documentId || '');

  return (
    <RightContentModal>
      <RightContentModal.Header
        titleSize={30}
        title={<ModalTitle amount={details.amount} status={details.status} />}
        rightTitle={
          <UnstyledButton c="neutral.0" onClick={closeModal}>
            <PiXLight size={'2rem'} />
          </UnstyledButton>
        }
      />
      <RightContentModal.Body>
        <Stack>
          <Group>
            <Stack gap={0} className={classes.dataBlock}>
              <Text size="sm" color="neutral.8">
                Pending date
              </Text>
              <Text fw="500">{formatDate(details.date)}</Text>
            </Stack>
            <Stack gap={0} className={classes.dataBlock}>
              <Text size="sm" color="neutral.8">
                Settled date
              </Text>
              <Text fw="500">{formatDate(details.date)}</Text>
            </Stack>
          </Group>
          <Group>
            <Stack gap={0} className={classes.dataBlock}>
              <Text size="sm" color="neutral.8">
                Vendor
              </Text>
              <Group align="center" gap="xs">
                <Avatar radius="xl" size={32} sx={{ alignItems: 'center' }}>
                  <Text size="xs" color="neutral.7" fw="bold">
                    {getInitialsOfNames(details.vendor ?? '')}
                  </Text>
                </Avatar>
                <Text fw="500">{details.vendor}</Text>
              </Group>
            </Stack>
            <Stack gap={0} className={classes.dataBlock}>
              <Text size="sm" color="neutral.8">
                Accounting category
              </Text>
              <Text fw="500">2010 - Software Services</Text>
            </Stack>
          </Group>
          <Stack className={classes.dataBlock}>
            <Text size="sm" color="neutral.8">
              Associated invoice
            </Text>
            <Box className={classes.documentWrapper} pos="relative">
              {isDocumentLoading && <LoadingOverlay visible />}

              {documentError && (
                <Stack
                  h="100%"
                  w="100%"
                  bg="white"
                  justify="center"
                  align="center"
                >
                  <Text color="critical.2">Error loading document</Text>
                </Stack>
              )}

              {documentData && (
                <DocumentViewer
                  document={documentData as File}
                  altText="invoice document"
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </RightContentModal.Body>
    </RightContentModal>
  );
};

const ModalTitle = ({
  amount,
  status,
}: {
  amount: string;
  status: BillPayStatus;
}) => {
  const { displayStatus, backgroundColor, textColor } =
    getBillPayStatusDisplay(status);
  return (
    <Group>
      <Text fz={rem(40)}>{formatCurrency(formatCents(amount))}</Text>
      <Badge
        styles={{
          root: {
            backgroundColor,
          },
        }}
      >
        <Text color={textColor}>{displayStatus} </Text>
      </Badge>
    </Group>
  );
};
const useStyles = createStyles((theme, _, helpers) => ({
  documentWrapper: {
    height: rem(788),
    width: '100%',
  },
  dataBlock: {
    flex: '1 1 0',
  },
  body: {
    padding: `${theme.spacing.xl} ${rem(32)}`,
    display: 'flex',
    flexDirection: 'column',
    [helpers.smallerThan('sm')]: {
      overflowX: 'hidden',
      padding: theme.spacing.sm,
    },
  },
}));

export default DrawdownDetailsModal;
