import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

const ICON_SIZE = rem(32);

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    maxWidth: rem(1307.5),
    margin: 'auto',
  },
  widgetContainer: {
    flexDirection: 'row',
    backgroundColor: theme.colors.neutral[0],
    padding: rem(24),
    '@media(max-width: 767px)': {
      width: '100%',
      padding: theme.spacing.md,
    },
  },
  storeName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
    maxWidth: rem(220),
  },
  storeIcon: {
    fontSize: rem(20),
    color: theme.colors[theme.primaryColor][6],
    '> div:first-of-type': {
      color: `${theme.colors[theme.primaryColor][6]} !important`,
      backgroundColor: `${theme.colors.neutral[3]} !important`, // TODO change this shit
      fontWeight: 500,
      fontFamily: 'PP Neue Montreal',
    },
    marginRight: rem(10),
    height: ICON_SIZE,
    width: ICON_SIZE,
  },
  storeLogo: {
    borderRadius: rem(34),
    width: ICON_SIZE,
    height: ICON_SIZE,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: rem(10),
  },
  date: {
    fontSize: rem(14),
    fontWeight: 400,
    color: theme.colors.neutral[8],
    lineHeight: rem(21),
  },
  status: {
    padding: `${rem(4)} ${rem(16)} ${rem(4)} ${rem(16)}`,
    background: '#EEEEF3',
    fontSize: rem(14),
    fontWeight: 500,
    width: rem(105),
    height: rem(32),
    lineHeight: rem(24),
    textAlign: 'center',
    borderRadius: rem(100),
    color: theme.colors.neutral[10],
  },
  transactionRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xs,
    gap: theme.spacing.xs,
    width: '100%',
  },
  transactionYearRow: {
    backgroundColor: theme.colors.neutral[0],
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xs,
    gap: theme.spacing.xs,
    borderRadius: theme.defaultRadius,
    height: rem(76),
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(14),
    lineHeight: rem(20),
  },
  showMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${rem(8)} ${rem(4)}`,
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(16),
    lineHeight: rem(20),
    cursor: 'pointer',
    height: rem(50),
  },
  closeIcon: {
    color: '#979797',
    cursor: 'pointer',
  },
}));
