import { Button, Text, useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';
import { useStyles } from './styles';
import { ArrowRight } from '../../../assets/svg';
import { useRedemptionMultistepFormContext } from './redemption-multi-step-form.context';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  children: ReactNode;
  title?: string;
  subtitle?: ReactNode;
  nextButtonText: string;
};

export const RedemptionStepper = (props: Props) => {
  const { children, title, subtitle, nextButtonText } = props;
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const {
    goToNextStep,
    goToPreviousStep,
    hasPreviousStep,
    hasNextStep,
    currentStep,
  } = useRedemptionMultistepFormContext();
  const navigate = useNavigate();

  const goBack = () => {
    if (hasPreviousStep) {
      goToPreviousStep();
    } else {
      navigate('/benefits-and-rewards');
    }
  };

  const goForward = () => {
    if (hasNextStep) {
      goToNextStep();
    } else {
      navigate('/benefits-and-rewards');
    }
  };

  return (
    <div className={useMobileView ? classes.amountMobile : classes.amount}>
      {title && <Text className={classes.amountTitle}>{title}</Text>}
      {subtitle && <Text className={classes.amountSubtitle}>{subtitle}</Text>}
      {children}

      <div
        className={
          currentStep?.route === 'success'
            ? classes.successNavigationButtons
            : classes.redemptionNavigationButtons
        }
      >
        {currentStep?.route !== 'success' && (
          <Button
            variant="neutral-outline"
            className={classes.redemptionBackButton}
            onClick={goBack}
          >
            Back
          </Button>
        )}
        {/*<Button*/}
        {/*  variant="neutral-outline"*/}
        {/*  className={classes.redemptionBackButton}*/}
        {/*  onClick={goBack}*/}
        {/*>*/}
        {/*  Back*/}
        {/*</Button>*/}
        <Button
          variant="default"
          className={classes.redemptionForwardButton}
          onClick={goForward}
        >
          {nextButtonText}
          <ArrowRight style={{ marginLeft: theme.spacing.sm }}></ArrowRight>
        </Button>
      </div>
    </div>
  );
};
