import {
  useCheckDepositFilters,
  useCheckDepositModalFilters,
} from './filters/use-check-deposits-filters';
import { CheckDepositsStatusFilter } from './filters/check-deposits-status-filter';
import { CheckDepositsAmountFilter } from './filters/check-deposits-amount-filter';
import { CheckDepositsDateRangeFilter } from './filters/check-deposits-date-range.filter';
import {
  PiCalendarBlank,
  PiCurrencyCircleDollar,
  PiFunnelBold,
  PiMagnifyingGlass,
  PiShieldStar,
} from 'react-icons/pi';
import {
  Box,
  Button,
  Flex,
  rem,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { FilterChips } from '@common/composites/filter-chips';
import { useFilterModal } from '@common/utilities/filter/filter-modal';

const CheckDepositLeftHeader = () => {
  const theme = useMantineTheme();
  const filterHook = useCheckDepositFilters();
  const modalFilterHook = useCheckDepositModalFilters();
  const {
    activeFiltersArray,
    removeFilter,
    removeAllFilters,
    addFilter,
    openResponsiveFilterModal,
    getFilterByKey,
  } = useFilterModal({ filterHook, modalFilterHook });

  const searchFilterValue = getFilterByKey('search')?.filterValue;

  const CHECK_DEPOSITS_FILTERS = [
    {
      key: 'date',
      header: 'Date',
      icon: <PiCalendarBlank size={'1.5rem'} />,
      component: <CheckDepositsDateRangeFilter />,
    },
    {
      key: 'status',
      header: 'Status',
      icon: <PiShieldStar size={'1.5rem'} />,
      component: <CheckDepositsStatusFilter />,
    },
    {
      key: 'amount',
      header: 'Amount',
      icon: <PiCurrencyCircleDollar size={'1.5rem'} />,
      component: <CheckDepositsAmountFilter />,
    },
  ];

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();

        const strValues = Object.values(transaction).filter(
          (v) => !!v && typeof v === 'string',
        ) as string[];

        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleOpenFilters = () => {
    openResponsiveFilterModal(CHECK_DEPOSITS_FILTERS);
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  return (
    <Box>
      <Flex gap="md" mb="md">
        <TextInput
          value={searchFilterValue}
          w={{ base: '100%', sm: rem(370) }}
          placeholder="Search checks"
          onChange={(e) => handleSearchChange(e.target.value)}
          leftSection={<PiMagnifyingGlass color={theme.colors.neutral[6]} />}
        />
        <Button
          variant="neutral-outline"
          rightSection={<PiFunnelBold />}
          onClick={handleOpenFilters}
        >
          Filters
        </Button>
      </Flex>
      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </Box>
  );
};

export default CheckDepositLeftHeader;
