import {
  Flex,
  Text,
  Divider,
  Center,
  Loader,
  Box,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { formatCents, formatCurrency } from '@utilities/formatters';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { PiInfoBold } from 'react-icons/pi';
import { useGetBillpayConfiguration } from '@queries/use-bill-pay';
import { formatPercentage } from '@utilities/formatters/format-percentage';
import { DateTime } from 'luxon';

type ReviewBodyLocProps = {
  invoiceTotal?: {
    cents: number;
    formatted: string;
  };
  sendOn?: Date;
};

export default function ReviewBodyLoc({
  invoiceTotal,
  sendOn,
}: ReviewBodyLocProps) {
  const theme = useMantineTheme();
  const { businessId } = useRecoilValue(ApplicationState);

  const {
    data: billpayConfigurationData,
    isPending: isPendingBillpayConfiguration,
  } = useGetBillpayConfiguration(businessId);

  const netTerms =
    billpayConfigurationData?.billpayConfiguration?.netTerms ?? 60;
  const invoiceTotalCents = invoiceTotal?.cents || 0;
  const feePercentage = Number(
    billpayConfigurationData?.billpayConfiguration?.fundingFeePercentage ?? 0,
  );
  const formattedFeePercentage = formatPercentage(
    billpayConfigurationData?.billpayConfiguration?.fundingFeePercentage ?? '0',
  );
  const feePercentageDecimal = feePercentage / 100;
  const fee = feePercentageDecimal
    ? Math.round(feePercentageDecimal * invoiceTotalCents)
    : 0;

  const invoiceTotalDue = Number(invoiceTotalCents) + fee;
  const feeLabel = formatCurrency(formatCents(fee));
  const totalTooltipLabel = `This is the total amount owed to Flex ${netTerms} days after the payment is sent, including a ${formattedFeePercentage} fee. Paying early reduces the fee.`;
  const feeTooltipLabel = `This is the fee for the option to pay later. Paying early reduces the fee.`;

  const dueDate = sendOn
    ? DateTime.fromJSDate(sendOn)
        .plus({ days: netTerms })
        .toLocaleString(DateTime.DATE_MED)
    : undefined;

  const feesData = [
    {
      id: 'terms',
      title: 'Terms',
      description: `${netTerms} Days`,
    },
    {
      id: 'fee',
      title: `Fee (${formattedFeePercentage})`,
      tooltip: feeTooltipLabel,
      description: feeLabel,
    },
    {
      id: 'total',
      tooltip: totalTooltipLabel,
      title: `Total Due on ${dueDate}`,
      description: formatCurrency(formatCents(invoiceTotalDue)),
    },
  ];

  if (isPendingBillpayConfiguration) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <>
      <Divider my="md" />
      <Text fw={500} size="sm">
        Repayment terms
      </Text>
      {feesData.map((item) => (
        <Flex align="center" justify="space-between" key={item.id} mt="md">
          <Flex align="center" gap="xs">
            <Text c="neutral.8" size="sm">
              {item.title}
            </Text>
            {item.tooltip && (
              <Tooltip label={item.tooltip} withArrow>
                <Box mt="xxs">
                  <PiInfoBold size={15} color={theme.colors.neutral[6]} />
                </Box>
              </Tooltip>
            )}
          </Flex>
          <Text size="sm">{item.description}</Text>
        </Flex>
      ))}
    </>
  );
}
