import { useState } from 'react';
import { Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { QueryObserverResult } from '@tanstack/react-query';
import TransferData from './transfer-funds';
import TransferError from './transfer-error';
import useModal from '@common/composites/modal/modal-hook';
import { AUTHORIZATIONS } from 'constants/constants';
import { useMakePayment } from '@queries/use-payments';
import { formatCurrency } from '@utilities/formatters';
import ConnectBankAccount from './connect-bank-account';
import { showNotification } from '@mantine/notifications';
import { PlaidAccounts } from '@services/flexbase/banking.model';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';

type BankTransferProps = {
  plaidAccounts: PlaidAccount[];
  depositAccounts: DepositAccount[];
  refetchPlaidAccounts: () => Promise<
    QueryObserverResult<PlaidAccounts, Error>
  >;
};

const getAmountErrorText = (value: number, resourceAccount: PlaidAccount) => {
  if (value === 0) {
    return 'Amount is required';
  }

  if (Number(resourceAccount?.available) < value) {
    return `Your payment amount cannot exceed the available balance in your selected payment account.`;
  }

  return null;
};

const BankTransfer = ({
  plaidAccounts,
  depositAccounts,
  refetchPlaidAccounts,
}: BankTransferProps) => {
  const { closeAllModals } = useModal();
  const currentStep = plaidAccounts.length > 0 ? 'review' : 'link-bank';
  const [step, setStep] = useState<'review' | 'link-bank' | 'error'>(
    currentStep,
  );
  const [reason, setReason] = useState('');
  const { mutate: mutateMakePayment, isPending } = useMakePayment();

  const transferForm = useForm({
    initialValues: {
      amount: 0,
      toAccount: depositAccounts[0],
      fromAccount: plaidAccounts[0],
    },
    validate: {
      amount: (value, values) => getAmountErrorText(value, values.fromAccount),
    },
  });

  const transferFormValues = transferForm.values;

  const handleLinkingCompleted = async () => {
    const { data } = await refetchPlaidAccounts();
    const newPlaidAccountsState = data?.accounts ?? [];
    const plaidAccount = transferFormValues.fromAccount;
    const selectedAccount = newPlaidAccountsState.find(
      (acc) => acc.id === plaidAccount.id,
    ) as PlaidAccount;
    // update the form with the new current Plaid account state
    transferForm.setFieldValue('fromAccount', selectedAccount);
  };

  const handleRetry = () => setStep('review');
  const handleLinkBankAccount = () => {
    refetchPlaidAccounts();
  };

  const makeTransfer = () => {
    mutateMakePayment(
      {
        type: 'ach',
        direction: 'Debit',
        description: 'Funding',
        accountId: transferFormValues.toAccount.id,
        plaidTokenId: transferFormValues.fromAccount.id,
        amount: formatCurrency(transferFormValues.amount),
        authorizations: [AUTHORIZATIONS.achDebit],
      },
      {
        onSuccess: (data) => {
          if (data.status === 'Rejected') {
            setReason(data?.reason ?? '');
            return setStep('error');
          }

          const depositAccName =
            transferFormValues.toAccount.nickName ??
            transferFormValues.toAccount.name ??
            '';

          showNotification({
            color: 'sage.4',
            message: `Your ${data.payAmount} transfer to ${depositAccName} is processing.`,
          });
          closeAllModals();
        },
        onError: () => {
          setStep('error');
        },
      },
    );
  };

  const getContent = () => {
    switch (step) {
      case 'review':
        return (
          <TransferData
            transferForm={transferForm}
            isLoadingRequest={isPending}
            onMakeTransfer={makeTransfer}
            plaidAccounts={plaidAccounts}
            depositAccounts={depositAccounts}
            onLinkingCompleted={handleLinkingCompleted}
          />
        );

      case 'link-bank':
        return <ConnectBankAccount onLinkAccount={handleLinkBankAccount} />;

      case 'error':
        return (
          <TransferError
            reason={reason}
            onRetry={handleRetry}
            onClose={closeAllModals}
          />
        );
    }
  };

  return <Box>{getContent()}</Box>;
};

export default BankTransfer;
