import FlexSelect from './flex-select';
import { useMemo } from 'react';
import { useCardsAndCategories } from '@queries/use-credit-cards';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import { ComboboxItem, SelectStylesNames, rem } from '@mantine/core';

type Props = {
  onChange: (value: string | null) => void;
  value?: string;
  disabled?: boolean;
  classNames?: Partial<Record<SelectStylesNames, string>>;
  filter?: (items: ComboboxItem[]) => ComboboxItem[];
};

const CardCategorySelect = ({
  value,
  onChange,
  disabled,
  classNames,
  filter,
}: Props) => {
  const { connectionId = '' } = useActiveExpenseLink();
  const { data: cardsCategories } = useCardsAndCategories({ connectionId });

  let categories = useMemo(() => {
    if (cardsCategories?.categories) {
      const formattedCategories = cardsCategories.categories.map<ComboboxItem>(
        (item) => ({
          label: item.name,
          value: item.id,
        }),
      );
      return formattedCategories;
    }
    return [];
  }, [cardsCategories]);

  if (filter) {
    categories = filter(categories);
  }

  return (
    <FlexSelect
      inputProps={{ classNames, disabled, radius: rem(8) }}
      searchable
      value={value}
      label={'Accounting category'}
      data={categories}
      onChange={onChange}
    />
  );
};

export default CardCategorySelect;
