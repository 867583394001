import { Box, Button, Grid } from '@mantine/core';
import FullScreenModalContainer from './composites/modal/full-screen-modal-container';
import {
  IssueMarqetaCreditCardForm,
  IssueMarqetaCreditCardFormType,
  useIssueMarqetaCreditCardForm,
} from '@common/issue-marqeta-credit-card-form';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useGetUsers } from '@queries/use-users';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import {
  useCreateCardCategory,
  useIssueCard,
  useNet60CreditCards,
} from '@queries/use-credit-cards';
import {
  RequiredFieldValidator,
  validatePostalCode,
} from '@utilities/validators';
import { useStyles } from './cards-styles';
import { v4 as uuidv4 } from 'uuid';
import {
  CardFulfillment,
  SpendRestrictionYaml,
} from '@flexbase-eng/sdk-typescript/models/components';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useCardTypes } from '@queries/use-credit-programs';

type IssueCreditCardProps = {
  /**
   * The company's card program to which the card will be associated.
   */
  programId: string;

  /**
   * The line of credit to which the card will be issued.
   *
   * eg. marqeta, lithic, unit, etc.
   */
  lineOfCreditId: string;

  onClose: () => void;
};

export const IssueCreditCard = ({
  lineOfCreditId,
  programId,
  onClose,
}: IssueCreditCardProps) => {
  const { classes } = useStyles();
  const { accountId, personId } = useRecoilValue(ApplicationState);
  const { connectionId = '' } = useActiveExpenseLink();
  const { data: users = [] } = useGetUsers();
  const { data: creditCards } = useNet60CreditCards();
  const { data: cardTypes = [] } = useCardTypes(personId);
  const { mutate: issueCard, isPending: isIssuingCard } = useIssueCard();
  const { mutate: addCardExpenseCategory } = useCreateCardCategory();

  const getCorporeality = (cardTypeId: string) => {
    return cardTypes?.find((ct) => ct.id === cardTypeId)?.corporeality;
  };

  const requiredIfPhysical = (errMsg: string) => {
    return (value: string, values: IssueMarqetaCreditCardFormType) => {
      const isPhysical = getCorporeality(values.cardTypeId) === 'physical';

      return !isPhysical || !!value ? null : errMsg;
    };
  };

  const form = useIssueMarqetaCreditCardForm({
    initialValues: {
      userId: '',
      cardTypeId: '',
      cardName: '',
      spendingLimit: 0,
      spendingLimitInterval: undefined,
      groups: [],
      categoryId: '',
      terminateAt: null,
      limitMerchantCategories: 'no',
      autoExpire: 'no',
      street1: '',
      street2: '',
      locality: '',
      region: '',
      postalCode: '',
      country: 'US',
      shippingMethod: 'standard',
    },
    validate: {
      spendingLimit: (value, values) => {
        if (values.spendingLimitInterval !== 'unlimited' && value <= 0) {
          return 'Spending limit must be greater than 0 unless limit type is unlimited.';
        }
        return null;
      },
      cardName: RequiredFieldValidator('Card name is required.'),
      cardTypeId: (value, values) => {
        if (!value) {
          return 'Card type is required.';
        }

        const cardType = cardTypes.find((ct) => ct.id === value);

        if (!cardType) {
          return 'Card type invalid or unknown.';
        }

        if (cardType.corporeality !== 'physical') {
          return null;
        }

        const [memberName] =
          users
            .filter((u) => u.id === values.userId)
            .map((u) => `${u.firstName} ${u.lastName}`) || [];
        const activePhysicalCards =
          creditCards?.filter(
            (c) =>
              c.userId === values.userId &&
              c.cardType === 'PHYSICAL' &&
              c.status !== 'terminated',
          ) || [];

        return activePhysicalCards.length
          ? `${memberName || 'Selected member'} already has an active physical card. You must cancel that card before ordering a new one.`
          : null;
      },
      userId: RequiredFieldValidator('A user must be selected for this card'),
      spendingLimitInterval: RequiredFieldValidator('Select a limit type'),
      street1: requiredIfPhysical('Street is required'),
      locality: requiredIfPhysical('City is required'),
      region: requiredIfPhysical('State is required'),
      postalCode: (value, values) => {
        const isPhysical = getCorporeality(values.cardTypeId) === 'physical';

        if (!isPhysical) {
          return null;
        }

        return (
          requiredIfPhysical('A valid zip code is required')(value, values) ||
          (validatePostalCode(value) ? null : 'A valid zip code is required')
        );
      },
    },
  });

  form.watch('userId', ({ value }) => {
    const selectedUser = users.find((u) => u.id === value);

    if (selectedUser) {
      form.setValues({
        street1: selectedUser.address,
        street2: selectedUser.addressLine2 ?? '',
        locality: selectedUser.city,
        region: selectedUser.state,
        postalCode: selectedUser.postalCode,
      });
    }
  });

  const handleClose = () => {
    onClose();
  };

  const handleSave = form.onSubmit((formValues) => {
    const fulfillment: CardFulfillment | undefined = (() => {
      // address only required when issuing a physical card
      if (getCorporeality(formValues.cardTypeId) !== 'physical') {
        return undefined;
      }

      return {
        shippingAddress: {
          address: {
            street: [formValues.street1, formValues.street2].filter(
              isTruthyString,
            ),
            locality: formValues.locality,
            region: formValues.region,
            postalCode: formValues.postalCode,
            country: formValues.country,
          },
          shippingMethod: 'standard',
        },
      };
    })();

    const limits: SpendRestrictionYaml = (() => {
      const isUnlimited = formValues.spendingLimitInterval === 'unlimited';

      return {
        amount: isUnlimited
          ? undefined
          : {
              amount: Math.round(formValues.spendingLimit * 100),
              scale: 2,
              currency: 'USD',
            },
        interval: formValues.spendingLimitInterval,
        groups: formValues.groups,
      };
    })();

    issueCard(
      {
        accountId,
        personId: formValues.userId,
        issueCard: {
          programId,
          lineOfCreditId,
          cardTypeId: formValues.cardTypeId,
          idempotencyKey: uuidv4(),
          name: formValues.cardName,
          form: getCorporeality(formValues.cardTypeId) ?? 'virtual',
          suspendDate: formValues.terminateAt
            ? new Date(formValues.terminateAt)
            : undefined,
          fulfillment: fulfillment,
          limits: limits,
        },
      },
      {
        onSuccess: (issuedCard) => {
          if (formValues.categoryId) {
            addCardExpenseCategory(
              {
                connectionId,
                categoryId: formValues.categoryId,
                cardName: issuedCard.name || '',
                userId: issuedCard.personId,
              },
              {
                onSuccess: () => {
                  onClose();
                },
              },
            );
          } else {
            onClose();
          }
        },
      },
    );
  });

  return (
    <FullScreenModalContainer closeModal={handleClose}>
      <Box
        className={classes.issueCardContainer}
        w="100%"
        data-testid="issue-card"
      >
        <IssueMarqetaCreditCardForm
          form={form}
          title="Create a new credit card"
        />

        <Grid
          className="buttons-container"
          justify="space-between"
          align="center"
          mt="1rem"
          gutter={0}
        >
          <Grid.Col span={12}>
            <Button
              loading={isIssuingCard}
              onClick={() => handleSave()}
              fullWidth
              className="btn-create-card"
              variant="primary-filled"
            >
              Create Card
            </Button>
          </Grid.Col>
        </Grid>
      </Box>
    </FullScreenModalContainer>
  );
};
