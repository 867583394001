import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { useAutoPayChargeCardFeatureFlag } from '@utilities/feature-flags';

export const useAccountManagement = () => {
  const isAutoPayChargeCardFeatureFlagEnabled =
    useAutoPayChargeCardFeatureFlag();
  const {
    data: depositAccountsData,
    isError: isDepositAccountsError,
    isLoading: isDepositAccountsLoading,
    refetch: refetchDepositAccounts,
  } = useGetDepositAccounts();

  const {
    data: externalAccountsData,
    isLoading: isExternalAccountsLoading,
    isError: isExternalAccountsError,
    refetch: refetchExternalAccounts,
  } = useExternalAccounts(isAutoPayChargeCardFeatureFlagEnabled);

  const depositAccounts =
    depositAccountsData?.accounts
      .filter((acc) => acc.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map(
        (acc) =>
          ({
            ...acc,
            plaidOrDeposit: 'deposit',
          }) as DepositAccount,
      ) ?? [];

  const externalAccounts =
    externalAccountsData
      .filter((acc) => acc.active && !acc.unlinked)
      .map(
        (acc) =>
          ({
            ...acc,
            plaidOrDeposit: 'plaid',
          }) as PlaidAccount,
      ) ?? [];

  const accounts = [...depositAccounts, ...externalAccounts];

  const refetchAccounts = () => {
    if (isDepositAccountsError) {
      refetchDepositAccounts();
    }
    if (isExternalAccountsError) {
      refetchExternalAccounts();
    }
  };

  return {
    accounts,
    isLoading: isDepositAccountsLoading || isExternalAccountsLoading,
    isError: isDepositAccountsError || isExternalAccountsError,
    refetchAccounts,
  };
};
