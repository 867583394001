import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';

const CreditTransactionsFilterState = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'credit_transactions_filter_state',
  default: {},
});

const CreditTransactionsFilterStateModal = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'credit_transactions_filter_state_modal',
  default: {},
});

const MarqetaTransactionsFilterState = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'credit_transactions_marqeta_filter_state',
  default: {},
});

const MarqetaTransactionsFilterStateModal = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'credit_transactions_marqeta_filter_state_modal',
  default: {},
});

export function useMarqetaTransactionsFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    MarqetaTransactionsFilterState,
  );
}

export function useMarqetaTransactionsModalFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    MarqetaTransactionsFilterStateModal,
  );
}

export function useCreditTransactionFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    CreditTransactionsFilterState,
  );
}

export function useCreditTransactionModalFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    CreditTransactionsFilterStateModal,
  );
}
