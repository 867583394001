import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    padding: theme.spacing.xl,
    borderRadius: theme.defaultRadius,
    color: theme.colors.neutral[2],
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
  header: {
    paddingBottom: rem(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      gap: rem(15),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));
