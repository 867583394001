import { Box, rem, Text } from '@mantine/core';
import { Outlet, useParams } from 'react-router-dom';
import GenericError from '../../../components/generic-error';
import { useChartFilter } from '@utilities/custom-hooks/use-chart-filter';
import MoneyMovementModal from './money-movement-modal';
import { useFilteredDates } from '@utilities/custom-hooks/use-filtered-dates';
import { formatCurrency } from '@utilities/formatters';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import { useGetReserveAccountInfo } from '@queries/user-reserve-account';
import FlexDefaultHeader from '@common/composites/header/flex-default-header';
import { getFilteredReserveTransactions } from './get-filtered-transactions';
import DetailPageLayout from '@common/layouts/detail-page/detail-page-layout';
import TransactionsSummary from '../components/transactions-summary/transactions-summary';

const ReserveAccountDetailsComp = ({
  currentBalance,
}: {
  currentBalance: number;
}) => {
  return (
    <Box>
      <Text size="sm" c="neutral.8">
        Reserve balance
      </Text>
      <Text fz={rem(32)} fw={500}>
        {formatCurrency(currentBalance)}
      </Text>
    </Box>
  );
};

const ReserveAccountDetails = () => {
  const { id: accountId } = useParams();
  const { before, after } = useFilteredDates();
  const { chartFilterValue } = useChartFilter();

  const {
    data: reserveAccountData,
    isError: isReserveAccountError,
    isLoading: isLoadingReserveAccount,
  } = useGetReserveAccountInfo(accountId ?? '');

  // const { data } = useGetReserveAccountTransactions(accountId ?? '');
  // TODO: use the data from the endpoint where this one is fixed
  const reserveTransactions = [
    {
      bankingTransactionId: '4740707c-572b-43bd-b80b-2b903edf58c0',
      createdAt: '2024-05-29 16:44:54.239+00',
      type: 'receivedAch',
      amount: 500000,
    },
    {
      bankingTransactionId: '4740707c-572b',
      createdAt: '2024-06-10 12:44:54.239+00',
      type: 'receivedAch',
      amount: 100000,
    },
  ];

  const transactions = getFilteredReserveTransactions(
    reserveTransactions,
    after,
    before,
  );

  const reserveAccount = reserveAccountData?.reserveAccount;

  const basePath = 'banking/reserve-account';
  const tabs = [
    {
      label: 'Account Information',
      route: `/${basePath}/${accountId}/account-information`,
    },
  ];

  if (isReserveAccountError) {
    return (
      <DetailPageLayout
        linkTitle="All bank accounts"
        linkPath="/banking/accounts"
      >
        <GenericError>
          <Text>
            We are unable to retrieve your bank account information at the
            moment.
          </Text>
        </GenericError>
      </DetailPageLayout>
    );
  }

  return (
    <DetailPageLayout
      linkTitle="All bank accounts"
      linkPath="/banking/accounts"
    >
      <FlexDefaultHeader title="Reserve Account" />

      <TransactionsSummary
        isReserveAccount={true}
        accountDetailsComponent={
          <ReserveAccountDetailsComp
            currentBalance={reserveAccount?.balance ?? 0.0}
          />
        }
        chartFilterValue={chartFilterValue}
        //TODO
        chart={<Box />}
        modalContent={<MoneyMovementModal />}
        transactions={transactions}
      />

      {isLoadingReserveAccount ? (
        <Box mt="md">
          <SkeletonLoading withTabs />
        </Box>
      ) : (
        <NavTabContainer tabs={tabs}>
          <Outlet />
        </NavTabContainer>
      )}
    </DetailPageLayout>
  );
};

export default ReserveAccountDetails;
