import { useEffect, useMemo, useState } from 'react';
import { BUSINESS_MERCHANT_CODES } from '../../../../../constants/constants';
import { filterAutocompleteData } from '../../../../../utilities/filter/filter-data';
import { Autocomplete } from '@mantine/core';
import { SummaryEditInput } from './summary-edit-input';
import { CompanyInfoFormValues } from '../summary';
import { UseFormReturnType } from '@mantine/form';
import { flexbaseOnboardingClient } from '@services/flexbase-client';

type Props = {
  value: string;
  form: UseFormReturnType<CompanyInfoFormValues>;
  isEditMode: boolean;
};

const SummaryCategoryForm = ({ value, form, isEditMode }: Props) => {
  const [mcc, setMcc] = useState<{ value: string; label: string }[]>([]);
  const [searchString, setSearchString] = useState('');
  const mccAutocompleteData = filterAutocompleteData(
    searchString,
    mcc.length > 0 ? mcc : BUSINESS_MERCHANT_CODES,
  );

  const categoryLabel = useMemo(() => {
    const category = mcc.find((code) => code.value === value);
    return category ? `${category?.value} - ${category?.label}` : value;
  }, [value]);

  const getCodes = async () => {
    const code = await flexbaseOnboardingClient.getMccCodes();
    setMcc(code.map((c) => ({ value: c.code, label: c.description })));
  };

  useEffect(() => {
    getCodes();
  }, []);

  return (
    <SummaryEditInput
      label="Category"
      value={categoryLabel}
      isEditMode={isEditMode}
      editInputs={
        <Autocomplete
          {...form.getInputProps('category')}
          onChange={(e) => {
            setSearchString(e);
            form.setFieldValue('category', e);
          }}
          onOptionSubmit={(i) => {
            form.setFieldValue('category', i);
          }}
          data={mccAutocompleteData}
          label="Category"
          placeholder="Search for your MCC by code or description"
          id="business-industry"
        />
      }
    />
  );
};

export default SummaryCategoryForm;
