import { TRANSACTION_GROUPS } from 'constants/constants';
import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';
import { useBankingTransactionsModalFilters } from '../use-banking-transactions-filters';

export const BankingTransactionsTypeFilter = () => {
  const bankingFilters = useBankingTransactionsModalFilters();

  const FILTER_TYPE_OPTIONS = [
    {
      label: 'ACH',
      key: 'ach',
      filterCriteria: TRANSACTION_GROUPS.ach,
    },
    {
      label: 'Wire',
      key: 'wire',
      filterCriteria: TRANSACTION_GROUPS.wire,
    },
    {
      label: 'Card',
      key: 'card',
      filterCriteria: TRANSACTION_GROUPS.card,
    },
    {
      label: 'ATM',
      key: 'atm',
      filterCriteria: TRANSACTION_GROUPS.atm,
    },
    {
      label: 'Check',
      key: 'check',
      filterCriteria: TRANSACTION_GROUPS.check,
    },
    {
      label: 'Payment',
      key: 'payment',
      filterCriteria: TRANSACTION_GROUPS.payment,
    },
    {
      label: 'Transfers',
      key: 'book',
      filterCriteria: TRANSACTION_GROUPS.book,
    },
    {
      label: 'Others',
      key: 'others',
      filterCriteria: TRANSACTION_GROUPS.others,
    },
  ];

  return (
    <SimpleSegmentSelectedFilter
      label="Type"
      filterKey="type"
      filterHook={bankingFilters}
      options={FILTER_TYPE_OPTIONS}
      includesValue={(row) => row.type}
    />
  );
};
