import { formatIntlPhoneForPlatform } from '../../../../utilities/formatters/format-phone-number';
import { validateIntlPhoneNumber } from '../../../../utilities/validators/validate-phone-number';
import { useState } from 'react';
import { useVerifyPhoneStyles } from './styles';
import { FlexIntlPhoneInput } from '@common/composites/input/flexbase-intl-phone-input';
import OnboardingStep from '../../components/onboarding-step';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { useOnboardingStyles } from '../../onboarding.styles';
import TagManager from 'react-gtm-module';
import { formatPhoneForApi } from '@utilities/formatters';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { useAddFactorsContext } from 'providers/add-factors.context';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { VerificationCodeInput } from '@common/verify-code';
import { IsFlexHttpError } from 'services/platform/models/authorize.models';
import {
  isPhoneNotSupportedError,
  PHONE_NOT_SUPPORTED_ERROR_COPY,
} from 'constants/index';
import { Country } from 'react-phone-number-input';
import { useRecoilValue } from 'recoil';

const VerifyPhone = () => {
  const { user } = useRecoilValue(ApplicationState);
  const { navigateToNextProductStep } = useApplicationFlowContext();

  const { registerFactor, resendOtpSmsVerification, verifyFactor, savePhone } =
    useAddFactorsContext();
  const [methodId, setMethodId] = useState('');

  const { classes: onboardingClasses } = useOnboardingStyles();
  const { classes } = useVerifyPhoneStyles();
  const [error, setError] = useState('');
  const [hasInputError, setHasInputError] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user.cellPhone || '');
  const [phoneCountryCode, setPhoneCountryCode] = useState<Country>(
    user.countryCode ?? 'US',
  );
  const [codeVerified, setCodeVerified] = useState(false);

  const handleSetPhoneNumber = (formattedValue: string) => {
    if (
      hasInputError &&
      validateIntlPhoneNumber(formattedValue, phoneCountryCode)
    ) {
      setHasInputError(false);
      setError('');
    }

    setPhoneNumber(formattedValue);
  };

  const onPhoneContinue = async () => {
    setLoading(true);
    setError('');
    const validationResult = validateIntlPhoneNumber(
      phoneNumber,
      phoneCountryCode,
    );

    if (!validationResult) {
      setHasInputError(true);
      setError('Please enter a valid phone number');
      setLoading(false);
      return;
    }

    try {
      const phoneFormattedForPlatform = formatIntlPhoneForPlatform(
        phoneNumber,
        phoneCountryCode,
      );
      const registerFactorResponse = await registerFactor({
        factorType: 'phone',
        value: phoneFormattedForPlatform,
      });
      setMethodId(registerFactorResponse.methodId);
      setShowVerify(true);
    } catch (e) {
      // If the error is a 409, then the factor is already registered and verified, and there is nothing else to do.
      if (IsFlexHttpError(e) && e.statusCode === 409) {
        await onCodeVerified();
      } else if (isPhoneNotSupportedError(e)) {
        setError(PHONE_NOT_SUPPORTED_ERROR_COPY);
      } else {
        setError(
          'Failed to update phone number. Please contact support if the issue continues.',
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const onNextClick = () => {
    if (!codeVerified) {
      setError('Verify the code sent to your phone to continue.');
    } else {
      navigateToNextProductStep();
    }
  };

  // After we verify thď user's phone, we will save it to the API and Platform.dd
  const onCodeVerified = async () => {
    await savePhone(phoneNumber, phoneCountryCode);
    const dataLayerArgs = {
      dataLayer: {
        event: 'phoneVerified',
      },
    };
    TagManager.dataLayer(dataLayerArgs);
    setCodeVerified(true);
    navigateToNextProductStep();
  };

  const onBackClick = () => {
    setError('');
    setShowVerify(false);
  };

  const handleCodeChange = async (code: string) => {
    setLoading(true);
    try {
      await verifyFactor(methodId, 'otp', code);
      await onCodeVerified();
    } catch {
      setError('The code you entered is invalid or expired.');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCodeClick = async () => {
    try {
      await resendOtpSmsVerification({
        value: `+1${formatPhoneForApi(phoneNumber)}`,
        factorType: 'phone',
      });
    } catch (e) {
      setError(
        `An error occurred when attempting to resend a code to the phone number ending in ${getPaddedAccountMask(
          phoneNumber,
          4,
        )}`,
      );
    }
  };

  return (
    <OnboardingStep
      title={showVerify ? 'Enter verification code' : 'Verify your number'}
      stepId="verify-phone"
      subtitle={
        showVerify
          ? `A 6 digit verification code has been sent to the phone number ending in ${phoneNumber.slice(
              -4,
            )}`
          : 'We’ll send you a one time security code'
      }
      showBack={showVerify}
      nextLabel={showVerify ? 'Verify' : 'Send Verification'}
      error={error}
      onNextClick={showVerify ? onNextClick : onPhoneContinue}
      onBackClick={onBackClick}
      showNext={true}
      showContinueSpinner={loading}
      bottomDisclosureText={
        !showVerify
          ? 'By clicking "Send Verification," you agree to receive SMS alerts from Flex regarding account activity. Standard messaging and data rates may apply. '
          : null
      }
      width={360}
    >
      {showVerify ? (
        <VerificationCodeInput
          onResendCodeClick={handleResendCodeClick}
          onCodeSubmit={handleCodeChange}
        />
      ) : (
        <div className={classes.inputContainer} data-name="container">
          <FlexIntlPhoneInput
            label="Cellphone"
            data-sardine-id="cellphone"
            inputMode="tel"
            value={phoneNumber}
            countryCode={phoneCountryCode}
            onCountryCodeChange={(v) => setPhoneCountryCode(v)}
            onValueChange={(e) => handleSetPhoneNumber(e.formattedValue)}
            placeholder="Enter number"
            error={hasInputError}
            classNames={{
              root: onboardingClasses.inputRoot,
              label: onboardingClasses.inputLabel,
            }}
            id="input-phone-number"
          />
        </div>
      )}
    </OnboardingStep>
  );
};

export default VerifyPhone;
