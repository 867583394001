import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { ComboboxItem, MultiSelect } from '@mantine/core';
import {
  useChargeCards,
  useNet60CreditCards,
  usePlatformCreditCards,
} from '@queries/use-credit-cards';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import {
  useCreditTransactionModalFilters,
  useMarqetaTransactionsModalFilters,
} from '../use-credit-transaction-filters';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';
import { uniq } from 'underscore';

type FilterSelectItem = ComboboxItem & {
  last4: string;
};

type CreditTransactionsCardsFilterProps = {
  options: FilterSelectItem[];
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditCardsFilter = ({
  options,
  filterHook,
}: CreditTransactionsCardsFilterProps) => {
  const { addFilter, getFilterByKey, removeFilter } = filterHook;
  const uniqueOptions = uniq(options, (o) => o.value);

  return (
    <MultiSelect
      data={uniqueOptions}
      placeholder="Cards"
      searchable
      value={getFilterByKey('cards')?.filterValue || []}
      onChange={(values) => {
        if (values.length > 0) {
          addFilter('cards', {
            key: 'cards',
            filterValue: values,
            fn: (row) => !!row.cardId && values.includes(row.cardId),
            label: `Cards: ${values
              .map((id) => options.find((o) => o.value === id)?.last4)
              .filter(Boolean)
              .join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('cards');
        }
      }}
    />
  );
};

export const CreditTransactionsCardsFilter = () => {
  const filterHook = useCreditTransactionModalFilters();
  const { data: cards } = useNet60CreditCards();
  const options =
    cards?.map<FilterSelectItem>((c) => {
      const last4 = getPaddedAccountMask(c.cardNumber.slice(-4), 2);

      return {
        label: `${c.cardName || 'Card'} - ${last4}`,
        value: c.id,
        last4,
      };
    }) ?? [];

  return <CreditCardsFilter options={options} filterHook={filterHook} />;
};

export const ChargeCardTransactionsCardsFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();
  const { data: cards } = useChargeCards();
  const options =
    cards?.map<FilterSelectItem>((c) => {
      const last4 = getPaddedAccountMask(c.cardNumber.slice(-4), 2);

      return {
        label: `${c.cardName || 'Card'} - ${last4}`,
        value: c.id,
        last4,
      };
    }) ?? [];

  return <CreditCardsFilter options={options} filterHook={filterHook} />;
};

export const MarqetaTransactionsCardsFilter = () => {
  const filterHook = useMarqetaTransactionsModalFilters();
  const { data: cards } = usePlatformCreditCards();
  const options =
    cards?.map<FilterSelectItem>((c) => {
      const last4 = getPaddedAccountMask(c.last4, 2);

      return {
        label: `${c.name || 'Card'} - ${last4}`,
        value: c.id,
        last4,
      };
    }) ?? [];

  return <CreditCardsFilter options={options} filterHook={filterHook} />;
};
