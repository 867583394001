import { Box, Text, Flex } from '@mantine/core';

const ReserveAccountInformation = () => {
  return (
    <Box
      bg="neutral.0"
      p="xl"
      sx={(theme) => ({
        border: `1px solid ${theme.colors.neutral[4]}`,
        borderRadius: theme.defaultRadius,
      })}
    >
      <Text size="xl" mb="xl">
        Account information
      </Text>
      <Flex gap={60}>
        <Box>
          <Text size="sm">Account name</Text>
          <Text>Reserve Account</Text>
        </Box>
        <Box>
          <Text size="sm">Account type</Text>
          <Text>Reserve</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ReserveAccountInformation;
