import { Box, Button, Menu, TextInput, useMantineTheme } from '@mantine/core';
import {
  ChargeCardTransactionsDateRangeFilter,
  CreditTransactionsDateRangeFilter,
  MarqetaTransactionsDateRangeFilter,
} from './filters/credit-date-range-filter';
import {
  ChargeCardTransactionsStatusFilter,
  CreditTransactionsStatusFilter,
  MarqetaTransactionsStatusFilter,
} from './filters/credit-status-filter';
import {
  ChargeCardTransactionsTypeFilter,
  CreditTransactionsTypeFilter,
  MarqetaTransactionsTypeFilter,
} from './filters/credit-type-filter';
import {
  ChargeCardTransactionsAmountFilter,
  CreditTransactionsAmountFilter,
  MarqetaTransactionsAmountFilter,
} from './filters/credit-transactions-amount.filter';
import {
  ChargeCardTransactionsCardsFilter,
  CreditTransactionsCardsFilter,
  MarqetaTransactionsCardsFilter,
} from './filters/credit-transactions-cards.filter';
import {
  ChargeCardTransactionsAttachmentsFilter,
  CreditTransactionsAttachmentsFilter,
  MarqetaTransactionsAttachmentsFilter,
} from './filters/credit-transactions-attachments.filter';
import {
  ChargeCardTransactionsNameFilter,
  CreditTransactionsNameFilter,
  MarqetaTransactionsNameFilter,
} from './filters/credit-transactions-name.filter';
import { isTruthyString } from 'utilities/validators/validate-string';
import { useFilterModal } from '@common/utilities/filter/filter-modal';
import { CreditTransactionsOverviewModel } from '@queries/use-credit-transactions';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';
import { LineOfCredit } from '@services/flexbase/credit.model';
import { FilterChips } from '@common/composites/filter-chips';
import {
  PiDownloadSimpleBold,
  PiFunnel,
  PiMagnifyingGlass,
} from 'react-icons/pi';

type Props = {
  onDownloadCSVClick: () => void;
  creditTransactionsData?: CreditTransactionsOverviewModel;
  lineOfCredit: LineOfCredit;
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
  modalFilterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

/**
 * This component is a replacement for the old header that maintains the same signature
 * @constructor
 */
export const CreditTransactionsTableHeader = ({
  onDownloadCSVClick,
  lineOfCredit,
  creditTransactionsData,
  filterHook,
  modalFilterHook,
}: Props) => {
  const theme = useMantineTheme();
  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const filterConfig = [
    {
      key: 'date',
      header: 'Date',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsDateRangeFilter />
        ) : lineOfCredit === 'marqeta' ? (
          <MarqetaTransactionsDateRangeFilter />
        ) : (
          <CreditTransactionsDateRangeFilter />
        ),
    },
    {
      key: 'status',
      header: 'Status',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsStatusFilter />
        ) : lineOfCredit === 'marqeta' ? (
          <MarqetaTransactionsStatusFilter />
        ) : (
          <CreditTransactionsStatusFilter />
        ),
    },
    {
      key: 'type',
      header: 'Type',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsTypeFilter />
        ) : lineOfCredit === 'marqeta' ? (
          <MarqetaTransactionsTypeFilter />
        ) : (
          <CreditTransactionsTypeFilter />
        ),
    },
    {
      key: 'amount',
      header: 'Amount',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsAmountFilter />
        ) : lineOfCredit === 'marqeta' ? (
          <MarqetaTransactionsAmountFilter />
        ) : (
          <CreditTransactionsAmountFilter />
        ),
    },
    {
      key: 'cards',
      header: 'Cards',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsCardsFilter />
        ) : lineOfCredit === 'marqeta' ? (
          <MarqetaTransactionsCardsFilter />
        ) : (
          <CreditTransactionsCardsFilter />
        ),
    },
    {
      key: 'name',
      header: 'Name',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsNameFilter />
        ) : lineOfCredit === 'marqeta' ? (
          <MarqetaTransactionsNameFilter />
        ) : (
          <CreditTransactionsNameFilter />
        ),
    },
    {
      key: 'attachments',
      header: 'Attachments',
      component:
        lineOfCredit === 'unit' ? (
          <ChargeCardTransactionsAttachmentsFilter />
        ) : lineOfCredit === 'marqeta' ? (
          <MarqetaTransactionsAttachmentsFilter />
        ) : (
          <CreditTransactionsAttachmentsFilter />
        ),
    },
  ];

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  const openModal = () => {
    openResponsiveFilterModal(filterConfig);
  };

  return (
    <>
      <Box
        sx={() => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={(_, helpers) => ({
            display: 'flex',
            gap: theme.spacing.md,
            [helpers.smallerThan('sm')]: {
              flexDirection: 'column',
              width: '100%',
            },
          })}
        >
          <TextInput
            placeholder="Search transactions"
            w={368}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
            leftSection={
              <PiMagnifyingGlass
                size={'1.25rem'}
                color={theme.colors.neutral[8]}
              />
            }
          />
          <Button
            variant="neutral-outline"
            rightSection={<PiFunnel />}
            onClick={() => openModal()}
            disabled={creditTransactionsData?.transactions.length === 0}
          >
            Filters
          </Button>

          <Menu>
            <Menu.Target>
              <Button
                variant="neutral-outline"
                leftSection={<PiDownloadSimpleBold />}
              >
                CSV
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={onDownloadCSVClick}>Download CSV</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Box>

      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </>
  );
};
