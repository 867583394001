import { Box, BoxProps } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { ReactNode } from 'react';

type Props = {
  size?: 'md' | 'sm';
  variant?: 'flat' | 'border';
  state?: 'success' | 'warning' | 'error' | 'secondary' | 'zero' | string;
  children: ReactNode;
} & BoxProps;

/**
 * This is the status tag component as pulled from Figma. The props correspond exactly to the Figma
 * except for "style" which was renamed to "variant".
 *
 * Size: "sm" (default), "md"
 * Variant: "flat" (default), "border"
 * State: "success", "warning", "error", "secondary", "zero" (default)
 *
 * Figma: https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Web-DS?node-id=8086-22061&m=dev
 *
 * @constructor
 */
export const StatusTag = ({
  children,
  size,
  variant,
  state,
  className,
  ...boxProps
}: Props) => {
  const { classes, cx } = useStatusTagStyles();

  const getStateClassname = () => {
    switch (state) {
      case 'success':
        return classes.success;
      case 'warning':
        return classes.warning;
      case 'error':
        return classes.error;
      case 'secondary':
        return classes.secondary;
      case 'zero':
      default:
        return classes.zero;
    }
  };

  const getVariantClassname = () => {
    if (!variant || variant === 'flat') {
      return null;
    }

    switch (state) {
      case 'success':
        return classes.successBorder;
      case 'warning':
        return classes.warningBorder;
      case 'error':
        return classes.errorBorder;
      case 'secondary':
        return classes.secondaryBorder;
      case 'zero':
      default:
        return classes.zeroBorder;
    }
  };

  return (
    <Box
      className={cx(
        classes.main,
        size === 'md' ? classes.md : classes.sm,
        getStateClassname(),
        getVariantClassname(),
        className,
      )}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

const useStatusTagStyles = createStyles((theme) => ({
  main: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '2px 6px',
    width: 'fit-content',
  },
  md: {
    height: '28px',
    fontSize: '1rem',
    lineHeight: '150%',
    fontVariantNumeric: 'lining-nums tabular-nums',
  },
  sm: {
    height: '20px',
    fontSize: '12px',
    lineHeight: '1rem',
  },
  zero: {
    backgroundColor: theme.colors.neutral[2],
    color: theme.colors.neutral[8],
  },
  success: {
    backgroundColor: theme.colors.primary[0],
    color: theme.colors.neutral[7],
  },
  warning: {
    backgroundColor: theme.colors.warning[0],
    color: theme.colors.warning[6],
  },
  error: {
    backgroundColor: theme.colors.critical[0],
    color: theme.colors.critical[6],
  },
  secondary: {
    backgroundColor: theme.colors.promote[1],
    color: theme.colors.promote[7],
  },
  zeroBorder: {
    border: `1px solid ${theme.colors.neutral[4]}`,
  },
  successBorder: {
    border: `1px solid ${theme.colors.primary[3]}`,
  },
  warningBorder: {
    border: `1px solid ${theme.colors.warning[3]}`,
  },
  errorBorder: {
    border: `1px solid ${theme.colors.critical[3]}`,
  },
  secondaryBorder: {
    border: `1px solid ${theme.colors.promote[3]}`,
  },
}));
