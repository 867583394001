import { Avatar, Badge, Box, Button, Flex, rem, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import useModal from '@common/composites/modal/modal-hook';
import FlexbaseTable from 'components/table/flexbase-table';
import { useStyles } from '../styles';
import { UserIdState } from 'recoil-state/application/onboarding-form.state';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { CardByUser } from 'services/flexbase/banking.model';
import { getInitialsOfNames } from 'utilities/object';
import {
  formatCurrency,
  formatDepositAccountDetails,
} from 'utilities/formatters';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import GenericError from '../../../components/generic-error';
import DebitCardDetails from '@common/charge-and-credit-cards/debit-card-details';
import Header from './debit-cards-header/debit-cards-header';
import { useGetDebitCards } from '@queries/use-debit-cards';
import { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { cardIsChargeCard, FlexCard } from '@services/flexbase/card.model';
import { useDebitCardsFilters } from '@common/utilities/filter/cards-filter/use-cards-filters';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';

const DebitCards = () => {
  const modal = useModal();
  const { classes, theme } = useStyles();
  const { id: accountId } = useParams();
  const userId = useRecoilValue(UserIdState);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const {
    data: debitCardsData,
    isPending,
    isError,
    refetch,
  } = useGetDebitCards();
  const {
    data: bankingAccountsData,
    isLoading: isLoadingBankingAccounts,
    isError: isErrorBankingAccounts,
    refetch: refetchBankingAccounts,
  } = useGetDepositAccounts();
  const { activeFiltersArray } = useDebitCardsFilters();
  const debitCards =
    debitCardsData?.filter(
      (c) => !cardIsChargeCard(c as unknown as FlexCard),
    ) || [];

  const cardType: Record<string, string> = {
    VIRTUAL: 'Virtual',
    PHYSICAL: 'Physical',
  };

  const cardStatus: Record<string, string> = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Unactivated',
    terminated: 'Canceled',
  };

  const columns: TableColumn<CardByUser & { depositAccount: string }>[] = [
    {
      name: 'Cardholder',
      cell: (row) => (
        <Flex align="center">
          <Avatar>{getInitialsOfNames(row.holder)}</Avatar>
          <Text mx={rem(12)}>{row.holder}</Text>
        </Flex>
      ),
      selector: (row) => row.holder,
      sortable: true,
    },
    {
      name: 'Card name',
      cell: (row) => row.cardName,
      selector: (row) => row.cardName,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => cardType[row.cardType],
      sortable: true,
      compact: true,
    },
    {
      name: 'Deposit account',
      selector: (row) => row.depositAccount,
      sortable: true,
      compact: true,
      grow: 1.5,
    },
    {
      name: 'Debit card number',
      cell: (row) => getPaddedAccountMask(row.cardNumber, 4),
      compact: true,
    },
    {
      name: 'Spent this month',
      compact: true,
      sortable: true,
      selector: (row) => formatCurrency(row.monthToDateSpends?.mtdSpend || 0),
    },
    {
      name: 'Status',
      compact: true,
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        let backgroundColor, textColor;

        switch (row.status) {
          case 'suspended':
            backgroundColor = theme.colors.promote[0];
            textColor = theme.colors.promote[5];
            break;
          case 'active':
            backgroundColor = theme.colors.sage[1];
            textColor = theme.colors.sage[6];
            break;
          case 'terminated':
            backgroundColor = theme.colors.critical[0];
            textColor = theme.colors.critical[4];
            break;
          default:
            backgroundColor = theme.colors.neutral[2];
            textColor = theme.colors.neutral[10];
        }

        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text c={textColor}>{cardStatus[row.status]}</Text>
          </Badge>
        );
      },
    },
  ];

  const columnsSm: TableColumn<CardByUser & { depositAccount: string }>[] = [
    {
      grow: 5,
      sortable: true,
      name: 'Cardholder',
      selector: (row) => row.holder,
      cell: (row) => (
        <Box>
          <Flex align="center">
            <Text>{row.holder}</Text>
            <Badge
              className="badge"
              styles={{
                root: {
                  backgroundColor:
                    row.status === 'suspended'
                      ? 'rgba(48, 44, 255, 0.1)'
                      : '#EEEEF3',
                  display:
                    row.status === 'active' && userId !== row.userId
                      ? 'none'
                      : 'inline-flex',
                },
                inner: { color: `${theme.colors.neutral[10]} !important` },
              }}
            >
              {userId === row.userId && row.status === 'active'
                ? 'You'
                : cardStatus[row.status]}
            </Badge>
          </Flex>
          <Text>{getPaddedAccountMask(row.cardNumber, 4)}</Text>
        </Box>
      ),
    },
    {
      name: 'Type',
      compact: true,
      sortable: true,
      selector: (row) => row.cardType,
      cell: (row) => cardType[row.cardType],
    },
  ];

  const handleRowClicked = (card: CardByUser) => {
    useMobileView
      ? modal.openFullModal(<DebitCardDetails card={card} />)
      : modal.openRightModal(<DebitCardDetails card={card} />);
  };

  const accounts = useMemo(
    () => bankingAccountsData?.accounts ?? [],
    [bankingAccountsData?.accounts],
  );

  const sortDebitCards = useMemo(() => {
    const formatCard = (card: CardByUser) => ({
      ...card,
      depositAccount: formatDepositAccountDetails(
        accounts,
        card.depositAccountId,
      ),
    });

    const terminatedCards = debitCards
      .filter((card) => card.status === 'terminated')
      .map(formatCard);

    const nonTerminatedCards = debitCards
      .filter((card) => card.status !== 'terminated')
      .map(formatCard);

    return [...nonTerminatedCards, ...terminatedCards];
  }, [debitCards, accounts]);

  const filteredTableData = useMemo(() => {
    return sortDebitCards.filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [sortDebitCards, activeFiltersArray]);

  const handleRefetch = () => {
    refetch();
    refetchBankingAccounts();
  };

  if (isPending || isLoadingBankingAccounts) {
    return <SkeletonLoading />;
  }

  if (isError || isErrorBankingAccounts) {
    return (
      <GenericError>
        <p>An unhandled error occurred getting the debit cards.</p>
        <Button variant="primary-light" onClick={handleRefetch} mt={rem(20)}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <Box className={classes.debitCardsContent}>
      <Box className={classes.card}>
        <Header accountId={accountId} debitCards={debitCards} />
        <FlexbaseTable
          onRowClicked={handleRowClicked}
          isFetchingData={isPending}
          data={filteredTableData}
          columns={useMobileView ? columnsSm : columns}
          pagination={filteredTableData.length > 8}
          noDataComponent={
            <Text fz={rem(24)} fw={500}>
              No debit cards yet
            </Text>
          }
        />
      </Box>
    </Box>
  );
};

export default DebitCards;
