import { Box, Radio, RadioProps, Stack, Text, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { ReactNode } from 'react';

type SpendPlanRadioCardProps = Pick<RadioProps, 'value' | 'checked'> & {
  label: string;
  description: string;
  icon?: ReactNode;
};

export const SpendPlanRadioCard = (props: SpendPlanRadioCardProps) => {
  const { label, description, value, checked, icon } = props;
  const { classes } = useSpendPlanRadioCardStyles();

  return (
    <label>
      <Stack gap={12} className={classes.containerStack}>
        {icon ? icon : null}

        <Box className={classes.flexDynamic} ta="center">
          <Text className={classes.label}>{label}</Text>
          <Text className={classes.description}>{description}</Text>
        </Box>

        <Box className={classes.flexStatic}>
          <Radio checked={checked} value={value} />
        </Box>
      </Stack>
    </label>
  );
};

const useSpendPlanRadioCardStyles = createStyles((theme) => ({
  containerStack: {
    alignItems: 'center',
    backgroundColor: theme.colors.neutral[0],
    borderColor: 'transparent',
    borderRadius: theme.radius.sm,
    borderStyle: 'solid',
    borderWidth: 1,
    fontSize: rem(14),
    fontWeight: 400,
    height: '100%',
    padding: `${theme.spacing.sm} ${theme.spacing.xs}`,

    '&:has(:checked)': {
      borderColor: theme.primaryColor,
    },
  },
  flexDynamic: {
    flexGrow: 1,
    flexShrink: 1,
  },
  flexStatic: {
    flexGrow: 0,
    flexShrink: 0,
  },
  label: {
    color: theme.primaryColor[9],
  },
  description: {
    color: theme.colors.neutral[8],
  },
}));
