import { Badge, Box, Divider, Flex, Group, rem, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DateTime } from 'luxon';
import AttachtmentsSection from './attachtment-section';
import { useStyles } from './styles';
import ManagePayment, { getIsCancelable } from '@common/manage-payment';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { isFuturePayment } from '@utilities/dates';
import { PiDownloadSimpleBold, PiXLight } from 'react-icons/pi';
import { formatPaymentType } from 'areas/billpay/payments/utils/payments';
import { PaymentWithInvoiceNumber } from '../payments';

type Props = {
  row: PaymentWithInvoiceNumber;
  onClose: () => void;
};

const confirmationCopy = `By clicking 'Approve', I authorize Flex to initiate the transaction detailed above. Flex is powered by Flexbase Technologies, Inc`;

const BillPayPaymentDetails = ({ row, onClose }: Props) => {
  const { classes, cx } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 755px)');
  const isAdmin = useRecoilValue(IsAdmin);

  const createdAt = DateTime.fromSQL(row.createdAt);
  const formattedDate = `${createdAt.toFormat('DDD')} at ${createdAt.toFormat(
    'ttt',
  )}`;

  const deliveryFormattedDate = DateTime.fromFormat(
    row.scheduledFor ?? '',
    'yyyy-MM-dd',
  ).toFormat('DD');

  const futurePayment = isFuturePayment(row.scheduledFor ?? '');

  const needsAction = getIsCancelable(row.status, row.type);
  const showActionSection = isAdmin && needsAction;
  const showConfirmationDisclaimer =
    row.status === 'AwaitingApproval' || row.status === 'AwaitingConfirmation';
  const showDownloadReceiptButton =
    !futurePayment && row.payDirection !== 'Debit';

  return (
    <Box className={classes.paymentDetailsModal}>
      <Box className={cx(classes.header, classes.rowContent)}>
        <Box>
          <Box style={{ display: 'flex', gap: rem(14), alignItems: 'center' }}>
            <Text fz={useMobileView ? rem(20) : rem(36)}>{row.payAmount}</Text>
            <Badge>{row.status}</Badge>
          </Box>
          <Text fz={useMobileView ? rem(10) : rem(14)}>{formattedDate}</Text>
        </Box>
        <Box>
          <PiXLight
            color="neutral.0"
            onClick={onClose}
            size={useMobileView ? '1.25rem' : '1.9rem'}
          />
        </Box>
      </Box>
      <Group
        gap="xl"
        grow
        mx={useMobileView ? rem(20) : rem(50)}
        align="initial"
        mt={24}
      >
        <Box>
          <Text className={classes.smallText}>Recipient</Text>
          <Text mt={rem(5)} className={classes.bigText}>
            {row.payCtrParty}
          </Text>
        </Box>
      </Group>

      <Divider my={rem(24)} />
      <Group
        grow
        gap="xl"
        mx={useMobileView ? rem(20) : rem(50)}
        align="flex-start"
      >
        <Box>
          <Text className={classes.smallText}>Type</Text>
          <Text mt={rem(5)} className={classes.bigText}>
            {formatPaymentType(row.type)}
          </Text>
        </Box>
        <Box>
          <Text className={classes.smallText}>{`Invoice Number`}</Text>
          <Text mt={rem(5)} className={classes.bigText}>
            {row.invoiceNumber}
          </Text>
        </Box>
      </Group>

      <Divider my={rem(24)} />
      <Group grow gap="xl" mx={useMobileView ? rem(20) : rem(50)}>
        <Box>
          <Text className={classes.smallText}>External Notes</Text>
          <Text className={classes.bigText}>{row.payDescription}</Text>
        </Box>
        {row.scheduledFor && (
          <Box>
            <Text className={classes.smallText}>Scheduled to be sent on</Text>
            <Text className={classes.bigText}>{deliveryFormattedDate}</Text>
          </Box>
        )}
      </Group>
      {row.notes !== '' && (
        <>
          <Divider my={rem(24)} />
          <Box className={classes.detailsContainer}>
            <Text className={classes.smallText}>Internal Notes</Text>
            <Text className={classes.bigText}>{row.notes}</Text>
          </Box>
        </>
      )}

      <AttachtmentsSection {...{ row }} />

      {showDownloadReceiptButton && (
        <>
          <Divider my={rem(24)} />
          <Box className={classes.detailsContainer}>
            <Text className={classes.smallText}>Payment receipt</Text>
            <Box
              className={classes.receiptSection}
              onClick={() => window.open(`/payment/receipt/${row?.id}`)}
            >
              <PiDownloadSimpleBold size={'0.9rem'} />
              <Text>Download</Text>
            </Box>
          </Box>
        </>
      )}

      {showActionSection && (
        <>
          <Divider my={rem(24)} />

          {showConfirmationDisclaimer && (
            <>
              <Box className={classes.detailsContainer}>
                <Text className={classes.smallText}>Action required</Text>
              </Box>
              <Text className={classes.paymentConfirmationDisclaimer}>
                {confirmationCopy}
              </Text>
            </>
          )}

          <Flex justify="end" mt="sm">
            <ManagePayment
              paymentId={row.id}
              createdBy={row.userId}
              approvedBy={row.approvedBy}
              status={row.status}
              type={row.type}
              onSuccess={onClose}
            />
          </Flex>
        </>
      )}
    </Box>
  );
};

export default BillPayPaymentDetails;
