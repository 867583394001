import ErrorIcon from '@common/composites/error-icon';
import WizardFullscreenMessageCard from '@common/composites/wizard-fullscreen-message-card';
import { Button } from '@mantine/core';
import { InvoiceWizardError } from '../invoice-wizard';

type PaymentDetailsStepErrorProps = {
  submissionError: InvoiceWizardError;
  onClear: () => void;
};

const InvoiceWizardStepError = ({
  submissionError,
  onClear,
}: PaymentDetailsStepErrorProps) => {
  const handleRetry = () => {
    onClear();
  };

  return (
    <WizardFullscreenMessageCard
      icon={<ErrorIcon />}
      title="Sorry about that..."
      subtitle={submissionError.message}
    >
      <Button size="sm" onClick={handleRetry} variant="primary-filled">
        Try again
      </Button>
    </WizardFullscreenMessageCard>
  );
};

export default InvoiceWizardStepError;
