import { createStyles } from '@mantine/emotion';

export const useOnboardingStepperStyles = createStyles((theme) => ({
  webContainer: {
    marginTop: 48,
    paddingBottom: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: 1,
  },
  mobileContainer: {
    width: '100%',
    padding: '20px 16px 0 16px',
    margin: 'unset',
  },
  header: {
    padding: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
      padding: 16,
    },
  },
  footerContainer: {
    backgroundColor: theme.colors.neutral[3],
  },
  footerDisclosure: {
    color: theme.primaryColor,
  },
  scrollContainer: {
    backgroundColor: theme.colors.neutral[3],
  },
  notices: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10rem',
    '@media(max-width: 767px)': {
      marginLeft: 'auto',
    },
  },
  headerSupport: {
    display: 'flex',
    gap: 16,
    cursor: 'pointer',
  },
  headerImage: {},
  headerText: {
    color: theme.colors.neutral[7],
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
    alignItems: 'center',
    display: 'flex',
    gap: 8,
  },
  progressSaved: {
    color: theme.colors.primary[6],
    '@media(max-width: 550px)': {
      svg: {
        marginRight: 0,
      },
    },
  },
  creditImpactNotice: {
    '@media(max-width: 767px)': {
      justifyContent: 'center',
      svg: {
        marginRight: -7,
      },
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 32,
  },
  headerBnpl: {
    cursor: 'default',
    alignItems: 'center',
  },
  banner: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    height: '217px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
  },
  progressRoot: {
    backgroundColor: theme.colors.neutral[2],
  },
  containerLogout: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    '@media(max-width: 767px)': {
      gap: 10,
    },
  },
}));
