import { Modal, rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import MakeBillPayCreditPayment from './make-bill-pay-credit-payment';
import { ModifiedLineTransaction } from '../outstanding-table';

const BillPayCreditPaymentModal = ({
  transaction,
}: {
  transaction: ModifiedLineTransaction;
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handleClose = () => {
    navigate('..', { relative: 'path' });
  };

  return (
    <Modal
      opened={!!transaction.id}
      onClose={handleClose}
      styles={{
        header: { display: 'none' },
        inner: { width: isMobile ? '100vw' : rem(642), right: '0px' },
        body: { padding: 0 },
      }}
      transitionProps={{ transition: 'fade' }}
      closeOnClickOutside
      fullScreen
    >
      <MakeBillPayCreditPayment
        transaction={transaction}
        onCloseModal={handleClose}
      />
    </Modal>
  );
};

export default BillPayCreditPaymentModal;
