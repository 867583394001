import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { SwitchFilter } from '@common/utilities/filter/switch-filter';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import {
  useCreditTransactionModalFilters,
  useMarqetaTransactionsModalFilters,
} from '../use-credit-transaction-filters';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';
import { Stack, SegmentedControl, Text } from '@mantine/core';

type CreditTransactionsAttachmentsFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

export const CreditAttachmentsFilter = ({
  filterHook,
}: CreditTransactionsAttachmentsFilterProps) => {
  const { addFilter, getFilterByKey } = filterHook;

  const handleSwitchChange = (selectedKey: string, selectedValue: boolean) => {
    let label = '';
    let filterFn: (row: CreditTransactionsTableRow) => boolean = () => true;

    switch (selectedKey) {
      case 'memo':
        label = selectedValue ? 'Has memo' : 'No memo';
        filterFn = (row) =>
          selectedValue ? !!row.description : !row.description;
        break;
    }

    addFilter(selectedKey, {
      label,
      filterValue: selectedValue,
      key: selectedKey,
      fn: filterFn,
      showChip: selectedValue,
    });
  };

  const handleReceiptFilterChange = (value: string) => {
    // Clear existing receipt filters
    addFilter('has-receipt', {
      label: '',
      filterValue: false,
      key: 'has-receipt',
      fn: () => true,
      showChip: false,
    });
    addFilter('no-receipt', {
      label: '',
      filterValue: false,
      key: 'no-receipt',
      fn: () => true,
      showChip: false,
    });

    // If "all" is selected, we don't need to add any new filters
    if (value === 'all') return;

    const key = value;
    const label = value === 'has-receipt' ? 'Has receipt' : 'No receipt';
    const filterFn = (row: CreditTransactionsTableRow) =>
      value === 'has-receipt' ? !!row.docId : !row.docId;

    addFilter(key, {
      label,
      filterValue: true,
      key,
      fn: filterFn,
      showChip: true,
    });
  };

  // Determine current receipt filter value
  const currentReceiptFilter = getFilterByKey('has-receipt')?.filterValue
    ? 'has-receipt'
    : getFilterByKey('no-receipt')?.filterValue
      ? 'no-receipt'
      : 'all';

  return (
    <Stack gap="xs">
      <SwitchFilter
        options={[{ label: 'Has memo', key: 'memo' }]}
        value={{
          memo: getFilterByKey('memo')?.filterValue,
        }}
        onOptionChange={handleSwitchChange}
      />

      <Stack gap={4}>
        <Text size="md" fw={500}>
          Receipt Filter
        </Text>
        <SegmentedControl
          value={currentReceiptFilter}
          onChange={handleReceiptFilterChange}
          data={[
            { label: 'All', value: 'all' },
            { label: 'Has receipt', value: 'has-receipt' },
            { label: 'No receipt', value: 'no-receipt' },
          ]}
          variant="secondary"
          fullWidth
        />
      </Stack>
    </Stack>
  );
};

export const CreditTransactionsAttachmentsFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditAttachmentsFilter filterHook={filterHook} />;
};

export const MarqetaTransactionsAttachmentsFilter = () => {
  const filterHook = useMarqetaTransactionsModalFilters();

  return <CreditAttachmentsFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsAttachmentsFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditAttachmentsFilter filterHook={filterHook} />;
};
