import {
  Box,
  Button,
  Center,
  Divider,
  Loader,
  Paper,
  rem,
  SegmentedControl,
  Text,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { isHoliday, isWeekend } from '@utilities/dates';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { DateTime } from 'luxon';
import { useState } from 'react';
import {
  InvoiceWizard,
  PaymentInfo,
  PaymentSource,
  useInvoiceWizard,
} from '../../invoice-wizard';
import { LineOfCreditSource } from './line-of-credit-source';
import { useCreditLines } from '@queries/use-credit-lines';
import { ACH_COPY, WIRE_COPY } from 'constants/constants';
import { PayMethod } from 'types/payments';
import PaymentOption from './payment-options/payment-options';
import { PiBank } from 'react-icons/pi';
import WizardFullscreenMessageCard from '@common/composites/wizard-fullscreen-message-card';
import ErrorIcon from '@common/composites/error-icon';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { currencyToCents } from '@utilities/formatters';
import { BankingAccountSource } from './banking-account-source';
import { useBillPayCreditFlag } from '@utilities/feature-flags';
import { useGetBillpayConfiguration } from '@queries/use-bill-pay';

const MAX_PAYMENT_DATE = DateTime.now().plus({ years: 2 }).toJSDate();
const MIN_PAYMENT_DATE = DateTime.now().toJSDate();
const ACH_COPY_LOC =
  '1 business day | Same-day if sent by 3:00 PM ET for amounts under $1M';
const WIRE_COPY_LOC = '1 business day | Same-day if sent by 6:00 PM ET';

const PAYMENT_SOURCES = [
  {
    value: PaymentSource.ACCOUNT,
    label: 'Banking account',
  },
  {
    value: PaymentSource.LOC,
    label: 'Pay later',
  },
];

const sendOnDateLabel: Partial<Record<PayMethod, string>> = {
  wire: WIRE_COPY,
  ach: ACH_COPY,
};

const sendOnDateLabelLoc: Partial<Record<PayMethod, string>> = {
  wire: WIRE_COPY_LOC,
  ach: ACH_COPY_LOC,
};

const AmountSourceStep = () => {
  const {
    data: depositAccounts,
    isLoading: isLoadingDepositAccounts,
    isError: isDepositAccountsError,
    refetch: refetchDepositAccounts,
  } = useGetDepositAccounts();
  const paymentAccounts = depositAccounts?.accounts
    .filter((account) => account.status === 'Open')
    .sort((a, b) => b.balance - a.balance)
    .map((account) => ({ ...account, plaidOrDeposit: 'deposit' as const }));

  return (
    <AmountSourceStepContent
      isErrorPaymentAccounts={isDepositAccountsError}
      paymentAccounts={paymentAccounts || []}
      refetchPaymentAccountsData={refetchDepositAccounts}
      isLoadingPaymentAccounts={isLoadingDepositAccounts}
    />
  );
};

type AmountSourceStepContentProps = {
  paymentAccounts: DepositAccount[];
  isLoadingPaymentAccounts: boolean;
  isErrorPaymentAccounts: boolean;
  refetchPaymentAccountsData: () => void;
};

const AmountSourceStepContent = ({
  paymentAccounts,
  isErrorPaymentAccounts,
  isLoadingPaymentAccounts,
  refetchPaymentAccountsData,
}: AmountSourceStepContentProps) => {
  const { businessId } = useRecoilValue(ApplicationState);
  const { state, setState, onNext, onBack, goToNextStep, goToPreviousStep } =
    useInvoiceWizard();
  const hasBillPayCreditFeatureFlag = useBillPayCreditFlag();

  const [notEnoughFundsError, setNotEnoughFundsError] = useState<
    string | undefined
  >(undefined);
  const [notEnoughCreditError, setNotEnoughCreditError] = useState<
    string | undefined
  >(undefined);

  const { data: lines = [], isLoading: isLoadingLinesOfCredit } =
    useCreditLines(businessId);

  const {
    data: billpayConfigurationData,
    isPending: isPendingBillpayConfiguration,
  } = useGetBillpayConfiguration(businessId);

  const { isActionDisabled = false, isInvoiceDraft, paymentInfo } = state || {};

  const isDisabled = !isInvoiceDraft || isActionDisabled;

  const initialSendFrom =
    (paymentInfo && paymentInfo.sendFrom) || paymentAccounts[0];
  const initialCreditProgramId = paymentInfo && paymentInfo.creditProgramId;
  const initialPaymentSource =
    (paymentInfo && paymentInfo.paymentSource) || PaymentSource.ACCOUNT;

  const form = useForm<PaymentInfo>({
    initialValues: {
      sendFrom: initialSendFrom,
      sendOn: paymentInfo?.sendOn ?? DateTime.now().toJSDate(),
      paymentSource: initialPaymentSource,
      creditProgramId: initialCreditProgramId,
    },
    validate: {
      sendFrom: (value, values) => {
        if (values.paymentSource === PaymentSource.ACCOUNT) {
          return value ? null : 'Select a source account';
        }
      },
      sendOn: (value) =>
        value instanceof Date && !isNaN(value.getTime())
          ? null
          : 'Select a valid date',
    },
  });

  const onAccountChange = (value: string) => {
    const account = paymentAccounts.find((acc) => acc.id === value);
    form.setFieldValue('sendFrom', account as PlaidAccount | DepositAccount);
    const invoiceTotalCents = state.invoiceTotal?.cents || 0;
    // we need to convert the available balance from an external account to cents
    const availableAmount = account?.available || 0;
    validateAccountHasEnoughFunds(invoiceTotalCents, availableAmount);
  };

  const onCreditProgramChange = (value: string) => {
    const creditProgram = lines.find((cp) => cp.id === value);
    form.setFieldValue('creditProgramId', creditProgram?.id);
    const invoiceTotalCents = state.invoiceTotal?.cents || 0;
    const totalFees = creditProgram?.fee || 0;
    const availableAmount = creditProgram?.available || 0;
    const availableCredit = creditProgram?.limit || 0;
    validateCreditProgramHasEnoughCreditAndFunds(
      invoiceTotalCents,
      availableCredit,
      availableAmount,
      totalFees,
    );
  };

  const clearErrors = () => {
    setNotEnoughCreditError(undefined);
    setNotEnoughFundsError(undefined);
  };

  const onPaymentSourceChange = (value: string | null) => {
    form.setFieldValue('paymentSource', value as PaymentSource);
    if (value === PaymentSource.ACCOUNT) {
      form.setFieldValue('creditProgramId', undefined);
      clearErrors();
    }
    if (value === PaymentSource.LOC) {
      form.setFieldValue('sendFrom', undefined);
      clearErrors();
      const flexCreditLine = lines.find((cp) => cp.issuer === 'lithic')?.id;
      onCreditProgramChange(flexCreditLine || '');
    }
  };

  const validateAccountHasEnoughFunds = (
    invoiceTotal: number,
    availableFunds: number,
  ) => {
    if (availableFunds < invoiceTotal) {
      setNotEnoughFundsError('Selected account has insufficient funds.');
      return false;
    }
    setNotEnoughFundsError(undefined);
    return true;
  };

  const validateCreditProgramHasEnoughCreditAndFunds = (
    invoiceTotal: number,
    availableCredit: number,
    availableFunds: number,
    totalFees: number,
  ) => {
    if (availableCredit < invoiceTotal) {
      setNotEnoughCreditError('Insufficient credit');
      return false;
    }
    if (availableFunds < Math.round(invoiceTotal * totalFees)) {
      setNotEnoughFundsError(
        'Selected account has insufficient funds to cover the fee.',
      );
      return false;
    }
    setNotEnoughCreditError(undefined);
    setNotEnoughFundsError(undefined);
    return true;
  };

  onNext(() => {
    form.validate();
    const invoiceTotalCents = state.invoiceTotal?.cents || 0;
    const sendFrom = form.values.sendFrom;
    const availableFunds =
      (sendFrom?.plaidOrDeposit === 'plaid'
        ? currencyToCents(Number(sendFrom?.available))
        : sendFrom?.available) || 0;
    const creditProgram = lines.find(
      (cp) => cp.id === form.values.creditProgramId,
    );
    const availableCredit = creditProgram?.limit || 0;
    const availableCreditFunds = creditProgram?.available || 0;
    const totalFees = creditProgram?.fee || 0;
    if (
      form.isValid() &&
      (form.values.paymentSource === PaymentSource.ACCOUNT
        ? validateAccountHasEnoughFunds(invoiceTotalCents, availableFunds)
        : validateCreditProgramHasEnoughCreditAndFunds(
            invoiceTotalCents,
            availableCredit,
            availableCreditFunds,
            totalFees,
          ))
    ) {
      setState((prev) => ({
        ...prev,
        paymentInfo: form.values,
      }));
      goToNextStep();
    }
  });

  onBack(() => {
    setState((prev) => ({
      ...prev,
      paymentInfo: form.values,
    }));
    goToPreviousStep();
  });

  const paymentOptions = [
    {
      Icon: PiBank,
      label: 'Bank account',
      accounts: paymentAccounts,
      value: PaymentSource.ACCOUNT,
      placeholder: 'Select account',
    },
  ];

  if (isLoadingPaymentAccounts || isLoadingLinesOfCredit) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (isErrorPaymentAccounts) {
    return (
      <WizardFullscreenMessageCard
        icon={<ErrorIcon />}
        title="Somenthing went wrong"
        subtitle="There was an error getting your payment accounts"
      >
        <Button onClick={refetchPaymentAccountsData} variant="primary-filled">
          Try again
        </Button>
      </WizardFullscreenMessageCard>
    );
  }

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Paper radius="xs" bg="neutral.0">
        <Box w="100%" p="xxl">
          <Text fw="500" size="sm">
            Recipient gets
          </Text>
          <Text fz={rem(40)} lh={1}>
            {state.invoiceTotal?.formatted}
          </Text>
          <Divider />
        </Box>
      </Paper>
      {hasBillPayCreditFeatureFlag && (
        <Box mt="xxxl">
          <Text size="sm">Pay via</Text>
          <SegmentedControl
            fullWidth
            {...form.getInputProps('paymentSource')}
            onChange={onPaymentSourceChange}
            data={PAYMENT_SOURCES}
            disabled={isDisabled}
            variant="secondary"
            data-testid="payment-source"
          />
        </Box>
      )}

      <Box mt="xl">
        {form.values.paymentSource === PaymentSource.ACCOUNT &&
          (hasBillPayCreditFeatureFlag ? (
            <PaymentOption
              options={paymentOptions}
              sendFrom={form.values.sendFrom}
              onAccountChange={onAccountChange}
              isActionDisabled={isActionDisabled}
              notEnoughFundsError={notEnoughFundsError}
              initialPaymentSource={form.values.paymentSource}
            />
          ) : (
            <BankingAccountSource
              sendFrom={form.values.sendFrom}
              isActionDisabled={isActionDisabled}
              paymentAccounts={paymentAccounts}
              onAccountChange={onAccountChange}
              notEnoughFundsError={notEnoughFundsError}
            />
          ))}
        {form.values.paymentSource === PaymentSource.LOC &&
          billpayConfigurationData?.billpayConfiguration && (
            <LineOfCreditSource
              billpayConfiguration={
                billpayConfigurationData?.billpayConfiguration
              }
              isPendingBillpayConfiguration={isPendingBillpayConfiguration}
              creditPrograms={lines}
              creditProgramId={form.values.creditProgramId}
              onCreditProgramChange={onCreditProgramChange}
              notEnoughCreditError={notEnoughCreditError}
              notEnoughFundsError={notEnoughFundsError}
            />
          )}
      </Box>

      <DatePickerInput
        mt="xl"
        label="Send on"
        valueFormat="MMM DD, YYYY"
        minDate={MIN_PAYMENT_DATE}
        maxDate={MAX_PAYMENT_DATE}
        excludeDate={(date) => isWeekend(date) || isHoliday(date)}
        {...form.getInputProps('sendOn')}
        disabled={isActionDisabled}
      />
      {state.recipientAccount?.type && (
        <Text size="xs" c={'neutral.8'}>
          {form.values.paymentSource === PaymentSource.LOC
            ? sendOnDateLabelLoc[state.recipientAccount?.type]
            : sendOnDateLabel[state.recipientAccount?.type]}
        </Text>
      )}
    </InvoiceWizard.Step>
  );
};

AmountSourceStep.stepId = 'amount-source';

export default AmountSourceStep;
