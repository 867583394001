export const formatCurrency = (
  value: string | number, // value in dollars
  currency = 'USD',
  format = 'en-US',
) => {
  if (value === '' || value === undefined) {
    return value;
  }

  const stringValue = String(value);

  if (!stringValue.match(/\d/g)) {
    return '';
  }

  const removeComma = stringValue.replace(/,/g, '');
  const hasDecimal = removeComma.match(/\./);
  const [int, decimals] = removeComma.split('.');

  const formattedInt = new Intl.NumberFormat(format, {
    currency,
    style: 'currency',
  })
    .format(Number(int) || Number(removeComma))
    .replace(currency.slice(0, 2), '');

  if (!int || !hasDecimal) {
    return formattedInt;
  }
  return decimals
    ? new Intl.NumberFormat(format, {
        currency,
        style: 'currency',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
        .format(Number(`${int || removeComma}.${decimals}`))
        .replace(currency.slice(0, 2), '')
    : `${formattedInt}.`;
};

export const round = (num: string) =>
  Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;

export const formatCurrencyInput = (input: string) => {
  if (input.length === 0) {
    return '';
  } else {
    const sanitizedInput = input.replace(/[^0-9.]/g, '');

    if (sanitizedInput.length === 0) {
      return '';
    }

    const amount = parseFloat(sanitizedInput);

    if (!isNaN(amount)) {
      return amount > 5000000
        ? '5,000,000'
        : amount.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    }
  }
};

export const stripCurrencySymbol = (value: string) => {
  return value.replace(/[^0-9.]/g, '');
};

export const formatCents = (value: string | number) => {
  let cents;
  if (typeof value === 'string') {
    cents = parseFloat(value);
  } else {
    cents = value;
  }
  return cents / 100;
};

export const currencyToCents = (value: number) => {
  return Math.round(value * 100);
};

export const centsToFormattedCurrency = (value: number | string) => {
  return formatCurrency(formatCents(value));
};
