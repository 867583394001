import { Anchor, Button, Group, Stack, Text } from '@mantine/core';
import { useQueryParams } from '../../utilities/url/query-param';
import AuthPageContainer from 'areas/login/login/auth-page';
import { Link, useNavigate } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { ZendeskAPI } from 'react-zendesk';

export const InvalidMagicLinkPage = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const errorType = queryParams.get('type') ?? 'invalid';

  let errorMessage;

  switch (errorType) {
    case 'same-device':
      errorMessage =
        'To reset your password, you must click the password reset link from the same device and browser that you used to initiate the reset.';
      break;
    case 'invalid':
    default:
      errorMessage = (
        <>
          The link you clicked is invalid or expired. If you were attempting to
          reset your password, please{' '}
          <Anchor
            component={Link}
            to="/forgot-password"
            data-testid="button-reset-password"
            c="primary.1"
          >
            click here
          </Anchor>{' '}
          to request another link. Alternatively, contact support.
        </>
      );
      break;
  }

  const handleButtonClick = () => {
    if (errorType === 'same-device') {
      navigate('/login');
    } else {
      ZendeskAPI('messenger', 'open');
    }
  };

  return (
    <AuthPageContainer>
      <Anchor
        component={Link}
        to="/login"
        data-testid="button-login"
        c="neutral.0"
      >
        <Group align="center" gap="0.25rem" fz={16}>
          <FiChevronLeft fontSize="1.5rem" />
          Back
        </Group>
      </Anchor>
      <Stack gap="xl">
        <Text fz="3rem" ff="Redaction" c="neutral.0" lh={1}>
          Invalid link
        </Text>
        <Text fz="1.25rem" c="neutral.0">
          {errorMessage}
        </Text>
        <Button fullWidth variant="primary-light" onClick={handleButtonClick}>
          {errorType === 'same-device' ? 'Back To Login' : 'Contact Support'}
        </Button>
      </Stack>
    </AuthPageContainer>
  );
};
