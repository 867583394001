import {
  Box,
  Button,
  ComboboxItem,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useStyles } from './transaction-details.styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import { showNotification } from '@mantine/notifications';
import { useRecoilValue } from 'recoil';
import { IsAccountant } from '../../../../../recoil-state/application/product-onboarding';
import { useDetailsStyles } from './details-styles';
import { formatDatetime } from 'utilities/formatters/format-datetime';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';
import { ExpensesStatusEnum } from '@flexbase-eng/types/dist/accounting';
import FlexSelect from '@common/composites/flex-select';
import { useSyncedTransactionsQuery } from '@queries/use-integrations';
import { useForm } from '@mantine/form';
import { useSyncTransactionExpenses } from '@utilities/custom-hooks/use-sync-transaction-expenses';
import { SyncIcon } from 'assets/svg';
import {
  useActiveExpenseLink,
  useSyncExpenseAllowed,
} from '@utilities/integrations/accounting';
import {
  DetailsBody,
  DetailsFieldsRow,
  DetailsFieldsSection,
  DetailsHeader,
} from './components/details-components';
import { LineOfCredit } from '@services/flexbase/credit.model';
import { usePatchBankingCardSwipe } from '@queries/use-card-swipes';

type Props = {
  tableRow: CreditTransactionsTableRow;
  focusElement?: FocusElement;
  lineOfCredit: LineOfCredit;
  status: string;
  closeModal: () => void;
  onTransactionUpdate?: () => void;
};

export type FocusElement = 'receipt' | 'memo' | 'flag';

export const FundingDetails = ({
  focusElement,
  closeModal,
  onTransactionUpdate,
  tableRow,
  status,
  lineOfCredit,
}: Props) => {
  const {
    id,
    purchaseDate,
    settledAt: settledDate,
    description,
    toFrom,
    authInfo,
  } = tableRow;

  const theme = useMantineTheme();
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [memo, setMemo] = useState(description);
  const [loadingMemo, setLoadingMemo] = useState(false);
  const { mutateAsync: patchChargeCardSwipe } = usePatchBankingCardSwipe();
  const memoRef = useRef<HTMLTextAreaElement>(null);
  const isAccountant = useRecoilValue(IsAccountant);
  const isSyncExpenseAllowed = useSyncExpenseAllowed();
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();

  const expenseCategorySelectItems = useMemo<ComboboxItem[]>(() => {
    return (
      expenseLink?.accounts?.map((category) => ({
        key: category.id,
        label: category.displayName ?? category.name,
        value: category.id,
      })) || []
    );
  }, [expenseLink?.accounts]);
  const { data: syncedExpenses, refetch: refetchSyncedExpenses } =
    useSyncedTransactionsQuery({
      connectionId,
      transactionIds: [id],
      updateCache: true,
    });
  const { syncTransactions } = useSyncTransactionExpenses({
    connectionId,
    syncedExpenses,
  });
  const syncedExpense = syncedExpenses?.expenses.find(
    (e) => e.transactionId === id,
  );

  const [waitingForSyncUpdate, setWaitingForSyncUpdate] = useState(false);

  useEffect(() => {
    setWaitingForSyncUpdate(false);
  }, [syncedExpense]);

  const expenseForm = useForm<{
    categoryId: string;
    status: ExpensesStatusEnum | undefined;
  }>({
    /**
     * tableRow.syncedExpense is preloaded and available during mount
     * Don't use the live syncedExpense for the initialValues or it will init to undefined
     */
    initialValues: {
      categoryId: tableRow.syncedExpense?.expense?.account?.id || '',
      status: tableRow.syncedExpense?.status,
    },
  });

  const notifyError = (error: string) => {
    showNotification({
      title: 'Oops',
      message: error,
      color: 'red',
    });
  };

  const notifySuccess = (msg: string) => {
    showNotification({
      title: 'Success!',
      message: msg,
      color: 'green',
    });
  };

  const { cx, classes } = useStyles({
    flagMenuHeight: '115px',
  });
  const { classes: detailsClasses } = useDetailsStyles();

  const updateMemo = async (text: string) => {
    try {
      setLoadingMemo(true);
      const updateResult =
        lineOfCredit === 'unit'
          ? await patchChargeCardSwipe({
              cardSwipeId: id,
              request: { memo: text },
            })
          : await flexbaseClient.updateInvoice(id, {
              description: text,
            } as any);
      if (updateResult && onTransactionUpdate) {
        notifySuccess('Memo added');
        onTransactionUpdate();
        setUpdatedDescription(text);
      } else {
        notifyError('Unable to update the memo.');
      }
    } catch (error) {
      console.error('Error updating the invoice', error);
      setMemo(updatedDescription);
    } finally {
      setLoadingMemo(false);
    }
  };

  const getDeclinedReason = () => {
    switch (authInfo?.reason) {
      case 'AMOUNT.negativePurchaseAmount':
        return 'Negative purchase amount';
      case 'MERCHANT.excludedMerchantCountry':
        return 'Restricted country';
      case 'MERCHANT.excludedMerchantCategory':
        return 'Restricted MCC group';
      case 'CARD.notActivated':
        return 'Card is not activated';
      case 'CARD.merchantCategoryNotAllowed':
        return 'Merchant category not allowed';
      case 'CARD.exceedDailyBankLimit':
        return 'Card declined. Please contact support.';
      case 'CARD.exceedMonthlyBankLimit':
        return 'Card declined. Please contact support.';
      case 'COMPANY.deactivated':
        return 'Company is deactivated';
      case 'COMPANY.frozen':
        return 'Company is frozen';
      case 'COMPANY.noCreditLimit':
        return 'Company has no credit limit';
      case 'CARD.exceedSpendingLimit':
        return 'Card spend limit exceeded';
      case 'COMPANY.availableCredit':
        return 'Insufficient credit available';
      case 'CARD.blindAuthorization':
        return 'Blind authorization failed';
      default:
        return 'Card declined. Please contact support.';
    }
  };

  const syncExpenseCategory = (categoryId: string) => {
    setWaitingForSyncUpdate(true);

    syncTransactions(
      [
        {
          transactionId: id,
          accountId: categoryId,
        },
      ],
      {
        successMessage: () => `Transaction updated`,
        partialSuccessMessage: () => `Transaction updated`,
      },
    )
      .then(() => refetchSyncedExpenses())
      .then(() => expenseForm.resetDirty());
  };

  const handleExpenseCategoryChange = (categoryId: string | null) => {
    if (!categoryId) {
      return;
    }

    expenseForm.setValues({ categoryId });

    syncExpenseCategory(categoryId);
  };

  useEffect(() => {
    switch (focusElement) {
      case 'memo':
        memoRef?.current?.scrollIntoView({ behavior: 'smooth' });
        memoRef?.current?.focus();
        break;
      default:
        break;
    }
  }, [memoRef, focusElement]);

  return (
    <div className={detailsClasses.baseContainer}>
      <DetailsHeader tableRow={tableRow} onCloseClick={closeModal} />

      <DetailsBody>
        <DetailsFieldsSection>
          <DetailsFieldsRow>
            <div className={detailsClasses.infoCluster}>
              <Text className={detailsClasses.infoTitle}>Transaction name</Text>
              <Text className={detailsClasses.infoValue}>{toFrom}</Text>
            </div>

            <div>
              <Text className={detailsClasses.infoTitle}>Status</Text>
              <Text className={detailsClasses.infoValue}>{status}</Text>
            </div>
          </DetailsFieldsRow>

          <DetailsFieldsRow>
            {purchaseDate && (
              <div className={detailsClasses.infoCluster}>
                <Text className={detailsClasses.infoTitle}>
                  Transaction Date
                </Text>
                <Text className={detailsClasses.infoValue}>
                  {formatDatetime(purchaseDate)}
                </Text>
              </div>
            )}
            {settledDate && (
              <div className={detailsClasses.infoCluster}>
                <Text className={detailsClasses.infoTitle}>Settled Date</Text>
                <Text className={detailsClasses.infoValue}>
                  {formatDatetime(settledDate)}
                </Text>
              </div>
            )}
          </DetailsFieldsRow>

          {status?.toLowerCase() === 'declined' && !authInfo?.authorize && (
            <DetailsFieldsRow>
              <div className={detailsClasses.infoCluster}>
                <Text className={detailsClasses.infoTitle}>
                  Declined reason
                </Text>
                <Text className={detailsClasses.infoValue}>
                  {getDeclinedReason()}
                </Text>
              </div>
            </DetailsFieldsRow>
          )}

          {!!expenseLink?.enabledExpenses &&
          isSyncExpenseAllowed &&
          tableRow.syncedExpense ? (
            <Stack>
              <Text color={theme.primaryColor}>Category</Text>

              <FlexSelect
                label={
                  <>
                    Account
                    {syncedExpense?.status === ExpensesStatusEnum.Queued ? (
                      <SyncIcon
                        fill="currentColor"
                        height={12}
                        width={12}
                        className={cx(classes.syncIcon, classes.spin)}
                      />
                    ) : null}
                  </>
                }
                data={expenseCategorySelectItems}
                searchable
                inputProps={{
                  disabled: waitingForSyncUpdate || syncedExpense?.lock,
                }}
                placeholder={`Choose ${
                  syncedExpenses?.accounts?.type || 'Accounts'
                }`}
                value={expenseForm.values.categoryId}
                onChange={handleExpenseCategoryChange}
              />
            </Stack>
          ) : null}
        </DetailsFieldsSection>
        <div>
          <Box mb="xs">
            <Text className={detailsClasses.infoTitle}>Memo</Text>
          </Box>
          <Textarea
            size="6rem"
            className={classes.containerMemoInput}
            classNames={{ input: classes.memoInput }}
            minRows={5}
            value={memo}
            onChange={(event) => {
              setMemo(event.target.value);
            }}
            ref={memoRef}
            disabled={loadingMemo || isAccountant}
          />
          <div
            className={classes.memoButtons}
            style={{
              maxHeight: memo !== updatedDescription ? '90px' : 0,
            }}
          >
            <Button
              variant="neutral-outline"
              onClick={() => {
                setMemo(updatedDescription);
              }}
              disabled={loadingMemo}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateMemo(memo);
              }}
              disabled={loadingMemo}
              variant="primary-filled"
            >
              Save
            </Button>
          </div>
        </div>
      </DetailsBody>
    </div>
  );
};
