/**
 * This is the same list the backend is using to verify country codes
 * See here: https://github.com/flexbase-eng/platform/blob/main/apps/platform/src/api/util.ts#L1209
 *
 * We should only show supported countries in the intl phone number input
 */
export const supportedCountryCodes = {
  AF: '+93', // Afghanistan
  AL: '+355', // Albania
  DZ: '+213', // Algeria
  AS: '+1', // American Samoa
  AO: '+244', // Angola
  AI: '+1', // Anguilla
  AG: '+1', // Antigua and Barbuda
  AR: '+54', // Argentina
  AM: '+374', // Armenia
  AW: '+297', // Aruba
  AU: '+61', // Australia
  AT: '+43', // Austria
  AZ: '+994', // Azerbaijan
  BS: '+1', // Bahamas
  BH: '+973', // Bahrain
  BD: '+880', // Bangladesh
  BB: '+1', // Barbados
  BY: '+375', // Belarus
  BE: '+32', // Belgium
  BZ: '+501', // Belize
  BJ: '+229', // Benin
  BM: '+1', // Bermuda
  BT: '+975', // Bhutan
  BO: '+591', // Bolivia
  BA: '+387', // Bosnia
  BW: '+267', // Botswana
  BR: '+55', // Brazil
  BG: '+359', // Bulgaria
  BI: '+257', // Burundi
  KH: '+855', // Cambodia
  CM: '+237', // Cameroon
  CA: '+1', // Canada
  KY: '+1', // Cayman Islands
  CF: '+236', // Central African Republic
  TD: '+235', // Chad
  CL: '+56', // Chile
  CN: '+86', // China
  CO: '+57', // Colombia
  CD: '+243', // The Democratic Republic of the Congo
  CG: '+242', // The Republic of the Congo
  CK: '+682', // Cook Islands
  CR: '+506', // Costa Rica
  HR: '+385', // Croatia
  CU: '+53', // Cuba
  CY: '+357', // Cyprus
  CZ: '+420', // Czech Republic
  DK: '+45', // Denmark
  DM: '+1', // Dominica
  DO: '+1', // Dominican Republic
  EC: '+593', // Ecuador
  EG: '+20', // Egypt
  SV: '+503', // El Salvador
  GQ: '+224', // Guniea
  ER: '+291', // Eritrea
  EE: '+372', // Estonia
  ET: '+251', // Ethiopia
  FK: '+500', // Falkland Islands
  FJ: '+679', // Fiji
  FI: '+358', // Finland
  FR: '+33', // France
  PF: '+689', // French Polynesia
  GM: '+220', // Gambia
  GE: '+995', // Georgia
  DE: '+49', // Germany
  GH: '+233', // Ghana
  GI: '+350', // Gibraltar
  GR: '+30', // Greece
  GL: '+299', // Greenland
  GD: '+1', // Grenada
  GP: '+590', // Guadeloupe
  GU: '+1', // Guam
  GT: '+502', // Guatemala
  GN: '+224', // Guinea
  HT: '+509', // Haiti
  VA: '+379', // The Holy See
  HN: '+504', // Honduras
  HK: '+852', // Hong Kong
  HU: '+36', // Hungary
  IS: '+354', // Iceland
  IN: '+91', // India
  ID: '+62', // Indonesia
  IR: '+98', // Iran
  IQ: '+964', // Iraq
  IE: '+353', // Ireland
  IL: '+972', // Israel
  IT: '+39', // Italy
  JM: '+1', // Jamaica
  JP: '+81', // Japan
  JO: '+962', // Jordan
  KZ: '+7', // Kazakhstan
  KE: '+254', // Kenya
  KI: '+686', // Kiribati
  KP: '+850', // North Korea
  KR: '+82', // South Korea
  KW: '+965', // Kuwait
  LA: '+856', // Laos
  LV: '+371', // Latvia
  LB: '+961', // Lebanon
  LR: '+231', // Liberia
  LY: '+218', // Lybia
  LI: '+423', // Liechtenstein
  LT: '+370', // Lithuania
  LU: '+352', // Luxembourg
  MG: '+261', // Madagascar
  MW: '+265', // Malawi
  MY: '+60', // Malaysia
  MV: '+960', // Maldives
  ML: '+223', // Mali
  MT: '+356', // Malta
  MH: '+692', // Marshall Islands
  MQ: '+596', // Martinique
  MX: '+52', // Mexico
  FM: '+691', // Micronesia
  MD: '+373', // Moldova
  MC: '+377', // Monaco
  MN: '+976', // Mongolia
  ME: '+382', // Montenegro
  MS: '+1', // Montserrat
  MA: '+212', // Morocco
  MZ: '+258', // Mozambique
  MM: '+95', // Myanmar
  NA: '+264', // Namibia
  NR: '+674', // Nauru
  NP: '+977', // Nepal
  NL: '+31', // Netherlands
  NC: '+687', // New Caledonia
  NZ: '+64', // New Zealand
  NI: '+505', // Nicaragua
  NE: '+227', // Niger
  NG: '+234', // Nigeria
  NU: '+683', // Niue
  NO: '+47', // Norway
  OM: '+968', // Oman
  PK: '+92', // Pakistan
  PW: '+680', // Palau
  PS: '+970', // Palestine
  PA: '+507', // Panama
  PG: '+675', // Papua New Guinea
  PY: '+595', // Paraguay
  PE: '+51', // Peru
  PH: '+63', // Philippines
  PL: '+48', // Poland
  PT: '+351', // Portugal
  PR: '+1', // Puerto Rico
  QA: '+974', // Qatar
  RO: '+40', // Romania
  RU: '+7', // Russia
  RW: '+250', // Rwanda
  SA: '+966', // Saudi Arabia
  SN: '+221', // Senegal
  RS: '+381', // Serbia
  SL: '+232', // Sierra Leone
  SG: '+65', // Singapore
  SK: '+421', // Slovakia
  SI: '+386', // Slovenia
  SB: '+677', // Solomon Islands
  SO: '+252', // Somalia
  ZA: '+27', // South Africa
  ES: '+34', // Spain
  LK: '+94', // Sri Lanka
  SD: '+249', // Sudan
  SE: '+46', // Sweden
  CH: '+41', // Switzerland
  SY: '+963', // Syrian Arab Republic
  TW: '+886', // Taiwan
  TJ: '+992', // Tajikistan
  TZ: '+255', // Tanzania
  TH: '+66', // Thailand
  TL: '+670', // Timor-Leste
  TG: '+228', // Togo
  TO: '+676', // Tonga
  TT: '+1', // Trinidad and Tobago
  TN: '+216', // Tunisia
  TR: '+90', // Turkey
  TC: '+1', // Turks and Caicos Islands
  TV: '+688', // Tuvalu
  UG: '+256', // Uganda
  UA: '+380', // Ukraine
  AE: '+971', // United Arab Emirates
  GB: '+44', // Great Britain
  UK: '+44',
  US: '+1',
  UY: '+598', // Uruguay
  UZ: '+998', // Uzbekistan
  VU: '+678', // Vanuatu
  VE: '+58', // Venezuela
  VN: '+84', // Viet Nam
  VG: '+1', // British Virgin Islands
  VI: '+1', // US Virgin Islands
  YE: '+967', // Yemen
  ZM: '+260', // Zambia
  ZW: '+263', // Zimbabwe
};
