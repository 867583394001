import { ReactNode } from 'react';
import { useOnboardingStyles } from 'areas/onboarding-v2/onboarding.styles';
import { Button } from '@mantine/core';

type Props = {
  showBackButton: boolean;
  showNextButton: boolean;
  nextButtonLabel: ReactNode;
  nextButtonFormId?: string;
  nextButtonType?: 'button' | 'submit';
  backButtonLabel: ReactNode;
  onBackClick: () => void;
  onNextClick: () => void;
  disableNext: boolean;
  disableBack: boolean;
  disableAll: boolean;
  classNames: Partial<
    Record<'backButton' | 'nextButton' | 'buttonGroup', string>
  >;
  loading?: boolean;
};

const defaultProps = {
  showBackButton: true,
  showNextButton: true,
  nextButtonLabel: 'Continue',
  nextButtonFormId: '',
  nextButtonType: 'button',
  backButtonLabel: 'Back',
};

const OnboardingButtonGroup = ({
  showBackButton,
  showNextButton,
  nextButtonLabel,
  nextButtonFormId,
  nextButtonType,
  backButtonLabel,
  onBackClick,
  onNextClick,
  disableNext,
  disableBack,
  disableAll,
  classNames,
  loading,
}: Partial<Props>) => {
  const { classes, cx } = useOnboardingStyles();

  const onBackButtonClick = () => {
    if (!disableAll && !disableBack && onBackClick) {
      onBackClick();
    }
  };

  const onNextButtonClick = () => {
    if (!disableAll && !disableNext && onNextClick) {
      onNextClick();
    }
  };

  return (
    <div
      className={cx(
        classes.buttonGroup,
        !(showBackButton ?? defaultProps.showBackButton) &&
          classes.buttonGroupContinueOnly,
        classNames?.buttonGroup,
      )}
    >
      {(showBackButton ?? defaultProps.showBackButton) && (
        <Button
          variant="neutral-outline"
          id={'button-back'}
          data-testid={'button-back'}
          onClick={onBackButtonClick}
          disabled={disableAll || disableBack || loading}
          className={cx(classes.backButton, classNames?.backButton)}
        >
          {backButtonLabel || defaultProps.backButtonLabel}
        </Button>
      )}
      {(showNextButton ?? defaultProps.showNextButton) && (
        <Button
          variant="primary-filled"
          id={'button-next'}
          data-testid={'button-next'}
          form={nextButtonFormId}
          loading={loading}
          onClick={onNextButtonClick}
          disabled={disableAll || disableNext || loading}
          className={cx(classes.continueButton, classNames?.nextButton)}
          styles={{
            label: { justifyContent: 'center' },
          }}
          type={nextButtonType ?? 'button'}
        >
          {nextButtonLabel || defaultProps.nextButtonLabel}
        </Button>
      )}
    </div>
  );
};

export default OnboardingButtonGroup;
