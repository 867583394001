import { Fragment, useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import CreditLimit from '../../components/credit-limit/credit-limit';
import CreditPaymentCard from '../../components/credit-payment/credit-payment';
import { CreditTransactions } from '@common/charge-and-credit-cards/credit-transactions';
import SpentWeek from 'areas/credit/components/spent-this-week/spent-this-week';
import LastTransaction from 'areas/credit/components/last-transaction/last-transaction';
import { useStyles } from './overview.styles';
import { useRecoilValue } from 'recoil';
import { modals } from '@mantine/modals';

import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { CreditActionAlertBar } from '../../components/credit-action-alert-bar/credit-action-alert-bar';
import ControlPersonMenu from '@common/control-person-menu';
import { useGetUsers } from '@queries/use-users';
import { Employees, isGraceDayEarlyPayConfig } from 'types/onboarding-info';
import CreditTransactionsSm from '@common/charge-and-credit-cards/credit-transactions-sm';
import { EarlyPayProjectedDueCard } from '@common/charge-and-credit-cards/early-pay-projected-due-card';
import {
  useBillPayCreditFlag,
  useSpendPlansFeatureFlag,
} from '@utilities/feature-flags';
import {
  useCreditTransactionFilters,
  useCreditTransactionModalFilters,
} from 'areas/credit/components/credit-transactions/credit-transactions-header/use-credit-transaction-filters';
import { useNet60CreditTransactions } from '@queries/use-credit-transactions';
import { useMatchMedia } from '@utilities/url/window-helpers';
import CreditLinePaymentCard from 'areas/credit/components/credit-payment/credit-payment-card';
import { Box } from '@mantine/core';

const Overview = () => {
  const { classes, cx } = useStyles();
  const [hasMadePayment, setHasMadePayment] = useState(false);
  const { accountId, user, company } = useRecoilValue(ApplicationState);
  const { data, isLoading } = useGetUsers();
  const spendPlansEnabled = useSpendPlansFeatureFlag();
  const isMobile = useMatchMedia('(max-width: 767px)');
  const filterHook = useCreditTransactionFilters();
  const modalFilterHook = useCreditTransactionModalFilters();
  const dateFilterValue = filterHook.getFilterByKey('date')?.filterValue;
  const transactionsStartDate = dateFilterValue?.startDate.toISODate();
  const transactionsEndDate = dateFilterValue?.endDate.toISODate();
  const isBillPayCreditEnabled = useBillPayCreditFlag();

  const dataQuery = useNet60CreditTransactions(accountId, {
    spendPlansEnabled,
    params: {
      ...(transactionsStartDate && { after: transactionsStartDate }),
      ...(transactionsEndDate && { before: transactionsEndDate }),
    },
  });

  const openModal = (employees: Employees[] | undefined, loading: boolean) =>
    modals.open({
      title: 'Please select a control person',
      children: <ControlPersonMenu employees={employees} isLoading={loading} />,
      closeOnClickOutside: false,
      closeOnEscape: false,
      withCloseButton: false,
    });

  useEffect(() => {
    const getCompanyBalance = async () => {
      const result = await flexbaseOnboardingClient.getMinimumDue(company.id);
      setHasMadePayment(result.paymentMadeThisCycle);
    };
    getCompanyBalance();
  }, []);

  useEffect(() => {
    const filterUser = data?.filter((item) => item.promoCodeId);
    if (
      filterUser?.length === 0 &&
      user.roles?.includes('ADMIN') &&
      !company.controlPerson
    ) {
      openModal(data, isLoading);
    }
  }, [data]);

  return (
    <Fragment>
      <CreditActionAlertBar />
      <div className={classes.creditContainer}>
        {user.roles.some(
          (r) => r === 'ADMIN' || r === 'ACCOUNTANT' || r === 'OWNER',
        ) ? (
          <Box className={classes.widgetContainer}>
            <Box className={classes.widget}>
              <CreditLimit hasPendingPayment={hasMadePayment} />
            </Box>
            {isGraceDayEarlyPayConfig(company.earlypay) && (
              <Box className={cx(classes.monaySpentContainer, classes.widget)}>
                <EarlyPayProjectedDueCard earlyPayConfig={company.earlypay} />
              </Box>
            )}
            <Box className={classes.widget}>
              <CreditPaymentCard disablePayNowBtn={false} />
            </Box>
            {isBillPayCreditEnabled && (
              <Box className={classes.widget}>
                <CreditLinePaymentCard />
              </Box>
            )}
          </Box>
        ) : (
          <>
            <LastTransaction />
            <SpentWeek />
          </>
        )}
      </div>
      {isMobile ? (
        <CreditTransactionsSm lineOfCredit="lithic" dataQuery={dataQuery} />
      ) : (
        <CreditTransactions
          lineOfCredit="lithic"
          dataQuery={dataQuery}
          filterHook={filterHook}
          modalFilterHook={modalFilterHook}
        />
      )}
    </Fragment>
  );
};

export default Overview;
