import { rem, Text, Tooltip } from '@mantine/core';
import { TablePayment } from '@utilities/payments-rows';
import { tooltipPaymentInfo } from './tooltip-payment-info';
import { domesticPaymentTypes } from '@services/flexbase/banking.model';
import { PiInfoBold } from 'react-icons/pi';
import { TagDelta } from '@common/delta';

const checkDirection = (direction: string) => {
  if (direction === 'Credit') {
    return 'increase';
  }
  return 'decrease';
};

const DisplayingPayAmount = ({
  payment,
  useTagDelta = false,
}: {
  payment: TablePayment;
  useTagDelta?: boolean;
}) => {
  const isUSDPayment = payment.currency === 'USD';
  const isDomesticType = domesticPaymentTypes.has(payment.type);
  const direction = checkDirection(payment.direction);
  const tone = direction === 'increase' ? 'sage' : 'neutral';

  const statusNotValidForTooltip = new Set(['Canceled', 'Completed', 'Failed']);
  const displayTooltip =
    !isUSDPayment &&
    payment.type === 'International outgoing wire' &&
    !statusNotValidForTooltip.has(payment.status);

  if (displayTooltip) {
    return (
      <Tooltip
        sx={{ padding: rem(10), fontSize: rem(13), textAlign: 'center' }}
        label={tooltipPaymentInfo(payment.currency || 'USD')}
      >
        <Text>
          {payment.estimatedAmount} <PiInfoBold size={'1rem'} /> &rarr;{' '}
          {payment.fxAmount} {payment.currency}
        </Text>
      </Tooltip>
    );
  }

  let formattedAmount: JSX.Element | string;

  if (!['Failed', 'Canceled'].includes(payment.status) && isUSDPayment) {
    formattedAmount = (
      <>
        {payment.amount} &rarr; {payment.amount} USD
      </>
    );
  } else if (payment.status.includes('Completed') && !isDomesticType) {
    formattedAmount = (
      <>
        {payment.amount} &rarr; {payment.fxAmount} {payment.currency}
      </>
    );
  } else {
    formattedAmount = payment.amount;
  }

  return useTagDelta ? (
    <TagDelta tone={tone} change={direction}>
      {formattedAmount}
    </TagDelta>
  ) : (
    formattedAmount
  );
};

export default DisplayingPayAmount;
