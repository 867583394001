import { useQuery } from '@tanstack/react-query';

type PointsSummaryData = {
  balance: number;
  pendingPoints: number;
  redeemedPoints: number;
};

const mockData: PointsSummaryData = {
  balance: 10200000,
  pendingPoints: 500000,
  redeemedPoints: 1000000,
};

const fetchPointsSummary = async (): Promise<PointsSummaryData> => {
  // Simulate API call with 1 second delay
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return mockData;
};

export const usePointsSummary = () => {
  return useQuery({
    queryKey: ['points-summary'],
    queryFn: fetchPointsSummary,
  });
};
