import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';
import Frame from '../../../assets/images/flex-background-text.png';

/**
 * Moved from onboarding-submitting-screen.tsx to avoid Vite complaining
 */
export const approveStyles = createStyles((theme) => ({
  screen: {
    zIndex: 10,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Frame})`,
    width: '100%',
    backgroundColor: theme.colors.neutral[3],
    '@media(max-width: 767px)': {
      backgroundImage: 'unset',
    },
  },
  header: {
    padding: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      padding: 16,
    },
    borderBottom: `2px solid ${theme.colors.primary[2]}`,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.colors.neutral[8],
  },
  email: {
    color: theme.primaryColor,
    textDecoration: 'none',
  },
  container: {
    flex: 1,
    padding: `0 ${theme.spacing.md}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifySelf: 'center',
    '@media(max-width: 767px)': {
      padding: theme.spacing.sm,
    },
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.xl,
    color: theme.colors.primary[2],
    marginTop: rem(72),
    '@media(max-width: 767px)': {
      gap: theme.spacing.sm,
      marginTop: theme.spacing.md,
      flexDirection: 'column',
    },
  },
  graphic: {
    position: 'absolute',
    top: '40%',
    '@media(max-width: 767px)': {
      display: 'none',
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 400,
    fontFamily: 'Redaction',
    color: theme.colors.primary[8],
    '@media(max-width: 767px)': {
      fontSize: 28,
    },
  },
  statusBoxes: {
    display: 'flex',
    gap: rem(32),
    '@media(max-width: 767px)': {
      gap: theme.spacing.md,
      flexDirection: 'column',
    },
  },
  status: {
    zIndex: 20,
    width: 468,
    height: 293,
    padding: '32px 40px',
    background: 'rgba(236, 233, 226, 0.9)',
    borderRadius: theme.defaultRadius,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
    '@media(max-width: 1000px)': {
      width: 400,
    },
    '@media(max-width: 767px)': {
      width: `100%`,
      padding: theme.spacing.md,
      height: 'fit-content',
    },
  },
  statusTitle: {
    fontFamily: 'Redaction',
    fontSize: 32,
    fontWeight: 400,
    lineHeight: 'normal',
    '@media(max-width: 767px)': {
      fontSize: 24,
    },
  },
  description: {
    fontSize: '1rem',
    lineHeight: '24px',
    color: theme.colors.neutral[8],
  },
  statusGraphic: {
    marginRight: 90,
    marginLeft: 90,
    '@media(max-width: 1000px)': {
      marginRight: 40,
      marginLeft: 10,
    },
    '@media(max-width: 767px)': {
      display: 'none',
    },
  },
  primaryButton: {
    marginTop: 51,
    backgroundColor: theme.colors.primary,
    color: theme.colors.neutral[0],
    borderRadius: 8,
    height: 42,
    width: 181,
    '@media(max-width: 767px)': {
      width: `100%`,
      marginTop: 20,
    },
  },
}));
