import FlexbaseMainLayout from 'components/layouts/main';
import BenefitsList from './benefits-list';
import { useBenefitsAndRewardsFeatureFlag } from '@utilities/feature-flags';
import { Navigate } from 'react-router-dom';
import FlexDefaultHeader from '@common/composites/header/flex-default-header';
import PointsSummary from './points-summary';

const BenefitsAndRewardsDashboard = () => {
  const hasBenefitsAndRewardsFeatureFlag = useBenefitsAndRewardsFeatureFlag();
  if (!hasBenefitsAndRewardsFeatureFlag) {
    return <Navigate to="/" />;
  }
  return (
    <FlexbaseMainLayout>
      <FlexDefaultHeader title="Benefits &amp; Rewards" />
      <PointsSummary />
      <BenefitsList />
    </FlexbaseMainLayout>
  );
};
export default BenefitsAndRewardsDashboard;
