import {
  Alert,
  Checkbox,
  Container,
  PasswordInput,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { FaExclamationCircle } from 'react-icons/fa';
import { useForm } from '@mantine/form';
import { ReactNode, useState } from 'react';
import PasswordStrengthPopover from '@common/password-strength-popover';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { NewPasswordValidator } from '../../../../utilities/validators/validate-password';
import { useMediaQuery } from '@mantine/hooks';
import { TermsOfServiceCheckboxPrompt } from '@common/utilities/terms-prompt';
import OnboardingStep from '../../components/onboarding-step';
import { selector, useRecoilValue } from 'recoil';
import { useOnboardingStyles } from '../../onboarding.styles';
import { Link } from 'react-router-dom';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { platformAuthClient } from '../../../../services/platform/platform-auth-client';
import { IsFlexHttpError } from '../../../../services/platform/models/authorize.models';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import { refreshAndStoreTokenFromUser } from '../../../../utilities/auth/refresh-token';
import { createStyles } from '@mantine/emotion';
import { PasswordError } from '@common/password-error';

type ConfigureAccountFormValues = {
  newPassword: string;
  confirmPassword: string;
  legalAge: boolean;
  flexbaseTos: boolean;
};

const useStyles = createStyles(() => ({
  checkboxBody: { alignItems: 'center' },
  disclosure: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

// Fetch and cache the latest credit Application and Solicitation Disclosure
const applicationDisclosureQuery = selector({
  key: 'application_disclosure',
  get: async () => {
    const documents = await flexbaseOnboardingClient.getTermsOfServiceDocs(
      'credit',
      false,
    );
    return documents.find(
      ({ label }) => label === 'Application and Solicitation Disclosure',
    );
  },
});

const ChangePasswordPage = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const applicationDisclosure = useRecoilValue(applicationDisclosureQuery);
  const { navigateToNextProductStep } = useApplicationFlowContext();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>('');
  const passwordForm = useForm<ConfigureAccountFormValues>({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      legalAge: false,
      flexbaseTos: false,
    },
    validate: {
      newPassword: (value) => {
        if (!value) {
          return 'New password is required';
        }
        return NewPasswordValidator()(value);
      },
      confirmPassword: (value, allFormValues) =>
        value !== allFormValues.newPassword ? 'Passwords do not match' : null,
      flexbaseTos: (value) =>
        value ? null : 'You must accept the terms and conditions',
      legalAge: (value) => (value ? null : 'You must be of legal age'),
    },
  });

  const onNextClick = async () => {
    setError(null);
    try {
      const validationResult = passwordForm.validate();

      if (!validationResult.hasErrors) {
        const { newPassword, flexbaseTos } = passwordForm.values;
        setLoading(true);

        await flexbaseOnboardingClient.updateUser({
          termsOfServiceSigned: flexbaseTos,
        });

        await platformAuthClient.registerFactor({
          factorType: 'password',
          value: newPassword,
        });

        // Refresh token to get the list of updated factors stored
        await refreshAndStoreTokenFromUser(true);

        navigateToNextProductStep();
      }
    } catch (err) {
      if (IsFlexHttpError(err)) {
        const errors = err?.errors?.password;

        if (typeof errors === 'string') {
          setError(capitalizeOnlyFirstLetter(errors));
        } else if (Array.isArray(errors)) {
          setError(<PasswordError errors={errors} />);
        }
      } else {
        setError(
          'An unknown error occurred while trying to save your password.',
        );
      }
      console.error('Unable to update the password', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OnboardingStep
      title={'Welcome!'}
      stepId="change-password"
      subtitle={'Please get started by creating a secure password.'}
      showBack={false}
      onNextClick={onNextClick}
      showContinueSpinner={loading}
    >
      <Container p={0} size="xs">
        <Stack style={{ marginTop: 30 }}>
          <PasswordStrengthPopover inputValue={passwordForm.values.newPassword}>
            <PasswordInput
              label="New Password"
              required
              classNames={{
                label: onboardingClasses.inputLabel,
              }}
              {...passwordForm.getInputProps('newPassword')}
              id="new-password"
            />
          </PasswordStrengthPopover>
          <PasswordInput
            label="Confirm New Password"
            required
            classNames={{
              label: onboardingClasses.inputLabel,
            }}
            {...passwordForm.getInputProps('confirmPassword')}
            id="confirm-password"
          />
          <Checkbox
            mt={useMobileView ? '1.5rem' : '0.5rem'}
            {...passwordForm.getInputProps('legalAge')}
            id="checkbox-legal-age"
            label={
              <Text size="sm" fw={500}>
                I am at least 18 years old
              </Text>
            }
          />
          <TermsOfServiceCheckboxPrompt
            type="flexbase"
            size="sm"
            mt={15}
            {...passwordForm.getInputProps('flexbaseTos')}
            className={classes.checkboxBody}
          />
          <Stack pb={0} mt={useMobileView ? '1rem' : 'auto'} align="flex-end">
            {applicationDisclosure && (
              <Text className={classes.disclosure}>
                By clicking Continue you are agreeing to our{' '}
                <Link
                  to={applicationDisclosure.url}
                  target="_blank"
                  style={{ color: theme.primaryColor }}
                >
                  Application and Solicitation Disclosure
                </Link>
              </Text>
            )}
          </Stack>
          {error && (
            <Alert icon={<FaExclamationCircle />} color="red">
              {error}
            </Alert>
          )}
        </Stack>
      </Container>
    </OnboardingStep>
  );
};

export default ChangePasswordPage;
