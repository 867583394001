import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  card: {
    padding: '24px',
    color: theme.colors.neutral[2],
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
    marginTop: theme.spacing.lg,
  },
}));
