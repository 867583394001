import { DateTime, Interval } from 'luxon';
import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
  useDebitCardsModalFilters,
} from '../use-cards-filters';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { DateRangeFilter } from '@common/composites/date-range.filter';

type CardsDateRangeFilterProps<T> = {
  filterHook: ActiveFiltersReturnType<T>;
};

const CardsDateRangeFilter = <T extends { asOf: string }>({
  filterHook,
}: CardsDateRangeFilterProps<T>) => {
  return (
    <DateRangeFilter
      filterHook={filterHook}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromSQL(item.asOf));
      }}
    />
  );
};

export const CreditCardsDateRangeFilter = () => {
  const filterHook = useCreditCardsModalFilters();

  return <CardsDateRangeFilter filterHook={filterHook} />;
};

export const ChargeCardsDateRangeFilter = () => {
  const filterHook = useChargeCardsModalFilters();

  return <CardsDateRangeFilter filterHook={filterHook} />;
};

export const DebitCardsDateRangeFilter = () => {
  const filterHook = useDebitCardsModalFilters();

  return <CardsDateRangeFilter filterHook={filterHook} />;
};
