import {
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  UnstyledButton,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useState } from 'react';

type Props = {
  onDeny: (reason: string) => Promise<void> | void;
  onCancel: () => void;
};

const DenyBox = ({ onDeny, onCancel }: Props) => {
  const { classes } = useStyles();
  const [denyReason, setDenyReason] = useState('');
  const [isDenying, setIsDenying] = useState(false);

  const handleDenyRequest = async () => {
    try {
      setIsDenying(true);
      await onDeny(denyReason);
    } catch {
      // swallow
    } finally {
      setIsDenying(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Box>
      <Textarea
        label="Why are you denying this request? (Optional)"
        placeholder="Enter reason"
        size="4rem"
        mt={4}
        classNames={{ input: classes.denyingReason }}
        minRows={5}
        onChange={(e) => {
          setDenyReason(e.target.value);
        }}
      />
      <Text fz="10px" c="neutral.8" mt={4}>
        Manager will see this response
      </Text>
      <Flex mt={10} justify="flex-end">
        <UnstyledButton
          variant="transparent"
          fz="14px"
          fw={500}
          onClick={handleCancel}
        >
          Cancel
        </UnstyledButton>
        <Button
          bg="critical.6"
          p="10px 12px"
          sx={(theme) => ({
            borderRadius: theme.defaultRadius,
          })}
          loading={isDenying}
          disabled={isDenying}
          c="neutral.0"
          fw={500}
          fz="0.875rem"
          ml="xl"
          onClick={handleDenyRequest}
        >
          Deny request
        </Button>
      </Flex>
    </Box>
  );
};
export default DenyBox;

export const useStyles = createStyles(() => ({
  denyingReason: {
    padding: 0,
    paddingLeft: 10,
    paddingTop: 5,
  },
}));
