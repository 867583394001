import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  actionsContainer: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#e5e7eb',
    },
    width: '100%',
    textAlign: 'left',
    fontSize: rem(16),
    padding: `${rem(6)} ${rem(8)}`,
    color: 'gray',
  },

  transactionsContainer: {
    height: '100%',
    '.table': {
      padding: rem(10),
      margin: `${rem(16)} 0`,
      borderRadius: rem(8),
      alignItems: 'center',
      backgroundColor: '#F9FBFE',
      '@media(max-width: 767px)': {
        border: `1px solid`,
        borderColor: '#ebf0f7',
      },
      span: {
        fontSize: rem(12),
        lineHeight: rem(20),
        color: 'rgba(111, 113, 129, 0.56)',
      },
      p: {
        margin: 0,
        color: '#0b1130',
      },
      button: {
        border: 0,
        cursor: 'pointer',
        backgroundColor: 'unset',
      },
      '.actions': {
        display: 'flex',
        justifyContent: 'end',
      },
    },
  },

  emptyState: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '.image-container': {
      display: 'flex',
      marginTop: rem(50),
      justifyContent: 'center',
    },

    '> div': {
      width: '30%',
    },

    span: {
      display: 'flex',
      fontWeight: 600,
      marginTop: rem(32),
      justifyContent: 'center',
    },

    p: {
      color: '#6F7181',
      fontSize: rem(14),
      textAlign: 'center',
    },
  },

  // invoice info modal styles
  modalContainer: {
    padding: `${rem(5)} ${rem(32)}`,
    '@media(max-width: 767px)': {
      '.mantine-Col-root grid mantine-9f0u9d': {
        span: {
          margin: 0,
        },
      },
    },
    textarea: {
      padding: `${rem(2)} 0`,
    },
    span: {
      color: '#6f7181',
      fontSize: rem(14),
      margin: rem(5),
    },
    p: {
      margin: rem(2),
      fontSize: rem(14),
    },
    '.transaction-details': {
      margin: 0,
      borderRadius: rem(8),
      border: `1px solid #ecf0f1`,
    },
    '.grid': {
      borderBottom: `${rem(2)} solid #f4f7f8`,
      span: {
        '@media(max-width: 767px)': {
          margin: 0,
        },
      },
    },
    '.buttons-container': {
      display: 'block',
      '.main-action': {
        height: 'auto',
        padding: rem(10),
        '> div': {
          justifyContent: 'center',
        },
        span: {
          color: theme.colors.neutral[0],
        },
      },
      '.secondary-action': {
        border: 0,
        width: '100%',
        color: '#adb0ca',
        fontSize: rem(12),
        cursor: 'pointer',
        marginTop: rem(12),
        backgroundColor: 'unset',
        justifyContent: 'center',
      },
    },
    '.title': {
      fontSize: rem(20),
      margin: `${rem(27)} 0`,
      fontFamily: 'PP Neue Montreal',
    },
    '.subtitle': {
      margin: `${rem(20)} 0`,
      fontFamily: 'PP Neue Montreal',
    },
    '.invoice': {
      marginTop: rem(16),
      position: 'relative',
      button: {
        top: rem(10),
        left: rem(10),
        zIndex: 1,
        width: rem(32),
        height: rem(32),
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '100%',
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        border: `1px solid rgba(255, 255, 255, 0.5)`,
      },
    },
    '.loading': {
      display: 'flex',
      justifyContent: 'center',
      height: rem(360),
      alignItems: 'center',
    },
    '.error-message': {
      fontSize: rem(12),
      marginTop: rem(20),
      color: theme.primaryColor,
    },
  },

  // dropzone styles
  root: {
    padding: rem(25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed #ecf0f1`,
    '&:hover': {
      backgroundColor: 'rgba(57, 160, 255, 0.04)',
    },
    p: {
      fontSize: rem(12),
      textAlign: 'center',
      color: 'rgba(111, 113, 129, 0.56)',
    },
  },

  cardsContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[1]}`,
    flexDirection: 'row',
    backgroundColor: theme.colors.neutral[0],
    padding: rem(24),
    '@media(max-width: 767px)': {
      width: '100%',
      backgroundColor: 'unset',
      padding: 0,
    },
  },
  tableTitles: {
    alignItems: 'center',
    display: 'flex',
    fontSize: rem(12),
    color: '#6f7181',
    fontWeight: 400,
  },

  tableArrows: {
    cursor: 'pointer',
    marginTop: '0.2rem',
  },

  // pagination styles
  item: {
    fontSize: rem(12),
    color: '#6F7181',
    marginTop: rem(20),
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid #EBF0F7`,
    '&[data-active]': {
      color: theme.primaryColor,
    },
  },
  dots: {
    fontSize: rem(12),
    color: '#6F7181',
    marginTop: rem(20),
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid #EBF0F7`,
  },
  active: {
    fontSize: rem(12),
    color: theme.primaryColor,
    marginTop: rem(20),
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid #EBF0F7`,
  },

  // issue card modal
  issueCardContainer: {
    '.mantine-Title-root': {
      '@media(max-width: 767px)': {
        fontSize: rem(24),
      },
    },
    h1: {
      fontWeight: 300,
      textAlign: 'center',
    },
    'input[type="checkbox"]': {
      margin: 0,
    },
    '.box': {
      width: '100%',
      display: 'flex',
      padding: rem(28),
      marginTop: rem(25),
      cursor: 'pointer',
      borderRadius: rem(8),
      alignItems: 'center',
      textAlign: 'initial',
      backgroundColor: 'unset',
      border: `1px solid #ebf0f7`,
      justifyContent: 'space-between',
      '@media(max-width: 767px)': {
        padding: 0,
      },
      '& svg': {
        color: '#ebf0f7',
      },
      '&:hover': {
        padding: rem(27),
        borderRadius: rem(8),
        border: `2px solid ${theme.primaryColor}`,
        '& svg': {
          color: theme.primaryColor,
        },
      },
    },
    '.buttons-container': {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      '@media(max-width: 767px)': {
        justifyContent: 'center',
        display: 'contents',
      },
    },
    '.btn-create-card': {
      '@media(max-width: 767px)': {
        width: '100%',
        marginLeft: 0,
      },
    },
    '.image-container': {
      display: 'flex',
      marginTop: rem(80),
      justifyContent: 'center',
      '@media(max-width: 767px)': {
        width: '100%',
        height: '100%',
      },
    },
    '.alert': {
      left: rem(20),
      bottom: rem(50),
      position: 'absolute',
    },
    '.center-container': {
      display: 'flex',
      marginTop: rem(50),
      justifyContent: 'center',
    },
  },

  // checkboxs styles
  input: {
    backgroundColor: '#f9fbfe !important',
    borderColor: '#ebf0f7 !important',
  },

  icon: {
    color: '#1ec98b',
  },

  grayContainer: {
    borderRadius: rem(8),
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ebf0f7',
    width: '60%',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },

  totalBalanceContainer: {
    marginRight: rem(16),
    marginTop: '5rem',
    borderColor: '#c8d6e5',
    width: '70%',
    margin: rem(24),
  },

  creditLimitCont: {
    fontSize: rem(14),
    marginBottom: rem(8),
    color: '#6f7181',
  },

  statusBar: {
    borderRadius: rem(8),
    backgroundColor: '#EBF0F6',
  },

  greenBar: {
    backgroundColor: '#5ec92c',
    height: rem(8),
    borderRadius: rem(8),
  },

  purpleContainer: {
    backgroundColor: '#7f7bf7',
    width: '40%',
    borderRadius: rem(8),
    padding: rem(24),
    marginLeft: rem(16),
    '@media(max-width: 767px)': {
      width: '100%',
      marginLeft: 0,
    },
  },

  repayButton: {
    backgroundColor: 'rgb(216 180 254)',
    width: '100%',
    paddingTop: rem(8),
    paddingBottom: rem(8),
    borderRadius: '0.375rem',
    outline: 'none',
    color: theme.colors.neutral[0],
    fontWeight: 'bold',
    cursor: 'pointer',
    borderWidth: 0,
  },

  containerRepayButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: rem(64),
  },

  containerCompBalance: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: rem(16),
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      marginLeft: 0,
    },
  },

  btnNewCreditCard: {
    background: theme.colors.neutral[0],
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
    fontSize: rem(14),
    fontWeight: 'normal',
    ':hover': {
      background: theme.colors.neutral[0],
    },
    width: rem(300),
  },

  containerSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: rem(24),
    paddingBottom: rem(24),
    flexWwrap: 'wrap',
    gap: rem(16),
  },

  searchInput: {
    color: theme.colors.neutral[10],
    display: 'flex',
    height: rem(40),
    alignItems: 'flex-end',
    '@media(max-width: 767px)': {
      flexWrap: 'wrap',
      width: '65%',
    },
  },

  holderField: {
    marginLeft: rem(8),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'max-content',
  },

  cardAvatar: {
    color: theme.colors.primary,
    '> div:first-of-type': {
      color: `${theme.primaryColor} !important`,
      backgroundColor: `${theme.colors.neutral[2]} !important`, // TODO change this shit
      fontSize: rem(14),
      fontWeight: 400,
    },
  },

  utilizationField: {
    marginLeft: rem(8),
    color: theme.colors.neutral[10],
    fontSize: rem(12),
  },

  showAccountNumber: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  tableTitle: {
    color: '#5F5F5F',
    fontSize: rem(16),
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
  },

  inputSearch: {
    '.mantine-TextInput-input': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: rem(14),
      lineHeight: rem(16),
      color: '#5F5F5F',
    },
  },

  containerMessage: {
    width: 'max-content',
    position: 'fixed',
    bottom: rem(32),
    zIndex: 1,
  },

  containerButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  badge: {
    fontWeight: 500,
    fontSize: rem(12),
    marginLeft: rem(10),
    padding: `${rem(4)} ${rem(15)}`,
    textTransform: 'capitalize',
  },
}));

export const CustomMantineStyles = (primaryColor?: string) => {
  return {
    searchTransactions: {
      input: {
        width: '100%',
        height: rem(40),
        opacity: 0.8,
        border: `1px solid #E6E7E9`,
        borderRadius: rem(8),
        fontSize: rem(14),
        background: 'white',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: rem(16),
        color: ' #5F5F5F',
        '&:focus': { borderColor: primaryColor },
      },
    },
    issueCard: {
      radio: {
        body: {
          alignItems: 'center',
        },
      },
    },
  };
};
