import {
  Button,
  ButtonProps,
  Flex,
  Group,
  rem,
  Box,
  alpha,
} from '@mantine/core';
import { memo } from 'react';
import { createStyles } from '@mantine/emotion';
import { PiX } from 'react-icons/pi';

type FlexWizardDialogProps = {
  title: string;
  message: string;
  options: {
    label: string;
    onClick: () => void;
    variant: ButtonProps['variant'];
    disabled?: boolean;
    loading?: boolean;
  }[];
  onClose: () => void;
};

const InvoiceWizardDialog = ({
  title,
  message,
  options,
  onClose,
}: FlexWizardDialogProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wizardDialogOverlay} onClick={onClose}>
      <Flex
        className={classes.wizardDialog}
        onClick={(e) => e.stopPropagation()}
        direction="column"
        gap="xs"
      >
        <Group justify="space-between" align="flex-start">
          <Box className={classes.wizardDialogTitle}>{title}</Box>
          <Flex className={classes.wizardDialogClose} onClick={onClose}>
            <PiX size={'1.25rem'} />
          </Flex>
        </Group>
        <Box className={classes.wizardDialogMessage}>{message}</Box>
        <Box className={classes.wizardDialogOptions}>
          {options.map((option) => (
            <Button
              key={option.label}
              onClick={option.onClick}
              variant={option.variant || 'neutral-subtle'}
              disabled={option.disabled}
              loading={option.loading}
            >
              {option.label}
            </Button>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default memo(InvoiceWizardDialog);

const useStyles = createStyles((theme) => ({
  wizardDialogOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: alpha(theme.colors.neutral[10], 0.5),
    backdropFilter: 'blur(4px)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wizardDialog: {
    backgroundColor: theme.colors.neutral[0],
    padding: theme.spacing.xs,
    width: rem(480),
    boxShadow: theme.shadows.md,
    borderRadius: theme.radius.xs,
  },
  wizardDialogTitle: {
    fontSize: theme.fontSizes.xl,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.xxs,
    color: theme.colors.neutral[10],
  },
  wizardDialogMessage: {
    padding: theme.spacing.sm,
    color: theme.colors.neutral[8],
  },
  wizardDialogOptions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing.xs,
    padding: theme.spacing.sm,
  },
  wizardDialogClose: {
    padding: theme.spacing.xs,
    borderRadius: theme.radius.xs,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.neutral[2],
    },
  },
}));
