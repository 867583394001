import { createStyles } from '@mantine/emotion';

export const useDetailsStyles = createStyles((theme) => ({
  baseContainer: {
    padding: 0,
    '> *': {
      width: '100%',
    },
    overflowY: 'scroll',
    '@media(max-width: 767px)': {
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
    },
  },
  headerRow1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 35,
    backgroundColor: theme.colors.primary[8],
    '> div:last-of-type': {
      marginRight: '-11px',
    },
    flexWrap: 'nowrap',
  },
  storeLogo: {
    borderRadius: '8px',
    width: '50px',
    height: '50px',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  avatar: {
    height: '50px',
    width: '50px',
    color: theme.colors[theme.primaryColor][6],
    '> div:first-of-type': {
      color: `${theme.colors[theme.primaryColor][6]} !important`,
      backgroundColor: '#FFEEEC !important',
      fontSize: '20px',
      fontWeight: 500,
      fontFamily: 'PP Neue Montreal',
    },
  },
  amount: {
    height: '43px',
    color: theme.colors.neutral[0],
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '36px',
  },
  closeButton: {
    display: 'flex',
    width: '34px',
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: '#979797',
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'unset',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  dateTime: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.colors.neutral[0],
  },
  section: {
    padding: 20,
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '> *': {
      flex: 1,
    },
    marginBottom: '50px',
    '&:first-of-type': {
      marginTop: '0.6rem;',
    },
    '&:last-of-type': {
      marginBottom: '32px;',
    },
    '@media(max-width: 767px)': {
      marginBottom: '30px',
    },
  },
  infoTitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.colors.neutral[8],
    fontFamily: 'PP Neue Montreal',
  },
  infoValue: {
    marginTop: '10px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.colors.primary[8],
  },
  infoCluster: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusContainer: {
    height: '20px',
    borderRadius: '100px',
    padding: '0px 10px 0px 10px',
  },
}));
