import {
  useGetBillpayInvoice,
  useGetBillpayInvoices,
} from '../../queries/use-bill-pay';
import { useGetBankingPayments } from '../../queries/use-payments';
import {
  useGetAllRecipients,
  useGetRecipient,
} from '../../queries/use-recipients';
import { useGetPlatformDocument } from '../../queries/use-get-platform-document';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { MoneyMovement } from '@services/flexbase/banking.model';
import { UserInfoState } from 'types/user-info';
import { useRecoilValue } from 'recoil';
import { CompanyIdState } from 'recoil-state/application/onboarding-form.state';

export const useBillpayInvoicesWithRecipientsAndPayments = (
  recipientId?: string,
) => {
  const companyId = useRecoilValue(CompanyIdState);
  const now = useMemo(() => DateTime.now().toISO(), []);
  const user = useRecoilValue(UserInfoState);
  const {
    data: recipientsData,
    isLoading: isRecipientsLoading,
    error: recipientsError,
  } = useGetAllRecipients();
  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    error: invoicesError,
  } = useGetBillpayInvoices({
    sort: 'desc',
    validAt: now,
    companyId,
    ...(recipientId && { recipientId }),
  });

  const {
    data: paymentsData,
    isLoading: isPaymentsLoading,
    error: paymentsError,
  } = useGetBankingPayments({ paymentScope: 'billpay', companyId });

  const isLoading =
    isRecipientsLoading || isInvoicesLoading || isPaymentsLoading;
  const error = recipientsError || invoicesError || paymentsError;

  if (isLoading) {
    return {
      data: { invoices: [] },
      isLoading: true,
      isSuccess: false,
      error: null,
    };
  }

  if (error) {
    return {
      data: { invoices: [] },
      isLoading: false,
      isSuccess: false,
      error,
    };
  }

  const paymentsByInvoiceId: Record<string, MoneyMovement[]> = {};
  paymentsData?.forEach((payment) => {
    if (
      payment.status === 'AwaitingApproval' ||
      (payment.status === 'AwaitingConfirmation' &&
        (payment.approvedBy === user.id || payment.userId === user.id))
    ) {
      if (payment.billpayInvoicesId) {
        if (!paymentsByInvoiceId[payment.billpayInvoicesId]) {
          paymentsByInvoiceId[payment.billpayInvoicesId] = [];
        }
        paymentsByInvoiceId[payment.billpayInvoicesId].push(payment);
      }
    }
  });

  const recipientsMap = new Map(
    recipientsData?.recipients.map((recipient) => [
      recipient.id,
      recipient.name || 'Unknown Recipient',
    ]),
  );

  const combinedData = invoicesData?.invoices.map((invoice) => ({
    ...invoice,
    paymentsNeedingApproval: paymentsByInvoiceId[invoice.id] || [],
    recipientName:
      recipientsMap.get(invoice.recipientId) || 'Unknown Recipient',
  }));

  return {
    data: { invoices: combinedData },
    isLoading: false,
    isSuccess: true,
    error: null,
  };
};

export const useBillpayInvoiceById = (invoiceId: string) => {
  const companyId = useRecoilValue(CompanyIdState);
  const {
    data: invoiceData,
    isLoading: isInvoiceLoading,
    error: invoiceError,
  } = useGetBillpayInvoice(invoiceId, companyId);

  const {
    data: paymentsData,
    isLoading: isPaymentsLoading,
    error: paymentsError,
  } = useGetBankingPayments({ billpayInvoicesId: invoiceId, companyId });

  const {
    data: recipientData,
    isLoading: isRecipientLoading,
    error: recipientError,
  } = useGetRecipient(invoiceData?.invoice?.recipientId || '');
  const {
    data: documentData,
    isLoading: isDocumentLoading,
    error: documentError,
  } = useGetPlatformDocument(invoiceData?.invoice?.documentId || '');
  const isLoading =
    isInvoiceLoading ||
    isRecipientLoading ||
    isDocumentLoading ||
    isPaymentsLoading;
  const error =
    invoiceError || recipientError || documentError || paymentsError;

  if (isLoading) {
    return {
      data: null,
      isLoading: true,
      isSuccess: false,
      error: null,
    };
  }

  if (error) {
    return {
      data: null,
      isLoading: false,
      isSuccess: false,
      error,
    };
  }

  return {
    data: {
      ...invoiceData?.invoice,
      recipient: recipientData?.recipient,
      document: documentData,
      payments: paymentsData,
    },
    isLoading: false,
    isSuccess: true,
    error: null,
  };
};

export const useBillPayPayments = () => {
  const companyId = useRecoilValue(CompanyIdState);
  const now = useMemo(() => DateTime.now().toISO(), []);
  const {
    data: paymentsData,
    isLoading: isPaymentsLoading,
    isSuccess: isPaymentsSuccess,
    error: paymentsError,
    refetch,
  } = useGetBankingPayments({ paymentScope: 'billpay', companyId });

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    isSuccess: isInvoicesSuccess,
    error: invoicesError,
    refetch: invoicesRefetch,
  } = useGetBillpayInvoices({ sort: 'desc', validAt: now, companyId });

  const isLoading = isPaymentsLoading || isInvoicesLoading;
  const isSuccess = isPaymentsSuccess && isInvoicesSuccess;
  const error = paymentsError || invoicesError;

  const handleRetry = () => {
    refetch();
    invoicesRefetch();
  };

  const invoicesMap = new Map(
    invoicesData?.invoices.map((invoice) => [
      invoice.id,
      invoice.invoiceNumber || 'N/A',
    ]),
  );

  const combinedData =
    paymentsData?.map((payment) => ({
      ...payment,
      invoiceNumber: invoicesMap.get(payment.billpayInvoicesId ?? '') || 'N/A',
    })) ?? [];

  return {
    data: { payments: combinedData },
    isLoading,
    isSuccess,
    error,
    refetch: handleRetry,
  };
};
