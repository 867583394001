import { Box, Button, Group, rem, Stack } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';
import OnboardingHeader from '@common/onboarding-header';
import { CreditBankingFooter } from '@common/credit-banking.footer';
import { ReactNode } from 'react';
import { PiArrowLeftBold } from 'react-icons/pi';

type Props = {
  titleText?: string;
  subtitleText?: string;
  estimatedTime?: string;
  progress?: number;
  handleContinueClick?: () => void;
  continueLoading?: boolean;
  handleBackClick?: () => void;
  hideFooter?: boolean;
  hideNavigation?: boolean;
  children?: ReactNode;
  handleSkipClick?: () => void;
  isSkipLoading?: boolean;
};

const QualificationPageShell = ({
  titleText,
  subtitleText,
  estimatedTime,
  progress,
  handleContinueClick,
  continueLoading,
  handleBackClick,
  children,
  hideFooter,
  hideNavigation,
  handleSkipClick,
  isSkipLoading,
}: Props) => {
  const { theme, classes } = useStyles();
  const isMobile = useIsMobile();
  return (
    <>
      <Stack bg={theme.colors.neutral[3]} mih="100vh" pos="relative">
        <OnboardingHeader
          titleText={titleText}
          subtitleText={subtitleText}
          estimatedTime={estimatedTime}
          progress={progress}
        />
        <Stack
          my="xl"
          flex={{ base: 1, sm: 'unset' }}
          w={{ base: '100%', sm: rem(525) }}
          mx="auto"
        >
          {children}
          {isMobile && !hideFooter && (
            <CreditBankingFooter mt={rem(128)} className={classes.footer} />
          )}
        </Stack>
        {!hideNavigation && (
          <Group
            bg={theme.colors.neutral[3]}
            bd={{ base: `1px solid ${theme.colors.neutral[4]}`, sm: 'none' }}
            px={{ base: 'md', sm: 0 }}
            h={rem(72)}
            align="center"
            justify="space-between"
            pos={{ base: 'sticky', sm: 'unset' }}
            bottom={0}
            w={{ base: '100%', sm: rem(525) }}
            mx="auto"
          >
            {handleBackClick ? (
              <Button
                size="md"
                onClick={handleBackClick}
                leftSection={
                  <PiArrowLeftBold
                    color={theme.colors.neutral[6]}
                    width={16}
                    height={16}
                  />
                }
                fz="md"
                variant="neutral-outline"
              >
                Back
              </Button>
            ) : (
              <Box />
            )}
            <Group>
              {handleSkipClick && (
                <Button
                  variant="neutral-subtle"
                  size="md"
                  onClick={handleSkipClick}
                  fz="md"
                  loading={isSkipLoading}
                >
                  Skip
                </Button>
              )}
              <Button
                variant="primary-filled"
                size="md"
                disabled={!handleContinueClick}
                loading={continueLoading}
                onClick={handleContinueClick}
              >
                Continue
              </Button>
            </Group>
          </Group>
        )}
      </Stack>
      {!isMobile && !hideFooter && (
        <CreditBankingFooter
          bg={theme.colors.neutral[3]}
          className={classes.footer}
        />
      )}
    </>
  );
};

const useStyles = createStyles((theme) => ({
  footer: {
    p: {
      color: theme.colors.neutral[7],
      lineHeight: 'normal',
      letterSpacing: '0.05px',
    },
    a: {
      color: `${theme.colors.primary[6]} !important`,
      lineHeight: 'normal',
      letterSpacing: '0.05px',
    },
  },
}));

export default QualificationPageShell;
