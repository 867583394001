import {
  Text,
  Group,
  Stack,
  Avatar,
  Anchor,
  Button,
  Popover,
  Box,
  Flex,
  rem,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  extractToFrom,
  formatCurrency,
  formatInitials,
} from 'utilities/formatters';
import { useEffect, useReducer, useState } from 'react';
import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import TwoFactorAuth from '@common/composites/two-factor-auth';
import TransparentCenteredLoader from '@common/composites/loading/transaparent-centered-loader';
import { PiEyeLight } from 'react-icons/pi';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { showNotification } from '@mantine/notifications';
import { Transaction } from '@services/flexbase/banking.model';
import { DollarAmountTitle } from '@common/composites/dollar-amount-title';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { getMonthDayYear } from '@utilities/dates';

type TransactionDetailsProps = {
  transaction: Transaction;
};

type StateProps = {
  bearerToken: string | null;
  needsTwoFactor: boolean;
  errorMsg: string;
  loading: boolean;
  retry: boolean;
};

const initialState = {
  bearerToken: null,
  needsTwoFactor: false,
  errorMsg: '',
  loading: false,
  retry: false,
};

const actionTypes = {
  SET_BEARER_TOKEN: 'SET_BEARER_TOKEN',
  SET_NEEDS_TWO_FACTOR: 'SET_NEEDS_TWO_FACTOR',
  SET_ERROR_MSG: 'SET_ERROR_MSG',
  SET_LOADING: 'SET_LOADING',
  SET_RETRY: 'SET_RETRY',
};

const reducer = (state: StateProps, action: any) => {
  switch (action.type) {
    case actionTypes.SET_BEARER_TOKEN:
      return { ...state, bearerToken: action.payload };
    case actionTypes.SET_NEEDS_TWO_FACTOR:
      return { ...state, needsTwoFactor: action.payload };
    case actionTypes.SET_ERROR_MSG:
      return { ...state, errorMsg: action.payload };
    case actionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SET_RETRY:
      return { ...state, retry: action.payload };
    default:
      return state;
  }
};

declare const VGSShow: any;

const TransactionDetails = ({ transaction }: TransactionDetailsProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [last4Numbers, setLast4Numbers] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  const useMobileView = useMediaQuery('(max-width: 755px)');
  const formattedDate = getMonthDayYear(transaction.createdAt);
  const displayDisputeButton = [
    'Incoming ACH',
    'Debit card purchase',
    'Debit card transaction',
  ].includes(transaction.type);

  const cardId = transaction.cardId || '';
  const paymentId = transaction.paymentId;

  const getDebitCards = async () => {
    try {
      setLoading(true);
      const result = await flexbaseBankingClient.getDebitCards(false);
      if (result.success && result.cards) {
        const cardData = result.cards.find((card) => {
          if (card.ucCardId.startsWith('ucdc_')) {
            return card.ucCardId.slice(5) === cardId;
          }
        });
        if (cardData?.cardNumber) {
          setLast4Numbers(GetPaddedAccountMask(cardData.cardNumber, 4));
        }
      }
    } catch (error) {
      console.error('Unable to get company debit cards', error);
      showNotification({
        title: 'Error',
        message: 'An error occurred while getting the card number',
        color: 'critical.2',
      });
    } finally {
      setLoading(false);
    }
  };

  const show = VGSShow.create(import.meta.env.VITE_APP_UNIT_VAULT_ID);
  const customerToken = state.bearerToken;

  const cardNumberIframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: `/cards/${cardId}/secure-data/pan`,
    headers: {
      Authorization: `Bearer ${customerToken}`,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.pan',
    serializers: [
      show.SERIALIZERS.replace(
        '(\\d{4})(\\d{4})(\\d{4})(\\d{4})',
        '$1 $2 $3 $4',
      ),
    ],
  });

  const getUnitToken = async () => {
    dispatch({ type: actionTypes.SET_RETRY, payload: true });
    dispatch({ type: actionTypes.SET_ERROR_MSG, payload: '' });
    dispatch({ type: actionTypes.SET_BEARER_TOKEN, payload: null });

    try {
      dispatch({ type: actionTypes.SET_LOADING, payload: true });
      dispatch({ type: actionTypes.SET_NEEDS_TWO_FACTOR, payload: true });

      const unitToken = await flexbaseClient.getUnitcoToken();
      if (unitToken.success && unitToken.attributes) {
        setToken(unitToken.attributes.verificationToken); // Set the token separately
      }
    } catch (error) {
      console.error('Error updating customer token', error);
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  const handleOnChange = async (code: string) => {
    const errorMessage = 'Invalid code. Please try again or re-send the code';
    dispatch({ type: actionTypes.SET_ERROR_MSG, payload: '' });

    try {
      if (code.length === 6) {
        dispatch({ type: actionTypes.SET_LOADING, payload: true });

        const tokenResponse = await flexbaseBankingClient.createVGSCustToken({
          verificationCode: code,
          verificationToken: token, // Use the token from useState
        });

        if (tokenResponse.success) {
          dispatch({
            type: actionTypes.SET_BEARER_TOKEN,
            payload: tokenResponse.accessToken,
          });
          dispatch({ type: actionTypes.SET_NEEDS_TWO_FACTOR, payload: false });
        } else {
          dispatch({ type: actionTypes.SET_ERROR_MSG, payload: errorMessage });
          dispatch({ type: actionTypes.SET_RETRY, payload: true });
        }
      }
    } catch (error) {
      console.error('Error updating customer token', error);
      dispatch({ type: actionTypes.SET_RETRY, payload: true });
      dispatch({ type: actionTypes.SET_ERROR_MSG, payload: errorMessage });
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  const getPaymentInfo = async (id: string) => {
    try {
      setLoading(true);
      const response = await flexbaseBankingClient.getMoneyMovement(id);
      if (response.success) {
        // the "recipient" of a Money Movement initiated through the API
        // is the Counterparty in the case of a Credit, and is the Company
        // that initiated the Money Movement through the API when the direction is Debit.
        const recipient =
          response.payment.payDirection === 'Credit'
            ? response.payment.payCtrParty
            : transaction.companyName;

        setRecipientName(recipient);
      }
    } catch (error) {
      console.error('Error getting payment info', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.bearerToken) {
      cardNumberIframe.render('#cardNumber', {
        color: '#071C14',
        'font-family': '"Inter", sans-serif',
        'font-size': rem(20),
      });
    }
  }, [state.bearerToken]);

  useEffect(() => {
    if (cardId) {
      getDebitCards();
    }
  }, [cardId]);

  useEffect(() => {
    if (paymentId) {
      getPaymentInfo(paymentId);
    }
  }, [paymentId]);

  if (loading) return <TransparentCenteredLoader visible={true} />;

  return (
    <RightContentModal>
      <RightContentModal.SingleTitleHeader
        title={
          <Flex gap="md">
            <Avatar mt={rem(4)}>
              {formatInitials(extractToFrom(transaction.summary))}
            </Avatar>
            <Box>
              <DollarAmountTitle
                dollarAmountString={formatCurrency(transaction.amount)}
                size="sm"
              />
              <Text mt="xxs" fz={useMobileView ? rem(10) : rem(14)}>
                {formattedDate}
              </Text>
            </Box>
          </Flex>
        }
      />
      <RightContentModal.Body>
        <Group gap="xl" grow align="initial">
          {recipientName && transaction.paymentType !== 'book' && (
            <Box>
              <Text fz={useMobileView ? rem(12) : rem(14)}>Recipient</Text>
              <Text fz={useMobileView ? rem(15) : rem(20)}>
                {recipientName}
              </Text>
            </Box>
          )}
          <Box>
            <Text size={useMobileView ? rem(12) : rem(14)}>Account name</Text>
            <Text size={useMobileView ? rem(15) : rem(20)}>
              {transaction.depositAccount}
            </Text>
          </Box>
        </Group>
        <Group grow my={30} align="initial">
          <Box>
            <Text fz={useMobileView ? rem(12) : rem(14)}>Type</Text>
            <Text fz={useMobileView ? rem(15) : rem(20)}>
              {transaction.type}
            </Text>
          </Box>
          {cardId && (
            <Box>
              <Text fz={useMobileView ? rem(12) : rem(14)}>Card Number</Text>
              {state.bearerToken ? (
                <Box mb="-8rem" id="cardNumber"></Box>
              ) : (
                <Flex align="center">
                  <Text>{last4Numbers}</Text>
                  <PiEyeLight
                    style={{ cursor: 'pointer', marginLeft: rem(5) }}
                    onClick={() => {
                      getUnitToken();
                    }}
                  />
                </Flex>
              )}
            </Box>
          )}
        </Group>

        <RightContentModal.Divider />

        <Stack gap="xxs">
          <Text fz={useMobileView ? rem(12) : 14}>Memo</Text>
          <Text fz={useMobileView ? rem(15) : rem(20)}>
            {transaction.summary}
          </Text>
        </Stack>

        <RightContentModal.Divider />

        <Stack gap="xxs">
          <Text fz={useMobileView ? rem(10) : rem(14)}>Transaction ID</Text>
          <Text>#{transaction.id}</Text>
        </Stack>

        {displayDisputeButton && (
          <Stack my={30} align="flex-start">
            <Popover
              width={useMobileView ? '100%' : rem(250)}
              withArrow
              arrowPosition="side"
              shadow="md"
            >
              <Popover.Target>
                <Button
                  w={useMobileView ? '100%' : 'auto'}
                  variant="primary-filled"
                >
                  Dispute Transaction
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                {transaction.type === 'Incoming ACH' ? (
                  <Text fz={useMobileView ? rem(12) : rem(14)}>
                    Send the signed copy of the{' '}
                    <Anchor
                      c="primary.5"
                      href="https://docs.google.com/document/d/1qoSVbvCPqrASp2jQoehAg7ryHGVLEGDb/edit?pli=1"
                      target="_blank"
                      td="underline"
                    >
                      ACH debit dispute
                    </Anchor>{' '}
                    to the following email address:{' '}
                    <Anchor
                      href="mailto:support@flex.one"
                      target="_blank"
                      fw={600}
                    >
                      support@flex.one
                    </Anchor>
                  </Text>
                ) : (
                  <Text fz={useMobileView ? rem(12) : rem(14)}>
                    If you want to dispute this transaction, please contact the
                    toll-free number <strong>18338521519</strong>
                  </Text>
                )}
              </Popover.Dropdown>
            </Popover>
          </Stack>
        )}

        {token && state.needsTwoFactor && (
          <TwoFactorAuth
            hasRetry={state.retry}
            errorMsg={state.errorMsg}
            onChange={handleOnChange}
            onResendCode={getUnitToken}
            loading={state.loading}
            label="Verification required"
          />
        )}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default TransactionDetails;
