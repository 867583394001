import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import { Button } from '@mantine/core';
import { IoMdCheckmark } from 'react-icons/io';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseBankingClient,
} from '@services/flexbase-client';
import { showNotification } from '@mantine/notifications';
import { PlaidAccount } from 'types/move-funds.model';
import { PlaidToken } from 'types/plaid';

type Props = {
  plaidAccount: PlaidAccount;
  buttonLabel: ReactNode;
  onLinkingCompleted: () => void;
};
export function PlaidRelinkOneAccButton({
  plaidAccount,
  buttonLabel,
  onLinkingCompleted,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<PlaidToken>();
  const onSuccess: PlaidLinkOnSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    setLoading(true);
    const result = await flexbaseClient.exchangePlaidPublicToken(
      publicToken,
      metadata,
    );
    const bank = token?.bankName || 'Bank';

    if (result.success) {
      showNotification({
        title: 'Success!',
        message: `${bank} linked.`,
        color: 'sage.4',
        icon: <IoMdCheckmark />,
      });
      onLinkingCompleted();
    } else {
      showNotification({
        title: 'Failure',
        message: `Unable to link ${bank}.`,
        color: 'red',
      });
    }
    setLoading(false);
  };

  const { ready, open } = usePlaidLink({
    onSuccess: onSuccess,
    token: token?.linkToken ?? null,
  });

  const getLinkToken = async (plaidData: PlaidAccount) => {
    setLoading(true);
    const bankName = plaidData.bankName ?? '';

    try {
      const response = await flexbaseBankingClient.relinkPlaidAccount({
        plaidTokenId: plaidData.id,
      });

      setToken({
        bankName,
        linkToken: response.response.link_token,
      });
    } catch (error) {
      console.error('error re-linking the account', error);
      showNotification({
        title: 'Failure',
        message: `Unable to get link token for ${bankName}. Please contact support if the issue persists.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (plaidAccount) {
      getLinkToken(plaidAccount);
    }
  }, [plaidAccount]);

  const handleRelinkClick = useCallback(() => {
    if (ready && open) {
      open();
    }
  }, [ready, open]);

  return (
    <Button
      onClick={handleRelinkClick}
      loading={loading}
      variant="neutral-outline"
      size="xs"
    >
      {buttonLabel}
    </Button>
  );
}
