import { TermsOfServiceType } from '../../../../services/flexbase/flexbase-onboarding-client';
import {
  createMultiStepFormRouteContext,
  RouteStep,
} from '../../../../providers/multi-step-form-provider';
import { Country } from 'react-phone-number-input';

export type PrefillFormStep = RouteStep<PrefillFormState> & {
  metadata: {
    description?: string;
    disclosures?: TermsOfServiceType[];
  };
};

export type PrefillFormState = {
  dateOfBirthProvided: boolean;
  phoneVerification: {
    phoneNumber: string;
    /**
     * This is the country code (eg. 'US'), not the country calling code (eg. '+1')
     */
    phoneNumberCountryCode: Country;
    methodId: string;
  } | null;
  phoneVerified: boolean;
  loading: boolean;
  error: string | null;
  prefilled: boolean;
  businessPrefillAvailable: boolean;
};

export const [PrefillProvider, usePrefillMultistepFormContext] =
  createMultiStepFormRouteContext<PrefillFormState, PrefillFormStep>();
