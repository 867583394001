import {
  Box,
  Group,
  Loader,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useClientAccessToken } from '@queries/use-credit-client-access-token';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';
import { useEffect, useRef, useState } from 'react';

type MarqetaCardFrameProps = {
  cardToken: string | undefined;
  status: string | undefined;
};

const IFRAME_ID = {
  PAN: 'mq-pan',
  COPY_PAN: 'mq-copy-pan',
  EXP: 'mq-exp',
  COPY_EXP: 'mq-copy-exp',
  CVV: 'mq-cvv',
  COPY_CVV: 'mq-copy-cvv',
};

// Since web-fonts aren't allowed by Marqeta.js, we need to use a font-stack that will render similarly on all devices natively
// This font stack was chosen by the design team
const FONT_STACK =
  "Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial, sans-serif;";
const EXP_CVV_FONT_SIZE = 16;
const TOOLTIP_TTL = 1200;
const MOBILE_SCALE = 0.75;

export const MarqetaCardFrame = ({
  cardToken = '',
  status,
}: MarqetaCardFrameProps) => {
  const theme = useMantineTheme();
  const isMobile = useIsMobile();
  const { data: clientAccessToken = '', isPending: isTokenPending } =
    useClientAccessToken(cardToken, {
      enabled: !!cardToken,
    });
  const [iframeLoading, setIframeLoading] = useState(true);
  const [error, setError] = useState('');
  const [panCopied, setPanCopied] = useState('');
  const [expCopied, setExpCopied] = useState('');
  const [cvvCopied, setCvvCopied] = useState('');
  const timeoutRef = useRef(0);

  const isActivated = status !== 'issued';
  const isLoading = isTokenPending || iframeLoading;
  const showCardStatus = !error && !isLoading;
  const showSecureDetails = !error && !!clientAccessToken;
  const showLoader = !error && isLoading;
  const showActivationText = !isActivated && showCardStatus;

  const mappedStatus = (() => {
    switch (status) {
      case 'suspended':
        return 'Frozen';
      case 'terminated':
        return 'Canceled';
      case 'issued':
      case 'inactive':
      case 'active':
      default:
        return status
          ? status.charAt(0).toUpperCase() + status.slice(1)
          : 'Inactive';
    }
  })();

  const displayTooltip = (onFn: () => void, offFn: () => void) => {
    resetAllTooltips();
    onFn();
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(offFn, TOOLTIP_TTL);
  };

  const resetAllTooltips = () => {
    setPanCopied('');
    setExpCopied('');
    setCvvCopied('');
  };

  useEffect(() => {
    if (!clientAccessToken) {
      return;
    }

    if (!isActivated) {
      setIframeLoading(false);
      return;
    }

    setIframeLoading(true);

    window.marqeta
      ?.bootstrap({
        clientAccessToken,
        component: {
          showPan: {
            cardPan: {
              domId: IFRAME_ID.PAN,
              format: true,
              styles: {
                span: {
                  color: theme.colors.neutral[0],
                  'font-size': '26px',
                  'font-family': FONT_STACK,
                },
              },
            },
            copyCardPan: {
              domId: IFRAME_ID.COPY_PAN,
              mode: 'transparent',
              onCopySuccess: () => {
                displayTooltip(
                  () => setPanCopied('Copied to clipboard'),
                  () => setPanCopied(''),
                );
              },
              onCopyFailure: () => {
                displayTooltip(
                  () => setPanCopied('Unable to copy to clipboard'),
                  () => setPanCopied(''),
                );
              },
            },
            cardCvv: {
              domId: IFRAME_ID.CVV,
              styles: {
                span: {
                  color: theme.colors.neutral[0],
                  'font-size': `${EXP_CVV_FONT_SIZE}px`,
                  'font-family': FONT_STACK,
                  'letter-spacing': '2px',
                },
              },
            },
            copyCardCvv: {
              domId: IFRAME_ID.COPY_CVV,
              mode: 'transparent',
              onCopySuccess: () => {
                displayTooltip(
                  () => setCvvCopied('Copied to clipboard'),
                  () => setCvvCopied(''),
                );
              },
              onCopyFailure: () => {
                displayTooltip(
                  () => setCvvCopied('Unable to copy to clipboard'),
                  () => setCvvCopied(''),
                );
              },
            },
            cardExp: {
              domId: IFRAME_ID.EXP,
              format: true,
              styles: {
                span: {
                  color: theme.colors.neutral[0],
                  'font-size': `${EXP_CVV_FONT_SIZE}px`,
                  'font-family': FONT_STACK,
                  'letter-spacing': '2px',
                },
              },
            },
            copyCardExp: {
              domId: IFRAME_ID.COPY_EXP,
              mode: 'transparent',
              onCopySuccess: () => {
                displayTooltip(
                  () => setExpCopied('Copied to clipboard'),
                  () => setExpCopied(''),
                );
              },
              onCopyFailure: () => {
                displayTooltip(
                  () => setExpCopied('Unable to copy to clipboard'),
                  () => setExpCopied(''),
                );
              },
            },
          },
        },
        callbackEvents: {
          onSuccess: () => {
            setIframeLoading(false);
          },
          onFailure: () => {
            setIframeLoading(false);
          },
        },
      })
      .catch((e) => {
        setError('Unable to load secure card information');
        console.error('Unable to load secure card information', e);
      });

    return () => {
      window.marqeta?.destroy();
    };
  }, [clientAccessToken, isActivated]);

  return (
    <Box
      sx={{
        position: 'relative', // Necessary to correctly center the loader
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 436 * (isMobile ? MOBILE_SCALE : 1),
        height: 275 * (isMobile ? MOBILE_SCALE : 1),
        backgroundColor: theme.colors.primary[8],
        borderRadius: 16,
        ...(status === 'suspended' && { opacity: 0.5 }),
        ...(showCardStatus && {
          backgroundImage: `url("/images/CardBack2x.png")`,
          backgroundSize: 'cover',
        }),
      }}
    >
      {showLoader && <Loader color="neutral.0" sx={{ position: 'absolute' }} />}
      {showCardStatus && (
        <Box
          sx={{
            backgroundColor: theme.colors.neutral[2],
            color: theme.colors.neutral[9],
            padding: '0 8px',
            borderRadius: '100px',
            position: 'absolute',
            zIndex: 100,
            top: isMobile ? 38 : 54,
            right: isMobile ? 28 : 44,
          }}
        >
          <Text fz={14} fw={500}>
            {mappedStatus}
          </Text>
        </Box>
      )}
      {showActivationText && (
        <Box
          sx={{
            padding: theme.spacing.md,
            borderRadius: 16,
            backgroundColor: theme.colors.neutral[2],
            fontSize: isMobile ? 16 : 20,
          }}
        >
          <Text c="primary.8" fw={500}>
            Activate card to view details
          </Text>
        </Box>
      )}

      {showSecureDetails && (
        <>
          <Tooltip label={panCopied} opened={!!panCopied} position="top-end">
            <Box
              id="pan-parent"
              sx={{
                position: 'absolute',
                height: 30,
                width: 280,
                transformOrigin: 'left',
                top: isMobile ? 100 : 144,
                left: isMobile ? 30 : 38,
                transform: isMobile ? `scale(${MOBILE_SCALE})` : undefined,
              }}
            >
              <Box id={IFRAME_ID.PAN} h={34} />
              <Box
                id={IFRAME_ID.COPY_PAN}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
            </Box>
          </Tooltip>

          <Group
            id="exp-cvv-parent"
            wrap="nowrap"
            gap={84}
            sx={{
              color: theme.colors.neutral[0],
              position: 'absolute',
              fontFamily: FONT_STACK,
              fontSize: EXP_CVV_FONT_SIZE,
              height: 30,
              transformOrigin: 'left',
              top: isMobile ? 132 : 180,
              left: isMobile ? 30 : 38,
              transform: isMobile ? `scale(${MOBILE_SCALE})` : undefined,
            }}
          >
            <Tooltip label={expCopied} opened={!!expCopied} position="top-end">
              <Group
                id="exp-parent"
                sx={{
                  alignItems: 'start',
                  position: 'relative',
                }}
                gap={8}
                wrap="nowrap"
              >
                <Box
                  sx={{
                    visibility:
                      isLoading || !isActivated ? 'hidden' : undefined,
                    display: 'inline-block',
                    lineHeight: 1.3,
                  }}
                >
                  EXP
                </Box>
                <Box
                  id={IFRAME_ID.EXP}
                  sx={{
                    display: 'inline-block',
                    width: 60,
                    height: 30,
                    overflow: 'hidden',
                  }}
                ></Box>

                <Box
                  id={IFRAME_ID.COPY_EXP}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                ></Box>
              </Group>
            </Tooltip>

            <Tooltip label={cvvCopied} opened={!!cvvCopied} position="top-end">
              <Group
                id="cvv-parent"
                sx={{
                  alignItems: 'start',
                  position: 'relative',
                }}
                gap={8}
                wrap="nowrap"
              >
                <Box
                  sx={{
                    visibility:
                      isLoading || !isActivated ? 'hidden' : undefined,
                    display: 'inline-block',
                    lineHeight: 1.3,
                  }}
                >
                  CVV
                </Box>
                <Box
                  id={IFRAME_ID.CVV}
                  sx={{
                    display: 'inline-block',
                    width: 60,
                    height: 30,
                    overflow: 'hidden',
                  }}
                />
                <Box
                  id={IFRAME_ID.COPY_CVV}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                />
              </Group>
            </Tooltip>
          </Group>
        </>
      )}

      {!!error && <Text c="neutral.0">{error}</Text>}
    </Box>
  );
};
