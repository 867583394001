import wretch from 'wretch';
import { FlexbaseOnboardingClient } from './flexbase/flexbase-onboarding-client';
import { FlexbaseBankingClient } from './flexbase/flexbase-banking-client';
import { AuthenticationTokenAccessorBase } from '@flexbase/http-client-middleware';
import { FlexbaseClient, FlexbasePasswordCredentials } from 'flexbase-client';
import { logger } from './logging';
import { RefreshTokenMiddleware, TokenMiddleware } from './token-middleware';

class FlexbaseAuthenticationTokenAccessor extends AuthenticationTokenAccessorBase<FlexbasePasswordCredentials> {
  protected generateBody(
    credentials: FlexbasePasswordCredentials,
    refreshToken: string | undefined,
  ): { url: string; body: any } {
    let url = '';
    const body: any = {};

    if (refreshToken && refreshToken.trim().length !== 0) {
      body.grant_type = credentials.refreshGrantType;
      body.refresh_token = refreshToken;
      url = credentials.refreshTokenUrl || credentials.tokenUrl;
    } else {
      body.grant_type = credentials.grantType;
      body.password = credentials.password;
      url = credentials.tokenUrl;
    }

    body.username = credentials.username;
    body.scope = credentials.scope;
    body.code = credentials.code;

    return { url, body };
  }
}

const tokenAccessor = new FlexbaseAuthenticationTokenAccessor();

const flexbaseClient = new FlexbaseClient(
  wretch(import.meta.env.VITE_APP_FLEXBASE_API_URL)
    .options({
      headers: { Accept: 'application/json' },
    })
    .accept('application/json')
    .middlewares([RefreshTokenMiddleware, TokenMiddleware]),
  tokenAccessor,
  logger,
);

export const flexbaseOnboardingClient = new FlexbaseOnboardingClient(
  flexbaseClient,
);
export const flexbaseBankingClient = new FlexbaseBankingClient(flexbaseClient);

export default flexbaseClient;
