import DebitCard from '../debit-card-details/debit-card';
import { Alert, Button, rem } from '@mantine/core';
import { CardByUser } from 'services/flexbase/banking.model';
import { RedAlertIcon } from 'assets/svg';
import { useVGScollect } from '@utilities/custom-hooks/vgs-collect';
import VGSerror from 'areas/banking/components/vgs-error/vgs-error';

type Props = {
  loading: boolean;
  cardStatus: string;
  errorMessage: string;
  card: CardByUser;
  onNextClick: (f: any) => void;
};

const cssInputField = {
  display: 'flex',
  fontSize: rem(48),
  textAlign: 'center',
  position: 'relative',
};

const SetPin = ({
  card,
  loading,
  cardStatus,
  errorMessage,
  onNextClick,
}: Props) => {
  const { form, errors, validateForm, errorOnInitForm } = useVGScollect(
    'setPIN',
    cssInputField,
  );
  const handleSubmit = () => {
    const hasErrors = validateForm();
    if (!hasErrors) {
      onNextClick(form);
    }
  };

  return (
    <div className="set-pin">
      <span className="title">Set your new pin</span>
      {errorOnInitForm ? (
        <VGSerror my={rem(20)} withCloseButton={false} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div id="cc-pin" className="field" />
          </div>
          <p className="valiation-message">{errors.validationPinMessage}</p>
        </>
      )}

      <div style={{ margin: `${rem(30)} 0px` }}>
        <DebitCard {...{ card, cardStatus }} />
      </div>

      {errorMessage && errorMessage !== '' && (
        <Alert mt={rem(20)} icon={<RedAlertIcon />} color="red">
          {errorMessage}
        </Alert>
      )}

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          mt={rem(20)}
          variant="primary-light"
          type="submit"
          {...{ loading }}
          onClick={handleSubmit}
        >
          Save pin
        </Button>
      </div>
    </div>
  );
};

export default SetPin;
