import { Button, rem, Text } from '@mantine/core';

type Props = {
  onClick: () => void;
};

const CancelDebitCardError = ({ onClick }: Props) => {
  return (
    <div className="error-step">
      <Text fw={500} fz={rem(20)} c="neutral.9">
        Something went wrong
      </Text>
      <p>An unexpected error occurred. Please, try it again later</p>
      <Button variant="primary-light" onClick={onClick}>
        Back to debit cards{' '}
      </Button>
    </div>
  );
};

export default CancelDebitCardError;
