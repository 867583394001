import { formatCurrency } from '@utilities/formatters';
import {
  Badge,
  Box,
  Flex,
  rem,
  SegmentedControl,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { AccountProps } from 'types/move-funds.model';
import { useConfirmAndReviewStyles } from '@utilities/custom-hooks/use-confirm-and-review.styles';
import AccountBox from '@common/composites/account-box/account-box';
import { useState } from 'react';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import AccountIcon from './acount-icon';
import { PiWarningCircle } from 'react-icons/pi';

type SegmentedControlValues = 'all' | 'flex' | 'external';

type AccountSegmentedControlProps = {
  onChange: (value: string | null) => void;
  data: { label: string; value: SegmentedControlValues }[];
};

const AccountSegmentedControl = ({
  onChange,
  data,
}: AccountSegmentedControlProps) => {
  const theme = useMantineTheme();
  return (
    <SegmentedControl
      data={data}
      size="xs"
      onChange={onChange}
      radius={4}
      color="neutral.2"
      my={2}
      ml={2}
      variant="secondary"
      styles={{
        label: {
          border: `solid 1px ${theme.colors.neutral[4]}`,
          '&[data-active]': {
            color: theme.colors.neutral[8],
          },
        },
        control: {
          marginRight: '0.5rem',
        },
      }}
    />
  );
};

const accountSubheaderText = (acct: AccountProps) => {
  switch (acct.plaidOrDeposit) {
    case 'deposit': {
      const flexbaseAccount = acct;
      return `${formatCurrency(
        flexbaseAccount.balance / 100,
      )} / ${capitalizeOnlyFirstLetter(
        flexbaseAccount.accountType,
      )} ${getPaddedAccountMask(flexbaseAccount.accountNumber, 4)}`;
    }
    case 'plaid': {
      const plaidAccount = acct;
      return (
        <>
          {plaidAccount.available &&
            `${formatCurrency(plaidAccount.available)} / `}
          {capitalizeOnlyFirstLetter(plaidAccount.accountType || '')}{' '}
          {getPaddedAccountMask(plaidAccount.last4 ?? plaidAccount.account, 4)}
        </>
      );
    }
    case 'admDeposit': {
      const admAccount = acct;
      return `${admAccount.subName} ${getPaddedAccountMask(
        admAccount.accountNumber,
        4,
      )}`;
    }
  }
};

const getName = (acct: AccountProps) => {
  switch (acct.plaidOrDeposit) {
    case 'deposit': {
      return acct.nickName ?? 'Flex';
    }
    case 'plaid': {
      return acct.bankName ?? acct.accountName;
    }
    case 'admDeposit': {
      return acct.name;
    }
  }
};

const filterAccountsBySegmentedControlValue = (
  value: SegmentedControlValues,
  accounts?: AccountProps[],
) => {
  if (!accounts) {
    return {
      all: [],
      flex: [],
      external: [],
    }[value];
  }

  return {
    all: accounts,
    flex: accounts.filter((acct) => acct.plaidOrDeposit === 'deposit'),
    external: accounts.filter((acct) => acct.plaidOrDeposit !== 'deposit'),
  }[value];
};

type Props = {
  currentAccount: AccountProps;
  accounts?: AccountProps[];
  onAccountChange?: (account: AccountProps) => void;
  label?: string;
  description?: string;
  classNames?: Partial<{ list: string; target: string }>;
  showAccountFilters?: boolean;
  onLinkingCompleted?: () => void;
  disabled?: boolean;
  errorMessage?: string;
};

const AccountSelection = ({
  currentAccount,
  accounts,
  onAccountChange,
  label,
  description,
  classNames,
  showAccountFilters = false,
  onLinkingCompleted,
  disabled = false,
  errorMessage,
}: Props) => {
  const { classes, cx, theme } = useConfirmAndReviewStyles();
  const [showSelect, setShowSelect] = useState(false);
  const [segmentedControlValue, setSegmentedControlValue] =
    useState<SegmentedControlValues>('all');
  const ref = useClickOutside(() => {
    resetDropdown();
  });

  const visibleAccounts = accounts?.filter(
    (acct) => acct.id !== currentAccount.id,
  );
  const filteredAccounts = filterAccountsBySegmentedControlValue(
    segmentedControlValue,
    visibleAccounts,
  );

  const resetDropdown = () => {
    setSegmentedControlValue('all');
    setShowSelect(false);
  };

  const handleSegmentedControlChange = (val: string | null) => {
    setSegmentedControlValue(val as SegmentedControlValues);
  };

  const handleDropdownClick = () => {
    if (disabled) {
      return;
    }
    if (accounts) {
      setShowSelect((prev) => {
        if (accounts.length > 1) {
          return !prev;
        }
        return prev;
      });
      setSegmentedControlValue('all');
    }
    setShowSelect(!showSelect);
  };

  return (
    <Box ref={ref} className={classNames?.target} pos="relative">
      {(label || description) && (
        <Stack mb={rem(4)} gap={rem(2)}>
          {label && <Text className={classes.inputTitle}>{label}</Text>}
          {description && (
            <Text fz={rem(12)} lh={rem(14)} c="neutral.8">
              {description}
            </Text>
          )}
        </Stack>
      )}

      <AccountBox
        headerText={
          <Flex>
            {getName(currentAccount)}{' '}
            {errorMessage && (
              <Badge
                ml={rem(6)}
                pl={rem(5)}
                pr={rem(5)}
                pb={0}
                pt={0}
                m={0}
                color={theme.colors.critical[0]}
                size="xs"
                variant="critical-flat"
              >
                <Flex align="center">
                  <PiWarningCircle size={12} color={theme.colors.critical[4]} />{' '}
                  <Text span ml={rem(2)} c={theme.colors.critical[6]} size="xs">
                    {errorMessage}
                  </Text>
                </Flex>
              </Badge>
            )}
          </Flex>
        }
        isError={!!errorMessage}
        subheaderText={accountSubheaderText(currentAccount) || ''}
        onClick={handleDropdownClick}
        showArrow={accounts && accounts.length > 1}
        rotateArrow={showSelect}
        isListItem={false}
        showBorder={true}
        icon={
          <AccountIcon
            externalName={
              currentAccount.plaidOrDeposit === 'plaid'
                ? getName(currentAccount)
                : null
            }
            data-testid={'accounts-list'}
          />
        }
        data-testid={'accounts-list'}
        onLinkingCompleted={onLinkingCompleted}
        isAccUnlinked={
          currentAccount.plaidOrDeposit === 'plaid' && currentAccount.unlinked
        }
        plaidAccount={
          currentAccount.plaidOrDeposit === 'plaid' ? currentAccount : undefined
        }
        style={{
          cursor: !showSelect ? 'default' : 'pointer',
          ...(disabled && {
            backgroundColor: theme.colors.neutral[2],
            cursor: 'not-allowed',
            opacity: 0.6,
          }),
        }}
      />

      {showSelect && accounts && accounts.length > 1 && (
        <Box className={cx(classes.selectList, classNames?.list)}>
          {showAccountFilters && (
            <AccountSegmentedControl
              data={[
                { label: 'All accounts', value: 'all' },
                { label: 'Flex accounts', value: 'flex' },
                { label: 'External accounts', value: 'external' },
              ]}
              onChange={handleSegmentedControlChange}
            />
          )}

          {filteredAccounts.map((acct, i) => (
            <Box key={acct.id} data-testid={`accounts-list-${i}`}>
              <AccountBox
                headerText={getName(acct)}
                subheaderText={accountSubheaderText(acct) || ''}
                onClick={() => {
                  if (onAccountChange) {
                    onAccountChange(acct);
                    resetDropdown();
                  }
                }}
                showArrow={false}
                rotateArrow={false}
                isListItem={true}
                icon={
                  <AccountIcon
                    externalName={
                      acct.plaidOrDeposit === 'plaid' ? getName(acct) : null
                    }
                    data-testid={'accounts-list'}
                  />
                }
                isAccUnlinked={acct.plaidOrDeposit === 'plaid' && acct.unlinked}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AccountSelection;
