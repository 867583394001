import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import PaymentsPendingTable from '../components/payments-pending-table/payments-pending-table';
import { usePayments } from '@utilities/custom-hooks/use-payments';
import { useRecoilValue } from 'recoil';
import { CompanyIdState } from 'recoil-state/application/onboarding-form.state';

const PendingPayments = () => {
  const companyId = useRecoilValue(CompanyIdState);
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const {
    users,
    payments,
    intlPayments,
    depositAccounts,
    isError,
    loading,
    refetch,
  } = usePayments({ companyId });
  // validate the ID in the URL (if one was provided) as a UUID
  if (
    id &&
    !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(id)
  ) {
    return <Navigate to="/payments/pending" />;
  }

  return (
    <PaymentsPendingTable
      users={users}
      payments={payments}
      intlPayments={intlPayments}
      depositAccounts={depositAccounts}
      recipientName={searchParams.get('recipient') || undefined}
      isLoading={loading}
      error={isError || undefined}
      handleRetry={refetch}
    />
  );
};

export default PendingPayments;
