import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';
import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
  useDebitCardsModalFilters,
  useMarqetaCardsModalFilters,
} from '../use-cards-filters';
import { CardTableRow } from '../card-table-row.model';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';

const TYPE_CARD_OPTIONS = [
  {
    label: 'Virtual',
    filterCriteria: 'VIRTUAL',
    key: 'virtual',
  },
  {
    label: 'Physical',
    filterCriteria: 'PHYSICAL',
    key: 'physical',
  },
];

type CardTypeFilterProps<T> = {
  filterHook: ActiveFiltersReturnType<T>;
};

const TypeFilter = <T extends CardTableRow>({
  filterHook,
}: CardTypeFilterProps<T>) => {
  return (
    <SimpleSegmentSelectedFilter
      filterHook={filterHook}
      filterKey="type"
      options={TYPE_CARD_OPTIONS}
      label="Type"
      includesValue={(row) => row.cardType}
    />
  );
};

export const CreditCardTypeFilter = () => {
  const filterHook = useCreditCardsModalFilters();

  return <TypeFilter filterHook={filterHook} />;
};

export const MarqetaCardTypeFilter = () => {
  const filterHook = useMarqetaCardsModalFilters();

  return <TypeFilter filterHook={filterHook} />;
};

export const ChargeCardTypeFilter = () => {
  const filterHook = useChargeCardsModalFilters();

  return <TypeFilter filterHook={filterHook} />;
};

export const DebitCardTypeFilter = () => {
  const filterHook = useDebitCardsModalFilters();

  return <TypeFilter filterHook={filterHook} />;
};
