import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';
import { ChecksTable } from '../../check-deposits';

const checkDepositsFilterState = atom<FilterFnMap<ChecksTable>>({
  key: 'check_deposits_filter_state',
  default: {},
});

const CheckDepositFilterModalState = atom<FilterFnMap<ChecksTable>>({
  key: 'check_deposit_filter_modal_state',
  default: {},
});

export function useCheckDepositModalFilters(): ActiveFiltersReturnType<ChecksTable> {
  return createUseFiltersHook<ChecksTable>(CheckDepositFilterModalState);
}

export function useCheckDepositFilters(): ActiveFiltersReturnType<ChecksTable> {
  return createUseFiltersHook(checkDepositsFilterState);
}
