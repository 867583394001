import { useNavigate } from 'react-router-dom';
import {
  IssueDebitCardWizard,
  IssueDebitCardWizardState,
  IssueDebitCardWizardStep,
} from './create-wizard';
import { IssueDebitCardStep } from './steps/issue-debit-card-step';
import { IssueDebitCardStepSubmittedStep } from './steps/issue-debit-card-submitted-step';
import IssueDebitCardWizardBody from './issue-debit-card-wizard-body';

const initialState: IssueDebitCardWizardState = {
  error: false,
  debitCardFormValues: {
    userId: '',
    cardSubType: null,
    cardName: '',
    limitType: 'unlimited',
    purchaseLimit: null,
    cardUser: '',
  },
};

const DebitCardWizard = () => {
  const navigate = useNavigate();
  const steps: IssueDebitCardWizardStep[] = [
    {
      id: IssueDebitCardStep.stepId,
      title: 'Issue a new debit card',
      element: <IssueDebitCardStep />,
    },
    {
      id: IssueDebitCardStepSubmittedStep.stepId,
      title: '',
      element: <IssueDebitCardStepSubmittedStep />,
    },
  ];

  const handleCancelClick = () => {
    navigate(-1);
  };

  return (
    <IssueDebitCardWizard
      steps={steps}
      initialState={initialState}
      onCancel={handleCancelClick}
      progressBar={false}
      body={<IssueDebitCardWizardBody />}
    />
  );
};

export default DebitCardWizard;
