import {
  rem,
  Box,
  Flex,
  Text,
  Grid,
  Button,
  Avatar,
  Divider,
  useMantineTheme,
} from '@mantine/core';
import { PiCheck } from 'react-icons/pi';

type BillPayCreditPaymentSuccessProps = {
  formattedTotal: string;
  paymentAccount: string;
  onClose: () => void;
};

const BillPayCreditPaymentSuccess = ({
  formattedTotal,
  paymentAccount,
  onClose,
}: BillPayCreditPaymentSuccessProps) => {
  const theme = useMantineTheme();
  const paymentInfo = [
    {
      label: 'Pay from',
      value: paymentAccount,
    },
  ];

  return (
    <>
      <Box
        p="xl"
        bg="neutral.0"
        sx={{
          borderRadius: theme.spacing.xxs,
        }}
      >
        <Flex gap="md" align="center">
          <Avatar p={rem(19)} size={rem(78)} bg="primary.0">
            <PiCheck size={rem(40)} color={theme.colors.primary[6]} />
          </Avatar>
          <Box>
            <Text size="lg" fw={500}>
              {`You've scheduled a ${formattedTotal} payment.`}
            </Text>
            <Text c="neutral.8" size="sm">
              {`We’ll update your available credit immediately.`}
            </Text>
          </Box>
        </Flex>

        <Divider color="neutral.3" my="md" />

        <Grid>
          {paymentInfo.map((info) => (
            <Grid.Col key={info.label} span={6}>
              <Text c="nuetral.8" size="sm">
                {info.label}
              </Text>
              <Text fw={500}>{info.value}</Text>
            </Grid.Col>
          ))}
        </Grid>
      </Box>
      <Flex mt={rem(40)} justify="flex-end">
        <Button onClick={onClose}>Done</Button>
      </Flex>
    </>
  );
};

export default BillPayCreditPaymentSuccess;
