import { FilterChips } from '@common/composites/filter-chips';
import useModal from '@common/composites/modal/modal-hook';
import { FilterData } from '@common/utilities/filter/filters';
import {
  Badge,
  Box,
  Button,
  Card,
  Group,
  Image,
  rem,
  SimpleGrid,
  Text,
  TextInput,
  useMantineTheme,
  Stack,
} from '@mantine/core';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import { BenefitsFeatured } from 'assets/svg';
import { useCallback, useMemo, useState } from 'react';
import { PiChats, PiMagnifyingGlass, PiSliders } from 'react-icons/pi';
import FilterDrawer from './benefits-list-filter-drawer';
import { useStyles } from './styles';
import { RightContentModal } from '@common/composites/modal/right-content.modal';

type BenefitDetailsDrawerProps = {
  benefit: Benefit;
};

const BenefitDetailsDrawer = ({ benefit }: BenefitDetailsDrawerProps) => {
  return (
    <RightContentModal>
      <RightContentModal.SingleTitleHeader title={benefit.title} />
      <RightContentModal.Body>
        <Stack>
          <Image
            src={benefit.imageSrc}
            width="100%"
            height={300}
            mr="auto"
            ml="auto"
          />
          <Stack gap={4}>
            <Text size={rem(20)} fw={500}>
              {benefit.detailTitle}
            </Text>
            <Text size={rem(14)} lh={'md'} mt={'mdxs'}>
              {benefit.detailContent}
            </Text>
          </Stack>
        </Stack>
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export type FilterState = {
  rewardType: string;
  categories: string[];
};

type Benefit = {
  type: string;
  category: string;
  imageSrc: string;
  title: string;
  description: string;
  ctaButtonText?: string;
  detailTitle: string;
  detailContent: string;
};

const staticBenefits = [
  {
    type: 'Flex Elite',
    category: 'Travel',
    imageSrc:
      'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-4.png',
    title: 'Travel',
    description: 'Hello I like travel',
    ctaButtonText: 'Access Concierge',
    detailTitle: 'Travel Benefits Overview',
    detailContent:
      'Detailed information about travel benefits and how to access them.',
  },
  {
    type: 'Flex Elite',
    category: 'Lounges',
    imageSrc:
      'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-4.png',
    title: 'Lounge 1',
    description:
      'Stuff about lounges. Blah blah blah. Blah blah blah. Blah blah blah.',
    detailTitle: 'Airport Lounge Access',
    detailContent:
      'Complete information about lounge access, locations, and how to use this benefit.',
  },
  {
    type: 'Flex Elite',
    category: 'Lounges',
    imageSrc:
      'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-4.png',
    title: 'Lounge 2',
    description:
      'More stuff about lounges. Blah blah blah. Blah blah blah. Blah blah blah.',
    detailTitle: 'Premium Lounge Experience',
    detailContent:
      'Details about our premium lounge offerings and exclusive amenities.',
  },
];

const BenefitCard = ({ benefit }: { benefit: Benefit }) => {
  const theme = useMantineTheme();
  const modal = useModal();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleCardClick = () => {
    const drawerContent = <BenefitDetailsDrawer benefit={benefit} />;
    if (isMobile) {
      modal.openFullModal(drawerContent);
    } else {
      modal.openRightModal(drawerContent);
    }
  };

  return (
    <Card
      withBorder
      shadow="sm"
      radius="0"
      p={'xl'}
      w="100%"
      onClick={handleCardClick}
      style={{ cursor: 'pointer' }}
    >
      <Card.Section inheritPadding py="xs">
        <Group justify="space-between">
          <Text size={rem(14)} c="neutral.7">
            {benefit.type}
          </Text>
          <Badge
            size="xl"
            radius="xs"
            styles={{
              label: {
                color: theme.colors.neutral[7],
                fontSize: rem(14),
              },
            }}
            color="neutral.3"
          >
            <BenefitsFeatured width={12} height={12} color={'#94928F'} />{' '}
            {benefit.category}
          </Badge>
        </Group>
      </Card.Section>

      <Card.Section inheritPadding>
        <Image
          src={benefit.imageSrc}
          width="100%"
          height={166}
          mr="auto"
          ml="auto"
          mt={8}
        />
      </Card.Section>

      <Card.Section inheritPadding mt="sm" pb="md">
        <Text mt={'md'} size={rem(20)}>
          {benefit.title}
        </Text>
        <Text size={rem(14)} lh={'md'} mt={'mdxs'}>
          {benefit.description}
        </Text>
      </Card.Section>

      {benefit.ctaButtonText && (
        <Card.Section inheritPadding mt="sm" pb="md" display="flex">
          <Button h={32} variant="outline" style={{ marginLeft: 'auto' }}>
            {benefit.ctaButtonText}{' '}
            <PiChats
              width={16}
              height={16}
              style={{ marginLeft: theme.spacing.xs }}
            />
          </Button>
        </Card.Section>
      )}
    </Card>
  );
};

const useBenefits = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debounced] = useDebouncedValue(searchQuery, 200);
  const [activeFilters, setActiveFilters] = useState<FilterState>({
    rewardType: 'all',
    categories: [],
  });

  const matchBenefit = (benefit: Benefit, searchString: string) => {
    return (
      benefit.type.toLowerCase().includes(searchString) ||
      benefit.category.toLowerCase().includes(searchString) ||
      benefit.title.toLowerCase().includes(searchString) ||
      benefit.description.toLowerCase().includes(searchString)
    );
  };

  const filteredBenefits = useMemo(() => {
    let benefits = staticBenefits.filter((b) => {
      if (
        activeFilters.rewardType !== 'all' &&
        b.type.toLowerCase() !== activeFilters.rewardType
      ) {
        return false;
      }

      if (
        activeFilters.categories.length > 0 &&
        !activeFilters.categories.includes(b.category.toLowerCase())
      ) {
        return false;
      }

      return true;
    });

    if (debounced?.length) {
      benefits = benefits.filter((b) =>
        matchBenefit(b, debounced.toLowerCase()),
      );
    }

    return benefits;
  }, [activeFilters, debounced]);

  const handleRemoveFilter = useCallback((filterKey: string) => {
    setActiveFilters((prev) => ({
      categories: prev.categories.filter((c) => c !== filterKey),
      rewardType: prev.rewardType === filterKey ? 'all' : prev.rewardType,
    }));
  }, []);

  const handleClearFilters = useCallback(() => {
    setActiveFilters({
      rewardType: 'all',
      categories: [],
    });
  }, []);

  const handleSearchChange = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  return {
    filteredBenefits,
    searchQuery,
    activeFilters,
    setActiveFilters,
    handleRemoveFilter,
    handleClearFilters,
    handleSearchChange,
  };
};
// This is a workaround to get the filter data type from a string, no need to bring Recoil into this
const createFilterDataType = (val: string): FilterData<Benefit> => {
  return {
    key: val,
    label: val,
    fn: (item: Benefit) => item.type === val,
    showChip: val === 'all' ? false : true,
  };
};

const BenefitsList = () => {
  const { classes } = useStyles();
  const modal = useModal();
  const {
    filteredBenefits,
    searchQuery,
    activeFilters,
    setActiveFilters,
    handleRemoveFilter,
    handleClearFilters,
    handleSearchChange,
  } = useBenefits();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const filterChipList = [
    createFilterDataType(activeFilters.rewardType),
    ...activeFilters.categories.map(createFilterDataType),
  ];
  const isInitialFilterChipList = filterChipList.every(
    (filter) => filter.key === 'all',
  );

  const handleFilterButtonClick = () => {
    const handleFilterApply = (filters: FilterState) => {
      setActiveFilters(filters);
      modal.closeAllModals();
    };

    const drawerContent = (
      <FilterDrawer
        onClose={() => modal.closeAllModals()}
        onApply={handleFilterApply}
        activeFilters={activeFilters}
      />
    );

    if (isMobile) {
      modal.openFullModal(drawerContent);
    } else {
      modal.openRightModal(drawerContent);
    }
  };

  return (
    <>
      <Box my={'xl'}>
        <Text size="xl">Benefits</Text>
        <Group>
          <TextInput
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
            leftSection={<PiMagnifyingGlass />}
            w={{ base: 'initial', sm: rem(400) }}
          />
          <Button
            variant="transparent"
            rightSection={<PiSliders />}
            onClick={handleFilterButtonClick}
          >
            Filters
          </Button>

          {!isInitialFilterChipList && (
            <FilterChips
              filterArray={filterChipList}
              onRemoveFilter={handleRemoveFilter}
              onClearFilters={handleClearFilters}
              groupProps={{ mb: 0 }}
            />
          )}
        </Group>
      </Box>

      {filteredBenefits.length === 0 ? (
        <Text className={classes.noResults}>No benefits found.</Text>
      ) : (
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="lg" w="100%">
          {filteredBenefits.map((benefit) => (
            <BenefitCard key={benefit.title} benefit={benefit} />
          ))}
        </SimpleGrid>
      )}
    </>
  );
};

export default BenefitsList;
