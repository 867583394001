import { Badge, MantineColor } from '@mantine/core';

type BadgeCellProps<T extends string = string> = {
  /**
   * The value to display in the badge
   */
  value: T;
  /**
   * Optional mapping of values to colors
   * If provided, will use this to determine badge color
   */
  colorMap?: Record<T, MantineColor>;
  /**
   * Optional direct color override
   * If provided, will override colorMap
   */
  color?: MantineColor;
  /**
   * Optional mapping of values to labels
   * If provided, will use this to determine badge text
   */
  labelMap?: Record<T, string>;
};

export const BadgeCell = <T extends string>({
  value,
  colorMap,
  color,
  labelMap,
}: BadgeCellProps<T>) => {
  const badgeColor = color ?? colorMap?.[value] ?? 'neutral.3';
  const label = labelMap?.[value] ?? value;

  return (
    <Badge bg={badgeColor} size="lg">
      {label}
    </Badge>
  );
};

export default BadgeCell;
