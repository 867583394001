import { platformClient } from '@services/platform/platform-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PostDocumentRequestBody } from '@flexbase-eng/sdk-typescript/models/operations';
import { DocumentYaml } from '@flexbase-eng/sdk-typescript/models/components';

const QUERY_KEY = 'get_platform_document';

async function getPlatformDocument(docId: string) {
  return await platformClient.downloadPlatformDocument(docId);
}

export function useGetPlatformDocument(docId: string) {
  return useQuery({
    queryKey: [QUERY_KEY, docId],
    queryFn: () => getPlatformDocument(docId),
    staleTime: Infinity,
    enabled: !!docId,
  });
}

export function useUploadPlatformDocument() {
  return useMutation({
    mutationFn: async (
      body: PostDocumentRequestBody,
    ): Promise<DocumentYaml> => {
      return await platformClient.uploadPlatformDocument(body);
    },
  });
}
