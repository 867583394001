import { ListCardsProgramsRequest } from '@flexbase-eng/sdk-typescript/models/operations';
import { platformClient } from '@services/platform/platform-client';
import { queryOptions, useQuery } from '@tanstack/react-query';

const listCardsProgramsOptions = (request: ListCardsProgramsRequest = {}) => {
  return queryOptions({
    queryKey: ['credit', 'programs', request],
    queryFn: async () => {
      // TODO: replace with SDK once types are fixed in the backend
      const res = await platformClient.listCardsPrograms(request);
      // const res = await platformSdk.programs.listCardsPrograms(request);

      return res.data;
    },
  });
};

export const useCardPrograms = (request: ListCardsProgramsRequest = {}) => {
  return useQuery(listCardsProgramsOptions(request));
};

export const useMarqetaCardProgram = () => {
  return useQuery({
    ...listCardsProgramsOptions({ active: true }),
    select: (programs) => {
      return programs.find(
        (program) => program.active && program.processor === 'marqeta',
      );
    },
  });
};

/**
 * 'card type' here refers to the specific variation of the card.
 *
 * In other words, 2 cards can both be physical and tied to the same credit
 * line, but if they have different packaging or designs, the vender considers
 * them each a different 'card type'.
 *
 */
export const useCardTypes = (personId: string) => {
  return useQuery({
    queryKey: ['credit', 'types', personId],
    queryFn: async () => {
      // TODO: replace with SDK once types are fixed in the backend
      const res = await platformClient.getCardTypes({ personId });
      // const res = await platformSdk.cards.getCardTypes({ personId });

      return res.cardTypes;
    },
  });
};
