import {
  Box,
  Button,
  Input,
  Loader,
  Menu,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import OnboardingStep from '../../components/onboarding-step';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { useEffect, useMemo, useState } from 'react';
import AddControlPersonForm from './add-control-person';
import { useStyle } from './styles';
import { useMediaQuery } from '@mantine/hooks';
import { useGetUsers } from '../../../../queries/use-users';
import { showNotification } from '@mantine/notifications';
import { OnboardingUser } from '../../../../types/onboarding-info';
import { EditIcon } from '../../../../assets/svg';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { platformClient } from '../../../../services/platform/platform-client';
import { ParsePlatformError } from '../../../../services/platform/models/identity.model';
import { Analytics } from '@services/analytics/analytics';
import { ApplicationEvents } from '@services/analytics/events';

const ControlPerson = () => {
  const { user, company, accountId } = useRecoilValue(ApplicationState);
  const { classes } = useStyle();
  const { data, isLoading, isError, isSuccess } = useGetUsers();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [newControlPerson, setNewControlPerson] = useState<
    Partial<OnboardingUser>
  >({});
  const [showControlPersonForm, setShowControlPersonForm] = useState(false);
  const [newPersonPreview, setNewPersonPreview] = useState(false);

  const { goBack, navigateToNextProductStep, createOrUpdateCompany } =
    useApplicationFlowContext();

  const dataResult = useMemo(
    () =>
      data
        ?.filter(
          (item) =>
            item.id !== user.id ||
            item.roles?.includes('EMPLOYEE') ||
            item.roles?.includes('SERVICER'),
        )
        .map((item) => {
          return {
            value: `${item.firstName} ${item.lastName}`,
            label: `${item.firstName} ${item.lastName}`,
            id: item.id,
            role: item.roles,
          };
        }),
    [data],
  );

  const [controlPerson, setControlPerson] = useState({
    name: '',
    id: '',
  });

  const selectData = [
    {
      value: `${user.firstName} ${user.lastName}`,
      label: `${user.firstName} ${user.lastName} (Applicant)`,
      id: user.id,
      role: user.roles,
    },
    ...(dataResult || []),
  ];

  const handleAddNewControlPerson = (request: Partial<OnboardingUser>) => {
    setNewControlPerson(request);
    setControlPerson({
      name: `${request?.firstName} ${request?.lastName}`,
      id: '',
    });
    setNewPersonPreview(true);
  };

  const handleNextClick = async () => {
    Analytics.track(ApplicationEvents.CONTROL_PERSON_SUBMIT_CLICKED);
    try {
      setLoading(true);

      let controlPersonId = controlPerson.id;

      if (!controlPersonId && newControlPerson.email) {
        const inviteResponse = await platformClient.inviteUser(
          accountId,
          newControlPerson.email,
          'signatory',
          {
            firstName: newControlPerson.firstName,
            lastName: newControlPerson.lastName,
            roles: ['ADMIN'],
            companyId: company.id,
          },
        );
        controlPersonId = inviteResponse.person.id;
      }
      const result = await createOrUpdateCompany({
        controlPerson: controlPersonId,
      });
      if (result) {
        navigateToNextProductStep();
      } else {
        setError('Unable to add a control person');
        showNotification({
          color: 'red',
          title: 'Error',
          message: `Unable to set control person`,
        });
      }
    } catch (catchError) {
      console.error('Unable to add a control person', catchError);
      const asPlatformError = ParsePlatformError(catchError);
      if (asPlatformError?.statusCode === 409) {
        setError('A user with this email already exists.');
      } else {
        setError('Unable to add a control person');
      }
    } finally {
      setLoading(false);
    }
  };

  const onBackClick = async () => {
    goBack();
  };

  useEffect(() => {
    if (data && isSuccess) {
      const find = data?.find((item) => item.id === company.controlPerson);
      const initialValue = !find ? user : find;
      setControlPerson({
        name: `${initialValue?.firstName} ${initialValue?.lastName}`,
        id: initialValue?.id || '',
      });
    }
  }, [data, isSuccess]);

  const showAddNewPersonButton =
    !showControlPersonForm &&
    !newControlPerson.firstName &&
    !company.controlPerson;

  return (
    <OnboardingStep
      title="Add control person"
      subtitle="Please enter information for one individual with significant responsibility for
                managing the legal entity applying for this account, such as a: Chief Executive Officer,
                Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, or an
                individual who regularly performs similar functions."
      stepId="control-person"
      onBackClick={onBackClick}
      error={isError ? 'Unable to get the company users' : error}
      showContinueSpinner={loading}
      onNextClick={handleNextClick}
    >
      <div>
        <Menu
          width={useMobileView ? 360 : 520}
          data-testid="menu-select-control-person"
          id="menu-select-control-person"
        >
          <Menu.Target>
            <Input
              component="button"
              aria-placeholder="Select a control person"
              pointer
              data-sardine-id="input-control-person"
            >
              {controlPerson.name}
            </Input>
          </Menu.Target>
          <Menu.Dropdown>
            {isLoading ? (
              <Loader color={theme.colors.primary[3]} />
            ) : (
              <>
                {selectData.map((item) => (
                  <Menu.Item
                    data-testid={`${item.value}`}
                    id={`${item.value}`}
                    onClick={() => {
                      setNewPersonPreview(false);
                      setShowControlPersonForm(false);
                      setControlPerson({ name: item.value, id: item.id || '' });
                    }}
                    key={item?.id}
                  >
                    {item.id === user.id ? (
                      <div className={classes.inputsContainer}>
                        <Text>{item.label}</Text>
                        <Text c={theme.colors.primary[3]}>Default</Text>
                      </div>
                    ) : (
                      <div>
                        <Text>{item.label}</Text>
                      </div>
                    )}
                  </Menu.Item>
                ))}
                {newControlPerson.firstName && (
                  <Menu.Item
                    onClick={() => {
                      setNewPersonPreview(true);
                      setControlPerson({
                        name: `${newControlPerson?.firstName} ${newControlPerson?.lastName}`,
                        id: '',
                      });
                    }}
                  >
                    {newControlPerson.firstName} {newControlPerson.lastName}
                  </Menu.Item>
                )}
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </div>
      {newPersonPreview && (
        <Box mt="sm" bg="#ECE9E2" p="sm">
          <Text size={'sm'} mb={6}>
            New Person Added
          </Text>
          <Box
            bg="neutral.0"
            sx={{ border: `1px solid ${theme.colors.neutral[4]}` }}
            p={8}
            className={classes.inputsContainer}
          >
            <Text size={'sm'}>
              {newControlPerson.firstName} {newControlPerson.lastName}
            </Text>
            <UnstyledButton
              onClick={() => setShowControlPersonForm(!showControlPersonForm)}
            >
              <EditIcon width={18} height={18} />
            </UnstyledButton>
          </Box>
        </Box>
      )}
      {showControlPersonForm && (
        <AddControlPersonForm
          onCancel={() => setShowControlPersonForm(false)}
          setControlPerson={handleAddNewControlPerson}
          newControlPerson={newControlPerson}
        />
      )}
      {showAddNewPersonButton && (
        <Button
          mt="xl"
          variant="neutral-outline"
          fullWidth
          classNames={{ root: classes.addNewControlPersonBtn }}
          onClick={() => setShowControlPersonForm(true)}
          id="button-add-new-owner"
        >
          + Add new person
        </Button>
      )}
    </OnboardingStep>
  );
};

export default ControlPerson;
