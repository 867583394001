import { MantineTheme } from '@mantine/core';
import { Page, Text, View } from '@react-pdf/renderer';
import HeaderPDF from '@common/composites/pdf-viewer/header';
import {
  DomesticWireInstructionsModel,
  InternationalWireInstructionsModel,
} from 'services/flexbase/banking.model';
import { stylesPDF } from './styles';

const getCompanyAddress = (address: string) => {
  const parts = address.split(', ');
  if (parts.length < 2) {
    return address;
  }
  const [street, city, stateAndZipCode] = parts;

  const [state, ...rest] = stateAndZipCode.split(' ');
  let zipCode = rest.join(' ');

  if (zipCode.search('-') > 0) {
    zipCode = zipCode.split('-')[0];
  }

  return `${street}\n${city}, ${state} - ${zipCode}`;
};

const isDomesticWireInstructionsModel = (
  wireInstructions:
    | DomesticWireInstructionsModel
    | InternationalWireInstructionsModel,
): wireInstructions is DomesticWireInstructionsModel => {
  return (
    (wireInstructions as DomesticWireInstructionsModel).receiverABA !==
    undefined
  );
};

type WireInstructionProps = {
  theme: MantineTheme;
  wireInstructions?:
    | DomesticWireInstructionsModel
    | InternationalWireInstructionsModel;
  error: string;
};

const WireInstructionDoc = ({
  theme,
  wireInstructions,
  error,
}: WireInstructionProps) => {
  const styles = stylesPDF({
    primaryDark: theme.colors.primary[8],
    secondaryDark: theme.colors.primary[2],
    neutral: theme.colors.neutral[3],
    textColor: theme.colors.neutral[7],
  });

  const isDomesticWire =
    wireInstructions && isDomesticWireInstructionsModel(wireInstructions);

  const bankNameSubcontent = isDomesticWire
    ? 'Flex uses Thread Bank as a banking partner'
    : 'Flex uses CurrencyCloud as a currency exchange partner';

  const title = `${
    isDomesticWire ? 'Domestic' : 'International'
  } Transfer Details`;

  const wireType = isDomesticWire ? 'domestic' : 'international';

  const filterTitlesAndNamesByWireType = (item: {
    id: string;
    title: string;
    info?: string;
  }) => {
    if (isDomesticWire && item.id === 'swiftNumber') {
      return;
    } else if (!isDomesticWire && item.id === 'accountNumber') {
      return;
    }
    return item;
  };

  const getTitlesAndNames = () =>
    [
      {
        id: 'accountName',
        title: 'Account Name',
        info: wireInstructions?.beneficiaryName,
      },
      { id: 'typeAccount', title: 'Type of Account', info: 'Checking' },
      {
        id: 'accountAddress',
        title: 'Account Address',
        info: wireInstructions?.beneficiaryAddress,
      },
      {
        id: 'accountNumber',
        title: 'Account Number',
        info: wireInstructions?.beneficiaryAccountNumber,
      },
      {
        id: 'routingNumber',
        title: isDomesticWire ? 'Routing Number' : 'IBAN',
        info: isDomesticWire
          ? wireInstructions?.receiverABA
          : wireInstructions?.receiverIBAN,
      },
      {
        id: 'swiftNumber',
        title: 'SWIFT',
        info: wireInstructions?.beneficiaryBankRoutingNumber,
      },
      {
        id: 'bankName',
        title: 'Bank Name',
        info: wireInstructions?.beneficiaryBankName,
      },
      {
        id: 'bankAddress',
        title: 'Bank Address',
        info: wireInstructions?.beneficiaryBankAddress,
      },
    ].filter((item) => filterTitlesAndNamesByWireType(item));

  const subheaderContent = error
    ? 'Please refresh your browser or contact support for assistance.'
    : `Use these details to send ${wireType} wires to ${wireInstructions?.beneficiaryName}’s Flex account.`;

  return (
    <Page size="A4">
      <View fixed>
        <HeaderPDF {...{ theme }} />
      </View>
      <View fixed style={styles.containerHeader}>
        <View>
          <Text style={styles.textHeader}>
            {wireInstructions?.beneficiaryName}
          </Text>
          <Text style={{ ...styles.textHeader, textAlign: 'right' }}>
            {getCompanyAddress(wireInstructions?.beneficiaryAddress || '')}
          </Text>
        </View>
      </View>

      <View style={styles.containerPage}>
        {error ? (
          <View style={{ ...styles.card, marginTop: 25 }}>
            <Text style={styles.title}>{error}</Text>
          </View>
        ) : (
          <>
            <View>
              <Text style={{ ...styles.subText, fontStyle: 'thin' }}>
                Let’s flex this
              </Text>
              <Text style={{ ...styles.title }}>{title}</Text>
              <Text style={{ ...styles.subText, fontStyle: 'thin' }}>
                {subheaderContent}
              </Text>
            </View>
            <View style={{ ...styles.card, marginTop: 25 }}>
              <Text style={styles.greenText}>Beneficiary</Text>
              {getTitlesAndNames()
                .slice(0, 3)
                .map((item) => (
                  <View key={item.id} style={styles.containerData}>
                    <Text style={styles.text}>{item.title}</Text>
                    <Text style={styles.textInfo}>{item.info}</Text>
                  </View>
                ))}
            </View>

            <View style={{ ...styles.card, marginTop: 25 }}>
              <Text style={styles.greenText}>Bank Details</Text>
              {getTitlesAndNames()
                .slice(3, 7)
                .map((item) => (
                  <View key={item.id} style={styles.containerData}>
                    <Text style={styles.text}>{item.title}</Text>
                    {item.title === 'Bank Name' ? (
                      <View>
                        <Text style={styles.textInfo}>{item.info}</Text>
                        <Text
                          style={{ ...styles.smallText, fontStyle: 'thin' }}
                        >
                          {bankNameSubcontent}
                        </Text>
                      </View>
                    ) : (
                      <Text style={styles.textInfo}>{item.info}</Text>
                    )}
                  </View>
                ))}
            </View>
          </>
        )}
      </View>

      <View style={styles.footerContainer} fixed>
        <Text>Banking services provided by Thread Bank, Member FDIC.</Text>
        <Text>
          Apply for an account in minutes at{' '}
          <Text style={styles.smallGreenText}>flex.one</Text>
        </Text>
      </View>
    </Page>
  );
};

export default WireInstructionDoc;
