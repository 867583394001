import { useState } from 'react';
import {
  Box,
  rem,
  Flex,
  Text,
  Radio,
  Avatar,
  RadioGroup,
  useMantineTheme,
} from '@mantine/core';
import { IconType } from 'react-icons';
import { PaymentSource } from '../../../invoice-wizard.js';
import WizardErrorBanner from '../../../../wizard-error-banner.js';
import { CustomOptionsSelect } from './custom-options-select.tsx.js';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model.js';

type OptionsProps = {
  label: string;
  Icon: IconType;
  placeholder: string;
  value: PaymentSource;
  accounts: (PlaidAccount | DepositAccount)[];
};

type PaymentOptionProps = {
  options: OptionsProps[];
  isActionDisabled: boolean;
  notEnoughFundsError?: string;
  initialPaymentSource: PaymentSource;
  sendFrom?: PlaidAccount | DepositAccount;
  onAccountChange: (accountId: string) => void;
};

const PaymentOption = ({
  options,
  sendFrom,
  onAccountChange,
  isActionDisabled,
  initialPaymentSource,
  notEnoughFundsError,
}: PaymentOptionProps) => {
  const theme = useMantineTheme();
  const initialValue = initialPaymentSource ?? '';
  const [value, setValue] = useState<PaymentSource>(initialValue);

  const getBorderColor = (option: string, currentValue: string) => {
    if (option === currentValue) {
      return theme.colors.primary[3];
    }
    return theme.colors.neutral[4];
  };

  return (
    <>
      <RadioGroup
        value={value}
        onChange={(val) => setValue(val as PaymentSource)}
      >
        {options.map((opt) => (
          <Box
            p="sm"
            bg="neutral.0"
            key={opt.label}
            onClick={() => setValue(opt.value)}
            sx={{
              cursor: 'pointer',
              borderRadius: rem(2),
              border: `1px solid ${getBorderColor(opt.value, value)}`,
            }}
          >
            <Flex align="center" justify="space-between">
              <Flex gap="sm" align="center">
                <Avatar bg="primary.0">
                  <opt.Icon size="1.5rem" color={theme.colors.primary[9]} />
                </Avatar>
                <Text fw={400}>{opt.label}</Text>
              </Flex>
              <Radio value={opt.value} disabled={isActionDisabled} />
            </Flex>
            {opt.value === value && (
              <CustomOptionsSelect
                sendFrom={sendFrom}
                placeholder={opt.placeholder}
                paymentAccounts={opt.accounts}
                onAccountChange={onAccountChange}
                isActionDisabled={isActionDisabled}
              />
            )}
          </Box>
        ))}
      </RadioGroup>
      {notEnoughFundsError && (
        <WizardErrorBanner message={notEnoughFundsError} />
      )}
    </>
  );
};

export default PaymentOption;
