import { createStyles } from '@mantine/emotion';

export const useSettingsSectionStyles = createStyles((theme) => ({
  sectionContainer: {
    display: 'grid',
    minHeight: 90,
    width: '100%',
    gridTemplateColumns: '395px 395px',
    paddingLeft: '24px',
    '@media(max-width: 767px)': {
      gridTemplateColumns: '50% 50%', // Set to 50% instead of 1fr so that overflowing text doesn't mess up vertical alignment
      paddingLeft: '12px',
    },
  },
  containerBorder: {
    borderBottom: '1px solid #E6E7E9',
  },
  text: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.colors.neutral[7],
    '@media(max-width: 767px)': {
      overflowWrap: 'break-word',
    },
  },
  edit: {
    cursor: 'pointer',
    marginTop: 16,
  },
  fieldContainer: {
    marginTop: '16px',
  },
  valueContainer: {
    margin: '16px 0',
  },
  photoPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    marginRight: 32,
    height: '70px',
    width: '70px',
    position: 'relative',
    backgroundColor: '#E2E2E2',
    [`& img`]: {
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: '100%',
      height: '100%',
      width: '70px',
    },
  },
  uploadPhotoText: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#808080',
    cursor: 'pointer',
  },
}));
