import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  activateDebitCard: {
    margin: '0px',
    padding: rem(40),
    minHeight: '100vh',
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[3],
    '.close': {
      display: 'grid',
      justifyContent: 'end',
      alignContent: 'baseline',
      p: {
        margin: '0px',
        textAlign: 'center',
      },
    },
    '.content': {
      width: rem(420),
      display: 'flex',
      marginTop: rem(20),
      justifyContent: 'center',
    },
    iframe: {
      width: '100%',
      height: '100%',
    },
    '.input-data': {
      width: rem(420),
      '.title': {
        color: theme.colors.neutral[10],
        fontWeight: 400,
        fontSize: rem(28),
        lineHeight: rem(33),
      },
      p: {
        fontSize: rem(14),
      },

      '.group': {
        width: rem(350),
        padding: rem(20),
        margin: `${rem(20)} 0px`,
        borderRadius: theme.defaultRadius,
        border: `1px solid ${theme.colors.neutral[2]}`,
        p: {
          margin: '0px',
          fontSize: rem(12),
          color: theme.colors.critical[2],
        },
      },

      label: {
        height: rem(50),
        display: 'flex',
        lineHeight: rem(50),
        position: 'relative',
        flexDirection: 'row',
        borderBottom: `1px solid ${theme.colors.neutral[10]}`,
      },

      'label > span': {
        width: '40%',
        color: theme.colors.neutral[10],
        fontSize: rem(14),
        textAlign: 'right',
        marginRight: rem(30),
      },

      '.field': {
        flex: 1,
        cursor: 'text',

        fontWeight: 300,
      },

      '.response-container': {
        paddingTop: rem(30),
        maxWidth: rem(480),
      },

      '.image-container': {
        display: 'flex',
        margin: `${rem(20)} 0px`,
        justifyContent: 'center',
      },
    },
    '.error': {
      display: 'flex',
      padding: '16px',
      fontSize: rem(14),
      color: '#991B1B',
      margin: rem(30),
      borderRadius: theme.defaultRadius,
      alignItems: 'center',
      backgroundColor: '#FEF2F2',
      svg: {
        marginRight: '10px',
      },
    },
    '.set-pin': {
      width: rem(420),
      '.valiation-message': {
        fontWeight: 400,
        fontSize: rem(14),
        textAlign: 'center',
        color: theme.colors.critical[1],
      },
      '.title': {
        color: theme.colors.neutral[10],
        fontWeight: 400,
        fontSize: rem(28),
        lineHeight: rem(33),
        fontFamily: 'PP Neue Montreal',
      },
      '.field': {
        color: theme.colors.neutral[10],
        width: rem(170),
        height: rem(60),
        fontWeight: 400,
        fontSize: rem(48),
        marginTop: rem(30),
        borderBottom: `${rem(3)} solid ${theme.colors.primary[2]}`,
      },
    },
    '.error-step': {
      span: {
        fontWeight: 400,
        fontSize: rem(28),
        color: theme.colors.neutral[10],
      },
      button: {
        display: 'flex',
        fontSize: rem(12),

        marginTop: rem(24),
        alignItems: 'center',
        svg: {
          marginLeft: rem(12),
        },
      },
    },
    '@media(max-width: 767px)': {
      padding: rem(20),
      '.content': {
        width: '100%',
        display: 'block',
      },
      '.close-button': {
        width: '100%',
        display: 'flex',
        marginBottom: rem(15),
        justifyContent: 'flex-end',
      },
      '.input-data': {
        '.title': {
          fontSize: rem(24),
        },
        p: {
          margin: '0px',
        },
      },
      '.set-pin': {
        width: 'auto',
        '.title': {
          fontSize: rem(24),
        },
      },
    },
  },

  card: {
    padding: rem(34),
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
}));
