import { useState } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { SimpleGrid, Stack, Text, TextInput } from '@mantine/core';
import FlexSelect from '@common/composites/flex-select';
import { US_STATES } from 'constants/constants';
import { formatZip } from 'utilities/formatters/format-address';
import GooglePlacesSuggest from '@common/composites/input/google-places-suggest-input';
import { SummaryEditInput } from './summary-edit-input';

type AddressFormValues = {
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
  };
};
type Props<T extends AddressFormValues> = {
  displayLine1: string;
  displayLine2: string;
  form: UseFormReturnType<T>;
  isEditMode: boolean;
  label: string;
  initialSearch: string;
};
const SummaryAddressForm = <T extends AddressFormValues>({
  displayLine1,
  displayLine2,
  form,
  isEditMode,
  label,
  initialSearch,
}: Props<T>) => {
  const [searchAddress, setSearchAddress] = useState(initialSearch);

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    /**
     * The form context is type safe and will only allow us to set the values that are defined in the form schema.
     * Mantine actually just uses `any` under the hood in the `onChange` method from `form.getInputProps` as well,
     * so casting these to `any` is reasonably safe.
     */
    form.setFieldValue('address.line1', item.address.trim() as any);
    form.setFieldValue('address.city', item.city as any);
    form.setFieldValue('address.state', item.state as any);
    form.setFieldValue('address.postalCode', item.postalCode as any);
  };

  const setZip = (zip: string) => {
    form.setFieldValue('address.postalCode', formatZip(zip) as any);
  };

  const addressString = (
    <Stack gap="sm">
      <Text>{displayLine1}</Text>
      <Text>{displayLine2}</Text>
    </Stack>
  );

  return (
    <SummaryEditInput
      label={label}
      value={addressString}
      isEditMode={isEditMode}
      editInputs={
        <SimpleGrid cols={{ base: 1, sm: 2 }}>
          <GooglePlacesSuggest
            label="Street"
            value={searchAddress}
            onChange={setSearchAddress}
            onOptionSubmit={selectAddress}
            placeholder="Address"
            data-sardine-id="input-search-edit-address"
            id="input-search-address"
          />

          <TextInput
            label="Apt/Unit number"
            data-sardine-id="input-edit-address-line2"
            placeholder="Apt, suite, etc. (Optional)"
            {...form.getInputProps('address.line2')}
          />
          <TextInput
            label="City"
            placeholder="City"
            {...form.getInputProps('address.city')}
            data-sardine-id="input-edit-city"
          />
          <FlexSelect
            {...form.getInputProps('address.state')}
            label="State"
            data-sardine-id="input-edit-state"
            placeholder="State"
            data={US_STATES}
            searchable
            comboboxProps={{ position: 'bottom' }}
            maxDropdownHeight={400}
          />
          <TextInput
            label="Zip code"
            placeholder="Zip code"
            pattern={'[0-9]*'}
            data-sardine-id="input-edit-postal-code"
            {...form.getInputProps('address.postalCode')}
            onChange={(e) => setZip(e.target.value)}
          />
        </SimpleGrid>
      }
    />
  );
};

export default SummaryAddressForm;
