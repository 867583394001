import { useState } from 'react';
import { Box, Text, Flex, LoadingOverlay, UnstyledButton } from '@mantine/core';
import { PiBank } from 'react-icons/pi';
import { RegularPlus } from 'assets/svg';
import { usePlaidBankingComponent } from '@utilities/custom-hooks/plaid-banking-component';

type Props = {
  onLinkAccount: () => void;
};

const ConnectBankAccount = ({ onLinkAccount }: Props) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { open, ready } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => setError('*Unable to link bank account'),
    setLoading,
  });

  const onLinkSuccess = async () => {
    setError('');
    onLinkAccount();
  };

  const onLinkBankClick = () => {
    if (ready) {
      setError('');
      open();
    }
  };

  return (
    <Box>
      <UnstyledButton
        p="xl"
        w="100%"
        bg="neutral.0"
        onClick={onLinkBankClick}
        sx={(theme) => ({ border: `1px solid ${theme.colors.neutral[4]}` })}
      >
        {loading && <LoadingOverlay visible={loading} />}
        <Flex justify="space-between">
          <Flex gap="md" align="center">
            <PiBank size={24} />
            <Text>Connect a bank account</Text>
          </Flex>
          <RegularPlus />
        </Flex>
      </UnstyledButton>
      {error && (
        <Text mt="sm" size="sm" c="critical.5">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default ConnectBankAccount;
