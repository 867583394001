import { flexbaseBankingClient } from '../services/flexbase-client';
import { DepositAccountHistoryParams } from '../services/flexbase/banking.model';
import { useQuery } from '@tanstack/react-query';

const DEPOSIT_ACCOUNT_HISTORY_QUERY_KEY = 'bankingDepositAccountHistory';

export const useGetDepositAccountHistory = (
  id: string,
  params: DepositAccountHistoryParams = {},
) => {
  return useQuery({
    queryKey: [DEPOSIT_ACCOUNT_HISTORY_QUERY_KEY, id, params],
    queryFn: async () => {
      return await flexbaseBankingClient.getDepositAccountHistory(id, params);
    },
    meta: {
      errorMessage: 'Unable to retrieve banking account history at this time.',
    },
    enabled: !!id,
  });
};
