import {
  Alert,
  Badge,
  Box,
  Flex,
  LoadingOverlay,
  rem,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import {
  Limits,
  CardByUser,
  DepositAccount,
  ChargeCardAccount,
} from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import Actions from 'areas/banking/debit-cards/debit-card-details/actions';
import DebitCard from 'areas/banking/debit-cards/debit-card-details/debit-card';
import TerminatedDebitCard from 'areas/banking/debit-cards/debit-card-details/terminated-debit-card';
import UpdateDebitOrChargeCard from 'areas/banking/debit-cards/debit-card-details/update-debit-or-charge-card';
import {
  getATMSpendLimitByTier,
  getTier,
} from 'areas/banking/debit-cards/util';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';
import { useRecoilValue } from 'recoil';
import { IsEmployee } from 'recoil-state/application/product-onboarding';
import { cardIsChargeCard, FlexCard } from '@services/flexbase/card.model';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import { PiXCircle } from 'react-icons/pi';
import { newCategories } from 'constants/mcc-restrictions';
import { RightContentModal } from '@common/composites/modal/right-content.modal';

type Props = {
  card: CardByUser;
};

const DebitCardDetails = ({ card }: Props) => {
  const [error, setError] = useState(false);
  const isEmployee = useRecoilValue(IsEmployee);
  const [editCard, setEditCard] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [cardStatus, setCardStatus] = useState(card.status);
  const isChargeCard = cardIsChargeCard(card as unknown as FlexCard);
  const { data } = useGetDepositAccounts(!(isChargeCard || isEmployee));
  const depositAccounts = data?.accounts || [];
  const { data: chargeAccountsData, isLoading: isChargeAccountLoading } =
    useGetChargeCardAccounts(isChargeCard);
  const chargeAccounts = chargeAccountsData?.accounts ?? [];

  const getLimitType = (expensesTypes: Limits) => {
    if (expensesTypes?.dailyPurchase) {
      return 'daily';
    }
    if (expensesTypes?.monthlyPurchase) {
      return 'monthly';
    }
    return 'unlimited';
  };

  const limitType = getLimitType(card.expensesTypes);

  const cardLimit =
    card.expensesTypes?.dailyPurchase ??
    card.expensesTypes?.monthlyPurchase ??
    0;

  const [cardData, setCardData] = useState({
    ...card,
    limit: cardLimit,
    categories: card.expensesTypes?.groups,
    limitType: limitType as 'daily' | 'monthly' | 'unlimited',
  });

  const displayPercent = Math.round(
    (card.monthToDateSpends?.mtdSpend * 100) / (cardData.limit / 100),
  );

  const dailyWithdrawal = cardData.expensesTypes?.dailyWithdrawal
    ? cardData.expensesTypes.dailyWithdrawal / 100
    : null;
  const currentDailyWithdrawalLimit =
    dailyWithdrawal ??
    getATMSpendLimitByTier(depositAccounts, card.depositAccountId);

  const getAccountName = (
    accts: DepositAccount[] | ChargeCardAccount[],
    currentId: string,
  ) => {
    const { nickName, name } = accts.find(({ id }) => id === currentId) ?? {};
    return nickName || name || '';
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (error) {
        setError(false);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [error]);

  const getCurrentLimit = () => {
    switch (cardData.limitType) {
      case 'daily':
        return ((cardData.expensesTypes?.dailyPurchase ?? 0) / 100).toFixed(2);
      case 'monthly':
        return ((cardData.expensesTypes?.monthlyPurchase ?? 0) / 100).toFixed(
          2,
        );
      default:
        return '0.00';
    }
  };

  const getAccountNameValue = () => {
    if (isChargeCard) {
      return getAccountName(chargeAccounts, card.chargeCardAccountId);
    }

    return getAccountName(depositAccounts, card.depositAccountId);
  };

  const categoriesAllowed =
    cardData.categories?.map(
      (group) => newCategories.find((cat) => cat.value === group)?.label ?? '',
    ) ?? [];

  const footerData = [
    {
      title: 'Account',
      value: getAccountNameValue(),
    },
    {
      title: 'Card type',
      value: capitalizeOnlyFirstLetter(card.cardType),
    },
    {
      title:
        cardData.limitType !== 'unlimited'
          ? `${capitalizeOnlyFirstLetter(cardData.limitType)} spending limit`
          : 'Spending limit',
      value:
        cardData.limitType !== 'unlimited'
          ? formatCurrency(getCurrentLimit())
          : 'Unlimited',
    },
  ];

  if (!(isChargeCard || card.cardType === 'VIRTUAL')) {
    footerData.splice(2, 0, {
      title: 'Daily ATM withdrawal limit',
      value: formatCurrency(currentDailyWithdrawalLimit),
    });
  }

  if (isChargeCard && categoriesAllowed.length > 0) {
    footerData.splice(4, 0, {
      title: 'Allowed spending categories',
      value: categoriesAllowed[0],
    });
  }

  if (cardStatus === 'terminated') {
    return (
      <TerminatedDebitCard
        card={card}
        cardStatus={cardStatus}
        limit={cardData.limit}
        limitType={limitType}
      />
    );
  }

  return (
    <RightContentModal>
      <LoadingOverlay visible={isChargeAccountLoading} />
      <Box style={{ opacity: editCard ? 0.5 : 1 }}>
        <RightContentModal.Header
          subtitle={card.holder}
          title={cardData.cardName || null}
          rightSubtitle="Spent this month"
          rightTitle={formatCurrency(card.monthToDateSpends?.mtdSpend)}
        />
      </Box>

      <RightContentModal.Body>
        <Box style={{ opacity: editCard ? 0.5 : 1 }}>
          {cardData.limitType !== 'unlimited' && (
            <RightContentModal.ProgressBar
              progressValue={displayPercent}
              animate={true}
              leftText={formatCurrency((cardData.limit ?? 0) / 100)}
              rightText={cardData.limitType}
            />
          )}
          <DebitCard {...{ card, cardStatus }} />
        </Box>

        {editCard ? (
          <>
            <RightContentModal.Divider />
            <UpdateDebitOrChargeCard
              {...{
                setCardData,
                setEditCard,
              }}
              cardData={cardData}
              accountTier={getTier(depositAccounts, card.depositAccountId)}
              atmWithdrawalLimit={currentDailyWithdrawalLimit}
            />
          </>
        ) : (
          <>
            <Actions
              {...{
                card,
                setError,
                cardStatus,
                setEditCard,
                setCardStatus,
              }}
            />

            {card.address && (
              <>
                <RightContentModal.Divider />
                <RightContentModal.AddressSection
                  address={{
                    ...card.address,
                    line1: card.address.address ?? card.address.street,
                    line2: card.address.addressLine2 ?? card.address.street2,
                  }}
                  title="Billing address"
                />
              </>
            )}

            <RightContentModal.Divider />
            {footerData.map(({ title, value }) => (
              <Flex justify="space-between" key={title} mb="lg">
                <Text fz={rem(14)}>{title}</Text>
                {title === 'Allowed spending categories' ? (
                  <Flex
                    gap="xs"
                    align="center"
                    onClick={() => setEditCard(true)}
                  >
                    <Text fz={rem(14)} truncate maw={rem(150)}>
                      {value}
                    </Text>
                    {categoriesAllowed?.length > 1 && (
                      <Badge
                        bg="neutral.2"
                        sx={{
                          cursor: 'pointer',
                          ':hover': { textDecoration: 'underline' },
                        }}
                      >
                        +{categoriesAllowed.length - 1}
                      </Badge>
                    )}
                  </Flex>
                ) : (
                  <Text fz={rem(14)} ta="end">
                    {value}
                  </Text>
                )}
              </Flex>
            ))}
          </>
        )}

        {error && (
          <Alert
            color="red"
            mt={rem(20)}
            mx={useMobileView ? rem(20) : rem(50)}
            icon={<PiXCircle size="1.25rem" />}
          >
            Unable to update card status. Please try again.
          </Alert>
        )}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default DebitCardDetails;
