import { createStyles } from '@mantine/emotion';

export const accountDetailsStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.md,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[4]}`,
  },
}));
