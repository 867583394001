import { FlexbaseTable } from '@common/table';
import {
  SpendPlanAdminView,
  SpendPlanLimit,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';
import { Box, Button, Checkbox, Text } from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import { useGetUsers } from '@queries/use-users';
import { formatCurrency } from '@utilities/formatters';
import {
  AvatarTableCell,
  MemberDetailsTableCell,
} from 'areas/spend-plans/components/spend-plan-table-cells';
import { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { SpendPlanLimitsTableHeader } from './spend-plan-limits-table-header';
import { useState } from 'react';
import { noop } from 'underscore';
import { SpendPlanMemberFields } from 'areas/spend-plans/create/steps/team-members/team-members-step.context';
import { SpendPlanCurrencyInput } from 'areas/spend-plans/create/components/spend-plan-currency-input';
import { RequiredFieldValidator } from '@utilities/validators/validate-required';
import {
  deriveCurrentAssigned,
  deriveCurrentAssignedError,
  deriveFutureAssigned,
  deriveFutureAssignedError,
} from 'areas/spend-plans/utils/derive-member-limits';
import { useSpendPlanLimitsStyles } from './spend-plan-limits.styles';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import {
  useGetSpendPlanLimits,
  useUpdateSpendPlan,
} from '@queries/use-spend-plans';
import { usePlatformPersonContext } from 'providers/platform-person.context';
import { mapSpendPlanToFormState } from 'areas/spend-plans/utils/map-spend-plan-to-form-state';
import { toUpdateSpendPlanPayload } from 'areas/spend-plans/create/utils/map-form-to-payload';
import { SpendPlanFormState } from 'areas/spend-plans/create/create-spend-plan.wizard';
import { showNotification } from '@mantine/notifications';
import { useSpendPlanDetailsContext } from '../../spend-plan-details.context';

type ArrayType<T> = T extends (infer U)[] ? U : never;

type FormType = {
  members: SpendPlanMemberFields[];
};

type MemberType = ArrayType<FormType['members']>;
type MemberKeys = keyof MemberType;

type SpendPlanLimitTableData = Omit<SpendPlanLimit, 'personName'> &
  Pick<SpendPlanMemberFields, 'firstName' | 'lastName' | 'isManager'>;

type UseSpendPlanLimitsTableProps = {
  plan: SpendPlanAdminView | SpendPlanView;
  tableData: SpendPlanLimitTableData[];
  canEdit: boolean;
};

function toMemberFormFields(member: SpendPlanLimitTableData) {
  return {
    id: member.personId,
    firstName: member.firstName,
    lastName: member.lastName,
    email: member.personEmail,
    isManager: member.isManager,
    fullFundsAccess: member.fullFundAccess,
    currentAssigned: member.initialLimit || 0,
    repeatFundsAccess: member.repeatFundAccess,
    assigned: member.futureLimit || 0,
  };
}

function renderReadonlyInput(value: number, asInput: boolean) {
  return asInput ? (
    <SpendPlanCurrencyInput value={value} disabled />
  ) : (
    formatCurrency(value)
  );
}

function getMemberFormattedName(row: SpendPlanLimitTableData) {
  return `${row.firstName || ''} ${row.lastName || ''}`.trim();
}

const useSpendPlanLimitsTable = ({
  plan,
  tableData,
  canEdit,
}: UseSpendPlanLimitsTableProps): {
  columns: TableColumn<SpendPlanLimitTableData>[];
  form: UseFormReturnType<FormType>;
} => {
  const planLimit = plan.limit;
  const planFutureLimit = plan.limit;
  const isRecurringPlan = plan.frequency !== 'onetime';

  const form = useForm<FormType>({
    validateInputOnChange: true,
    initialValues: {
      members: tableData.map(toMemberFormFields),
    },
    validate: {
      members: {
        currentAssigned: (v, formValues, path) => {
          const idx = path.split('.')[1]; // 'members.0.currentAssigned'
          const member = formValues.members[parseInt(idx)];

          return v === 0
            ? null
            : RequiredFieldValidator()(v) ||
                deriveCurrentAssignedError({ member, planLimit })
              ? true
              : null;
        },
        assigned: (v, formValues, path) => {
          const idx = path.split('.')[1]; // 'members.0.assigned'
          const member = formValues.members[parseInt(idx)];
          return v === 0
            ? null
            : RequiredFieldValidator()(v) ||
                deriveFutureAssignedError({
                  member,
                  planLimit,
                  planFutureLimit,
                })
              ? true
              : null;
        },
      },
    },
  });

  function getMember(
    row: SpendPlanLimitTableData,
  ): SpendPlanMemberFields | undefined {
    const idx = form.values.members.findIndex((m) => m.id === row.personId);

    return form.values.members[idx];
  }

  function getMemberProps(
    row: SpendPlanLimitTableData,
    prop: MemberKeys,
    checkbox = false,
  ) {
    const idx = form.values.members.findIndex((m) => m.id === row.personId);
    return form.getInputProps(`members.${idx}.${prop}`, {
      type: checkbox ? 'checkbox' : 'input',
    });
  }

  const columns: TableColumn<SpendPlanLimitTableData>[] = [
    {
      id: 'defaultSort',
      selector: (row) => (row.isManager ? 0 : 1),
      sortable: true,
      omit: true,
    },
    {
      id: 'avatar',
      cell: (row) => (
        <AvatarTableCell
          name={getMemberFormattedName(row) || row.personEmail}
        />
      ),
      width: '64px',
      grow: 0,
      center: true,
      compact: true,
    },
    {
      id: 'memberInfo',
      name: 'Name',
      selector: (row) => getMemberFormattedName(row),
      cell: (row) => (
        <MemberDetailsTableCell
          name={getMemberFormattedName(row) || row.personEmail}
          email={row.personEmail}
          isManager={row.isManager}
        />
      ),
      compact: true,
      grow: 2,
      sortable: true,
    },
    {
      name: 'Spend',
      selector: (row) => row.spent || 0,
      format: (row) => formatCurrency(row.spent) || 0,
      sortable: true,
    },
    {
      name: 'Full funds access',
      selector: (row) => (getMember(row)?.fullFundsAccess ? 1 : 0),
      cell: (row) => (
        <Checkbox
          {...getMemberProps(row, 'fullFundsAccess', true)}
          disabled={!canEdit}
        />
      ),
      sortable: true,
    },
    {
      name: 'Assigned',
      selector: (row) => getMember(row)?.currentAssigned || 0,
      cell: (row) => {
        const member = getMember(row);
        const idx = form.values.members.findIndex((m) => m.id === row.personId);
        const derivedCurrentLimit = member
          ? deriveCurrentAssigned({
              member,
              planLimit,
            })
          : 0;

        return member?.fullFundsAccess || !canEdit ? (
          renderReadonlyInput(derivedCurrentLimit, canEdit)
        ) : (
          <SpendPlanCurrencyInput
            {...getMemberProps(row, `currentAssigned`)}
            onChange={noop}
            onValueChange={(v) =>
              form.setFieldValue(`members.${idx}.currentAssigned`, v.floatValue)
            }
          />
        );
      },
      sortable: true,
    },
    {
      name: 'Remaining',
      selector: (row) => row.remaining || 0,
      format: (row) => formatCurrency(row.remaining) || 0,
      sortable: true,
    },
  ];

  if (isRecurringPlan) {
    columns.push(
      {
        name: 'Repeat funds',
        selector: (row) => (getMember(row)?.repeatFundsAccess ? 1 : 0),
        cell: (row) => (
          <Checkbox
            {...getMemberProps(row, 'repeatFundsAccess', true)}
            disabled={!canEdit}
          />
        ),
        sortable: true,
      },
      {
        name: 'Future assigned',
        selector: (row) => getMember(row)?.assigned || 0,
        cell: (row) => {
          const member = getMember(row);
          const idx = form.values.members.findIndex(
            (m) => m.id === row.personId,
          );
          const derivedFutureLimit = member
            ? deriveFutureAssigned({
                member,
                planLimit,
              })
            : 0;

          return member?.repeatFundsAccess || !canEdit ? (
            renderReadonlyInput(derivedFutureLimit, canEdit)
          ) : (
            <SpendPlanCurrencyInput
              {...getMemberProps(row, 'assigned')}
              onChange={noop}
              onValueChange={(v) =>
                form.setFieldValue(`members.${idx}.assigned`, v.floatValue)
              }
            />
          );
        },
        sortable: true,
      },
    );
  }

  return {
    columns,
    form,
  };
};

type SpendPlanLimitsTableProps = {
  plan: SpendPlanAdminView | SpendPlanView;
  limits: SpendPlanLimit[];
};

const SpendPlanLimitsTable = ({ plan, limits }: SpendPlanLimitsTableProps) => {
  const { person } = usePlatformPersonContext();
  const { data: allUsers } = useGetUsers();
  const { isAdmin, isManager } = useSpendPlanDetailsContext();

  const { mutate: updateSpendPlan, isPending: isUpdatingPlan } =
    useUpdateSpendPlan();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const canEdit = (isAdmin || isManager) && !!plan.isPlanActive;

  const tableData = limits
    .filter((limit) => {
      if (!canEdit) {
        return limit.personId === person.id;
      }

      return limit;
    })
    .map<SpendPlanLimitTableData>(({ personName, ...limit }) => {
      const user = allUsers?.find((u) => u.id === limit.personId);
      const memberIsManager =
        'managers' in plan
          ? !!plan.managers.find((m) => m.personId === limit.personId)
          : false;

      if (!user) {
        return {
          ...limit,
          firstName: personName,
          lastName: '',
          isManager: memberIsManager,
        };
      }

      return {
        ...limit,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        isManager: memberIsManager,
      };
    })
    .filter((limit) => {
      if (!searchFilter) {
        return true;
      }

      const searchFilterLower = searchFilter.trim().toLowerCase();
      const nameMatch = getMemberFormattedName(limit)
        .toLowerCase()
        .includes(searchFilterLower);
      const emailMatch = (limit.personEmail || '')
        .toLowerCase()
        .includes(searchFilterLower);

      return nameMatch || emailMatch;
    });

  const { columns, form } = useSpendPlanLimitsTable({
    plan,
    tableData,
    canEdit,
  });

  const handleSaveChanges = form.onSubmit((formValues) => {
    const formState: SpendPlanFormState = {
      ...mapSpendPlanToFormState(plan as SpendPlanAdminView),
      ...formValues,
    };
    const payload = toUpdateSpendPlanPayload(formState);

    updateSpendPlan(
      {
        accountId: person.accountId,
        spendPlanId: plan.id,
        payload: payload,
      },
      {
        onSuccess: () => {
          form.resetDirty();
          showNotification({
            message: `Spend plan updated`,
            color: 'sage.4',
          });
        },
        onError: () => {
          showNotification({
            message: `Unable to update spend plan`,
            color: 'red',
          });
        },
      },
    );
  });

  const saveChangesButton = canEdit ? (
    <Button
      onClick={() => handleSaveChanges()}
      loading={isUpdatingPlan}
      disabled={!form.isDirty()}
      variant="primary-filled"
    >
      Save changes
    </Button>
  ) : undefined;

  return (
    <>
      <SpendPlanLimitsTableHeader
        searchValue={searchFilter}
        onSearchChange={setSearchFilter}
        saveChangesButton={saveChangesButton}
      />

      <FlexbaseTable
        data={tableData}
        columns={columns}
        keyField="personId"
        defaultSortFieldId="defaultSort"
        noDataComponent={
          <Text fz={24} fw={500} mt="lg">
            No team members found
          </Text>
        }
      />
    </>
  );
};

export const SpendPlanLimits = () => {
  const {
    person: { accountId },
  } = usePlatformPersonContext();
  const { classes } = useSpendPlanLimitsStyles();
  const { id: spendPlanId } = useParams();
  const { plan } = useSpendPlanDetailsContext();
  const { data: spendPlanLimits, isError: isLimitsError } =
    useGetSpendPlanLimits({ accountId, spendPlanId });

  if (isLimitsError) {
    return <Box>Something went wrong. Please try again later.</Box>;
  }

  return (
    <Box className={classes.container}>
      {spendPlanLimits && plan ? (
        <SpendPlanLimitsTable plan={plan} limits={spendPlanLimits} />
      ) : (
        <SkeletonLoading />
      )}
    </Box>
  );
};
