import { createStyles } from '@mantine/emotion';

type StylesProps = {
  platform?: 'iOS' | 'android' | '';
};

export const useStyles = createStyles((theme, { platform }: StylesProps) => ({
  avatar: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.primaryColor,
    borderRadius: 8,
    marginRight: 5,
  },
  leftContainer: {
    width: '174px',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'right',
    width: '174px',
  },
  escapeIcon: {
    color: '#979797',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    svg: {
      height: '18px',
      width: '18px',
    },
  },
  escapeIconSm: {
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    marginBottom: '15px',
    justifyContent: 'flex-end',
  },
  escape: {
    color: '#979797',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  fakeModal: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    overflowX: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 50,
    backgroundColor: theme.colors.neutral[3],
    alignItems: 'flex-start', // Otherwise the height for the content goes funky
  },
  innerContent: {
    backgroundColor: theme.colors.neutral[0],
    minHeight: 521,
    width: 420,
    marginTop: '2.5vh',
    padding: '36px 32px',
    display: 'flex',
    border: `1px solid ${theme.colors.neutral[2]}`,
    '@media(max-width: 767px)': {
      width: '100%',
      padding: '1rem',
    },
  },
  smallModal: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: platform === 'iOS' ? '90vh' : '100vh',
    minWidth: '100%',
    backgroundColor: '#FAFAFA',
    position: 'fixed',
    overflowX: 'auto',
    '@media(max-width: 767px)': {
      width: '100%',
      overflowX: 'hidden',
    },
  },
  smallContent: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.neutral[0],
    width: '100%',
    marginTop: 16,
    padding: 32,
    minHeight: 521,
  },
}));
