import { Box, Flex, Grid, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type Props = {
  summaryDetails: JSX.Element;
  chart: JSX.Element;
  summary: JSX.Element;
  summaryActions?: JSX.Element;
};

const DetailSummaryLayout = ({
  summaryDetails,
  chart,
  summary,
  summaryActions,
}: Props) => {
  const { classes } = layoutStyles();
  return (
    <Grid className={classes.container} justify="space-between">
      <Grid.Col span={{ xs: 12, md: 7, lg: 8.5 }} p={0}>
        <Box className={classes.containerItem}>
          {summaryDetails}
          <Flex align={'center'} mt="md">
            {summaryActions}
          </Flex>
        </Box>
        <Box className={classes.chart}>{chart}</Box>
      </Grid.Col>
      <Grid.Col
        span={{ xs: 12, md: 5, lg: 3.5 }}
        className={classes.summaryContainer}
      >
        {summary}
      </Grid.Col>
    </Grid>
  );
};

export default DetailSummaryLayout;

const layoutStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.md,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[4]}`,
    padding: theme.spacing.xs,
  },
  containerItem: {
    padding: theme.spacing.lg,
    paddingRight: 0,
  },
  summaryContainer: {
    padding: theme.spacing.lg,
  },
  chart: {
    height: rem(200),
    marginTop: theme.spacing.lg,
    marginLeft: rem(-5),
    padding: 0,
  },
}));
