import { Avatar, Box, Flex, Menu } from '@mantine/core';
import { useStyles } from './vendors.styles';
import { FlexbaseTable } from '@common/table';
import { getInitialsOfNames } from '@utilities/object';
import { useMediaQuery } from '@mantine/hooks';
import { TableColumn } from 'react-data-table-component';
import { formatCurrency } from '@utilities/formatters';
import { PiDotsThreeBold, PiEyeBold, PiTrash } from 'react-icons/pi';
import useModal from '@common/composites/modal/modal-hook';
import DeleteVendor from './delete-vendor/delete-vendor';
import { useNavigate } from 'react-router-dom';
import { useGetAllRecipients } from '@queries/use-recipients';
import { Recipient } from 'types/recipient';
import { useMemo } from 'react';
import { useBillpayVendorsFilters } from './use-billpay-vendor-filters';
import Header from '../header/header';
import EmptyBillTable from '../bills/empty-bill-table';

const Vendors = () => {
  const { classes, theme } = useStyles();
  const { data, isLoading, isSuccess } = useGetAllRecipients();
  const { activeFiltersArray } = useBillpayVendorsFilters();
  const { openCenterModalUnstyled, closeAllModals } = useModal();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const navigate = useNavigate();

  const handleRowClicked = (recipientId: string) => {
    navigate(`/recipient-details/${recipientId}/payments`, {
      state: location.pathname,
    });
  };

  const recipients: Recipient[] = useMemo(() => {
    if (!isSuccess) return [];

    return (data.recipients ?? [])
      .filter((cc) => cc.status === 'active')
      .filter((cc) => activeFiltersArray.every((filter) => filter.fn(cc)));
  }, [data, activeFiltersArray]);

  const handleDeleteRecipient = (row: Recipient) => {
    openCenterModalUnstyled(
      <DeleteVendor
        closeModal={closeAllModals}
        recipientId={row.id}
        recipientName={row.name}
      />,
      'md',
    );
  };

  const columns: TableColumn<Recipient>[] = [
    {
      name: 'Recipient',
      cell: (row) => (
        <Flex align="center">
          <Avatar radius="xl" mr={15} w={45} h={45}>
            {getInitialsOfNames(row.name ?? '')}
          </Avatar>
          {row.name}
        </Flex>
      ),
      sortable: true,
      selector: (row) => row.name,
      grow: useMobileView ? 2 : 1.5,
    },
    {
      name: 'Unpaid bills',
      selector: (row) => row.invoices.outstanding.count,
      sortable: true,
    },
    {
      name: 'Open balance',
      selector: (row) =>
        parseFloat(row.invoices.outstanding.balance.toString()),
      format: (row) => formatCurrency(row.invoices.outstanding.balance / 100),
      sortable: true,
    },
    {
      name: '',
      cell: (row) => (
        <Menu position="bottom-end">
          <Menu.Target>
            <Box w={'1.8rem'}>
              <PiDotsThreeBold className={classes.rowActionIcon} />
            </Box>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => handleRowClicked(row.id)}
              className={classes.viewRecipientOption}
              leftSection={<PiEyeBold size={'1.125rem'} />}
            >
              View recipient
            </Menu.Item>
            <Menu.Item
              className={classes.deleteRecipientOption}
              leftSection={<PiTrash size={'1.125rem'} />}
              onClick={() => handleDeleteRecipient(row)}
            >
              Delete recipient
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
      right: true,
    },
  ];

  return (
    <Box className={classes.card}>
      <Header
        placeholder="Search recipients"
        useFilters={useBillpayVendorsFilters}
      />
      <FlexbaseTable
        isFetchingData={isLoading}
        onRowClicked={(row) => handleRowClicked(row.id)}
        columns={columns}
        data={recipients}
        noDataComponent={
          <EmptyBillTable headingText="There are no recipients to display" />
        }
      />
    </Box>
  );
};

export default Vendors;
