import { Anchor, Flex, Group, rem, Stack, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PiCheckLight } from 'react-icons/pi';

const DENIAL_REQUIREMENTS = [
  'Have primary operations in the United States',
  'Have been generating revenue for more than 6 months',
  'Generated more than $250K in the past 12 months',
];

const QualificationFullRejection = () => {
  const { classes, theme } = useStyles();

  return (
    <Stack px={{ base: 'lg', sm: 0 }} align="stretch">
      {DENIAL_REQUIREMENTS.map((requirement, idx) => (
        <Group key={idx}>
          <Flex className={classes.checkContainerSmall}>
            <PiCheckLight size={16} />
          </Flex>
          <Text flex={1}>{requirement}</Text>
        </Group>
      ))}

      <Group
        bg="white"
        p="xl"
        bd={`1px solid ${theme.colors.neutral[4]}`}
        gap={rem(10)}
        mt="md"
      >
        <Text>Questions or concerns?</Text>
        <Anchor
          td="underline"
          href="https://support.flex.one/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact us
        </Anchor>
      </Group>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  checkContainerSmall: {
    background: theme.colors.primary[0],
    height: rem(24),
    width: rem(24),
    borderRadius: rem(100),
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.colors.primary[3]}`,
  },
}));

export default QualificationFullRejection;
