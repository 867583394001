import { Box, Flex, Text } from '@mantine/core';
import DenyApproveElement from './deny-approve/deny-approve-element';
import { Request } from '../../spend-plans.types';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { VscChromeClose } from 'react-icons/vsc';
import { useStyles } from '../styles';
import { useMemo } from 'react';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';

type Props = {
  requests?: Request[];
  closeModal: () => void;
  spendPlan: SpendPlanAdminView | SpendPlanView;
  accountId: string;
};

const RecentActivityModal = ({
  requests,
  spendPlan,
  accountId,
  closeModal,
}: Props) => {
  const { classes } = useStyles();

  const sortedData = useMemo(() => {
    return (
      requests?.sort((a, b) => {
        if (a.status === 'pending' && b.status !== 'pending') {
          return -1;
        }
        if (a.status !== 'pending' && b.status === 'pending') {
          return 1;
        }
        return 0;
      }) || []
    );
  }, [requests]);

  return (
    <RightContentModal>
      <Flex bg="primary.7" p="xl" justify="space-between" align="center">
        <Text fz="2.5rem" fw={400} lh="56px" c="neutral.0">
          Recent activity
        </Text>
        <button
          className={classes.closeButton}
          onClick={() => {
            closeModal();
          }}
        >
          <VscChromeClose size={34} color="#FFFF" />
        </button>
      </Flex>
      <RightContentModal.Body>
        <Box>
          <Box>
            {sortedData?.map((item) => (
              <DenyApproveElement
                request={item}
                accountId={accountId}
                spendPlanId={spendPlan.id}
                key={item.requestId}
              />
            ))}
          </Box>
        </Box>
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default RecentActivityModal;
