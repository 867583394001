import React, { ReactElement, useState } from 'react';
import { Badge, Box, Flex, Text } from '@mantine/core';
import { IconContext } from 'react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import useStyles from './navbar.styles';
import {
  getBottomNavbarOptions,
  MenuOption,
  SubMenuOption,
  useTopNavbarOptions,
} from './menu-options';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAdmin,
  OptedProduct,
  RolesSelector,
} from 'recoil-state/application/product-onboarding';
import { UserRole } from 'types/user-info';
import FlexIconLink from '@common/composites/flex-icon-link';
import CompaniesDropdown from './multi-account/companies-dropdown';
import { useBackgroundContext } from 'providers/background-provider';
import { useDisclosure } from '@mantine/hooks';

type Props = {
  children?: React.ReactNode;
};
/**
 *
 * @param userRoles the currently logged in persons roles
 * @param allowedRoles the allowed roles for the navtab
 * @returns
 */
const allowed = (userRoles: UserRole[], allowedRoles?: UserRole[]): boolean => {
  return (
    !allowedRoles?.length || userRoles.some((r) => allowedRoles.includes(r))
  );
};

const Navbar = ({ children }: Props): ReactElement => {
  const { classes, theme } = useStyles({});
  const { background } = useBackgroundContext();
  const [highlightedRoute, setHighlightedRoute] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useRecoilValue(IsAdmin);
  const { optedProducts, excludedProducts } = useRecoilValue(ApplicationState);
  const roles = useRecoilValue(RolesSelector);
  const [opened, { toggle, close }] = useDisclosure(false);

  const topOptions = useTopNavbarOptions();

  const path = location.pathname.split('/').filter((p) => p);

  const menuItemClass = (opt: MenuOption): string => {
    if (
      location.pathname === opt.location ||
      opt.location.includes(path[0]) ||
      highlightedRoute === opt.label ||
      opt.subRoutes?.some((s) => s.location.includes(path[0]))
    ) {
      if (opt.unclickable) {
        return classes.notClickableHighlightedMenuOption;
      }
      return classes.highlightedMenuOption;
    } else if (opt.disabled || (opt.adminOnly && !isAdmin)) {
      return classes.disabledMenuOption;
    }
    if (opt.unclickable) {
      return classes.notClickableMenuOption;
    }
    return classes.menuOption;
  };

  const subMenuItemClass = (opt: SubMenuOption): string => {
    const split = opt.location.split('/');
    const optionPath = split[split.length - 1];
    if (optionPath === path[0]) {
      return classes.highlightedMenuSuboption;
    } else if (opt.disabled) {
      return classes.disabledMenuOption;
    }
    return classes.menuSuboption;
  };

  const generateNavbarOptions = (options: MenuOption[]) => {
    /*
    set cross sellable products in this array -
    if the user hasn't opted into a product and the product is in this array, show it on nav bar as Apply Now.
    note: need to ensure that the cross sell UI is in place to support user applying for product
    */
    const crossSellProducts: OptedProduct[] = ['CREDIT', 'BANKING'];

    return options
      .filter((opt) => {
        // filter out navtabs that the user role does not have access to
        return allowed(roles, opt.rolesAllowed);
      })
      .filter((menuOpt) => {
        // Product restrictions
        // null value, no restrictions
        // never show if the product is in the company excludedProducts
        // show if product is in company optedProducts
        // show if product is in cross-sell and is not excluded
        if (menuOpt.product === null) {
          return true;
        }

        if (excludedProducts.includes(menuOpt.product)) {
          return false;
        }

        return (
          optedProducts.includes(menuOpt.product) ||
          crossSellProducts.includes(menuOpt.product)
        );
      })
      .map((opt: MenuOption) => {
        return (
          <div key={opt.label}>
            <div
              className={`${classes.menuOptionCommon} ${menuItemClass(opt)}`}
              onClick={() => {
                if (!opt.subRoutes) {
                  navigate(opt.location);
                  setHighlightedRoute('');
                }
              }}
              data-testid={opt.label.toLowerCase()}
              key={opt.label}
            >
              <IconContext.Provider value={{ size: '25px' }}>
                <div
                  className={classes.menuOptionIcon}
                  data-testid={`icon-${opt.label.toLowerCase()}`}
                >
                  {opt.icon}
                </div>
              </IconContext.Provider>
              <Text
                size="sm"
                sx={(t) => ({
                  fontFamily: 'PP Neue Montreal',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginLeft: '30px',
                  alignItems: 'center',
                  color: t.colors.neutral[0],
                  display: 'flex',
                })}
              >
                {opt.label}{' '}
                {['Insurance'].includes(opt.label) && (
                  <span className={classes.txtComingSoon}>Coming Soon</span>
                )}
                {opt.product !== null &&
                  !optedProducts.includes(opt.product) &&
                  crossSellProducts.includes(opt.product) && (
                    <span className={classes.txtComingSoon}>Apply Now</span>
                  )}
              </Text>
            </div>
            <Box className={classes.subroute} mt="xs">
              {opt.subRoutes?.map((route) => {
                return (
                  <Flex
                    px="xs"
                    mb="xs"
                    gap="xs"
                    align="center"
                    c="neutral.0"
                    key={route.key}
                    className={subMenuItemClass(route)}
                    onClick={() => {
                      setHighlightedRoute(opt.label);
                      navigate(`${route.location}`);
                    }}
                    data-testid={route.label.toLowerCase()}
                  >
                    <Text lh={2}>{route.label}</Text>
                    {['Bill pay'].includes(route.label) && (
                      <Badge
                        radius={4}
                        bg="primary.0"
                        sx={{
                          border: `1px solid ${theme.colors.primary[3]}`,
                        }}
                      >
                        Beta
                      </Badge>
                    )}
                  </Flex>
                );
              })}
            </Box>
          </div>
        );
      });
  };

  return (
    <div className={classes.fullScreen}>
      <div className={classes.navbar} onMouseLeave={() => close()}>
        <CompaniesDropdown {...{ opened, toggle }} />
        <div className={classes.navbarOptions}>
          {generateNavbarOptions(topOptions)}
        </div>
        <div>
          <div className={classes.navbarOptions}>
            {generateNavbarOptions(getBottomNavbarOptions())}
          </div>
          <div className={classes.logoSection}>
            <Box ml={8} mr={10}>
              <FlexIconLink className={classes.icon} />
            </Box>
            <div className={classes.nameSection}>
              <Text size="sm" className={classes.nameText}>
                Flexbase Technologies
              </Text>
            </div>
          </div>
        </div>
      </div>

      <Box id="main" className={classes.pageDiv} bg={background}>
        <div>{children}</div>
      </Box>
    </div>
  );
};

export default Navbar;
