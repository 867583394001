import { useState } from 'react';
import { Button, Menu, useMantineTheme } from '@mantine/core';
import { PiArrowSquareOutBold, PiQuestionBold } from 'react-icons/pi';

const HelpActionButtons = () => {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  return (
    <Menu
      withArrow
      offset={4}
      shadow="sm"
      opened={opened}
      position="bottom-end"
      arrowPosition="center"
      onChange={(open) => setOpened(open)}
    >
      <Menu.Target>
        <Button
          variant="subtle"
          size="sm"
          leftSection={<PiQuestionBold color={theme.colors.neutral[8]} />}
        >
          Help
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Resources</Menu.Label>
        <Menu.Item
          leftSection={<PiArrowSquareOutBold size={'0.875rem'} />}
          component="a"
          href="https://flexbase.zendesk.com/hc/en-us"
          target="_blank"
        >
          Support articles
        </Menu.Item>
        <Menu.Divider />

        <Menu.Label>Get in touch</Menu.Label>
        <Menu.Item
          component="a"
          href="https://flexbase.zendesk.com/hc/en-us/requests/new"
          target="_blank"
        >
          Share feedback
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default HelpActionButtons;
