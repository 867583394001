import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import TransactionDetailRow from 'areas/credit/components/credit-transactions/credit-transactions-sm/transaction-detail-row';
import TransactionYearRow from 'areas/credit/components/credit-transactions/credit-transactions-sm/transaction-year-row';
import TransactionsByYear from 'areas/credit/components/credit-transactions/credit-transactions-sm/transactions-by-year';
import { CreditTransactionsTableRow } from 'areas/credit/components/credit-transactions/credit-transactions-table-helpers';
import { Box, Modal, rem, Text } from '@mantine/core';
import { uniq } from 'underscore';
import {
  mapCreditTransactionToTableRow,
  useOpenCreditTransactionDetails,
} from 'areas/credit/components/credit-transactions/credit-transactions-columns';
import { TableColumn } from 'react-data-table-component';
import { FlexbaseTable } from '@common/table';
import { useSyncedTransactionsQuery } from '@queries/use-integrations';
import { mapTransactionStatusToBusinessStatus } from '@services/flexbase/flexbase-onboarding-client';
import { Expenses } from '@flexbase-eng/types/dist/accounting';
import {
  useActiveExpenseLink,
  useSyncExpenseAllowed,
} from '@utilities/integrations/accounting';
import { LineOfCredit } from '@services/flexbase/credit.model';
import { UseQueryResult } from '@tanstack/react-query';
import { CreditTransactionsOverviewModel } from '@queries/use-credit-transactions';

function getYears(transactions: CreditTransactionsTableRow[]) {
  return uniq(transactions.map((tx) => DateTime.fromSQL(tx.date).year));
}

function getCounts(transactions: CreditTransactionsTableRow[]) {
  return transactions.reduce<Record<number, number>>((acc, tx) => {
    const year = DateTime.fromSQL(tx.date).year;
    const prev = acc[year] || 0;

    acc[year] = prev + 1;

    return acc;
  }, {});
}

function getYearCounts(transactions: CreditTransactionsTableRow[]) {
  const uniqueYears = getYears(transactions);
  const totalCounts = getCounts(transactions);

  return uniqueYears.map((year) => ({
    year,
    totalCount: totalCounts[year],
  }));
}

/**
 * A component to display credit transaction data in a non-tabular form for mobile views.
 * @constructor
 */
const CreditTransactionsSm = ({
  dataQuery,
  lineOfCredit,
}: {
  dataQuery: UseQueryResult<CreditTransactionsOverviewModel>;
  lineOfCredit: LineOfCredit;
}) => {
  const { data, isLoading, refetch } = dataQuery;
  const visibleCount = 10;
  const onRowClicked = useOpenCreditTransactionDetails(refetch);
  const syncFeatureEnabled = useSyncExpenseAllowed();
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();

  const syncedTransactionsQueryIds = useMemo(
    () =>
      data?.transactions
        .filter((tx) => mapTransactionStatusToBusinessStatus(tx) === 'Settled')
        .map((tx) => tx.id) ?? [],
    [data],
  );

  const { data: syncedExpenses } = useSyncedTransactionsQuery({
    enabled: syncFeatureEnabled && !!expenseLink?.enabledExpenses,
    connectionId,
    transactionIds: syncedTransactionsQueryIds,
  });

  const transactions: CreditTransactionsTableRow[] = useMemo(() => {
    const syncedExpenseMap =
      syncedExpenses?.expenses.reduce<Partial<Record<string, Expenses>>>(
        (acc, expense) => {
          acc[expense.transactionId] = expense;
          return acc;
        },
        {},
      ) ?? {};

    return (
      data?.transactions.map((transaction, index) =>
        mapCreditTransactionToTableRow(
          transaction,
          index,
          {
            syncedExpense: syncedExpenseMap[transaction.id],
            expenseCategories: syncedExpenses?.accounts?.items,
          },
          lineOfCredit,
        ),
      ) ?? []
    );
  }, [data, syncedExpenses]);

  const yearCounts = useMemo(() => {
    return getYearCounts(transactions);
  }, [transactions]);

  const [modalYear, setModalYear] = useState(0);
  const yearModalOpened = modalYear > 0;

  const handleYearModalClose = () => {
    setModalYear(0);
  };

  const handleYearClick = (year: number) => {
    setModalYear(year);
  };

  const columns: TableColumn<CreditTransactionsTableRow>[] = [
    {
      compact: true,
      cell: (row) => (
        <TransactionDetailRow
          transaction={row}
          onClick={onRowClicked}
          showBottomBorder={false}
        />
      ),
    },
  ];

  return (
    <Box>
      <Text fz={rem(16)} fw={500} c="neutral.8">
        Latest transactions
      </Text>

      <FlexbaseTable
        data={transactions.filter((_, idx) => idx < visibleCount)}
        columns={columns}
        isFetchingData={isLoading}
        paginationComponent={() => null}
        noTableHead
        customStyles={{
          table: { style: { width: '100%' } },
        }}
      />

      {yearCounts.map(({ year, totalCount }) => (
        <Box key={year} mt="sm">
          <TransactionYearRow
            year={year}
            totalTransactions={totalCount}
            onClick={handleYearClick}
          />
        </Box>
      ))}

      <Modal
        opened={yearModalOpened}
        onClose={handleYearModalClose}
        fullScreen
        closeOnClickOutside
        styles={{
          header: {
            display: 'none',
          },
          body: {
            padding: 0,
          },
          inner: {
            padding: 0,
          },
        }}
      >
        <TransactionsByYear
          year={modalYear}
          transactions={transactions}
          onClose={handleYearModalClose}
          onRowClick={onRowClicked}
        />
      </Modal>
    </Box>
  );
};

export default CreditTransactionsSm;
