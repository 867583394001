import { Button } from '@mantine/core';
import { useFormStyles } from './form.styles';

type Props = {
  suppressOnSubmit?: boolean;
  cancelText?: string;
  submitText?: string;
  onCancel: () => void;
  onSubmit: () => void;
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
};

const FormBase = ({
  suppressOnSubmit,
  cancelText,
  submitText,
  onCancel,
  onSubmit,
  children,
  loading,
}: Props) => {
  const { classes } = useFormStyles();

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          onSubmit();
        }
      }}
    >
      <div>{children}</div>
      <div className={classes.buttonContainer}>
        <Button variant="neutral-outline" onClick={onCancel}>
          {cancelText || 'Cancel'}
        </Button>
        {suppressOnSubmit === true ? null : (
          <Button variant="primary-light" onClick={onSubmit} {...{ loading }}>
            {submitText || 'Update'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormBase;
