import { useEffect, useState } from 'react';
import { PiCheckCircleBold } from 'react-icons/pi';
import { Flex, Text, Divider, Badge, LoadingOverlay } from '@mantine/core';
import {
  formatCents,
  formatCurrency,
  formatDepositAccountDetails,
} from '@utilities/formatters';
// import {
//   PayInvoiceParams,
//   buildMakePaymentParams,
// } from '@utilities/make-payment';
// import { useMakePayment } from '@queries/use-payments';
import { DepositAccount } from '@services/flexbase/banking.model';
import BillPayCreditPaymentInfo from './bill-pay-credit-payment-info';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import BillPayCreditPaymentError from './bill-pay-credit-payment-error';
import BillPayCreditPaymentReview from './bill-pay-credit-payment-review';
import { DollarAmountTitle } from '@common/composites/dollar-amount-title';
import BillPayCreditPaymentSuccess from './bill-pay-credit-payment-success';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { ModifiedLineTransaction } from '../outstanding-table';

type MakeBillPayCreditPaymentProps = {
  transaction: ModifiedLineTransaction;
  onCloseModal: () => void;
};

type MakeBillPayCreditPaymentStep = 'info' | 'review' | 'success' | 'error';

const getMappedPaymentAccounts = (depositAccounts: DepositAccount[]) => {
  const mappedDepositAccounts = depositAccounts
    .sort((a, b) => b.available - a.available)
    .map((acc) => ({
      value: acc.id,
      availableBalance: acc.available ?? 0,
      label: formatDepositAccountDetails(depositAccounts, acc.id, true),
    }));

  return mappedDepositAccounts;
};

const MakeBillPayCreditPayment = ({
  transaction,
  onCloseModal,
}: MakeBillPayCreditPaymentProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState<MakeBillPayCreditPaymentStep>('info');
  const [paymentAccountId, setPaymentAccountId] = useState<string | undefined>(
    undefined,
  );
  const { amount } = transaction;

  const {
    data: depositAccountsData,
    isError: isDepositAccountsError,
    isLoading: isDepositAccountsLoading,
    refetch: refetchDepositAccounts,
  } = useGetDepositAccounts();
  const depositAccounts =
    depositAccountsData?.accounts.filter((acc) => acc.status === 'Open') ?? [];

  const mappedPaymentAccounts = getMappedPaymentAccounts(depositAccounts);
  const paymentAccount = formatDepositAccountDetails(
    depositAccounts,
    paymentAccountId,
  );

  const selectedAccountBalance =
    mappedPaymentAccounts.find((acc) => acc.value === paymentAccountId)
      ?.availableBalance ?? 0;
  const hasSufficientFunds = selectedAccountBalance >= amount;

  const isLoading = isDepositAccountsLoading;
  const isError = isDepositAccountsError;
  const feeAmount = transaction.fees[0]?.fundingFeePercentage
    ? formatCents(transaction.fees[0]?.fundingFeePercentage * amount)
    : 0;

  const proratedFee =
    Number(transaction.fees[0]?.proratedFundingFeePercentage?.toFixed(4)) ?? 0;

  const proratedFeeAmount = transaction.fees[0]?.proratedFundingFeePercentage
    ? formatCents(
        Math.round(transaction.fees[0]?.proratedFundingFeePercentage * amount),
      )
    : undefined;

  const formattedTotal = formatCurrency(
    formatCents(amount + (proratedFeeAmount ?? 0)),
  );

  // TODO: Delete this once we have the BE work for this

  // const { mutate: makePayment, isPending: isPendingMakePayment, isError: isErrorMakePayment } =
  //   useMakePayment();

  const handleChangeStep = (nextStep: MakeBillPayCreditPaymentStep) => {
    setStep(nextStep);
  };

  const handleMakePayment = () => {
    // Commented out for now as we don't have the data for this yet
    // const initialDomesticParams: PayInvoiceParams = {
    //   scheduledForDate: new Date(date),
    //   sourceAccountId: paymentAccountId!,
    //   invoiceMemo: 'Invoice memo',
    //   notes: 'Notes',
    //   recipientPaymentMethod: 'ACH',
    //   amount: amount.toString(),
    //   recipientId,
    //   billpayInvoicesId: id,
    // };

    // const { domesticPaymentParams } = buildMakePaymentParams(
    //   initialDomesticParams,
    // );
    // makePayment(domesticPaymentParams, {
    //   onSuccess: () => {
    //     handleChangeStep('success');
    //   },
    //   onError: (error) => {
    //     setErrorMessage('There was an error in paying this bill.');
    //     handleChangeStep('error');
    //   },
    // });
    handleChangeStep('success');
  };

  const handleTryAgainOnError = () => {
    if (isError) {
      refetchDepositAccounts();
    }
    handleChangeStep('info');
  };

  const getStepContent = () => {
    switch (step) {
      case 'info':
        return (
          <BillPayCreditPaymentInfo
            transaction={transaction}
            formattedTotal={formattedTotal}
            paymentAccountId={paymentAccountId}
            hasSufficientFunds={hasSufficientFunds}
            paymentAccounts={mappedPaymentAccounts}
            onPaymentAccountChange={setPaymentAccountId}
            onNextStep={() => handleChangeStep('review')}
          />
        );
      case 'review':
        return (
          <BillPayCreditPaymentReview
            paymentAccount={paymentAccount}
            proratedFee={proratedFee}
            paymentAmount={transaction.amount}
            feePercentage={transaction.fees[0]?.fundingFeePercentage ?? 0}
            formattedTotal={formattedTotal}
            proratedAmount={transaction.fees[0]?.proratedAmount ?? 0}
            feeAmount={feeAmount}
            isMakingPayment={false}
            onNextStep={handleMakePayment}
            onPreviousStep={() => handleChangeStep('info')}
          />
        );
      case 'success':
        return (
          <BillPayCreditPaymentSuccess
            onClose={onCloseModal}
            paymentAccount={paymentAccount}
            formattedTotal={formattedTotal}
          />
        );

      case 'error':
        return (
          <BillPayCreditPaymentError
            errorMessage={errorMessage}
            onBackToPayments={onCloseModal}
            onTryAgain={handleTryAgainOnError}
          />
        );
    }
  };

  useEffect(() => {
    if (isError) {
      setErrorMessage('There was an error getting your payment information');
      handleChangeStep('error');
    }
  }, [isError]);

  return (
    <RightContentModal>
      {isLoading && <LoadingOverlay visible />}
      <RightContentModal.Header
        title={
          <Flex align="center" gap="xs">
            <DollarAmountTitle size="sm" dollarAmountString={formattedTotal} />
            {step === 'success' && <Badge variant="primary-flat">Paid</Badge>}
          </Flex>
        }
      />
      <RightContentModal.Body>
        {step !== 'error' && (
          <>
            <StepIndicator currentStep={step} />
            <Divider color="neutral.4" my="xl" />
          </>
        )}
        {getStepContent()}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default MakeBillPayCreditPayment;

const StepIndicator = ({
  currentStep,
}: {
  currentStep: MakeBillPayCreditPaymentStep;
}) => (
  <Flex gap="xxs" align="center">
    {currentStep !== 'info' && <PiCheckCircleBold color="primary.6" />}
    <Text c={currentStep === 'info' ? 'neutral.10' : 'primary.6'}>
      Payment information
    </Text>
    <Text c="neutral.7">—</Text>
    <Text c={currentStep === 'info' ? 'neutral.7' : 'neutral.10'}>Review</Text>
  </Flex>
);
