import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { Text, Box, Grid, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { AccountDetailCell } from 'areas/payments/components/account-information/account-detail-cell';
import { useOutletContext } from 'react-router-dom';
import type { ParsedAccount } from 'types/parsed-account';
import { Recipient } from 'types/recipient';

type AccountInfoContext = {
  domesticAccounts: ParsedAccount[];
  recipient: Recipient;
};

const RecipientAccountInformation = () => {
  const { domesticAccounts } = useOutletContext<AccountInfoContext>();

  const { classes } = domesticAccountsStyles();

  if (!domesticAccounts) {
    return <SkeletonLoading />;
  }

  if (domesticAccounts.length === 0) {
    return (
      <Text size="lg" fw={500} ta="center" mt={rem(40)} c="neutral.8">
        No accounts have been added.
      </Text>
    );
  }

  return (
    <Box className={classes.container}>
      <Grid gutter={rem(70)} align="stretch" justify="flex-start">
        {domesticAccounts.map((account) => (
          <Grid.Col
            key={account.id}
            span={{ base: 12, sm: 6, lg: 4 }}
            className={classes.column}
          >
            <Box>
              <Text c="primary.8" size={rem(20)} fw={400} mb={rem(24)}>
                {account.nickName || 'No nickname'} ••
                {account.accountNumber?.slice(-4) || ''}
              </Text>
              <Box className={classes.row}>
                <Box className={classes.cell}>
                  <Text c="neutral.8" size="sm">
                    Type
                  </Text>
                  <Text c="primary.8" size="sm" fw={500}>
                    {account.type || ''}
                  </Text>
                </Box>
                <Box className={classes.cell}>
                  <Text c="neutral.8" size="sm">
                    Account Number
                  </Text>
                  <Text c="primary.8" size="sm" fw={500}>
                    <AccountDetailCell value={account.accountNumber || ''} />
                  </Text>
                </Box>
              </Box>
              <Box className={classes.row}>
                <Box className={classes.cell}>
                  <Text c="neutral.8" size="sm">
                    Account Type
                  </Text>
                  <Text c="primary.8" size="sm" fw={500}>
                    {account.accountType || 'N/A'}
                  </Text>
                </Box>
                <Box className={classes.cell}>
                  <Text c="neutral.8" size="sm">
                    Routing Number
                  </Text>
                  <Text c="primary.8" size="sm" fw={500}>
                    {account.routingNumber || ''}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Grid.Col>
        ))}
      </Grid>
    </Box>
  );
};

export default RecipientAccountInformation;

export const domesticAccountsStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.md,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[4]}`,
  },
  column: {
    maxWidth: rem(360),
    paddingBottom: 0,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: rem(16),
    marginBottom: rem(24),
  },
  cell: {
    width: rem(160),
  },
}));
