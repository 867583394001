import { MultiSelect } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import {
  useChargeCardTransactions,
  useNet60CreditTransactions,
  useNet60MarqetaTransactions,
} from '@queries/use-credit-transactions';
import { uniq } from 'underscore';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import {
  useCreditTransactionModalFilters,
  useMarqetaTransactionsModalFilters,
} from '../use-credit-transaction-filters';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';

type CreditTransactionsNameFilterProps = {
  options: string[];
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditNameFilter = ({
  options,
  filterHook,
}: CreditTransactionsNameFilterProps) => {
  const { addFilter, removeFilter, getFilterByKey } = filterHook;
  const uniqueOptions = uniq(options);

  return (
    <MultiSelect
      data={uniqueOptions}
      placeholder="Name"
      searchable
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(values) => {
        if (values.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: values,
            fn: (row) => {
              return values.includes(row.name);
            },
            label: `Name: ${values.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};

export const CreditTransactionsNameFilter = () => {
  const filterHook = useCreditTransactionModalFilters();
  const { accountId } = useRecoilValue(ApplicationState);
  const { data: allTx } = useNet60CreditTransactions(accountId);
  const options = allTx?.transactions.map((tx) => tx.who).filter(Boolean) ?? [];

  return <CreditNameFilter options={options} filterHook={filterHook} />;
};

export const ChargeCardTransactionsNameFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();
  const { accountId } = useRecoilValue(ApplicationState);
  const { data: allTx } = useChargeCardTransactions(accountId);
  const options = allTx?.transactions.map((tx) => tx.who).filter(Boolean) ?? [];

  return <CreditNameFilter options={options} filterHook={filterHook} />;
};

export const MarqetaTransactionsNameFilter = () => {
  const filterHook = useMarqetaTransactionsModalFilters();
  const { accountId } = useRecoilValue(ApplicationState);
  const { data: allTx } = useNet60MarqetaTransactions(accountId);
  const options = allTx?.transactions.map((tx) => tx.who).filter(Boolean) ?? [];

  return <CreditNameFilter options={options} filterHook={filterHook} />;
};
