import {
  LoginFormState,
  useLoginFormContext,
} from 'providers/login-form.context';
import {
  ComponentStep,
  MultiStepFormContextReturnType,
} from './multi-step-form-provider';
import {
  IsSuccessResponse,
  PlatformResponse,
} from 'services/platform/models/authorize.models';
import { platformAuthClient } from 'services/platform/platform-auth-client';
import { AuthenticationFactor } from '@flexbase-eng/types/dist/identity';
import { storeToken } from 'utilities/auth/store-token';
import { createContext, ReactNode, useContext } from 'react';
import { useHandleLoginSuccess } from 'areas/login/use-handle-login-success';
import { useSetRecoilState } from 'recoil';
import { integrationModalState } from 'recoil-state/integration-modal-state';
import { platformSdk } from 'services/platform-sdk';
import { APIError } from '@flexbase-eng/sdk-typescript/models/errors';
import {
  KEY_REMEMBER_USER,
  KEY_USER_EMAIL_STORAGE,
} from 'providers/auth.provider';

type UseLoginContextReturnType = MultiStepFormContextReturnType<
  LoginFormState,
  ComponentStep<LoginFormState>
> & {
  loginWithPassword: (
    email: string,
    password: string,
    rememberUser: boolean,
  ) => Promise<void>;
  verifyCode: (code: string) => Promise<void>;
  setSelectedFactor: (factor: AuthenticationFactor) => void;
  resendSmsCode: () => Promise<void>;
  loginOauth: (email: string, password: string) => Promise<void>;
  verifyCodeOauth: (code: string) => Promise<void>;
};

const LoginContext = createContext<UseLoginContextReturnType | null>(null);

export const LoginContextProvider = ({ children }: { children: ReactNode }) => {
  const loginFormContext = useLoginFormContext();
  const { handleLoginSuccess, handleLoginSuccessOauth } =
    useHandleLoginSuccess();
  const setIntegrationModalState = useSetRecoilState(integrationModalState);

  const loginRobAuth = async (
    email: string,
    password: string,
    rememberUser: boolean,
  ) => {
    loginFormContext.resetState(); // Reset all state in-case they backed out to here and change their login. Don't want to retain factors from another login.
    loginFormContext.setState({
      loading: true,
      error: null,
      email,
      password,
      rememberUser,
    });

    try {
      const authenticationToken = await platformSdk.authorize.authorizeToken({
        grantType: 'password',
        username: email,
        password,
      });
      if (rememberUser) {
        localStorage?.setItem(KEY_REMEMBER_USER, 'true');
        localStorage?.setItem(KEY_USER_EMAIL_STORAGE, email);
      } else {
        // Just in case this somehow does not get cleared (IE, if token / session expires and they get logged out automatically)
        localStorage?.setItem(KEY_REMEMBER_USER, 'false');
        localStorage?.removeItem(KEY_USER_EMAIL_STORAGE);
      }
      storeToken({
        access_token: authenticationToken.accessToken,
        expires_in: authenticationToken.expiresIn,
        scope: authenticationToken.scope,
        refresh_token: authenticationToken.refreshToken,
        token_type: authenticationToken.tokenType,
      });

      const factors = await handlePlatformApiCall(
        platformAuthClient.getFactors(),
        'An error occurred while retrieving your list of authentication factors.',
      );

      const availableFactors = factors.possession.filter(
        (f) =>
          f.verified && (f.method === 'sms' || f.method === 'authenticator'),
      );

      const pkce = await platformAuthClient.generatePKCE();
      loginFormContext.setState({
        pkce,
        factorList: availableFactors.length > 1 ? availableFactors : [],
      });

      if (availableFactors.length === 0) {
        await handleLoginSuccess(
          {
            access_token: authenticationToken.accessToken,
            expires_in: authenticationToken.expiresIn,
            scope: authenticationToken.scope,
            refresh_token: authenticationToken.refreshToken,
            token_type: authenticationToken.tokenType,
          },
          {
            factors: availableFactors,
          },
        );
      } else if (availableFactors.length > 1) {
        loginFormContext.goToStep('choose-factor', {
          loading: false,
          factorList: availableFactors,
        });
      } else {
        const onlyFactor = availableFactors[0];
        await handlePlatformApiCall(
          platformAuthClient.issueChallenge({
            type: 'otp',
            methodId: onlyFactor.methodId!,
            codeChallenge: pkce.codeChallenge,
          }),
          `An error occurred while trying to issue an SMS code to ${onlyFactor.value}`,
        );
        loginFormContext.goToStep('2FA', {
          loading: false,
          selectedFactor: onlyFactor,
        });
      }
    } catch (e) {
      // If we already have an error from one of the handlePlatformApiCall calls, don't try to set it again.
      if (loginFormContext.state.error) {
        loginFormContext.setState({ loading: false });
      } else {
        const errorMessage =
          e instanceof APIError &&
          e.rawResponse.headers
            .get('www-authenticate')
            ?.includes('invalid_credentials')
            ? 'You entered invalid credentials. If you forgot your password, click the "Forgot Password" link above.'
            : 'Incorrect credentials.';
        loginFormContext.setState({
          loading: false,
          error: errorMessage,
        });
      }
    }
  };

  const loginWithPassword = async (
    email: string,
    password: string,
    rememberUser: boolean,
  ) => {
    // Clear any previous token
    localStorage?.removeItem('fb_full_token');
    await loginRobAuth(email, password, rememberUser);
  };

  const verifyCodePlatform = async (code: string) => {
    loginFormContext.setState({ loading: true, error: null });

    const pkce = loginFormContext.state.pkce;
    const selectedFactor = loginFormContext.state.selectedFactor;

    if (pkce && selectedFactor) {
      const tokenResponse = await platformAuthClient.requestTokenByCode({
        code,
        grantType: selectedFactor?.method === 'sms' ? 'otp' : 'totp',
        methodId: selectedFactor.methodId!,
        codeVerifier: pkce.codeVerifier,
      });

      if (IsSuccessResponse(tokenResponse) && tokenResponse.body) {
        await handleLoginSuccess(tokenResponse.body, {
          onError: () =>
            loginFormContext.setState({
              error:
                'Successfully verified code but an error occurred when retrieving user data. Please refresh and try again',
              loading: false,
            }),
        });
      } else {
        loginFormContext.setState({
          error: 'Unable to verify code',
          loading: false,
        });
      }
    } else {
      loginFormContext.setState({
        error: 'Unable to verify code',
        loading: false,
      });
    }
  };

  const verifyCode = async (code: string) => {
    await verifyCodePlatform(code);
  };

  const resendSmsCode = async () => {
    loginFormContext.setState({ loading: true });

    const factor = loginFormContext.state.selectedFactor;
    const pkce = loginFormContext.state.pkce;

    if (factor && pkce) {
      const challengeResponse = await platformAuthClient.issueChallenge({
        type: 'otp',
        codeChallenge: pkce.codeChallenge,
        methodId: factor.methodId!,
      });
      if (IsSuccessResponse(challengeResponse)) {
        loginFormContext.setState({
          loading: false,
          otpStatus: 're-sent',
        });
      } else {
        loginFormContext.setState({
          loading: false,
          error: `Unable to issue a challenge to ${factor.value}`,
        });
      }
    } else {
      loginFormContext.setState({
        loading: false,
        error: 'An unexpected error occurred. Please login again and retry.',
      });
    }
  };

  const setSelectedFactor = async (factor: AuthenticationFactor) => {
    loginFormContext.setState({
      selectedFactor: factor,
      loading: true,
      error: null,
    });

    const pkce =
      loginFormContext.state.pkce ?? (await platformAuthClient.generatePKCE());

    // If the factor is SMS, generate a challenge.
    if (factor.method === 'sms') {
      const challengeResponse = await platformAuthClient.issueChallenge({
        type: 'otp',
        codeChallenge: pkce.codeChallenge,
        methodId: factor.methodId ?? '', // This won't be undef. Thanks types pack
      });

      if (!IsSuccessResponse(challengeResponse)) {
        loginFormContext.setState({
          loading: false,
          error: 'Unable to issue a challenge to your selected factor',
        });
      } else {
        loginFormContext.goToStep('2FA', {
          loading: false,
        });
      }
    } else {
      loginFormContext.goToStep('2FA', { loading: false });
    }
  };

  type ErrorMessageHandler<T> = (
    response: PlatformResponse<T>,
  ) => string | JSX.Element;

  async function handlePlatformApiCall<T>(
    call: Promise<PlatformResponse<T>>,
    errorMessage: string | JSX.Element | ErrorMessageHandler<T>,
  ): Promise<T> {
    const result = await call;

    if (!result.rawResponse.ok) {
      loginFormContext.setState({
        loading: false,
        error:
          typeof errorMessage === 'function'
            ? errorMessage(result)
            : errorMessage,
      });
      throw result.error;
    }

    return result.body!;
  }

  const loginOauth = async (email: string, password: string) => {
    localStorage?.removeItem('fb_full_token');

    loginFormContext.resetState(); // Reset all state in-case they backed out to here and change their login. Don't want to retain factors from another login.
    loginFormContext.setState({
      loading: true,
      error: null,
      email,
      password,
      rememberUser: false,
    });

    try {
      const authenticationToken = await handlePlatformApiCall(
        platformAuthClient.requestTokenByPassword(email, password),
        (r) =>
          r.wwwAuthenticate?.error === 'invalid_credentials' ? (
            <>
              You entered invalid credentials. If you forgot your password,{' '}
              <a
                href="https://home.flex.one/forgot-password"
                target="_blank"
                rel="noopener noreferrer"
              >
                reset your password here
              </a>
              .
            </>
          ) : (
            'An unknown error occurred.'
          ),
      );

      storeToken(authenticationToken);

      const factors = await handlePlatformApiCall(
        platformAuthClient.getFactors(),
        'An error occurred while retrieving your list of authentication factors.',
      );

      const availableFactors = factors.possession.filter(
        (f) =>
          f.verified && (f.method === 'sms' || f.method === 'authenticator'),
      );

      const pkce = await platformAuthClient.generatePKCE();
      loginFormContext.setState({
        pkce,
        factorList: availableFactors.length > 1 ? availableFactors : [],
      });

      // According to our design we only use SMS as 2FA for Oauth
      if (availableFactors.length === 0) {
        await handleLoginSuccessOauth(authenticationToken, {
          factors: availableFactors,
        });
      } else {
        const smsFactor = availableFactors.find(
          (factor) => factor.method === 'sms',
        );
        if (smsFactor) {
          await handlePlatformApiCall(
            platformAuthClient.issueChallenge({
              type: 'otp',
              methodId: smsFactor.methodId!,
              codeChallenge: pkce.codeChallenge,
            }),
            `An error occurred while trying to issue an SMS code to ${smsFactor.value}`,
          );
          loginFormContext.goToStep('verify-code', {
            loading: false,
            selectedFactor: smsFactor,
          });
        } else {
          loginFormContext.setState({
            error:
              'An error occurred while trying to issue an SMS code. Please contact support.',
            loading: false,
          });
        }
      }
    } catch {
      console.error('Login error');
    }
  };

  const verifyCodeOauth = async (code: string) => {
    loginFormContext.setState({ loading: true, error: null });

    const pkce = loginFormContext.state.pkce;
    const selectedFactor = loginFormContext.state.selectedFactor;

    if (pkce && selectedFactor) {
      const tokenResponse = await platformAuthClient.requestTokenByCode({
        code,
        grantType: selectedFactor?.method === 'sms' ? 'otp' : 'totp',
        methodId: selectedFactor.methodId!,
        codeVerifier: pkce.codeVerifier,
      });

      if (IsSuccessResponse(tokenResponse) && tokenResponse.body) {
        setIntegrationModalState((prev) => ({
          ...prev,
          logedByflow: true,
        }));
        await handleLoginSuccessOauth(tokenResponse.body);
      } else {
        loginFormContext.setState({
          error: 'Unable to verify code',
          loading: false,
        });
      }
    } else {
      loginFormContext.setState({
        error: 'Unable to verify code',
        loading: false,
      });
    }
  };

  return (
    <LoginContext.Provider
      value={{
        ...loginFormContext,
        loginWithPassword,
        verifyCode,
        setSelectedFactor,
        resendSmsCode,
        loginOauth,
        verifyCodeOauth,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  const context = useContext(LoginContext);

  if (context === null) {
    throw new Error(
      'useLoginContext must be used within a LoginContextProvider',
    );
  }

  return context;
};
