import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStartScreenStyles = createStyles((theme, _, helpers) => ({
  submitButton: {
    backgroundColor: theme.colors.primary[2],
    color: theme.colors.neutral[0],
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 16,
    marginTop: 15,
    '@media(max-width: 767px)': {
      fontSize: 14,
    },
    '&:not([data-disabled])': {
      '&:hover': {
        backgroundColor: theme.colors.primary[1],
      },
    },
  },
  container: {
    padding: '0px 0px 0px 70px',
    display: 'grid',
    gridTemplateColumns: '575px 1fr',
    alignItems: 'center',
    gap: '3px',
    '@media(max-width: 2000px)': {
      gap: '61px',
    },
    '@media(max-width: 767px)': {
      display: 'flex',
      justifyContent: 'center',
      padding: '40px 35px 40px 35px',
      width: '100%',
    },
  },
  greenContainer: {
    width: 187,
    marginTop: 8,
    padding: '4px',
    backgroundColor: theme.colors.critical[0],
    borderRadius: 100,
    '@media(max-width: 767px)': {
      width: 90,
      padding: '4px',
    },
  },
  greenContText: {
    fontSize: 14,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 7,
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    overflow: 'hidden',
    '@media(max-width: 767px)': {
      display: 'none',
    },
  },
  imageContainerBorder: {
    borderRadius: '20px 0 0 20px',
    border: '1px solid #e4e4e4',
    marginBottom: '80px',
  },
  input: {
    borderRadius: theme.defaultRadius,
    background: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
    fontSize: rem(14),
    fontWeight: 500,
    '&:focus-within': { borderColor: theme.colors.neutral[0] },
    color: theme.colors.primary[8],
    lineHeight: '20px',
    [helpers.smallerThan('sm')]: {
      fontSize: '1rem',
    },
  },
  label: {
    color: `${theme.colors.neutral[0]} !important`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
    a: {
      color: `${theme.colors.primary[2]} !important`, // The terms as provided by the API style with inline styles, and we can't be more specific than that without this important
    },
  },
  visibilityToggle: {
    color: '#5F5F5F',
  },
  disclosure: {
    color: '#5F5F5F',
    fontSize: '12px',
    textAlign: 'center',
  },
  innerInput: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  title: {
    fontSize: 48,
    fontFamily: 'Redaction',
    marginTop: 49,
    '@media(max-width: 767px)': {
      fontSize: 24,
      marginTop: 25,
    },
  },
  subtitle: {
    fontSize: 24,
    '@media(max-width: 767px)': {
      fontSize: 12,
    },
  },
  txtCreditScore: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    alignItems: 'center',
    '@media(max-width: 767px)': {
      fontSize: 14,
      marginTop: '1rem',
    },
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px 70px 0px 70px',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      padding: '  40px 12px 0px 12px',
    },
  },
  txtAlreadyAccount: {
    display: 'flex',
    '@media(max-width: 767px)': {
      fontSize: 13,
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  screenContainer: {
    backgroundColor: theme.colors.primary[8],
    color: theme.colors.neutral[0],
  },
  checkboxBody: { alignItems: 'center' },
  nameGroup: { alignItems: 'flex-start' }, // Used because an error on one input in the group causes misalignment
  nameInputs: { flex: 1 },
  image: {
    [helpers.largerThan(767)]: {
      width: '30rem',
    },
    [helpers.largerThan(1000)]: {
      width: '36rem',
    },
    [helpers.largerThan(1280)]: {
      width: '42rem',
    },
    [helpers.largerThan(1920)]: {
      width: rem(715),
    },
    objectFit: 'contain',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.neutral[0],
    width: '100%',
    padding: '2rem',
    [helpers.smallerThan(1281)]: {
      padding: `${theme.spacing.sm} ${theme.spacing.sm} 0 ${theme.spacing.sm}`,
    },
    [helpers.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [helpers.smallerThan(1281)]: {
      alignItems: 'center',
    },
  },
  gridLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxWidth: 1440,
  },
  contentContainer: {
    [helpers.largerThan(767)]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  },
  creditBankingTitle: {
    fontFamily: 'Redaction',
    fontSize: rem(48),
    fontWeight: 400,
    lineHeight: 'normal',
    [helpers.smallerThan(1281)]: {
      fontSize: rem(32),
    },
  },
  creditBankingSubtitle: {
    fontSize: rem(22),
    [helpers.smallerThan(1281)]: {
      fontSize: rem(16),
    },
    lineHeight: 'normal',
  },
  creditBankingSubtitleEmphasis: {
    fontWeight: 700,
  },
  darkBackgroundInputError: {
    border: `10px solid ${theme.colors.critical[3]}`,
  },
  creditBankingFormContainer: {},
  alert: {
    gridColumn: '1/3',
    width: '100%',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    padding: theme.spacing.sm,
    color: theme.colors.neutral[10],
    borderRadius: theme.defaultRadius,
    background: theme.colors.critical[0],
    border: `1px solid ${theme.colors.critical[2]}`,
  },
  footerDisclosure: {
    color: theme.colors.neutral[0],
  },
  footerContainer: {
    backgroundColor: theme.colors.primary[8],
    color: theme.colors.neutral[0],
    borderTop: `1px solid rgba(255, 255, 255, 0.20)`, // Color on the Figma, not a variable
  },
  noCreditImpactChip: {
    color: theme.colors.neutral[8],
    background: theme.colors.warning[1],
    width: 'fit-content',
    borderRadius: theme.defaultRadius,
    fontVariantNumeric: 'lining-nums tabular-nums',
  },
  gridInputContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '2rem',
    rowGap: '1.25rem',
    [helpers.smallerThan('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
