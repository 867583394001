import { Box, Grid, Text } from '@mantine/core';
import { DollarAmountTitle } from '@common/composites/dollar-amount-title';
import { PropsWithChildren } from 'react';
import { formatCurrency } from '@utilities/formatters';

type Props = {
  headlineAmount: string;
  subtitle: string;
} & PropsWithChildren;

const SummaryDetailLayout = ({ headlineAmount, subtitle, children }: Props) => {
  return (
    <Box>
      <Text size="sm" c="neutral.8">
        {subtitle}
      </Text>
      <Grid justify="space-between" align="center" mr="md">
        <Grid.Col span={{ xs: 12, sm: 7, lg: 8, xl: 9 }}>
          <DollarAmountTitle
            dollarAmountString={formatCurrency(headlineAmount)}
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 5, lg: 4, xl: 3 }}>{children}</Grid.Col>
      </Grid>
    </Box>
  );
};

export default SummaryDetailLayout;
