import { Alert, Checkbox, Stack } from '@mantine/core';
import { useMemo, useState } from 'react';
import {
  formatIntlPhoneForPlatform,
  formatPhoneForApi,
} from '@utilities/formatters';
import { RedAlertIcon } from 'assets/svg';
import QualificationPageShell from './components/qualification-page-shell';
import { Country } from 'react-phone-number-input';
import { useAddFactorsContext } from 'providers/add-factors.context';
import { VerificationCodeInput } from '@common/verify-code';
import { FlexIntlPhoneInput } from '@common/composites/input/flexbase-intl-phone-input';
import { validateIntlPhoneNumber } from '@utilities/validators/validate-phone-number';
import { IsFlexHttpError } from '@services/platform/models/authorize.models';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import {
  isPhoneNotSupportedError,
  PHONE_NOT_SUPPORTED_ERROR_COPY,
} from 'constants/index';
import { Analytics } from '@services/analytics/analytics';
import { QualificationApplicationEvents } from '@services/analytics/events';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';
import { useGetQualificationApplication } from './use-qualification-application';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useInvalidateMeQuery } from '@queries/use-get-me';

const QualificationVerifyPhonePage = () => {
  const navigate = useMarketingNavigate();
  const { user, accountId, personId } = useRecoilValue(ApplicationState);
  const { registerFactor, resendOtpSmsVerification, verifyFactor, savePhone } =
    useAddFactorsContext();
  const { data: application } = useGetQualificationApplication();
  const { invalidate: invalidatePlatformMeQuery } = useInvalidateMeQuery();
  const [methodId, setMethodId] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hasInputError, setHasInputError] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone || '');
  const [phoneCountryCode, setPhoneCountryCode] = useState<Country>('US');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const titleText = useMemo(() => {
    if (showVerify) {
      return 'Enter verification code';
    }
    return 'We need to verify your phone number';
  }, []);

  const handleSetPhoneNumber = (formattedValue: string) => {
    if (
      hasInputError &&
      validateIntlPhoneNumber(formattedValue, phoneCountryCode)
    ) {
      setHasInputError(false);
      setErrorMessage(null);
    }

    setPhoneNumber(formattedValue);
  };

  // After we verify the user's phone, we will save it to the API and Platform.dd
  const onCodeVerified = async () => {
    await savePhone(phoneNumber, phoneCountryCode, accountId, personId);
    await invalidatePlatformMeQuery();
    Analytics.track(
      QualificationApplicationEvents.QUALIFICATION_APPLICATION_PHONE_VERIFICATION_PHONE_VERIFIED,
      {
        application,
      },
    );
    if (
      application?.status === 'Rejected' ||
      application?.status === 'Approved'
    ) {
      navigate('/qualification/completed');
    } else {
      navigate('/qualification/company-location');
    }
    window.scroll(0, 0);
  };

  const onPhoneContinue = async () => {
    Analytics.track(
      QualificationApplicationEvents.QUALIFICATION_APPLICATION_PHONE_VERIFICATION_CONTINUE_CLICKED,
      {
        application,
      },
    );
    setIsLoading(true);
    setErrorMessage(null);
    const validationResult = validateIntlPhoneNumber(
      phoneNumber,
      phoneCountryCode,
    );

    if (!validationResult) {
      setHasInputError(true);
      setErrorMessage('Please enter a valid phone number');
      setIsLoading(false);
      return;
    }

    try {
      const phoneFormattedForPlatform = formatIntlPhoneForPlatform(
        phoneNumber,
        phoneCountryCode,
      );
      const registerFactorResponse = await registerFactor({
        factorType: 'phone',
        value: phoneFormattedForPlatform,
      });
      setMethodId(registerFactorResponse.methodId);
      setShowVerify(true);
    } catch (e) {
      // If the error is a 409, then the factor is already registered and verified, and there is nothing else to do.
      if (IsFlexHttpError(e) && e.statusCode === 409) {
        await onCodeVerified();
      } else if (isPhoneNotSupportedError(e)) {
        setErrorMessage(PHONE_NOT_SUPPORTED_ERROR_COPY);
      } else {
        setErrorMessage(
          'Failed to update phone number. Please contact support if the issue continues.',
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCodeClick = async () => {
    try {
      await resendOtpSmsVerification({
        value: `+1${formatPhoneForApi(phoneNumber)}`,
        factorType: 'phone',
      });
    } catch (e) {
      setErrorMessage(
        `An error occurred when attempting to resend a code to the phone number ending in ${GetPaddedAccountMask(
          phoneNumber,
          4,
        )}`,
      );
    }
  };

  const handleCodeChange = async (code: string) => {
    setIsLoading(true);
    try {
      await verifyFactor(methodId, 'otp', code);
      await onCodeVerified();
    } catch {
      setErrorMessage('The code you entered is invalid or expired.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <QualificationPageShell
      titleText={titleText}
      subtitleText={
        showVerify
          ? `Enter the 6 digit code that was sent to ${phoneNumber}.`
          : undefined
      }
      handleContinueClick={
        isCheckboxChecked && !showVerify ? onPhoneContinue : undefined
      }
      continueLoading={isLoading}
    >
      <>
        <Stack px={{ base: 'lg', sm: 0 }}>
          {showVerify ? (
            <VerificationCodeInput
              onResendCodeClick={handleResendCodeClick}
              onCodeSubmit={handleCodeChange}
            />
          ) : (
            <FlexIntlPhoneInput
              label="Phone number"
              data-sardine-id="cellphone"
              inputMode="tel"
              value={phoneNumber}
              countryCode={phoneCountryCode}
              onCountryCodeChange={(v) => setPhoneCountryCode(v)}
              onValueChange={(e) => handleSetPhoneNumber(e.formattedValue)}
              placeholder="Enter number"
              error={hasInputError}
              id="input-phone-number"
            />
          )}
        </Stack>
        {!showVerify && (
          <Stack mt="lg" px={{ base: 'lg', sm: 0 }}>
            <Checkbox
              styles={{
                body: {
                  alignItems: 'center',
                },
              }}
              id="checkbox-legal-age"
              label="By clicking 'Continue' you agree to receive SMS alerts from Flex regarding account activity. Standard messaging and data rates may apply."
              checked={isCheckboxChecked}
              onChange={(event) =>
                setIsCheckboxChecked(event.currentTarget.checked)
              }
              color="primary.2"
            />
          </Stack>
        )}
        {errorMessage && (
          <Alert
            icon={<RedAlertIcon />}
            my="lg"
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        )}
      </>
    </QualificationPageShell>
  );
};

export default QualificationVerifyPhonePage;
