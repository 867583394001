import { Box, Button, Flex } from '@mantine/core';
import { useState } from 'react';
import { useApproveDenyRequest } from '../../../../../queries/use-spend-plans';
import { Request } from '../../../spend-plans.types';
import DenyBox from './deny-box';
import RequestStatus from './request-status-box';

type Props = {
  request: Request;
  spendPlanId: string;
  accountId: string;
};

const DenyApproveElement = ({ request, spendPlanId, accountId }: Props) => {
  const [showDenyBox, setShowDenyBox] = useState(false);
  const [decision, setDecision] = useState('');
  const { mutate, isSuccess, isPending } = useApproveDenyRequest();
  const showActions = request.status === 'pending' && !decision && !isSuccess;

  const handleApproveRequest = () => {
    if (accountId && spendPlanId && request.requestId) {
      mutate(
        {
          body: {
            decision: 'approved',
            reason: '',
          },
          accountId: accountId,
          spendPlanId: spendPlanId,
          requestId: request.requestId,
        },
        {
          onSuccess: (data) => setDecision(data.decision),
        },
      );
    }
  };

  const handleDenyRequest = () => {
    setShowDenyBox(true);
  };

  const handleDenyConfirm = (denyReason: string) => {
    if (accountId && spendPlanId && request.requestId) {
      mutate(
        {
          body: {
            decision: 'declined',
            reason: denyReason,
          },
          accountId: accountId,
          spendPlanId: spendPlanId,
          requestId: request.requestId,
        },
        {
          onSuccess: (res) => {
            setDecision(res.decision);
          },
          onError: () => {
            setShowDenyBox(false);
          },
          onSettled: () => {
            setShowDenyBox(false);
          },
        },
      );
    }
  };

  const handleDenyCancel = () => {
    setShowDenyBox(false);
  };

  return (
    <Box bg="neutral.0" mb={8} p={16}>
      <RequestStatus request={request} decision={decision}>
        {showActions &&
          (showDenyBox ? (
            <Box mt={16}>
              <DenyBox onDeny={handleDenyConfirm} onCancel={handleDenyCancel} />
            </Box>
          ) : (
            <Flex justify="flex-end" mt={16}>
              <Button
                variant="critical-outline"
                mr={24}
                onClick={handleDenyRequest}
              >
                Deny request
              </Button>
              <Button
                variant="primary-light"
                onClick={handleApproveRequest}
                loading={isPending}
                disabled={isPending}
              >
                Approve request
              </Button>
            </Flex>
          ))}
      </RequestStatus>
    </Box>
  );
};
export default DenyApproveElement;
