import { Group, Stack, useMantineTheme } from '@mantine/core';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';
import { PreviewRow } from '../../components/preview-row';
import { PreviewLabel } from '../../components/preview-label';
import { useCreateSpendPlanWizard } from '../../create-spend-plan.wizard';

const numFmt = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
});

export const SpendPlanSpendControlsStepPreview = () => {
  const theme = useMantineTheme();
  const { spendControlsForm: form } = useCreateSpendPlanContext();
  const { state } = useCreateSpendPlanWizard();

  const isOneTimePlan = state.recurring === 'onetime';

  const getCurrentPeriodString = () => {
    return state.currentLimit
      ? numFmt.format(state.currentLimit)
      : isOneTimePlan
        ? `Spend plan limit not set`
        : `Current period spend limit not set`;
  };

  const getFuturePeriodString = () => {
    if (state.repeatCurrentLimit) {
      return state.currentLimit
        ? numFmt.format(state.currentLimit)
        : `Future period spend limit not set`;
    }

    return state.futureLimit
      ? numFmt.format(state.futureLimit)
      : `Future period spend limit not set`;
  };

  return (
    <Stack gap={24}>
      {form.values ? (
        <PreviewRow>
          <PreviewLabel
            fz={20}
            fw={500}
            lh="32px"
            color={theme.colors.primary[8]}
          >
            Available funds for each period
          </PreviewLabel>

          <Group gap="24" align="start">
            <Stack gap={4} sx={{ flexBasis: '50%' }}>
              <PreviewLabel>
                {isOneTimePlan ? 'Spend limit' : 'Current period spend limit'}
              </PreviewLabel>

              <PreviewLabel fs={state.currentLimit ? 'normal' : 'italic'}>
                {getCurrentPeriodString()}
              </PreviewLabel>
            </Stack>

            {!isOneTimePlan ? (
              <Stack gap={4} sx={{ flexBasis: '50%' }}>
                <PreviewLabel>Future period spend limit</PreviewLabel>

                <PreviewLabel
                  fs={
                    (
                      state.repeatCurrentLimit
                        ? !state.currentLimit
                        : !state.futureLimit
                    )
                      ? 'italic'
                      : 'normal'
                  }
                >
                  {getFuturePeriodString()}
                </PreviewLabel>
              </Stack>
            ) : null}
          </Group>
        </PreviewRow>
      ) : null}
    </Stack>
  );
};
