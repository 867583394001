import useModal from '@common/composites/modal/modal-hook';
import {
  Box,
  Button,
  Group,
  rem,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { ActiveFiltersReturnType } from './filters';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { createStyles } from '@mantine/emotion';
import { PiXBold } from 'react-icons/pi';

export type FilterConfig = {
  header: string;
  icon?: ReactNode;
  key: string;
  component: ReactNode;
};

type Props = {
  filters: FilterConfig[];
  onSave: () => void;
  onCancel: () => void;
};

export const FilterModal = ({ filters, onSave, onCancel }: Props) => {
  const { classes, theme } = useStyles();
  const [isScrollable, setIsScrollable] = useState(false);

  const filterMap = filters.reduce<Record<string, ReactNode>>((p, current) => {
    p[current.key] = current.component;
    return p;
  }, {});

  const handleApply = () => onSave();
  const handleCancel = () => onCancel();
  const handleClose = () => onCancel();

  useEffect(() => {
    const handleResize = () => {
      const content = document.getElementById('filters-container');
      if (content) {
        setIsScrollable(content.scrollHeight > content.clientHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box id="filters-container" className={classes.filtersContainer}>
      <Group className={classes.headerRow1}>
        <Stack gap="xs">
          <Text className={classes.title}>Filters</Text>
        </Stack>
        <UnstyledButton
          className={classes.closeButton}
          c="neutral.0"
          onClick={handleClose}
        >
          <PiXBold size="2rem" />
        </UnstyledButton>
      </Group>
      <Box
        sx={{
          width: '100%',
          backgroundColor: theme.colors.neutral[3],
        }}
        p={35}
      >
        {filters.map((f) => (
          <Fragment key={f.key}>
            <Text fw={500}>{f.header}</Text>
            <Box mt={rem(8)} mb={rem(16)} w="100%">
              {filterMap[f.key]}
            </Box>
          </Fragment>
        ))}
        <Box mt={rem(32)}>
          <Group
            style={{
              position: isScrollable ? 'static' : 'fixed',
              bottom: isScrollable ? 'unset' : rem(16),
              right: isScrollable ? 'unset' : rem(20),
              justifyContent: 'right',
            }}
          >
            <Button
              classNames={{ root: classes.btnCancel }}
              variant="neutral-outline"
              onClick={handleCancel}
              bg="neutral.0"
            >
              Cancel
            </Button>
            <Button onClick={handleApply} variant="primary-filled">
              Apply filters
            </Button>
          </Group>
        </Box>
      </Box>
    </Box>
  );
};

type UseFilterModalProps<T> = {
  filterHook: ActiveFiltersReturnType<T>;
  modalFilterHook: ActiveFiltersReturnType<T>;
};

/**
 * Custom utility hook to handle opening, saving, and canceling the FilterModal.
 *
 * @returns The original filterHook values, along with a utility function to open the FilterModal.
 *
 * @example
 * const filterHook = useMyTableFilters();
 * const modalFilterHook = useMyModalFilters();
 * const {
 *   getFilterByKey,
 *   openResponsiveFilterModal,
 * } = useFilterModal({ filterHook, modalFilterHook });
 *
 * const searchValue = getFilterByKey('search').filterValue;
 *
 * const filters: FilterConfig[] = [
 *   // your filter configs
 * ];
 *
 * const handleOpen = () => {
 *   openResponsiveFilterModal(filters);
 * };
 */
export const useFilterModal = function <T>({
  filterHook,
  modalFilterHook,
}: UseFilterModalProps<T>) {
  const theme = useMantineTheme();
  const isMobile = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);
  const { openFullModal, openRightModal, closeAllModals } = useModal();
  const {
    activeFilters: activeTableFilters,
    applyAllFilters: applyToTableFilters,
  } = filterHook;
  const {
    activeFilters: activeModalFilters,
    applyAllFilters: applyToModalFilters,
  } = modalFilterHook;

  /**
   * Any props passed to a Modal are basically snapshots of the values during
   * initial render. In order for any of the handlers to access current values,
   * we need to store them in a ref.
   */
  const activeModalFiltersRef = useRef(activeModalFilters);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
  }, [activeModalFilters]);

  const handleModalSave = () => {
    // apply the updated modal filters to the table filters
    applyToTableFilters(activeModalFiltersRef.current);
    closeAllModals();
  };

  const handleModalCancel = () => {
    closeAllModals();
  };

  /**
   * Opens the FilterModal by calling openRightModal() (desktop) or openFullModal (mobile).
   *
   * Override the default save/cancel handlers by passing in options.
   *
   * @param [options.onSave] Optional. Handle the save event.
   * @param [options.onCancel] Optional. Handle the cancel event.
   */
  const openResponsiveFilterModal = (
    filters: FilterConfig[],
    options: {
      onSave?: () => void;
      onCancel?: () => void;
    } = {},
  ) => {
    // initialize the modal filters to the current filter state
    applyToModalFilters(activeTableFilters);

    const openFn = isMobile ? openFullModal : openRightModal;

    return openFn(
      <FilterModal
        filters={filters}
        onSave={options.onSave ?? handleModalSave}
        onCancel={options.onCancel ?? handleModalCancel}
      />,
    );
  };

  return {
    ...filterHook,
    openResponsiveFilterModal,
  };
};

const useStyles = createStyles((theme) => ({
  filtersContainer: {
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  headerRow1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: rem(35),
    backgroundColor: theme.colors.primary[8],
    '> div:last-of-type': {
      marginRight: rem(-11),
    },
    flexWrap: 'nowrap',
  },
  closeButton: {
    display: 'flex',
    cursor: 'pointer',
  },
  title: {
    height: rem(43),
    color: theme.colors.neutral[0],
    fontFamily: theme.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(36),
  },
  btnCancel: {
    ':hover': {
      background: 'white !important',
    },
  },
}));
