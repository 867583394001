import { Anchor, Group, Text, Tooltip, useMantineTheme } from '@mantine/core';
import {
  PiArrowRight,
  PiCheckCircleBold,
  PiCircle,
  PiInfoBold,
} from 'react-icons/pi';

type Props = {
  title: string;
  isComplete?: boolean;
  isSkipped?: boolean;
  skippedTooltip?: string;
  icon: React.ReactNode;
  href?: string;
};

export default function UnbundledStepCard({
  title,
  isComplete,
  isSkipped,
  skippedTooltip,
  icon,
  href,
}: Props) {
  const theme = useMantineTheme();
  return (
    <Anchor
      sx={{
        borderRadius: theme.radius.sm,
      }}
      px="md"
      py="xl"
      bg={theme.colors.neutral[0]}
      bd={`1px solid ${isComplete ? theme.colors.primary[3] : theme.colors.neutral[2]}`}
      underline="never"
      href={href}
    >
      <Group align="center" justify="space-between">
        <Group align="center" gap={0}>
          {icon}
          <Text
            ml="sm"
            mr="xs"
            fw={isComplete ? 'normal' : 'bold'}
            lts={0.33}
            td={isComplete ? 'line-through' : 'none'}
            lh="normal"
            c={isComplete ? theme.colors.primary[4] : theme.colors.neutral[10]}
            flex={1}
          >
            {title}
          </Text>
          {isComplete ? (
            <PiCheckCircleBold color={theme.colors.primary[4]} />
          ) : (
            <PiCircle />
          )}
        </Group>

        {!isComplete && (
          <PiArrowRight size={20} color={theme.colors.neutral[8]} />
        )}

        {isSkipped && (
          <Tooltip label={skippedTooltip}>
            <Group gap="xs">
              <Text lh="normal" fs="italic">
                Skipped
              </Text>
              <PiInfoBold size={20} color={theme.colors.neutral[7]} />
            </Group>
          </Tooltip>
        )}
      </Group>
    </Anchor>
  );
}
