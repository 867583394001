import { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { PiCopy } from 'react-icons/pi';
import { useClipboard } from '@mantine/hooks';
import { DepositAccount } from 'types/move-funds.model';
import AccountSelection from '@common/account-selection';
import { downloadWireInstructions } from '../accounts/utilities';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';

type WireTransferProps = {
  depositAccounts: DepositAccount[];
};

const WireTransfer = ({ depositAccounts }: WireTransferProps) => {
  const theme = useMantineTheme();
  const clipboard = useClipboard();
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);
  const [accountSelected, setAccountSelected] = useState(depositAccounts[0]);

  return (
    <Box>
      <AccountSelection
        accounts={depositAccounts}
        currentAccount={accountSelected}
        label="Your receiving account info"
        onAccountChange={(acc) => setAccountSelected(acc as DepositAccount)}
      />

      <Flex mt="xl" gap="md">
        <Box w="100%">
          <Text size="sm">Routing number</Text>
          <Tooltip
            withArrow
            label={clipboard.copied ? 'Copied' : 'Click to copy'}
            onClick={() => clipboard.copy(accountSelected?.routingNumber)}
          >
            <Flex
              p="sm"
              align="center"
              bg="neutral.0"
              justify="space-between"
              sx={{ border: `1px solid ${theme.colors.neutral[4]}` }}
            >
              <Text>{accountSelected?.routingNumber}</Text>
              <PiCopy size={24} color={theme.colors.neutral[6]} />
            </Flex>
          </Tooltip>
        </Box>
        <Box w="100%">
          <Text size="sm">Account number</Text>
          <Tooltip
            withArrow
            label={clipboard.copied ? 'Copied' : 'Click to copy'}
            onClick={() => clipboard.copy(accountSelected?.accountNumber)}
          >
            <Flex
              p="sm"
              align="center"
              bg="neutral.0"
              justify="space-between"
              onMouseOver={() => setDisplayAccountNumber(true)}
              onMouseLeave={() => setDisplayAccountNumber(false)}
              sx={{ border: `1px solid ${theme.colors.neutral[4]}` }}
            >
              <Text>
                {displayAccountNumber
                  ? accountSelected?.accountNumber
                  : GetPaddedAccountMask(
                      accountSelected?.accountNumber ?? '',
                      4,
                    )}
              </Text>
              <PiCopy size={24} color={theme.colors.neutral[6]} />
            </Flex>
          </Tooltip>
        </Box>
      </Flex>
      <Flex justify="flex-end" mt="xl">
        <Button
          onClick={() => downloadWireInstructions(accountSelected.id)}
          variant="primary-filled"
        >
          Wire Details
        </Button>
      </Flex>
    </Box>
  );
};

export default WireTransfer;
