import { Group, Stack, Text } from '@mantine/core';
import { useDetailsStyles } from '../details/details-styles';
import { formatCurrency } from 'utilities/formatters';
import { formatDatetime } from 'utilities/formatters/format-datetime';
import { PiXBold } from 'react-icons/pi';

type Props = {
  createdAt: string;
  total: string;
  type: string;
  status: string;
  closeModal: () => void;
};

const DetailsHeader = ({
  total,
  createdAt,
  closeModal,
  status,
  type,
}: Props) => {
  const { classes, theme } = useDetailsStyles();

  let statusColor = '';

  switch (status) {
    case 'Pending':
      statusColor = '#EEEEF3';
      break;
    case 'Declined':
    case 'Failed':
    case 'Voided':
      statusColor = theme.colors.critical[0];
      break;
    case 'Settled':
    case 'Chargeback':
    case 'Credit':
      statusColor = theme.colors.sage[1];
      break;
    default:
      statusColor = '#EEEEF3';
      break;
  }

  return (
    <Group className={classes.headerRow1}>
      <Group gap="md">
        <Stack gap="xs">
          <Group align="end" gap="lg">
            <Text className={classes.amount}>
              {formatCurrency(Math.abs(total as unknown as number)) || 'N/A'}
            </Text>
            <Text
              fz={14}
              className={classes.statusContainer}
              style={{ backgroundColor: statusColor }}
            >
              {status}
            </Text>
          </Group>
          <Group gap="sm" className={classes.dateTime}>
            <Text>{type}</Text>
            <Text>|</Text>
            <Text>{formatDatetime(createdAt)}</Text>
          </Group>
        </Stack>
      </Group>
      <button className={classes.closeButton} onClick={closeModal}>
        <PiXBold size="2.125rem" color={theme.colors.neutral[0]} />
      </button>
    </Group>
  );
};

export default DetailsHeader;
