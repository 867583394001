import {
  ScaledMoneyYaml,
  ScaledMoneyYamlScaledMoneyYaml,
} from '@flexbase-eng/sdk-typescript/models/components';

/**
 * Gets the actual value from a ScaledMoney object.
 *
 * Copied from Platform.
 *
 * @example
 * const m = {
 *  amount: 1234,
 *  scale: 2,
 *  currency: 'USD',
 * };
 *
 * actualValue(m); // 12.34
 */
export function actualValue(m: undefined): undefined;
export function actualValue(m: ScaledMoneyYaml): number;
export function actualValue(m: ScaledMoneyYamlScaledMoneyYaml): number;
export function actualValue(m: ScaledMoneyYaml | undefined): number | undefined;
export function actualValue(
  m: ScaledMoneyYaml | ScaledMoneyYamlScaledMoneyYaml | undefined,
): number | undefined {
  if (m === undefined) {
    return undefined;
  }

  return Math.round(m.amount * Math.pow(10, m.scale * -1) * 100) / 100;
}
