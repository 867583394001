import { ReactNode } from 'react';
import {
  Box,
  Divider as MantineDivider,
  Flex,
  Progress,
  rem,
  Text,
  MantineStyleProps,
  MantineSpacing,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import useModal from './modal-hook';
import { ArrowBackIconWhite } from '../../../assets/svg';
import { IAddress } from 'types/user-info';
import { FiX } from 'react-icons/fi';
import { createStyles } from '@mantine/emotion';

type ChildrenProps = { children: ReactNode };

type HeaderProps = {
  title: ReactNode;
  rightTitle?: ReactNode;
  subtitle?: ReactNode;
  rightSubtitle?: ReactNode;
  titleSize?: string | number;
};

function Header({
  title,
  rightTitle,
  subtitle,
  rightSubtitle,
  titleSize,
}: HeaderProps) {
  const { classes } = useStyles();
  const isMobileView = useMediaQuery('(max-width: 767px)');
  const modal = useModal();
  return (
    <>
      {isMobileView && (
        <Box className={classes.backButtonBar}>
          <Box
            className={classes.iconContainer}
            onClick={() => modal.closeAllModals()}
          >
            <ArrowBackIconWhite height={rem(16)} width={rem(16)} />
          </Box>
        </Box>
      )}
      <Box
        className={classes.headerBar}
        sx={() => ({ height: subtitle || rightSubtitle ? rem(152) : rem(120) })}
      >
        <Box className={classes.spaceBetween}>
          <Text
            component={typeof title === 'string' ? 'p' : 'div'}
            fz={titleSize ?? rem(20)}
            lineClamp={1}
          >
            {title}
          </Text>
          <Text
            component={typeof rightTitle === 'string' ? 'p' : 'div'}
            fz={rem(20)}
          >
            {rightTitle}
          </Text>
        </Box>
        <Box className={classes.spaceBetween}>
          <Text
            component={typeof subtitle === 'string' ? 'p' : 'div'}
            fz={rem(14)}
          >
            {subtitle}
          </Text>
          <Text
            component={typeof rightSubtitle === 'string' ? 'p' : 'div'}
            fz={rem(14)}
          >
            {rightSubtitle}
          </Text>
        </Box>
      </Box>
    </>
  );
}

function SingleTitleHeader({
  title,
  subtitle,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
}) {
  const modal = useModal();
  const { classes } = useStyles();

  return (
    <Flex className={classes.headerBar} h={rem(133)} align="center">
      <Flex align="center" justify="space-between" w="100%">
        <Box>
          <Text
            component={typeof title === 'string' ? 'p' : 'div'}
            fz={rem(40)}
            lineClamp={1}
            lts={rem(-0.88)}
            lh={1}
          >
            {title}
          </Text>
          {subtitle && (
            <Text
              component={typeof subtitle === 'string' ? 'p' : 'div'}
              fz={rem(14)}
              lh={1}
              mt={rem(4)}
            >
              {subtitle}
            </Text>
          )}
        </Box>
        <FiX
          onClick={() => modal.closeAllModals()}
          className={classes.headerCloseButton}
          size="2rem"
          strokeWidth={1.25}
        />
      </Flex>
    </Flex>
  );
}

function Body({ children }: ChildrenProps) {
  const { classes } = useStyles();
  return <Box className={classes.contentContainer}>{children}</Box>;
}

function Card({ children }: ChildrenProps) {
  const { classes, cx } = useStyles();
  return (
    <Box
      mt="md"
      w="100%"
      className={cx(classes.spaceBetween, classes.flexCenterMobile)}
    >
      {children}
    </Box>
  );
}

type DividerProps = {
  mx?: MantineSpacing;
  my?: MantineSpacing;
};

function Divider({ mx, my }: DividerProps) {
  return <MantineDivider my={my ?? 'xl'} mx={mx ?? rem(-32)} />;
}

type AddressSectionProps = { address: IAddress; title: string };

function AddressSection({ address, title }: AddressSectionProps) {
  return (
    <Box fz={rem(20)}>
      <Text
        component={typeof title === 'string' ? 'p' : 'div'}
        fz={rem(14)}
        mb="xs"
      >
        {title}
      </Text>
      <Text fz="inherit">{address.line1}</Text>
      <Text fz="inherit">{address.line2}</Text>
      <Text fz="inherit">
        {address.city}, {address.state}{' '}
        {address.postalCode?.length > 5
          ? address.postalCode.substring(0, 5)
          : address.postalCode}
      </Text>
    </Box>
  );
}

type TitleValueLineProps = {
  title: ReactNode;
  value: ReactNode;
} & Partial<MantineStyleProps>;

function TitleValueLine({ title, value, ...styleProps }: TitleValueLineProps) {
  return (
    <Flex justify="space-between" align="center" fz={rem(14)} {...styleProps}>
      <Text fz="sm" component={typeof title === 'string' ? 'p' : 'div'}>
        {title}
      </Text>
      <Text fz="sm" component={typeof value === 'string' ? 'p' : 'div'}>
        {value}
      </Text>
    </Flex>
  );
}

type ProgressBarProps = {
  progressSize?: MantineSpacing;
  progressValue: number;
  animate: boolean;
  leftText: ReactNode;
  rightText: ReactNode;
  hideProgressBar?: boolean;
} & Partial<MantineStyleProps>;

function ProgressBar({
  progressValue,
  progressSize,
  animate,
  leftText,
  rightText,
  hideProgressBar,
  ...styleProps
}: ProgressBarProps) {
  return (
    <>
      {!hideProgressBar && (
        <Progress
          value={progressValue}
          animated={animate}
          size={progressSize || 'sm'}
          color="primary.8"
          sx={(theme) => ({
            backgroundColor: theme.colors.neutral[0],
          })}
          my="md"
          h={rem(6)}
          {...styleProps}
        />
      )}
      <TitleValueLine fw={500} title={leftText} value={rightText} />
    </>
  );
}

export function RightContentModal({ children }: ChildrenProps) {
  const { classes } = useStyles();
  return <Box className={classes.container}>{children}</Box>;
}

RightContentModal.Header = Header;
RightContentModal.SingleTitleHeader = SingleTitleHeader;
RightContentModal.Body = Body;
RightContentModal.Card = Card;
RightContentModal.Divider = Divider;
RightContentModal.AddressSection = AddressSection;
RightContentModal.TitleValueLine = TitleValueLine;
RightContentModal.ProgressBar = ProgressBar;

const useStyles = createStyles((theme, _, helpers) => ({
  container: {
    backgroundColor: theme.colors.neutral[3],
    minHeight: '100vh',
    color: theme.colors.primary[8],
  },
  headerBar: {
    backgroundColor: theme.colors.primary[8],
    color: theme.colors.neutral[0],
    padding: rem(32),
    [helpers.smallerThan('sm')]: {
      paddingTop: theme.spacing.md,
    },
  },
  headerCloseButton: {
    cursor: 'pointer',
    ['&:hover']: {
      opacity: 0.8,
    },
  },
  backButtonBar: {
    width: '100%',
    backgroundColor: theme.colors.primary[8],
    paddingTop: rem(8),
    paddingLeft: rem(16),
  },
  iconContainer: {
    width: rem(32),
    height: rem(32),
    display: 'flex',
    alignItems: 'center',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.md,
  },
  flexCenterMobile: {
    [helpers.smallerThan('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  contentContainer: {
    padding: `${theme.spacing.xl} ${rem(32)}`,
    [helpers.smallerThan('sm')]: {
      overflowX: 'hidden',
      padding: theme.spacing.sm,
    },
  },
}));
