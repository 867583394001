import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { useMantineTheme } from '@mantine/core';
import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import type { PlaidAccount, DepositAccount } from 'types/move-funds.model';
import FlexLogo from '@common/composites/pdf-viewer/flex-logo';
import HeaderPDF from '@common/composites/pdf-viewer/header';
import { usePDFStyles } from './pdf-payment-receipt.styles';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

// all acceptable Payment Account types
type PaymentAccount = PlaidAccount | DepositAccount;

// derive a masked account number from any acceptable Payment Account
const getAccountMask = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'plaid') {
    return getPaddedAccountMask(account.last4 ?? account.account, 4);
  }

  return getPaddedAccountMask(account.accountNumber, 4);
};

// derive an account name from the Payment Account
const getName = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'plaid') {
    return account.bankName;
  }

  return account.nickName ?? account.name;
};

// derive a processing time from the Payment Account
const getProcessingMessage = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'deposit') {
    return 'Payment will successfully be posted on your account in 3-5 days depending on your external banking institution.';
  }

  return 'Processing of your payment may take up to 4 business days, after which your credit balance will be updated.';
};

type PDFProps = {
  isError: boolean;
  createdAt?: string;
  creditText: string;
  documentName: string;
  payAmount: string | number;
  paymentAccount: DepositAccount | PlaidAccount | undefined;
};

const PDFPaymentReceipt = ({
  isError,
  createdAt,
  payAmount,
  creditText,
  documentName,
  paymentAccount,
}: PDFProps) => {
  const theme = useMantineTheme();
  const { company } = useRecoilValue(ApplicationState);

  const timeInitiated = createdAt
    ? DateTime.fromSQL(createdAt).toFormat('ff')
    : '';

  const styles = usePDFStyles({
    neutral: theme.colors.neutral[3],
    textColor: theme.colors.neutral[8],
    primaryDark: theme.colors.primary[8],
  });

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document title={documentName}>
        <Page wrap size="A4">
          <View fixed>
            <HeaderPDF {...{ theme }} />
          </View>
          <View style={{ padding: '0px 25px' }}>
            <View style={styles.headerRow}>
              <FlexLogo {...{ theme }} />
              <View>
                <Text
                  style={{
                    ...styles.textHeader,
                    fontStyle: 'bold',
                  }}
                >
                  {creditText}
                </Text>
                <Text style={styles.textHeader}>{company?.companyName}</Text>
                <Text style={styles.textHeader}>
                  {company?.address.line1}, {company?.address.line2}
                </Text>
                <Text style={styles.textHeader}>
                  {company?.address.city}, {company?.address.state} -{' '}
                  {company?.address.postalCode}
                </Text>
              </View>
            </View>
            <View>
              <Text style={{ ...styles.text, marginTop: 10 }}>
                {`Time initiated: ${timeInitiated}`}
              </Text>
              <Text
                style={{
                  ...styles.text,
                  fontSize: 18,
                }}
              >
                {creditText} Payment Receipt
              </Text>
            </View>
            {isError ? (
              <View style={styles.cardSentFromContainer}>
                <Text
                  style={{
                    ...styles.text,
                    fontSize: 18,
                  }}
                >
                  An error occurred while retrieving the payment information.
                </Text>
              </View>
            ) : (
              <>
                <View style={styles.cardReceiptContainer}>
                  <Text style={styles.text}>
                    Payment towards your credit balance
                  </Text>
                  <Text style={styles.amountText}>{payAmount}</Text>
                </View>
                {paymentAccount && (
                  <View style={styles.cardSentFromContainer}>
                    <Text
                      style={{
                        ...styles.text,
                        fontStyle: 'normal',
                        color: theme.colors.primary[2],
                      }}
                    >
                      Sent from
                    </Text>
                    <Text
                      style={{
                        ...styles.text,
                        marginTop: 10,
                      }}
                    >
                      Account
                    </Text>
                    <Text style={{ ...styles.text, marginTop: 2 }}>
                      {`${getName(paymentAccount)} / ${
                        paymentAccount.accountType
                      } ${getAccountMask(paymentAccount)}`}
                    </Text>
                    <Text
                      style={{
                        ...styles.text,
                        fontSize: '9px',
                        color: theme.colors.neutral[7],
                        marginTop: 10,
                      }}
                    >
                      {getProcessingMessage(paymentAccount)}
                    </Text>
                  </View>
                )}
              </>
            )}
          </View>

          <View style={styles.footerContainer} fixed>
            <View>
              <Text>Flexbase Technologies, Inc. </Text>
              <Text>390 NE 191st St #8019 Miami, FL 33179</Text>
            </View>
            <View>
              <Text style={{ alignSelf: 'flex-end' }}>Need help?</Text>
              <Text>support@flex.one or call 415-840-8721</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFPaymentReceipt;
