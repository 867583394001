import { FlexWizard } from '@common/emerald/wizard/components/flex-wizard';
import {
  Badge,
  Box,
  Button,
  Group,
  rem,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { FlexIcon } from 'assets/svg';
import { useAuthToken } from 'providers/auth.provider';
import { PiClockBold, PiSignOutBold } from 'react-icons/pi';
import { useQualificationApplication } from '../areas/onboarding-v2/qualification-application-provider';
import { useNavigate } from 'react-router-dom';

type OnboardingHeaderProps = {
  titleText?: string;
  subtitleText?: string;
  estimatedTime?: string;
  progress?: number;
};

const OnboardingHeader = ({
  titleText,
  subtitleText,
  estimatedTime,
  progress,
}: OnboardingHeaderProps) => {
  const { progress: applicationProgress } = useQualificationApplication();
  const { logout, userIsAuthorized } = useAuthToken();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  return (
    <Box>
      <Group py="lg" px="sm" justify="space-between">
        <FlexIcon width={88} color={theme.colors.neutral[9]} />
        {userIsAuthorized ? (
          <Button
            variant="neutral-subtle"
            onClick={logout}
            leftSection={<PiSignOutBold />}
          >
            Logout
          </Button>
        ) : (
          <Button
            data-testid="button-login"
            variant="primary-subtle"
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
        )}
      </Group>
      <FlexWizard.Progress value={progress ?? applicationProgress} />
      <Stack
        w={{ base: '100%', sm: rem(525) }}
        mx="auto"
        pt={rem(48)}
        pb="lg"
        px={{ base: 'lg', sm: 0 }}
      >
        {estimatedTime && (
          <Badge bg={theme.colors.promote[1]}>
            <Group gap="xxs">
              <PiClockBold color={theme.colors.promote[7]} size={12} />
              <Text c={theme.colors.promote[7]} size="xs">
                {estimatedTime}
              </Text>
            </Group>
          </Badge>
        )}
        {titleText && (
          <Text ff="Redaction" fz={rem(32)} lh="normal">
            {titleText}
          </Text>
        )}
        {subtitleText && <Text lh="normal">{subtitleText}</Text>}
      </Stack>
    </Box>
  );
};

export default OnboardingHeader;
