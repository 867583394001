import { Box, rem, Select, Stack } from '@mantine/core';
import QualificationPageShell from './components/qualification-page-shell';
import { Country } from 'country-state-city';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useRef, useState } from 'react';
import {
  useGetQualificationApplication,
  useUpdateApplication,
} from './use-qualification-application';
import { useQualificationApplication } from 'areas/onboarding-v2/qualification-application-provider';
import { Analytics } from '@services/analytics/analytics';
import { QualificationApplicationEvents } from '@services/analytics/events';
import { showNotification } from '@mantine/notifications';
import QueryErrorMessage from 'areas/onboarding-v2/components/query-error-message';
import { useMediaQuery } from '@mantine/hooks';

const countries = Country.getAllCountries()
  .sort((a, b) => {
    // Sort alphabetically but put United States at the top
    if (a.name === 'United States') {
      return -1;
    }
    if (b.name === 'United States') {
      return 1;
    }
    return a.name.localeCompare(b.name);
  })
  .map((c) => ({
    label: c.name,
    value: c.isoCode,
  }));

const QualificationCompanyLocationPage = () => {
  const selectRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const {
    data: application,
    isError,
    refetch,
  } = useGetQualificationApplication();
  const isRejected = application?.status === 'Rejected';
  const { mutate: updateApplication, isPending: isUpdatingApplication } =
    useUpdateApplication();
  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    application?.fields?.primaryOperationCountry || null,
  );
  const { navigateToNextStep } = useQualificationApplication();

  const handleContinueClick = () => {
    if (!selectedCountry) {
      return;
    }
    if (
      isRejected ||
      selectedCountry === application?.fields?.primaryOperationCountry
    ) {
      return navigateToNextStep();
    }
    updateApplication(
      {
        id: application!.id,
        updateApplicationBody: {
          primaryOperationCountry: selectedCountry,
        },
      },
      {
        onSuccess: (updatedApplication) => {
          Analytics.track(
            QualificationApplicationEvents.QUALIFICATION_APPLICATION_COMPANY_LOCATION_CONTINUE_CLICKED,
            {
              application: updatedApplication,
            },
          );
          navigateToNextStep(updatedApplication);
        },
        onError: () => {
          showNotification({
            color: 'red',
            title: 'Unable to save company information',
            message: 'Please try again.',
          });
        },
      },
    );
  };

  const handleSelectClick = () => {
    if (!isMobile) return;

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        if (selectRef.current) {
          window.scrollTo({
            top:
              selectRef.current.getBoundingClientRect().top +
              window.scrollY -
              50,
            behavior: 'smooth',
          });
        }
      });
    }, 100);
  };

  return (
    <QualificationPageShell
      titleText="Where is your company based?"
      subtitleText="Either your headquarters, principal place of business, or where most of your employees are located."
      handleContinueClick={selectedCountry ? handleContinueClick : undefined}
      continueLoading={isUpdatingApplication}
    >
      <Stack px={{ base: 'lg', sm: 0 }}>
        {isError ? (
          <QueryErrorMessage
            message="Unable to load application information."
            refetch={refetch}
          />
        ) : (
          <Box ref={selectRef}>
            <Select
              label="Country of primary operation"
              styles={{
                label: { fontSize: rem(16) },
              }}
              placeholder="Select country"
              leftSection={<PiMagnifyingGlass />}
              searchable
              value={selectedCountry}
              onChange={(value) => setSelectedCountry(value)}
              data={countries}
              onClick={handleSelectClick}
            />
          </Box>
        )}
      </Stack>
    </QualificationPageShell>
  );
};

export default QualificationCompanyLocationPage;
