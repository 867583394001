import { Text, Button, Badge, rem, TextInput } from '@mantine/core';
import { useStyles } from '../common/styles';
import { FlexbaseTable } from 'components/table';
import { DepositAccount, MoneyMovement } from 'services/flexbase/banking.model';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '@queries/use-users';
import { useState } from 'react';
import { sortDate } from '@utilities/dates';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { UserInfoState } from 'types/user-info';
import { useRecoilValue } from 'recoil';
import {
  IntlPaymentInfo,
  TablePayment,
  generateRows,
} from '@utilities/payments-rows';
import PaymentDetailsModal from '@common/payment-details-modal';
import { TableColumn } from 'react-data-table-component';
import DisplayingPayAmount from '../common/displaying-pay-amount';
import { OverflowTooltip } from '@common/utilities/overflow-tooltip';
import GenericError from '../../../../components/generic-error';
import { PiMagnifyingGlass, PiPaperPlaneTiltBold } from 'react-icons/pi';

const columns: TableColumn<TablePayment>[] = [
  {
    name: 'Date submitted',
    selector: (row) => row.dateSubmitted ?? '',
    sortable: true,
    sortFunction: sortDate,
  },
  {
    name: 'Recipient',
    selector: (row) => row.recipient,
    sortable: true,
  },
  {
    name: 'Amount',
    cell: (row) => DisplayingPayAmount({ payment: row }),
    sortable: true,
  },
  {
    name: 'Type',
    selector: (row) => row.type,
    sortable: true,
  },
  {
    name: 'Sender',
    cell: (row) => row.requestedBy ?? 'N/A',
  },
  {
    name: 'Account',
    cell: (row: { accountName: string }) => (
      <OverflowTooltip text={row.accountName} />
    ),
    selector: (row: { accountName: string }) => row.accountName,
    sortable: true,
  },
  {
    name: 'Status',
    sortable: true,
    selector: (row) => row.status,
    cell: (row) => {
      return <Badge bg="neutral.2">{formatApiStrings(row.status)}</Badge>;
    },
  },
];

const columnsSm: TableColumn<TablePayment>[] = [
  {
    name: 'Date submitted',
    selector: (row) => row.dateSubmitted ?? '',
    sortable: true,
    compact: true,
  },
  {
    name: 'Recipient',
    selector: (row) => row.recipient,
    sortable: true,
    compact: true,
  },
  {
    name: 'Amount',
    selector: (row) => row.fxAmount ?? '',
    cell: (row) => DisplayingPayAmount({ payment: row }),
    sortable: true,
    compact: true,
  },
  {
    name: 'Type',
    selector: (row) => row.type,
    sortable: true,
    compact: true,
  },
  {
    name: 'Sender',
    compact: true,
    cell: (row) => row.requestedBy ?? 'N/A',
  },
  {
    name: 'Account',
    selector: (row: { accountName: string }) => row.accountName,
    sortable: true,
  },
  {
    name: 'Status',
    compact: true,
    cell: (row) => {
      return <Badge>{formatApiStrings(row.status)}</Badge>;
    },
  },
];

type Props = {
  payments: MoneyMovement[];
  users: User[];
  isLoading: boolean;
  error?: Error;
  recipientName?: string;
  intlPayments: IntlPaymentInfo[];
  depositAccounts: DepositAccount[];
  handleRetry: () => void;
};

const PaymentsPendingTable = ({
  payments,
  users,
  isLoading,
  error,
  recipientName,
  intlPayments,
  depositAccounts,
}: Props) => {
  const { id } = useParams();
  const user = useRecoilValue(UserInfoState);
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [searchTerm, setSearchTerm] = useState<string>();

  const handleSearchChange = (val: string) => setSearchTerm(val.toLowerCase());

  // generate table rows from payments, deposit accounts, and users filtered by the search term
  const rows = generateRows({
    users,
    payments,
    intlPayments,
    depositAccounts,
  }).filter((payment) => {
    if (!searchTerm) {
      return payment;
    } else {
      return (
        payment?.dateSubmitted?.toLowerCase().includes(searchTerm) ||
        payment.accountName?.toLowerCase().includes(searchTerm) ||
        payment.amount.toLowerCase().includes(searchTerm) ||
        payment.status.toLowerCase().includes(searchTerm) ||
        payment.description.toLowerCase().includes(searchTerm) ||
        payment.requestedBy?.toLowerCase().includes(searchTerm) ||
        payment.recipient?.toLowerCase().includes(searchTerm) ||
        payment.notes?.toLowerCase().includes(searchTerm) ||
        payment.type.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    }
  });

  if (error) {
    return (
      <GenericError>
        <p>{error?.message}</p>
        <Button
          variant="primary-light"
          onClick={() => navigate('/payments/approvals')}
          mt={20}
        >
          Please try again
        </Button>
      </GenericError>
    );
  }

  // fetch an open payment if one exists from the URL param
  const openPayment = rows.find((row) => row.id === id);

  const awaitingApprovalRows = rows
    .filter(
      (row) =>
        row.status === 'Approval Needed' ||
        (row.status === '2FA Required' &&
          (row.approvedBy === user.id || row.userId === user.id)),
    )
    .sort((a, b) => sortDate(a, b, false));

  // FIXME: account for mobile modals again
  return (
    <>
      {openPayment && <PaymentDetailsModal openPayment={openPayment} />}
      <div className={classes.container}>
        <div className={classes.header}>
          <TextInput
            w={isMobile ? '100%' : rem(300)}
            placeholder="Search all fields"
            onChange={(e) => handleSearchChange(e.target.value)}
            defaultValue={recipientName || undefined}
            leftSection={
              <PiMagnifyingGlass
                size={'1.25rem'}
                color={theme.colors.neutral[6]}
              />
            }
          ></TextInput>
          <Button
            variant="primary-filled"
            leftSection={<PiPaperPlaneTiltBold />}
            disabled={!user.roles.includes('ADMIN')}
            onClick={() => {
              navigate('/payments/outgoing/recipient');
            }}
            data-testid={'send-payment'}
          >
            Send a payment
          </Button>
        </div>
        <FlexbaseTable
          columns={isMobile ? (columnsSm as any[]) : (columns as any[])}
          data={awaitingApprovalRows}
          pagination={awaitingApprovalRows && awaitingApprovalRows?.length > 8}
          onRowClicked={(row) => navigate(`${row.id}`)}
          isFetchingData={isLoading}
          noDataComponent={
            <Text fz={rem(24)} fw={500} mt="lg">
              {/* FIXME: this is likely incorrect if filters have been applied */}
              No actions required
            </Text>
          }
        />
      </div>
    </>
  );
};

export default PaymentsPendingTable;
