import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'space-between',
    color: theme.colors.neutral[0],
    padding: rem(45),
    backgroundColor: theme.primaryColor,
    '@media(max-width: 767px)': {
      padding: rem(20),
    },
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerItem: {
    marginBottom: rem(20),
  },
  padding: {
    padding: `0px ${rem(50)}`,
    '@media(max-width: 767px)': {
      padding: `0px ${rem(20)}`,
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  cardDetails: {
    paddingBottom: rem(50),
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[3],
    '@media(max-width: 767px)': {
      paddingBottom: rem(20),
      width: '100vw',
    },
  },
  transactionsAction: {
    gap: 5,
    fontSize: rem(14),
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.colors.primary[2],
      svg: {
        fill: theme.colors.primary[2],
      },
    },
  },
  debitCardInfoContent: {
    display: 'flex',
    alignItems: 'center',
    span: {
      color: theme.primaryColor,
    },
    p: {
      margin: '0px',
      color: theme.colors.neutral[7],
    },
  },
  twoFactorContainer: {
    margin: `${rem(20)} 0`,
    '@media(max-width: 767px)': {
      margin: '0px',
    },
  },
  divider: {
    borderColor: theme.colors.neutral[2],
    marginTop: rem(20),
    marginBottom: rem(20),
  },
}));
