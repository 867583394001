import {
  AmountFilterValue,
  filterDirectionToLabel,
} from '@common/utilities/filter/amount-filter';
import { formatCurrency } from '@utilities/formatters';
import { useCheckDepositModalFilters } from './use-check-deposits-filters';
import { QuantityFilter } from '@common/utilities/filter/quantity-filter';

export const CheckDepositsAmountFilter = () => {
  const { addFilter, getFilterByKey } = useCheckDepositModalFilters();

  const handleChange = (val: AmountFilterValue) => {
    addFilter('amount', {
      label: `Amount - ${filterDirectionToLabel(
        val.direction,
      )} ${formatCurrency(val.amount)}`,
      key: 'amount',
      fn: (row) => {
        const filterNum = +val.amount;
        const rowAmount = Number(row.amount);
        switch (val.direction) {
          case 'greaterThan':
            return rowAmount > filterNum;
          case 'lessThan':
            return rowAmount < filterNum;
          case 'equal':
          default:
            return rowAmount === filterNum;
        }
      },
      filterValue: val,
      showChip: true,
    });
  };

  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={handleChange}
    />
  );
};
