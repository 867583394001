import ErrorPill from '@common/composites/error-pill';
import {
  Avatar,
  Box,
  Flex,
  Group,
  Loader,
  Paper,
  rem,
  Stack,
  Text,
} from '@mantine/core';
import { Line } from '@services/flexbase/credit.model';
import { formatCents, formatCurrency } from '@utilities/formatters';
import { formatPercentage } from '@utilities/formatters/format-percentage';
import { FlexIconShort } from 'assets/svg';
import { BillpayConfiguration } from 'types/bill-pay';

const FlexAvatar = () => (
  <Avatar radius="xl" w={rem(46)} h={rem(46)}>
    <FlexIconShort />
  </Avatar>
);

export const LineOfCreditSource = ({
  creditPrograms,
  creditProgramId,
  notEnoughCreditError,
  notEnoughFundsError,
  billpayConfiguration,
  isPendingBillpayConfiguration,
}: {
  creditPrograms: Line[];
  creditProgramId?: string;
  onCreditProgramChange: (creditProgramId: string) => void;
  notEnoughCreditError?: string;
  notEnoughFundsError?: string;
  billpayConfiguration: BillpayConfiguration;
  isPendingBillpayConfiguration: boolean;
}) => {
  const creditProgram = creditPrograms.find((cp) => cp.id === creditProgramId);
  const errorMessage = notEnoughCreditError || notEnoughFundsError;

  const netTerms = billpayConfiguration?.netTerms ?? 60;
  const formattedFeePercentage = formatPercentage(
    billpayConfiguration?.fundingFeePercentage ?? 0,
  );
  return (
    <Paper px="lg" py="md" withBorder>
      <Stack>
        <Group gap="sm">
          <Flex align="center" gap="sm" w="100%">
            <FlexAvatar />
            <Box>
              <Text size="sm" c="neutral.10">
                Pay later
              </Text>
              <Text size="sm" c="neutral.8" data-testid="loc-copy">
                Use your credit to pay your vendors
              </Text>
            </Box>
          </Flex>
          <Box style={{ alignSelf: 'flex-start' }}>
            {errorMessage && <ErrorPill message={errorMessage} />}
          </Box>
        </Group>
        {isPendingBillpayConfiguration ? (
          <Flex justify="center" align="center" h="100%" mb="md">
            <Loader />
          </Flex>
        ) : (
          <Flex gap="sm" direction="column">
            <Box>
              <Text size="sm" c="neutral.10" fw={500}>
                Available credit
              </Text>
              <Text size="sm" c="neutral.10">
                {formatCurrency(formatCents(creditProgram?.available || 0))}
              </Text>
            </Box>
            {billpayConfiguration?.fundingFeePercentage &&
              billpayConfiguration?.netTerms && (
                <Flex direction="column">
                  <Text size="sm" fw={500} c="neutral.10">
                    Repayment terms
                  </Text>
                  <Text size="sm">
                    {netTerms} days after the payment is sent
                  </Text>
                  <Text size="sm">
                    Up to {formattedFeePercentage} fee. Early repayment
                    qualifies for a lower fee amount.
                  </Text>
                </Flex>
              )}
          </Flex>
        )}
      </Stack>
    </Paper>
  );
};
