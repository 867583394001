import { ReactNode } from 'react';
import { Box, Button, Group, Stack, rem, useMantineTheme } from '@mantine/core';
import { PiPencilSimpleBold } from 'react-icons/pi';

type Props = {
  children: ReactNode;
  isEditMode?: boolean;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  isLoading?: boolean;
};
export const SummaryEditBox = ({
  children,
  isEditMode = false,
  onEditClick,
  onSaveClick,
  onCancelClick,
  isLoading,
}: Props) => {
  const theme = useMantineTheme();

  return (
    <Stack
      mih={rem(69)}
      bd={`1px solid ${theme.colors.neutral[4]}`}
      p="lg"
      bg={theme.colors.neutral[0]}
      style={{ borderRadius: theme.radius.xs }}
    >
      {children}
      {isEditMode ? (
        <Group ml="auto">
          <Button
            onClick={onCancelClick}
            variant="neutral-subtle"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSaveClick}
            variant="primary-light"
            loading={isLoading}
          >
            Save
          </Button>
        </Group>
      ) : (
        <Box ml="auto">
          <Button
            onClick={onEditClick}
            variant="primary-subtle"
            leftSection={
              <PiPencilSimpleBold
                width={16}
                height={16}
                color={theme.colors.primary[6]}
              />
            }
          >
            Edit
          </Button>
        </Box>
      )}
    </Stack>
  );
};
