import { AccountMaskCell } from '@common/table';
import {
  Avatar,
  Badge,
  Group,
  MantineTheme,
  rem,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import FlexbaseTable from 'components/table/flexbase-table';
import { useRecoilValue } from 'recoil';
import { IsComptroller } from 'recoil-state/application/product-onboarding';
import { PlaidAccount } from '../../../types/move-funds.model';
import { useStyles } from './external-accounts.styles';
import { formatCurrency } from '@utilities/formatters';
import { PiArrowsLeftRight, PiBank } from 'react-icons/pi';
import useModal from '@common/composites/modal/modal-hook';
import AddFunds from '../add-funds/add-funds';

const AddFundsColumnContent = () => {
  const { openRightModal } = useModal();

  const handleAddFundsClick = () => {
    openRightModal(<AddFunds />);
  };

  return (
    <Group onClick={handleAddFundsClick} gap={rem(4)}>
      <Text size="sm">Fund from account</Text>
      <PiArrowsLeftRight color={'neutral.8'} />
    </Group>
  );
};
type Styles = {
  tableCell: string;
  contentText: string;
  smallContentText: string;
};

const getColumnsSm = (classes: Styles) => {
  return [
    {
      name: 'Bank name',
      sortable: true,
      cell: (account: PlaidAccount) => (
        <div className={classes.tableCell}>
          <div className={classes.contentText}>{account.bankName}</div>
          <div className={classes.smallContentText}>
            <AccountMaskCell accountNumber={account.account} />
            {account.accountType || 'Plaid Account'}
          </div>
        </div>
      ),
      selector: (account: PlaidAccount) => account.bankName!,
      grow: 3,
    },
    {
      name: 'Routing number',
      sortable: true,
      cell: (account: PlaidAccount) => (
        <div className={classes.tableCell}>
          <div className={classes.contentText}>{account.routing}</div>
        </div>
      ),
    },
    {
      name: 'Available balance',
      cell: (account: PlaidAccount) => formatCurrency(account.available ?? 0),
      // omit: isUnlinkedAccount,
      omit: true,
    },
  ];
};

const getColumns = (
  classes: Styles,
  theme: MantineTheme,
  isComptroller: boolean,
) => {
  return [
    {
      name: 'Account',
      sortable: true,
      cell: (account: PlaidAccount) => (
        <Group>
          <Avatar>
            <PiBank size={'1.2rem'} fill={theme.primaryColor} />
          </Avatar>
          <div className={classes.tableCell}>
            <div className={classes.contentText}>{account.bankName}</div>
            <div className={classes.smallContentText}>
              {account.accountType || 'Plaid Account'}
            </div>
          </div>
        </Group>
      ),
      selector: (account: PlaidAccount) => account.bankName!,
      grow: 4,
    },
    {
      name: 'ACH/Routing number',
      cell: (account: PlaidAccount) => (
        <div className={classes.tableCell}>
          <div className={classes.contentText}>{account.routing}</div>
        </div>
      ),
      grow: 2,
    },
    {
      name: 'Account number',
      cell: (account: PlaidAccount) => (
        <AccountMaskCell accountNumber={account?.last4 ?? account.account} />
      ),
      grow: 2,
    },
    {
      name: 'Available balance',
      cell: (account: PlaidAccount) => formatCurrency(account.available ?? 0),
      // omit: isUnlinkedAccount,
      omit: true,
    },
    {
      name: '',
      cell: () => <AddFundsColumnContent />,
      omit: !isComptroller,
      grow: 2,
    },
    {
      name: '',
      cell: (account: PlaidAccount) => {
        return (
          account.unlinked && (
            <Badge
              styles={{
                root: {
                  backgroundColor: theme.colors.neutral[2],
                  color: theme.colors.neutral[10],
                },
              }}
            >
              Reconnect
            </Badge>
          )
        );
      },
    },
  ];
};

const getFilteredTableData = (
  filterText: string,
  tableData: PlaidAccount[],
) => {
  const normalizedFilterText = filterText.trim().toLowerCase();
  const sortedTableData = [...tableData]
    .sort((a, b) => a.bankName!.localeCompare(b.bankName!))
    .sort((a, b) => {
      if (a.bankName === b.bankName) {
        return a.accountType!.localeCompare(b.accountType!);
      }
      return 0;
    });

  return sortedTableData.filter((d) => {
    return (
      d.accountName?.toLowerCase().includes(normalizedFilterText) ||
      d.bankName?.toLowerCase().includes(normalizedFilterText) ||
      d.routing!.includes(normalizedFilterText)
    );
  });
};

type Props = {
  accounts: PlaidAccount[];
  searchText: string;
};

const ExternalAccountsTable = ({ accounts, searchText }: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const isComptroller = useRecoilValue(IsComptroller);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  // const isUnlinkedAccount = accounts.some((acc) => acc.unlinked);
  const columnsSm = getColumnsSm(classes);
  const columns = getColumns(classes, theme, isComptroller);
  const data = getFilteredTableData(searchText, accounts);

  return (
    <FlexbaseTable
      columns={isMobile ? columnsSm : columns}
      data={data}
      pagination={data.length > 8}
    />
  );
};

export default ExternalAccountsTable;
