import { Accordion, Box, Flex, rem, Text } from '@mantine/core';
import { useMatchMedia } from '@utilities/url/window-helpers';

const FAQBox = () => {
  const isMobileScreenSize = useMatchMedia('(max-width: 767px)');

  const FAQ = [
    {
      question: 'What types of services do you offer?',
      answer: 'Answer',
      value: 'services',
    },
    {
      question: 'How do you ensure privacy and confidentiality?',
      answer: 'Answer',
      value: 'privacy-confidentiality',
    },
    {
      question: 'How can I request a specific service?',
      answer: 'Answer',
      value: 'specific-service',
    },
    {
      question: 'Can you help with last-minute or emergency requests?',
      answer: 'Answer',
      value: 'emergency-requests',
    },
    {
      question:
        'Can you provide personalized shopping and gift recommendations?',
      answer: 'Answer',
      value: 'personalized-shopping',
    },
  ];
  return (
    <Box
      mt={24}
      mb={30}
      p={24}
      sx={(theme) => ({
        backgroundColor: '#F2F2F3',
        borderRadius: theme.radius.md,
      })}
    >
      <Flex
        direction={isMobileScreenSize ? 'column' : 'row'}
        justify="space-between"
      >
        <Text fz={20} fw={500}>
          {' '}
          Frequently asked questions{' '}
        </Text>
        <Text fz={rem(16)}> Still need help? Contact support </Text>
      </Flex>
      <Accordion defaultValue="customization" mt={24}>
        {FAQ.map((item) => {
          return (
            <Accordion.Item
              key={item.value}
              value={item.value}
              sx={(theme) => ({
                backgroundColor: theme.colors.neutral[0],
                borderRadius: theme.radius.md,
                border: 'none',
              })}
              mt={20}
            >
              <Accordion.Control>
                <Text fz={rem(16)} fw={600}>
                  {item.question}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>{item.answer}</Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Box>
  );
};

export default FAQBox;
