import TabTableLayout from '@common/layouts/tab-table-layout';
import {
  Badge,
  Box,
  Button,
  MantineTheme,
  rem,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import GenericError from '@common/generic-error';
import { FlexbaseTable } from 'components/table';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMonthDayYear, sortDate } from '@utilities/dates';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { stripCurrencySymbol } from 'utilities/formatters';
import PaymentHeader from './payment-right-header';
import { usePaymentFilters } from './use-payment-filters';
import { OverflowTooltip } from '@common/utilities/overflow-tooltip';
import PaymentDetailsModal from '@common/payment-details-modal';
import { TablePayment, generateRows } from '@utilities/payments-rows';
import { usePayments } from '@utilities/custom-hooks/use-payments';
import { TableColumn } from 'react-data-table-component';
import DisplayingPayAmount from '../common/displaying-pay-amount';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import PaymentLeftHeader from './payment-left-header';
import { CompanyIdState } from 'recoil-state/application/onboarding-form.state';

const selectAmountColumn = (row: { amount: string }) => {
  const parsedValue = stripCurrencySymbol(row.amount);
  return Number(parsedValue);
};

// configure columns for rows
const getColumns = (
  theme: MantineTheme,
  isAccountPaymentsDetailsPage: boolean,
): TableColumn<TablePayment>[] => {
  return [
    {
      name: 'Created date',
      selector: (row) => row.createdAt,
      format: (row) => getMonthDayYear(row.createdAt),
      sortable: true,
      sortFunction: sortDate,
      grow: 1,
    },
    {
      name: 'Recipient',
      selector: (row) => formatApiStrings(row.recipient) || '',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Amount',
      selector: selectAmountColumn,
      cell: (row) => DisplayingPayAmount({ payment: row, useTagDelta: true }),
      format: (row) => row.amount,
      sortable: true,
      grow: isAccountPaymentsDetailsPage ? 0.7 : 1.4,
    },
    {
      name: 'Type',
      selector: (row) => formatApiStrings(row.type),
      sortable: true,
      grow: isAccountPaymentsDetailsPage ? 1 : 2,
    },
    {
      name: 'Sender',
      selector: (row) => row.requestedBy ?? 'N/A',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Account',
      cell: (row) => <OverflowTooltip text={row.accountName} />,
      grow: 2,
      selector: (row) => row.accountName,
      sortable: true,
      omit: isAccountPaymentsDetailsPage,
    },
    {
      name: 'Status',
      cell: (row) => {
        let backgroundColor, textColor;

        switch (row.status) {
          case 'Pending':
            backgroundColor = theme.colors.promote[0];
            textColor = theme.colors.promote[5];
            break;
          case 'Approval Needed':
          case '2FA Required':
            backgroundColor = theme.colors.warning[0];
            textColor = theme.colors.warning[5];
            break;
          case 'Completed':
            backgroundColor = theme.colors.sage[1];
            textColor = theme.colors.sage[6];
            break;
          case 'Failed':
          case 'Canceled':
          case 'Denied':
            backgroundColor = theme.colors.critical[0];
            textColor = theme.colors.critical[4];
            break;
          default:
            backgroundColor = theme.colors.neutral[2];
            textColor = theme.colors.neutral[10];
        }

        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text c={textColor}>{formatApiStrings(row.status)} </Text>
          </Badge>
        );
      },
    },
  ];
};

const getColumnsSm = (
  theme: MantineTheme,
  isAccountPaymentsDetailsPage: boolean,
): TableColumn<TablePayment>[] => {
  return [
    {
      width: rem(130),
      sortable: true,
      name: 'Payment Details',
      selector: (row) => formatApiStrings(row.recipient) || '',
      cell: (row) => (
        <Box>
          <Text size="sm">{formatApiStrings(row.recipient)}</Text>
          <Text size="xs">{row.dateSubmitted}</Text>
        </Box>
      ),
    },
    {
      name: 'Amount',
      selector: selectAmountColumn,
      cell: (row) => DisplayingPayAmount({ payment: row, useTagDelta: true }),
      format: (row) => row.amount,
      sortable: true,
      compact: true,
      width: rem(130),
    },
    {
      name: 'Type',
      selector: (row) => formatApiStrings(row.type),
      sortable: true,
      compact: true,
      width: rem(150),
    },
    {
      name: 'Sender',
      selector: (row) => row.requestedBy ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Account',
      selector: (row) => row.accountName,
      sortable: true,
      width: rem(200),
      omit: isAccountPaymentsDetailsPage,
    },
    {
      name: 'Status',
      cell: (row) => {
        let backgroundColor, textColor;

        switch (row.status) {
          case 'Pending':
            backgroundColor = theme.colors.promote[0];
            textColor = theme.colors.promote[5];
            break;
          case 'Approval Needed':
          case '2FA Required':
            backgroundColor = theme.colors.warning[0];
            textColor = theme.colors.warning[5];
            break;
          case 'Completed':
            backgroundColor = theme.colors.sage[1];
            textColor = theme.colors.sage[6];
            break;
          case 'Failed':
          case 'Canceled':
          case 'Denied':
            backgroundColor = theme.colors.critical[0];
            textColor = theme.colors.critical[4];
            break;
          default:
            backgroundColor = theme.colors.neutral[2];
            textColor = theme.colors.neutral[10];
        }

        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{formatApiStrings(row.status)} </Text>
          </Badge>
        );
      },
    },
  ];
};

// Banking Payments in table format
const PaymentsTable = () => {
  const location = useLocation();
  const { pathname } = location;
  const isAdmin = useRecoilValue(IsAdmin);
  const companyId = useRecoilValue(CompanyIdState);
  const { id, paymentId = '', recipientId } = useParams();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { activeFiltersArray } = usePaymentFilters();

  const isAccountPaymentsDetailsPage = pathname.includes(
    '/banking/account-details/',
  );

  const accountId = isAccountPaymentsDetailsPage ? id : '';

  const params = {
    companyId,
    ...(accountId && { depositId: accountId }),
    ...(recipientId && { recipientId }),
  };
  const {
    users,
    payments,
    intlPayments,
    depositAccounts,
    isError,
    loading,
    refetch,
  } = usePayments(params);

  const columnsSm = getColumnsSm(theme, isAccountPaymentsDetailsPage);
  const columns = getColumns(theme, isAccountPaymentsDetailsPage);

  const filteredPayments = generateRows({
    users,
    payments,
    intlPayments,
    depositAccounts,
    accountId,
  }).filter((t) => activeFiltersArray.every((f) => f.fn(t)));

  const openPayment = filteredPayments.find((row) => row.id === paymentId);

  if (isError) {
    return (
      <GenericError>
        <p>{'Something went wrong'}</p>
        <Button variant="primary-light" onClick={() => refetch()} mt={rem(20)}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <>
      {openPayment && <PaymentDetailsModal openPayment={openPayment} />}
      <TabTableLayout
        leftHeaderContent={
          <PaymentLeftHeader paymentsData={filteredPayments} />
        }
        borderBottomSolid={false}
        rightHeaderContent={isAdmin && <PaymentHeader />}
        table={
          <FlexbaseTable
            columns={isMobile ? columnsSm : columns}
            data={filteredPayments}
            pagination={filteredPayments && filteredPayments?.length > 8}
            onRowClicked={(row) => navigate(`${row.id}`)}
            isFetchingData={loading}
            noDataComponent={
              <Text fz={rem(24)} fw={500} mt="lg">
                No payments available for this filter
              </Text>
            }
          />
        }
      />
    </>
  );
};

export default PaymentsTable;
