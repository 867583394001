import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { platformClient } from '../services/platform/platform-client';
import { Transactions } from '@services/flexbase/flexbase-onboarding-client';

const QUERY_KEY = 'credit_transactions';

type ApiTrueFalse = 'true' | 'false';
type GetCreditTransactionParams = {
  after: string;
  before: string;
  sort: 'desc' | 'asc';
  noInterest: ApiTrueFalse;
  inclReversed: ApiTrueFalse;
  inclExpired: ApiTrueFalse;
  includeChargeCard: ApiTrueFalse;
  inclDisputed: ApiTrueFalse;
  includeLithicCard: ApiTrueFalse;
  lineOfCreditId?: string;
};

type GetCreditTransactionsProps = {
  params?: Partial<GetCreditTransactionParams>;
  spendPlansEnabled?: boolean;
};

export type CreditTransactionsOverviewModel = {
  beginningBalance: number;
  beginningChargeCardBalance: number;
  businessId: string;
  endingBalance: number;
  endingChargeCardBalance: number;
  fromDate: string;
  overview: {
    totalPurchases: string;
    creditsRefunds: string;
    fees: string;
    interest: string;
    totalBalance: string;
    payments: string;
    total: string;
  };
  toDate: string;
  transactions: Transactions[];
};

async function getTransactionSpendPlanNameMap(
  accountId: string,
  transactions: Transactions[],
) {
  const spendNameMap: Record<string, string> = {};
  const transactionsIds = transactions.map((tx) => tx.id);

  try {
    const spendPlanNameTransactions =
      await platformClient.getSpendPlanNameTransactions(
        accountId,
        transactionsIds,
      );

    spendPlanNameTransactions.forEach((item) => {
      if (item.spendPlanId && item.spendPlanName) {
        spendNameMap[item.invoiceId] = item.spendPlanName;
      }
    });
  } catch (e) {
    // swallow error
    console.error('Unable to fetch spend plans for transactions', e);
  }

  return spendNameMap;
}

async function getCreditTransactions(
  accountId: string,
  props: GetCreditTransactionsProps,
): Promise<CreditTransactionsOverviewModel> {
  const result = await platformClient.getTransactionOverview(accountId, {
    params: {
      inclReversed: 'true',
      inclExpired: 'true',
      inclDisputed: 'true',
      sort: 'desc',
      ...props.params,
    },
  });

  let newTransactions = result.transactions ?? [];

  if (props.spendPlansEnabled && newTransactions.length) {
    const spendNameMap = await getTransactionSpendPlanNameMap(
      accountId,
      newTransactions,
    );

    newTransactions = newTransactions.map((transaction) => {
      if (spendNameMap[transaction.id]) {
        transaction.spendName = spendNameMap[transaction.id];
      }
      return transaction;
    });
  }

  return {
    ...result,
    beginningBalance: result.beginningBalance ?? 0,
    beginningChargeCardBalance: result.beginningChargeCardBalance ?? 0,
    endingBalance: result.endingBalance ?? 0,
    endingChargeCardBalance: result.endingChargeCardBalance ?? 0,
    transactions: newTransactions,
  };
}

function linkInvoice({
  accountId,
  spendPlanId,
  invoiceId,
}: {
  accountId: string;
  spendPlanId: string;
  invoiceId: string;
}) {
  return platformClient.linkInvoiceToSpendPlan(
    accountId,
    spendPlanId,
    invoiceId,
  );
}

const creditTransactionsOptions = (
  accountId: string,
  props: GetCreditTransactionsProps = {},
) => {
  return queryOptions({
    queryKey: [QUERY_KEY, accountId, props],
    queryFn: () => getCreditTransactions(accountId, props),
    meta: {
      errorMessage: 'Failed to retrieve transactions',
    },
    enabled: !!accountId,
  });
};

export const useNet60MarqetaTransactions = (
  accountId: string,
  props: GetCreditTransactionsProps = {},
) => {
  const options = creditTransactionsOptions(accountId, {
    ...props,
    params: {
      ...props.params,
      includeLithicCard: 'false',
      includeChargeCard: 'false',
    },
  });

  return useQuery({
    ...options,
    enabled: options.enabled && !!props.params?.lineOfCreditId,
    queryKey: [QUERY_KEY, 'net60_cards', 'marqeta', accountId, props],
  });
};

export const useNet60CreditTransactions = (
  accountId: string,
  props: GetCreditTransactionsProps = {},
) => {
  const options = creditTransactionsOptions(accountId, {
    ...props,
    params: {
      ...props.params,
      includeLithicCard: 'true',
      includeChargeCard: 'false',
    },
  });

  return useQuery({
    ...options,
    queryKey: [QUERY_KEY, 'net60_cards', 'lithic', accountId, props],
  });
};

export const useChargeCardTransactions = (
  accountId: string,
  props: GetCreditTransactionsProps = {},
) => {
  const options = creditTransactionsOptions(accountId, {
    ...props,
    params: {
      ...props.params,
      includeChargeCard: 'true',
      includeLithicCard: 'false',
    },
  });

  return useQuery({
    ...options,
    queryKey: [QUERY_KEY, 'charge_cards', accountId, props],
  });
};

export const uselinkInvoiceToSpendPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: linkInvoice,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
    },
  });
};
