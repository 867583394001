import { Text, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PiUploadSimple } from 'react-icons/pi';

const DropzoneChildren = () => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.dropZonePlaceholderContainer}>
      <PiUploadSimple size={'1.25rem'} color={theme.colors.neutral[6]} />
      <Text c="neutral.8" className={classes.dropZonePlaceholder}>
        Drag and drop file, or&nbsp;<span>browse</span>&nbsp;to add your
        document
      </Text>
      <Text size="xs" c="neutral.7">
        .pdf, .jpg, .png
      </Text>
    </div>
  );
};

export default DropzoneChildren;

const useStyles = createStyles((theme) => ({
  dropZonePlaceholderContainer: {
    gap: rem(8),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dropZonePlaceholder: {
    '> span': {
      display: 'inline',
      color: theme.colors.primary[2],
    },
  },
}));
