import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  card: {
    alignItems: 'flex-start',
    backgroundColor: theme.colors.neutral[0],
    borderColor: theme.colors.neutral[4],
    borderRadius: theme.defaultRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    color: theme.colors.neutral[7],
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontFamily: theme.fontFamily,
    gap: theme.spacing.xl,
    height: '100%',
    padding: theme.spacing.xl,
    width: '100%',
    minHeight: 104,
  },
  cardBody: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontFamily: theme.fontFamily,
    gap: theme.spacing.md,
    height: '100%',
    width: '100%',
    minHeight: 104,
  },
  cardContent: {
    flexGrow: 1,
    height: '100%',
  },
  cardTitleRow: {
    alignItems: 'center',
    marginBottom: theme.spacing.md,
  },
  cardTitle: {
    alignItems: 'center',
    color: theme.colors.primary[8],
    display: 'flex',
    flexGrow: 1,
    fontFamily: theme.fontFamily,
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: '2rem',
  },
  cardTag: {
    borderRadius: 4,
    flexGrow: 0,
    fontSize: rem(12),
    fontWeight: 400,
    lineHeight: rem(16),
    padding: `${rem(2)} ${rem(6)}`,
    whiteSpace: 'nowrap',
  },
  cardTagNeutral: {
    backgroundColor: theme.colors.neutral[2],
    color: theme.colors.neutral[8],
  },
  cardTagSuccess: {
    backgroundColor: theme.colors.primary[0],
    color: theme.colors.primary[6],
  },
  cardDescriptionRow: {
    flexGrow: 1,
  },
  cardDescription: {
    fontSize: 14,
    fontWeight: 50,
  },
  disabled: {
    opacity: '50%',
    pointerEvents: 'none',
  },
  confirmDisconnect: {
    background: theme.colors.critical[2],
    borderColor: theme.colors.critical[2],
    '&:not([data-disabled])&:hover': {
      backgroundColor: theme.colors.critical[2],
    },
  },
}));
