import { PiFunnelBold, PiMagnifyingGlass } from 'react-icons/pi';
import {
  Box,
  Flex,
  Group,
  Button,
  TextInput,
  useMantineTheme,
  rem,
} from '@mantine/core';
import {
  useBankingTransactionsFilters,
  useBankingTransactionsModalFilters,
} from '../use-banking-transactions-filters';
import { FilterChips } from '@common/composites/filter-chips';
import { useFilterModal } from '@common/utilities/filter/filter-modal';
import { Transaction } from '@services/flexbase/banking.model';
import { BankingTransactionsAccountFilter } from '../filters/banking-account-filter';
import { BankingTransactionsTypeFilter } from '../filters/banking-transactions-type.filter';
import { BankingTransactionsAmountFilter } from '../filters/banking-transactions-amount-filter';
import { BankingTransactionsDateRangeFilter } from '../filters/banking-transactions-date-range.filter';

export type BankingTransactionsHeaderProps = {
  accountId?: string;
  transactions: Transaction[];
  downloadCSV: (array: any) => void;
};

const BankingTransactionsHeader = ({
  accountId,
  downloadCSV,
  transactions,
}: BankingTransactionsHeaderProps) => {
  const theme = useMantineTheme();
  const filterHook = useBankingTransactionsFilters();
  const modalFilterHook = useBankingTransactionsModalFilters();
  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const filters = [
    {
      key: 'type',
      header: 'Type',
      component: <BankingTransactionsTypeFilter />,
    },
    {
      key: 'amount',
      header: 'Amount',
      component: <BankingTransactionsAmountFilter />,
    },
    {
      key: 'date',
      header: 'Date',
      component: <BankingTransactionsDateRangeFilter />,
    },
  ];

  if (!accountId) {
    filters.splice(1, 0, {
      key: 'accountName',
      header: 'Account name',
      component: (
        <BankingTransactionsAccountFilter transactions={transactions} />
      ),
    });
  }

  const searchFilterValue = getFilterByKey('search')?.filterValue;

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();

        const strValues = Object.values(transaction).filter(
          (v) => !!v && typeof v === 'string',
        ) as string[];

        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleOpenFilters = () => {
    openResponsiveFilterModal(filters);
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  return (
    <Box>
      <Group justify="space-between" mb="md">
        <Flex gap="md">
          <TextInput
            w={rem(370)}
            value={searchFilterValue}
            placeholder="Search transactions"
            onChange={(e) => handleSearchChange(e.target.value)}
            leftSection={<PiMagnifyingGlass color={theme.colors.neutral[6]} />}
          />
          <Button
            variant="neutral-outline"
            rightSection={<PiFunnelBold />}
            onClick={handleOpenFilters}
          >
            Filters
          </Button>
        </Flex>
        <Button onClick={downloadCSV} variant="primary-filled">
          Download CSV
        </Button>
      </Group>
      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </Box>
  );
};

export default BankingTransactionsHeader;
