import { TextInput } from '@mantine/core';
import { SummaryEditInput } from './summary-edit-input';
import { CompanyInfoFormValues } from '../summary';
import { UseFormReturnType } from '@mantine/form';

type Props = {
  value: string;
  form: UseFormReturnType<CompanyInfoFormValues>;
  isEditMode: boolean;
};

const SummaryBusinessNameForm = ({ value, form, isEditMode }: Props) => {
  return (
    <SummaryEditInput
      label={'Business name'}
      value={value}
      isEditMode={isEditMode}
      editInputs={
        <TextInput
          placeholder="Business name"
          label="Business name"
          {...form.getInputProps('companyName')}
          id="summary-business-name"
        />
      }
    />
  );
};

export default SummaryBusinessNameForm;
