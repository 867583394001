import { Alert, Group, Text, useMantineTheme } from '@mantine/core';
import { PlaidRelinkButton } from './plaid-relink-button';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ApplicationState,
  getProductOnboardingStatus,
  IsPlaidStale,
} from '../../../../recoil-state/application/product-onboarding';
import { uniq } from 'underscore';
import { useEffect, useState } from 'react';
import { PiWarning } from 'react-icons/pi';

export const CreditActionAlertBar = () => {
  const theme = useMantineTheme();
  const [{ company, user }, setApplicationState] =
    useRecoilState(ApplicationState);
  const isPlaidStale = useRecoilValue(IsPlaidStale);
  const [reason, setReason] = useState('');
  const [showPlaidButton, setShowPlaidButton] = useState(false);
  const [dismissable, setDismissable] = useState(false);

  const onPlaidLinkingComplete = async () => {
    const status = await getProductOnboardingStatus(false);
    setApplicationState(status);
  };

  useEffect(() => {
    if (company.frozenReason) {
      switch (company.frozenReason) {
        case 'accountDelinquent':
          setReason(
            'Your account is frozen because it is past due. Please make a repayment for the amount due to unfreeze your account or contact support at (415)-840-8721.',
          );
          break;
        case 'plaidUnlinked':
        case 'plaidDropped': {
          if (!user.roles.includes('PLAID-STALE')) {
            setShowPlaidButton(false);
            setReason(
              'Please contact support to finish unfreezing your account by calling this number: (415)-840-8721.',
            );
          } else {
            setShowPlaidButton(true);
            setReason(
              'Your account was frozen because a linked account was deactivated or unlinked. To unfreeze, link a new account and contact support at the provided number.',
            );
          }

          break;
        }
        case 'deniedBanking':
        case 'byServicer':
        case 'unknown':
          setReason(
            'Flex has frozen your account. Please contact support at (415)-840-8721 to learn more.',
          );
          break;
      }
    } else if (isPlaidStale) {
      setShowPlaidButton(true);
      const hasNoAccounts = company.financialInstitutions.length === 0;
      if (hasNoAccounts) {
        setReason(
          'You have no linked bank accounts. To continue using Flex, you must have at least 1 linked account.',
        );
      } else {
        const uniqueUnlinkedInsitutions = uniq(
          company.financialInstitutions
            .filter((f) => f.unlinked)
            .map((f) => f.institutionId),
        ).length;

        const hasLinkedAccount = !user.roles.includes('PLAID-STALE');

        if (!hasLinkedAccount) {
          setReason(
            `You have ${uniqueUnlinkedInsitutions || 'an'} unlinked bank account${
              uniqueUnlinkedInsitutions > 1 ? 's' : ''
            }. To continue using Flex, you must have at least 1 linked account.`,
          );
          setDismissable(false);
        }
      }
    } else {
      setReason('');
      setShowPlaidButton(false);
    }
  }, [company, isPlaidStale]);

  return reason ? (
    <Alert
      withCloseButton={dismissable}
      icon={<PiWarning size={'1.5rem'} color={theme.colors.critical[2]} />}
      color="warn"
      mb="xl"
      styles={() => ({
        wrapper: { alignItems: 'center' },
        closeButton: {
          marginLeft: theme.spacing.md,
          color: theme.colors.neutral[8],
        },
      })}
      onClose={() => setReason('')}
    >
      <Group justify="space-between">
        <Text fz={16} c="neutral.8">
          {reason}
        </Text>
        {showPlaidButton && (
          <PlaidRelinkButton
            buttonLabel="Re-link now"
            onLinkingCompleted={onPlaidLinkingComplete}
            buttonProps={{
              variant: 'outline',
              size: 'xs',
              c: 'neutral.8',
            }}
          />
        )}
      </Group>
    </Alert>
  ) : (
    false
  );
};
