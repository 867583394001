import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  tabsContainer: {
    marginBottom: theme.spacing.xl,
    width: 'fit-content',
  },
  summaryContinueButton: {
    maxWidth: '230px',
  },
  buttonContainer: {
    marginTop: '32px',
    justifyContent: 'space-between',
  },
}));
