import { ComboboxItem } from '@mantine/core';

export const oldCategories: ComboboxItem[] = [
  {
    label: 'Material suppliers',
    value: 'MATERIALSUPPLIERS',
  },
  {
    label: 'Convenience stores',
    value: 'CONVENIENCESTORES',
  },
  {
    label: 'Food and beverage',
    value: 'FOODANDBEVERAGE',
  },
  {
    label: 'Equipment',
    value: 'EQUIPMENT',
  },
  {
    label: 'Fuel and Gas',
    value: 'FUELANDGAS',
  },
  {
    label: 'Office supplies and cleaning',
    value: 'OFFICESUPPLIESANDCLEANING',
  },
];

export const newCategories: ComboboxItem[] = [
  {
    label: 'Advertising',
    value: 'ADVERTISING',
  },
  {
    label: 'Airlines',
    value: 'AIRLINES',
  },
  {
    label: 'Alcohol and bars',
    value: 'ALCOHOLANDBARS',
  },
  {
    label: 'Antiques, pawn, trinket and thriftshops',
    value: 'ANTIQUESPAWNTRINKETANDTHRIFTSHOPS',
  },
  {
    label: 'Books and newspapers',
    value: 'BOOKSANDNEWSPAPERS',
  },
  {
    label: 'Car rental',
    value: 'CARRENTAL',
  },
  {
    label: 'Cleaning supplies',
    value: 'CLEANINGSUPPLIES',
  },
  {
    label: 'Clothing',
    value: 'CLOTHING',
  },
  {
    label: 'Clubs and memberships',
    value: 'CLUBSANDMEMBERSHIPS',
  },
  {
    label: 'Construction contractors',
    value: 'CONSTRUCTIONCONTRACTORS',
  },
  {
    label: 'Construction materials',
    value: 'CONSTRUCTIONMATERIALS',
  },
  {
    label: 'Dealerships',
    value: 'DEALERSHIPS',
  },
  {
    label: 'Education',
    value: 'EDUCATION',
  },
  {
    label: 'Charities',
    value: 'CHARITIES',
  },
  {
    label: 'Electronics',
    value: 'ELECTRONICS',
  },
  {
    label: 'Entertainment',
    value: 'ENTERTAINMENT',
  },
  {
    label: 'Financial Institutions',
    value: 'FINANCIALINSTITUTIONS',
  },
  {
    label: 'Fines',
    value: 'FINES',
  },
  {
    label: 'Fuel and Gas',
    value: 'FUELANDGAS',
  },
  {
    label: 'Florists',
    value: 'FLORISTS',
  },
  {
    label: 'Freight, moving and delivery services',
    value: 'FREIGHTMOVINGANDDELIVERYSERVICES',
  },

  {
    label: 'Gambling',
    value: 'GAMBLING',
  },
  {
    label: 'General merchandise',
    value: 'GENERALMERCHANDISE',
  },
  {
    label: 'Government services',
    value: 'GOVERNMENTSERVICES',
  },
  {
    label: 'Home furnishings',
    value: 'HOMEFURNISHINGS',
  },
  {
    label: 'Hotel sandlodging',
    value: 'HOTELSANDLODGING',
  },
  {
    label: 'Insurance',
    value: 'INSURANCE',
  },
  {
    label: 'Internet and phone',
    value: 'INTERNETANDPHONE',
  },
  {
    label: 'Intra company purchases',
    value: 'INTRACOMPANYPURCHASES',
  },
  {
    label: 'Jewelry',
    value: 'JEWELRY',
  },
  {
    label: 'Landscaping',
    value: 'LANDSCAPING',
  },
  {
    label: 'Legal services',
    value: 'LEGALSERVICES',
  },
  {
    label: 'Mechanics and auto supplies',
    value: 'MECHANICSANDAUTOSUPPLIES',
  },
  {
    label: 'Medical',
    value: 'MEDICAL',
  },
  {
    label: 'Office supplies',
    value: 'OFFICESUPPLIES',
  },
  {
    label: 'Parking',
    value: 'PARKING',
  },
  {
    label: 'Pet',
    value: 'PET',
  },
  {
    label: 'Political Organizations',
    value: 'POLITICALORGANIZATIONS',
  },
  {
    label: 'Professional services',
    value: 'PROFESSIONALSERVICES',
  },
  {
    label: 'Religious organizations',
    value: 'RELIGIOUSORGANIZATIONS',
  },
  {
    label: 'Restaurants',
    value: 'RESTAURANTS',
  },
  {
    label: 'SAAS Software',
    value: 'SAASSOFTWARE',
  },
  {
    label: 'Sporting good stores',
    value: 'SPORTINGGOODSTORES',
  },
  {
    label: 'Storage',
    value: 'STORAGE',
  },
  {
    label: 'Streaming services and cable',
    value: 'STREAMINGSERVICESANDCABLE',
  },
  {
    label: 'Supermarket and grocery stores',
    value: 'SUPERMARKETSANDGROCERYSTORES',
  },
  {
    label: 'Taxes accounting',
    value: 'TAXESACCOUNTING',
  },
  {
    label: 'Taxi ride share',
    value: 'TAXIRIDESHARE',
  },
  {
    label: 'Tours, museums, amusement and travel agencies',
    value: 'TOURSMUSEUMSAMUSEMENTANDTRAVELAGENCIES',
  },
  {
    label: 'Transportation services',
    value: 'TRANSPORTATIONSERVICES',
  },
  {
    label: 'Travel misc',
    value: 'TRAVELMISC',
  },
  {
    label: 'Utilities',
    value: 'UTILITIES',
  },
];
