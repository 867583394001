import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { isFuturePayment } from './dates';
import {
  CreateWireMoneyMovement,
  CreateACHMoneyMovement,
} from '@services/flexbase/banking.model';

export type PayInvoiceParams = {
  scheduledForDate: Date;
  sourceAccountId?: string;
  invoiceMemo: string;
  recipientPaymentMethod: string;
  amount: string;
  recipientId: string;
  notes?: string;
  billpayInvoicesId: string;
  creditLineId?: string;
};

export const buildMakePaymentParams = ({
  amount,
  notes,
  invoiceMemo,
  recipientId,
  creditLineId,
  sourceAccountId,
  scheduledForDate,
  billpayInvoicesId,
  recipientPaymentMethod,
}: PayInvoiceParams) => {
  const idempotencyToken = uuidv4();
  const formattedScheduledFor =
    DateTime.fromJSDate(scheduledForDate).toISODate()?.toString() ?? '';
  const scheduledFor = isFuturePayment(formattedScheduledFor)
    ? scheduledForDate
    : null;

  const commonParams = {
    idempotencyToken,
    direction: 'Credit',
    description: invoiceMemo,
    notes,
    scheduledFor,
    billpayInvoicesId,
    ...(sourceAccountId && { accountId: sourceAccountId }),
    ...(creditLineId && { creditLineId }),
  };

  const domesticPaymentParams = {
    ...commonParams,
    type: recipientPaymentMethod,
    amount,
    counterpartyId: recipientId,
  } as CreateWireMoneyMovement | CreateACHMoneyMovement;

  return { domesticPaymentParams };
};
