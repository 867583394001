import {
  NavigateFunction,
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';

type MarketingParams = {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  referral: string;
};

/**
 * This hook returns a string of the marketing search params that should be preserved across navigation changes.
 * This can be used to assemble a query string that can be used to navigate to a new page with the same marketing params.
 **/
const useMarketingSearchString = (): string => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: Partial<MarketingParams> = {};
  [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'referral',
    'prefill',
  ].forEach((param) => {
    const value = searchParams.get(param);
    if (value) {
      params[param as keyof MarketingParams] = value;
    }
  });

  return `?${createSearchParams(params)}`;
};

/**
 * This hook returns a navigate function that preserves the marketing search params.
 * Use this in place of the default useNavigate hook when you want to preserve the marketing search params.
 **/
const useMarketingNavigate = (): NavigateFunction => {
  const navigate = useNavigate();
  const marketingSearchString = useMarketingSearchString();

  const navigateWithMarketing: NavigateFunction = (
    to: To | number,
    options: NavigateOptions = {},
  ) => {
    if (typeof to === 'string') {
      navigate({ pathname: to, search: marketingSearchString }, options);
    } else if (typeof to === 'number') {
      navigate(to);
    } else {
      const passedSearchParams = new URLSearchParams(to.search || '');
      const marketingParams = new URLSearchParams(marketingSearchString);

      // Combine both search params, with marketing params taking precedence
      for (const [key, value] of marketingParams.entries()) {
        passedSearchParams.set(key, value);
      }

      const combinedSearch = `?${passedSearchParams.toString()}`;
      navigate({ ...to, search: combinedSearch }, options);
    }
  };

  return navigateWithMarketing;
};

export { useMarketingSearchString, useMarketingNavigate };
