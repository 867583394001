import { UseFormReturnType } from '@mantine/form';
import { TextInput } from '@mantine/core';
import { SummaryEditInput } from './summary-edit-input';
import { CompanyInfoFormValues } from '../summary';

type Props = {
  value: string;
  form: UseFormReturnType<CompanyInfoFormValues>;
  isEditMode: boolean;
};

const SummaryWebsiteForm = ({ value, form, isEditMode }: Props) => {
  return (
    <SummaryEditInput
      label="Website"
      value={value}
      isEditMode={isEditMode}
      editInputs={
        <TextInput
          placeholder="Company website (eg www.flex.one)"
          label="Website"
          {...form.getInputProps('website')}
          id="summary-website"
        />
      }
    />
  );
};

export default SummaryWebsiteForm;
