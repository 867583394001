import {
  Box,
  Collapse,
  ComboboxItem,
  Group,
  Radio,
  Stack,
  Title,
  useMantineTheme,
  Select,
  TextInput,
  NumberInput,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useMemo } from 'react';
import { Group as CardInfoGroup } from 'constants/card-info';
import { CustomMantineStyles } from '@common/cards-styles';
import { CreditCardInterval, LIMIT_INTERVALS } from 'constants/limit-intervals';
import { CalendarIcon } from '../assets/svg';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { DateTime } from 'luxon';
import CardCategorySelect from '@common/composites/card-category-select';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import Multiselect from '@common/custom-multiselect';
import { useGetUsers } from '@queries/use-users';
import { NumberFormatValues } from 'react-number-format';
import { Corporeality } from '@flexbase-eng/sdk-typescript/models/components';
import { Employees } from 'types/onboarding-info';

type YesNo = 'yes' | 'no';

type IssueLithicCreditCardFormType = {
  userId: string;
  cardType: Corporeality;
  cardName: string;
  spendingLimit: number;
  spendingLimitInterval: CreditCardInterval | undefined;
  groups: CardInfoGroup[];
  categoryId?: string;
  terminateAt: string | null;
  limitMerchantCategories: YesNo;
  autoExpire: YesNo;
};

const STACK_SPACING = 'lg';
const RADIO_SPACING = 'lg';

type IssueLithicCreditCardFormProps = {
  form: UseFormReturnType<IssueLithicCreditCardFormType>;
  title?: React.ReactNode;
  disabled?: boolean;
  preissue?: boolean;
};

export const useIssueLithicCreditCardForm =
  useForm<IssueLithicCreditCardFormType>;

const useTeamMemberOptions = (users: Employees[] | undefined) => {
  return useMemo<ComboboxItem[]>(() => {
    // check if still fetching data
    if (!users) {
      return [];
    }

    // only consider active onboarded users
    const activeUsers = users.filter(
      (u) =>
        u.completedOnboarding &&
        !u.completedOffboarding &&
        u.status === 'active',
    );

    // map to select options
    const availableUsers: ComboboxItem[] = activeUsers.map((u) => ({
      value: u.id!,
      label: `${u.firstName} ${u.lastName}`,
    }));

    return availableUsers;
  }, [users]);
};

const useMinimumSuspendDate = () => {
  return DateTime.now().plus({ days: 1 }).toJSDate();
};

export const IssueLithicCreditCardForm = ({
  form,
  title,
  disabled,
  preissue = false,
}: IssueLithicCreditCardFormProps) => {
  const theme = useMantineTheme();
  const { data: users } = useGetUsers();
  const { expenseLink } = useActiveExpenseLink();

  const teamMemberOptions = useTeamMemberOptions(users);
  const minimumTerminationDate = useMinimumSuspendDate();

  const handleFrequencyChange = (e: string | null) => {
    form.setFieldValue('spendingLimit', 0);

    if (e) {
      form.setFieldValue('spendingLimitInterval', e as CreditCardInterval);
    }
  };

  const handleLimitAmountChange = (value: NumberFormatValues) => {
    form.setFieldValue('spendingLimit', value.floatValue ?? 0);
  };

  const handleTerminationDateChange = (d: DateValue) => {
    if (d) {
      const date = DateTime.fromJSDate(d);
      form.setFieldValue('terminateAt', date.toFormat('yyyy-MM-dd'));
    } else {
      form.setFieldValue('terminateAt', null);
    }
  };

  return (
    <Stack gap={STACK_SPACING}>
      {!!title && (
        <Title
          style={{
            textAlign: 'start',
            fontSize: '28px',
            fontFamily: 'PP Neue Montreal',
            fontWeight: 400,
          }}
        >
          {title}
        </Title>
      )}

      {!preissue && (
        <Select
          label="Team member"
          searchable
          data={teamMemberOptions}
          radius={8}
          maxDropdownHeight={280}
          data-testid="select-employee"
          placeholder="Select employee"
          disabled={disabled}
          {...form.getInputProps('userId')}
        />
      )}

      <TextInput
        label="Card purpose"
        data-testid="card-name"
        placeholder="e.g. Advertising expenses"
        disabled={disabled}
        {...form.getInputProps('cardName')}
      />

      {!preissue && (
        <Radio.Group
          label="Card type"
          size="sm"
          {...form.getInputProps('cardType')}
          color={theme.primaryColor}
          aria-label="card-type"
        >
          <Group mt="xxs" gap={RADIO_SPACING}>
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="physical-card"
              value="physical"
              label="Physical"
            />
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="virtual-card"
              value="virtual"
              label="Virtual"
            />
          </Group>
        </Radio.Group>
      )}

      <Select
        label="Limit frequency"
        placeholder="Select frequency"
        data={LIMIT_INTERVALS}
        radius={8}
        maxDropdownHeight={280}
        data-testid="select-limit-type"
        disabled={disabled}
        {...form.getInputProps('spendingLimitInterval')}
        onChange={handleFrequencyChange}
      />

      {form.values.spendingLimitInterval !== 'unlimited' && (
        <NumberInput
          hideControls
          label="Limit"
          variant="default"
          radius={8}
          leftSection="$"
          pattern="[0-9]*"
          thousandSeparator
          data-testid="amount"
          disabled={disabled}
          onValueChange={handleLimitAmountChange}
          value={form.values.spendingLimit}
          error={form.errors.spendingLimit}
        />
      )}

      {expenseLink?.enabledExpenses && (
        <CardCategorySelect {...form.getInputProps('categoryId')} />
      )}

      <Box>
        {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
        <Radio.Group
          label="Restrict spending to specific categories?"
          size="sm"
          color={theme.primaryColor}
          {...form.getInputProps('limitMerchantCategories')}
        >
          <Group gap={RADIO_SPACING} mt="xxs">
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="categoriesYes"
              value="yes"
              label="Yes"
            />
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="categoriesNo"
              value="no"
              label="No"
            />
          </Group>
        </Radio.Group>

        <Box>
          {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
          <Collapse in={form.values.limitMerchantCategories === 'yes'}>
            <Box mt={STACK_SPACING}>
              <Multiselect form={form} disabled={disabled} />
            </Box>
          </Collapse>
        </Box>
      </Box>

      <Box>
        {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
        <Radio.Group
          label="Set termination date?"
          size="sm"
          color={theme.primaryColor}
          {...form.getInputProps('autoExpire')}
        >
          <Group gap={RADIO_SPACING} mt="xxs">
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="autoExpireYes"
              value="yes"
              label="Yes"
            />
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="autoExpireNo"
              value="no"
              label="No"
            />
          </Group>
        </Radio.Group>

        <Box>
          {' '}
          {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
          <Collapse in={form.values.autoExpire === 'yes'}>
            <DatePickerInput
              leftSection={
                <CalendarIcon
                  color={theme.colors.neutral[8]}
                  height="20px"
                  width="20px"
                />
              }
              mt={STACK_SPACING}
              clearable
              valueFormat="YYYY-MM-DD"
              label="Termination date"
              minDate={minimumTerminationDate}
              disabled={disabled}
              onChange={handleTerminationDateChange}
            />
          </Collapse>
        </Box>
      </Box>
    </Stack>
  );
};
