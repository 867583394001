import { DollarAmountTitle } from '@common/composites/dollar-amount-title';
import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Paper,
  Stack,
  Text,
  Loader,
  Alert,
  Container,
} from '@mantine/core';
import { formatCents, formatCurrency } from '@utilities/formatters';
import { IconType } from 'react-icons';
import { PiChartBar, PiCurrencyDollar, PiWarning } from 'react-icons/pi';
import { usePointsSummary } from '@queries/use-benefits-and-rewards';

type MainSummaryProps = {
  title: string;
  value: number;
};

const MainSummary = ({ title, value }: MainSummaryProps) => {
  return (
    <Stack gap="0" w="100%">
      <Text size="sm" c="neutral.10">
        {title}
      </Text>

      <DollarAmountTitle
        dollarAmountString={formatCurrency(formatCents(value))}
        size={'xxs'}
      />
      <Text size="xs" c="neutral.8">
        Worth {formatCurrency(formatCents(value / 10))}
      </Text>
    </Stack>
  );
};

type SmallBlockSummaryProps = {
  icon: IconType;
  content: string;
};

const SmallBlockSummary = ({ icon: Icon, content }: SmallBlockSummaryProps) => {
  return (
    <Stack gap={'lg'} mb={'lg'}>
      <Avatar radius="xl" size="md">
        <Icon size={20} />
      </Avatar>
      <Box>
        <Text size="xs" c="neutral.8">
          Coming Soon
        </Text>
        <Text c="neutral.10" size="md" fw={500}>
          {content}
        </Text>
      </Box>
    </Stack>
  );
};

const PointsSummary = () => {
  const { data: summaryData, isPending, error } = usePointsSummary();

  if (isPending) {
    return (
      <Paper withBorder radius={'md'} p="xl">
        <Flex align="center" justify="center">
          <Loader />
        </Flex>
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper withBorder radius={'md'} p="xl">
        <Alert
          icon={<PiWarning />}
          title="Error"
          color="red"
          withCloseButton={false}
        >
          Failed to load points summary. Please try again later.
        </Alert>
      </Paper>
    );
  }

  const { balance, pendingPoints, redeemedPoints } = summaryData;
  const summaryDataToDisplay = [
    {
      title: 'Flex points balance',
      value: balance,
    },
    {
      title: 'Pending points',
      value: pendingPoints,
    },
    {
      title: 'Redeemed to date',
      value: redeemedPoints,
    },
  ];

  return (
    <Paper withBorder radius={'md'}>
      <Grid
        sx={(theme) => ({
          overflow: 'hidden',
          '& .mantine-Grid-col': {
            paddingRight: theme.spacing.md,
            paddingLeft: theme.spacing.md,
            paddingTop: theme.spacing.md,
          },
          [`@media (min-width: ${theme.breakpoints.md})`]: {
            '& .mantine-Grid-col:not(:last-of-type)': {
              borderRight: `1px solid ${theme.colors.gray[3]}`,
            },
          },
          [`@media (max-width: ${theme.breakpoints.md})`]: {
            '& .mantine-Grid-col:not(:last-child)': {
              borderBottom: `1px solid ${theme.colors.gray[3]}`,
              paddingBottom: theme.spacing.md,
            },
          },
        })}
      >
        <Grid.Col
          span={{ base: 12, md: 3 }}
          sx={() => ({
            paddingBottom: '0 !important',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          })}
        >
          <Image
            src="svg/benefits-and-rewards-card.svg"
            mb={0}
            height={200}
            fit="fill"
            w="100%"
          />
        </Grid.Col>
        <Grid.Col
          span={{ base: 12, md: 5 }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Container size="sm" px={0}>
            <Stack align="stretch" w="100%">
              <Flex
                gap="xl"
                wrap={{ base: 'nowrap', md: 'wrap', lg: 'nowrap' }}
              >
                {summaryDataToDisplay.map((item) => (
                  <MainSummary
                    key={item.title}
                    title={item.title}
                    value={item.value}
                  />
                ))}
              </Flex>
            </Stack>
          </Container>
        </Grid.Col>
        <Grid.Col
          span={{ base: 6, md: 2 }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <SmallBlockSummary icon={PiChartBar} content="See Points Activity" />
        </Grid.Col>
        <Grid.Col
          span={{ base: 6, md: 2 }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <SmallBlockSummary icon={PiCurrencyDollar} content="Redeem points" />
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export default PointsSummary;
