import { BadgeProps, CSSProperties, MantineTheme, rem } from '@mantine/core';

export const getThemedBadgeStyles = (
  theme: MantineTheme,
  props: BadgeProps,
) => {
  let color; // font color
  let padding;
  let fontSize;
  let iconColor;
  let borderColor;
  let borderRadius;
  let backgroundColor;

  switch (props.variant) {
    case 'neutral-flat':
    case 'neutral-outline':
      borderRadius = rem(4);
      padding = `${rem(2)} ${rem(6)}`;
      color = theme.colors.neutral[8];
      borderColor = theme.colors.neutral[4];
      backgroundColor = theme.colors.neutral[2];
      fontSize = props.size === 'lg' ? rem(16) : rem(12);
      break;
    case 'primary-flat':
    case 'primary-outline':
      borderRadius = rem(4);
      padding = `${rem(2)} ${rem(6)}`;
      color = theme.colors.primary[6];
      borderColor = theme.colors.primary[3];
      backgroundColor = theme.colors.primary[0];
      fontSize = props.size === 'lg' ? rem(16) : rem(12);
      break;
    case 'warning-flat':
    case 'warning-outline':
      borderRadius = rem(4);
      padding = `${rem(2)} ${rem(6)}`;
      color = theme.colors.warning[7];
      borderColor = theme.colors.warning[3];
      backgroundColor = theme.colors.warning[0];
      fontSize = props.size === 'lg' ? rem(16) : rem(12);
      break;
    case 'critical-flat':
    case 'critical-outline':
      borderRadius = rem(4);
      padding = `${rem(2)} ${rem(6)}`;
      color = theme.colors.critical[6];
      borderColor = theme.colors.critical[3];
      backgroundColor = theme.colors.critical[0];
      fontSize = props.size === 'lg' ? rem(16) : rem(12);
      break;
    case 'promote-flat':
    case 'promote-outline':
      borderRadius = rem(4);
      padding = `${rem(2)} ${rem(6)}`;
      color = theme.colors.promote[7];
      borderColor = theme.colors.promote[3];
      backgroundColor = theme.colors.promote[1];
      fontSize = props.size === 'lg' ? rem(16) : rem(12);
      break;
    case 'amount-decrease':
      fontSize = rem(12);
      padding = `${rem(4)} ${rem(8)}`;
      color = theme.colors.neutral[8];
      iconColor = theme.colors.neutral[6];
      backgroundColor = theme.colors.neutral[1];
      break;
    case 'amount-increase':
      fontSize = rem(12);
      padding = `${rem(4)} ${rem(8)}`;
      color = theme.colors.primary[6];
      iconColor = theme.colors.primary[2];
      backgroundColor = theme.colors.primary[0];
      break;
    case 'amount-critical':
      fontSize = rem(12);
      padding = `${rem(4)} ${rem(8)}`;
      color = theme.colors.critical[6];
      iconColor = theme.colors.critical[4];
      backgroundColor = theme.colors.critical[0];
      break;
    case 'indicator':
      fontSize = rem(12);
      borderRadius = rem(4);
      padding = `0 ${rem(4)}`;
      color = theme.colors.neutral[0];
      backgroundColor = theme.colors.primary[2];
      break;
    // TODO: Remove this case once we have updated all the badges to use the new styles
    case 'default':
      borderRadius = rem(8);
      color = theme.colors.primary[8];
      borderColor = theme.colors.neutral[0];
      backgroundColor = theme.colors.neutral[0];
      fontSize = props.size === 'sm' ? rem(12) : rem(14);
      padding =
        props.size === 'sm' ? `${rem(3)} ${rem(4)}` : `${rem(5)} ${rem(6)}`;
      break;
    default:
      borderRadius = rem(4);
      padding = `${rem(2)} ${rem(6)}`;
      color = props.c || theme.colors.neutral[8];
  }

  return {
    root: {
      padding,
      height: 'fit-content',
      ...(borderRadius && {
        borderRadius,
        '--badge-radius': borderRadius,
      }),
      ...(backgroundColor && {
        backgroundColor,
        '--badge-bg': backgroundColor,
      }),
      ...(props.variant &&
        props.variant.includes('outline') && {
          border: `1px solid ${borderColor}`,
          '--badge-bd': `1px solid ${borderColor}`,
        }),
      '--badge-color': color,
      ...(fontSize && {
        '--badge-fz': fontSize,
      }),
    },
    label: {
      fontWeight: 400,
      lineHeight: rem(16),
      textTransform: 'none' as CSSProperties['textTransform'],
      // when a Text component is used inside a Badge, it inherits the Badge's styles
      p: {
        fontSize: 'inherit',
        lineHeight: 'inherit',
      },
    },
    section: {
      svg: {
        width: rem(16),
        height: rem(16),
        color: iconColor,
      },
    },
  };
};
