import { FlexbaseTable } from '@common/table';
import { Avatar, Badge, Box, Flex, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { getInitialsOfNames } from '@utilities/object';
import { TableColumn } from 'react-data-table-component';
import { useStyles } from './payments.styles';
import { useMemo } from 'react';
import { MoneyMovement } from '@services/flexbase/banking.model';
import {
  useBillpayPaymentsFilters,
  useBillpayPaymentsModalFilters,
} from './use-billpay-payments-filters';
import { getMonthDayYear, sortDate } from '@utilities/dates';
import { formatPaymentType, getPaymentStatusDisplay } from './utils/payments';
import { useBillPayPayments } from '../use-bill-pay';
import Header from '../new-header/header';
import { filterPayments } from '../new-header/filters/payments/payments';
import EmptyBillTable from '../bills/empty-bill-table';
import { useNavigate, useParams } from 'react-router-dom';
import BillPayPaymentDetailsModal from './utils/payment-details-modal';
import { DateTime } from 'luxon';

export type PaymentWithInvoiceNumber = MoneyMovement & {
  invoiceNumber: string;
};

const compareDates = (
  a: PaymentWithInvoiceNumber,
  b: PaymentWithInvoiceNumber,
): number => {
  const dateA = a.scheduledFor ? DateTime.fromISO(a.scheduledFor) : null;
  const dateB = b.scheduledFor ? DateTime.fromISO(b.scheduledFor) : null;

  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;

  return dateA.toMillis() - dateB.toMillis();
};

const Payments = () => {
  const { classes, theme } = useStyles();
  const { data, isLoading } = useBillPayPayments();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { activeFiltersArray } = useBillpayPaymentsFilters();
  const payments: PaymentWithInvoiceNumber[] = useMemo(() => {
    return (data.payments ?? []).filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [data, activeFiltersArray]);

  const columns: TableColumn<PaymentWithInvoiceNumber>[] = [
    {
      name: 'Created on',
      selector: (payment) => payment.createdAt,
      format: (payment) => getMonthDayYear(payment.createdAt),
      sortable: true,
      sortFunction: sortDate,
    },
    {
      id: 'recipient',
      name: 'Recipient',
      cell: (payment) => (
        <Flex align="center" data-tag="allowRowEvents">
          <Avatar data-tag="allowRowEvents" radius="xl" mr={15} w={45} h={45}>
            <Text data-tag="allowRowEvents">
              {getInitialsOfNames(payment.payCtrParty ?? '')}
            </Text>
          </Avatar>
          <Text data-tag="allowRowEvents" sx={{ flex: 1 }}>
            {payment.payCtrParty}
          </Text>
        </Flex>
      ),
      sortable: true,
      selector: (payment) => payment.payCtrParty,
      grow: useMobileView ? 3 : 1.8,
    },
    {
      id: 'paymentMethod',
      name: 'Payment method',
      selector: (payment) => payment.type,
      format: (payment) => formatPaymentType(payment.type),
      sortable: true,
    },
    {
      id: 'invoiceNumber',
      name: 'Invoice number',
      selector: (payment) => payment.invoiceNumber ?? '',
      sortable: true,
    },
    {
      id: 'paymentDate',
      name: 'Payment date',
      selector: (payment) => payment.scheduledFor ?? '',
      format: (payment) => {
        if (!payment.scheduledFor) return '';
        return getMonthDayYear(payment.scheduledFor);
      },
      sortable: true,
      sortFunction: compareDates,
    },
    {
      id: 'amount',
      name: 'Amount',
      selector: (payment) => payment.payAmount,
      sortFunction: (a, b) =>
        parseInt(a.payAmountCents, 10) - parseInt(b.payAmountCents, 10),
      sortable: true,
    },
    {
      id: 'invoiceStatus',
      name: 'Invoice status',
      cell: (payment) => {
        const { displayStatus, backgroundColor, textColor } =
          getPaymentStatusDisplay(payment.status);
        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{displayStatus} </Text>
          </Badge>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const { paymentId = '' } = useParams();
  const openPayment = payments.find((row) => row.id === paymentId);
  return (
    <Box className={classes.card}>
      {openPayment && <BillPayPaymentDetailsModal openPayment={openPayment} />}
      <Header
        data={payments}
        placeholder="Search payments"
        useFilters={useBillpayPaymentsFilters}
        useFiltersModal={useBillpayPaymentsModalFilters}
        filtersData={filterPayments(payments)}
      />
      <FlexbaseTable
        defaultSortFieldId="paymentDate"
        defaultSortAsc
        columns={columns}
        data={payments}
        onRowClicked={(row) => navigate(`${row.id}`)}
        isFetchingData={isLoading}
        noDataComponent={
          <EmptyBillTable headingText="There are no payments to display" />
        }
      />
    </Box>
  );
};

export default Payments;
