import {
  QualificationApplication,
  Requirement,
} from '@flexbase-eng/sdk-typescript/models/components';
import {
  UpdateApplicationRequest,
  UpdateApplicationRequirementRequest,
} from '@flexbase-eng/sdk-typescript/models/operations';
import { logger } from '@services/logging';
import { platformClient } from '@services/platform/platform-client';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

export const useGetQualificationApplication = (
  queryOptions?: Partial<UseQueryOptions<QualificationApplication | null>>,
) => {
  const { accountId } = useRecoilValue(ApplicationState);

  return useQuery({
    queryKey: ['applications', 'qualification', accountId],
    queryFn: async () => {
      const { data } = await platformClient.listApplications({
        accountId,
        type: 'qualification',
      });

      return data?.[0] ?? null;
    },
    enabled: !!accountId,
    ...queryOptions,
  });
};

export const useCreateApplication = () => {
  const { accountId } = useRecoilValue(ApplicationState);
  const queryClient = useQueryClient();
  return useMutation<
    QualificationApplication,
    Error,
    {
      accountId: string;
      intendedProducts: string[];
    }
  >({
    mutationFn: async (data) => {
      return await platformClient.createQualificationApplication({
        accountId: data.accountId,
        intendedProducts: data.intendedProducts,
        type: 'qualification',
      });
    },
    // Update query data with new application data
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['applications', 'qualification', accountId],
        () => {
          return data;
        },
      );
    },
    onError: (error) => {
      logger.error(
        `Unable to create qualification application: ${error.message}`,
        error,
      );
    },
  });
};

export const useUpdateApplication = () => {
  const { accountId } = useRecoilValue(ApplicationState);
  const queryClient = useQueryClient();
  return useMutation<QualificationApplication, Error, UpdateApplicationRequest>(
    {
      mutationFn: async (data: UpdateApplicationRequest) => {
        return await platformClient.updateApplication(data);
      },
      // Update query data with new application data
      onSuccess: (data) => {
        queryClient.setQueryData(
          ['applications', 'qualification', accountId],
          (oldData: QualificationApplication) => {
            return {
              ...oldData,
              ...data,
            };
          },
        );
      },
      onError: (error) => {
        logger.error(`Unable updating application: ${error.message}`, error);
      },
    },
  );
};

export const useUpdateApplicationRequirement = () => {
  const { accountId } = useRecoilValue(ApplicationState);
  const queryClient = useQueryClient();
  return useMutation<Requirement, Error, UpdateApplicationRequirementRequest>({
    mutationFn: async (data: UpdateApplicationRequirementRequest) => {
      return await platformClient.updateApplicationRequirement(data);
    },
    // Update the application with the updated requirement
    onSuccess: (updatedRequirement) => {
      queryClient.setQueryData(
        ['applications', 'qualification', accountId],
        (oldData: QualificationApplication) => {
          return {
            ...oldData,
            requirements: {
              ...oldData.requirements,
              [updatedRequirement.requirement]: updatedRequirement,
            },
          };
        },
      );
    },
    onError: (error) => {
      logger.error(
        `Unable to update application requirement: ${error.message}`,
        error,
      );
    },
  });
};
