import PaymentsTable from '../components/payments-table/payments-table';
import { Navigate, useParams } from 'react-router-dom';

const OutgoingPayments = () => {
  const { id } = useParams();

  // validate the ID in the URL (if one was provided) as a UUID
  if (
    id &&
    !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(id)
  ) {
    return <Navigate to="/payments/outgoing" />;
  }

  return <PaymentsTable />;
};

export default OutgoingPayments;
