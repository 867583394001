import { ReactNode } from 'react';
import {
  Box,
  Grid,
  Group,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';

type Props = {
  editInputs?: ReactNode;
  showEdit?: boolean;
  label: string | ReactNode;
  value: string | ReactNode;
  isEditMode?: boolean;
};
export const SummaryEditInput = ({
  editInputs,
  showEdit = true,
  label,
  value,
  isEditMode,
}: Props) => {
  const theme = useMantineTheme();
  if (isEditMode) {
    if (showEdit) {
      return <Box>{editInputs}</Box>;
    } else {
      return (
        <Grid align="center">
          <Grid.Col span={4}>
            <Text fw={500} size="sm">
              {label}
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Group>
              <Text size="sm">{value}</Text>
              <Tooltip label="Incorrect? Contact support to change.">
                <Text size="lg" c={theme.colors.primary[6]}>
                  ?
                </Text>
              </Tooltip>
            </Group>
          </Grid.Col>
        </Grid>
      );
    }
  }
  return (
    <Grid>
      <Grid.Col span={4}>
        <Text fw={500} size="sm">
          {label}
        </Text>
      </Grid.Col>
      <Grid.Col span={8}>
        <Text size="sm">{value}</Text>
      </Grid.Col>
    </Grid>
  );
};
