import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { Divider, Text, Image, Loader, rem } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import { useStyles } from './styles';
import { PaymentWithInvoiceNumber } from '../payments';
import useModal from '@common/composites/modal/modal-hook';
import { PaymentAttachmentIdState } from './state';
import { DocMetadata, MoneyMovement } from 'services/flexbase/banking.model';
import { getPdfThumbnailUrlFromUrl } from '@utilities/pdf-thumbnail';
import { PiPlus, PiTrash, PiUploadSimple } from 'react-icons/pi';

const DropzoneChildren = () => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.dropZonePlaceholderContainer}>
      <PiUploadSimple size={'1.25rem'} color={theme.colors.neutral[6]} />
      <Text c="neutral.8" className={classes.dropZonePlaceholder}>
        Drag and drop file, or&nbsp;<span>browse</span>&nbsp;to add your
        document
      </Text>
      <Text size="xs" c="neutral.7">
        .pdf, .jpg, .png
      </Text>
    </div>
  );
};

type Doc = {
  id: string;
  url?: string;
  name: string;
  blobURL?: string;
  extension: string;
  status?: 'error' | 'loading';
};

type Props = {
  row: PaymentWithInvoiceNumber | MoneyMovement;
};

const AttachmentsSection = ({ row }: Props) => {
  const { classes, theme } = useStyles();
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [docsLoading, setDocsLoading] = useState(false);
  const [deletingDocId, setDeletingDocId] = useRecoilState(
    PaymentAttachmentIdState,
  );
  const [addPaymentDocs, setAddPaymentDocs] = useState(false);
  const { openConfirmationModal, closeModalById } = useModal();
  const [docsToDisplay, setDocsToDisplay] = useState<Doc[]>([]);

  const displayDocs = async (docs: DocMetadata[]) => {
    try {
      const docsWithUrl: Doc[] = [];

      for (const doc of docs) {
        const result = await flexbaseClient.getDocumentImage(doc.id);

        if (result) {
          const blobURL = URL.createObjectURL(new Blob([result]));

          let docData: Doc = {
            id: doc.docId!,
            name: doc.sourceName,
            extension: doc.extension,
          };

          if (doc.extension === 'pdf') {
            const pdfPreview = await getPdfThumbnailUrlFromUrl(blobURL);

            docData = pdfPreview
              ? { url: pdfPreview, blobURL, ...docData }
              : { status: 'error', ...docData };

            docsWithUrl.push(docData);
          } else {
            docsWithUrl.push({
              url: blobURL,
              ...docData,
            });
          }
        }
      }
      setDocsToDisplay([...docsToDisplay, ...docsWithUrl]);
    } catch (error) {
      console.error('Error displaying payment docs', error);
      setErrorMessage('Error displaying attachment docs');
    }
  };

  const getDocs = async () => {
    try {
      setDocsLoading(true);
      const result = await flexbaseBankingClient.getPaymentDocs(row.id);

      if (result.success && result.docs.length) {
        const docsMetadata = result.docs
          // Filter out dropped docs (deleted docs)
          .filter((doc) => !doc.droppedAt)
          .map((doc) => {
            return {
              ...doc.metadata,
              docId: doc.id,
            };
          });

        await displayDocs(docsMetadata);
      } else {
        setAddPaymentDocs(true);
      }
    } catch (error) {
      console.error('Error displaying payment docs', error);
      setErrorMessage('Error getting attachment docs');
    } finally {
      setDocsLoading(false);
    }
  };

  const addDoc = async (files: File[]) => {
    setAddPaymentDocs(false);
    try {
      setUploading(true);
      const newDocs: DocMetadata[] = [];
      for (const file of files) {
        const result = await flexbaseBankingClient.uploadPaymentDoc(row.id, {
          file,
          description: 'Banking payments docs',
        });

        if (result.success) {
          newDocs.push({ ...result.doc.metadata, docId: result.doc.id });
        }
      }
      setDocsToDisplay((prevDocs) => [
        ...prevDocs,
        ...newDocs.map((doc) => {
          return {
            id: doc.docId!,
            name: doc.sourceName,
            extension: doc.extension,
            status: 'loading' as Doc['status'],
          };
        }),
      ]);
      displayDocs(newDocs);
    } catch (error) {
      console.error('Error uploading payment doc', error);
      setErrorMessage('Error uploading attachment');
    } finally {
      setUploading(false);
    }
  };

  const downloadDoc = (doc: Doc) => {
    const docUrl = doc.extension === 'pdf' ? doc.blobURL! : doc.url!;
    const alink = document.createElement('a');
    alink.href = docUrl;
    alink.download = `${doc.name}`;
    alink.click();
  };

  const deleteDoc = async (doc: Doc) => {
    try {
      setDocsLoading(true);
      setDeletingDocId(doc.id);
      await flexbaseBankingClient.deletePaymentDocs(row.id, doc.id);
    } catch (error) {
      console.error('Error deleting payment doc', error);
      setErrorMessage('Error deleting attachment');
    } finally {
      setDocsLoading(false);
    }
  };

  const RemoveAttachmentModal = async (doc: Doc) => {
    openConfirmationModal({
      title:
        'Are you sure you want to remove this attachment from the payment?',
      confirmText: 'Yes, remove',
      cancelText: 'Cancel',
      onCancel: () => {
        closeModalById('remove-doc');
      },
      onConfirm: async () => deleteDoc(doc),
      showCloseButton: false,
      content: (
        <div className={classes.docsBox}>
          <div className={classes.rowContent}>
            <Image width={rem(300)} height={rem(300)} src={doc.url} />
            <div>{doc.name}</div>
          </div>
        </div>
      ),
      id: 'remove-doc',
    });
  };

  const RenderView = (doc: Doc) => {
    switch (doc.status) {
      case 'loading':
        return (
          <div className={classes.loadingContainer}>
            <Loader size="sm" variant="dots" />
          </div>
        );

      case 'error':
        return <Text>{`Error displaying "${doc.name}"`}</Text>;

      default:
        return (
          <>
            <div
              className={classes.rowContent}
              onClick={() => downloadDoc(doc)}
            >
              <Image width={rem(50)} height={rem(50)} src={doc.url} />
              <div>{doc.name}</div>
            </div>
            <PiTrash
              color={theme.colors.primary[2]}
              onClick={() => RemoveAttachmentModal(doc)}
            />
          </>
        );
    }
  };

  useEffect(() => {
    getDocs();
  }, []);

  return (
    <div>
      <Divider my={rem(24)} />
      <div className={classes.docsContainer}>
        {docsLoading ? (
          <div className={classes.loadingContainer}>
            <Loader size="sm" h={rem(160)} />
          </div>
        ) : (
          <>
            {errorMessage ? (
              <Text className={classes.bigText}>{errorMessage}</Text>
            ) : (
              <>
                {docsToDisplay.map((doc) => {
                  // This is a (dirty?) hack to not diaplay the doc that is being deleted
                  if (deletingDocId === doc.id) {
                    return;
                  }

                  return (
                    <div key={doc.id} className={classes.docsBox}>
                      {RenderView(doc)}
                    </div>
                  );
                })}

                {uploading && (
                  <div className={classes.docsBox}>
                    <div className={classes.loadingContainer}>
                      <Loader size="sm" variant="dots" />
                    </div>
                  </div>
                )}
              </>
            )}
            {addPaymentDocs ? (
              <Dropzone
                onDrop={(file) => addDoc(file)}
                maxSize={3 * 1024 ** 2}
                accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
                classNames={{
                  root: classes.dropZone,
                }}
              >
                <DropzoneChildren />
              </Dropzone>
            ) : (
              <div
                className={classes.addAttachmentContent}
                onClick={() => setAddPaymentDocs(true)}
              >
                <PiPlus />
                <Text>Add attachments</Text>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AttachmentsSection;
