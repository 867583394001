import {
  Box,
  Collapse,
  Divider,
  Group,
  Progress,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import { Fragment, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

export type CardUtilization = {
  name: string;
  subtitle?: string;
  frequency: string;
  value: number;
  total: number;
};

const Utilization = ({
  name,
  subtitle,
  frequency,
  value,
  total,
}: CardUtilization) => {
  let utilizationPct = 0;

  if (total !== 0) {
    utilizationPct = +((value * 100) / total).toFixed(0);

    if (utilizationPct === 100 && value < total) {
      utilizationPct = 99;
    }
  }

  const isUnlimited = total === 0;
  const title = isUnlimited ? name : `${name} - ${frequency}`;
  const utilizationPctString =
    utilizationPct === 0 && value > 0 ? '< 1' : utilizationPct;
  const utilizationString = isUnlimited
    ? 'No spending limit'
    : `${utilizationPctString}% utilization`;

  return (
    <Box>
      <Group align="center" justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          {!!subtitle && (
            <Text fz={14} c="neutral.8">
              {subtitle}
            </Text>
          )}

          <Text fz={16} c="primary.8">
            {title}
          </Text>
        </Stack>

        <Text
          fz={14}
          c="primary.8"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {utilizationString}
        </Text>
      </Group>

      {!isUnlimited && (
        <>
          <Progress
            mt="md"
            value={utilizationPct}
            h={8}
            bg="neutral.4"
            size="sm"
            radius={'xl'}
            styles={(t) => ({
              bar: {
                backgroundColor: t.colors.primary[5],
              },
            })}
          />

          <Group justify="space-between" mt={4}>
            <Text fz={12}>{formatCurrency(value)}</Text>

            <Text fz={12}>{formatCurrency(total)}</Text>
          </Group>
        </>
      )}
    </Box>
  );
};

type ToggleLimitsButtonProps = {
  showAll: boolean;
  onClick: () => void;
};

const ToggleLimitsButton = ({ showAll, onClick }: ToggleLimitsButtonProps) => {
  return (
    <UnstyledButton fz={14} fw={500} sx={{ flexShrink: 0 }} onClick={onClick}>
      <Group gap={'xs'} wrap="nowrap">
        {showAll ? (
          <>
            <Text>Hide All Limits</Text>
            <FiEyeOff />
          </>
        ) : (
          <>
            <Text>Show All Limits</Text>
            <FiEye />
          </>
        )}
      </Group>
    </UnstyledButton>
  );
};

type CardUtilizationsProps = {
  utilizations: CardUtilization[];
};

export const CardUtilizations = ({ utilizations }: CardUtilizationsProps) => {
  const [showAll, setShowAll] = useState(false);
  const [defaultUtilization, ...additionalUtilizations] = utilizations;
  const hasMore = additionalUtilizations.length > 0;

  return (
    <Box>
      <Group justify="space-between" wrap="nowrap" mb="md" align="center">
        <Text c="primary.8" fw={500} fz={20} lh={2}>
          {hasMore ? 'Card Limits' : 'Card Limit'}
        </Text>

        {hasMore && (
          <ToggleLimitsButton
            showAll={showAll}
            onClick={() => setShowAll((v) => !v)}
          />
        )}
      </Group>

      {defaultUtilization ? (
        <Utilization
          name={defaultUtilization.name}
          subtitle={defaultUtilization.subtitle}
          frequency={defaultUtilization.frequency}
          value={defaultUtilization.value}
          total={defaultUtilization.total}
        />
      ) : (
        <Text>Unable to display card limit.</Text>
      )}

      {hasMore && (
        <Collapse in={showAll}>
          <Stack gap="md" mt="md">
            {additionalUtilizations.map((u, idx) => (
              <Fragment key={`addl-utilization-${u.name}-${idx}`}>
                <Divider />
                <Utilization
                  name={u.name}
                  subtitle={u.subtitle}
                  frequency={u.frequency}
                  value={u.value}
                  total={u.total}
                />
              </Fragment>
            ))}
          </Stack>
        </Collapse>
      )}
    </Box>
  );
};
