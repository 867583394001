import { useMutation } from '@tanstack/react-query';
import { flexbaseBankingClient } from '@services/flexbase-client';
import { PostDocumentRequestBody } from '@flexbase-eng/sdk-typescript/models/operations';
import { platformClient } from '@services/platform/platform-client';

export const usePatchBankingCardSwipe = () => {
  return useMutation({
    mutationFn: async ({
      cardSwipeId,
      request,
    }: {
      cardSwipeId: string;
      request: Partial<{ memo: string; docId: string | null }>;
    }) => await flexbaseBankingClient.patchCardSwipe(cardSwipeId, request),
  });
};

export const useUploadCardSwipeDoc = () => {
  return useMutation({
    mutationFn: async ({
      cardSwipeId,
      document,
    }: {
      cardSwipeId: string;
      document: PostDocumentRequestBody;
    }) => {
      const uploadedDocument =
        await platformClient.uploadPlatformDocument(document);
      const patchResult = await flexbaseBankingClient.patchCardSwipe(
        cardSwipeId,
        { docId: uploadedDocument.id },
      );
      return patchResult;
    },
  });
};
