import { UseHasTermsReturnType } from '@utilities/custom-hooks/use-has-terms';
import { flexbaseOnboardingClient } from '../services/flexbase-client';
import {
  TermsOfServiceAcceptance,
  TermsOfServiceDoc,
  TermsOfServiceType,
} from 'services/flexbase/flexbase-onboarding-client';
import { DefaultError, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useGetTermsAgreement } from './use-generated-terms';

const QUERY_KEY = 'terms_of_service';

type TermsOfServiceDocsReturnType = Awaited<
  ReturnType<typeof flexbaseOnboardingClient.getTermsOfServiceDocs>
>;

type Props<T> = {
  termsType?: TermsOfServiceType;
  useAuth?: boolean;
  select?: (data: TermsOfServiceDocsReturnType) => T;
};

export const useGetTermsOfServiceDocs = <T = TermsOfServiceDocsReturnType>({
  termsType,
  useAuth,
  select,
}: Props<T> = {}) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'docs', termsType, useAuth],
    queryFn: () =>
      flexbaseOnboardingClient.getTermsOfServiceDocs(termsType, useAuth),
    select,
    meta: {
      errorMessage: `We're unable to get the terms of service documents at this time.`,
    },
  });
};

type UseTermsOfServiceProps<T> = {
  select?: (terms: TermsOfServiceAcceptance[]) => T[];
};

export const useTermsOfServiceHistory = function <T>({
  select,
}: UseTermsOfServiceProps<T> = {}) {
  return useQuery<TermsOfServiceAcceptance[], DefaultError, T[]>({
    queryKey: [QUERY_KEY, 'history'],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getTosHistory();
    },
    select,
    staleTime: Infinity,
  });
};

export type TermsOfServiceDocWithContents = TermsOfServiceDoc & {
  contents?: string;
};

export const useGetTermsOfServiceDocsNeededForUser = ({
  termsConfig,
  showTextAgreements,
}: {
  termsConfig: Omit<
    UseHasTermsReturnType,
    | 'hasActiveBanking'
    | 'hasActiveTreasury'
    | 'hasActiveCredit'
    | 'hasTermsForActiveProduct'
    | 'hasActiveIntnlPayments'
  >;
  showTextAgreements?: boolean;
}): {
  termsNeeded?: TermsOfServiceDocWithContents[];
  isPending: boolean;
} => {
  const {
    hasBankingTerms,
    hasFlexbaseTerms,
    hasInternationalPaymentsTerms,
    hasTreasuryTerms,
    hasCreditTerms,
    hasPatriotAct,
    hasFicoPull,
    hasFlTermsAgreement,
    hasNyTermsAgreement,
  } = termsConfig;
  const { data: flAgreement, isPending: flAgreementPending } =
    useGetTermsAgreement('fl-credit');
  const { data: nyAgreement, isPending: nyAgreementPending } =
    useGetTermsAgreement('ny-credit');
  const { data: termsDocuments, isPending: termsDocumentsPending } =
    useGetTermsOfServiceDocs();

  const termsNeeded = useMemo(() => {
    const filteredTerms: TermsOfServiceDocWithContents[] | undefined =
      termsDocuments?.filter((term) => {
        if (hasBankingTerms && term.type === 'banking') return true;
        if (hasFlexbaseTerms && term.type === 'flexbase') return true;
        if (
          hasInternationalPaymentsTerms &&
          term.type === 'internationalPayments'
        )
          return true;
        if (hasTreasuryTerms && term.type === 'treasury') return true;
        if (hasCreditTerms && term.type === 'credit') return true;
        if (showTextAgreements) {
          if (hasFicoPull && term.type === 'ficoPull') return true;
          if (hasPatriotAct && term.type === 'patriotAct') return true;
        }
        return false;
      });

    if (
      hasFlTermsAgreement &&
      flAgreement &&
      flAgreement?.status !== 'not-applicable'
    ) {
      filteredTerms?.push({
        type: 'flTermsAgreement',
        label: 'FL Terms Agreement',
        tosId: flAgreement.agreement.id,
        tosCreatedAt: flAgreement.agreement.asOf,
        contents: flAgreement.agreement.contents,
        url: '',
      });
    }
    if (
      hasNyTermsAgreement &&
      nyAgreement &&
      nyAgreement?.status !== 'not-applicable'
    ) {
      filteredTerms?.push({
        type: 'nyTermsAgreement',
        label: 'NY Terms Agreement',
        tosId: nyAgreement.agreement.id,
        tosCreatedAt: nyAgreement.agreement.asOf,
        contents: nyAgreement.agreement.contents,
        url: '',
      });
    }
    return filteredTerms;
  }, [termsDocuments, termsConfig, flAgreement, nyAgreement]);

  return {
    termsNeeded,
    isPending:
      termsDocumentsPending || flAgreementPending || nyAgreementPending,
  };
};
