import { Text, TextProps } from '@mantine/core';
import { PropsWithChildren } from 'react';

type PreviewLabelProps = PropsWithChildren<TextProps>;

export const PreviewLabel = ({ children, ...rest }: PreviewLabelProps) => {
  return (
    <Text fz={14} color="neutral.8" {...rest}>
      {children}
    </Text>
  );
};
