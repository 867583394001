import {
  AppShell,
  Box,
  Container,
  Flex,
  Group,
  Loader,
  rem,
  Stack,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { useCheatSheetFeatureFlag } from '@utilities/feature-flags';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Suspense, lazy, useMemo } from 'react';
import { PiArrowUpRight, PiCircleFill, PiDiamondsFour } from 'react-icons/pi';

type Component = {
  // The label to display in the navbar
  label: string;
  // The path segment to use in the URL
  path: string;
  // The component to render
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  // The Figma link to view the component
  figmaLink?: string;
};

// Components that will be rendered in the sidebar
const COMPONENTS: Record<string, Component> = {
  drawer: {
    label: 'Drawer',
    path: 'drawer',
    component: lazy(() => import('./components/drawer/drawer-container')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=13422-61502&p=f&m=dev',
  },
  forms: {
    label: 'Forms',
    path: 'forms',
    component: lazy(() => import('./components/form/form')),
  },
  wizard: {
    label: 'Wizard',
    path: 'wizard',
    component: lazy(() => import('./components/wizard/wizard')),
  },
  errorCard: {
    label: 'Error Card',
    path: 'errorCard',
    component: lazy(() => import('./components/wizard/error-card')),
  },
  table: {
    label: 'Table',
    path: 'table',
    component: lazy(() => import('./components/table')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=11344-66172&m=dev',
  },
  tableCells: {
    label: 'Table Cells',
    path: 'table-cells',
    component: lazy(() => import('./components/table-cells/table-cells')),
  },
  alert: {
    label: 'Alert',
    path: 'alert',
    component: lazy(() => import('./components/alert/alert')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=9527-38170&p=f&m=dev',
  },
  widget: {
    label: 'Widget',
    path: 'widget',
    component: lazy(() => import('./components/widget/widget')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=12819-73893&p=f&m=dev',
  },
  mainLayout: {
    label: 'Main Layout',
    path: 'mainLayout',
    component: lazy(() => import('./components/main-layout/main-layout')),
  },
  detailLayout: {
    label: 'Detail Layout',
    path: 'detailLayout',
    component: lazy(() => import('./components/detail-layout/datail-layout')),
  },
  textInput: {
    label: 'Text Input',
    path: 'textInput',
    component: lazy(() => import('./components/text-input/text-input')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=16521-134081&m=dev',
  },
  numberInput: {
    label: 'Number Input',
    path: 'number-input',
    component: lazy(() => import('./components/number-input/number-input')),
  },
  loader: {
    label: 'Loader',
    path: 'loader',
    component: lazy(() => import('./components/loader/loader')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=17273-9738&t=oxwymwA2CQEUK6cR-0',
  },
  button: {
    label: 'Button',
    path: 'button',
    component: lazy(() => import('./components/button/button')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=7164-17748&m=dev',
  },
  badge: {
    label: 'Badge',
    path: 'badge',
    component: lazy(() => import('./components/badge/badge')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=11483-72288&p=f&t=hwk4W3FEylqNdajf-0',
  },
  select: {
    label: 'Selects',
    path: 'select',
    component: lazy(() => import('./components/select/select')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?node-id=11483-72288&p=f&t=hwk4W3FEylqNdajf-0',
  },
  segmentedControl: {
    label: 'Segmented Control',
    path: 'segmented-control',
    component: lazy(
      () => import('./components/segmented-control/segmented-control'),
    ),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?m=auto&node-id=17246-6537',
  },
  radio: {
    label: 'Radio',
    path: 'radio',
    component: lazy(() => import('./components/radio/radio')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?m=auto&node-id=16503-129001',
  },
  switch: {
    label: 'Switch',
    path: 'switch',
    component: lazy(() => import('./components/switch/switch')),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?m=auto&node-id=16503-131798',
  },
  datePicker: {
    label: 'Date Picker Input',
    path: 'date-picker-input',
    component: lazy(
      () => import('./components/date-picker-input/date-picker-input'),
    ),
    figmaLink:
      'https://www.figma.com/design/NTqnWKxlFuazNcnrlovMme/%F0%9F%96%A5%EF%B8%8F-Emerald-%2F%2F-Web?m=auto&node-id=16521-122349',
  },
  // NEW COMPONENTS HERE: 👇
  // newComponent: {
  //   label: 'New Component',
  //   path: 'new-component',
  //   component: lazy(() => import('./components/route/to/component')),
  //   figmaLink: 'figma link, if available',
  // },
};

// Components that don't need a tab on the sidebar, but will be used by subroutes / outlets
const NESTED_COMPONENTS: Record<string, Component> = {
  firstTab: {
    label: 'Layout First Tab',
    path: 'first-tab',
    component: lazy(() => import('./components/main-layout/layout-first-tab')),
  },
  secondTab: {
    label: 'Layout Second Tab',
    path: 'second-tab',
    component: lazy(() => import('./components/main-layout/layout-second-tab')),
  },
  detailLayoutItem: {
    label: 'Detail Layout Item',
    path: 'detail-layout-item/:id',
    component: lazy(
      () => import('./components/detail-layout/detail-layout-item'),
    ),
  },
  detailLayoutItemTabOne: {
    label: 'Detail Layout Item Tab One',
    path: 'tab-one',
    component: lazy(
      () => import('./components/detail-layout/detail-layout-item-tab-one'),
    ),
  },
  detailLayoutItemTabTwo: {
    label: 'Detail Layout Item Tab Two',
    path: 'tab-two',
    component: lazy(
      () => import('./components/detail-layout/detail-layout-item-tab-two'),
    ),
  },
};

const PlaygroundHeader = ({
  activeComponent,
}: {
  activeComponent?: Component;
}) => {
  const navigate = useNavigate();
  return (
    <AppShell.Header>
      <Flex justify="space-between" align="center" h="100%" px="lg">
        <Group
          gap="xs"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/playground')}
        >
          <Text size="xl">❇️</Text>
          <Text size="lg" fw={600}>
            Playground
          </Text>
        </Group>

        {!activeComponent?.figmaLink ? (
          <Text size="sm" c="neutral.6">
            Kickstart your UI workflow
          </Text>
        ) : (
          <Box
            component="a"
            href={activeComponent?.figmaLink}
            target="_blank"
            rel="noopener noreferrer"
            py="xxs"
            px="sm"
            pr="xs"
            sx={(theme) => ({
              borderRadius: rem(4),
              textDecoration: 'none',
              color: theme.colors.neutral[10],
              '&:hover': {
                backgroundColor: theme.colors.neutral[2],
              },
            })}
          >
            <Group gap="xxs">
              <Text size="sm" fw={500}>
                View in Figma
              </Text>
              <PiArrowUpRight size={16} />
            </Group>
          </Box>
        )}
      </Flex>
    </AppShell.Header>
  );
};

const SidebarRow = ({
  component,
  isActive,
}: {
  component: Component;
  isActive: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Flex
      key={component.path}
      onClick={() => navigate(`/playground/${component.path}`)}
      py="xs"
      px="md"
      bg={isActive ? 'neutral.2' : 'unset'}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': { backgroundColor: `${theme.colors.neutral[1]} !important` },
        transition: 'background-color 180ms ease',
        borderRadius: rem(6),
      })}
      align="center"
      justify="space-between"
    >
      <Text
        fw={isActive ? 500 : 400}
        color={isActive ? 'neutral.10' : 'neutral.8'}
        mr="sm"
      >
        {component.label}
      </Text>
      <Transition
        mounted={isActive}
        transition="fade"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => <PiCircleFill size={7} color="black" style={styles} />}
      </Transition>
    </Flex>
  );
};

const EmptyState = () => {
  const theme = useMantineTheme();
  return (
    <Box p="lg" pt="20%" mih="100%">
      <Flex justify="center" align="center" direction="column" gap="sm">
        <PiDiamondsFour size={36} color={theme.colors.neutral[6]} />
        <Text c={theme.colors.neutral[6]} fw={500}>
          Select a component on the sidebar
        </Text>
      </Flex>
    </Box>
  );
};

const Playground = () => {
  const location = useLocation();
  const isEnabled = useCheatSheetFeatureFlag();

  const activeComponent = useMemo(
    () =>
      Object.values(COMPONENTS).find(
        (component) => location.pathname === `/playground/${component.path}`,
      ),
    [location.pathname],
  );

  if (!isEnabled) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box>
      <AppShell
        padding="md"
        styles={(theme) => ({
          main: {
            backgroundColor: theme.colors.neutral[3],
          },
        })}
        header={{ height: 50 }}
        navbar={{ breakpoint: 'base', width: 250 }}
      >
        <PlaygroundHeader activeComponent={activeComponent} />
        <AppShell.Navbar p="xs">
          <Stack gap="xxs" sx={{ overflowY: 'auto' }}>
            {Object.values(COMPONENTS).map((component) => {
              const isActive = component?.path === activeComponent?.path;
              return (
                <SidebarRow
                  key={component.path}
                  component={component}
                  isActive={isActive}
                />
              );
            })}
          </Stack>
        </AppShell.Navbar>
        <AppShell.Main>
          <Container size="xl" p={0} mih="100%">
            <Suspense
              fallback={
                <Box p="lg" pt="20%" mih="100%">
                  <Flex justify="center" align="center">
                    <Loader size="sm" />
                  </Flex>
                </Box>
              }
            >
              <Routes>
                <Route
                  path={`${COMPONENTS.widget.path}`}
                  element={<COMPONENTS.widget.component />}
                />
                <Route
                  path={`${COMPONENTS.drawer.path}`}
                  element={<COMPONENTS.drawer.component />}
                />
                <Route
                  path={`${COMPONENTS.forms.path}`}
                  element={<COMPONENTS.forms.component />}
                />
                <Route
                  path={`${COMPONENTS.wizard.path}`}
                  element={<COMPONENTS.wizard.component />}
                />
                <Route
                  path={`${COMPONENTS.errorCard.path}`}
                  element={<COMPONENTS.errorCard.component />}
                />
                <Route
                  path={`${COMPONENTS.table.path}`}
                  element={<COMPONENTS.table.component />}
                />
                <Route
                  path={`${COMPONENTS.tableCells.path}`}
                  element={<COMPONENTS.tableCells.component />}
                />
                <Route
                  path={`${COMPONENTS.mainLayout.path}`}
                  element={<COMPONENTS.mainLayout.component />}
                >
                  <Route
                    index
                    element={
                      <Navigate
                        to={`${NESTED_COMPONENTS.firstTab.path}`}
                        replace
                      />
                    }
                  />
                  <Route
                    path={`${NESTED_COMPONENTS.firstTab.path}`}
                    element={<NESTED_COMPONENTS.firstTab.component />}
                  />
                  <Route
                    path={`${NESTED_COMPONENTS.secondTab.path}`}
                    element={<NESTED_COMPONENTS.secondTab.component />}
                  />
                </Route>
                <Route
                  path={`${COMPONENTS.alert.path}`}
                  element={<COMPONENTS.alert.component />}
                />
                <Route path={`${COMPONENTS.detailLayout.path}`}>
                  <Route
                    path=""
                    element={<COMPONENTS.detailLayout.component />}
                  />
                  <Route
                    path={`${NESTED_COMPONENTS.detailLayoutItem.path}`}
                    element={<NESTED_COMPONENTS.detailLayoutItem.component />}
                  >
                    <Route
                      index
                      element={
                        <Navigate
                          to={`${NESTED_COMPONENTS.detailLayoutItemTabOne.path}`}
                          replace
                        />
                      }
                    />
                    <Route
                      path={`${NESTED_COMPONENTS.detailLayoutItemTabOne.path}`}
                      element={
                        <NESTED_COMPONENTS.detailLayoutItemTabOne.component />
                      }
                    />
                    <Route
                      path={`${NESTED_COMPONENTS.detailLayoutItemTabTwo.path}`}
                      element={
                        <NESTED_COMPONENTS.detailLayoutItemTabTwo.component />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path={`${COMPONENTS.textInput.path}`}
                  element={<COMPONENTS.textInput.component />}
                />
                <Route
                  path={`${COMPONENTS.numberInput.path}`}
                  element={<COMPONENTS.numberInput.component />}
                />
                <Route
                  path={`${COMPONENTS.loader.path}`}
                  element={<COMPONENTS.loader.component />}
                />
                <Route
                  path={`${COMPONENTS.button.path}`}
                  element={<COMPONENTS.button.component />}
                />
                <Route
                  path={`${COMPONENTS.badge.path}`}
                  element={<COMPONENTS.badge.component />}
                />
                <Route
                  path={`${COMPONENTS.select.path}`}
                  element={<COMPONENTS.select.component />}
                />
                <Route
                  path={`${COMPONENTS.segmentedControl.path}`}
                  element={<COMPONENTS.segmentedControl.component />}
                />
                <Route
                  path={`${COMPONENTS.datePicker.path}`}
                  element={<COMPONENTS.datePicker.component />}
                />
                <Route
                  path={`${COMPONENTS.radio.path}`}
                  element={<COMPONENTS.radio.component />}
                />
                <Route
                  path={`${COMPONENTS.switch.path}`}
                  element={<COMPONENTS.switch.component />}
                />
                <Route path="*" element={<EmptyState />} />
              </Routes>
            </Suspense>
          </Container>
        </AppShell.Main>
      </AppShell>
    </Box>
  );
};

export default Playground;
