import { showNotification } from '@mantine/notifications';
import { flexbaseOnboardingClient } from '../services/flexbase-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { ApplicationState } from '../recoil-state/application/product-onboarding';
import { OnboardingCompany } from '../types/onboarding-info';

const QUERY_KEY = 'companyData';
const QUERY_MINIMUM_DUE_KEY = 'minimumDue';

const mutationFn = async (params: Partial<OnboardingCompany>) => {
  return await flexbaseOnboardingClient.updateCompany({
    ...params,
  });
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();
  const setUserAndCompanyInfo = useSetRecoilState(ApplicationState);

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      // DO NOT COPY THIS CODE OR THIS PATTERN OF MIXING RECOIL WITH REACT QUERY!!!
      setUserAndCompanyInfo((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          ...data,
        },
      }));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to update company details',
      });
    },
  });
};

const updateControlPersonFn = async (controlPerson: string) => {
  return await flexbaseOnboardingClient.updateCompany({
    controlPerson,
  });
};

export const useUpdateControlPersonFn = () => {
  return useMutation({
    mutationFn: updateControlPersonFn,
    onSuccess: () => {
      showNotification({
        color: 'sage.4',
        title: 'Success',
        message: 'Control person has been successfully added',
      });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to add the control person',
      });
    },
  });
};

export const useGetCompanyData = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, companyId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getCompanyInfo(companyId);
    },
    meta: {
      errorMessage: 'Unable to get the company data',
    },
  });
};

export const useGetMinimumDue = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_MINIMUM_DUE_KEY, companyId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getMinimumDue(companyId);
    },
    meta: {
      errorMessage: 'Unable to get minimum due data',
    },
  });
};
