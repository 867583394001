import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    padding: theme.spacing.xl,
    borderRadius: theme.defaultRadius,
    color: theme.colors.neutral[2],
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
  containerVendor: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  rowActionIcon: {
    fill: '#979797',
    cursor: 'pointer',
  },
  deleteRecipientOption: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.critical[6],
  },
  viewRecipientOption: {
    display: 'flex',
    alignItems: 'center',
  },
}));
