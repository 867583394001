import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStatementsStyles = createStyles((theme) => ({
  container: {
    padding: rem(24),
    color: theme.colors.neutral[8],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    backgroundColor: theme.colors.neutral[0],
    'table:not(.main_account) > tbody > tr:hover': {
      backgroundColor: '#FFEEEC',
    },
  },
  card: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: rem(552),
    height: rem(79),
    borderRadius: rem(8),
    alignItems: 'center',
    border: `1px solid ${theme.colors.neutral[2]}`,
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  totalCreditLabel: {
    fontSize: rem(14),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(21),
    color: theme.colors.neutral[8],
  },
  input: {
    color: theme.colors.neutral[8],
    height: rem(40),
    opacity: 0.8,
    border: `1px solid ${theme.colors.neutral[2]}`,
    borderRadius: rem(8),
    fontSize: rem(14),
    lineHeight: rem(16),
    fontWeight: 500,
    background: theme.colors.neutral[0],
    '&:focus': { borderColor: theme.primaryColor },
    '@media(max-width: 767px)': {
      marginLeft: rem(16),
    },
  },
  statementRow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    ':hover': {
      color: theme.primaryColor,
    },
    svg: {
      margin: '0px !important',
    },
    span: {
      color: 'red !important',
    },
  },

  tabContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    backgroundColor: theme.colors.neutral[0],
    gap: rem(32),
    overflow: 'auto',
    scrollbarWidth: 'none',
    width: '100%',
    msOverflowStyle: 'none',
    '&:-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    height: '100%',
    padding: rem(1),
    position: 'relative',
    marginBottom: rem(12),
  },

  containerNoAvailable: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  noDataTitle: {
    fontSize: rem(24),
    fontWeight: 500,
    color: '#202020',
    filter: 'none !important',
  },

  pdfTableHeader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: rem(13),
    fontWeight: 500,
  },

  blurryTextStyle: {
    filter: 'blur(4px)',
  },
  activeTab: {
    background: theme.colors.warning[2],
    span: {
      color: `${theme.primaryColor} !important`,
    },
  },
  deactivatedTab: {
    background: theme.colors.neutral[0],
    span: {
      color: `${theme.colors.neutral[8]} !important`,
    },
  },
  containerMessageNoData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: 0,
    bottom: '50%',
    width: '100%',
    zIndex: 10,
  },

  filterStyle: {
    filter: 'blur(3px)',
  },

  rectangleRounded: {
    width: rem(90),
    height: rem(20),
    borderRadius: rem(100),
    background: '#F0F0F0',
  },

  contentCenter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  customTab: {
    borderRadius: rem(100),
    border: rem(1),
    fontSize: rem(16),
    fontWeight: 500,
  },
}));
