import { Box, BoxProps, Group, Text, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { CheckBlack } from '../../../assets/svg';
import React, { ReactNode } from 'react';
import { IconType } from 'react-icons';

export const RadioSelectCard = ({
  selected,
  onClick,
  title,
  description,
  icon,
  id,
  boxProps,
  disabled = false,
}: {
  selected: boolean;
  onClick: () => void;
  title: string;
  description: ReactNode;
  icon?: IconType;
  id?: string;
  boxProps?: BoxProps;
  disabled?: boolean;
}) => {
  const { classes } = useStyles({ selected, disabled });
  return (
    <Box
      className={classes.radioCard}
      onClick={disabled ? undefined : onClick}
      id={`radio-select-card-${id}`}
      data-testid={`radio-select-card-${id}`}
      {...boxProps}
    >
      <Group gap={rem(10)}>
        <Box className={classes.radioIcon}>{selected && <CheckBlack />}</Box>
        {icon && (
          <Box w={rem(24)} h={rem(24)}>
            {React.createElement(icon, { size: rem(24), color: 'neutral.1' })}
          </Box>
        )}
      </Group>
      <div>
        <Text fz={rem(16)} fw={500} c={selected ? 'neutral.0' : 'neutral.10'}>
          {title}
        </Text>
        <Text
          fz={rem(14)}
          opacity={selected ? 1 : 0.7}
          c={selected ? 'neutral.2' : 'neutral.10'}
        >
          {description}
        </Text>
      </div>
    </Box>
  );
};

const useStyles = createStyles(
  (
    theme,
    { selected, disabled }: { selected: boolean; disabled: boolean },
    helpers,
  ) => ({
    radioCard: {
      width: rem(340),
      height: rem(106),
      borderRadius: theme.defaultRadius,
      border: `1px solid ${theme.colors.neutral[4]}`,
      padding: `${theme.spacing.md} ${theme.spacing.lg}`,
      display: 'flex',
      gap: theme.spacing.sm,
      alignItems: 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
      color: selected ? theme.colors.neutral[3] : theme.colors.primary[8],
      ...(selected && {
        backgroundColor: theme.colors.primary[8],
        transition: 'background-color 0.2s ease, color 0.2s ease',
      }),
      ...(disabled && {
        opacity: 0.5,
        cursor: 'not-allowed',
      }),
      [helpers.smallerThan('sm')]: {
        width: '100%',
      },
    },
    radioIcon: {
      height: rem(24),
      width: rem(24),
      minHeight: rem(24),
      minWidth: rem(24),
      borderRadius: '100%',
      border: `1px solid ${theme.colors.neutral[4]}`,
      backgroundColor: selected ? theme.colors.neutral[0] : '',
      transition: 'background-color 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
