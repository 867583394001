// This one is used when making a payment
export const setPaymentRejectedError = (reason: string) => {
  let message = '';
  switch (reason) {
    case 'InsufficientFunds':
      message =
        "It looks like there's not enough funds in your account to complete this transaction. Please add funds and try again.";
      break;

    case 'AccountClosed':
      message =
        'We are sorry, you cannot make a payment with an account that is closed.';
      break;

    case 'DailyACHCreditLimitExceeded':
      message =
        "You've exceeded the daily credit limit set by Flex. Please try again tomorrow.";
      break;

    case 'MonthlyACHCreditLimitExceeded':
      message =
        "You've exceeded the monthly credit limit set by Flex. Please try again next month.";
      break;

    case 'DailyACHDebitLimitExceeded':
      message =
        "You've exceeded the daily debit limit set by Flex. Please try again tomorrow.";
      break;

    case 'MonthlyACHDebitLimitExceeded':
      message =
        "You've exceeded the monthly debit limit set by Flex. Please try again next month.";
      break;

    case 'CounterpartyInsufficientFunds':
      message =
        'The account does not have enough funds to complete this transaction. Please try again later or select a different account.';
      break;

    case 'PlaidBalanceUnavailable':
      message =
        "Sorry! We're unable to determine the balance of the account at this time. Please try again later.";
      break;

    case 'PlaidLoginRequired':
      message =
        'It looks like your Plaid authentication has expired. Please re-login to Plaid and try again.';
      break;

    case 'PlaidInvalidProduct':
    case 'PlaidUnsupportedBank':
      message =
        'Sorry! Plaid integration is not available for this product or bank. Please try a different account.';
      break;

    case 'DailyWireLimitExceeded':
      message =
        "You've exceeded the daily wire limit set by Flex. Please try again tomorrow.";
      break;

    case 'MonthlyWireLimitExceeded':
      message =
        "You've exceeded the monthly wire limit set by Flex. Please try again next month.";
      break;

    case 'PlaidInternalError':
      message =
        "We've encountered an error while connecting to Plaid. Please contact Flex for assistance.";
      break;
    case 'ProcessorRequestFailed':
      message =
        'We are sorry - there is an issue with your request. Please contact Flex for assistance.';
      break;
  }

  return message;
};

export type RejectedReason =
  | 'InsufficientFunds'
  | 'AccountClosed'
  | 'DailyACHCreditLimitExceeded'
  | 'MonthlyACHCreditLimitExceeded'
  | 'DailyACHDebitLimitExceeded'
  | 'MonthlyACHDebitLimitExceeded'
  | 'CounterpartyInsufficientFunds'
  | 'PlaidBalanceUnavailable'
  | 'PlaidLoginRequired'
  | 'PlaidTokenError'
  | 'PlaidTokenExpired'
  | 'PlaidInvalidProduct'
  | 'PlaidUnsupportedBank'
  | 'DailyWireLimitExceeded'
  | 'MonthlyWireLimitExceeded'
  | 'InvalidRoutingNumber'
  | 'WireRejected'
  | 'PlaidInternalError'
  | 'PlaidTokenError'
  | 'PlaidTokenExpired'
  | 'ProcessorRequestFailed'
  | 'SuspectedFraud'
  | 'ClientRequest'
  | 'NameMismatch'
  | 'AccountFrozen';
// This one is used in the payment details modal
// The rejected reasons can be found in the following links:
// Wire payments --> https://www.unit.co/docs/api/wires/#wire-reject-reasons
// ACH payments --> https://www.unit.co/docs/api/ach-origination/#ach-reject-reasons
// Book payments --> https://www.unit.co/docs/api/book-payments/#book-payment-reject-reasons
export const getPaymentRejectedMessage = (reason: RejectedReason) => {
  let message = '';
  switch (reason) {
    case 'InsufficientFunds':
      message =
        'This payment was rejected due to insufficient funds in the sending account at the time of processing.';
      break;

    case 'AccountClosed':
      message =
        'This payment was rejected because the associated account was closed at the time of processing.';
      break;

    case 'DailyACHCreditLimitExceeded':
      message =
        'This payment was rejected because it exceeded the daily ACH credit limit. This limit resets each day.';
      break;

    case 'MonthlyACHCreditLimitExceeded':
      message =
        'This payment was rejected because it exceeded the monthly ACH credit limit. This limit resets at the beginning of each month.';
      break;

    case 'DailyACHDebitLimitExceeded':
      message =
        'This payment was rejected because it exceeded the daily ACH debit limit. This limit resets each day.';
      break;

    case 'MonthlyACHDebitLimitExceeded':
      message =
        'This payment was rejected because it exceeded the monthly ACH debit limit. This limit resets at the beginning of each month.';
      break;

    case 'CounterpartyInsufficientFunds':
      message =
        'This payment was rejected because the linked account had insufficient funds at the time of processing.';
      break;

    case 'PlaidBalanceUnavailable':
      message =
        'This payment was rejected because we were unable to verify the account balance at the time of processing.';
      break;

    case 'PlaidLoginRequired':
      message =
        'This payment was rejected because the bank connection needed to be re-authenticated';
      break;

    case 'PlaidInvalidProduct':
    case 'PlaidUnsupportedBank':
      message =
        'This payment was rejected because the selected bank account is not supported for this type of transaction.';
      break;

    case 'DailyWireLimitExceeded':
      message =
        'This payment was rejected because it exceeded the daily wire transfer limit. This limit resets each day.';
      break;

    case 'MonthlyWireLimitExceeded':
      message =
        'This payment was rejected because it exceeded the monthly wire transfer limit. This limit resets at the beginning of each month.';
      break;

    case 'InvalidRoutingNumber':
      message =
        'This payment was rejected because the routing number was invalid.';
      break;

    case 'WireRejected':
      message =
        'This payment was rejected because the recipient rejected the payment.';
      break;

    case 'PlaidInternalError':
    case 'PlaidTokenError':
      message =
        'This payment was rejected due to a technical issue with the bank connection.';
      break;

    case 'PlaidTokenExpired':
    case 'ProcessorRequestFailed':
      message =
        'This payment was rejected due to a processing error. Please contact Flex support for assistance.';
      break;

    case 'SuspectedFraud':
      message =
        'This payment was rejected because it was suspected to be fraudulent. Please contact Flex support for assistance.';
      break;

    case 'ClientRequest':
      message = 'This payment was requested to be cancelled.';
      break;

    case 'NameMismatch':
      message =
        'This payment was rejected because the name on the payment does not match the name on the account.';
      break;

    case 'AccountFrozen':
      message = 'This payment was rejected because the account was frozen.';
      break;

    default:
      message =
        'This payment was rejected due to an unknown reason. Please contact Flex support for assistance.';
      break;
  }

  return message;
};
