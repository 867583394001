import { UseFormReturnType } from '@mantine/form';
import { SummaryEditInput } from './summary-edit-input';
import { PersonalInfoFormValues } from '../summary';
import FlexPatternFormat from '@common/flex-pattern-format';

type Props = {
  value: string;
  form: UseFormReturnType<PersonalInfoFormValues>;
  isEditMode: boolean;
};

const SummaryBirthdayForm = ({ value, form, isEditMode }: Props) => {
  return (
    <SummaryEditInput
      isEditMode={isEditMode}
      editInputs={
        <FlexPatternFormat
          format="##/##/####"
          inputMode="numeric"
          data-sardine-id="input-birthdate"
          {...form.getInputProps('birthDate')}
          label="Birthday"
          placeholder="MM/DD/YYYY"
          id="input-birthdate"
        />
      }
      label={'Birthday'}
      value={value}
    />
  );
};

export default SummaryBirthdayForm;
