import { useStyles } from './styles';
import { ReactNode } from 'react';
import { Badge, Box, BoxProps, Flex } from '@mantine/core';
import { PlaidRelinkOneAccButton } from './re-link-account';
import { PlaidAccount } from 'types/move-funds.model';
import { PiCaretDown } from 'react-icons/pi';

type Props = {
  onClick?: () => void;
  showArrow?: boolean;
  rotateArrow?: boolean;
  isListItem?: boolean;
  icon: ReactNode;
  headerText: string | ReactNode;
  subheaderText?: string | ReactNode;
  showBorder?: boolean;
  classNames?: Partial<{ containerClassName: string; headerText: string }>;
  isAccUnlinked?: boolean;
  onLinkingCompleted?: () => void;
  plaidAccount?: PlaidAccount;
  isError?: boolean;
} & BoxProps;

export const AccountBox = ({
  headerText,
  subheaderText,
  showArrow,
  classNames,
  isListItem,
  icon,
  showBorder,
  onClick,
  rotateArrow,
  isAccUnlinked,
  onLinkingCompleted,
  plaidAccount,
  isError,
  ...boxProps
}: Props) => {
  const { classes, cx, theme } = useStyles({ rotateArrow });

  return (
    <Box
      className={cx(
        classes.sendToBox,
        isListItem && classes.listItem,
        classNames?.containerClassName,
        isError && classes.error,
      )}
      style={!showBorder ? { border: 'none' } : {}}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      {...boxProps}
    >
      <div
        id="icon"
        className={classes.initials}
        style={isError ? { background: theme.colors.critical[0] } : {}}
      >
        {icon}
      </div>
      <Flex justify="space-between" align="center" w="80%">
        <div>
          <div className={cx(classes.optionText, classNames?.headerText)}>
            {headerText}
          </div>
          <div className={classes.optionInfoContainer}>{subheaderText}</div>
        </div>
        {isAccUnlinked && onLinkingCompleted && plaidAccount && (
          <PlaidRelinkOneAccButton
            buttonLabel="Re-link"
            plaidAccount={plaidAccount}
            onLinkingCompleted={onLinkingCompleted}
          />
        )}
        {isListItem && isAccUnlinked && (
          <Badge bg="critical.0" c="critical.6">
            Unlinked
          </Badge>
        )}
      </Flex>
      {showArrow && (
        <PiCaretDown
          className={classes.arrowIcon}
          fill={theme.colors.neutral[6]}
        />
      )}
    </Box>
  );
};
export default AccountBox;
