import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  header: {
    color: theme.colors.neutral[0],
    padding: rem(45),
    backgroundColor: theme.colors.primary[8],
    '@media(max-width: 767px)': {
      padding: rem(20),
    },
  },
  rowContent: {
    gap: rem(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  padding: {
    padding: `0px ${rem(50)}`,
    '@media(max-width: 767px)': {
      padding: `0px ${rem(20)}`,
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  alingEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paymentDetailsModal: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: rem(50),
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[3],
    '@media(max-width: 767px)': {
      paddingBottom: rem(20),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${rem(20)} ${rem(63)} 0px ${rem(63)}`,
    '@media (max-width: 768px)': {
      padding: rem(10),
    },
  },
  smallText: {
    fontSize: rem(14),
    color: theme.colors.neutral[8],
    fontWeight: 400,
    '@media (max-width: 768px)': {
      fontSize: rem(12),
    },
  },
  bigText: {
    fontSize: rem(16),
    color: theme.primaryColor,
    fontWeight: 500,
    '@media (max-width: 768px)': {
      fontSize: rem(14),
    },
  },
  statusText: {
    padding: `${rem(5)} ${rem(10)}`,
  },
  statusContainer: {
    border: 'solid',
    borderRadius: rem(8),
  },
  counterpartyAmountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${rem(15)} ${rem(63)} ${rem(15)} ${rem(63)}`,
    '@media (max-width: 768px)': {
      padding: rem(10),
    },
  },
  dateContainer: {
    padding: `${rem(15)} ${rem(63)} ${rem(15)} ${rem(63)}`,
    '@media (max-width: 768px)': {
      padding: rem(10),
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: rem(15),
  },
  detailsContainer: {
    padding: `0px ${rem(50)}`,
    '@media (max-width: 768px)': {
      padding: `0px ${rem(20)}`,
    },
  },
  paymentConfirmationDisclaimer: {
    padding: `0 ${rem(50)}`,
    '@media (max-width: 768px)': {
      padding: `0 ${rem(20)}`,
    },
    fontSize: rem(14),
    marginBottom: rem(20),
  },
  detailsData: {
    alignItems: 'flex-end',
    fontSize: rem(14),
    color: theme.colors.neutral[8],
  },

  docsContainer: {
    gap: rem(10),
    display: 'grid',
    padding: `0px ${rem(50)}`,
    '@media (max-width: 768px)': {
      padding: `0px ${rem(20)}`,
    },
  },

  docsBox: {
    gap: rem(10),
    fontSize: rem(14),
    display: 'flex',
    padding: `${rem(10)} ${rem(20)}`,
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors.neutral[8],
    border: `1px solid ${theme.colors.neutral[2]}`,
    borderRadius: theme.defaultRadius,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      backgroundColor: theme.colors.neutral[0],
    },
  },
  addAttachmentContent: {
    gap: rem(10),
    fontSize: rem(14),
    display: 'flex',
    color: theme.colors.neutral[8],
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.colors.primary[2],
      svg: {
        color: theme.colors.primary[2],
      },
    },
  },

  dropZone: {
    width: '100%',
    height: rem(160),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'unset',
    borderRadius: theme.defaultRadius,
    border: `1px dashed ${theme.colors.neutral[5]}`,
    '&:hover': {
      backgroundColor: theme.colors.neutral[0],
    },
  },

  loadingContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  receiptSection: {
    gap: rem(5),
    fontSize: rem(14),
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.colors.primary[2],
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  stepCompletedIcon: {
    display: 'none',
  },
  verticalSeparator: {
    top: 'unset',
    left: 'calc(1.4rem / 2)',
    borderLeftColor: theme.colors.neutral[6],
  },
  stepIcon: {
    width: rem(24),
    height: rem(24),
    minWidth: rem(24),
    color: theme.colors.neutral[0],
    borderColor: theme.colors.neutral[6],
    backgroundColor: theme.colors.neutral[0],
  },
  step: {
    margin: 0,
    // taking this calc from Mantine's style changing some values
    minHeight: 'calc(1.6rem + 1.5rem + calc(0.5rem / 2))',
  },
  stepLabel: {
    fontSize: rem(12),
    fontWeight: 600,
    color: theme.colors.neutral[10],
  },
  stepDescription: {
    fontSize: rem(12),
    color: theme.colors.neutral[10],
  },
}));
