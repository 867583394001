/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';
// Progress
import { createStyles } from '@mantine/emotion';
import { Flex, Text, rem, Badge } from '@mantine/core';
import { formatCurrency } from '../../../../utilities/formatters';
import { DollarAmountTitle } from '@common/composites/dollar-amount-title';

type Props = {
  title: ReactNode;
  totalAvailableBalance: number;
  currentAmount: number;
  currentAmountPercent?: boolean;
  classNames?: Partial<{ card: string; endContent: string }>;
  children?: ReactNode;
};

const ProgressPercent = ({
  title,
  totalAvailableBalance,
  currentAmountPercent,
  classNames,
  currentAmount,
  children,
}: Props) => {
  const { classes, cx, theme } = useStyles();
  const amountPercent =
    totalAvailableBalance > 0
      ? (currentAmount / totalAvailableBalance) * 100
      : 0;

  return (
    <div className={cx(classes.card, classNames?.card)}>
      <Text fz={rem(16)} c={theme.colors.neutral[8]} fw={500}>
        {title}
      </Text>
      <Flex align="center">
        <DollarAmountTitle
          dollarAmountString={formatCurrency(currentAmount)}
          size="sm"
        />
        {currentAmountPercent && (
          <Badge
            variant="light"
            color="primary.6"
            fz={20}
            p={rem(6)}
            fw={400}
            ml={6}
          >
            {Number(amountPercent.toFixed(2))}%
          </Badge>
        )}
      </Flex>
      {children}
    </div>
  );
};

export default ProgressPercent;

const useStyles = createStyles((theme) => ({
  card: {
    width: 'auto',
    height: rem(200),
    backgroundColor: theme.colors.neutral[0],
    borderRadius: rem(8),
    padding: rem(24),
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
  pp: {
    fontFamily: 'PP Neue Montreal',
  },
  endContent: {
    marginTop: 'auto',
  },
  flexGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacingTop: {
    marginTop: rem(16),
  },
}));
