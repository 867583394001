import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  input: {
    fontFamily: 'PP Neue Montreal',
    fontSize: rem(48),
    textAlign: 'center',
    height: 'fit-content',
    border: 'unset',
    borderRadius: 'unset',
    display: 'flex',
    '@media(max-width: 767px)': {
      fontSize: rem(32),
      marginTop: rem(16),
    },
    borderBottom: `${rem(3)} solid ${theme.colors.primary[2]}`,
  },
  inputRoot: { width: 'fit-content' },
  icon: { opacity: 0.8 },
  sectionHeader: {
    fontWeight: 500,
    fontSize: rem(14),
    color: theme.colors.primary[8],
    marginTop: rem(16),
    marginBottom: rem(8),
  },
  selectList: {
    position: 'absolute',
    maxHeight: rem(250),
    width: rem(356),
    overflow: 'scroll',
    marginTop: rem(4),
    backgroundColor: theme.colors.neutral[0],
    zIndex: 1,
    boxShadow: theme.shadows.sm,
    borderRadius: theme.defaultRadius,
  },
  textArea: {
    height: rem(152),
  },
  filesContainer: {
    minHeight: rem(50),
    display: 'column',
    justifyContent: 'center',
  },
  file: {
    width: '100%',
    height: rem(40),
    margin: `${rem(8)} 0px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: rem(8),
    fontSize: rem(12),
    fontWeight: 500,
    color: theme.colors.neutral[7],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
  deleteFile: {
    all: 'unset',
    width: rem(24),
    height: rem(24),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dropZone: {
    marginBottom: rem(16),
    gap: rem(10),
    padding: rem(12),
    width: '100%',
    fontSize: rem(14),
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    color: theme.colors.neutral[7],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    '&:hover': {
      backgroundColor: theme.colors.neutral[3],
    },
  },
}));
