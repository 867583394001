import {
  Alert,
  Anchor,
  Box,
  Button,
  Group,
  Radio,
  rem,
  Stack,
  Switch,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useStyles } from './invite-user.styles';
import { useForm } from '@mantine/form';
import {
  RequiredFieldValidator,
  validateRequired,
} from '../../../utilities/validators/validate-required';
import ModalSuccess from '@common/modal-success';
import { v4 as uuidv4 } from 'uuid';
import OptionBox from './option-box';
import {
  ApplicationState,
  IsFullAdmin,
  OptedProduct,
} from '../../../recoil-state/application/product-onboarding';
import { CustomMantineStyles } from '@common/cards-styles';
import { EmailValidator } from '../../../utilities/validators/validate-email';
import { roleDescriptions } from 'constants/index';
import { useCreditBalance } from '@queries/use-credit-balance';
import { formatCurrency } from '@utilities/formatters';
import { LimitInterval } from 'constants/limit-intervals';
import { useCreateCardCategory } from '../../../queries/use-credit-cards';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import { platformClient } from '../../../services/platform/platform-client';
import { useMarqetaUiFeatureFlag } from '@utilities/feature-flags';
import {
  IssueLithicCreditCardForm,
  useIssueLithicCreditCardForm,
} from '@common/issue-lithic-credit-card-form';
import {
  CardFulfillment,
  QueuedCard,
  SpendRestrictionYaml,
} from '@flexbase-eng/sdk-typescript/models/components';
import { validatePostalCode } from '@utilities/validators';
import {
  useCardTypes,
  useMarqetaCardProgram,
} from '@queries/use-credit-programs';
import { useMarqetaCreditLines } from '@queries/use-credit-lines';
import { isTruthyString } from '@utilities/validators/validate-string';
import { RFCDate } from '@flexbase-eng/sdk-typescript/types';
import {
  IssueMarqetaCreditCardForm,
  IssueMarqetaCreditCardFormType,
  useIssueMarqetaCreditCardForm,
} from '@common/issue-marqeta-credit-card-form';
import { getEnvironment } from '@utilities/url/window-helpers';

export type UserInviteFormValues = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  roles: string[];
};

export type CardDetails = {
  cardType: 'physical' | 'virtual' | 'both';
  limits?: {
    interval?: LimitInterval;
    amount?: number;
  };
  cardName?: string;
};

type Props = { closeModal: () => void; refreshTeamMembers?: () => void };

type RolesProps = {
  label: string;
  value: string[];
  description: string;
  products: OptedProduct[];
};

const roles: RolesProps[] = [
  {
    products: ['BANKING', 'CREDIT'],
    label: 'Admin - Full',
    value: ['ADMIN', 'COMPTROLLER'],
    description: roleDescriptions.adminFull,
  },
  {
    products: ['BANKING', 'CREDIT'],
    label: 'Admin - Limited',
    value: ['ADMIN'],
    description: roleDescriptions.adminLimited,
  },
  {
    products: ['CREDIT'],
    label: 'Employee',
    value: ['EMPLOYEE'],
    description: roleDescriptions.employee,
  },
  {
    products: ['BANKING', 'CREDIT'],
    label: 'Bookkeeper',
    value: ['ACCOUNTANT'],
    description: roleDescriptions.bookKeeper,
  },
];

const CARD_FORM_STACK_SPACING = 'lg';
const CARD_FORM_RADIO_SPACING = 'lg';

const InviteUser = ({ closeModal, refreshTeamMembers }: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isMarqetaUiEnabled = useMarqetaUiFeatureFlag();
  const { accountId, businessId, optedProducts, personId } =
    useRecoilValue(ApplicationState);
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();
  const { data: creditBalance } = useCreditBalance(businessId);
  const { data: marqetaCardProgram } = useMarqetaCardProgram();
  const { data: marqetaCreditLines = [] } = useMarqetaCreditLines(businessId);
  const { data: cardTypes = [] } = useCardTypes(personId);
  const { mutate: mutateCategoryCard } = useCreateCardCategory();
  const [preissueCard, setPreissueCard] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<'invite' | 'card' | 'success'>('invite');
  const [selectedCreditIssuer, setSelectedCreditIssuer] = useState<
    'lithic' | 'marqeta'
  >('lithic');
  const isFullAdmin = useRecoilValue(IsFullAdmin);

  const getCorporeality = (cardTypeId: string) => {
    return cardTypes.find((ct) => ct.id === cardTypeId)?.corporeality;
  };

  const requiredIfPhysical = (errMsg: string) => {
    return (value: string, values: IssueMarqetaCreditCardFormType) => {
      const isPhysical = getCorporeality(values.cardTypeId) === 'physical';

      return !isPhysical || !!value ? null : errMsg;
    };
  };

  const userInviteForm = useForm<UserInviteFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      roles: [],
    },
    validate: {
      firstName: (v) =>
        validateRequired(v)
          ? null
          : 'Please enter the first name of the user you wish to invite.',
      lastName: (v) =>
        validateRequired(v)
          ? null
          : 'Please enter the last name of the user you wish to invite.',
      emailAddress: (v) =>
        EmailValidator()(v) === null
          ? null
          : 'Please enter an email address for the user you wish to invite.',
      roles: (v) =>
        v && v.length > 0
          ? null
          : 'Please select at least one role for the user you wish to invite.',
    },
  });

  const lithicCardForm = useIssueLithicCreditCardForm({
    initialValues: {
      userId: '',
      cardType: 'virtual',
      categoryId: '',
      spendingLimitInterval: undefined,
      spendingLimit: 0,
      cardName: '',
      limitMerchantCategories: 'no',
      groups: [],
      terminateAt: null,
      autoExpire: 'no',
    },
    validate: {
      cardName: (value) => {
        if (!preissueCard) {
          return null;
        }

        return value ? null : 'Card name is required.';
      },
      spendingLimit: (value, values) => {
        if (!preissueCard || values.spendingLimitInterval === 'unlimited') {
          return null;
        }

        return creditBalance?.creditLimit && value > creditBalance.creditLimit
          ? `Limit amount cannot exceed credit limit of ${formatCurrency(creditBalance.creditLimit)}`
          : null;
      },
    },
  });

  const marqetaCardForm = useIssueMarqetaCreditCardForm({
    initialValues: {
      userId: '',
      cardTypeId: '',
      cardName: '',
      spendingLimit: 0,
      spendingLimitInterval: undefined,
      groups: [],
      categoryId: '',
      terminateAt: null,
      limitMerchantCategories: 'no',
      autoExpire: 'no',
      street1: '',
      street2: '',
      locality: '',
      region: '',
      postalCode: '',
      country: 'US',
      shippingMethod: 'standard',
    },
    validate: {
      spendingLimit: (value, values) => {
        if (values.spendingLimitInterval !== 'unlimited' && value <= 0) {
          return 'Spending limit must be greater than 0 unless limit type is unlimited.';
        }
        if (creditBalance?.creditLimit && value > creditBalance.creditLimit) {
          return `Limit amount cannot exceed credit limit of ${formatCurrency(
            creditBalance.creditLimit,
          )}`;
        }
        return null;
      },
      cardName: RequiredFieldValidator('Card name is required.'),
      cardTypeId: RequiredFieldValidator('Card type is required'),
      spendingLimitInterval: RequiredFieldValidator('Select a limit type'),
      street1: requiredIfPhysical('Street is required'),
      locality: requiredIfPhysical('City is required'),
      region: requiredIfPhysical('State is required'),
      postalCode: (value, values) => {
        const isPhysical = getCorporeality(values.cardTypeId) === 'physical';

        if (!isPhysical) {
          return null;
        }

        return (
          requiredIfPhysical('A zip code is required')(value, values) ||
          (validatePostalCode(value) ? null : `A valid zip code is required`)
        );
      },
    },
  });

  const availableRoles = roles.filter((role) => {
    if (role.label === 'Admin - Full') {
      if (!isFullAdmin) {
        return false;
      }
    }
    return true;
  });

  const isVirtualCard = lithicCardForm?.values?.cardType === 'virtual';
  const hasIntegrationLinks = connectionId ? true : false;
  const shouldRenderCategory =
    isVirtualCard && hasIntegrationLinks && !!expenseLink?.enabledExpenses;

  const currentEnv = getEnvironment() || '';
  const isDevEnv = ['development', 'local', 'deploy-preview'].some((env) =>
    currentEnv.includes(env),
  );
  const showMarqetaToggle = isMarqetaUiEnabled && isDevEnv;
  const isUsingMarqetaForm =
    isMarqetaUiEnabled && isDevEnv && selectedCreditIssuer === 'marqeta';

  const sendInvite = async () => {
    try {
      setLoading(true);
      const formValues = userInviteForm.values;
      const validationResult = userInviteForm.validate();
      const isInviteValid = !validationResult.hasErrors;

      if (!isInviteValid) {
        return;
      }

      const sendInviteRequest = async ({
        cardDetails,
        queuedCard,
      }: {
        cardDetails?: CardDetails[];
        queuedCard?: QueuedCard;
      } = {}) => {
        try {
          const result = await platformClient.inviteUser(
            accountId,
            formValues.emailAddress,
            'user',
            {
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              roles: formValues.roles,
              cardDetails,
              companyId: businessId,
            },
            queuedCard,
          );

          if (shouldRenderCategory && lithicCardForm.values.categoryId) {
            mutateCategoryCard({
              categoryId: lithicCardForm.values.categoryId || '',
              cardName: lithicCardForm.values.cardName,
              userId: result.person.id,
              connectionId,
            });
          }

          setError('');
          setStep('success');

          if (refreshTeamMembers) {
            refreshTeamMembers();
          }
        } catch {
          // Platform actually returns decent errors so we could better target this error message
          setError('There was an error inviting the new user.');
        }
      };

      // if we're not preissuing a card, just invite the user
      if (!preissueCard) {
        await sendInviteRequest();
        return;
      }

      const preissueValidation = isUsingMarqetaForm
        ? marqetaCardForm.validate()
        : lithicCardForm.validate();
      const isPreissueFormValid = !preissueValidation.hasErrors;

      if (!isPreissueFormValid) {
        return;
      }

      const preissueRequest = ((): {
        cardDetails?: CardDetails[];
        queuedCard?: QueuedCard;
      } => {
        if (isUsingMarqetaForm) {
          const cardFormValues = marqetaCardForm.values;
          const cardForm = getCorporeality(cardFormValues.cardTypeId);

          if (!cardForm) {
            return {};
          }

          const fulfillment: CardFulfillment | undefined = (() => {
            // address only required when issuing a physical card
            if (cardForm !== 'physical') {
              return undefined;
            }

            return {
              shippingAddress: {
                address: {
                  street: [
                    cardFormValues.street1,
                    cardFormValues.street2,
                  ].filter(isTruthyString),
                  locality: cardFormValues.locality,
                  region: cardFormValues.region,
                  postalCode: cardFormValues.postalCode,
                  country: cardFormValues.country,
                },
                shippingMethod: 'standard' as const,
              },
            };
          })();

          const limits: SpendRestrictionYaml = (() => {
            const isUnlimited =
              cardFormValues.spendingLimitInterval === 'unlimited';

            return {
              amount: isUnlimited
                ? undefined
                : {
                    amount: Math.round(cardFormValues.spendingLimit * 100),
                    scale: 2,
                    currency: 'USD',
                  },
              interval: cardFormValues.spendingLimitInterval,
              groups: cardFormValues.groups,
            };
          })();

          return {
            queuedCard: {
              programId: marqetaCardProgram?.id ?? '',
              cardTypeId: cardFormValues.cardTypeId,
              lineOfCreditId: marqetaCreditLines.find(Boolean)?.id ?? '',
              idempotencyKey: uuidv4(),
              name: cardFormValues.cardName,
              form: cardForm,
              suspendDate: cardFormValues.terminateAt
                ? new RFCDate(cardFormValues.terminateAt)
                : undefined,
              shipping: fulfillment?.shippingAddress,
              limits: limits,
            },
          };
        } else {
          const cardFormValues = lithicCardForm.values;

          const cardLimits = {
            ...(cardFormValues.spendingLimitInterval && {
              interval: cardFormValues.spendingLimitInterval,
            }),
            amount:
              cardFormValues.spendingLimitInterval &&
              cardFormValues.spendingLimitInterval !== 'unlimited'
                ? cardFormValues.spendingLimit
                : undefined,
            ...(cardFormValues.limitMerchantCategories === 'yes' &&
              cardFormValues.groups.length && {
                groups: cardFormValues.groups,
              }),
          };

          return {
            cardDetails: [
              {
                cardType: cardFormValues.cardType,
                limits: cardLimits,
                cardName: cardFormValues.cardName,
                ...(cardFormValues.autoExpire === 'yes' && {
                  terminateAt: cardFormValues.terminateAt,
                }),
              },
            ],
          };
        }
      })();

      await sendInviteRequest(preissueRequest);
    } catch (err) {
      if (err.message.includes('supplied email is already in use')) {
        setError(
          'An account with this email already exists. Please use different email or contact customer support at 415-840-8721.',
        );
      } else {
        setError('There was an error inviting the new user.');
      }
      console.error('Unable to invite user', err);
    } finally {
      setLoading(false);
    }
  };

  const onNextClick = () => {
    const validationResult = userInviteForm.validate();
    if (validationResult.errors.role) {
      setError('Please select a role');
    } else {
      setError('');
    }
    if (!validationResult.hasErrors && optedProducts.includes('CREDIT')) {
      setStep('card');
    } else {
      sendInvite();
    }
  };

  const contents = {
    success: (
      <ModalSuccess
        title={`Invite sent to ${userInviteForm.values.firstName}!`}
        backTo="team page"
        closeModal={closeModal}
        onClick={() => {
          userInviteForm.reset();
          lithicCardForm.reset();
          setStep('invite');
        }}
        textToStart="Invite another person"
      />
    ),
    invite: (
      <Box className={classes.container}>
        <Text className={classes.title}>Invite team members</Text>
        <Box className={classes.inputContainer}>
          <TextInput
            label="First Name"
            w={'167px'}
            style={{ paddingTop: 20 }}
            placeholder="First Name"
            {...userInviteForm.getInputProps('firstName')}
          />
          <TextInput
            label="Last Name"
            w={'167px'}
            ml={'1rem'}
            style={{ paddingTop: 20 }}
            placeholder="Last Name"
            {...userInviteForm.getInputProps('lastName')}
          />
        </Box>
        <TextInput
          label="Email"
          style={{ paddingTop: 20 }}
          placeholder="Email address"
          {...userInviteForm.getInputProps('emailAddress')}
        />

        <Box>
          <Box className={classes.rolesLabel}>Assign role</Box>
          <Box className={classes.rolesDescription}>
            A team member&apos;s role determines what they can see and do on
            your Flex account.{' '}
            <Anchor
              className={classes.link}
              href="https://flexbase.zendesk.com/hc/en-us/articles/9118462437261-What-are-the-types-of-user-roles-on-flexbase-"
              target="_blank"
            >
              Learn more about roles
            </Anchor>
          </Box>
          {availableRoles
            .filter((role) =>
              role.products.some((product) => optedProducts.includes(product)),
            )
            .map((role) => (
              <OptionBox
                key={role.label}
                optionLabel={role.label}
                optionDescription={role.description}
                isSelected={
                  userInviteForm.values.roles &&
                  role.value.length === userInviteForm.values.roles.length &&
                  role.value.every((roleItem) =>
                    userInviteForm.values.roles.includes(roleItem),
                  )
                }
                onSelect={() =>
                  userInviteForm.setFieldValue('roles', role.value)
                }
              />
            ))}
        </Box>

        <Text style={{ paddingBottom: 10, color: theme.colors.critical[3] }}>
          {error}
        </Text>
        <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
          <Button
            variant="neutral-outline"
            disabled={loading}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary-light"
            loading={loading}
            onClick={() => onNextClick()}
          >
            {optedProducts.includes('CREDIT') ? 'Next' : 'Send Invite'}
          </Button>
        </Box>
      </Box>
    ),
    card: (
      <Box className={classes.issueContainer} w="100%">
        <Stack gap={CARD_FORM_STACK_SPACING}>
          {selectedCreditIssuer === 'lithic' ? (
            <Text className={classes.title}>Create a virtual credit card</Text>
          ) : (
            <Text className={classes.title}>Issue a credit card</Text>
          )}

          <Radio.Group
            label="Issue virtual credit card?"
            size="sm"
            value={preissueCard ? 'yes' : 'no'}
            onChange={(v) => setPreissueCard(v === 'yes')}
            color={theme.primaryColor}
            aria-label="card-type"
          >
            <Group mt="xs" gap={CARD_FORM_RADIO_SPACING}>
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="yes-card"
                value="yes"
                label="Yes"
              />
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="no-card"
                value="no"
                label="No"
              />
            </Group>
          </Radio.Group>

          {showMarqetaToggle && (
            <Switch
              label="Use Marqeta instead of Lithic"
              description="For dev testing"
              checked={selectedCreditIssuer === 'marqeta'}
              onChange={(e) =>
                setSelectedCreditIssuer(e.target.checked ? 'marqeta' : 'lithic')
              }
            />
          )}

          {selectedCreditIssuer === 'lithic' ? (
            <Alert
              color="info"
              withCloseButton={false}
              styles={{
                root: {
                  borderWidth: 0,
                },
                message: {
                  fontSize: rem(12),
                },
              }}
            >
              Your team member will be able to start using this card immediately
              after they finish creating an account.
            </Alert>
          ) : null}

          {selectedCreditIssuer === 'lithic' ? (
            <IssueLithicCreditCardForm
              form={lithicCardForm}
              disabled={!preissueCard}
              preissue
            />
          ) : (
            <IssueMarqetaCreditCardForm
              form={marqetaCardForm}
              disabled={!preissueCard}
              preissue
            />
          )}

          {error && (
            <Text mt="1rem" c={theme.colors.critical[3]}>
              {error}
            </Text>
          )}

          <Box
            mt="1rem"
            style={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Button
              variant="neutral-outline"
              disabled={loading}
              onClick={() => setStep('invite')}
            >
              Back
            </Button>
            <Button
              variant="primary-light"
              loading={loading}
              onClick={() => sendInvite()}
            >
              Send Invite
            </Button>
          </Box>
        </Stack>
      </Box>
    ),
  };

  return contents[step];
};

export default InviteUser;
