import { TextInput } from '@mantine/core';
import { SummaryEditInput } from './summary-edit-input';
import { CompanyInfoFormValues } from '../summary';
import { UseFormReturnType } from '@mantine/form';

type Props = {
  value: string;
  form: UseFormReturnType<CompanyInfoFormValues>;
  isEditMode: boolean;
};

const SummaryBusinessPurposeForm = ({ value, form, isEditMode }: Props) => {
  return (
    <SummaryEditInput
      isEditMode={isEditMode}
      value={value}
      editInputs={
        <TextInput
          placeholder="Business purpose"
          label="What is the your business's purpose?"
          {...form.getInputProps('businessPurpose')}
          id="summary-business-purpose"
        />
      }
      label={'Business purpose'}
    />
  );
};

export default SummaryBusinessPurposeForm;
