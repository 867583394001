import QualificationPageShell from './components/qualification-page-shell';
import { RegistrationProductsState } from 'recoil-state/application/product-onboarding';
import { useMemo } from 'react';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import { useGetQualificationApplication } from './use-qualification-application';
import { Analytics } from '@services/analytics/analytics';
import { QualificationApplicationEvents } from '@services/analytics/events';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';
import { useSetRecoilState } from 'recoil';
import QualificationFullRejection from './components/qualification-full-rejection';
import QualificationAccepted from './components/qualification-accepted';

const QualificationCompletedPage = () => {
  const navigate = useMarketingNavigate();
  const { data: application } = useGetQualificationApplication();
  const setSelectedProducts = useSetRecoilState(RegistrationProductsState);
  const isRejected = application?.status === 'Rejected';

  const deniedTitleText = useMemo(() => {
    return "Based on your answers, Flex isn't a fit";
  }, []);

  const deniedSubtitleText = useMemo(() => {
    if (
      !application?.fields?.intendedProducts?.length ||
      application?.fields?.intendedProducts.length === 2
    ) {
      return 'We currently only offer credit and banking services to companies that:';
    }
    return `We currently only offer ${capitalizeOnlyFirstLetter(application?.fields?.intendedProducts[0]?.toLowerCase() || '')} services to companies that:`;
  }, [application?.fields?.intendedProducts]);

  const handleContinueClick = () => {
    Analytics.track(
      QualificationApplicationEvents.QUALIFICATION_APPLICATION_COMPLETED_CONTINUE_CLICKED,
      {
        application,
      },
    );
    setSelectedProducts(application?.fields?.intendedProducts || []);
    navigate('/prefill');
  };

  return (
    <QualificationPageShell
      handleContinueClick={handleContinueClick}
      titleText={isRejected ? deniedTitleText : undefined}
      subtitleText={isRejected ? deniedSubtitleText : undefined}
      hideFooter
      hideNavigation={isRejected}
      progress={100}
    >
      {isRejected ? <QualificationFullRejection /> : <QualificationAccepted />}
    </QualificationPageShell>
  );
};

export default QualificationCompletedPage;
