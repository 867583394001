import { useForm } from '@mantine/form';
import {
  Alert,
  Box,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import FlexPatternFormat from '@common/flex-pattern-format';
import { useStartScreenStyles } from '../../steps/start-screen/styles';
import { TermsOfServiceCheckboxPrompt } from '@common/utilities/terms-prompt';
import { RequiredFieldValidator } from '../../../../utilities/validators/validate-required';
import { EmailValidator } from '@utilities/validators';
import { validateIntlPhoneNumber } from '@utilities/validators/validate-phone-number';
import PasswordStrengthPopover from '@common/password-strength-popover';
import {
  RegistrationFormReturnType,
  useRegistration,
} from '../../steps/start-screen/use-registration';
import { RedAlertIcon } from '../../../../assets/svg';
import { validateUSBirthDate } from '../../../../utilities/validators/validate-dates';
import { MultiStepFormStepWrapper } from '@common/composites/multi-step-form/multi-step-form-step-wrapper';
import { MultiStepFormSubmitButton } from '@common/composites/multi-step-form/multi-step-form-submit-button';
import { useRecoilValue } from 'recoil';
import { RegistrationProductsState } from '../../../../recoil-state/application/product-onboarding';
import { useMaintenanceFlag } from '@utilities/feature-flags';
import { REGISTRATION_COPY } from './registration.copy';
import { NewPasswordValidator } from '../../../../utilities/validators/validate-password';
import { DateTime } from 'luxon';
import { FlexIntlPhoneInput } from '@common/composites/input/flexbase-intl-phone-input';
import { noop } from 'underscore';
import { ApplicationEvents } from '@services/analytics/events';
import { Analytics } from '@services/analytics/analytics';

export const RegistrationForm = () => {
  const isMaintenance = useMaintenanceFlag();
  const { classes } = useStartScreenStyles();
  const registeringForProducts = useRecoilValue(RegistrationProductsState);

  const isBankingOnly = registeringForProducts.every((p) => p === 'BANKING');

  const rForm = useForm<RegistrationFormReturnType>({
    initialValues: {
      birthday: '',
      cellphone: '',
      cellphoneCountryCode: 'US',
      email: '',
      password: '',
      termsOfServiceSigned: false,
    },
    validate: {
      birthday: (val) =>
        validateUSBirthDate(val || '')
          ? null
          : 'Must be at least 18 in format MM/DD/YYYY.',
      cellphone: (val, formVals) =>
        validateIntlPhoneNumber(val, formVals.cellphoneCountryCode)
          ? null
          : 'Invalid phone number.',
      password: NewPasswordValidator(),
      termsOfServiceSigned: RequiredFieldValidator(),
      email: EmailValidator(),
    },
  });

  const { loading, registerNewUser, errorMessage, setErrorMessage } =
    useRegistration(rForm, { usePrefillApplication: true });

  const handleSubmit = async () => {
    if (!isMaintenance) {
      Analytics.track(ApplicationEvents.APPLICATION_START_CLICKED, {
        products: registeringForProducts,
      });
      await registerNewUser();
    }
  };

  return (
    <MultiStepFormStepWrapper
      id="start-application"
      onFormSubmit={handleSubmit}
    >
      <Stack gap="1.25rem" w="100%">
        {isMaintenance && (
          <Alert my="lg" withCloseButton={false}>
            New applications are disabled while we perform routine maintenance.
            Maintenance is expected to last until{' '}
            {DateTime.now()
              .plus({ hours: 1 })
              .endOf('hour')
              .toLocaleString(DateTime.TIME_SIMPLE)}
          </Alert>
        )}
        {!isBankingOnly && (
          <Text px="md" py=".5rem" className={classes.noCreditImpactChip}>
            No credit impact
          </Text>
        )}
        <Text ff="Redaction" fz="3rem" lh="normal">
          {isBankingOnly
            ? REGISTRATION_COPY.banking.title
            : REGISTRATION_COPY.credit.title}
        </Text>
        <Text fz={20} lh="normal">
          {isBankingOnly
            ? REGISTRATION_COPY.banking.subtitle
            : REGISTRATION_COPY.credit.subtitle}
        </Text>
        <Box className={classes.gridInputContainer}>
          <FlexPatternFormat
            format="##/##/####"
            inputMode="numeric"
            label="Date of birth"
            placeholder="mm/dd/yyyy"
            data-testid="input-birthdate"
            classNames={{
              input: classes.input,
              label: classes.label,
            }}
            {...rForm.getInputProps('birthday')}
          />
          <FlexIntlPhoneInput
            inputMode="tel"
            label="Mobile number"
            placeholder="Mobile number"
            data-testid="input-phone"
            classNames={{
              input: classes.input,
              label: classes.label,
            }}
            {...rForm.getInputProps('cellphone')}
            onChange={noop}
            countryCode={rForm.values.cellphoneCountryCode}
            onCountryCodeChange={(c) =>
              rForm.setFieldValue('cellphoneCountryCode', c)
            }
            onValueChange={(e) =>
              rForm.setFieldValue('cellphone', e.formattedValue)
            }
          />
          <TextInput
            label="Email"
            placeholder="Email"
            inputMode="email"
            data-testid="input-email"
            classNames={{
              input: classes.input,
              label: classes.label,
            }}
            {...rForm.getInputProps('email')}
          />
          <PasswordStrengthPopover inputValue={rForm.values.password}>
            <PasswordInput
              label="Password"
              placeholder="Password"
              data-testid="input-password"
              classNames={{
                input: classes.input,
                label: classes.label,
              }}
              {...rForm.getInputProps('password')}
            />
          </PasswordStrengthPopover>
        </Box>
        <TermsOfServiceCheckboxPrompt
          type="flexbase"
          color="primary.2"
          {...rForm.getInputProps('termsOfServiceSigned', { type: 'checkbox' })}
          classNames={{
            input: classes.input,
            label: classes.label,
          }}
        />
        {errorMessage && (
          <Alert
            icon={<RedAlertIcon />}
            className={classes.alert}
            my="lg"
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        )}
        <MultiStepFormSubmitButton
          variant="primary-light"
          form="start-application"
          data-testid="button-start-application"
          loading={loading}
          disabled={isMaintenance}
        >
          Start Application
        </MultiStepFormSubmitButton>
      </Stack>
    </MultiStepFormStepWrapper>
  );
};
