import React from 'react';
import { Flex, Alert, Button } from '@mantine/core';

type QueryErrorMessageProps = {
  message: string;
  refetch: () => void;
};

export const QueryErrorMessage: React.FC<QueryErrorMessageProps> = ({
  message,
  refetch,
}) => {
  return (
    <Flex direction="column" justify="center" align="center">
      <Alert color="red" mb="md" ta="center">
        {message}
      </Alert>
      <Button mt="md" onClick={refetch}>
        Re-try
      </Button>
    </Flex>
  );
};

export default QueryErrorMessage;
