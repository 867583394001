import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useQueryParams } from '../../../../utilities/url/query-param';
import { Group, Radio, rem, Stack, Text, useMantineTheme } from '@mantine/core';
import { CardIcon } from 'assets/svg';
import {
  OptedProduct,
  RegistrationProductsState,
} from 'recoil-state/application/product-onboarding';
import QualificationPageShell from './components/qualification-page-shell';
import { QualificationApplicationEvents } from 'services/analytics/events';
import { Analytics } from '@services/analytics/analytics';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';

const QualificationStartPage = () => {
  const theme = useMantineTheme();
  const navigate = useMarketingNavigate();
  const [searchParams] = useSearchParams();
  const query = useQueryParams();
  const productQueryParam = query.get('product');
  const [products, setProducts] = useRecoilState(RegistrationProductsState);
  const [selectedProduct, setSelectedProduct] = useState<string>(
    products.length === 2 || !products.length ? 'ALL' : products[0],
  );
  const segmentAnon = searchParams.get('segment_anon');

  if (segmentAnon) {
    localStorage.setItem('segment_anon', segmentAnon);
  }

  useEffect(() => {
    // If there is a query param, always set the product, even if one was previously set.
    if (productQueryParam) {
      switch (productQueryParam) {
        case 'banking':
          setProducts(['BANKING']);
          break;
        case 'credit':
          setProducts(['CREDIT']);
          break;
        case 'all':
          setSelectedProduct('ALL');
          break;
      }
    }
  }, [productQueryParam]);

  const handleContinueClick = () => {
    if (!selectedProduct) return;

    Analytics.track(
      QualificationApplicationEvents.QUALIFICATION_APPLICATION_START_CLICKED,
      {
        products:
          selectedProduct === 'ALL' ? ['CREDIT', 'BANKING'] : [selectedProduct],
      },
    );

    if (selectedProduct === 'ALL') {
      setProducts(['CREDIT', 'BANKING']);
    } else {
      setProducts([selectedProduct as OptedProduct]);
    }

    navigate('register');
    window.scroll(0, 0);
  };

  return (
    <QualificationPageShell
      titleText="Which Flex products are you interested in?"
      subtitleText="We'll customize your experience accordingly."
      progress={25}
      handleContinueClick={selectedProduct ? handleContinueClick : undefined}
    >
      <Stack px={{ base: 'lg', sm: 0 }}>
        <Radio.Group
          value={selectedProduct}
          onChange={setSelectedProduct}
          name="selectedProduct"
        >
          <Stack gap="sm">
            <Radio.Card
              value="ALL"
              bg="white"
              px="md"
              h={rem(100)}
              radius="sm"
              bd={`1px solid ${theme.colors.neutral[4]}`}
            >
              <Group align="center">
                <Radio.Indicator />
                <CardIcon color={theme.colors.neutral[5]} width={20} />
                <Stack flex={1} gap={0}>
                  <Text fw="bold">Credit Cards and Banking</Text>
                </Stack>
              </Group>
            </Radio.Card>
            <Radio.Card
              value="CREDIT"
              bg="white"
              px="md"
              h={rem(100)}
              radius="sm"
              bd={`1px solid ${theme.colors.neutral[4]}`}
            >
              <Group align="center">
                <Radio.Indicator />
                <CardIcon color={theme.colors.neutral[5]} width={20} />
                <Stack flex={1} gap={0}>
                  <Text fw="bold">Credit Cards</Text>
                  <Text fz="sm" c={theme.colors.neutral[7]}>
                    Flexible Cashback
                  </Text>
                </Stack>
              </Group>
            </Radio.Card>
            <Radio.Card
              value="BANKING"
              bg="white"
              px="md"
              h={rem(100)}
              radius="sm"
              bd={`1px solid ${theme.colors.neutral[4]}`}
            >
              <Group align="center">
                <Radio.Indicator />
                <CardIcon color={theme.colors.neutral[5]} width={20} />
                <Stack flex={1} gap={0}>
                  <Text fw="bold">Banking</Text>
                  <Text fz="sm" c={theme.colors.neutral[7]}>
                    Earn up to 2.99% APY
                  </Text>
                </Stack>
              </Group>
            </Radio.Card>
          </Stack>
        </Radio.Group>
      </Stack>
    </QualificationPageShell>
  );
};

export default QualificationStartPage;
