import { useEffect, useMemo } from 'react';
import {
  Button,
  Flex,
  Group,
  Image,
  rem,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import QualificationPageShell from './components/qualification-page-shell';
import {
  useCreateRutterConnection,
  useIntegrationLinks,
} from '@utilities/integrations';
import { PiCalculatorBold, PiLinkBreakBold } from 'react-icons/pi';
import { createStyles } from '@mantine/emotion';
import { AccountingPlatforms } from 'constants/accounting-integrations';
import { getIntegrationCards } from '@utilities/integrations/list-integrations';
import { useDisconnectIntegration } from '@utilities/integrations/use-disconnect-integration';
import {
  useGetQualificationApplication,
  useUpdateApplicationRequirement,
} from './use-qualification-application';
import { useQualificationApplication } from 'areas/onboarding-v2/qualification-application-provider';
import { Analytics } from '@services/analytics/analytics';
import { QualificationApplicationEvents } from '@services/analytics/events';
import QuickbooksGreen from 'assets/images/quickbooks.png';
import Netsuite from 'assets/images/oracle-netsuite.png';
import Xero from 'assets/images/xero.png';
import { showNotification } from '@mantine/notifications';

const INTEGRATIONS = getIntegrationCards();

const QualificationConnectAccountToolPage = () => {
  const { classes, theme } = useStyles();
  const {
    data: application,
    refetch: refetchApplication,
    isLoading: isLoadingApplication,
  } = useGetQualificationApplication();
  const isRejected = application?.status === 'Rejected';
  const { navigateToNextStep, goBack } = useQualificationApplication();
  const {
    mutate: updateApplicationRequirement,
    isPending: isUpdatingApplicationRequirement,
  } = useUpdateApplicationRequirement();
  const { openRutterLink, isExchangingToken, isRutterConnecting } =
    useCreateRutterConnection();
  const { accountingLinks, isPending } = useIntegrationLinks();
  const { disconnectIntegration, isPending: isDisconnecting } =
    useDisconnectIntegration();

  const isLoading =
    isLoadingApplication ||
    isPending ||
    isExchangingToken ||
    isRutterConnecting ||
    isDisconnecting;

  const mappedAccountingLinks = useMemo(() => {
    return accountingLinks.map((link) => {
      const integration = INTEGRATIONS.find(
        (i) => i.platform === link.platform,
      );

      return {
        ...link,
        ...integration,
      };
    });
  }, [accountingLinks]);

  useEffect(() => {
    // If the user has connected an accounting tool, we need to refetch the application to get the latest status
    if (accountingLinks.length) {
      refetchApplication();
    }
  }, [accountingLinks.length]);

  const handleBackClick = () => {
    goBack();
  };

  const handleAccountingToolClick = (platform: AccountingPlatforms) => {
    openRutterLink(platform);
  };

  const handleDisconnectClick = (
    platform: (typeof mappedAccountingLinks)[number],
  ) => {
    disconnectIntegration({
      platformLabel: platform.title || '',
      connectionId: platform.connectionId,
    });
  };

  const handleContinue = () => {
    Analytics.track(
      QualificationApplicationEvents.QUALIFICATION_APPLICATION_CONNECT_ACCOUNTING_CONTINUE_CLICKED,
      {
        application,
      },
    );
    navigateToNextStep(application);
  };

  const handleContinueClick = () => {
    if (isRejected) {
      return handleContinue;
    }
    return accountingLinks.length && !isLoading ? handleContinue : undefined;
  };

  const handleSkipClick = () => {
    if (accountingLinks.length || !application) return undefined;
    return () => {
      updateApplicationRequirement(
        {
          id: application.id,
          requirement: 'rutterLinked',
          updateApplicationRequirementBody: { status: 'skipped' },
        },
        {
          onSuccess: async () => {
            const { data: updatedApplication } = await refetchApplication();
            Analytics.track(
              QualificationApplicationEvents.QUALIFICATION_APPLICATION_CONNECT_ACCOUNTING_SKIP_CLICKED,
              {
                application: updatedApplication,
              },
            );
            navigateToNextStep(updatedApplication);
          },
          onError: () => {
            showNotification({
              color: 'red',
              title: 'Something went wrong',
              message: 'An unknown error occurred. Please try again',
            });
          },
        },
      );
    };
  };

  // const handleUploadTaxReturnsClick = () => {
  // TODO APP-150: Add ability to upload tax returns
  // };

  return (
    <QualificationPageShell
      titleText="Connect your accounting tool"
      subtitleText="Connect your accounting tool to Flex to help us better understand your financial picture and potentially increase your credit line."
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick()}
      isSkipLoading={isUpdatingApplicationRequirement}
      handleSkipClick={handleSkipClick()}
    >
      <Stack px={{ base: 'lg', sm: 0 }}>
        <Stack
          bg="white"
          p="md"
          pb="xl"
          bd={`1px solid ${theme.colors.neutral[4]}`}
          gap="lg"
        >
          <Group gap="xs" align="center">
            <Flex className={classes.iconContainer}>
              <PiCalculatorBold
                color={theme.colors.primary[6]}
                height={16}
                width={16}
              />
            </Flex>
            <Stack flex={1} gap={rem(2)}>
              <Text lh="normal" fw="500">
                Bookkeeping
              </Text>
              <Text lh="normal" fz="sm" c={theme.colors.neutral[8]}>
                This gives us a fuller picture of your company&apos;s financial
                history.
              </Text>
            </Stack>
          </Group>
          <Stack gap="sm" pl="xxxl">
            {mappedAccountingLinks.map((accountingLink) => (
              <UnstyledButton
                key={accountingLink.id}
                onClick={() => handleDisconnectClick(accountingLink)}
              >
                <Group
                  className={classes.accountContainer}
                  p="xs"
                  align="center"
                >
                  <Group gap={rem(2)} flex={1}>
                    <Text size="sm">{accountingLink.title}</Text>
                  </Group>
                  <Flex justify="center" align="center">
                    <PiLinkBreakBold />
                  </Flex>
                </Group>
              </UnstyledButton>
            ))}
            {!mappedAccountingLinks.length && (
              <Group>
                <Button
                  loading={isLoading}
                  onClick={() => handleAccountingToolClick('QUICKBOOKS')}
                  variant="primary-outline"
                  leftSection={
                    <Image
                      src={QuickbooksGreen}
                      height={20}
                      width={20}
                      radius={200}
                    />
                  }
                >
                  QuickBooks Online
                </Button>
                <Button
                  loading={isLoading}
                  onClick={() => handleAccountingToolClick('NETSUITE')}
                  variant="primary-outline"
                  leftSection={
                    <Image src={Netsuite} height={20} width={20} radius={200} />
                  }
                >
                  NetSuite
                </Button>
                <Button
                  loading={isLoading}
                  onClick={() => handleAccountingToolClick('XERO')}
                  variant="primary-outline"
                  leftSection={
                    <Image src={Xero} height={20} width={20} radius={200} />
                  }
                >
                  Xero
                </Button>
              </Group>
            )}
          </Stack>
        </Stack>

        {/* TODO APP-150: Add ability to upload tax returns */}
        {/* <Divider
          label={
            <Text size="sm" c={theme.colors.neutral[10]}>
              Or
            </Text>
          }
          labelPosition="center"
          color={theme.colors.neutral[4]}
        />

        <Stack
          bg="white"
          p="md"
          pb="xl"
          bd={`1px solid ${theme.colors.neutral[4]}`}
          gap="lg"
        >
          <Group gap="xs" align="center">
            <Flex className={classes.iconContainer}>
              <PiUploadSimpleBold
                color={theme.colors.primary[6]}
                height={16}
                width={16}
              />
            </Flex>
            <Stack flex={1} gap={rem(2)}>
              <Text lh="normal" fw="500">
                Upload the two most recent tax returns filed for your business.
              </Text>
            </Stack>
          </Group>
          <Box ml="auto" mr="auto">
            <Button
              loading={isLoading}
              variant="neutral-outline"
              onClick={handleUploadTaxReturnsClick}
              leftSection={<PiUploadSimpleBold />}
            >
              Upload a File
            </Button>
          </Box>
        </Stack> */}
      </Stack>
    </QualificationPageShell>
  );
};

const useStyles = createStyles((theme) => ({
  iconContainer: {
    borderRadius: 50,
    height: rem(28),
    width: rem(28),
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.primary[0],
  },
  accountContainer: {
    borderRadius: 8,
    border: `1px solid ${theme.colors.neutral[4]}`,
  },
}));

export default QualificationConnectAccountToolPage;
