import { useStyles } from './styles';

import { CheckIcon, rem, Text } from '@mantine/core';

const IssueCards = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.alingCenter} style={{ height: '50%' }}>
      <div className={classes.witheCard}>
        <div className={classes.iconContainer}>
          <CheckIcon width={rem(16)} />
        </div>

        <div>
          <Text fw={500} color="primary.8">
            Virtual debit card issued
          </Text>
          <Text c="neutral.6" fz={rem(10)}>
            Edward‘s card is ready for immediate use
          </Text>
        </div>
      </div>

      <div className={classes.backCard} />
      <div className={classes.backSecondCard} />
    </div>
  );
};

export default IssueCards;
