import FullScreenModalContainer from '@common/composites/modal/full-screen-modal-container';
import { useStyles } from '@common/cards-styles';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';
import { Box, Button, Grid, Text } from '@mantine/core';
import { useGetUsers } from '@queries/use-users';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import {
  useCreateCardCategory,
  useIssueCreditCardMutation,
  useNet60CreditCards,
} from '@queries/use-credit-cards';
import {
  IssueLithicCreditCardForm,
  useIssueLithicCreditCardForm,
} from '@common/issue-lithic-credit-card-form';
import { Group as CardInfoGroup } from 'constants/card-info';
import { showNotification } from '@mantine/notifications';
import { cardIsCreditCard } from '@services/flexbase/card.model';
import { RequiredFieldValidator } from '@utilities/validators';
import { CreditCardInterval } from 'constants/limit-intervals';

type Props = {
  closeModal: () => void;
  spendPlan?: SpendPlanAdminView | SpendPlanView;
};

export const IssueLithicCard = ({ closeModal }: Props) => {
  const { classes } = useStyles();
  const { connectionId = '' } = useActiveExpenseLink();
  const { data: users = [] } = useGetUsers();
  const { data: creditCards } = useNet60CreditCards();
  const { mutate: issueCreditCard, isPending } = useIssueCreditCardMutation();
  const { mutate: mutateCategoryCard } = useCreateCardCategory();

  const form = useIssueLithicCreditCardForm({
    initialValues: {
      userId: '',
      cardType: 'virtual',
      cardName: '',
      spendingLimit: 0,
      spendingLimitInterval: undefined,
      groups: [],
      categoryId: '',
      terminateAt: null,
      limitMerchantCategories: 'no',
      autoExpire: 'no',
    },
    validate: {
      spendingLimit: (value, values) => {
        if (values.spendingLimitInterval !== 'unlimited' && value <= 0) {
          return 'Spending limit must be greater than 0 unless limit type is unlimited.';
        }
        return null;
      },
      cardName: RequiredFieldValidator('Card name is required.'),
      cardType: (value, values) => {
        if (value !== 'physical') {
          return null;
        }

        const [memberName] =
          users
            ?.filter((u) => u.id === values.userId)
            .map((u) => `${u.firstName} ${u.lastName}`) || [];
        const activePhysicalCards =
          creditCards?.filter(
            (c) =>
              c.userId === values.userId &&
              c.cardType === 'PHYSICAL' &&
              c.status !== 'terminated',
          ) || [];

        return activePhysicalCards.length
          ? `${memberName || 'Selected member'} already has an active physical card. You must cancel that card before ordering a new one.`
          : null;
      },
      userId: RequiredFieldValidator('A user must be selected for this card'),
      spendingLimitInterval: RequiredFieldValidator('Select a limit type'),
    },
  });

  const handleSaveClick = form.onSubmit(async (formValues) => {
    const isUnlimited = formValues.spendingLimitInterval === 'unlimited';

    const limits: {
      interval?: CreditCardInterval;
      amount?: number;
      groups: CardInfoGroup[];
    } = {
      amount: isUnlimited ? undefined : formValues.spendingLimit,
      interval: formValues.spendingLimitInterval,
      groups: formValues.groups,
    };

    issueCreditCard(
      {
        userId: formValues.userId,
        card: {
          cardType: formValues.cardType,
          cardName: formValues.cardName,
          limits,
          ...(formValues.autoExpire === 'yes' && {
            terminateAt: formValues.terminateAt,
          }),
        },
      },
      {
        onSuccess: async (response) => {
          if (cardIsCreditCard(response.card)) {
            if (formValues.categoryId) {
              mutateCategoryCard({
                categoryId: formValues.categoryId,
                cardName: response.card.cardName,
                userId: formValues.userId,
                connectionId,
              });
            }
          }
          closeModal();
        },
        onError: (err) => {
          if (err.message.includes('must not be frozen')) {
            showNotification({
              title: 'Error',
              message:
                'The account is currently frozen and we are unable to issue a new cards until it is unfrozen. Please contact support at 415-840-8721.',
              color: 'critical.2',
            });
          } else {
            showNotification({
              title: 'Error',
              message: 'Unable to issue the card. Try it again.',
              color: 'critical.2',
            });
          }
        },
      },
    );
  });

  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <Box
        className={classes.issueCardContainer}
        w="100%"
        data-testid="issue-card"
      >
        <IssueLithicCreditCardForm
          form={form}
          title="Create a new credit card"
        />

        <Grid
          className="buttons-container"
          justify="space-between"
          align="center"
          mt="1rem"
          gutter={0}
        >
          {form.values.cardType === 'virtual' && (
            <Grid.Col span={6}>
              <Text c="#4B4B4B" fz="xs" mr={16}>
                The card will be activated within a few moments.
              </Text>
            </Grid.Col>
          )}

          <Grid.Col span={form.values.cardType === 'physical' ? 12 : 6}>
            <Button
              loading={isPending}
              onClick={() => handleSaveClick()}
              fullWidth
              className="btn-create-card"
              variant="primary-filled"
            >
              Create Card
            </Button>
          </Grid.Col>
        </Grid>
      </Box>
    </FullScreenModalContainer>
  );
};
