import { Accordion, Box, Divider, Flex, rem, Text } from '@mantine/core';
import { DateTime } from 'luxon';
import { getCurrentIsoDate, isFuturePayment } from '@utilities/dates';
import { formatCurrency } from 'utilities/formatters';
import { formatDepositAccountName } from 'utilities/formatters/format-strings';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import PaymentStep, { getPrettyMethod } from '../payment-step';
import { InternationalWiresFooter } from './international-payments/footer-international-wires';
import IntlPaymentCard from './international-payments/intl-payment-card/intl-payment-card';
import {
  isInternationalRecipient,
  tempRecipientValueConditionalFix,
} from './international-payments/util';
import {
  AvailableCurrencies,
  InternationalRecipient,
  PaymentPurposeFormValues,
} from './international-payments/util/types';
import { useStyles } from './payment-review.styles';
import {
  ParsedBeneficiary,
  isParsedBeneficiary,
} from '@utilities/custom-hooks/use-beneficiaries';
import { MoneyMovementDetails, PayMethod, Recipient } from 'types/payments';
import { ParsedAccount } from 'types/parsed-account';
import {
  isInternationalWire,
  isInternationalPayMethod,
} from '@utilities/international-payments';
import { PiCalendar, PiTrash } from 'react-icons/pi';

type Props = {
  onNextClick: () => void;
  onBackClick: () => void;
  loading: boolean;
  recipientName: string;
  payMethod: PayMethod;
  selectedRecipient: ParsedAccount;
  recipient: Recipient | InternationalRecipient;
  moneyMovementDetails: MoneyMovementDetails;
  setMoneyMovementDetails: React.Dispatch<
    React.SetStateAction<MoneyMovementDetails>
  >;
  isLimitedAdmin: boolean;
  selectedIntlCurrency?: AvailableCurrencies;
  intlPaymentPurpose?: PaymentPurposeFormValues;
  country: string;
};

const PaymentReview = ({
  onNextClick,
  onBackClick,
  loading,
  recipientName,
  payMethod,
  selectedRecipient,
  recipient,
  moneyMovementDetails,
  setMoneyMovementDetails,
  isLimitedAdmin,
  selectedIntlCurrency,
  intlPaymentPurpose,
  country,
}: Props) => {
  const { classes, theme } = useStyles();
  const internationalFooter = isInternationalPayMethod(payMethod) && (
    <InternationalWiresFooter />
  );

  const recipientDetails = [
    {
      id: 1,
      subTitle: 'Recipient',
      value: recipientName,
    },
    {
      id: 2,
      subTitle: 'Account nickname',
      value: isInternationalRecipient(recipient)
        ? ''
        : selectedRecipient?.nickName ||
          recipient?.nickName ||
          selectedRecipient.bank ||
          selectedRecipient.name ||
          recipient.name ||
          'No nickname provided',
    },
    {
      id: 3,
      subTitle: 'Account no.',
      value:
        selectedRecipient?.accountNumber ||
        tempRecipientValueConditionalFix(recipient, 'accountNumber'),
    },
    {
      id: 5,
      subTitle: 'From account',
      value: (
        <>
          <Box>
            {formatDepositAccountName(moneyMovementDetails.sourceAccount)}
          </Box>
          <Box>
            <Text>
              {formatCurrency(
                moneyMovementDetails.sourceAccount.available / 100,
              )}
            </Text>
            <Text color={theme.colors.neutral[7]}>
              {moneyMovementDetails.sourceAccount.accountType[0].toUpperCase() +
                moneyMovementDetails.sourceAccount.accountType.slice(1)}{' '}
              {getPaddedAccountMask(
                moneyMovementDetails.sourceAccount.accountNumber,
                2,
              )}
            </Text>
          </Box>
        </>
      ),
    },
    {
      id: 6,
      subTitle: 'Memo for the recipient',
      value: moneyMovementDetails.memo ?? 'No memo provided',
    },
  ];

  if (!isParsedBeneficiary(selectedRecipient)) {
    recipientDetails.splice(2, 0, {
      id: 4,
      subTitle: 'Routing no.',
      value:
        selectedRecipient?.routingNumber ||
        tempRecipientValueConditionalFix(recipient, 'routingNumber'),
    });
  }

  const futurePayment = isFuturePayment(
    moneyMovementDetails.scheduledFor ?? '',
  );

  const formattedDate = DateTime.fromFormat(
    moneyMovementDetails.scheduledFor ?? getCurrentIsoDate(),
    'yyyy-MM-dd',
  ).toFormat('DD');

  /*
    If the user initially selects 'International Wires - Foreign Exchange' as a payment method
    but later changes it to 'International Wires - USD', the app will continue to display the
    previously selected currency since the 'selectedIntlCurrency' state persists. That's why
    this check.
  */
  const currency =
    isInternationalWire(payMethod) && selectedIntlCurrency
      ? selectedIntlCurrency
      : undefined;

  const parsedSelectedRecipient = selectedRecipient as ParsedBeneficiary;
  const parsedRecipient = recipient as InternationalRecipient;

  const recipientData: any = {
    recipientType:
      // prettier-ignore
      // @ts-expect-error this is a hack - we need to refactor this flow PRONTO (DM)
      parsedSelectedRecipient.recipientType ? parsedSelectedRecipient.recipientType : parsedRecipient.recipientType,
    countryOfRecipient:
      parsedRecipient.countryOfRecipient ||
      parsedSelectedRecipient?.ccResponse?.beneficiaryCountry,
    countryOfRecipientsBank:
      parsedRecipient?.countryOfRecipientsBank ||
      parsedSelectedRecipient?.ccResponse?.bankCountry,
    bicSwift:
      parsedRecipient.bicSwift || parsedSelectedRecipient?.ccResponse?.bicSwift,
    accountHolder:
      parsedRecipient.accountHolder || parsedSelectedRecipient?.accountHolder,
    address:
      parsedRecipient.address ||
      parsedSelectedRecipient?.ccResponse?.beneficiaryAddress[0],
    accountNumber:
      parsedRecipient.acctNumber ||
      parsedSelectedRecipient?.ccResponse?.accountNumber,
    dob:
      parsedRecipient.dob ||
      parsedSelectedRecipient?.ccResponse?.beneficiaryDateOfBirth,
  };

  return (
    <PaymentStep
      nextButtonLabel={isLimitedAdmin ? 'Submit request' : 'Send payment'}
      loading={loading}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
      titleText="Review payment details"
      data-testid={'payment-method'}
      footer={
        <>
          {isLimitedAdmin ? null : (
            <div
              className={`${classes.text} ${classes.disclaimerText}`}
              data-testid={'disclaimer'}
            >
              By clicking Send payment, I authorize Flex to initiate the
              transaction detailed above. Flex is powered by Flexbase
              Technologies, Inc.
            </div>
          )}
          {internationalFooter}
        </>
      }
    >
      <Box mt={rem(16)} mb={rem(32)}>
        {isInternationalPayMethod(payMethod) && intlPaymentPurpose ? (
          <Box>
            <IntlPaymentCard
              currency={currency}
              recipientName={recipientName}
              currencyAmount={moneyMovementDetails.amount.toFixed(2)}
              purpose={intlPaymentPurpose.purpose}
            >
              <IntlPaymentCard.Details
                recipient={recipientData}
                currency={selectedIntlCurrency}
                paymentPurpose={intlPaymentPurpose}
                amount={moneyMovementDetails.amount}
                method={payMethod}
                country={country}
                fromAccount={
                  <>
                    <Text>
                      {formatDepositAccountName(
                        moneyMovementDetails.sourceAccount,
                      )}
                    </Text>
                    <Text>
                      {formatCurrency(
                        moneyMovementDetails.sourceAccount.available / 100,
                      )}
                    </Text>
                    <Text>
                      {moneyMovementDetails.sourceAccount.accountType[0].toUpperCase() +
                        moneyMovementDetails.sourceAccount.accountType.slice(
                          1,
                        )}{' '}
                      {getPaddedAccountMask(
                        moneyMovementDetails.sourceAccount.accountNumber,
                        2,
                      )}
                    </Text>
                  </>
                }
              />
            </IntlPaymentCard>
          </Box>
        ) : (
          <>
            <Flex justify="space-between" align="center">
              <Text size="sm">
                {payMethod
                  ? `${getPrettyMethod(payMethod)} to ${recipientName}`
                  : ''}
              </Text>
              {futurePayment && (
                <div className={classes.badge}>
                  <Flex align="center" gap="xs">
                    <PiCalendar width={'1rem'} />
                    <Text size="sm">Scheduled</Text>
                  </Flex>
                </div>
              )}
            </Flex>
            <div className={classes.titleLarge}>
              {formatCurrency(
                moneyMovementDetails.amount,
                selectedIntlCurrency ?? 'USD',
              )}
            </div>
          </>
        )}

        {futurePayment && (
          <Text mb="lg" size="xs">
            Will initiate on {formattedDate}
          </Text>
        )}

        {!isInternationalPayMethod(payMethod) &&
          recipientDetails.slice(0, 4).map((item) => (
            <Box key={item.id} className={classes.containerDetails}>
              <Text size="sm">{item.subTitle}</Text>
              <Text size="sm">{item.value}</Text>
            </Box>
          ))}

        {!isInternationalPayMethod(payMethod) && <Divider mb={'md'} />}

        {recipientDetails.slice(4, 5).map((item) => (
          <Box mt={rem(8)} key={item.id} className={classes.containerDetails}>
            <Text size="sm">{item.subTitle}</Text>
            <Text size="sm">{item.value}</Text>
          </Box>
        ))}

        {recipientDetails.slice(5, 6).map((item) => (
          <Box mt={rem(8)} key={item.id} className={classes.containerDetails}>
            <Text size="sm">{item.subTitle}</Text>
            <Text size="sm">{item.value}</Text>
          </Box>
        ))}
      </Box>

      {moneyMovementDetails.files && moneyMovementDetails.files.length > 0 && (
        <div>
          <Accordion
            chevronPosition="left"
            mb={rem(16)}
            defaultValue="attachments"
            classNames={{
              label: classes.sectionTitle,
              content: classes.content,
              control: classes.control,
            }}
          >
            <Accordion.Item value="attachments">
              <Accordion.Control>Attachments</Accordion.Control>
              <Accordion.Panel>
                <div className={classes.filesContainer}>
                  {moneyMovementDetails.files.map((file, i) => (
                    <div key={file.name + i} className={classes.file}>
                      {file.name}
                      <button
                        className={classes.deleteFile}
                        onClick={() => {
                          setMoneyMovementDetails({
                            ...moneyMovementDetails,
                            files: moneyMovementDetails.files!.filter(
                              (f) => f !== file,
                            ),
                          });
                        }}
                      >
                        <PiTrash
                          size={'1rem'}
                          color={theme.colors.primary[8]}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </PaymentStep>
  );
};

export default PaymentReview;
