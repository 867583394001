import { Avatar, Box, Flex, rem, Text } from '@mantine/core';
import { getInitialsOfNames } from '@utilities/object';

type EntityCellProps = {
  entityName: string | null | undefined;
};

export default function EntityCell({ entityName }: EntityCellProps) {
  if (!entityName) return null;

  return (
    <Flex align="center" data-tag="allowRowEvents">
      <Avatar data-tag="allowRowEvents" mr={10} size={rem(32)}>
        <Text data-tag="allowRowEvents" size="sm">
          {getInitialsOfNames(entityName)}
        </Text>
      </Avatar>
      <Box
        maw="100%"
        display="-webkit-box"
        // prevent the text from overflowing the box + truncate the text
        style={{
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          wordBreak: 'break-word',
        }}
      >
        <Text data-tag="allowRowEvents" size="sm">
          {entityName}
        </Text>
      </Box>
    </Flex>
  );
}
