import { Group, Image, rem, Text } from '@mantine/core';

import { useStyles } from './styles';
import SketchImage from 'assets/images/transferred-banking.png';

const MoneyTransfers = () => {
  const { classes } = useStyles();

  return (
    <Group p={rem(10)} className={classes.card}>
      <div className={classes.alingCenter}>
        <Image src={SketchImage} width={rem(120)} />
        <Text
          ff="redaction"
          fz={rem(20)}
          lh={1}
          mt={rem(20)}
          ta="center"
          c="primary.8"
        >
          You&apos;ve sent $8,502.60 to Gina Fowles.
        </Text>
        <Text
          c="primary.8"
          fz={rem(10)}
          lh={1.5}
          mt={rem(10)}
          w="90%"
          ta="center"
        >
          The funds should arrive within 1-2 business day for domestic wires
        </Text>
      </div>
    </Group>
  );
};

export default MoneyTransfers;
