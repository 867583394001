import { Box, Button, Flex, Group, rem, Stack, Text } from '@mantine/core';
import QualificationPageShell from './components/qualification-page-shell';
import { PiBankBold, PiLinkBold } from 'react-icons/pi';
import { createStyles } from '@mantine/emotion';
import { usePlaidBankingComponent } from '@utilities/custom-hooks/plaid-banking-component';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'recoil-state/application/product-onboarding';
import { useQualificationApplication } from 'areas/onboarding-v2/qualification-application-provider';
import {
  useGetQualificationApplication,
  useUpdateApplicationRequirement,
} from './use-qualification-application';
import { Analytics } from '@services/analytics/analytics';
import { QualificationApplicationEvents } from '@services/analytics/events';
import { showNotification } from '@mantine/notifications';

const QualificationConnectBankPage = () => {
  const { classes, theme } = useStyles();
  const { navigateToNextStep, goBack } = useQualificationApplication();
  const { data: application, refetch: refetchApplication } =
    useGetQualificationApplication();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {
    mutate: updateApplicationRequirement,
    isPending: isUpdatingApplicationRequirement,
  } = useUpdateApplicationRequirement();
  // TODO: Replace this with data not from the old application recoil state
  const [{ company }, setApplicationState] = useRecoilState(ApplicationState);
  const [isSuccess, setIsSuccess] = useState(
    !!company.financialInstitutions?.length,
  );
  const { open, ready } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => setError('Unable to link bank account'),
    setLoading,
  });

  const onLinkSuccess = async () => {
    setError('');
    const newStatus = await getProductOnboardingStatus(true);
    setApplicationState(newStatus);
    await refetchApplication();
    setIsSuccess(true);
  };

  const handleConnectClick = () => {
    if (ready) {
      open();
    }
  };

  const handleBackClick = () => {
    goBack();
  };

  const handleContinueClick = () => {
    Analytics.track(
      QualificationApplicationEvents.QUALIFICATION_APPLICATION_CONNECT_BANK_CONTINUE_CLICKED,
      {
        application,
      },
    );
    navigateToNextStep(application);
  };

  const handleSkipClick = () => {
    if (application) {
      updateApplicationRequirement(
        {
          id: application.id,
          requirement: 'plaidLinked',
          updateApplicationRequirementBody: { status: 'skipped' },
        },
        {
          onSuccess: async () => {
            const { data: updatedApplication } = await refetchApplication();
            Analytics.track(
              QualificationApplicationEvents.QUALIFICATION_APPLICATION_CONNECT_BANK_SKIP_CLICKED,
              {
                application: updatedApplication,
              },
            );
            navigateToNextStep(updatedApplication);
          },
          onError: () => {
            showNotification({
              color: 'red',
              title: 'Something went wrong',
              message: 'An unknown error occurred. Please try again',
            });
          },
        },
      );
    }
  };

  // const handleUploadFileClick = () => {
  // TODO: APP-150 - Implement file upload
  // };

  return (
    <QualificationPageShell
      titleText="Connect your banking app"
      subtitleText="No paperwork required. Simply connect your bank accounts securely, and we'll use that information to consider you for the best credit amount possible."
      handleBackClick={handleBackClick}
      handleContinueClick={isSuccess ? handleContinueClick : undefined}
      continueLoading={isUpdatingApplicationRequirement}
      isSkipLoading={isUpdatingApplicationRequirement}
      handleSkipClick={isSuccess ? undefined : handleSkipClick}
    >
      <Stack px={{ base: 'lg', sm: 0 }}>
        <Stack
          bg="white"
          p="md"
          bd={`1px solid ${theme.colors.neutral[4]}`}
          gap="sm"
        >
          <Group gap="xs" align="center">
            <Flex className={classes.iconContainer}>
              <PiBankBold
                color={theme.colors.primary[6]}
                height={16}
                width={16}
              />
            </Flex>
            <Stack flex={1} gap={rem(2)}>
              <Text lh="normal" fw="500">
                Bank Accounts
              </Text>
              <Text lh="normal" fz="sm" c={theme.colors.neutral[8]}>
                Securely connect your bank account to get started.
              </Text>
            </Stack>
          </Group>
          <Stack gap="sm" pl="xxxl">
            {company?.financialInstitutions?.map((account) => (
              <Group
                key={account.id}
                className={classes.accountContainer}
                p="xs"
                align="center"
              >
                <Group gap={rem(2)} flex={1}>
                  <Text size="sm">{account.accountName}</Text>
                  <Text size="sm" c={theme.colors.neutral[8]}>
                    ••{account.last4}
                  </Text>
                </Group>
                <Flex justify="center" align="center">
                  <PiLinkBold />
                </Flex>
              </Group>
            ))}
            <Box>
              <Button
                loading={loading || !ready}
                onClick={handleConnectClick}
                variant="primary-light"
                rightSection={<PiLinkBold />}
              >
                {isSuccess ? 'Connect Another Account' : 'Connect Account'}
              </Button>
            </Box>
            {error && (
              <Text size="xs" c={theme.colors.critical[2]}>
                {error}
              </Text>
            )}
            <Text size="xs" c={theme.colors.neutral[8]}>
              All accounts listed above will be included in your application
              unless you unlink them.
            </Text>
          </Stack>
        </Stack>

        <Text size="xs" c={theme.colors.neutral[8]}>
          Flex uses Plaid Inc (“Plaid”) to gather your data from financial
          institutions for bank account and fraud verification. By using the
          site, you grant Flex and Plaid the right, power, and authority to act
          on your behalf to access and transmit your personal and financial
          information from your relevant financial institution from your
          relevant financial institution.
        </Text>

        {/* TODO: APP-150 - Implement file upload */}
        {/* <Stack gap={rem(2)} mt="sm">
          <Text lh="normal">Don&apos;t see your bank account?</Text>
          <Text lh="normal" size="sm" c={theme.colors.neutral[8]}>
            You can upload your bank statements for the last 3 months.
          </Text>
        </Stack>

        <Group justify="center" mt="md">
          <Button
            variant="neutral-outline"
            leftSection={<PiUploadSimpleBold />}
            onClick={handleUploadFileClick}
          >
            Upload a File
          </Button>
        </Group> */}
      </Stack>
    </QualificationPageShell>
  );
};

const useStyles = createStyles((theme) => ({
  iconContainer: {
    borderRadius: 50,
    height: rem(28),
    width: rem(28),
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.primary[0],
  },
  accountContainer: {
    borderRadius: 8,
    border: `1px solid ${theme.colors.neutral[4]}`,
  },
}));

export default QualificationConnectBankPage;
