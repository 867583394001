import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  cancelDebitCard: {
    margin: '0px',
    padding: rem(40),
    minHeight: '100vh',
    borderRadius: rem(8),
    backgroundColor: '#FAFAFA',
    '.close': {
      display: 'grid',
      justifyContent: 'end',
      alignContent: 'baseline',
      p: {
        margin: '0px',
        color: '#979797',
        textAlign: 'center',
      },
    },
    '.content': {
      display: 'flex',
      marginTop: rem(20),
      height: 'fit-content',
      justifyContent: 'center',
    },
    '.error': {
      display: 'flex',
      padding: rem(16),
      fontSize: rem(14),
      color: '#991B1B',
      margin: rem(30),
      borderRadius: rem(8),
      alignItems: 'center',
      backgroundColor: '#FEF2F2',
      svg: {
        marginRight: rem(10),
      },
    },
    '@media(max-width: 767px)': {
      padding: rem(20),
      '.close-button': {
        width: '100%',
        display: 'flex',
        marginBottom: rem(15),
        justifyContent: 'flex-end',
      },
    },
  },

  card: {
    width: rem(500),
    padding: rem(34),
    color: theme.colors.neutral[8],
    position: 'relative',
    backgroundColor: theme.colors.neutral[0],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
  },

  subtitle: {
    fontSize: rem(12),
    color: theme.colors.neutral[8],
  },

  title: {
    color: theme.colors.neutral[10],
    fontWeight: 400,
    fontSize: rem(28),
    '@media(max-width: 767px)': {
      fontSize: rem(22),
    },
  },

  optionBox: {
    gap: 10,
    margin: rem(5),
    height: rem(70),
    display: 'flex',
    padding: rem(20),
    maxWidth: '47%',
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    '&:hover': {
      backgroundColor: theme.colors.neutral[3],
    },
    '@media(max-width: 767px)': {
      maxWidth: '100%',
    },
  },

  optionText: {
    fontSize: rem(16),
    fontWeight: 500,
    color: theme.primaryColor,
  },

  lightText: {
    color: theme.colors.neutral[0],
  },

  buttonsContainer: {
    display: 'flex',
    marginTop: rem(40),
    justifyContent: 'flex-end',
  },

  debitCardInfoContent: {
    display: 'flex',
    alignItems: 'center',
    span: {
      color: theme.colors.neutral[10],
    },
    p: {
      margin: '0px',
    },
  },

  radioLabel: {
    fontSize: rem(16),
  },

  radioDescription: {
    fontSize: rem(14),
    color: theme.colors.neutral[8],
  },

  circle: {
    width: rem(24),
    height: rem(24),
    display: 'flex',
    borderRadius: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    color: theme.primaryColor,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
}));
