import { platformClient } from '@services/platform/platform-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'support_code';

const getSupportCodeQueryFn = async (accountId: string, personId: string) => {
  return await platformClient.getSupportCode(accountId, personId);
};

type SupportCodeParams = {
  accountId: string;
  personId: string;
  enabled?: boolean;
};

export const useGetSupportCode = ({
  accountId,
  personId,
  enabled,
}: SupportCodeParams) => {
  return useQuery({
    queryKey: [QUERY_KEY, accountId, personId],
    queryFn: () => getSupportCodeQueryFn(accountId, personId),
    enabled,
    retry: false,
    // React Query won't automatically trigger a refetch of this data in the background
    staleTime: Infinity,
    // Don't refetch on window focus, since it's a long-lived code.
    refetchOnWindowFocus: false,
  });
};
