import { Text, Avatar, Flex, Box, useMantineTheme } from '@mantine/core';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { PiBankBold } from 'react-icons/pi';
import { centsToFormattedCurrency } from '@utilities/formatters';

type ReviewBodyBankingAccountProps = {
  sourceAccount: PlaidAccount | DepositAccount | undefined;
};

const ReviewBodyBankingAccount = ({
  sourceAccount,
}: ReviewBodyBankingAccountProps) => {
  const theme = useMantineTheme();
  const sourceAccountName =
    sourceAccount?.plaidOrDeposit === 'deposit'
      ? (sourceAccount.nickName ?? sourceAccount.name)
      : `${sourceAccount?.accountName ?? sourceAccount?.bankName} ${GetPaddedAccountMask(sourceAccount?.last4 ?? '', 3)}`;

  return (
    <Flex mt="md" justify="space-between" align="center">
      <Flex align="center" gap="xs">
        <Avatar size={24} bd="unset">
          <PiBankBold fill={theme.colors.neutral[6]} />
        </Avatar>
        <Text c="neutral.8" size="sm">
          Account
        </Text>
      </Flex>
      <Box>
        <Text ta="right" size="sm">
          {sourceAccountName}
        </Text>
        <Text ta="right" c="neutral.8" size="sm">
          {centsToFormattedCurrency(sourceAccount?.available || 0)} available
        </Text>
      </Box>
    </Flex>
  );
};

export default ReviewBodyBankingAccount;
