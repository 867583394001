import { createFormContext } from '@mantine/form';

export type TermsFormValues = {
  creditTermsOfServiceSigned: boolean;
  bankingTermsOfServiceSigned: boolean;
  internationalPaymentsTermsOfServiceSigned: boolean;
  treasuryTermsOfServiceSigned: boolean;
  termsOfServiceSigned: boolean;
  personalGuarantySigned: boolean;
  ficoPullSigned: boolean;
  patriotActSigned: boolean;
  nyTermsSigned: boolean;
  flTermsSigned: boolean;
};

export const [TermsFormProvider, useTermsFormContext, useTermsForm] =
  createFormContext<TermsFormValues>();
