import { ActionIcon, Flex, Text, TextProps } from '@mantine/core';
import { useState } from 'react';
import { PiEye, PiEyeSlash } from 'react-icons/pi';

const buttonProps = {
  color: '#85868D',
  size: 16,
};

type Props = {
  accountNumber: string;
  textStyles?: TextProps;
};

const AccountNumberToggle = ({ accountNumber, textStyles }: Props) => {
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);

  const handleToggle = () => {
    setDisplayAccountNumber(!displayAccountNumber);
  };

  const { button, displayValue } = displayAccountNumber
    ? {
        button: <PiEyeSlash {...buttonProps} />,
        displayValue: accountNumber,
      }
    : {
        button: <PiEye {...buttonProps} />,
        displayValue: `••${accountNumber?.slice(-4)}`,
      };

  return (
    <Flex>
      <Text {...textStyles}>{displayValue}</Text>
      <ActionIcon variant="primary-subtle" size="sm" onClick={handleToggle}>
        {button}
      </ActionIcon>
    </Flex>
  );
};

export default AccountNumberToggle;
