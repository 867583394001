import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import { UNSERVED_CREDIT_STATES } from '../../../constants/constants';
import { ReactNode, useMemo } from 'react';
import { useSalesStyles } from 'areas/credit/sales/styles';
import { useRouteSectionContext } from 'providers/route-context';
import { usePageBackground } from '../../../providers/background-provider';
import { ProductApplicationRoutes } from '../../../constants/onboarding.constants';
import { CreditApplyAgainCta } from './credit-apply-again-cta';
import { Button } from '@mantine/core';
import { ArrowRight } from '../../../assets/svg';
import { SalesPageContent } from 'areas/credit/sales/sales-page.content';
import { CreditCardGraphic } from './credit-card-graphic';

const StateDenialTitle = () => {
  const { company } = useRecoilValue(ApplicationState);
  const stateMap = UNSERVED_CREDIT_STATES.reduce<Record<string, string>>(
    (prev, curr) => {
      return { ...prev, [curr.value]: curr.label };
    },
    {},
  );

  let stateName: string;

  if (stateMap[company.stateOfOperation]) {
    stateName = stateMap[company.stateOfOperation];
  } else if (stateMap[company.address.state]) {
    stateName = stateMap[company.address.state];
  } else {
    stateName = company.stateOfOperation;
  }

  return (
    <>
      Unfortunately, we are not yet able to offer credit cards to {stateName}{' '}
      companies.
    </>
  );
};

const pageVerbiage: Record<
  'denial' | 'stateDenial' | 'selfPropDenial',
  { title: ReactNode; subtitle: string }
> = {
  denial: {
    title: `Unfortunately, we are unable to offer you a credit card at this time.`,
    subtitle: `You are welcome to apply again in 3 months.`,
  },
  stateDenial: {
    title: <StateDenialTitle />,
    subtitle: `We will contact you when this changes.`,
  },
  selfPropDenial: {
    title: `Unfortunately, we don't offer credit cards to sole proprietorships at this time.`,
    subtitle: `We will notify you when this changes.`,
  },
};

export const CreditDeniedPage = () => {
  const { classes } = useSalesStyles();
  const { user, productStatus } = useRecoilValue(ApplicationState);
  const creditProductStatus = productStatus.credit;
  const { setSectionAndNavigate } = useRouteSectionContext();

  usePageBackground(
    (t) =>
      `url("/images/page-top-frame.png") top/contain no-repeat ${t.colors.primary[7]}`,
  );

  let content: 'denial' | 'stateDenial' | 'selfPropDenial' = 'denial';

  // Should catch users who are unqualified or denied but the status flipped to incomplete
  const hasDeniedCreditLimit =
    creditProductStatus.creditLimit &&
    parseFloat(creditProductStatus.creditLimit) === 0;

  if (
    creditProductStatus.status === 'denied' ||
    creditProductStatus.status === 'unqualified' ||
    hasDeniedCreditLimit
  ) {
    const reason = creditProductStatus.reason?.toLowerCase();
    if (reason?.includes('company state') || reason?.includes('user state')) {
      content = 'stateDenial';
    } else if (reason?.includes('company type')) {
      content = 'selfPropDenial';
    } else {
      content = 'denial';
    }
  }

  const handleApplyAgainClick = () => {
    setSectionAndNavigate(
      'application',
      `${ProductApplicationRoutes.CREDIT_ONLY}/business-type`,
    );
    window.scroll(0, 0);
  };

  const callToAction = useMemo(() => {
    return content === 'selfPropDenial' ? (
      <CreditApplyAgainCta
        title="Do you now have a registered legal entity? Please apply again."
        subtitle="We have saved your information so applying again will take only a few
          minutes"
        onApplyAgainClick={handleApplyAgainClick}
        containerClassname={classes.creditInfoCta}
      />
    ) : (
      <Button
        component="a"
        href="mailto:support@flex.one"
        variant="neutral-outline"
        rightSection={<ArrowRight />}
        c="primary.8"
        sx={(theme) => ({
          backgroundColor: theme.colors.neutral[0],
          '&:not([data-disabled])&:hover': {
            backgroundColor: theme.colors.neutral[0],
          },
        })}
      >
        Contact Support
      </Button>
    );
  }, [content]);

  return (
    <SalesPageContent
      topGraphic={
        <CreditCardGraphic
          firstName={user.firstName}
          lastName={user.lastName}
        />
      }
      salesCards={[]}
      title={pageVerbiage[content].title}
      subtitle={pageVerbiage[content].subtitle}
      actionButton={callToAction}
      titleMaxWidth={980}
    />
  );
};
