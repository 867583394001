import { useForm } from '@mantine/form';
import { MultiStepFormStepWrapper } from '@common/composites/multi-step-form/multi-step-form-step-wrapper';
import { formatPhoneForApi } from '../../../../utilities/formatters/format-phone-number';
import { validateIntlPhoneNumber } from '@utilities/validators/validate-phone-number';
import { usePrefillMultistepFormContext } from '../../pages/prefill/prefill-multi-step-form.context';
import { useAddFactorsContext } from '../../../../providers/add-factors.context';
import { IsFlexHttpError } from '../../../../services/platform/models/authorize.models';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import {
  isPhoneNotSupportedError,
  PHONE_NOT_SUPPORTED_ERROR_COPY,
} from 'constants/index';
import { FlexIntlPhoneInput } from '@common/composites/input/flexbase-intl-phone-input';
import { noop } from 'underscore';
import { Country } from 'react-phone-number-input';

type ConfirmPhoneFormValues = {
  phone: string;
  phoneCountryCode: Country;
};

export const PrefillConfirmPhoneStep = () => {
  const { user } = useRecoilValue(ApplicationState);
  const prefillContext = usePrefillMultistepFormContext();

  const { registerFactor, savePhone } = useAddFactorsContext();

  const phoneForm = useForm<ConfirmPhoneFormValues>({
    initialValues: {
      phone: user.cellPhone || '',
      phoneCountryCode: user.countryCode ?? 'US',
    },
    validate: {
      phone: (val, formVals) =>
        validateIntlPhoneNumber(val, formVals.phoneCountryCode)
          ? null
          : 'Must be a valid phone number.',
    },
  });

  const handleVerifiedFactor = async (phone: string, countryCode: Country) => {
    try {
      await savePhone(phone, countryCode);
      prefillContext.goToNextStep({
        phoneVerified: true,
        loading: false,
      });
    } catch {
      prefillContext.setState({
        loading: false,
        error: 'An error occurred while saving your phone number.',
      });
    }
  };

  const handleFormSubmit = async () => {
    const validationResult = phoneForm.validate();

    if (!validationResult.hasErrors) {
      prefillContext.setState({ loading: true, error: null });
      const formattedForPlatform = `+1${formatPhoneForApi(
        phoneForm.values.phone,
      )}`;
      try {
        const { methodId } = await registerFactor({
          factorType: 'phone',
          value: formattedForPlatform,
        });
        prefillContext.goToNextStep({
          phoneVerified: false,
          phoneVerification: {
            phoneNumber: phoneForm.values.phone,
            phoneNumberCountryCode: phoneForm.values.phoneCountryCode,
            methodId,
          },
        });
      } catch (e) {
        if (IsFlexHttpError(e) && e.statusCode === 409) {
          handleVerifiedFactor(
            phoneForm.values.phone,
            phoneForm.values.phoneCountryCode,
          );
        } else if (isPhoneNotSupportedError(e)) {
          prefillContext.setState({
            error: PHONE_NOT_SUPPORTED_ERROR_COPY,
            loading: false,
          });
        } else {
          prefillContext.setState({
            error:
              'Failed to update phone number. Please contact support if the issue continues.',
            loading: false,
          });
        }
      }
    }
  };

  return (
    <MultiStepFormStepWrapper id="edit-phone" onFormSubmit={handleFormSubmit}>
      <FlexIntlPhoneInput
        label="Cellphone"
        inputMode="tel"
        data-sardine-id="cellphone"
        data-testid="input-cellphone"
        placeholder="Enter number"
        {...phoneForm.getInputProps('phone')}
        countryCode={phoneForm.values.phoneCountryCode}
        onChange={noop}
        onCountryCodeChange={(c) =>
          phoneForm.setFieldValue('phoneCountryCode', c)
        }
        onValueChange={(e) =>
          phoneForm.setFieldValue('phone', e.formattedValue)
        }
      />
    </MultiStepFormStepWrapper>
  );
};
