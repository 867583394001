import { ActionItemCard } from './action-item-card';
import { Box, Text } from '@mantine/core';
import { RequestedDocRow } from '@common/utilities/self-service-dashboard/action-items/requested-doc-row';
import { useCompanyDocuments } from '@queries/use-company-documents';
import { useState } from 'react';
import { ActionItemConstants } from '@common/utilities/self-service-dashboard/action-items/action-item-constants';
import { AlertTriangleIcon } from 'assets/svg';
import { useSelfServiceStyles } from '@common/utilities/self-service-dashboard/self-service-dashboard.styles';

type Props = { requiredDocuments: string[] };

export const RequestedDocumentsItem = ({ requiredDocuments }: Props) => {
  const { classes, cx } = useSelfServiceStyles();
  const { data: uploadedDocs } = useCompanyDocuments();
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
    subtitle: string;
  } | null>(null);

  const handleUploadError = (error: string) => {
    switch (error) {
      case ActionItemConstants.CLEAR_ERROR:
        setErrorMessage(null);
        break;
      case ActionItemConstants.FILE_SIZE_ERROR:
        setErrorMessage({
          title: 'File size is too large.',
          subtitle: 'We only accept files that are less than 49mb.',
        });
        break;
      case ActionItemConstants.FILE_UPLOAD_ERROR:
      default:
        setErrorMessage({
          title: 'Unable to upload file',
          subtitle:
            'We encountered an unknown error while uploading your file.',
        });
        break;
    }
  };

  return (
    <ActionItemCard
      title="Upload required documents"
      subtitle="Please upload one or more documents for each required document type. We accept PDF, Word, Excel, Powerpoint, and image files up to 49mb."
    >
      <Box>
        {errorMessage && (
          <Box
            w="100%"
            bg="critical.0"
            my="1.5rem"
            h="5rem"
            p="md"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing.md,
            })}
          >
            <Box c="critical.2">
              <AlertTriangleIcon />
            </Box>
            <Box>
              <Text c="primary.8" fz={16} fw={600}>
                {errorMessage.title}
              </Text>
              <Text c="primary.8" fz={14}>
                {errorMessage.subtitle}
              </Text>
            </Box>
          </Box>
        )}
        <Box
          className={cx(
            classes.gridTable,
            classes.gridTableHeader,
            classes.requestedDocumentsTable,
            classes.requestedDocumentsTableHeader,
          )}
        >
          <div>Document type</div>
          <div>Status</div>
        </Box>
        {requiredDocuments.map((r) => (
          <RequestedDocRow
            key={r}
            docName={r}
            uploadedDocs={
              uploadedDocs?.filter((d) => d.description === r) ?? []
            }
            onUploadError={handleUploadError}
          />
        ))}
      </Box>
    </ActionItemCard>
  );
};
