import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { ApplicationStatus } from 'recoil-state/application/product-onboarding.models';
import { useCreditLines } from '@queries/use-credit-lines';
import { LoadingOverlay } from '@mantine/core';
import { useMarqetaUiFeatureFlag } from '@utilities/feature-flags';

/**
 * Hack to allow users with approved credit but limit = 0 to redirect correctly.
 */
export function checkCreditDenied(productStatus: ApplicationStatus) {
  let isDenied = productStatus.credit.status === 'denied';

  if (productStatus.credit.status === 'denied') {
    const appApproved = productStatus.credit.appStatus === 'approved';
    const creditLimit = parseFloat(productStatus.credit.creditLimit ?? '-1');
    const validLimit = !isNaN(creditLimit) && creditLimit >= 0;

    if (appApproved && validLimit) {
      isDenied = false;
    }
  }

  return isDenied;
}

const ProductNavigator = () => {
  const { productStatus, businessId } = useRecoilValue(ApplicationState);
  const mqEnabled = useMarqetaUiFeatureFlag();
  const { data: creditLines, isPending } = useCreditLines(businessId);

  // Quick hit to navigate to Lithic cards if open, then charge cards if open, then fall through to regular product status processing
  if (!creditLines) {
    if (isPending) {
      return <LoadingOverlay visible={true} color="primary" />;
    }
  } else {
    const openCreditLines = creditLines.filter(
      (line) => line.status.toLowerCase() === 'open',
    );

    if (openCreditLines.some((line) => line.issuer === 'unit')) {
      return <Navigate to="/charge-card" />;
    } else if (
      mqEnabled &&
      openCreditLines.some((line) => line.issuer === 'marqeta')
    ) {
      return <Navigate to="/credit" />;
    } else if (openCreditLines.some((line) => line.issuer === 'lithic')) {
      return <Navigate to="/cards" />;
    }
  }

  const isCreditDenied = checkCreditDenied(productStatus);

  if (
    productStatus.credit.status === 'unused' ||
    isCreditDenied ||
    productStatus.credit.status === 'unqualified' ||
    productStatus.credit.status === 'restricted'
  ) {
    return <Navigate to="/banking" />;
  } else {
    return <Navigate to="/cards" />;
  }
};

export default ProductNavigator;
