import {
  _ApplicationState,
  OnboardingRouteNames,
} from '../../recoil-state/application/product-onboarding.models';
import {
  ApplicationState,
  getProductOnboardingStatus,
  OptedProduct,
  ProductCurrentStep,
  ProductNavStack,
  ProductOnboardingBtnLoaderState,
} from '../../recoil-state/application/product-onboarding';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';
import useShowBankingModal from './banking-modal-hook';
import { OnboardingCompany } from '../../types/onboarding-info';
import { formatDateForApi } from '../../utilities/formatters/format-date-input';
import { formatPhoneForApi } from '../../utilities/formatters/format-phone-number';
import { flexbaseOnboardingClient } from '../../services/flexbase-client';
import { UnqualifiedReason } from './components/offer-banking-modal';
import { isArray, isEmpty, isObject } from 'underscore';
import { ApplicationConfiguration } from 'application/application-config-builder';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';

type FormatterFn = (value: any) => any;
type Formatter<T> = { field: T; formatterFn: FormatterFn };

function clean(element: any): boolean {
  if (typeof element === 'boolean' || typeof element === 'number') {
    return true;
  }

  if (typeof element === 'string') {
    return element.length > 0;
  }

  return element != null && !isEmpty(element);
}

function removeEmpty<T>(obj: T): T {
  return isObject(obj)
    ? Object.entries(obj)
        .filter(([, value]) => clean(value))
        .reduce((acc, [key, value]) => {
          if (isArray(value)) {
            return { ...acc, [key]: value.map((v) => removeEmpty(v)) };
          }

          if (isObject(value) && !isEmpty(value)) {
            return { ...acc, [key]: removeEmpty(value) };
          }

          if (clean(value)) {
            return { ...acc, [key]: value };
          }

          return { ...acc };
        }, {} as T)
    : obj;
}

function stripAndFormat<T>(
  intermediate: T,
  formatters?: Formatter<keyof T>[],
): T {
  const unformatted = removeEmpty(intermediate);

  formatters?.forEach(({ field, formatterFn }) => {
    const formattedValue = formatterFn(unformatted[field]);
    if (formattedValue) {
      unformatted[field] = formattedValue;
    } else {
      delete unformatted[field];
    }
  });

  return unformatted;
}

type UseApplicationFlowContextReturnType = {
  config: ApplicationConfiguration;
  navigateToNextProductStep: (isTransientStep?: boolean) => void;
  goBack: () => void;
  applyingForProducts: OptedProduct[];
  progress: number;
  createOrUpdateCompany: (data: Partial<OnboardingCompany>) => Promise<{
    success: boolean;
    error: string;
    company: Partial<OnboardingCompany>;
  }>;
  refreshProductOnboardingStatus: (
    includeFullOwners?: boolean,
  ) => Promise<_ApplicationState>;
  navigateToEndScreen: () => void;
};

type ApplicationFlowProviderProps = { config: ApplicationConfiguration };

const ApplicationFlowContext =
  createContext<UseApplicationFlowContextReturnType>(
    {} as UseApplicationFlowContextReturnType,
  );

export const ApplicationFlowProvider = ({
  config: currentConfig,
}: ApplicationFlowProviderProps) => {
  const setLoading = useSetRecoilState(ProductOnboardingBtnLoaderState);
  const navigate = useMarketingNavigate();
  const { step } = useParams();
  const [navStack, setNavStack] = useRecoilState(ProductNavStack);
  const setCurrentStep = useSetRecoilState(ProductCurrentStep);
  const [productOnboardingStatus, setProductOnboardingStatus] =
    useRecoilState(ApplicationState);
  const [continueWithBankingOnly, setContinueWithBankingOnly] = useState(false);
  const openBankingModal = useShowBankingModal();

  const applyingForProducts = useMemo(() => {
    return currentConfig.products;
  }, [currentConfig]);

  // I'm not sure if this would be better as a memo. The arrays aren't that big, so likely the useMemo memory footprint will be larger than running this calculation every render.
  const progress = !step
    ? 0
    : currentConfig.getProgressForRoute(
        step as OnboardingRouteNames,
        productOnboardingStatus,
      );
  const refreshProductOnboardingStatus = async (includeFullOwners = false) => {
    const newStatus = await getProductOnboardingStatus(includeFullOwners);
    setProductOnboardingStatus(newStatus);
    return newStatus;
  };

  const navigateToNextProductStep = useCallback(
    async (isTransientStep = false) => {
      setLoading(true);
      try {
        const newStatus = await refreshProductOnboardingStatus();
        const displayBankingModal = shouldShowBankingModal(
          newStatus,
          continueWithBankingOnly,
        );

        if (navStack.length > 0) {
          const pop = navStack[0];
          setNavStack((prev) => [...prev.slice(1)]);
          navigate(pop);
        } else if (displayBankingModal) {
          const { credit } = newStatus.productStatus;
          const unqualifiedReason: UnqualifiedReason = credit.reason?.includes(
            'S-Prop',
          )
            ? 'sole-prop'
            : 'unserved-state';
          const redirectTo =
            unqualifiedReason === 'sole-prop'
              ? 'business-type'
              : 'verify-business';
          navigate(redirectTo);
          openBankingModal({
            unqualifiedReason,
            onContinueWithBankingOnly: () => setContinueWithBankingOnly(true),
          });
        } else {
          const nextStep = isTransientStep
            ? currentConfig.getNextRouteFromCurrentRoute(
                step as OnboardingRouteNames,
                newStatus.userIsApplicant,
                newStatus,
              )
            : currentConfig.getNextRouteFromStatus(newStatus);

          navigate(nextStep);
          setCurrentStep(nextStep);
        }
        window.scroll(0, 0);
      } catch (e) {
        navigate(`error`);
      } finally {
        setLoading(false);
      }
    },
    [step, navStack, currentConfig, continueWithBankingOnly],
  );

  const goBack = useCallback(() => {
    setNavStack((prev) => [step!, ...prev]);
    const prevStep = currentConfig.getPreviousRoute(
      step as OnboardingRouteNames,
      productOnboardingStatus.userIsApplicant,
      productOnboardingStatus,
    );
    navigate(prevStep);
    window.scroll(0, 0);
  }, [step, productOnboardingStatus, currentConfig]);

  const createOrUpdateCompany = useRecoilCallback(
    ({ snapshot }) =>
      async (
        data: Partial<OnboardingCompany>,
      ): Promise<{
        success: boolean;
        error: string;
        company: Partial<OnboardingCompany>;
      }> => {
        setLoading(true);
        const { company } = await snapshot.getPromise(ApplicationState);

        const formattedData = stripAndFormat({ id: company.id, ...data }, [
          { field: 'formationDate', formatterFn: formatDateForApi },
          { field: 'phone', formatterFn: formatPhoneForApi },
        ]);
        try {
          let companyPromise: Promise<OnboardingCompany>;
          let optionalFields = {};
          // This is a terrible hack to be able to explicitly set website to null for banking.
          // Need to figure out a better way to set null values and not have them stripped
          // from the request by the formatter above. (The API expects `null` to indicate that this company
          // does not have a website)
          if (data.website || data.website === null) {
            optionalFields = { website: data.website };
          }

          if (formattedData.id) {
            companyPromise = flexbaseOnboardingClient.updateCompany({
              ...formattedData,
              ...optionalFields,
            });
          } else {
            companyPromise = flexbaseOnboardingClient.createCompany({
              ...formattedData,
              ...optionalFields,
            });
          }
          const co = await companyPromise;
          setLoading(false);
          return { success: true, error: '', company: co };
        } catch (error) {
          console.error('useProductOnboarding::createOrUpdateCompany', error);
          setLoading(false);
          const errorMessage = error?.message?.toLowerCase();
          // Maybe just match on 'already'? That may not be specific enough..
          if (
            errorMessage?.includes('already exists') ||
            errorMessage?.includes('already in use')
          ) {
            return { success: false, error: 'ein_conflict', company: {} };
          }
          return {
            success: false,
            error: error?.message || 'api_error',
            company: {},
          };
        }
      },
    [],
  );

  const shouldShowBankingModal = (
    _applicationStatus: _ApplicationState,
    _continueWithBankingOnly: boolean,
  ) => {
    const { credit } = _applicationStatus.productStatus;
    const hasPromoCode = !!_applicationStatus.user.promoCode;
    const isSoleProprietor = credit.reason?.includes('S-Prop');
    const operatesInAnUnlicensedState =
      credit.reason?.includes('Company State');

    return (
      applyingForProducts.includes('CREDIT') &&
      // just for avoid strange cases where the company had completed onboarding at one point a long time ago
      !credit.creditLimit &&
      (isSoleProprietor || operatesInAnUnlicensedState) &&
      !hasPromoCode
    );
  };

  const navigateToEndScreen = () => {
    const endRoute = currentConfig.endRoute || 'end';
    navigate(endRoute);
  };

  return (
    <ApplicationFlowContext.Provider
      value={{
        config: currentConfig,
        applyingForProducts,
        goBack,
        navigateToNextProductStep,
        progress,
        createOrUpdateCompany,
        refreshProductOnboardingStatus,
        navigateToEndScreen,
      }}
    >
      <Outlet />
    </ApplicationFlowContext.Provider>
  );
};

export const useApplicationFlowContext = () => {
  return useContext(ApplicationFlowContext);
};
