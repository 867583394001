import { Flex, rem, Stack, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PiCheckLight } from 'react-icons/pi';
import { useGetQualificationApplication } from '../use-qualification-application';
import { useMemo } from 'react';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';

const QualificationAccepted = () => {
  const { classes } = useStyles();
  const { data: application } = useGetQualificationApplication();

  const titleText = useMemo(() => {
    if (
      !application?.fields?.intendedProducts?.length ||
      application?.fields?.intendedProducts.length === 2
    ) {
      return "You're pre-qualified for Flex";
    }
    return `You're pre-qualified for Flex ${capitalizeOnlyFirstLetter(application?.fields?.intendedProducts[0]?.toLowerCase() || '')}`;
  }, [application?.fields?.intendedProducts]);

  const subtitleText = useMemo(() => {
    if (
      !application?.fields?.intendedProducts?.length ||
      application?.fields?.intendedProducts.length === 2
    ) {
      return "Next, we'll learn more about your business and your banking and credit needs.";
    }
    return `Next, we'll learn more about your business and your ${application?.fields?.intendedProducts[0]?.toLowerCase()} needs.`;
  }, [application?.fields?.intendedProducts]);

  return (
    <Stack px={{ base: 'lg', sm: 0 }} align="center">
      <Flex className={classes.checkContainer}>
        <PiCheckLight size={40} />
      </Flex>

      <Text ff="Redaction" fz={rem(32)} lh="normal" ta="center">
        {titleText}
      </Text>
      <Text ta="center" mb={rem(96)}>
        {subtitleText}
      </Text>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  checkContainer: {
    background: theme.colors.primary[0],
    height: rem(78),
    width: rem(78),
    borderRadius: rem(100),
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.colors.primary[3]}`,
  },
}));

export default QualificationAccepted;
