import {
  Box,
  rem,
  Text,
  Modal,
  Alert,
  Button,
  useMantineTheme,
} from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { PiXCircle, PiXBold } from 'react-icons/pi';
import { useGetSupportCode } from '@queries/use-support';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

const ErrorAlert = () => (
  <Alert
    icon={<PiXCircle size="1.5rem" />}
    withCloseButton={false}
    color="red"
    mt="md"
  >
    Error generating support code. Please try again.
  </Alert>
);

const SupportCode = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { accountId, personId } = useRecoilValue(ApplicationState);
  const {
    data: supportCode,
    isError,
    isFetching,
    refetch,
  } = useGetSupportCode({
    accountId,
    personId,
    enabled: false,
  });

  const displayLoading = isFetching && !opened;
  const displayError = isError && !opened;

  const getSupportCode = async () => {
    const { data } = await refetch();
    if (data) {
      return true;
    }
    return false;
  };

  const handleClickButton = async () => {
    const isRefreshed = await getSupportCode();
    if (isRefreshed) {
      open();
    }
  };

  return (
    <>
      <Modal
        shadow="md"
        opened={opened}
        onClose={close}
        withCloseButton={false}
        closeOnClickOutside={false}
      >
        <ModalDialog
          onClose={close}
          isError={isError}
          isLoading={isFetching}
          token={supportCode?.token ?? ''}
          onRegenerate={getSupportCode}
          expiration={supportCode?.expiration ?? ''}
        />
      </Modal>
      <Button loading={displayLoading} onClick={handleClickButton}>
        Generate support code
      </Button>
      {displayError && (
        <Box w={rem(350)}>
          <ErrorAlert />
        </Box>
      )}
    </>
  );
};

export default SupportCode;

const useCountdownTimer = (expiresAt: string) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const expirationTime = new Date(expiresAt).getTime();
      const difference = expirationTime - now;

      if (difference <= 0) {
        setTimeLeft('The code has expired');
        return;
      }

      const minutes = Math.floor(difference / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft(
        `Expires in ${minutes}:${seconds.toString().padStart(2, '0')}`,
      );
    };

    calculateTimeLeft(); // Initial calculation
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [expiresAt]);

  return timeLeft;
};

type ModalDialogProps = {
  token: string;
  isError: boolean;
  isLoading: boolean;
  expiration: string;
  onClose: () => void;
  onRegenerate: () => void;
};

const ModalDialog = ({
  token,
  isError,
  isLoading,
  expiration,
  onClose,
  onRegenerate,
}: ModalDialogProps) => {
  const theme = useMantineTheme();
  const timeLeft = useCountdownTimer(expiration);
  const codeHasExpired = new Date(expiration) < new Date();

  const handleRegenerate = () => {
    onRegenerate();
  };

  return (
    <Box>
      <Button
        p={0}
        fullWidth
        onClick={onClose}
        justify="flex-end"
        size="compact-xs"
        variant="transparent"
      >
        <PiXBold color={theme.colors.neutral[8]} />
      </Button>
      <Text fw={500} size="sm">
        Your support code is:
      </Text>
      <Text size={rem(32)}>{token}</Text>
      <Text c="neutral.7" size="sm">
        {timeLeft}
      </Text>
      <Button
        mt="lg"
        fullWidth
        loading={isLoading}
        variant="primary-outline"
        onClick={handleRegenerate}
        disabled={!codeHasExpired}
      >
        Regenerate Code
      </Button>
      {isError && <ErrorAlert />}
    </Box>
  );
};
