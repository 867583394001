import { DateTime } from 'luxon';
import { forwardRef, useState } from 'react';
import { createStyles } from '@mantine/emotion';
import { Box, Button, Center, Loader, rem, Select, Text } from '@mantine/core';
import { FlexIconShort } from 'assets/svg';
import { useAccountManagement } from './use-account-management';
import {
  formatCurrency,
  capitalizeOnlyFirstLetter,
  centsToFormattedCurrency,
} from '@utilities/formatters';
import {
  RepaymentConfig,
  RepaymentAccount,
  ChargeCardAccount,
} from '@services/flexbase/banking.model';
import AccountSelection from '@common/account-selection';
import AccountBox from '@common/composites/account-box/account-box';
import { PlaidAccount, DepositAccount } from 'types/move-funds.model';
import { useUpdateChargeAccount } from '@queries/use-charge-card-accounts';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { getMappedPaymentAccounts } from '@utilities/formatters/get-mapped-payment-accounts';
import { useAutoPayChargeCardFeatureFlag } from '@utilities/feature-flags';

type ItemProps = {
  label: string;
  balance?: string;
} & React.ComponentPropsWithoutRef<'div'>;

const SelectDepositAccount = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, balance, ...others }: ItemProps, ref) => (
    <Box p="0" w="100%" ref={ref} {...others}>
      <AccountBox
        showArrow={false}
        isListItem={true}
        headerText={label}
        rotateArrow={false}
        subheaderText={balance}
        icon={<FlexIconShort />}
      />
    </Box>
  ),
);
SelectDepositAccount.displayName = 'SelectDepositAccount';

const getSelectedAccount = (
  id: string,
  accountsList: (DepositAccount | PlaidAccount)[],
) => {
  return accountsList.find((acc) => acc.id === id) ?? null;
};

const getInitialAccount = (repaymentConfigurations: RepaymentConfig) => {
  if (repaymentConfigurations?.autoPay) {
    if ('depositAccountId' in repaymentConfigurations.autoPay) {
      const depositAccountId = repaymentConfigurations.autoPay.depositAccountId;
      return depositAccountId;
    }
    if ('plaidAccountId' in repaymentConfigurations.autoPay) {
      const plaidAccountId = repaymentConfigurations.autoPay.plaidAccountId;
      return plaidAccountId;
    }
  }
};

type ChargeAutoPayProps = {
  isChargeAccountsError: boolean;
  chargeAccount: ChargeCardAccount;
  refetchChargeAccounts: () => void;
};

const ChargeAutoPay = ({
  chargeAccount,
  isChargeAccountsError,
  refetchChargeAccounts,
}: ChargeAutoPayProps) => {
  const { classes } = useChargeAutoPayStyles();
  const { accounts, isError, isLoading, refetchAccounts } =
    useAccountManagement();
  const { mutate: updateAutoPay, isPending } = useUpdateChargeAccount();
  const isAutoPayChargeCardFeatureFlagEnabled =
    useAutoPayChargeCardFeatureFlag();

  const handleRefetchData = () => {
    if (isChargeAccountsError) {
      refetchChargeAccounts();
    }

    if (isError) {
      refetchAccounts();
    }
  };

  const minimumDue = Number(chargeAccount.remainingAmountDue) / 100;

  const initAccount = getSelectedAccount(
    getInitialAccount(chargeAccount.repaymentConfigurations) ?? '',
    accounts,
  );

  const [selectedAccount, setSelectedAccount] = useState<
    DepositAccount | PlaidAccount | null
  >(initAccount);

  const currentAccount = selectedAccount ?? initAccount;

  const accountsDropdownData = getMappedPaymentAccounts(accounts).map(
    (acc) => ({
      value: acc.id,
      label: acc.accountName,
      balance: `${centsToFormattedCurrency(
        acc?.accountAvailable || 0,
      )} / ${capitalizeOnlyFirstLetter(acc?.accountType || '')} ${GetPaddedAccountMask(
        acc.accountNumber,
        4,
      )}`,
    }),
  );

  const handleAccountChange = (value: string | null) => {
    if (!value) return;
    const account = getSelectedAccount(value, accounts);
    const autopayForm: RepaymentAccount =
      account?.plaidOrDeposit === 'deposit'
        ? { depositAccountId: value }
        : { plaidAccountId: value };

    updateAutoPay(
      {
        accountId: chargeAccount.id,
        req: {
          repaymentConfigurations: {
            autoPay: autopayForm,
          },
        },
      },
      {
        onSuccess() {
          setSelectedAccount(account);
        },
      },
    );
  };

  if (isError || isChargeAccountsError) {
    return (
      <Box
        p="xl"
        h="100%"
        bg="neutral.0"
        sx={(theme) => ({
          borderRadius: theme.defaultRadius,
          border: `1px solid ${theme.colors.neutral[2]}`,
        })}
      >
        <Text c="neutral.8" fw={500}>
          Auto pay settings
        </Text>
        <Text size="sm" mt="sm">
          Something went wrong trying to retrieve auto pay information
        </Text>
        <Button mt="md" onClick={handleRefetchData} variant="primary-filled">
          Reload data
        </Button>
      </Box>
    );
  }

  return (
    <Box
      p="xl"
      h="100%"
      bg="neutral.0"
      sx={(theme) => ({
        borderRadius: theme.defaultRadius,
        border: `1px solid ${theme.colors.neutral[2]}`,
      })}
    >
      <Text c="neutral.8" fw={500}>
        Auto pay settings
      </Text>
      {isLoading ? (
        <Center h="80%">
          <Loader size="md" />
        </Center>
      ) : (
        <>
          <Text fw={500} fz={rem(26)} mb="xl">
            {chargeAccount?.nextRepaymentDueDate
              ? DateTime.fromISO(chargeAccount?.nextRepaymentDueDate).toFormat(
                  'LLL dd',
                )
              : DateTime.now().toFormat('LLL dd')}{' '}
            for {formatCurrency(minimumDue)}
          </Text>
          <Text c="neutral.8" fw={500} mb="sm">
            Default payment account
          </Text>
          {currentAccount ? (
            <AccountSelection
              disabled={isPending}
              accounts={accounts}
              currentAccount={currentAccount}
              showAccountFilters={isAutoPayChargeCardFeatureFlagEnabled}
              onAccountChange={(account) => handleAccountChange(account.id)}
            />
          ) : (
            <Select
              onChange={handleAccountChange}
              data={accountsDropdownData}
              renderOption={({ option }) => (
                <SelectDepositAccount {...option} />
              )}
              searchable
              placeholder="Select an account"
              rightSection={isPending && <Loader size="xs" />}
              classNames={classes}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ChargeAutoPay;

const useChargeAutoPayStyles = createStyles(() => ({
  option: {
    padding: 0,
    ':hover': {
      backgroundColor: 'unset',
    },
  },
}));
