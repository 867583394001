import { Avatar, Flex, Menu, rem, UnstyledButton } from '@mantine/core';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useAuthToken } from 'providers/auth.provider';
import { UserInfoState } from 'types/user-info';
import { PiUploadSimpleBold } from 'react-icons/pi';

const UserActionButtons = () => {
  const [opened, setOpened] = useState(false);
  const userInfo = useRecoilValue(UserInfoState);
  const { logout } = useAuthToken();

  return (
    <Menu
      position="bottom-end"
      opened={opened}
      onChange={(open) => setOpened(open)}
      closeOnClickOutside={true}
    >
      <Menu.Target>
        <Flex align="center" maw={rem(143)}>
          <UnstyledButton>
            <Avatar
              radius="xl"
              src={userInfo.picUrl || null}
              data-testid="user-avatar"
              sx={(theme) => ({
                borderColor: theme.colors.neutral[4],
              })}
            >
              {userInfo.initials}
            </Avatar>
          </UnstyledButton>
        </Flex>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={
            <PiUploadSimpleBold
              size={'1rem'}
              style={{ transform: 'rotate(90deg)' }}
            />
          }
          onClick={() => logout()}
          data-testid="logout-button"
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserActionButtons;
