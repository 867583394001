import { DateTime, Interval } from 'luxon';
import { DateRangeFilter } from '@common/composites/date-range.filter';
import { useCheckDepositModalFilters } from './use-check-deposits-filters';

export const CheckDepositsDateRangeFilter = () => {
  const paymentFilters = useCheckDepositModalFilters();

  return (
    <DateRangeFilter
      filterHook={paymentFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        const itemDate = DateTime.fromFormat(item.createdAt, 'LLL d, yyyy');
        return dateRange.contains(itemDate);
      }}
    />
  );
};
