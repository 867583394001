import { Button, Text, Stack } from '@mantine/core';
import { BsArrowRight } from 'react-icons/bs';
import { FiCheck } from 'react-icons/fi';
import { useStyles } from './modal-success.styles';

type Props = {
  title: string;
  backTo: string;
  description?: string;
  textToStart?: string;
  onClick?: () => void;
  closeModal: () => void;
};

const ModalSuccess = ({
  title,
  closeModal,
  onClick,
  backTo,
  description,
  textToStart,
}: Props) => {
  const { classes } = useStyles();
  return (
    <div>
      <div className={classes.modalSuccess}>
        <div
          className="check_icon"
          style={{ backgroundColor: 'rgba(39, 194, 129, 0.1)' }}
        >
          <FiCheck color="#27C281" size={38} />
        </div>

        <Text fz={28} color="#000" mt={12} ff="PP Neue Montreal">
          {title}
        </Text>
        {description && (
          <div style={{ textAlign: 'center' }}>{description}</div>
        )}
        <Stack>
          <Button onClick={() => closeModal()} variant="primary-filled">
            Go back to {backTo}
          </Button>

          {textToStart && (
            <Button
              variant="neutral-outline"
              onClick={onClick}
              rightSection={<BsArrowRight />}
            >
              {textToStart}
            </Button>
          )}
        </Stack>
      </div>
    </div>
  );
};
export default ModalSuccess;
