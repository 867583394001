import { useState } from 'react';
import GetPaddedAccountMask from '../../../utilities/formatters/get-padded-account-mask';
import { PiEyeBold, PiEyeSlashBold } from 'react-icons/pi';
import { useHover } from '@mantine/hooks';
import { Flex, useMantineTheme } from '@mantine/core';

export const AccountMaskCell = ({
  accountNumber,
  allowToggle = true,
}: {
  accountNumber: string;
  allowToggle?: boolean;
}) => {
  const theme = useMantineTheme();
  const [hideAccount, setHideAccount] = useState(true);
  const { hovered, ref } = useHover();

  if (!allowToggle) {
    return (
      <Flex direction="row" align="center" gap={4} sx={{ flexShrink: 0 }}>
        <div>{GetPaddedAccountMask(accountNumber, 6)}</div>
      </Flex>
    );
  }

  return (
    <Flex
      direction="row"
      align="center"
      gap={4}
      sx={{ cursor: 'pointer', flexShrink: 0 }}
      onClick={() => setHideAccount((p) => !p)}
      ref={ref}
    >
      <div>
        {hideAccount ? GetPaddedAccountMask(accountNumber, 6) : accountNumber}
      </div>
      {hideAccount && (
        <PiEyeBold
          color={theme.colors.neutral[6]}
          size={'1rem'}
          opacity={hovered ? 1 : 0}
        />
      )}
      {!hideAccount && (
        <PiEyeSlashBold color={theme.colors.neutral[6]} size={'1rem'} />
      )}
    </Flex>
  );
};

export default AccountMaskCell;
