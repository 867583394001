import { Radio, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { useStyles } from '../../styles';
import {
  BenefitsGoldDollar,
  BenefitsGoldFlex,
} from '../../../../../assets/svg';

export const RedemptionAmountContent = () => {
  const [custom, setCustom] = useState(false);
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const TEXT_INPUT_STYLE = {
    backgroundColor: theme.colors.neutral[3],
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    fontFamily: 'PP Neue Montreal',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.colors.primary[8],
    width: '166px',
  };

  return (
    <div>
      <div
        className={cx(
          classes.amountRedemptionRadio,
          !custom && classes.amountRedemptionRadioSelected,
          custom && classes.amountRedemptionRadioUnselected,
        )}
        onClick={() => setCustom(false)}
      >
        <div>
          <Text className={classes.amountRadioTextBold}>
            Full Points Balance
          </Text>
          <Text className={classes.amountRadioTextLight}>$100,000 pts</Text>
        </div>

        <Radio style={{ justifySelf: 'end' }} checked={!custom} />
      </div>
      <div
        className={cx(
          classes.amountRedemptionRadio,
          custom && classes.amountRedemptionRadioSelected,
          !custom && classes.amountRedemptionRadioUnselected,
        )}
        onClick={() => setCustom(true)}
      >
        <div>
          <Text className={classes.amountRadioTextBold}>Custom</Text>
          <Text className={classes.amountRadioTextLight}>
            Select a custom amount
          </Text>
        </div>

        <Radio style={{ justifySelf: 'end' }} checked={custom} />
      </div>
      {custom && (
        <div style={{ width: '100%' }}>
          <div
            className={cx(
              classes.redemptionYellowBox,
              classes.redemptionLeftBox,
            )}
          >
            <div className={classes.redemptionIconDiv}>
              <div className={classes.redemptionIcon}>
                <BenefitsGoldFlex height="24px" width="24px"></BenefitsGoldFlex>
              </div>
            </div>
            <Text className={classes.redemptionInputTitle}>Points</Text>
            <TextInput
              styles={{
                input: TEXT_INPUT_STYLE,
              }}
            ></TextInput>
          </div>
          <div
            className={cx(
              classes.redemptionYellowBox,
              classes.redemptionRightBox,
            )}
          >
            <div className={classes.redemptionIconDiv}>
              <div className={classes.redemptionIcon}>
                <BenefitsGoldDollar
                  height="24px"
                  width="24px"
                ></BenefitsGoldDollar>
              </div>
            </div>
            <Text className={classes.redemptionInputTitle}>Value</Text>
            <Text className={classes.redemptionBigText}>$1,000</Text>
          </div>
        </div>
      )}
    </div>
  );
};
