import { SegmentedControl, Text } from '@mantine/core';
import { Outlet, useNavigate } from 'react-router-dom';
import { useBillPayCreditFlag } from '@utilities/feature-flags';
import CreditPaymentTab from './credit-payment';

const PaymentControlContainer = () => {
  const navigate = useNavigate();
  const hasBillPayCreditFeatureFlag = useBillPayCreditFlag();

  const creditPaySection = location.pathname.includes('bill-pay-credit')
    ? 'billPay'
    : 'credit';

  const handleChange = (value: string) => {
    navigate(value === 'credit' ? 'credit' : 'bill-pay-credit/outstanding');
  };

  if (!hasBillPayCreditFeatureFlag) return <CreditPaymentTab />;

  return (
    <>
      <SegmentedControl
        fullWidth
        value={creditPaySection}
        onChange={handleChange}
        data={[
          { value: 'credit', label: <Text>Credit</Text> },
          { value: 'billPay', label: <Text>Bill Pay</Text> },
        ]}
        variant="secondary"
      />
      <Outlet />
    </>
  );
};

export default PaymentControlContainer;
