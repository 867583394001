import { showNotification } from '@mantine/notifications';
import {
  useCreateRecipient,
  useUpdateRecipient,
} from '@queries/use-recipients';
import {
  CounterpartyRequest,
  CreateBeneficiary,
} from '@services/flexbase/banking.model';
import { UseMutateFunction } from '@tanstack/react-query';
import { useQueryParams } from '@utilities/url/query-param';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'underscore';
import { recipientDetailsFormInitialValues } from './add-recipient';
import {
  RecipientDetailsStepFormValues,
  isIndividual,
} from './steps/recipient-details/recipient-details-step';
import { useState } from 'react';

const shouldCreateRecipient = (
  values: RecipientDetailsStepFormValues,
  recipientIdQueryParam: string | null,
  savedRecipientId: string,
): values is RecipientDetailsStepFormValues => {
  return (
    !isEqual(values, recipientDetailsFormInitialValues) &&
    !recipientIdQueryParam &&
    savedRecipientId.length === 0
  );
};

const shouldUpdateRecipientWithDob = (
  isIndividualEntity: boolean,
  recipientId: string | null,
  dob?: string,
) => {
  return isIndividualEntity && !!recipientId && !!dob;
};

const buildNewParams = (
  params: CreateBeneficiary | CounterpartyRequest,
  recipientId: string | null,
) => {
  const isCounterpartyRequest = 'type' in params;

  return isCounterpartyRequest
    ? {
        ...params,
        counterparty: {
          ...params.counterparty,
          recipientId: recipientId,
        },
      }
    : {
        ...params,
        recipientId,
      };
};

type SubmissionErrorType =
  | 'updateRecipient'
  | 'createRecipient'
  | 'createCounterpartyOrBeneficiary';
export type SubmissionError = {
  message: string;
  errorType: SubmissionErrorType;
};

export const useAddRecipientSubmit = (
  recipientDetailsFormValues: RecipientDetailsStepFormValues,
  givenMutation: UseMutateFunction<any, Error, any, unknown>,
) => {
  const { mutate: createRecipient, isPending: isPendingRecipientCreation } =
    useCreateRecipient();
  const { mutate: updateRecipient, isPending: isPendingRecipientUpdate } =
    useUpdateRecipient(false); // Do not invalidate query upon success, handles error scenario edge case (DM)
  const query = useQueryParams();
  const recipientIdQueryParam = query.get('recipientId');
  const navigate = useNavigate();
  const [submissionError, setSubmissionError] = useState<SubmissionError>();
  const [savedRecipientId, setSavedRecipientId] = useState('');

  const navigateUponSuccess = () => {
    navigate(-1);
    const message = recipientIdQueryParam
      ? 'Additional payment method created successfully'
      : 'Recipient created successfully';
    showNotification({
      message,
      color: 'sage.4',
    });
  };

  const processError = (
    err: Error,
    message: string,
    errorType: SubmissionErrorType,
  ) => {
    console.error(`Add recipent flow submission error: ${err}`);
    setSubmissionError({
      message,
      errorType,
    });
  };

  const handleClearSubmissionError = () => {
    setSubmissionError(undefined);
  };

  const isIndividualEntity = isIndividual(recipientDetailsFormValues.type);

  const submitRecipient = (
    params: CreateBeneficiary | CounterpartyRequest,
    dob?: string,
  ) => {
    /*
    We must include `dob` field for new + existing recipients when also
    creating an 'individual'-type beneficiary, as it's required for int'l payments,
    but optional for domestic payments.
    */
    if (dob) {
      recipientDetailsFormValues.dob = dob;
    }

    if (
      shouldUpdateRecipientWithDob(
        isIndividualEntity,
        recipientIdQueryParam,
        dob,
      )
    ) {
      updateRecipient(
        {
          id: recipientIdQueryParam!,
          params: { dob },
        },
        {
          onSuccess: (data) => {
            const newParams = buildNewParams(params, data.recipient.id);
            const derivedRecipientType =
              'counterparty' in newParams ? 'domestic' : 'international';
            givenMutation(
              {
                ...newParams,
              },
              {
                onSuccess: () => {
                  navigateUponSuccess();
                },
                onError: (err) => {
                  setSavedRecipientId(data.recipient.id);
                  // NOTE: `derivedRecipientType` is used to quietly denote error type for faster troubleshooting
                  processError(
                    err,
                    `An error occurred while creating this ${derivedRecipientType} recipient, please go back and try again.`,
                    'createCounterpartyOrBeneficiary',
                  );
                },
              },
            );
          },
          onError: (err) => {
            processError(
              err,
              'An error occurred while updating this recipient, please go back and try again.',
              'updateRecipient',
            );
          },
        },
      );
    } else if (
      shouldCreateRecipient(
        recipientDetailsFormValues,
        recipientIdQueryParam,
        savedRecipientId,
      )
    ) {
      createRecipient(recipientDetailsFormValues, {
        onSuccess: (data) => {
          const newRecipientId = data.recipient.id;
          const newParams = buildNewParams(params, newRecipientId);
          const derivedRecipientType =
            'counterparty' in newParams ? 'domestic' : 'international';

          givenMutation(
            {
              ...newParams,
            },
            {
              onSuccess: () => {
                navigateUponSuccess();
              },
              onError: (err) => {
                setSavedRecipientId(newRecipientId);
                processError(
                  err,
                  `An error occurred while creating this ${derivedRecipientType} recipient, please go back and try again.`,
                  'createCounterpartyOrBeneficiary',
                );
              },
            },
          );
        },
        onError: (err) => {
          processError(
            err,
            'An error occurred while creating this recipient, please go back and try again.',
            'createRecipient',
          );
        },
      });
    } else {
      const newParams = buildNewParams(
        params,
        recipientIdQueryParam || savedRecipientId,
      );
      const derivedRecipientType =
        'counterparty' in newParams ? 'domestic' : 'international';

      givenMutation(
        {
          ...newParams,
        },
        {
          onSuccess: () => {
            navigateUponSuccess();
          },
          onError: (err) => {
            processError(
              err,
              `An error occurred while creating this ${derivedRecipientType} recipient, please go back and try again.`,
              'createCounterpartyOrBeneficiary',
            );
          },
        },
      );
    }
  };

  return {
    submitRecipient,
    isPending: isPendingRecipientCreation || isPendingRecipientUpdate,
    submissionError,
    handleClearSubmissionError,
  };
};
