import { DollarAmountTitle } from '@common/composites/dollar-amount-title';
import { Box, Flex, Progress, rem, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { formatCurrency } from '@utilities/formatters/format-currency';

type Props = {
  hasDelinquentAmount?: boolean;
};

export const useStyles = createStyles(
  (theme, { hasDelinquentAmount }: Props) => ({
    creditContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      borderRadius: theme.defaultRadius,
      backgroundColor: theme.colors.neutral[0],
      border: `1px solid ${theme.colors.neutral[2]}`,
      height: rem(200),
      minWidth: rem(420),
      '@media(max-width: 767px)': {
        width: '100%',
        minWidth: 'unset',
      },
      ...(hasDelinquentAmount && {
        border: `1px solid rgba(48, 44, 255, 0.03)`,
      }),
    },
    totalBalanceContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing.xl,
      justifyContent: 'space-between',
      opacity: hasDelinquentAmount ? '0.3' : '',
      borderColor: theme.colors.neutral[4],
    },
  }),
);

type CreditBalance = {
  hold: number;
  total: number;
  spent: number;
  available: number;
  utilization: number;
};

type WidgetProps = {
  creditBalance: CreditBalance;
  frozenSection?: JSX.Element;
  hasDelinquentAmount?: boolean;
};

const CreditLimitWidget = ({
  creditBalance,
  frozenSection,
  hasDelinquentAmount,
}: WidgetProps) => {
  const { classes } = useStyles({ hasDelinquentAmount });

  return (
    <Box className={classes.creditContainer}>
      {frozenSection}
      <Box className={classes.totalBalanceContainer}>
        <Box>
          <Text c="neutral.8" fw={500}>
            Available credit
          </Text>
          <DollarAmountTitle
            dollarAmountString={formatCurrency(
              creditBalance.available > 0 ? creditBalance.available : 0,
            )}
            size="sm"
          />
        </Box>
        <Box>
          <Progress size="md" value={creditBalance.utilization} />
          <Flex align="end" justify="space-between" mt="md">
            <Box>
              <Text fs="sm" c="neutral.8">
                {formatCurrency(creditBalance.spent.toString())} spent
              </Text>
              <Text size="xs" c="neutral.6">
                {formatCurrency(creditBalance.hold)} on hold
              </Text>
            </Box>
            <Text fs="sm" c="neutral.8">
              of {formatCurrency(creditBalance.total.toString())} limit
            </Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default CreditLimitWidget;
