import {
  Text,
  Button,
  Badge,
  Avatar,
  Flex,
  Box,
  rem,
  TextInput,
} from '@mantine/core';
import { FlexbaseTable } from 'components/table';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { sortAmount, sortDate } from '@utilities/dates';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { UserInfoState } from 'types/user-info';
import { useRecoilValue } from 'recoil';
import GenericError from '@common/generic-error';
import { TableColumn } from 'react-data-table-component';
import {
  generateBillPaymentRows,
  TablePayment,
} from '@utilities/payments-rows';
import PaymentDetailsModal from '@common/payment-details-modal';
import { useStyles } from './action-required.styles';
import { useBillpayPayments } from '@utilities/custom-hooks/use-payments';
import { getInitialsOfNames } from '@utilities/object';
import { useFilterModal } from '@common/utilities/filter/filter-modal';
import {
  useActionRequiredFilters,
  useActionRequiredModalFilters,
} from './use-action-required-filters';
import { filterStatus } from './filters/filters';
import { FilterChips } from '@common/composites/filter-chips';
import EmptyBillTable from '../bills/empty-bill-table';
import { PiFunnel, PiMagnifyingGlass, PiPlusBold } from 'react-icons/pi';

const columns: TableColumn<TablePayment>[] = [
  {
    name: 'Date submitted',
    selector: (payment) => payment.dateSubmitted ?? '',
    sortable: true,
    sortFunction: sortDate,
  },
  {
    name: 'Recipient',
    cell: (row) => (
      <Flex align="center" data-tag="allowRowEvents">
        <Avatar data-tag="allowRowEvents" radius="xl" mr={15} w={45} h={45}>
          <Text data-tag="allowRowEvents">
            {getInitialsOfNames(row.vendor ?? '')}
          </Text>
        </Avatar>
        <Text data-tag="allowRowEvents" sx={{ flex: 1 }}>
          {row.vendor}
        </Text>
      </Flex>
    ),
    selector: (payment) => payment.vendor ?? '',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Amount',
    selector: (payment) => payment.amount,
    sortable: true,
    sortFunction: sortAmount,
  },
  {
    name: 'Payment method',
    selector: (payment) => payment.type,
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Submitted by',
    cell: (payment) => (
      <Flex align="center">
        <Avatar radius="xl" mr={15} w={45} h={45}>
          {getInitialsOfNames(payment.requestedBy ?? '')}
        </Avatar>
        {payment.requestedBy}
      </Flex>
    ),
    selector: (payment) => payment.requestedBy ?? '',
    sortable: true,
    grow: 1.4,
  },
  {
    name: 'Invoice number',
    cell: (payment) => payment.invoiceNumber ?? 'N/A',
    selector: (payment) => payment.invoiceNumber ?? '',
    sortable: true,
  },
  {
    name: 'Due date',
    selector: (payment) => payment.expectedCompletionDate ?? '',
    sortable: true,
  },
  {
    name: 'Status',
    sortable: true,
    selector: (payment) => payment.status,
    cell: (payment) => (
      <Badge bg="warning.0">
        <Text c="warning.5">{formatApiStrings(payment.status)}</Text>
      </Badge>
    ),
  },
];

const columnsSm: TableColumn<TablePayment>[] = [
  {
    name: 'Date submitted',
    selector: (payment) => payment.dateSubmitted ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Recipient',
    cell: (row) => (
      <Flex align="center" data-tag="allowRowEvents">
        <Avatar data-tag="allowRowEvents" radius="xl" mr={15} w={45} h={45}>
          <Text data-tag="allowRowEvents">
            {getInitialsOfNames(row.vendor ?? '')}
          </Text>
        </Avatar>
        <Text data-tag="allowRowEvents" sx={{ flex: 1 }}>
          {row.vendor}
        </Text>
      </Flex>
    ),
    selector: (payment) => payment.vendor ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Amount',
    selector: (payment) => payment.amount,
    sortable: true,
    sortFunction: sortAmount,
  },
  {
    name: 'Payment method',
    selector: (payment) => payment.type,
    sortable: true,
    grow: 2,
  },
  {
    name: 'Submitted by',
    cell: (payment) => (
      <Flex align="center">
        <Avatar radius="xl" mr={15} w={45} h={45}>
          {getInitialsOfNames(payment.requestedBy ?? '')}
        </Avatar>
        {payment.requestedBy}
      </Flex>
    ),
    selector: (payment) => payment.requestedBy ?? '',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Invoice number',
    selector: (payment) => payment.invoiceNumber ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Due date',
    selector: (payment) => payment.expectedCompletionDate ?? '',
    sortable: true,
    grow: 1.8,
  },
  {
    name: 'Status',
    sortable: true,
    selector: (payment) => payment.status,
    cell: (payment) => (
      <Badge bg="warning.0">
        <Text c="warning.5">{formatApiStrings(payment.status)}</Text>
      </Badge>
    ),
    grow: 2.4,
  },
];

const ActionRequired = () => {
  const { id } = useParams();
  const user = useRecoilValue(UserInfoState);
  const isBookkeeper = user.roles.includes('ACCOUNTANT');
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [searchTerm, setSearchTerm] = useState<string>();

  const { users, payments, depositAccounts, isError, loading, refetch } =
    useBillpayPayments();

  const handleSearchChange = (val: string) => setSearchTerm(val.toLowerCase());

  const rows = generateBillPaymentRows({
    users,
    payments,
    depositAccounts,
  }).filter((payment) => {
    if (!searchTerm) {
      return payment;
    } else {
      return (
        payment?.dateSubmitted?.toLowerCase().includes(searchTerm) ||
        payment.accountName?.toLowerCase().includes(searchTerm) ||
        payment.amount.toLowerCase().includes(searchTerm) ||
        payment.status.toLowerCase().includes(searchTerm) ||
        payment.description.toLowerCase().includes(searchTerm) ||
        payment.requestedBy?.toLowerCase().includes(searchTerm) ||
        payment.recipient?.toLowerCase().includes(searchTerm) ||
        payment.notes?.toLowerCase().includes(searchTerm) ||
        payment.type.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
        payment.invoiceNumber
          ?.toLowerCase()
          .startsWith(searchTerm.toLowerCase()) ||
        payment.expectedCompletionDate
          ?.toLowerCase()
          .startsWith(searchTerm.toLowerCase()) ||
        payment.vendor?.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    }
  });

  if (isError) {
    return (
      <GenericError>
        <p>Error</p>
        <Button variant="primary-light" onClick={refetch} mt={20}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  const openPayment = rows.find((row) => row.id === id);

  const awaitingApprovalRows = rows
    .filter(
      (row) =>
        row.status === 'Approval Needed' ||
        (row.status === '2FA Required' &&
          (row.approvedBy === user.id || row.userId === user.id)),
    )
    .map((row) => ({
      ...row,
      status: row.status === 'Approval Needed' ? 'Needs approval' : row.status,
    }))
    .sort((a, b) => sortDate(a, b, false));

  const filterHook = useActionRequiredFilters();
  const modalFilterHook = useActionRequiredModalFilters();
  const {
    activeFiltersArray,
    removeFilter,
    removeAllFilters,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  const actionRequiredFilteredData: TablePayment[] = useMemo(() => {
    if (!awaitingApprovalRows) return [];
    return (awaitingApprovalRows ?? []).filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [awaitingApprovalRows, activeFiltersArray]);

  const handleCreateInvoice = () => {
    navigate('/bill-pay/new');
  };

  const openModal = () => {
    openResponsiveFilterModal(filterStatus(awaitingApprovalRows));
  };

  return (
    <>
      {openPayment && <PaymentDetailsModal openPayment={openPayment} />}
      <Box className={classes.container}>
        <Box className={classes.header} mb={rem(24)}>
          <Flex>
            <TextInput
              w={isMobile ? '100%' : '300px'}
              placeholder="Search payments"
              onChange={(e) => handleSearchChange(e.target.value)}
              leftSection={
                <PiMagnifyingGlass
                  size={'1.25rem'}
                  color={theme.colors.neutral[8]}
                />
              }
            />
            <Button
              ml={10}
              variant="neutral-outline"
              rightSection={<PiFunnel />}
              onClick={() => openModal()}
              disabled={awaitingApprovalRows?.length === 0}
            >
              Filters
            </Button>
          </Flex>
          {!isBookkeeper && (
            <Button
              variant="primary-filled"
              onClick={handleCreateInvoice}
              leftSection={<PiPlusBold />}
              data-testId={'add-new-bill-btn'}
            >
              Add New Bill
            </Button>
          )}
        </Box>

        <FilterChips
          filterArray={activeFiltersArray}
          onRemoveFilter={handleRemoveFilter}
          onClearFilters={handleClearFilters}
        />

        <FlexbaseTable
          columns={isMobile ? columnsSm : columns}
          data={actionRequiredFilteredData}
          pagination={awaitingApprovalRows && awaitingApprovalRows?.length > 8}
          onRowClicked={(row) => navigate(`${row.id}`)}
          isFetchingData={loading}
          noDataComponent={
            <EmptyBillTable headingText="There are no bills to display" />
          }
        />
      </Box>
    </>
  );
};

export default ActionRequired;
