import { useState } from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { Combobox, TextInput, useCombobox } from '@mantine/core';
import {
  formatCents,
  formatCurrency,
  capitalizeOnlyFirstLetter,
} from '@utilities/formatters';
import { FlexIconShort } from 'assets/svg';
import AccountBox from '@common/composites/account-box/account-box';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { getMappedPaymentAccounts } from '@utilities/formatters/get-mapped-payment-accounts';

type AccountInfo = {
  id: string;
  accountType?: string;
  accountName: string;
  accountNumber: string;
  accountAvailable?: number;
};

// How should be look the account name in the textinput (Figma ->(https://www.figma.com/design/AVWTIZmKHPJWRefTmNMJ85/POD1-%2F%2F-Bill-Pay-Native?node-id=2875-79671&t=nDlUTF4XHlrX4q87-0))
const getAccountLabel = (acc: AccountInfo) => {
  // display $0.00 amount for deposit accounts
  const accountAvailable =
    acc.accountAvailable === 0 || acc.accountAvailable
      ? ` - ${formatCurrency(formatCents(acc.accountAvailable))} available`
      : '';
  const accountLabel = `${acc.accountName} • ${acc.accountNumber} ${accountAvailable}`;
  return accountLabel;
};

type CustomOptionsSelectProps = {
  placeholder: string;
  isActionDisabled: boolean;
  sendFrom?: PlaidAccount | DepositAccount;
  paymentAccounts: (PlaidAccount | DepositAccount)[];
  onAccountChange: (accountId: string) => void;
};

export const CustomOptionsSelect = ({
  sendFrom,
  placeholder,
  onAccountChange,
  paymentAccounts,
  isActionDisabled,
}: CustomOptionsSelectProps) => {
  const combobox = useCombobox();
  const formatedSendFromAccount = sendFrom
    ? getMappedPaymentAccounts([sendFrom])[0]
    : null;
  const sendFromAccountLabel = formatedSendFromAccount
    ? getAccountLabel(formatedSendFromAccount)
    : null;
  const [value, setValue] = useState(sendFromAccountLabel || '');

  const filterText = value.trim().toLowerCase();
  const filteredOptions = getMappedPaymentAccounts(paymentAccounts).filter(
    (acc) => {
      const accountLabel = getAccountLabel(acc);

      return (
        accountLabel.toLocaleLowerCase().includes(filterText) ||
        acc.accountName.toLowerCase().includes(filterText) ||
        acc.accountNumber?.toLowerCase().includes(filterText)
      );
    },
  );

  const handleAccountChange = (val: string) => {
    const selectedAccount = filteredOptions.find((acc) => acc.id === val);
    if (selectedAccount) {
      onAccountChange(val);
      combobox.closeDropdown();
      const accountLabel = getAccountLabel(selectedAccount);
      setValue(accountLabel);
    }
  };

  return (
    <Combobox onOptionSubmit={handleAccountChange} store={combobox}>
      <Combobox.Target>
        <TextInput
          mt="md"
          value={value}
          placeholder={placeholder}
          disabled={isActionDisabled}
          leftSection={<PiMagnifyingGlass />}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          data-testid="accounts-list"
        />
      </Combobox.Target>

      <Combobox.Dropdown mah={250} sx={{ overflowY: 'auto' }}>
        <Combobox.Options>
          {filteredOptions.length === 0 ? (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          ) : (
            <>
              {filteredOptions.map((acc, index) => {
                // display $0.00 amount for deposit accounts
                const accountAvailable =
                  acc.accountAvailable === 0 || acc.accountAvailable
                    ? formatCurrency(formatCents(acc.accountAvailable))
                    : '';
                const subHeaderText = `${accountAvailable} ${capitalizeOnlyFirstLetter(acc.accountType ?? '')} ${GetPaddedAccountMask(acc.accountNumber ?? '', 4)}`;

                return (
                  <Combobox.Option
                    key={acc.id}
                    value={acc.id}
                    sx={{
                      padding: 0,
                      ':hover': {
                        backgroundColor: 'unset',
                      },
                    }}
                    data-testid={`accounts-list-${index}`}
                  >
                    <AccountBox
                      showArrow={false}
                      isListItem={true}
                      rotateArrow={false}
                      headerText={acc.accountName}
                      subheaderText={subHeaderText}
                      icon={<FlexIconShort />}
                    />
                  </Combobox.Option>
                );
              })}
            </>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
