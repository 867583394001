import {
  Alert,
  Box,
  Checkbox,
  PasswordInput,
  rem,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  RegistrationFormReturnType,
  useRegistration,
} from '../start-screen/use-registration';
import {
  EmailValidator,
  NewPasswordValidator,
  RequiredFieldValidator,
} from '@utilities/validators';
import { ReactNode, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { RegistrationProductsState } from 'recoil-state/application/product-onboarding';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import PasswordStrengthPopover from '@common/password-strength-popover';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';
import { ErrorCircle, RedAlertIcon } from 'assets/svg';
import { TermsOfServiceCheckboxPrompt } from '@common/utilities/terms-prompt';
import { createStyles } from '@mantine/emotion';
import QualificationPageShell from './components/qualification-page-shell';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';

const QualificationRegisterPage = () => {
  const { classes } = useStyles();
  const navigate = useMarketingNavigate();
  const isMobile = useIsMobile();
  const form = useForm<RegistrationFormReturnType>({
    initialValues: {
      cellphoneCountryCode: 'US',
      cellphone: '',
      email: '',
      password: '',
      confirmPassword: '',
      is18: false,
      termsOfServiceSigned: false,
    },
    validate: {
      email: EmailValidator(),
      password: NewPasswordValidator(),
      confirmPassword: (val, formValues) =>
        val && val === formValues.password ? null : 'Passwords must match',
      termsOfServiceSigned: RequiredFieldValidator(),
      is18: RequiredFieldValidator(),
    },
  });
  const [selectedProducts] = useRecoilState(RegistrationProductsState);
  const { loading, registerNewUser, errorMessage, setErrorMessage } =
    useRegistration(form, { useQualificationApplication: true });

  const titleText = useMemo(() => {
    if (selectedProducts.length === 2) {
      return 'Get started with Flex Banking and Credit';
    }
    return `Get started with Flex ${capitalizeOnlyFirstLetter(selectedProducts[0]?.toLowerCase() || '')}`;
  }, []);

  useEffect(() => {
    if (selectedProducts.length === 0) {
      navigate('/qualification');
      window.scroll(0, 0);
    }
  }, [selectedProducts]);

  const handleBackClick = () => {
    navigate('/qualification', { replace: true });
    window.scroll(0, 0);
  };
  const handleContinueClick = () => {
    registerNewUser();
  };

  return (
    <QualificationPageShell
      titleText={titleText}
      subtitleText="We just need an email and password to get started."
      estimatedTime="10 minutes"
      handleContinueClick={handleContinueClick}
      continueLoading={loading}
      handleBackClick={handleBackClick}
    >
      <>
        <Stack px={{ base: 'lg', sm: 0 }}>
          <TextInput
            label="Work email"
            placeholder="Enter work email"
            classNames={{ label: classes.label }}
            inputMode="email"
            {...form.getInputProps('email')}
            id="input-email"
            error={getErrorNodeOrUndefined(form.errors.email)}
          />
          <PasswordStrengthPopover
            inputValue={form.values.password}
            position={`${isMobile ? 'top' : 'bottom'}-start`}
          >
            <PasswordInput
              label="Password"
              placeholder="Password"
              {...form.getInputProps('password')}
              id="input-password"
              data-testid="input-password"
              error={getErrorNodeOrUndefined(form.errors.password)}
            />
          </PasswordStrengthPopover>
          <PasswordInput
            label="Confirm password"
            placeholder="Password"
            {...form.getInputProps('confirmPassword')}
            id="input-confirm-password"
            data-testid="input-confirm-password"
            error={getErrorNodeOrUndefined(form.errors.confirmPassword)}
          />
        </Stack>
        <Stack mt="lg" px={{ base: 'lg', sm: 0 }}>
          <Checkbox
            id="checkbox-legal-age"
            label="I am at least 18 years old"
            {...form.getInputProps('is18', { type: 'checkbox' })}
            color="primary.2"
            error={getErrorNodeOrUndefined(form.errors.is18)}
          />
          <TermsOfServiceCheckboxPrompt
            type="flexbase"
            size="sm"
            color="primary.2"
            {...form.getInputProps('termsOfServiceSigned', {
              type: 'checkbox',
            })}
            classNames={{ label: classes.checkboxLabel }}
            error={getErrorNodeOrUndefined(form.errors.termsOfServiceSigned)}
          />
        </Stack>
        {errorMessage && (
          <Alert
            icon={<RedAlertIcon />}
            my="lg"
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        )}
      </>
    </QualificationPageShell>
  );
};

const InputError = ({ error }: { error: ReactNode }) => {
  return (
    <Box
      sx={(theme) => ({
        svg: { color: theme.colors.critical[2], minWidth: rem(12) },
        display: 'flex',
        alignItems: 'center',
        gap: rem(4),
      })}
    >
      <ErrorCircle />
      <span>{error}</span>
    </Box>
  );
};

function getErrorNodeOrUndefined(
  error: ReactNode | null,
): ReactNode | undefined {
  return error ? <InputError error={error} /> : undefined;
}

const useStyles = createStyles((theme) => ({
  checkboxLabel: {
    fontSize: rem(14),
  },
  label: {
    color: `${theme.colors.primary[8]} !important`,
  },
}));

export default QualificationRegisterPage;
