import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  category: {
    marginTop: theme.spacing.lg,
    marginBottom: '32px',
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
    borderRadius: theme.defaultRadius,
    padding: 20,
  },
  categoryHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    '> div:last-of-type': {
      marginLeft: 'auto',
    },
  },
  categoryName: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '16px',
  },
  categoryScope: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.colors.primary[8],
  },
  contentHeader: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#858585',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    '> div:last-of-type': {
      display: 'flex',
      width: '212px',
      justifyContent: 'space-between',
    },
  },
  notification: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '46px',
  },
  notificationName: {
    fontWeight: 450,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#374151',
    '> div:last-of-type': {
      marginLeft: '22px',
      display: 'inline-block',
    },
  },
  notificationCheckboxes: {
    display: 'flex',
    padding: '0 2px 0 5px',
    justifyContent: 'space-between',
  },
  inputPercent: {
    width: '3.7rem',
    height: '2rem',
    borderRadius: 2,
    borderColor: '#C5C2BE',
    padding: '0.5rem',
  },
  alertDescriptionContainer: {
    display: 'flex !important',
    alignItems: 'center !important',
    margin: ' 0 !important',
  },
}));
