import { Box, BoxProps } from '@mantine/core';
import { RadioSelectCard } from './radio-select-card';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';

type RadioOptions = {
  value: string;
  title: string;
  description: ReactNode;
  icon?: IconType;
  disabled?: boolean;
};
type Props = {
  selected: string;
  onRadioCardClick: (value: string) => void;
  radioOptions: RadioOptions[];
  radioCardProps?: BoxProps;
};

export const RadioSelectList = ({
  selected,
  onRadioCardClick,
  radioOptions,
  radioCardProps,
}: Props) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing.md,
      })}
    >
      {radioOptions.map((r: RadioOptions) => (
        <RadioSelectCard
          selected={selected === r.value}
          onClick={() => onRadioCardClick(r.value)}
          title={r.title}
          icon={r.icon}
          description={r.description}
          id={r.value}
          data-sardine-id={r.value}
          key={r.value}
          boxProps={radioCardProps}
          disabled={r.disabled}
        />
      ))}
    </Box>
  );
};
