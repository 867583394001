import { Box, Divider, Group, Text } from '@mantine/core';
import FlexIconLink from '@common/composites/flex-icon-link';

import { Outlet } from 'react-router-dom';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { useRedemptionMultistepFormContext } from './redemption-multi-step-form.context';
import { useStyles } from './styles';

export const RedemptionFormContainer = () => {
  const { classes, cx } = useStyles();
  const isMobileScreenSize = useMatchMedia('(max-width: 767px)');
  // const { goToNextStep, currentStep, goToPreviousStep, hasPreviousStep, state, setState } = useRedemptionMultistepFormContext();
  const { state } = useRedemptionMultistepFormContext();

  // useEffect(() => {
  //   // Refresh onboarding status on step change.
  //   setState({ loading: false, error: null }); // I'd like to abstract this to the MSF hook one day
  // }, [currentStep]);

  // useEffect(() => {
  //   if (businessPrefillData?.businesses.length === 0) {
  //     setState({ businessPrefillAvailable: false });
  //   } else if (businessPrefillError) {
  //     setState({ businessPrefillAvailable: false });
  //   }
  // }, [businessPrefillData, businessPrefillError]);

  return (
    <>
      <Box className={classes.header}>
        <FlexIconLink width={68} />
        {!isMobileScreenSize && (
          <Box className={classes.headerActions}>
            <Box className={classes.notices}>
              {/*<Group*/}
              {/*  gap="sm"*/}
              {/*  className={classes.creditImpactNotice}*/}
              {/*  c="primary.6"*/}
              {/*>*/}
              {/*  <CheckCircleProgress />*/}
              {/*  <Box className={cx(classes.headerText, classes.progressSaved)}>*/}
              {/*    Progress saved!*/}
              {/*  </Box>*/}
              {/*</Group>*/}
            </Box>
          </Box>
        )}

        {/*<Box className={classes.containerLogout}>*/}
        {/*  <>*/}
        {/*    <Box className={classes.headerText}>*/}
        {/*      <LockIcon /> No impact to credit*/}
        {/*    </Box>*/}
        {/*    <Divider orientation="vertical" size="0.15rem" />*/}
        {/*  </>*/}
        {/*  <Button*/}
        {/*    onClick={() => logout()}*/}
        {/*    leftSection={<LogOutIcon color="black" />}*/}
        {/*    variant="neutral-outline"*/}
        {/*    c="neutral.7"*/}
        {/*    size={isMobileScreenSize ? 'xs' : 'md'}*/}
        {/*  >*/}
        {/*    Log Out*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Box>
      <Divider />
      <Box
        className={cx(
          classes.webContainer,
          isMobileScreenSize && classes.mobileContainer,
        )}
        sx={(_, helpers) => ({
          width: '100%',
          [helpers.largerThan(767)]: {
            width: '32.8125rem',
          },
        })}
      >
        {/*<OnboardingTitleGroup*/}
        {/*  titleText={currentStep!.metadata.title}*/}
        {/*  subtitleText={currentStep!.metadata.description}*/}
        {/*/>*/}
        <Box mih="100%" py="md">
          <Outlet />
        </Box>
        {state.error && (
          <Text fz={12} c="critical.2">
            {state.error}
          </Text>
        )}
        <Group mt="md">
          {/*{hasPreviousStep && (*/}
          {/*  <Button*/}
          {/*    variant="neutral-outline"*/}
          {/*    onClick={() => goToPreviousStep()}*/}
          {/*    disabled={state.loading}*/}
          {/*  >*/}
          {/*    Back*/}
          {/*  </Button>*/}
          {/*)}*/}
          {/*<MultiStepFormSubmitButton*/}
          {/*  form={currentStep?.metadata.id ?? 'none'}*/}
          {/*  variant="primary-light"*/}
          {/*  ml="auto"*/}
          {/*  data-testid="button-continue"*/}
          {/*  loading={state.loading}*/}
          {/*>*/}
          {/*  Continue*/}
          {/*</MultiStepFormSubmitButton>*/}
        </Group>
      </Box>
    </>
  );
};
