import { Group, Stack, useMantineTheme } from '@mantine/core';
import { DateTime } from 'luxon';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';
import { PreviewRow } from '../../components/preview-row';
import { PreviewLabel } from '../../components/preview-label';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import { useCreateSpendPlanWizard } from '../../create-spend-plan.wizard';
import { formatCurrency } from '@utilities/formatters';

export const SpendPlanDetailsStepPreview = () => {
  const { detailsForm: form } = useCreateSpendPlanContext();
  const { state } = useCreateSpendPlanWizard();
  const theme = useMantineTheme();

  const getSpendPlanAmountString = () => {
    if (state.repeatCurrentLimit) {
      return formatCurrency(state.currentLimit || 0);
    } else {
      return formatCurrency(state.futureLimit || 0);
    }
  };

  const getFreqencyString = () => {
    if (state.recurring === 'onetime') {
      return 'One time';
    }

    return capitalizeOnlyFirstLetter(state.frequency) || `Not selected`;
  };

  const getExpirationDateString = () => {
    if (!state.expires || !state.expirationDate) {
      return `No expiration date`;
    }
    return DateTime.fromJSDate(state.expirationDate).toLocaleString(
      DateTime.DATE_MED,
    );
  };

  return (
    <Stack gap={24}>
      {form.values ? (
        <>
          <PreviewRow gap={0}>
            <PreviewLabel>Name</PreviewLabel>

            <PreviewLabel
              fz={32}
              color={theme.colors.primary[8]}
              fs={state.name ? 'normal' : 'italic'}
            >
              {state.name || 'Name'}
            </PreviewLabel>
          </PreviewRow>

          <PreviewRow py={24} bg={theme.colors.neutral[3]}>
            <PreviewLabel>Description</PreviewLabel>

            <PreviewLabel>{state.description}</PreviewLabel>
          </PreviewRow>

          <PreviewRow>
            <Group gap={24} align="start" grow>
              <Stack gap={4}>
                <PreviewLabel>Amount</PreviewLabel>

                <PreviewLabel>{getSpendPlanAmountString()}</PreviewLabel>
              </Stack>

              <Stack gap={4}>
                <PreviewLabel>Frequency</PreviewLabel>

                <PreviewLabel fs={state.frequency ? 'normal' : 'italic'}>
                  {getFreqencyString()}
                </PreviewLabel>
              </Stack>

              <Stack gap={4}>
                <PreviewLabel>Exp. Date</PreviewLabel>

                <PreviewLabel fs={state.expirationDate ? 'normal' : 'italic'}>
                  {getExpirationDateString()}
                </PreviewLabel>
              </Stack>
            </Group>
          </PreviewRow>
        </>
      ) : null}
    </Stack>
  );
};
