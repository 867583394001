import { Avatar, Box, Space, Text } from '@mantine/core';
import { PlatformAccountsInfo } from 'services/platform/models/identity.model';
import useStyles from '../navbar.styles';

type Props = {
  item: PlatformAccountsInfo;
  onAccountClick: (companyId: string) => void;
};
const AccountsGroup = ({ item, onAccountClick }: Props) => {
  const { classes } = useStyles({});
  return (
    <>
      <div
        className={classes.dropdownOptionsContainer}
        onClick={() => {
          onAccountClick(item.businessId);
        }}
      >
        <Box>
          <Avatar
            sx={(theme) => ({
              border: `2px solid ${theme.colors.primary[2]}`,
            })}
            color="white"
            size={'sm'}
          >
            {item.name[0].toUpperCase()}
          </Avatar>
        </Box>
        <div className={classes.nameSection}>
          <Text size="sm" ml={15} className={classes.optionAccountName}>
            {item.name}
          </Text>
        </div>
      </div>
      <Space h="xs" />
    </>
  );
};

export default AccountsGroup;
