import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flexbaseBankingClient } from '../services/flexbase-client';
import { UpdateChargeAccount } from '@services/flexbase/banking.model';

const QUERY_KEY = 'chargeCardAccounts';

export const useGetChargeCardAccounts = (enabled?: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseBankingClient.getChargeCardAccounts();
    },
    meta: {
      errorMessage: 'Unable to retrieve charge card accounts at this time.',
    },
    enabled: enabled ?? true,
  });
};

type UpdateChargeAccountInput = {
  accountId: string;
  req: UpdateChargeAccount;
};

const mutationUpdateChargeAccount = async ({
  accountId,
  req,
}: UpdateChargeAccountInput) => {
  return await flexbaseBankingClient.updateChargeAccount(accountId, req);
};

export function useUpdateChargeAccount() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mutationUpdateChargeAccount,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
    onError: (error) => {
      showNotification({
        color: 'red',
        title: 'Error updating the account',
        message: `${error.message}`,
      });
    },
  });
}
