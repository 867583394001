import { Divider, Text } from '@mantine/core';

const EmptyState = ({ direction }: { direction: 'Credit' | 'Debit' }) => (
  <>
    <Text size="sm" ta="center" c="neutral.8" fs="italic">
      There was no money {direction === 'Credit' ? 'in' : 'out'} activity during
      this time
    </Text>
    <Divider mt="sm" />
  </>
);

export default EmptyState;
