import { rem, Select, Stack, useMantineTheme } from '@mantine/core';
import QualificationPageShell from './components/qualification-page-shell';
import { useState } from 'react';
import {
  useUpdateApplication,
  useGetQualificationApplication,
} from './use-qualification-application';
import { useQualificationApplication } from 'areas/onboarding-v2/qualification-application-provider';
import {
  QualificationApplicationAnnualGrossRevenue,
  QualificationApplicationRevenueMonths,
} from '@flexbase-eng/sdk-typescript/models/components';
import { Analytics } from '@services/analytics/analytics';
import { QualificationApplicationEvents } from '@services/analytics/events';
import { showNotification } from '@mantine/notifications';
import QueryErrorMessage from 'areas/onboarding-v2/components/query-error-message';

const BUSINESS_ANNUAL_REVENUE = {
  '$0 - $120,000': { lower: 0, upper: 120000 },
  '$120,000 - $250,000': { lower: 120000, upper: 250000 },
  '$250,000 - $500,000': { lower: 250000, upper: 500000 },
  '$500,000 - $2,000,000': { lower: 500000, upper: 2000000 },
  '$2,000,000 - $5,000,000': { lower: 2000000, upper: 5000000 },
  'Over $5,000,000': { lower: 5000000, upper: undefined },
};

type BusinessAnnualRevenue = keyof typeof BUSINESS_ANNUAL_REVENUE;
const BUSINESS_REVENUE_AGE = {
  '0 - 6 months': { lower: 0, upper: 6 },
  '6 - 12 months': { lower: 6, upper: 12 },
  '12 - 18 months': { lower: 12, upper: 18 },
  '18+ months': { lower: 18, upper: undefined },
};
type BusinessRevenueAge = keyof typeof BUSINESS_REVENUE_AGE;

const getAnnualRevenueFromRange = (
  fromRange?: QualificationApplicationAnnualGrossRevenue,
) => {
  if (!fromRange) return null;
  return Object.keys(BUSINESS_ANNUAL_REVENUE).find((key) => {
    const range = BUSINESS_ANNUAL_REVENUE[key as BusinessAnnualRevenue];
    return fromRange.lower === range.lower && fromRange.upper === range.upper;
  }) as BusinessAnnualRevenue | null;
};

const getRevenueAgeFromRange = (
  fromRange?: QualificationApplicationRevenueMonths,
) => {
  if (!fromRange) return null;
  return Object.keys(BUSINESS_REVENUE_AGE).find((key) => {
    const range = BUSINESS_REVENUE_AGE[key as BusinessRevenueAge];
    return fromRange.lower === range.lower && fromRange.upper === range.upper;
  }) as BusinessRevenueAge | null;
};

const compareRevenueAge = (
  revenueAge: BusinessRevenueAge | null,
  applicationRevenueMonths: QualificationApplicationRevenueMonths | undefined,
) => {
  if (!revenueAge || !applicationRevenueMonths) return false;
  return (
    BUSINESS_REVENUE_AGE[revenueAge as BusinessRevenueAge].lower ===
      applicationRevenueMonths.lower &&
    BUSINESS_REVENUE_AGE[revenueAge as BusinessRevenueAge].upper ===
      applicationRevenueMonths.upper
  );
};

const compareAnnualRevenue = (
  annualRevenue: BusinessAnnualRevenue | null,
  applicationAnnualGrossRevenue:
    | QualificationApplicationAnnualGrossRevenue
    | undefined,
) => {
  if (!annualRevenue || !applicationAnnualGrossRevenue) return false;
  return (
    BUSINESS_ANNUAL_REVENUE[annualRevenue as BusinessAnnualRevenue].lower ===
      applicationAnnualGrossRevenue.lower &&
    BUSINESS_ANNUAL_REVENUE[annualRevenue as BusinessAnnualRevenue].upper ===
      applicationAnnualGrossRevenue.upper
  );
};

const QualificationCompanyRevenuePage = () => {
  const theme = useMantineTheme();
  const { mutate: updateApplication, isPending: isUpdatingApplication } =
    useUpdateApplication();
  const {
    data: application,
    isError,
    refetch,
  } = useGetQualificationApplication();
  const isRejected = application?.status === 'Rejected';
  const { navigateToNextStep, goBack } = useQualificationApplication();
  const [annualRevenue, setAnnualRevenue] =
    useState<BusinessAnnualRevenue | null>(
      getAnnualRevenueFromRange(application?.fields?.annualGrossRevenue) ||
        null,
    );
  const [revenueAge, setRevenueAge] = useState<BusinessRevenueAge | null>(
    getRevenueAgeFromRange(application?.fields?.revenueMonths) || null,
  );

  const handleBackClick = () => {
    goBack();
  };
  const handleContinueClick = () => {
    if (
      isRejected ||
      (compareRevenueAge(revenueAge, application?.fields?.revenueMonths) &&
        compareAnnualRevenue(
          annualRevenue,
          application?.fields?.annualGrossRevenue,
        ))
    ) {
      return navigateToNextStep();
    }
    updateApplication(
      {
        id: application!.id,
        updateApplicationBody: {
          revenueMonths: revenueAge
            ? BUSINESS_REVENUE_AGE[revenueAge]
            : undefined,
          annualGrossRevenue: annualRevenue
            ? BUSINESS_ANNUAL_REVENUE[annualRevenue]
            : undefined,
        },
      },
      {
        onSuccess: (updatedApplication) => {
          Analytics.track(
            QualificationApplicationEvents.QUALIFICATION_APPLICATION_COMPANY_REVENUE_CONTINUE_CLICKED,
            {
              application: updatedApplication,
            },
          );
          navigateToNextStep(updatedApplication);
        },
        onError: () => {
          showNotification({
            color: 'red',
            title: 'Unable to save company information',
            message: 'Please try again.',
          });
        },
      },
    );
  };

  return (
    <QualificationPageShell
      titleText="Revenue"
      handleBackClick={handleBackClick}
      handleContinueClick={
        revenueAge && annualRevenue ? handleContinueClick : undefined
      }
      continueLoading={isUpdatingApplication}
    >
      <Stack px={{ base: 'lg', sm: 0 }}>
        {isError ? (
          <QueryErrorMessage
            message="Unable to load application information."
            refetch={refetch}
          />
        ) : (
          <>
            <Select
              label="How long have you been generating revenue?"
              description="This helps us understand the age of your business."
              styles={{
                label: {
                  fontSize: `${rem(16)} !important`,
                },
                description: {
                  color: theme.colors.neutral[7],
                  fontSize: `${rem(14)} !important`,
                },
              }}
              placeholder="Select"
              data={Object.keys(BUSINESS_REVENUE_AGE).map((r) => ({
                label: r,
                value: r,
              }))}
              value={revenueAge}
              onChange={(value) => setRevenueAge(value as BusinessRevenueAge)}
            />
            <Select
              label="What was your revenue over the past 12 months?"
              description="Gross revenue helps us better understand the overall size and scale of your business."
              styles={{
                label: {
                  fontSize: `${rem(16)} !important`,
                },
                description: {
                  color: theme.colors.neutral[7],
                  fontSize: `${rem(14)} !important`,
                },
              }}
              placeholder="Select"
              data={Object.keys(BUSINESS_ANNUAL_REVENUE).map((r) => ({
                label: r,
                value: r,
              }))}
              value={annualRevenue}
              onChange={(value) =>
                setAnnualRevenue(value as BusinessAnnualRevenue)
              }
            />
          </>
        )}
      </Stack>
    </QualificationPageShell>
  );
};

export default QualificationCompanyRevenuePage;
