import { createWizard } from '@common/emerald/wizard/create-wizard';
import { FileWithPath } from '@mantine/dropzone';
import { MoneyMovement } from 'services/flexbase/banking.model';
import { DepositAccount, PlaidAccount } from '../../../types/move-funds.model';
import { ParsedAccount } from 'types/parsed-account';
import { Recipient } from 'types/recipient';
import { BillpayLineItemAccountingInfo, LineItemStatus } from 'types/bill-pay';

export type LineItemInput = {
  id?: string;
  description?: string;
  quantity?: number;
  total?: number;
  timestamp?: number;
  status?: LineItemStatus;
  accountingInfo?: BillpayLineItemAccountingInfo;
};

export type InvoiceDetails = {
  lineItems: LineItemInput[];
  credits?: number;
  dueDate?: string;
  tax?: number;
  createdAt?: string;
};

export enum PaymentSource {
  ACCOUNT = 'account',
  LOC = 'loc',
}

export type PaymentInfo = {
  paymentSource: PaymentSource;
  creditProgramId?: string;
  sendFrom?: DepositAccount | PlaidAccount; // optional as bills paid with LOC will not have a `sendFrom` field
  sendOn: Date;
};

export type InvoiceWizardError = {
  message: string;
  errorType: 'delete' | 'update' | 'create';
};

export type InvoiceWizardState = {
  existingInvoiceId?: string;
  recipient: Recipient;
  existingDocument?: FileWithPath;
  uploadedDocument?: FileWithPath;
  existingDocumentId?: string;
  isDocumentUploading?: boolean;
  invoiceDetails?: InvoiceDetails;
  invoicePayments?: MoneyMovement[];
  isNextEnabled: boolean;
  invoiceNumber?: string;
  memo: string;
  description?: string;
  notes?: string;
  error?: InvoiceWizardError;
  paymentSubmissionDetails: Error | MoneyMovement;
  invoiceTotal?: { formatted: string; cents: number };
  isInvoiceDraft?: boolean;
  isActionDisabled?: boolean;
  isBookkeeper?: boolean;
  recipientAccount?: ParsedAccount;
  paymentInfo?: PaymentInfo;
};

export const [InvoiceWizard, useInvoiceWizard] =
  createWizard<InvoiceWizardState>();
