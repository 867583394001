import { ActionItemCard } from './action-item-card';
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Image,
  rem,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import {
  useCreateRutterConnection,
  useIntegrationLinks,
} from '@utilities/integrations';
import { getIntegrationCards } from '@utilities/integrations/list-integrations';
import { useMemo, useState } from 'react';
import QuickbooksGreen from 'assets/images/quickbooks.png';
import Netsuite from 'assets/images/oracle-netsuite.png';
import Xero from 'assets/images/xero.png';
import { PiLinkBold, PiWarningCircleBold } from 'react-icons/pi';
import { useDisconnectIntegration } from '@utilities/integrations/use-disconnect-integration';
import { useCompanyDocuments } from '@queries/use-company-documents';
import { RequestedDocRow } from './requested-doc-row';
import { useSelfServiceStyles } from '../self-service-dashboard.styles';
import { ActionItemConstants } from './action-item-constants';

const INTEGRATIONS = getIntegrationCards();

const PREVIOUS_YEAR_TAX_RETURN = `${new Date().getFullYear() - 1} Tax Returns`;
const TWO_YEARS_AGO_TAX_RETURN = `${new Date().getFullYear() - 2} Tax Returns`;

export const AccountingItem = () => {
  const { classes, theme, cx } = useSelfServiceStyles();
  const { openRutterLink, isExchangingToken, isRutterConnecting } =
    useCreateRutterConnection();
  const { data: uploadedDocs, isPending: isUploadingDocs } =
    useCompanyDocuments();
  const { accountingLinks, isPending } = useIntegrationLinks();
  const { disconnectIntegration, isPending: isDisconnecting } =
    useDisconnectIntegration();
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
    subtitle: string;
  } | null>(null);

  const mappedAccountingLinks = useMemo(() => {
    return accountingLinks.map((link) => {
      const integration = INTEGRATIONS.find(
        (i) => i.platform === link.platform,
      );

      return {
        ...link,
        ...integration,
      };
    });
  }, [accountingLinks]);

  const isLoading =
    isPending ||
    isUploadingDocs ||
    isExchangingToken ||
    isRutterConnecting ||
    isDisconnecting;

  const handleDisconnectClick = (
    platform: (typeof mappedAccountingLinks)[number],
  ) => {
    disconnectIntegration({
      platformLabel: platform.title || '',
      connectionId: platform.connectionId,
    });
  };

  const handleUploadError = (error: string) => {
    switch (error) {
      case ActionItemConstants.CLEAR_ERROR:
        setErrorMessage(null);
        break;
      case ActionItemConstants.FILE_SIZE_ERROR:
        setErrorMessage({
          title: 'File size is too large.',
          subtitle: 'We only accept files that are less than 49mb.',
        });
        break;
      case ActionItemConstants.FILE_UPLOAD_ERROR:
      default:
        setErrorMessage({
          title: 'Unable to upload file',
          subtitle:
            'We encountered an unknown error while uploading your file.',
        });
        break;
    }
  };

  return (
    <ActionItemCard
      title="Connect accounting tool"
      subtitle="This gives us a fuller picture of your company's financial history."
    >
      <Stack mt="md" gap="xs">
        {mappedAccountingLinks.length > 0 ? (
          mappedAccountingLinks.map((accountingLink) => (
            <UnstyledButton
              key={accountingLink.id}
              onClick={() => handleDisconnectClick(accountingLink)}
            >
              <Group className={classes.accountContainer} p="lg" align="center">
                <Flex justify="center" align="center">
                  <PiLinkBold size={20} color={theme.colors.neutral[6]} />
                </Flex>
                <Group gap={rem(2)} flex={1}>
                  <Text fw="bold" size="md">
                    {accountingLink.title}
                  </Text>
                </Group>
              </Group>
            </UnstyledButton>
          ))
        ) : (
          <>
            <Text fw={500}>Select your accounting app</Text>
            <Group>
              <Button
                loading={isLoading}
                onClick={() => openRutterLink('QUICKBOOKS')}
                variant="primary-outline"
                leftSection={
                  <Image
                    src={QuickbooksGreen}
                    height={20}
                    width={20}
                    radius={200}
                  />
                }
              >
                QuickBooks Online
              </Button>
              <Button
                loading={isLoading}
                onClick={() => openRutterLink('NETSUITE')}
                variant="primary-outline"
                leftSection={
                  <Image src={Netsuite} height={20} width={20} radius={200} />
                }
              >
                NetSuite
              </Button>
              <Button
                loading={isLoading}
                onClick={() => openRutterLink('XERO')}
                variant="primary-outline"
                leftSection={
                  <Image src={Xero} height={20} width={20} radius={200} />
                }
              >
                Xero
              </Button>
            </Group>

            <Divider my="xl" />

            <Stack gap={0}>
              {errorMessage && (
                <Flex
                  w="100%"
                  bg="critical.0"
                  my="1.5rem"
                  h="5rem"
                  p="md"
                  align="center"
                  gap="md"
                >
                  <Box c="critical.2">
                    <PiWarningCircleBold size={20} />
                  </Box>
                  <Box>
                    <Text c="neutral.10" fw={500}>
                      {errorMessage.title}
                    </Text>
                    <Text c="neutral.8" fz={14}>
                      {errorMessage.subtitle}
                    </Text>
                  </Box>
                </Flex>
              )}
              <Text fw={500}>Don&apos;t see your accounting app?</Text>
              <Text mt={rem(2)} fz={14} c="neutral.7">
                Alternatively, upload the two most recent tax returns filed for
                your business. We accept PDF, Word, Excel, Powerpoint, and image
                files up to 49mb.
              </Text>

              <Box
                mt="xs"
                className={cx(
                  classes.gridTable,
                  classes.gridTableHeader,
                  classes.requestedDocumentsTable,
                  classes.requestedDocumentsTableHeader,
                )}
              >
                <Box>Document type</Box>
                <Box>Status</Box>
              </Box>
              <RequestedDocRow
                docName={PREVIOUS_YEAR_TAX_RETURN}
                uploadedDocs={
                  uploadedDocs?.filter(
                    (d) => d.description === PREVIOUS_YEAR_TAX_RETURN,
                  ) ?? []
                }
                onUploadError={handleUploadError}
              />
              <RequestedDocRow
                docName={TWO_YEARS_AGO_TAX_RETURN}
                uploadedDocs={
                  uploadedDocs?.filter(
                    (d) => d.description === TWO_YEARS_AGO_TAX_RETURN,
                  ) ?? []
                }
                onUploadError={handleUploadError}
              />
            </Stack>
          </>
        )}
      </Stack>
    </ActionItemCard>
  );
};
