import { ExpenseLink } from '@flexbase-eng/types/dist/accounting';
import { Button, List, Stack, Text } from '@mantine/core';
import { FiArrowUpRight } from 'react-icons/fi';

type IntuitBankFeedsProps = {
  link: ExpenseLink;
  strongTitle?: boolean;
};

export const IntuitBankFeeds = ({
  strongTitle = false,
}: IntuitBankFeedsProps) => {
  const handleGoToBankFeed = () => {
    const bankFeedUrl = `${
      import.meta.env.VITE_APP_QUICKBOOKS_ONLINE_URL
    }/app/banking`;
    window.open(bankFeedUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Stack
      sx={(t) => ({
        color: t.colors.primary[8],
      })}
    >
      <Text fw={strongTitle ? 'bold' : undefined}>
        To complete your QBO integration to Flex follow the steps below:
      </Text>

      <List type="ordered" spacing="md" withPadding={false}>
        <List.Item>
          <Text>Navigate to your QBO bank feed.</Text>

          <Button
            variant="neutral-outline"
            mt="xs"
            onClick={handleGoToBankFeed}
            rightSection={<FiArrowUpRight />}
          >
            Go to Bank Feed
          </Button>
        </List.Item>

        <List.Item>
          Click &apos;
          <Text fw="bold" span>
            Link account
          </Text>
          &apos;
        </List.Item>

        <List.Item>
          Search for &apos;
          <Text fw="bold" span>
            Flex
          </Text>
          &apos;
        </List.Item>

        <List.Item>Enter your Flex user ID and password</List.Item>

        <List.Item>Enter the 6-digit code sent to your cellphone</List.Item>

        <List.Item>
          Select &apos;
          <Text fw="bold" span>
            Connect
          </Text>
          &apos;
        </List.Item>

        <List.Item>Select the account(s) you want to connect to QBO</List.Item>

        <List.Item>
          Click &apos;
          <Text fw="bold" span>
            Finish
          </Text>
          &apos; after the connection completes
        </List.Item>
      </List>
    </Stack>
  );
};
