export type CreditCardInterval =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'quarterly'
  | 'annually'
  | 'unlimited';

// for Unit issuing card
export type ChargeCardInterval = 'dailyPurchase' | 'monthlyPurchase';

export type LimitInterval = CreditCardInterval | ChargeCardInterval;

export const LIMIT_INTERVALS = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'annually', label: 'Yearly' },
  { value: 'unlimited', label: 'Unlimited' },
] satisfies { value: LimitInterval; label: string }[];

export const UNIT_LIMIT_INTERVALS = [
  { value: 'dailyPurchase', label: 'Daily' },
  { value: 'monthlyPurchase', label: 'Monthly' },
  { value: 'unlimited', label: 'Unlimited' },
] satisfies { value: LimitInterval; label: string }[];
