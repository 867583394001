import { NavTab } from '@common/composites/tabs/nav-tabs';
import { Outlet } from 'react-router-dom';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import { Box } from '@mantine/core';

const navTabs: NavTab[] = [
  { label: 'Outstanding', route: '/cards/pay/bill-pay-credit/outstanding' },
];

const BillpayCreditTabs = () => {
  return (
    <Box
      mt="md"
      sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <Box sx={{ flex: 1 }}>
        <NavTabContainer tabs={navTabs}>
          <Outlet />
        </NavTabContainer>
      </Box>
    </Box>
  );
};

export default BillpayCreditTabs;
