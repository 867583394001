import { createStyles } from '@mantine/emotion';

export const useEndScreenStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifySelf: 'center',
  },
  graphic: {
    '@media(max-width: 767px)': {
      display: 'none',
    },
  },
  titleDescriptionContainer: {
    width: 380,
    marginLeft: 50,
    '@media(max-width: 767px)': {
      marginLeft: 0,
      padding: '0px 15px 0px 15px',
    },
  },
  title: {
    fontSize: 46,
    fontWeight: 'lighter',
    lineHeight: '50px',
    fontFamily: 'PP Neue Montreal',
    '@media(max-width: 767px)': {
      fontSize: 40,
    },
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '25px',
    fontFamily: 'PP Neue Montreal',
    color: '#5F5F5F',
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  primaryButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.neutral[0],
    marginTop: 34,
    borderRadius: 8,
    height: 45,
    minWidth: 291,
  },
  secondaryButton: {
    color: theme.colors.primary,
    borderColor: theme.colors.primary,
    marginTop: 13,
    borderRadius: 8,
    height: 45,
    minWidth: 291,
  },
}));
