import { Badge, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { ReactNode } from 'react';
import { PiArrowDownBold, PiArrowUpBold } from 'react-icons/pi';

type Change = 'increase' | 'decrease';
type Tone = 'critical' | 'neutral' | 'sage';

type Props = {
  tone: Tone;
  change: Change;
  children?: ReactNode;
};

const directionIcon = (change: Change) => {
  if (change === 'increase') {
    return <PiArrowUpBold />;
  }
  return <PiArrowDownBold />;
};

export const TagDelta = ({ tone, change, children }: Props) => {
  const { classes } = useStyles(tone);

  return (
    <Badge
      pl={0}
      size="lg"
      radius="xl"
      leftSection={directionIcon(change)}
      className={classes.badge}
    >
      {children}
    </Badge>
  );
};
const useStyles = createStyles((theme, tone: Tone) => ({
  badge: {
    '&.mantine-Badge-root': {
      gap: 4,
      padding: `${rem(4)} ${rem(8)}`,
      alignItems: 'center',
      borderRadius: 999,
      backgroundColor: theme.colors[tone.toString()][1],
    },
    '.mantine-Badge-label': {
      color: theme.colors[tone.toString()][8],
    },
    '.mantine-Badge-section': {
      marginLeft: rem(4),
      justifyContent: 'center',
      marginRight: 0,
      display: 'flex',
      color: theme.colors[tone.toString()][6],
    },
  },
}));
