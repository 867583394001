import TabTableLayout from '@common/layouts/tab-table-layout';
import { FlexbaseTable } from '@common/table';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import {
  Avatar,
  Box,
  Flex,
  Tooltip,
  useMantineTheme,
  Text,
  TextInput,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { AccountDetailCell } from 'areas/payments/components/account-information/account-detail-cell';
import { FlexIconShort, SearchIcon } from 'assets/svg';
import { useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useOutletContext } from 'react-router-dom';
import { ParsedAccount } from 'types/parsed-account';
import { isTruthyString } from '@utilities/validators/validate-string';
import { BsInfoCircle } from 'react-icons/bs';
import { OverflowTooltip } from '@common/utilities/overflow-tooltip';
import {
  formatLabel,
  countrySelectOptions,
  supportedCurrenciesData,
} from '@utilities/international-payments';

type AccountInfoContext = {
  internationalAccounts: ParsedAccount[];
};

const RecipientInternationalAccounts = () => {
  const { internationalAccounts } = useOutletContext<AccountInfoContext>();
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (value: string) => {
    setSearchTerm(value.toLowerCase().trim());
  };

  const filteredAccounts: ParsedAccount[] = internationalAccounts.filter(
    (account) =>
      Object.values(account)
        .filter(isTruthyString)
        .some((v) => v.toLowerCase().includes(searchTerm)),
  );

  const countryOfBank = (country: string) =>
    countrySelectOptions.find((currency) => currency.value === country)?.label;

  const getCurrencySymbol = (currency: string) =>
    supportedCurrenciesData.find((cc) => cc.value === currency)?.symbol ?? '$';

  const getAccountName = (recipient: ParsedAccount) =>
    recipient.nickName ?? recipient.bank ?? recipient.name;

  const columns: TableColumn<ParsedAccount>[] = [
    {
      name: 'Account name',
      cell: (row) => (
        <Flex display="flex" align="center">
          <Avatar radius="xl" mr={15} w={45} h={45}>
            <FlexIconShort />
          </Avatar>
          {getAccountName(row)} ••{row.accountNumber?.slice(-4)}
        </Flex>
      ),
      sortable: true,
      selector: (row) => getAccountName(row),
      grow: 1.9,
    },
    {
      name: 'Currency',
      cell: (row) =>
        `${getCurrencySymbol(row?.currency ?? '')}${row?.currency}`,
      selector: (row) => row.currency ?? '',
      sortable: true,
      compact: true,
    },
    {
      name: (
        <Tooltip label="Country of recipient bank">
          <Flex align="center" gap="xxs">
            <Text>Country</Text>
            <BsInfoCircle />
          </Flex>
        </Tooltip>
      ),
      selector: (row) => row.bankCountry ?? '',
      format: (row) => countryOfBank(row.bankCountry ?? ''),
      sortable: true,
      compact: true,
    },
    {
      name: (
        <Tooltip label="Individual or company">
          <Flex align="center" gap="xxs">
            <Text>Recipient type</Text>
            <BsInfoCircle />
          </Flex>
        </Tooltip>
      ),
      selector: (row) => row?.recipientType ?? '',
      format: (row) => formatLabel(row?.recipientType ?? ''),
      sortable: true,
      compact: true,
    },
    {
      name: 'Account number',
      cell: (row) => <AccountDetailCell value={row.accountNumber} />,
      grow: useMobileView ? 1.3 : 1.1,
    },
    {
      name: 'BIC/SWIFT code',
      selector: (row) => row.bicSwiftCode ?? 'N/A',
      sortable: true,
      compact: true,
    },
    {
      name: 'IBAN code',
      selector: (row) => row.ibanCode ?? 'N/A',
      cell: (row) => <OverflowTooltip text={row.ibanCode ?? ''} />,
      sortable: true,
      compact: true,
    },
    {
      name: (
        <Tooltip
          multiline
          w={370}
          color="neutral.0"
          c="primary.7"
          sx={() => ({
            borderRadius: theme.defaultRadius,
            border: `1px solid ${theme.colors.neutral[4]}`,
          })}
          label="A routing code maps to any of the following codes: sort, bsb, institution no's, bank, branch, ABA, clabe, cnaps, or ifsc"
        >
          <Flex align="center" gap="0.2rem">
            Routing codes <BsInfoCircle />
          </Flex>
        </Tooltip>
      ),
      cell: (row) => (
        <Box>
          <Text>{row.routingCode1 ?? 'N/A'}</Text>
          {row.routingCode2 && <Text>{row.routingCode2}</Text>}
        </Box>
      ),
    },
  ];

  if (!internationalAccounts) {
    return <SkeletonLoading />;
  }

  return (
    <TabTableLayout
      leftHeaderContent={
        <TextInput
          w={useMobileView ? '100%' : '320px'}
          placeholder="Search accounts"
          onChange={(e) => handleSearchChange(e.target.value)}
          leftSection={<SearchIcon width={20} height={20} />}
        />
      }
      borderBottomSolid={false}
      rightHeaderContent={undefined}
      table={
        <FlexbaseTable
          columns={columns}
          data={filteredAccounts}
          noDataComponent={
            <Text mt="md">No international accounts have been added.</Text>
          }
        />
      }
    />
  );
};

export default RecipientInternationalAccounts;
