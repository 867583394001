import { Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { SummaryEditInput } from './summary-edit-input';
import { PersonalInfoFormValues } from '../summary';

type Props = {
  value: string;
  form: UseFormReturnType<PersonalInfoFormValues>;
  isEditMode: boolean;
};

const SummaryNameForm = ({ form, value, isEditMode }: Props) => {
  return (
    <SummaryEditInput
      isEditMode={isEditMode}
      editInputs={
        <Stack>
          <TextInput
            placeholder="First name"
            label="First name"
            {...form.getInputProps('firstName')}
            data-sardine-id="input-summary-first-name"
            id="summary-first-name"
          />
          <TextInput
            placeholder="Last name"
            label="Last name"
            {...form.getInputProps('lastName')}
            data-sardine-id="input-summary-last-name"
            id="summary-last-name"
          />
        </Stack>
      }
      label={'Full name'}
      value={value}
    />
  );
};

export default SummaryNameForm;
