import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: '80px',
    minHeight: '100vh',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(/images/page-top-frame.png)`,
    backgroundColor: theme.colors.primary[8],
    '@media (max-width: 768px)': {
      padding: '20px',
    },
  },
  formContainer: {
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[4]}`,
    borderRadius: theme.radius.sm,
  },
  error: {
    color: theme.colors.critical[2],
  },
  alert: {
    fontWeight: 400,
    fontSize: rem(14),
    lineHeight: rem(19),
    padding: theme.spacing.sm,
    color: theme.colors.neutral[10],
    borderRadius: theme.defaultRadius,
    background: theme.colors.critical[0],
    border: `1px solid ${theme.colors.critical[2]}`,
  },
  reSendCode: {
    fontSize: rem(14),
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    textAlign: 'left',
  },
}));
