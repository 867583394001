import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  '.h2': {
    color: theme.colors.neutral[10],
    fontSize: rem(28),
    lineHeight: rem(34),
  },

  pp: {
    fontFamily: 'PP Neue Montreal',
  },
  switch: {
    marginTop: rem(20),
    marginBottom: rem(20),
  },
  '.title': {
    color: theme.colors.neutral[10],
    fontSize: rem(28),
    lineHeight: rem(34),
  },
  mainContainer: {
    minHeight: '100vh',
    background: theme.colors.neutral[3],
    padding: `0px ${rem(32)} ${rem(32)} ${rem(32)}`,
    '@media(max-width: 767px)': {
      padding: 0,
    },
    '.tabs': {
      height: '100%',
      fontWeight: 500,
      '[aria-selected="false"]': {
        borderRadius: rem(100),
        '&:hover': {
          background: theme.colors.critical[0],
        },
      },
      '[aria-selected="true"]': {
        borderRadius: rem(100),
        backgroundColor: theme.colors.critical[0],
      },
      '[role=tabpanel]': {
        padding: `${rem(10)} 0px`,
      },
    },
    table: {
      thead: {
        borderTop: `${rem(1)} solid #E6E7E9`,
        'tr > th': {
          fontWeight: 400,
          fontSize: rem(12),
        },
      },
      tbody: {
        'tr:nth-of-type(even)': {
          backgroundColor: 'rgba(230, 231, 233, 0.12)',
        },
      },
    },
  },

  authorizationSection: {
    fontSize: rem(14),
    color: theme.colors.neutral[8],
  },

  container: {
    padding: `${rem(15)} ${rem(24)} ${rem(3)} ${rem(24)}`,
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[10]}`,
  },

  card: {
    padding: rem(24),
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
  },

  amountInput: {
    input: {
      fontSize: rem(48),
      color: theme.colors.neutral[10],
      border: 'unset',
      borderBottom: `1px solid ${theme.colors.neutral[4]}`,
      height: rem(50),
    },
  },

  amountInputValue: {
    color: theme.colors.neutral[10],
    border: `1px solid ${theme.colors.neutral[4]}`,
    paddingLeft: rem(30),
    paddingRight: rem(30),
    paddingBottom: rem(25),
    paddingTop: rem(25),
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[0],
    '@media(max-width: 767px)': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },
  },

  cardOverride: {
    borderRadius: theme.defaultRadius,
    width: '100%',
  },

  alert: {
    position: 'absolute',
    bottom: rem(20),
    right: rem(20),
  },

  closeModal: {
    display: 'grid',
    justifyContent: 'end',
    alignContent: 'baseline',
    p: {
      margin: '0px',
      color: '#979797',
      textAlign: 'center',
    },
  },

  styleTest: {
    '.content': {
      display: 'flex',
      justifyContent: 'center',
      padding: rem(20),
    },
  },

  modalSuccess: {
    height: '70vh',
    display: 'grid',
    marginTop: rem(30),
    alignContent: 'center',
    justifyItems: 'center',
    '@media(max-width: 767px)': {
      margin: rem(20),
    },
    p: {
      textAlign: 'center',
      marginBottom: rem(20),
    },
    '> span': {
      color: theme.colors.neutral[10],
      fontWeight: 400,
      fontSize: rem(34),
      marginTop: rem(40),
      lineHeight: rem(40),
      textAlign: 'center',
      fontFamily: 'Redaction',
      '@media(max-width: 767px)': {
        fontSize: rem(24),
      },
    },
    '.check_icon': {
      width: rem(72),
      height: rem(72),
      display: 'flex',
      alignItems: 'center',
      borderRadius: rem(50),
      justifyContent: 'center',
    },
  },
  inlineCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    flexShrink: 0,
  },
  menuItem: {
    width: rem(200),
  },
  menuEllipsisContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },

  // DEBIT CARDS
  debitCardsContent: {
    display: 'flex',
    marginTop: rem(15),
    flexDirection: 'column',
    '.badge': {
      height: rem(32),
      fontWeight: 500,
      fontSize: rem(14),
      padding: `${rem(4)} ${rem(15)}`,
      textTransform: 'capitalize',
      '@media(max-width: 767px)': {
        fontSize: rem(10),
        marginLeft: rem(10),
        padding: `0px ${rem(10)}`,
        height: 'fit-content',
      },
    },
    '.search-input-icon': {
      padding: rem(6),
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
    },
  },

  modalFormContent: {
    margin: '0px',
    padding: rem(40),
    minWidth: '100%',
    height: '100vh',
    borderRadius: theme.defaultRadius,
    position: 'fixed',
    overflowX: 'auto',
    backgroundColor: theme.colors.neutral[3],
    '.content': {
      display: 'flex',
      marginTop: rem(20),
      width: rem(420),
      height: 'fit-content',
      justifyContent: 'center',
      '.external_account': {
        width: rem(420),
        '@media(max-width: 767px)': {
          width: '100%',
          height: '50%',
        },
        '.title': {
          fontWeight: 400,
          fontSize: rem(28),
          fontFamily: 'PP Neue Montreal',
          color: theme.colors.neutral[10],
          '@media(max-width: 767px)': {
            fontSize: rem(16),
          },
        },
        '.subtitle': {
          fontWeight: 500,
          fontSize: rem(16),

          fontFamily: 'PP Neue Montreal',
          '@media(max-width: 767px)': {
            fontSize: rem(12),
          },
        },
      },
      '.move_funds': {
        marginTop: rem(24),
        width: rem(420),
        '> span': {
          color: theme.colors.neutral[10],
          fontSize: rem(28),
          lineHeight: rem(34),
        },
        '.label_wrapper': {
          label: {
            fontWeight: 500,
          },
          marginTop: rem(30),
        },
        '.banner_info': {
          display: 'flex',
          padding: rem(12),
          color: '#11152A',
          margin: `${rem(30)} 0px`,
          borderRadius: theme.defaultRadius,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#F8FAFC',
          svg: {
            marginRight: rem(12),
          },
        },
        '.authorization_section': {
          color: '#11152A',
          marginTop: rem(30),
        },
        '.transfer_amount': {
          fontSize: rem(48),
          color: '#11152A',
          marginTop: rem(45),
          textAlign: 'center',
          height: 'fit-content',
          borderBottom: `${rem(3)} solid ${theme.colors.primary[2]}`,
        },
        '@media(max-width: 767px)': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          '> span': {
            fontSize: rem(24),
          },
        },
      },
      '.error': {
        display: 'flex',
        padding: rem(16),
        fontSize: rem(14),
        color: '#991B1B',
        marginTop: rem(30),
        borderRadius: theme.defaultRadius,
        alignItems: 'center',
        backgroundColor: '#FEF2F2',
        svg: {
          marginRight: rem(10),
        },
      },
      '.buttons_container': {
        display: 'grid',
        marginTop: rem(30),
        button: {
          height: rem(48),
        },
        span: {
          fontWeight: 400,
        },
      },
      '.row-content': {
        display: 'flex',
        marginTop: rem(30),
        alignItems: 'center',
        justifyContent: 'space-between',
        p: {
          margin: '0px',
        },
      },
    },
    '.close': {
      display: 'grid',
      justifyContent: 'end',
      alignContent: 'baseline',
      p: {
        margin: '0px',
        color: '#979797',
        textAlign: 'center',
      },
    },
    '@media(max-width: 767px)': {
      padding: rem(20),
      '.close-button': {
        width: '100%',
        display: 'flex',
        marginBottom: rem(15),
        justifyContent: 'flex-end',
      },
      '.content': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
    },
  },

  accountSelectTarget: {
    width: rem(400),
    marginBottom: rem(20),
  },
  accountSelectList: {
    width: rem(400),
    position: 'absolute',
  },
  containerWidgets: {
    '@media(max-width: 767px)': {
      width: '100%',
      display: 'flex',
    },
  },
}));
