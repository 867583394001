import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: '1.5rem',
    color: theme.colors.neutral[8],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    backgroundColor: theme.colors.neutral[0],
    'table:not(.main_account) > tbody > tr:hover': {
      backgroundColor: '#FFEEEC',
    },
  },
  pdfTableHeader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontWeight: 500,
  },
  blurryTextStyle: {
    filter: 'blur(4px)',
  },
}));
