import { DateTime } from 'luxon';
import { ParsedAccount } from 'types/parsed-account';
import { PiBankBold, PiCalendarBlankBold, PiInfoBold } from 'react-icons/pi';
import {
  Avatar,
  Box,
  Flex,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import {
  ACH_TIMEFRAME_CONTEXT,
  WIRE_TIMEFRAME_CONTEXT,
} from 'constants/constants';

type ReviewBodyRecipientProps = {
  sendOn?: Date;
  recipientAccountType: string;
  recipientAccount?: ParsedAccount;
};

const getDateLabel = (date?: Date) => {
  if (!date) {
    return '';
  }
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED);
};

const ReviewBodyRecipient = ({
  sendOn,
  recipientAccount,
  recipientAccountType,
}: ReviewBodyRecipientProps) => {
  const theme = useMantineTheme();

  const recipientAccountName =
    recipientAccount?.nickName ?? recipientAccount?.name;

  const additionalETAInfo =
    recipientAccount?.type === 'wire'
      ? WIRE_TIMEFRAME_CONTEXT
      : ACH_TIMEFRAME_CONTEXT;

  return (
    <>
      <Text mb="md" fw={500} size="sm">
        Recipient receives
      </Text>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="xs">
          <Avatar size={24} bd="unset">
            <PiBankBold color={theme.colors.neutral[6]} />
          </Avatar>
          <Text c="neutral.8" size="sm">
            Account
          </Text>
        </Flex>
        <Box>
          <Text ta="right" size="sm">
            {recipientAccountName}
          </Text>
          <Text c="neutral.8" ta="right" size="sm">
            {recipientAccountType} {' • '}
            {recipientAccount?.accountNumber?.slice(-4)}
          </Text>
        </Box>
      </Flex>
      <Flex mt="md" justify="space-between" align="center">
        <Flex align="center" gap="xs">
          <Avatar size={24} bd="unset">
            <PiCalendarBlankBold color={theme.colors.neutral[6]} />
          </Avatar>
          <Text c="neutral.8" size="sm">
            Sending on
          </Text>
          <Tooltip label={`ETA 1 business day. ${additionalETAInfo}`} withArrow>
            <Box mt="xxs">
              <PiInfoBold size={15} color={theme.colors.neutral[6]} />
            </Box>
          </Tooltip>
        </Flex>
        <Text ta="right" size="sm">
          {getDateLabel(sendOn)}
        </Text>
      </Flex>
    </>
  );
};

export default ReviewBodyRecipient;
