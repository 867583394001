import { Flex, Paper, Stack, Text, Title } from '@mantine/core';
import { PropsWithChildren } from 'react';

type Props = {
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
} & PropsWithChildren;

const WizardFullscreenMessageCard = ({
  icon,
  title,
  subtitle,
  children,
}: Props) => {
  return (
    <Paper radius="xs" p="xl" withBorder maw={700}>
      <Stack align="center">
        {icon}
        <Title
          ta="center"
          order={2}
          data-testid="wizard-fullscreen-message-card-title"
        >
          {title}
        </Title>
        {subtitle && (
          <Text size={'sm'} ta="justify">
            {subtitle}
          </Text>
        )}
        <Flex gap="sm" mt={'sm'}>
          {children}
        </Flex>
      </Stack>
    </Paper>
  );
};

export default WizardFullscreenMessageCard;
