import { createContext, PropsWithChildren, useContext } from 'react';
import {
  IntegrationLink,
  IntegrationPlatform,
} from 'constants/accounting-integrations';
import { noop } from 'underscore';
import { IntegrationCardItem } from '@utilities/integrations/list-integrations';
import {
  AccountingSettings,
  ExpenseLink,
} from '@flexbase-eng/types/dist/accounting';
import { UseMutateFunction } from '@tanstack/react-query';

export type IntegrationsContextType = {
  accountingLinks: ExpenseLink[];
  intuitFeedsLink: ExpenseLink | undefined;

  connectIntegration: (platform: IntegrationPlatform) => void;
  isConnecting: boolean;
  connectDisabled: boolean;

  createFeedConnection: UseMutateFunction<unknown, Error, void>;
  isCreatingFeed: boolean;

  disconnectIntegration: (
    integration: IntegrationCardItem,
    link: IntegrationLink,
    onConfirmed?: () => void,
  ) => void;
  isDisconnecting: boolean;
  disconnectDisabled: boolean;

  saveAccountingSettings: UseMutateFunction<
    unknown,
    Error,
    { connectionId: string; request: AccountingSettings },
    unknown
  >;
  isSavingSettings: boolean;
  saveSettingsDisabled: boolean;
};

export const IntegrationsContext = createContext<IntegrationsContextType>({
  accountingLinks: [],
  intuitFeedsLink: undefined,

  connectIntegration: noop,
  isConnecting: false,
  connectDisabled: false,

  createFeedConnection: noop,
  isCreatingFeed: false,

  disconnectIntegration: noop,
  isDisconnecting: false,
  disconnectDisabled: false,

  saveAccountingSettings: noop,
  isSavingSettings: false,
  saveSettingsDisabled: false,
});

type IntegrationsContextProviderProps = PropsWithChildren<{
  value: IntegrationsContextType;
}>;

export const IntegrationsContextProvider = ({
  children,
  value,
}: IntegrationsContextProviderProps) => {
  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export const useIntegrationsContext = () => {
  return useContext(IntegrationsContext);
};
