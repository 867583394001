import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MantineTheme, useMantineTheme } from '@mantine/core';

type ThemeToStringFn = (theme: MantineTheme) => string;
type SetBackgroundArgs = string | ThemeToStringFn;

type BackgroundContextReturnType = {
  background: string;
  setBackground: (args: SetBackgroundArgs) => void;
};

const DEFAULT_BG = 'neutral.3';

const BackgroundContext = createContext<BackgroundContextReturnType>({
  background: DEFAULT_BG,
} as BackgroundContextReturnType);

type BackgroundProviderProps = { children: ReactNode };

export const BackgroundProvider = ({ children }: BackgroundProviderProps) => {
  const [background, setBackground] = useState(DEFAULT_BG);
  const theme = useMantineTheme();

  const setPageBackground = (args1: SetBackgroundArgs) => {
    if (typeof args1 === 'string') {
      setBackground(args1);
    } else {
      setBackground(args1(theme));
    }
  };

  return (
    <BackgroundContext.Provider
      value={{ background, setBackground: setPageBackground }}
    >
      {children}
    </BackgroundContext.Provider>
  );
};

/**
 * This context allows you to change the background of the entire viewport in the app shell.
 * You can use any valid CSS background string, or any Mantine shorthand color string like "neutral.3"
 * @returns {BackgroundContextReturnType}
 */
export const useBackgroundContext = () => {
  return useContext(BackgroundContext);
};

/**
 * This hook allows you to change the background of the entire viewport in the app shell.
 *
 * @param bg Any valid CSS background string, or any Mantine shorthand color string like "neutral.3",
 * or pass in a callback that will be passed a theme argument.
 *
 * @example
 * usePageBackground('neutral.3');
 * usePageBackground((theme) => theme.primaryColor);
 */
export const usePageBackground = (bg: SetBackgroundArgs) => {
  const { setBackground } = useBackgroundContext();

  useEffect(() => {
    setBackground(bg);

    return () => {
      setBackground(DEFAULT_BG);
    };
  }, []);
};
