import { Avatar, Text, Flex, Box, useMantineTheme } from '@mantine/core';
import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PiScan } from 'react-icons/pi';

const ScanningLoading = () => {
  const { classes } = useWizardStyles();
  const theme = useMantineTheme();
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      h="94%"
      m={rem(16)}
      className={classes.flexContainer}
    >
      <Box className={classes.horizontalLine} />
      <Avatar
        size="lg"
        color="green"
        style={{ border: `1px solid ${theme.colors.primary[4]}` }}
      >
        <PiScan size={32} color={theme.colors.primary[4]} />
      </Avatar>
      <Text fz={rem(14)} mt={'sm'}>
        Scanning bill details...
      </Text>
    </Flex>
  );
};

const useWizardStyles = createStyles((theme) => ({
  flexContainer: {
    border: `1px solid ${theme.colors.primary[4]}`,
    borderRadius: rem(16),
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    position: 'relative',
    zIndex: 2,
    overflow: 'hidden',
  },
  horizontalLine: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '2px',
    backgroundColor: theme.colors.primary[4],
    animation: 'scan 2s ease-in-out infinite',
    '@keyframes scan': {
      '0%': {
        top: '0%',
      },
      '50%': {
        top: '100%',
      },
      '100%': {
        top: '0%',
      },
    },
  },
}));
export default ScanningLoading;
