import { Progress, rem, Stack } from '@mantine/core';
import OnboardingHeader from '@common/onboarding-header';
import UnbundledStepCard from '../components/unbundled-step-card';
import {
  PiBank,
  PiHandshake,
  PiKey,
  PiSealCheck,
  PiTable,
  PiUserCircleCheck,
  PiUsersThree,
} from 'react-icons/pi';
import { CreditBankingFooter } from '@common/credit-banking.footer';
import { createStyles } from '@mantine/emotion';

// TODO: POD3-776 - Hook this page up to actual application data
const ApplicationUnbundledLandingPage = () => {
  const { classes, theme } = useStyles();
  return (
    <>
      <Stack mih="100vh" bg={theme.colors.neutral[3]}>
        <OnboardingHeader
          titleText="Get started with Flex"
          subtitleText="To qualify, you'll first complete a quick eligibility check. Once qualified, you'll move on to the full application."
        />
        <Stack
          my="xl"
          flex={{ base: 1, sm: 'unset' }}
          w={{ base: '100%', sm: rem(525) }}
          mx="auto"
          px={{ base: 'lg', sm: 0 }}
        >
          <Progress
            value={50}
            color={theme.colors.primary[5]}
            bg={theme.colors.neutral[0]}
            radius={1000}
          />
          <UnbundledStepCard
            title="Get qualified for Flex"
            isComplete
            icon={<PiSealCheck size={24} />}
            href="/"
          />
          <UnbundledStepCard
            title="Verify your identity"
            isComplete
            icon={<PiUserCircleCheck size={24} />}
            href="/"
          />
          <UnbundledStepCard
            title="Add your business information"
            icon={<PiUsersThree size={24} />}
            href="/"
          />
          <UnbundledStepCard
            title="Add business information and control people"
            isComplete
            icon={<PiKey size={24} />}
            href="/"
          />
          <UnbundledStepCard
            title="Connect banking"
            isComplete
            icon={<PiBank size={24} />}
            href="/"
          />
          <UnbundledStepCard
            title="Connect accounting"
            isComplete
            isSkipped
            skippedTooltip="Optional tax documentation skipped. Adding accounting tools or tax returns later may help us process your application more efficiently."
            icon={<PiTable size={24} />}
            href="/"
          />
          <UnbundledStepCard
            title="Accept our terms and conditions"
            icon={<PiHandshake size={24} />}
            href="/"
          />
        </Stack>
      </Stack>
      <CreditBankingFooter
        bg={theme.colors.neutral[3]}
        className={classes.footer}
        p={{ base: 'lg', sm: '2rem' }}
      />
    </>
  );
};

const useStyles = createStyles((theme) => ({
  footer: {
    p: {
      color: theme.colors.neutral[7],
      lineHeight: 'normal',
      letterSpacing: '0.05px',
    },
    a: {
      color: `${theme.colors.primary[6]} !important`,
      lineHeight: 'normal',
      letterSpacing: '0.05px',
    },
  },
}));

export default ApplicationUnbundledLandingPage;
