import { DateTime } from 'luxon';
import { Avatar, Box, Divider, Flex, Text } from '@mantine/core';
import EmptyState from './empty-state';
import { PiArrowDownBold, PiArrowUpBold } from 'react-icons/pi';
import {
  MoneyMovementItemsProps,
  moneyMovementSummaryStyles,
} from 'areas/banking/account-details/money-movement-summary';
import { formatInitials } from '@utilities/formatters/format-strings';
import { formatCurrency } from '@utilities/formatters';
import { extractToFrom } from '@utilities/formatters/banking-transactions-utilities';

const TransactionsList = ({
  transactions,
  direction,
}: MoneyMovementItemsProps) => {
  if (!transactions.length) {
    return <EmptyState {...{ direction }} />;
  }

  return (
    <>
      {transactions.map((transaction) => {
        const formattedDate = transaction.createdAt
          ? DateTime.fromSQL(transaction.createdAt).toFormat('LLL dd, yyyy')
          : '';

        return (
          <Box key={transaction.id} c="neutral.8">
            <Flex justify="space-between" align="center">
              <Flex align="center" gap="xs">
                <Avatar size="sm">
                  {formatInitials(extractToFrom(transaction.summary))}
                </Avatar>
                <Box>
                  <Text size="sm">{transaction.summary}</Text>
                  <Text size="sm">{formattedDate}</Text>
                </Box>
              </Flex>
              <Text ta="right" size="sm">
                {direction === 'Debit' && '-'}
                {formatCurrency(Number(transaction.amount) / 100)}
              </Text>
            </Flex>
            <Divider my="sm" />
          </Box>
        );
      })}
    </>
  );
};

const MoneyDirection = ({
  transactions,
  direction,
}: MoneyMovementItemsProps) => {
  const { classes, theme } = moneyMovementSummaryStyles();

  const isDebitDirection = direction === 'Debit';
  const textTitle = isDebitDirection ? 'Money out' : 'Money in';
  const bgColor = isDebitDirection ? 'critical.0' : 'sage.1';
  const fontColor = isDebitDirection ? 'critical.4' : 'primary.2';
  const borderColor = isDebitDirection
    ? theme.colors.critical[4]
    : theme.colors.primary[2];

  return (
    <Box>
      <Flex align="center" gap="xs" my="md">
        <Box
          bg={bgColor}
          c={fontColor}
          sx={{ border: `1px solid ${borderColor}` }}
          className={classes.iconContainer}
        >
          {isDebitDirection ? (
            <PiArrowDownBold size={12} />
          ) : (
            <PiArrowUpBold size={12} />
          )}
        </Box>
        <Text>{textTitle}</Text>
      </Flex>
      <TransactionsList transactions={transactions} direction={direction} />
    </Box>
  );
};

export default MoneyDirection;
