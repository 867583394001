import {
  Box,
  Collapse,
  ComboboxItem,
  Group,
  Radio,
  Stack,
  Title,
  useMantineTheme,
  Select,
  TextInput,
  NumberInput,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useMemo } from 'react';
import { Group as CardInfoGroup } from 'constants/card-info';
import { CustomMantineStyles } from '@common/cards-styles';
import { CreditCardInterval, LIMIT_INTERVALS } from 'constants/limit-intervals';
import { CalendarIcon } from '../assets/svg';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { DateTime } from 'luxon';
import CardCategorySelect from '@common/composites/card-category-select';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import Multiselect from '@common/custom-multiselect';
import { useGetUsers } from '@queries/use-users';
import { NumberFormatValues } from 'react-number-format';
import {
  CardType,
  ShippingMethod,
} from '@flexbase-eng/sdk-typescript/models/components';
import { useCardTypes } from '@queries/use-credit-programs';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import FlexSelect from '@common/composites/flex-select';
import { US_STATES } from 'constants/constants';
import { Employees } from 'types/onboarding-info';
import GooglePlacesSuggest from '@common/composites/input/google-places-suggest-input';

type YesNo = 'yes' | 'no';

export type IssueMarqetaCreditCardFormType = {
  userId: string;
  cardTypeId: string;
  cardName: string;
  spendingLimit: number;
  spendingLimitInterval: CreditCardInterval | undefined;
  groups: CardInfoGroup[];
  categoryId?: string;
  terminateAt: string | null;
  limitMerchantCategories: YesNo;
  autoExpire: YesNo;
  street1: string;
  street2: string;
  locality: string;
  region: string;
  postalCode: string;
  country: string;
  shippingMethod: ShippingMethod;
};

type IssueMarqetaCreditCardFormProps = {
  form: UseFormReturnType<IssueMarqetaCreditCardFormType>;
  title?: React.ReactNode;
  disabled?: boolean;
  preissue?: boolean;
};

const STACK_SPACING = 'lg';
const RADIO_SPACING = 'lg';

const US_STATES_ABBR = US_STATES.map<ComboboxItem>(({ value }) => ({
  label: value,
  value,
}));

export const useIssueMarqetaCreditCardForm =
  useForm<IssueMarqetaCreditCardFormType>;

const useTeamMemberOptions = (users: Employees[] | undefined) => {
  return useMemo<ComboboxItem[]>(() => {
    // check if still fetching data
    if (!users) {
      return [];
    }

    // only consider active onboarded users
    const activeUsers = users.filter(
      (u) =>
        u.completedOnboarding &&
        !u.completedOffboarding &&
        u.status === 'active',
    );

    // map to select options
    const availableUsers: ComboboxItem[] = activeUsers.map((u) => ({
      value: u.id!,
      label: `${u.firstName} ${u.lastName}`,
    }));

    return availableUsers;
  }, [users]);
};

const useCardTypeOptions = (cardTypes: CardType[] | undefined) => {
  return useMemo(() => {
    if (!cardTypes) {
      return [];
    }

    return cardTypes.map<ComboboxItem>((ct) => {
      return {
        label: ct.name,
        value: ct.id,
      };
    });
  }, [cardTypes]);
};

const useMinimumSuspendDate = () => {
  return DateTime.now().plus({ days: 1 }).toJSDate();
};

export const IssueMarqetaCreditCardForm = ({
  form,
  title,
  disabled,
  preissue = false,
}: IssueMarqetaCreditCardFormProps) => {
  const theme = useMantineTheme();
  const { personId } = useRecoilValue(ApplicationState);
  const { expenseLink } = useActiveExpenseLink();
  const { data: users } = useGetUsers();
  const { data: cardTypes } = useCardTypes(personId);

  const teamMemberOptions = useTeamMemberOptions(users);
  const cardTypeOptions = useCardTypeOptions(cardTypes);
  const minimumTerminationDate = useMinimumSuspendDate();

  const isPhysicalTypeSelected: boolean =
    cardTypes?.find((ct) => ct.id === form.values.cardTypeId)?.corporeality ===
    'physical';

  const handleStreet1Change = (value: string) => {
    form.setFieldValue('street1', value);
  };

  const handleAutocompleteSubmit = (item: {
    address: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  }) => {
    form.setValues({
      street1: item.address,
      locality: item.city,
      region: item.state,
      postalCode: item.postalCode,
      country: item.country,
    });
  };

  const handleFrequencyChange = (e: string | null) => {
    form.setFieldValue('spendingLimit', 0);

    if (e) {
      form.setFieldValue('spendingLimitInterval', e as CreditCardInterval);
    }
  };

  const handleLimitAmountChange = (value: NumberFormatValues) => {
    form.setFieldValue('spendingLimit', value.floatValue ?? 0);
  };

  const handleTerminationDateChange = (d: DateValue) => {
    if (d) {
      const date = DateTime.fromJSDate(d);
      form.setFieldValue('terminateAt', date.toFormat('yyyy-MM-dd'));
    } else {
      form.setFieldValue('terminateAt', null);
    }
  };

  return (
    <Stack gap={STACK_SPACING}>
      {!!title && (
        <Title
          style={{
            textAlign: 'start',
            fontSize: '28px',
            fontFamily: 'PP Neue Montreal',
            fontWeight: 400,
          }}
        >
          {title}
        </Title>
      )}

      {!preissue && (
        <Select
          label="Team member"
          searchable
          data={teamMemberOptions}
          radius={8}
          maxDropdownHeight={280}
          data-testid="select-employee"
          placeholder="Select employee"
          disabled={disabled}
          {...form.getInputProps('userId')}
        />
      )}

      <TextInput
        label="Card purpose"
        data-testid="card-name"
        placeholder="e.g. Advertising expenses"
        disabled={disabled}
        {...form.getInputProps('cardName')}
      />

      <FlexSelect
        label="Card type"
        data={cardTypeOptions}
        nothingFound={() => 'No available card types.'}
        disabled={disabled}
        {...form.getInputProps('cardTypeId')}
      />

      {isPhysicalTypeSelected && (
        <Stack gap={STACK_SPACING}>
          <GooglePlacesSuggest
            id="input-address-street"
            label="Address line 1"
            placeholder="Address line 1"
            value={form.values.street1}
            disabled={disabled}
            onChange={handleStreet1Change}
            onOptionSubmit={handleAutocompleteSubmit}
          />

          <TextInput
            label="Address line 2"
            placeholder="Address line 2"
            disabled={disabled}
            {...form.getInputProps('street2')}
          />

          <Group gap={STACK_SPACING}>
            <TextInput
              label="City"
              placeholder="City"
              required
              disabled={disabled}
              sx={{
                flexBasis: '30%',
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 130,
              }}
              {...form.getInputProps('locality')}
            />

            <Group
              sx={{
                flexBasis: '40%',
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 200,
              }}
            >
              <Select
                label="State"
                data={US_STATES_ABBR}
                searchable
                required
                disabled={disabled}
                sx={{
                  flexBasis: '40%',
                  flexGrow: 0,
                  flexShrink: 1,
                }}
                {...form.getInputProps('region')}
              />
              <TextInput
                label="ZIP code"
                placeholder="ZIP"
                required
                disabled={disabled}
                sx={{
                  flexBasis: '50%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                {...form.getInputProps('postalCode')}
              />
            </Group>
          </Group>
        </Stack>
      )}

      <Select
        label="Limit frequency"
        placeholder="Select frequency"
        data={LIMIT_INTERVALS}
        radius={8}
        maxDropdownHeight={280}
        data-testid="select-limit-type"
        disabled={disabled}
        {...form.getInputProps('spendingLimitInterval')}
        onChange={handleFrequencyChange}
      />

      {form.values.spendingLimitInterval !== 'unlimited' && (
        <NumberInput
          hideControls
          label="Limit"
          variant="default"
          radius={8}
          leftSection="$"
          pattern="[0-9]*"
          thousandSeparator
          data-testid="amount"
          onValueChange={handleLimitAmountChange}
          disabled={disabled}
          value={form.values.spendingLimit}
          error={form.errors.spendingLimit}
        />
      )}

      {expenseLink?.enabledExpenses && (
        <CardCategorySelect {...form.getInputProps('categoryId')} />
      )}

      <Box>
        {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
        <Radio.Group
          label="Restrict spending to specific categories?"
          size="sm"
          color={theme.primaryColor}
          {...form.getInputProps('limitMerchantCategories')}
        >
          <Group gap={RADIO_SPACING} mt="xxs">
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="categoriesYes"
              value="yes"
              label="Yes"
            />
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="categoriesNo"
              value="no"
              label="No"
            />
          </Group>
        </Radio.Group>

        <Box>
          {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
          <Collapse in={form.values.limitMerchantCategories === 'yes'}>
            <Box mt={STACK_SPACING}>
              <Multiselect form={form} disabled={disabled} />
            </Box>
          </Collapse>
        </Box>
      </Box>

      <Box>
        {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
        <Radio.Group
          label="Set termination date?"
          size="sm"
          color={theme.primaryColor}
          {...form.getInputProps('autoExpire')}
        >
          <Group gap={RADIO_SPACING} mt="xxs">
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="autoExpireYes"
              value="yes"
              label="Yes"
            />
            <Radio
              disabled={disabled}
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="autoExpireNo"
              value="no"
              label="No"
            />
          </Group>
        </Radio.Group>

        <Box>
          {' '}
          {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
          <Collapse in={form.values.autoExpire === 'yes'}>
            <DatePickerInput
              leftSection={
                <CalendarIcon
                  color={theme.colors.neutral[8]}
                  height="20px"
                  width="20px"
                />
              }
              mt={STACK_SPACING}
              clearable
              valueFormat="YYYY-MM-DD"
              label="Termination date"
              minDate={minimumTerminationDate}
              disabled={disabled}
              onChange={handleTerminationDateChange}
            />
          </Collapse>
        </Box>
      </Box>
    </Stack>
  );
};
