import { currencyToCents } from '@utilities/formatters';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';

export const getMappedPaymentAccounts = (
  paymentAccounts: (PlaidAccount | DepositAccount)[],
) => {
  return paymentAccounts.map((acc) => {
    const isDepositAccount = acc.plaidOrDeposit === 'deposit';
    const accountName = isDepositAccount
      ? acc.nickName ?? acc.name
      : acc.accountName ?? acc.bankName;
    const accountNumber = isDepositAccount
      ? acc.accountNumber.slice(0, 4)
      : acc.last4 ?? '';
    // sometimes the balance from external accounts doesn't populate.
    // it might be better not to show anything instead of `0` to avoid user confusion
    const plaidAccountAvailable =
      !isDepositAccount && acc.available
        ? currencyToCents(Number(acc.available))
        : undefined;
    const accountAvailable = isDepositAccount
      ? acc.available
      : plaidAccountAvailable;

    return {
      id: acc.id,
      accountName,
      accountNumber,
      accountAvailable,
      accountType: acc.accountType,
    };
  });
};
