import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  creditContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    gap: rem(24),
    marginBottom: rem(24),
    '@media(max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },
  monaySpentContainer: {
    position: 'relative',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    minWidth: rem(420),
    height: rem(200),
    overflow: 'hidden',
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
  },
  widgetContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, minmax(420px, 1fr))',
    '@media(max-width: 767px)': {
      gridTemplateColumns: '1fr',
    },
    gap: rem(16),
    overflow: 'auto',
  },
  widget: {
    '@media(min-width: 767px)': {
      flex: 1,
    },
  },
  inner: {
    alignItems: 'center',
  },
  text: {
    fontFamily: 'PP Neue Montreal',
    fontSize: rem(16),
    lineHeight: rem(24),
    color: theme.colors.neutral[8],
  },
  controlPersonBtnModal: {
    width: '100%',
    margin: `${rem(48)} 0 0 0`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
