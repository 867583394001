import { useRecoilValue } from 'recoil';
import { LoadingOverlay } from '@mantine/core';
import { Navigate, Outlet } from 'react-router-dom';
import { NavTab } from '@common/composites/tabs/nav-tabs';
import FlexbaseMainLayout from 'components/layouts/main';
import { useUnitCreditLines } from '@queries/use-credit-lines';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import {
  IsAdmin,
  IsAccountant,
  ApplicationState,
} from 'recoil-state/application/product-onboarding';
import FlexDefaultHeader from '@common/composites/header/flex-default-header';

const adminTabs: NavTab[] = [
  { label: 'Overview', route: '/charge-card/overview' },
  { label: 'Charge cards', route: '/charge-card/details' },
  { label: 'Charge payment', route: '/charge-card/pay' },
  { label: 'Statements', route: '/charge-card/statements' },
];

const employeeTabs: NavTab[] = [
  { label: 'Overview', route: '/charge-card/overview' },
  { label: 'Charge cards', route: '/charge-card/details' },
];

const ChargeCardAccount = () => {
  const { businessId } = useRecoilValue(ApplicationState);
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);

  const { data: unitLines = [], isLoading } = useUnitCreditLines(businessId);
  const hasActiveChargeLines = unitLines.filter(
    (loc) => loc.status !== 'Closed',
  );

  const tabs = isAdmin || isAccountant ? adminTabs : employeeTabs;

  if (isLoading) {
    return (
      <FlexbaseMainLayout>
        <LoadingOverlay visible />
      </FlexbaseMainLayout>
    );
  }

  if (!hasActiveChargeLines.length) {
    return <Navigate to="/" />;
  }

  return (
    <FlexbaseMainLayout>
      <FlexDefaultHeader title="Charge card" />
      <NavTabContainer tabs={tabs}>
        <Outlet />
      </NavTabContainer>
    </FlexbaseMainLayout>
  );
};

export default ChargeCardAccount;
