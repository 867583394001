import {
  Radio,
  Stack,
  Switch,
  TextInput,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import FlexSelect from '@common/composites/flex-select';
import { RecurringSelectItems } from '../../../utils/frequency-select-items';
import { DatePickerInput } from '@mantine/dates';
import { PiCalendarLight } from 'react-icons/pi';
import { DateTime } from 'luxon';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';
import { Indent } from '../../components/indent';
import {
  CreateSpendPlanWizard,
  useCreateSpendPlanWizard,
} from '../../create-spend-plan.wizard';
import { SpendPlanReviewStep } from '../review/review-step';
import { SpendPlanAdminView } from '@flexbase-eng/types/dist/accounting';

const STEP_ID = 'details-step';

const DATE_TOMORROW = DateTime.now().plus({ day: 1 }).toJSDate();

type SpendPlanDetailsStepProps = {
  spendPlan?: SpendPlanAdminView;
};

export const SpendPlanDetailsStep = ({
  spendPlan,
}: SpendPlanDetailsStepProps) => {
  const theme = useMantineTheme();
  const { detailsForm: form } = useCreateSpendPlanContext();
  const {
    goToNextStep,
    goToStep,
    getLastVisitedStep,
    state,
    navigatedFromPreview,
  } = useCreateSpendPlanWizard();

  const isUpdate = !!spendPlan;

  const isFromReviewPreview =
    navigatedFromPreview &&
    getLastVisitedStep()?.id === SpendPlanReviewStep.stepId;

  const handleNext = form.onSubmit((formValues) => {
    if (isFromReviewPreview) {
      return goToStep(SpendPlanReviewStep.stepId, formValues);
    }

    goToNextStep(formValues);
  });

  return (
    <CreateSpendPlanWizard.Step
      onNext={handleNext}
      hideBack={isFromReviewPreview}
    >
      <Stack gap={24}>
        <TextInput
          label="Name"
          placeholder="Name"
          description="This name will be displayed to all team members in this spend plan. Edit this at any time."
          withAsterisk
          required
          autoFocus
          maxLength={50}
          {...form.getInputProps('name')}
        />

        <Textarea
          styles={{
            input: {
              height: 88,
            },
          }}
          label="Description"
          placeholder="Description"
          description="Describe the purpose of your spend plan so members know how and when to use it."
          {...form.getInputProps('description')}
          maxLength={300}
        />

        <Radio.Group
          label="Select spend plan frequency"
          {...form.getInputProps('recurring')}
        >
          <Stack gap={12}>
            <Radio label="Recurring" value="recurring" disabled={isUpdate} />

            <Indent visible={state.recurring === 'recurring'}>
              <FlexSelect
                data={RecurringSelectItems}
                {...form.getInputProps('frequency')}
                inputProps={{ disabled: isUpdate }}
              />
            </Indent>

            <Radio label="One time" value="onetime" disabled={isUpdate} />
          </Stack>
        </Radio.Group>

        <Stack gap={16}>
          <Switch
            label="Do you want this plan to expire?"
            labelPosition="left"
            description="Funds will be removed from the spend plan on the defined expiration date."
            {...form.getInputProps('expires', { type: 'checkbox' })}
          />

          <Indent visible={state.expires}>
            <DatePickerInput
              label="Expiration date"
              error={form.errors.expirationDate}
              leftSection={
                <PiCalendarLight
                  height={20}
                  width={20}
                  color={theme.colors.neutral[8]}
                />
              }
              minDate={DATE_TOMORROW}
              {...form.getInputProps('expirationDate')}
            />
          </Indent>
        </Stack>
      </Stack>
    </CreateSpendPlanWizard.Step>
  );
};

SpendPlanDetailsStep.stepId = STEP_ID;
