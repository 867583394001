import { MantineTheme, Text, UnstyledButton } from '@mantine/core';
import { useStyles } from '../styles';
import { useGetBankingTransactions } from '@queries/use-banking-transactions';
import FlexbaseTable from 'components/table/flexbase-table';
import type { Transaction } from 'services/flexbase/banking.model';
import type { DepositAccount } from '../../../types/move-funds.model';
import { downloadStatementCSV } from './utils';
import { StatementInfo } from '@services/flexbase/statements.model';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { DateTime } from 'luxon';
import { TableColumn } from 'react-data-table-component';
import { PiDownloadSimple } from 'react-icons/pi';
import { getStatement } from '@utilities/statements';

const getColumns = (
  theme: MantineTheme,
  csvData: Transaction[],
  acct: DepositAccount,
  accountId: string,
): TableColumn<StatementInfo>[] => [
  {
    name: 'Date',
    selector: (statement) => statement.period,
    format: (statement) =>
      DateTime.fromFormat(statement.period, 'yyyy-LL').monthLong,
    sortable: true,
    grow: 6,
  },
  {
    name: 'PDF',
    cell: (statement) => {
      const accountName = acct?.nickName ?? acct?.name;
      const statementName = `${accountName} Flex banking ${acct?.accountNumber.slice(
        -4,
      )} statement ${statement.period}`;

      return (
        <UnstyledButton
          onClick={() =>
            getStatement(statement.vendorStatementId, statementName, accountId)
          }
        >
          <PiDownloadSimple size={'1.25rem'} color={theme.colors.primary[2]} />
        </UnstyledButton>
      );
    },
    compact: true,
    center: true,
  },
  {
    name: 'CSV',
    cell: (statement) => {
      return (
        <PiDownloadSimple
          size={'1.25rem'}
          color={theme.colors.primary[2]}
          onClick={() => downloadStatementCSV(statement, csvData)}
        />
      );
    },
    compact: true,
    center: true,
  },
];

type Props = {
  statements: StatementInfo[];
  selectedAccount: DepositAccount;
};

const StatementsTable = ({ statements, selectedAccount }: Props) => {
  const { theme } = useStyles();
  const { data: transactionsData, isLoading } = useGetBankingTransactions({
    depositAccountId: selectedAccount.id,
  });
  const { accountId } = useRecoilValue(ApplicationState);

  const csvData = transactionsData?.transactions ?? [];

  const columns = getColumns(theme, csvData, selectedAccount, accountId);

  return (
    <FlexbaseTable
      data={statements}
      columns={columns}
      noDataComponent={
        <Text mt="md" size="lg" fw={500}>
          There are no statements to display
        </Text>
      }
      pagination={statements.length > 8}
      isFetchingData={isLoading}
    />
  );
};

export default StatementsTable;
