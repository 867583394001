import { Badge, Divider, Flex, Group, rem, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DateTime } from 'luxon';
import AttachtmentsSection from './attachments-section';
import { useStyles } from './styles';
import ManagePayment, { getIsCancelable } from '@common/manage-payment';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { isFuturePayment } from '@utilities/dates';
import PaymentTimeline from './payment-timeline';
import { AvailableCurrencies } from '../send-payment/international-payments/util/types';
import {
  IntlPaymentStatus,
  MoneyMovementStatus,
} from '@services/flexbase/banking.model';
import { PiDownloadSimpleBold, PiXLight } from 'react-icons/pi';
import {
  getPaymentRejectedMessage,
  RejectedReason,
} from '@utilities/formatters/payment-rejected-message';

export type Details = {
  createdAt: string;
  amount: any;
  type: string;
  status: string;
  exactStatus: MoneyMovementStatus;
  direction: string;
  description: string;
  recipient: string;
  id: string;
  notes: string;
  userId: string;
  approvedBy?: string;
  requestedBy?: string;
  accountName: string;
  scheduledFor?: string;
  internationalPaymentId?: string;
  currency?: AvailableCurrencies;
  fxAmount?: string | null;
  rate?: string;
  intlPaymentStatus?: IntlPaymentStatus;
  estimatedAmount?: string | number;
  reason?: string;
};

type Props = {
  row: Details;
  onClose: () => void;
};

const confirmationCopy = `By clicking 'Approve', I authorize Flex to initiate the transaction detailed above. Flex is powered by Flexbase Technologies, Inc`;

const PaymentDetails = ({ row, onClose }: Props) => {
  const { classes, cx } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 755px)');
  const isAdmin = useRecoilValue(IsAdmin);
  const createdAt = DateTime.fromSQL(row.createdAt);
  const formattedDate = `${createdAt.toFormat('DDD')} at ${createdAt.toFormat(
    'ttt',
  )}`;
  const deliveryFormattedDate = row.scheduledFor
    ? DateTime.fromFormat(row.scheduledFor, 'yyyy-MM-dd').toFormat('DD')
    : null;
  const futurePayment = isFuturePayment(row.scheduledFor ?? '');

  const needsAction = getIsCancelable(row.exactStatus, row.type);
  const showActionSection = isAdmin && needsAction;
  const showConfirmationDisclaimer =
    row.exactStatus === 'AwaitingApproval' ||
    row.exactStatus === 'AwaitingConfirmation';
  const showDownloadReceiptButton = row.direction !== 'Debit' && !futurePayment;
  const isIntlPayment = row.type === 'International outgoing wire';
  const rejectedReason = row?.reason
    ? getPaymentRejectedMessage(row?.reason as RejectedReason)
    : null;

  return (
    <div className={classes.paymentDetailsModal}>
      <div className={cx(classes.header, classes.rowContent)}>
        <div>
          <div style={{ display: 'flex', gap: rem(14), alignItems: 'center' }}>
            <Text fz={useMobileView ? rem(20) : rem(36)}>{row.amount}</Text>
            <Badge bg="neutral.2">{row.status}</Badge>
          </div>
          <Text fz={useMobileView ? rem(10) : rem(14)}>{formattedDate}</Text>
        </div>
        <div>
          <PiXLight
            color="neutral.0"
            onClick={onClose}
            size={useMobileView ? '1.25rem' : '1.9rem'}
          />
        </div>
      </div>
      {isIntlPayment && <PaymentTimeline {...row} />}
      <Group
        gap="xl"
        grow
        mx={useMobileView ? rem(20) : rem(50)}
        align="initial"
        mt={24}
      >
        <div>
          <Text className={classes.smallText}>Recipient</Text>
          <Text mt={rem(5)} className={classes.bigText}>
            {row.recipient}
          </Text>
        </div>

        {row.requestedBy && (
          <div>
            <Text className={classes.smallText}>Sender</Text>
            <Text mt={rem(5)} className={classes.bigText}>
              {row.requestedBy}
            </Text>
          </div>
        )}
      </Group>

      <Divider my={rem(24)} />
      <Group
        grow
        gap="xl"
        mx={useMobileView ? rem(20) : rem(50)}
        align="flex-start"
      >
        <div>
          <Text className={classes.smallText}>Type</Text>
          <Text mt={rem(5)} className={classes.bigText}>
            {row.type}
          </Text>
        </div>
        <div>
          <Text className={classes.smallText}>From account</Text>
          <Text mt={rem(5)} className={classes.bigText}>
            {row.accountName}
          </Text>
        </div>
      </Group>

      <Divider my={rem(24)} />
      <Group grow gap="xl" mx={useMobileView ? rem(20) : rem(50)}>
        <div>
          <Text className={classes.smallText}>External Notes</Text>
          <Text className={classes.bigText}>{row.description}</Text>
        </div>
        {row.scheduledFor && (
          <div>
            <Text className={classes.smallText}>Scheduled to be sent on</Text>
            <Text className={classes.bigText}>{deliveryFormattedDate}</Text>
          </div>
        )}
      </Group>
      {row.notes !== '' && (
        <>
          <Divider my={rem(24)} />
          <div className={classes.detailsContainer}>
            <Text className={classes.smallText}>Internal Notes</Text>
            <Text className={classes.bigText}>{row.notes}</Text>
          </div>
        </>
      )}

      {rejectedReason && (
        <>
          <Divider my={rem(24)} />
          <div className={classes.detailsContainer}>
            <Text className={classes.smallText}>Rejected reason</Text>
            <Text className={classes.bigText}>{rejectedReason}</Text>
          </div>
        </>
      )}

      <AttachtmentsSection {...{ row }} />

      {showDownloadReceiptButton && (
        <>
          <Divider my={rem(24)} />
          <div className={classes.detailsContainer}>
            <Text className={classes.smallText}>Payment receipt</Text>
            <div
              className={classes.receiptSection}
              onClick={() => window.open(`/payment/receipt/${row?.id}`)}
            >
              <PiDownloadSimpleBold size={'0.9rem'} />
              <Text>Download</Text>
            </div>
          </div>
        </>
      )}

      {showActionSection && (
        <>
          <Divider my={rem(24)} />

          {showConfirmationDisclaimer && (
            <>
              <div className={classes.detailsContainer}>
                <Text className={classes.smallText}>Action required</Text>
              </div>
              <Text className={classes.paymentConfirmationDisclaimer}>
                {confirmationCopy}
              </Text>
            </>
          )}

          <Flex justify="end" mt="sm">
            <ManagePayment
              paymentId={row.id}
              createdBy={row.userId}
              approvedBy={row.approvedBy}
              status={row.exactStatus}
              type={row.type}
              onSuccess={onClose}
            />
          </Flex>
        </>
      )}
    </div>
  );
};

export default PaymentDetails;
