import { ContainerWithFooter } from '@common/utilities/footer/footer-container';
import { useOnboardingStepperStyles } from '@utilities/custom-hooks/use-onboarding-stepper-styles';
import { PrefillFormContainer } from './prefill-form-container';
import {
  PrefillFormState,
  PrefillFormStep,
  PrefillProvider,
} from './prefill-multi-step-form.context';
import { useOnboardingStatusContext } from '../../../../providers/onboarding-status-context';
import { useGetMe } from '../../../../queries/use-get-me';

export const PrefillPage = () => {
  const { classes } = useOnboardingStepperStyles();
  const { data: platformPerson } = useGetMe();
  const {
    status: { userIsApplicant, requiredProperties, factors },
  } = useOnboardingStatusContext();

  const formSteps: PrefillFormStep[] = [
    {
      condition: (state) => !state.phoneVerified,
      route: 'phone',
      metadata: {
        title: 'Verify mobile phone',
        id: 'edit-phone',
        description: 'We’ll send you a one time security code',
      },
    },
    {
      condition: (state) => !state.phoneVerified,
      route: 'verify-phone',
      metadata: {
        title: 'Verify mobile phone',
        id: 'verify-phone',
      },
    },
    {
      condition: () => !platformPerson?.dateOfBirth,
      route: 'birthday',
      metadata: {
        title: 'Confirm birthdate',
        id: 'birthday',
        description: 'Confirm your date of birth.',
      },
    },
    {
      condition: (state) =>
        !state.prefilled &&
        requiredProperties.some(
          (r) =>
            r === 'user.firstName' ||
            r === 'user.lastName' ||
            r === 'user.taxId' ||
            r === 'user.address',
        ),
      route: 'identity',
      metadata: {
        title: 'Verify identity',
        id: 'identity',
        disclosures: ['patriotAct', 'ficoPull'],
      },
    },
    {
      condition: (state) => state.businessPrefillAvailable && userIsApplicant,
      route: 'business',
      metadata: {
        title: 'Add your business',
        id: 'business',
      },
    },
    {
      route: 'continue',
      metadata: {
        title: 'Continue application',
        id: 'continue',
      },
    },
  ];

  const initialPrefillState: PrefillFormState = {
    dateOfBirthProvided: !!platformPerson?.dateOfBirth,
    phoneVerified: factors.some(
      (factor) => factor.method === 'phone' && !!factor.verified,
    ),
    loading: false,
    error: null,
    prefilled: false,
    businessPrefillAvailable: true, // available by default
    phoneVerification: null,
  };

  return (
    <ContainerWithFooter
      disclaimer2={true}
      className={classes.footerContainer}
      classNames={{
        container: classes.footerContainer,
        footerDiscloure: classes.footerDisclosure,
        scrollContainer: classes.scrollContainer,
      }}
    >
      <PrefillProvider
        initialState={initialPrefillState}
        steps={formSteps}
        keepUtmParamsOnNavigation
      >
        <PrefillFormContainer />
      </PrefillProvider>
    </ContainerWithFooter>
  );
};
