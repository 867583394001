import { Box, Flex, rem } from '@mantine/core';
import { useInvoiceWizard } from '../../invoice-wizard';
import { ReactNode } from 'react';
import { useWizardStyles } from '@common/emerald/wizard/wizard.styles';
import DocumentViewer from '@common/document-viewer';
import ScanningLoading from './scanning-loading';

const UploadInvoicePreview = ({
  hideIfNoImage,
}: {
  hideIfNoImage?: boolean;
}) => {
  const { state } = useInvoiceWizard();
  const { uploadedDocument, existingDocument, isDocumentUploading } = state;
  const document = uploadedDocument || existingDocument;

  if (!document) {
    if (hideIfNoImage) {
      return null;
    }
    return (
      <Wrapper>
        <Flex justify="center" align="center" h="100%">
          Bill image will appear here
        </Flex>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {isDocumentUploading ? (
        <Box pos="relative" h="100%">
          <Box
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            style={{ filter: `blur(${rem(4)})`, zIndex: 1 }}
          >
            <DocumentViewer document={document} altText="invoice preview" />
          </Box>
          <ScanningLoading />
        </Box>
      ) : (
        <DocumentViewer document={document} altText="invoice preview" />
      )}
    </Wrapper>
  );
};

const Wrapper = ({ children }: { children?: ReactNode }) => {
  const { cx, classes } = useWizardStyles();
  return (
    <Box className={cx(classes.previewPanel, classes.scrollContainer)}>
      <Box h="100%" sx={{ zIndex: 10 }}>
        {children}
      </Box>
    </Box>
  );
};

export default UploadInvoicePreview;
