import { Avatar, Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { getInitialsOfNames } from '@utilities/object';
import { Request } from '../../../spend-plans.types';
import { IoMdCheckmark } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { ReactNode, useMemo } from 'react';
import { formatCurrency } from '@utilities/formatters';
import { useGetUsers } from '../../../../../queries/use-users';
import { useRecoilValue } from 'recoil';
import { UserIdState } from '../../../../../recoil-state/application/onboarding-form.state';

type Props = {
  request: Request;
  decision: string;
  children: ReactNode;
};

const RequestStatus = ({ request, decision, children }: Props) => {
  const theme = useMantineTheme();
  const { data: users } = useGetUsers();
  const userId = useRecoilValue(UserIdState);

  const decisionByName = useMemo(() => {
    const manager = users?.find((user) => user.id === request.decisionedBy);
    return request.decisionedBy === userId
      ? 'you'
      : manager
        ? `${manager?.firstName} ${manager?.lastName}`
        : 'Manager';
  }, [request, users]);

  return (
    <Flex>
      <Avatar mr={8} color="primary.2" radius="xl" size="sm">
        {getInitialsOfNames(request.name)}
      </Avatar>
      <Box w="100%">
        <Text fz="1rem">
          {`${request.name} is requesting an additional ${formatCurrency(
            request.amount,
          )} in funds`}
        </Text>
        <Box>
          <Text fz="14px" c="neutral.8">
            {request.status === 'declined'
              ? request.reason
              : request.description}
          </Text>
          <Flex align="center">
            {(request.status === 'declined' || decision === 'declined') && (
              <Flex align="center">
                <IoClose
                  width="16px"
                  height="16px"
                  color={theme.colors.critical[2]}
                />
                <Text fz="14px" c="neutral.8" ml={4}>
                  {`Denied by ${decision !== '' ? 'you' : decisionByName}`}
                </Text>
              </Flex>
            )}
            {(request.status === 'approved' || decision === 'approved') && (
              <Flex align="center">
                <IoMdCheckmark
                  width="16px"
                  height="16px"
                  color={theme.colors.primary[2]}
                />
                <Text fz="14px" c="neutral.8" ml={4}>
                  {`Approved by ${decision !== '' ? 'you' : decisionByName}`}
                </Text>
              </Flex>
            )}
          </Flex>
        </Box>
        {children}
      </Box>
    </Flex>
  );
};

export default RequestStatus;
