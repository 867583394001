import { Flex, Text } from '@mantine/core';
import { formatToTwoDecimalPlaces } from '../../util';

type Props = {
  amount: number | string;
  downloadButton: JSX.Element;
};

const IntlPaymentCardSummary = ({ amount, downloadButton }: Props) => {
  return (
    <Flex justify={'center'} align={'center'}>
      <Text color="neutral.8" size="sm" pr={'sm'}>
        ${formatToTwoDecimalPlaces(amount)} USD
      </Text>
      {downloadButton}
    </Flex>
  );
};

export default IntlPaymentCardSummary;
