import { Box, Button, Divider, Text, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { showNotification } from '@mantine/notifications';
import { useDeleteRecipient } from '@queries/use-recipients';
import { IoMdClose } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

type Props = {
  closeModal: () => void;
  recipientId: string;
  recipientName: string;
  refresh: () => void;
};

const DeleteVendor = ({
  closeModal,
  recipientId,
  refresh,
  recipientName,
}: Props) => {
  const { classes } = useStyles();
  const { mutate: deleteRecipient, isPending } = useDeleteRecipient();
  const { pathname } = useLocation();

  const isVendor = pathname.includes('/bill-pay/vendors');
  const vendorOrRecipient = isVendor ? 'Vendor' : 'Recipient';

  const onRemoveRecipient = () => {
    deleteRecipient(recipientId, {
      onSuccess: () => {
        showNotification({
          color: 'sage.4',
          message: `${vendorOrRecipient} removed`,
        });

        refresh();
        closeModal();
      },
      onError: () => {
        showNotification({
          color: 'red',
          message: `Unable to remove ${vendorOrRecipient.toLowerCase()}`,
        });
      },
    });
  };

  return (
    <Box>
      <Box p="md" className={classes.containerHeader}>
        <Text size="md" fw={'400'}>
          Delete {vendorOrRecipient.toLowerCase()}
        </Text>
        <IoMdClose onClick={() => closeModal()} />
      </Box>
      <Divider />
      <Box p="md">
        {isVendor
          ? `Are you sure you want to delete ${recipientName}? All bills associated with this vendor will remain.`
          : `Are you sure you want to remove ${recipientName}?`}
        <Box className={classes.containerButtons}>
          <Button variant="neutral-outline" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            loading={isPending}
            onClick={onRemoveRecipient}
            ml="xs"
            variant="critical-light"
          >
            Delete {vendorOrRecipient}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteVendor;

const useStyles = createStyles((theme) => ({
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerButtons: {
    marginTop: rem(16),
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.colors.neutral[7],
  },
}));
