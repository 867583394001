import { ApplicationConfigBuilder } from './application-config-builder';

const configBuilder = new ApplicationConfigBuilder();

configBuilder
  .addStep('company-location', true)
  .withRequirement('primaryOperationCountry');

configBuilder
  .addStep('company-revenue', true)
  .withRequirement('revenueMonths')
  .withRequirement('annualGrossRevenue');

configBuilder.addStep('connect-bank', true).withRequirement('plaidLinked');

configBuilder
  .addStep('connect-accounting-tool', true)
  .withRequirement('rutterLinked');

configBuilder.addEndRoute('completed');

export const QualificationApplicationConfig = configBuilder.build();
