import {
  QueryClient,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';
import {
  cardIsChargeCard,
  cardIsCreditCard,
  cardIsLithicCard,
  CreditCard,
  IssueCardRequest as IssueCardRequestApi,
  UpdateCardRequest,
  UpdateCardStatusRequest,
} from '../services/flexbase/card.model';
import { updateQueryData } from './helpers';
import { platformClient } from '../services/platform/platform-client';
import { showNotification } from '@mantine/notifications';
import {
  PatchExpensesCategoryToCardRequest,
  PostExpensesCategoryToCardRequest,
} from '@flexbase-eng/types/dist/accounting';
import { platformSdk } from '@services/platform-sdk';
import {
  ActivateCardRequest,
  IssueCardRequest,
  SuspendCardRequest,
  TerminateCardRequest,
  UpdateCardRequest as UpdateCardRequestEx,
} from '@flexbase-eng/sdk-typescript/models/operations';
import { Card, Sort } from '@flexbase-eng/sdk-typescript/models/components';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAccountant,
  IsAdmin,
} from 'recoil-state/application/product-onboarding';

export const QUERY_KEY = 'credit_cards_query';

type ListCardsAccountOptions = {
  enabled?: boolean;
  sort?: Sort;
};

const listCardsAccountOptions = (
  accountId: string,
  { enabled = true, sort = 'desc' }: ListCardsAccountOptions = {},
) => {
  return queryOptions({
    queryKey: ['credit', 'cards', accountId, 'list', accountId, { sort }],
    queryFn: async () => {
      // TODO: replace with platformSdk once the SDK is fixed
      const res = await platformClient.listCards({ accountId, sort });

      return res.data as (Card & { cardToken: string })[];
    },
    enabled: !!enabled,
  });
};

export const useAccountCreditCards = (
  accountId: string,
  options: { enabled?: boolean; sort?: Sort } = {},
) => {
  return useQuery(listCardsAccountOptions(accountId, options));
};

type ListCardsPersonOptions = {
  enabled?: boolean;
  sort?: Sort;
};

const listCardsPersonOptions = (
  accountId: string,
  personId: string,
  { enabled = true, sort = 'desc' }: ListCardsPersonOptions = {},
) => {
  return queryOptions({
    queryKey: ['credit', 'cards', accountId, 'list', personId, { sort }],
    queryFn: async () => {
      // TODO: replace with platformSdk once the SDK is fixed
      const res = await platformClient.listCardsPerson({
        accountId,
        personId,
        sort,
      });

      return res.data as (Card & { cardToken: string })[];
    },
    enabled: !!enabled,
  });
};

export const usePersonCreditCards = (
  accountId: string,
  personId: string,
  options: { enabled?: boolean; sort?: Sort } = {},
) => {
  return useQuery(listCardsPersonOptions(accountId, personId, options));
};

/**
 * Fetches credit cards from Platform.
 *
 * Admins/accountants can fetch all cards, other users can only fetch their own cards.
 */
export const usePlatformCreditCards = () => {
  const { accountId, personId } = useRecoilValue(ApplicationState);
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const isElevated = isAdmin || isAccountant;
  const accountQuery = useAccountCreditCards(accountId, {
    enabled: isElevated,
  });
  const personQuery = usePersonCreditCards(accountId, personId, {
    enabled: !isElevated,
  });

  return isElevated ? accountQuery : personQuery;
};

const personCardOptions = (
  accountId: string,
  personId: string,
  cardId: string,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  return queryOptions({
    queryKey: [
      'credit',
      'cards',
      accountId,
      'persons',
      personId,
      'card',
      cardId,
    ],
    queryFn: async () => {
      return await platformSdk.cards.getCard({ accountId, personId, cardId });
    },
    enabled: !!enabled,
  });
};

export const usePersonCard = (
  accountId: string,
  /**
   * The personId of the card's owner, not necessarily the one calling the function.
   */
  personId: string,
  cardId: string,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  return useQuery(personCardOptions(accountId, personId, cardId, { enabled }));
};

export const useUpdatePersonCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: UpdateCardRequestEx) => {
      const updatedCard = await platformSdk.cards.updateCard(request);

      return updatedCard;
    },
    onSuccess: async (_, { accountId, personId, cardId }) => {
      await Promise.allSettled([
        queryClient.invalidateQueries(listCardsAccountOptions(accountId)),
        queryClient.invalidateQueries(
          listCardsPersonOptions(accountId, personId),
        ),
        queryClient.invalidateQueries(
          personCardOptions(accountId, personId, cardId),
        ),
      ]);
    },
  });
};

export const useActivatePersonCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: ActivateCardRequest) => {
      return await platformSdk.cards.activateCard(request);
    },
    onSuccess: async (_, { accountId, personId, cardId }) => {
      await Promise.allSettled([
        queryClient.invalidateQueries(listCardsAccountOptions(accountId)),
        queryClient.invalidateQueries(
          listCardsPersonOptions(accountId, personId),
        ),
        queryClient.invalidateQueries(
          personCardOptions(accountId, personId, cardId),
        ),
      ]);
    },
  });
};

export const useFreezePersonCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: SuspendCardRequest) => {
      return await platformSdk.cards.suspendCard(request);
    },
    onSuccess: async (_, { accountId, personId, cardId }) => {
      await Promise.allSettled([
        queryClient.invalidateQueries(listCardsAccountOptions(accountId)),
        queryClient.invalidateQueries(
          listCardsPersonOptions(accountId, personId),
        ),
        queryClient.invalidateQueries(
          personCardOptions(accountId, personId, cardId),
        ),
      ]);
    },
  });
};

export const useCancelPersonCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: TerminateCardRequest) => {
      return await platformSdk.cards.terminateCard(request);
    },
    onSuccess: async (_, { accountId, personId, cardId }) => {
      await Promise.allSettled([
        queryClient.invalidateQueries(listCardsAccountOptions(accountId)),
        queryClient.invalidateQueries(
          listCardsPersonOptions(accountId, personId),
        ),
        queryClient.invalidateQueries(
          personCardOptions(accountId, personId, cardId),
        ),
      ]);
    },
  });
};

export const useIssueCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: IssueCardRequest) => {
      const issuedCard = await platformSdk.cards.issueCard(request);

      return issuedCard;
    },
    onSuccess: async (_, { accountId }) => {
      await queryClient.invalidateQueries(listCardsAccountOptions(accountId));
    },
  });
};

/**
 * This query automatically filters debit cards since it is a credit card query.
 * @param full
 * @param searchTerm
 * @param status
 */
type UseCreditCardsParams = {
  full?: boolean;
  searchTerm?: string;
  status?: string;
  enabled?: boolean;
};

const creditCardsOptions = (params: UseCreditCardsParams = {}) => {
  return queryOptions({
    queryKey: [QUERY_KEY, params],
    queryFn: async () => {
      const result = await flexbaseOnboardingClient.getCompanyCards({
        full: params?.full ?? true,
        searchTerm: params?.searchTerm ?? '',
        status: params?.status ?? '',
      });

      if (!result.success) {
        throw new Error(
          'Unable to retrieve cards but the error was squashed in legacy API client',
        );
      }

      return result.cards.filter((c) => cardIsCreditCard(c)) as CreditCard[];
    },
    meta: {
      errorMessage: 'Unable to retrieve credit cards at this time.',
    },
    enabled: params?.enabled ?? true,
  });
};

export const useCreditCards = (params?: UseCreditCardsParams) => {
  return useQuery(creditCardsOptions(params));
};

export const useNet60CreditCards = (params?: UseCreditCardsParams) => {
  const options = creditCardsOptions(params);

  return useQuery({
    ...options,
    select: (cards) => cards.filter(cardIsLithicCard),
  });
};

export const useChargeCards = (params?: UseCreditCardsParams) => {
  const options = creditCardsOptions(params);

  return useQuery({
    ...options,
    select: (cards) => cards.filter(cardIsChargeCard),
  });
};

type UpdateMutation = { id: string; card: Partial<UpdateCardRequest> };

/*
  This can be used for both debit and charge cards.
  However, I'll likely duplicate this in use-debit-cards.ts until we can consider a better solution to unify 'card mgmt'
  as a whole, since the 'cards' concept now can handle debit, charge, and credit cards. (DM)
*/
export const useUpdateCard = () => {
  const queryClient = useQueryClient();

  async function mutationFn({ id, card }: UpdateMutation) {
    return await flexbaseOnboardingClient.updateCreditCard(id, card);
  }

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries(creditCardsOptions());
    },
  });
};

type CardMutationContext = { oldCard?: CreditCard };

function onMutateSuccess(queryClient: QueryClient, data: CreditCard) {
  const { queryKey } = creditCardsOptions();

  updateQueryData<CreditCard[]>(queryClient, queryKey, (prev) => {
    return prev?.map((card) =>
      card.id === data.id ? { ...card, ...data } : card,
    );
  });
}

function onMutateError(
  queryClient: QueryClient,
  context?: CardMutationContext,
) {
  if (context?.oldCard) {
    const { queryKey } = creditCardsOptions();

    updateQueryData<CreditCard[]>(queryClient, queryKey, (prev) => {
      return prev?.map((card) => {
        // we need to do this check again because typescript
        if (context?.oldCard && card.id === context.oldCard.id) {
          return { ...context.oldCard };
        }
        return card;
      });
    });
  }
}

async function updateCreditCard({ id, card }: UpdateMutation) {
  return await flexbaseOnboardingClient.updateCreditCard(id, card);
}

export const useUpdateCreditCardMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCreditCard,
    onMutate: async (variables) => {
      const creditCardsQueryOptions = creditCardsOptions();
      const { queryKey } = creditCardsQueryOptions;
      const context: { oldCard?: CreditCard } = { oldCard: undefined };

      await queryClient.cancelQueries(creditCardsQueryOptions);

      updateQueryData<CreditCard[]>(queryClient, queryKey, (prev) => {
        return prev?.map((card) => {
          if (card.id === variables.id) {
            context.oldCard = card;
            return { ...card, ...variables.card };
          }

          return card;
        });
      });

      return context;
    },
    onSuccess: (data) => onMutateSuccess(queryClient, data.card),
    onError: (_error, _variables, context) =>
      onMutateError(queryClient, context),
  });
};

async function updateCardStatus(args: UpdateCardStatusRequest) {
  const result = await flexbaseOnboardingClient.updateCreditCardStatus(args);
  return result.card as CreditCard;
}

export const useUpdateCreditCardStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCardStatus,
    onMutate: async (variables) => {
      const creditCardsQueryOptions = creditCardsOptions();
      const { queryKey } = creditCardsQueryOptions;
      const context: { oldCard?: CreditCard } = { oldCard: undefined };

      await queryClient.cancelQueries(creditCardsQueryOptions);

      updateQueryData<CreditCard[]>(queryClient, queryKey, (prev) => {
        return prev?.map((card) => {
          if (card.id === variables.cardId) {
            context.oldCard = card;
            return { ...card, status: variables.status };
          }
          return card;
        });
      });

      return context;
    },
    onSuccess: (data) => onMutateSuccess(queryClient, data),
    onError: (_error, _variables, context) =>
      onMutateError(queryClient, context),
  });
};

type IssueCardMutationArgs = { userId: string; card: IssueCardRequestApi };

export const useIssueCreditCardMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userId, card }: IssueCardMutationArgs) => {
      return await flexbaseOnboardingClient.issueCard(userId, card);
    },
    onSuccess: async (data) => {
      const card = data.card;

      if (cardIsCreditCard(card)) {
        await queryClient.invalidateQueries(creditCardsOptions());
      }
    },
  });
};

const CATEGORIES_CARDS_KEY = 'cards_categories';

async function getCardsAndCategories(connectionId: string) {
  return await platformClient.getCardsAndCategories(connectionId);
}

type UseAcccountingCategoryProps = {
  connectionId: string;
};

export function useCardsAndCategories(props: UseAcccountingCategoryProps) {
  const { connectionId } = props;
  return useQuery({
    enabled: !!connectionId,
    queryKey: [CATEGORIES_CARDS_KEY, connectionId],
    queryFn: () => getCardsAndCategories(connectionId),
  });
}

const CARD_CATEGORY_KEY = 'card_category';

async function getCardCategory(connectionId: string, cardId: string) {
  return await platformClient.getCardCategory(connectionId, cardId);
}

type UseCardCategoryProps = {
  connectionId: string;
  cardId: string;
};

export function useCardCategory(props: UseCardCategoryProps) {
  const { connectionId, cardId } = props;
  return useQuery({
    enabled: !!connectionId,
    queryKey: [CARD_CATEGORY_KEY, connectionId, cardId],
    queryFn: () => getCardCategory(connectionId, cardId),
  });
}

async function updateCardCategory(params: {
  connectionId: string;
  categoryId: string;
  cardId: string;
}): Promise<PatchExpensesCategoryToCardRequest> {
  return platformClient.updateCardCategory(
    params.connectionId,
    params.categoryId,
    params.cardId,
  );
}

export function useUpdateCardCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCardCategory,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_CARDS_KEY, variables.connectionId],
      });
      queryClient.invalidateQueries({
        queryKey: [CARD_CATEGORY_KEY, variables.connectionId, variables.cardId],
      });
    },
  });
}

async function createCardCategory(params: {
  connectionId: string;
  categoryId: string;
  cardName: string;
  userId: string;
}): Promise<PostExpensesCategoryToCardRequest> {
  return platformClient.createCategoryCard(
    params.connectionId,
    params.categoryId,
    params.cardName,
    params.userId,
  );
}

export function useCreateCardCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCardCategory,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_CARDS_KEY, variables.connectionId],
      });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to create card category',
      });
    },
  });
}
