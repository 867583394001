import { DateTime } from 'luxon';

export const formatDate = (date: string) =>
  DateTime.fromSQL(date).toFormat('D');

export const formatOnboardingApiDate = (date?: string) => {
  if (!date) {
    return '';
  }
  const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');

  return dateTime.isValid ? dateTime.toFormat('MM/dd/yyyy') : '';
};

export const formatDatetime = (date: string) => {
  let luxDate = DateTime.fromISO(date);

  if (!luxDate.isValid) {
    luxDate = DateTime.fromSQL(date);
  }

  if (!luxDate.isValid) {
    return 'Invalid date';
  }

  return `${luxDate.monthLong} ${luxDate.day}, ${luxDate.year}`;
};

type TimeFrame = {
  day: number;
  hour: number;
  millisecond: number;
  minute: number;
  month: number;
  second: number;
  year: number;
};

export const formatFilterDate = (timeframe: TimeFrame) => {
  const prefixSingleDigitWithZero = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  const { year, month, day } = timeframe;
  const formattedMonth = prefixSingleDigitWithZero(month);
  const formattedDay = prefixSingleDigitWithZero(day);

  return `${year}-${formattedMonth}-${formattedDay}`;
};
