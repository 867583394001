import { useIssueDebitCardWizard } from '../create-wizard';
import { Box, Button, Flex, Group, useMantineTheme } from '@mantine/core';
import { PiCheckCircle } from 'react-icons/pi';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import WizardFullscreenMessageCard from '@common/composites/wizard-fullscreen-message-card';
import ErrorIcon from '@common/composites/error-icon';

const centeredBoxStyle = {
  background: 'url(/images/page-top-frame.png) top / contain no-repeat #223F2E',
  backgroundSize: 'contain',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
};

export const IssueDebitCardStepSubmittedStep = () => {
  const { goToPreviousStep, state, setState } = useIssueDebitCardWizard();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const handleNavigateToDebitCards = () => {
    navigate('/banking/debit-cards');
  };

  const handleResetFormAndGoBack = () => {
    setState({
      error: false,
      debitCardFormValues: {
        limitType: 'unlimited',
        purchaseLimit: null,
        cardName: '',
        cardSubType: null,
        userId: '',
        cardUser: '',
      },
    });
    goToPreviousStep();
  };

  const handleGoToPreviousStep = () => {
    goToPreviousStep();
    setState((prev) => ({
      ...prev,
      error: false,
    }));
  };

  if (state.error) {
    return (
      <Flex justify="center" align="center" h="100%">
        <WizardFullscreenMessageCard
          icon={<ErrorIcon />}
          title="An error occurred while issuing a new debit card"
        >
          <Button
            size="sm"
            onClick={handleGoToPreviousStep}
            variant="primary-filled"
          >
            Try again
          </Button>
        </WizardFullscreenMessageCard>
      </Flex>
    );
  }

  return (
    <Box style={centeredBoxStyle}>
      <WizardFullscreenMessageCard
        icon={
          <PiCheckCircle size={'4.875rem'} color={theme.colors.primary[2]} />
        }
        title={`You’ve created a new debit card for ${state.debitCardFormValues.cardUser}!`}
      >
        <Group
          gap="sm"
          wrap="nowrap"
          justify={useMobileView ? 'center' : 'apart'}
        >
          <Button
            size="sm"
            onClick={handleNavigateToDebitCards}
            variant="neutral-outline"
          >
            Back to debit cards
          </Button>
          <Button onClick={handleResetFormAndGoBack} variant="primary-filled">
            Issue another debit card
          </Button>
        </Group>
      </WizardFullscreenMessageCard>
    </Box>
  );
};

IssueDebitCardStepSubmittedStep.stepId = 'issue-debit-card-confirmation';
