import { platformClient } from '@services/platform/platform-client';
import { useQuery } from '@tanstack/react-query';

export const useClientAccessToken = (
  cardToken: string,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  return useQuery({
    queryKey: ['credit', 'client-access-token', cardToken],
    queryFn: async () => {
      const res = await platformClient.getClientAccessToken(cardToken);

      return res.token;
    },
    enabled: !!enabled,
    staleTime: 1000 * 60 * 5,
  });
};
