import { Input } from '@mantine/core';
import OnboardingEndScreen from '../../../components/onboarding-end-screen';
import DeclineGraphic from 'assets/images/watch-banking.png';
import { useState } from 'react';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';

const InvalidPromoCodeShortCircuit = () => {
  const navigate = useMarketingNavigate();
  const [promoCode, setPromoCode] = useState('');

  const returnToReg = () => {
    navigate(`/referral/${promoCode}`, { replace: true });
  };

  return (
    <OnboardingEndScreen
      title="Sorry, we don't recognize this code."
      description={`Please check your code and try again. If code does not work, please contact your personal success manager at (571) 409-6558 to resolve!`}
      graphic={DeclineGraphic}
      primaryButtonText="Submit promo code"
      onPrimaryButtonClick={() => returnToReg()}
      additionalFormElement={
        <Input
          mt={20}
          placeholder="promo code"
          onChange={(event) => setPromoCode(event.currentTarget.value)}
        ></Input>
      }
    />
  );
};
export default InvalidPromoCodeShortCircuit;
