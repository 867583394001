import { Flex, Text } from '@mantine/core';

type DollarAmountTitleProps = {
  dollarAmountString: string;
  size?: 'lg' | 'sm' | 'xs' | 'xxs';
};

const sizeStyles = {
  lg: {
    dollars: {
      text: '3rem',
      fw: 400,
      lh: '3rem',
    },
    cents: {
      text: '2rem',
      fw: 550,
      lh: '2rem',
    },
  },
  sm: {
    dollars: {
      text: '2.5rem',
      fw: 400,
      lh: '2.5rem',
    },
    cents: {
      text: '1.5rem',
      fw: 550,
      lh: '1.5rem',
    },
  },
  xs: {
    dollars: {
      text: '2rem',
      fw: 400,
      lh: '2rem',
    },
    cents: {
      text: '1rem',
      fw: 550,
      lh: '1rem',
    },
  },
  xxs: {
    dollars: {
      text: '1.75rem',
      fw: 400,
      lh: '1.75rem',
    },
    cents: {
      text: '1.25rem',
      fw: 550,
      lh: '1.25rem',
    },
  },
};

export const DollarAmountTitle = ({
  dollarAmountString,
  size = 'lg',
}: DollarAmountTitleProps) => {
  const [dollars, cents] = dollarAmountString.split('.');
  return (
    <Flex align="flex-start">
      <Text
        size={sizeStyles[size].dollars.text}
        fw={sizeStyles[size].dollars.fw}
        component="span"
      >
        {dollars}
      </Text>
      <Text
        size={sizeStyles[size].cents.text}
        fw={sizeStyles[size].cents.fw}
        component="span"
        mt={3}
        lh={sizeStyles[size].cents.lh}
      >
        <span style={{ marginRight: 1, marginLeft: 2 }}>.</span>
        {cents || '00'}
      </Text>
    </Flex>
  );
};
