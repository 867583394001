import { Grid, Group, rem, Space, Text, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { formatCurrency } from '../../../utilities/formatters';
import { useNavigate } from 'react-router-dom';
import { CardBase } from '@common/composites/cards/card-base';
import { useStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';
import { useGetBankingTransactions } from '../../../queries/use-banking-transactions';
import { Transaction } from '../../../services/flexbase/banking.model';

const filterCanceledTransactions = (transactions: Transaction[]) => {
  const PAYMENT_CANCELED_TRANSACTION = 'paymentCanceledTransaction';
  return transactions.filter(
    ({ type }) => type !== PAYMENT_CANCELED_TRANSACTION,
  );
};

const RecentTransactionsWidget = () => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery('(max-width: 1000px)');
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { data } = useGetBankingTransactions();
  const top3LatestTransactions = useMemo(() => {
    if (!data) return [];

    const filteredTransactions = filterCanceledTransactions(data.transactions);

    return [...filteredTransactions]
      .sort(
        (a, b) =>
          DateTime.fromSQL(b.createdAt).toMillis() -
          DateTime.fromSQL(a.createdAt).toMillis(),
      )
      .slice(0, 3);
  }, [data]);

  return (
    <CardBase
      className={classes.transactionWidget}
      height={useMobileView ? 'auto' : 200}
    >
      <Text size={rem(16)} c={theme.colors.neutral[8]} fw={500}>
        Recent Transactions
      </Text>
      <Space h="md" />
      <Grid style={{ color: theme.colors.neutral[8] }}>
        {top3LatestTransactions.map((transaction) => {
          return (
            <Grid.Col span={12} key={transaction.id}>
              <Group>
                <Text fz={rem(12)} key={`${transaction.id}-created-at`}>
                  {DateTime.fromSQL(transaction.createdAt).toFormat('LLL dd')}
                </Text>
                <Text flex={1} fz={12} key={`${transaction.id}-summary`}>
                  {transaction.summary.length > 25
                    ? transaction.summary.slice(0, 25) + '..'
                    : transaction.summary}
                </Text>
                <Text fz={rem(12)} key={`${transaction.id}-amount`}>
                  {transaction.direction === 'Debit' ? '-' : '+'}
                  {formatCurrency(Number(transaction.amount) / 100)}
                </Text>
              </Group>
            </Grid.Col>
          );
        })}
        <Grid.Col
          span={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Text
            fz={rem(12)}
            fw={500}
            onClick={() => {
              navigate('/banking/transactions');
            }}
            style={{
              cursor: 'pointer',
              color: theme.colors.primary[2],
            }}
          >
            View more
          </Text>
        </Grid.Col>
      </Grid>
    </CardBase>
  );
};

export default RecentTransactionsWidget;
