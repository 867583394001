import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  PromoCodeState,
  RegistrationProductsState,
} from '../../recoil-state/application/product-onboarding';
import { useEffect } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { platformClient } from '@services/platform/platform-client';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';

/**
 * This component sits on a route to capture promo codes and save them into state.
 * When a user registers, this code will be saved to their user.
 *
 * Current existing promo codes
 * DEV:
 * fishy22 (no uw auto-reject bypass)
 * vip-dev (uw auto-reject bypass)
 * bankingvip (no uw auto-reject bypass)
 * bankingvip1 (uw auto-reject bypass)
 *
 * PROD:
 * zaidvip (uw auto-reject bypass)
 * bankingvip (no uw auto-reject bypass)
 * bankingvip1 (uw auto-reject bypass)
 *
 * @constructor
 */
export const ReferralRoute = () => {
  const [, setPromoCode] = useRecoilState(PromoCodeState);
  const setProducts = useSetRecoilState(RegistrationProductsState);
  const { code } = useParams();
  const navigate = useMarketingNavigate();

  const [searchParams] = useSearchParams();
  const externalId = searchParams.get('externalId');
  const prefillParam = searchParams.get('prefill');

  useEffect(() => {
    // This could also be a tsquery, but then there would be a useEffect to set products anyways when data changes, so no point
    const setCodeProducts = async (promoCode: string) => {
      const details = await platformClient.getPromoCode(promoCode);
      setProducts(details.products);
      setPromoCode({
        code: promoCode,
        ...(externalId ? { externalId } : {}),
      });
      const prefillOverrideParam = prefillParam
        ? `?prefill=${prefillParam}`
        : '?prefill=no'; // TEMPORARY to make cx team happy
      navigate(
        { pathname: `/register`, search: prefillOverrideParam },
        {
          replace: true,
        },
      );
    };
    if (code) {
      setCodeProducts(code).catch(() =>
        navigate('/referral/missing', { replace: true }),
      );
    }
  }, [code]);

  return <LoadingOverlay visible={true} />;
};
