import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  titleLarge: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: rem(48),
  },
  titleSmall: {
    fontSize: rem(24),
    fontFamily: 'PP Neue Montreal',
  },
  sectionTitle: {
    color: theme.primaryColor,
    fontWeight: 500,
    fontSize: rem(14),
  },
  marginTop: {
    marginTop: rem(30),
  },
  notesSection: {
    height: rem(42),
    display: 'flex',
    fontWeight: 400,
    fontSize: rem(14),
    padding: `0px ${rem(20)}`,
    alignItems: 'center',
    borderRadius: theme.defaultRadius,
    color: theme.colors.neutral[7],
    backgroundColor: theme.colors.neutral[2],
  },
  text: {
    fontWeight: 400,
    fontSize: rem(12),
    color: theme.colors.primary[8],
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${rem(24)} 0`,
    gap: rem(16),
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: rem(24),
  },
  disclaimerText: {
    margin: `${rem(24)} 0`,
  },
  box: {
    padding: `${rem(24)} 0 !important`,
  },
  filesContainer: {
    minHeight: rem(50),
    display: 'column',
    justifyContent: 'center',
  },
  file: {
    width: '100%',
    height: rem(40),
    margin: `${rem(8)} 0px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: rem(8),
    fontSize: rem(12),
    fontWeight: 500,
    color: theme.colors.neutral[7],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
  deleteFile: {
    all: 'unset',
    width: rem(24),
    height: rem(24),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    marginTop: rem(20),
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    padding: '0',
  },
  containerDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: `minmax(${rem(40)}, auto)`,
  },
  badge: {
    padding: `0 ${theme.spacing.xs}`,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.warning[1],
  },
}));
