import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    padding: 0,
    '> *': {
      width: '100%',
    },
    overflowY: 'auto',
    '@media(max-width: 767px)': {
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
    },
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 35,
    backgroundColor: theme.colors.primary[8],
    '> div:last-of-type': {
      marginRight: '-0.688rem',
    },
  },
  avatar: {
    height: '3.125rem',
    width: '3.125rem',
    color: theme.primaryColor,
    '> div:first-of-type': {
      color: `${theme.primaryColor} !important`,
      backgroundColor: '#FFEEEC !important',
      fontSize: '20px',
      fontWeight: 500,
      fontFamily: 'PP Neue Montreal',
    },
    marginRight: '1.25rem',
  },
  containerUserName: {
    color: theme.colors.neutral[0],
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: '2.6rem',
  },
  containerRole: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '1.313rem',
    color: theme.colors.neutral[0],
    marginTop: theme.spacing.xs,
  },
  closeButton: {
    display: 'flex',
    width: '2.1rem',
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: '#979797',
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'unset',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
    },
  },
}));
