import { DateTime } from 'luxon';
import { Box, rem, Stack, Stepper, Text } from '@mantine/core';
import { useStyles } from './styles';
import { Details } from './payment-details';
import { formatCurrency } from '@utilities/formatters';

const PaymentTimeline = (payment: Details) => {
  const { classes } = useStyles();
  const createdAt = DateTime.fromSQL(payment.createdAt);

  const isUSDpayment = payment.currency === 'USD';
  /* getting this status to know when a FX payment has been sent over to CurrencyCloud
    when moneymovement = 'Sent', the payment is sent to CurrencyCloud
    the conversion rate step will not display until the payment is sent to CurrencyCloud */
  const displayConversionStep = isUSDpayment || payment.exactStatus === 'Sent';
  const currentStep = displayConversionStep ? 2 : 1;

  const statusSteps: Record<string, number> = {
    Queued: 0,
    Scheduled: 0,
    '2FA Required': 0,
    'Approval Needed': 0,
    Clearing: 1,
    Pending: currentStep,
    Failed: currentStep,
    Returned: currentStep,
    Canceled: currentStep,
    Completed: 3,
  };

  const activeStep = statusSteps[payment.status] || 0;
  const stepLabel = isUSDpayment
    ? `Sending ${payment.amount} USD`
    : `Converted to ${formatCurrency(payment.fxAmount!, payment.currency)} ${
        payment.currency
      }`;
  const stepDescription = isUSDpayment
    ? null
    : `1 USD = ${payment.rate} ${payment.currency}`;

  return (
    <Box bg="neutral.0" p="md" m="xxxl">
      <Text fw={600} c="primary.8">
        Status
      </Text>
      <Stepper
        mt="md"
        active={activeStep}
        classNames={classes}
        orientation="vertical"
      >
        <Stepper.Step
          icon={null}
          label={payment.accountName}
          description={`${createdAt.toFormat('DD')} at ${createdAt.toFormat(
            't',
          )}`}
        />
        {displayConversionStep && (
          <Stepper.Step
            icon={null}
            description={stepDescription}
            label={
              stepDescription ? (
                stepLabel
              ) : (
                <Stack justify="center" mih={rem(24)}>
                  {stepLabel}
                </Stack>
              )
            }
          />
        )}
        <Stepper.Step
          icon={null}
          label={payment.recipient}
          description={payment.status}
          color={
            ['Failed', 'Rejected', 'Canceled'].includes(payment.status)
              ? 'critical.4'
              : 'primary.8'
          }
        />
      </Stepper>
    </Box>
  );
};

export default PaymentTimeline;
