/**
 * Convert passed-in array of objects into parsable and writable string
 *
 * A bulk of this code was brought over from the example in
 * https://react-data-table-component.netlify.app/?path=/story/examples-export-csv--export-csv
 *
 * @param dataArray
 */
export const convertArrayOfObjectsToCSV = (dataArray: object[]) => {
  let result: string;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = dataArray[0] ? Object.keys(dataArray[0]) : [];

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  dataArray.forEach((item: any) => {
    /*
    This is for a custom row (not at 0 index) that doesn't adhere to the same structure as the rest of the rows
    IE - this mostly intended for beginning and end balances
     */
    if (item.custom) {
      Object.keys(item).forEach((key) => {
        if (key !== 'custom') {
          result += `${key}${columnDelimiter}${item[key]}${columnDelimiter}`;
        }
      });
      result += lineDelimiter;
    } else {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) {
          result += columnDelimiter;
        }

        let value = item[key];
        if (typeof value === 'string' && value.search(',') !== -1) {
          value = `"${value.replace('bnpl', 'Bill Pay')}"`;
        }

        result += value;

        ctr++;
      });
      result += lineDelimiter;
    }
  });

  return result;
};

/**
 * Download/export table data to CSV
 *
 * A bulk of this code was brought over from the example in
 * https://react-data-table-component.netlify.app/?path=/story/examples-export-csv--export-csv
 *
 * @param data
 * @param fileName
 */

type Props = {
  data: object[] | string;
  fileName: string;
  withDate?: boolean;
  accountName?: string;
};

export const downloadCSV = ({
  data,
  fileName,
  withDate = true,
  accountName,
}: Props) => {
  const link = document.createElement('a');
  const date = new Date().toDateString();
  const csvFileName = withDate
    ? `${fileName}_${accountName}_${date}.csv`
    : `${fileName}.csv`;
  const csv =
    typeof data === 'string' ? data : convertArrayOfObjectsToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', csvFileName);
  link.click();
};
