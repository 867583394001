import { ApplicationConfiguration } from 'application/application-config-builder';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ProductNavStack } from 'recoil-state/application/product-onboarding';
import { OnboardingRouteNames } from 'recoil-state/application/product-onboarding.models';
import { useGetQualificationApplication } from './steps/qualification/use-qualification-application';
import { QualificationApplication } from '@flexbase-eng/sdk-typescript/models/components/qualificationapplication';
import { useMarketingNavigate } from '@services/analytics/use-marketing-search-string';

type QualificationApplicationContextType = {
  config: ApplicationConfiguration;
  navigateToNextStep: (application?: QualificationApplication | null) => void;
  goBack: () => void;
  progress: number;
};

const QualificationApplicationContext =
  createContext<QualificationApplicationContextType>(
    {} as QualificationApplicationContextType,
  );

export function useQualificationApplication() {
  return useContext(QualificationApplicationContext);
}

const QualificationApplicationProvider: React.FC<{
  config: ApplicationConfiguration;
}> = ({ config: currentConfig }) => {
  const { data: application } = useGetQualificationApplication();
  const navigate = useMarketingNavigate();
  const { step } = useParams();
  const [navStack, setNavStack] = useRecoilState(ProductNavStack);

  const progress = useMemo(() => {
    return currentConfig.getProgressSimple(step as OnboardingRouteNames);
  }, [step]);

  const navigateToNextStep = useCallback(
    async (updatedApplication?: QualificationApplication | null) => {
      try {
        if (navStack.length > 0) {
          const pop = navStack[0];
          setNavStack((prev) => [...prev.slice(1)]);
          navigate(pop);
        } else {
          const nextStep = currentConfig.getNextRouteFromApplication(
            updatedApplication || application,
          );

          navigate(nextStep);
        }
        window.scroll(0, 0);
      } catch (e) {
        navigate(`error`);
      }
    },
    [step, navStack, currentConfig],
  );

  const goBack = useCallback(() => {
    setNavStack((prev) => [step!, ...prev]);
    const prevStep = currentConfig.getPreviousRouteSimple(
      step as OnboardingRouteNames,
      true,
    );
    navigate(prevStep);
    window.scroll(0, 0);
  }, [step, currentConfig]);
  return (
    <QualificationApplicationContext.Provider
      value={{
        config: currentConfig,
        navigateToNextStep,
        goBack,
        progress,
      }}
    >
      <Outlet />
    </QualificationApplicationContext.Provider>
  );
};

export default QualificationApplicationProvider;
