import { Tier } from '../../../../types/move-funds.model';
import { DepositAccount } from '../../../../services/flexbase/banking.model';

const DAILY_ATM_SPEND_LIMITS_BY_ACCOUNT_TIER: Record<Tier, number> = {
  0: 0,
  1: 1000,
  2: 1000,
  3: 1000,
  4: 1000,
};

export const getTier = (accts: DepositAccount[], currentId: string): Tier =>
  accts.find(({ id }) => id === currentId)?.tier || 0;

export const getATMSpendLimitByTier = (
  accts: DepositAccount[],
  currentId: string,
) => {
  const tier = getTier(accts, currentId);
  return DAILY_ATM_SPEND_LIMITS_BY_ACCOUNT_TIER[tier];
};

export const checkATMWithdrawalLimits = (num: number, tier: Tier) => {
  if ([null, 0].includes(num)) {
    return 'Amount is required';
  }

  if (num < 0) {
    return 'Amount must be greater than 0';
  }

  if (num > DAILY_ATM_SPEND_LIMITS_BY_ACCOUNT_TIER[tier]) {
    return `Daily spending limit cannot exceed $${DAILY_ATM_SPEND_LIMITS_BY_ACCOUNT_TIER[tier]}`;
  }

  return null;
};
