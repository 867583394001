import { Anchor, Group, rem } from '@mantine/core';
import { Link } from 'react-router-dom';
import FlexbaseMainLayout from '../main';
import { PiCaretLeftBold } from 'react-icons/pi';

type Props = {
  children: React.ReactNode;
  linkTitle: string;
  linkPath: string;
};

const DetailPageLayout = ({ children, linkTitle, linkPath }: Props) => {
  return (
    <FlexbaseMainLayout>
      <Anchor
        mt={rem(32)}
        c="neutral.8"
        display="inline-block"
        component={Link}
        to={linkPath}
      >
        <Group gap="xxs">
          <PiCaretLeftBold />
          {linkTitle}
        </Group>
      </Anchor>
      {children}
    </FlexbaseMainLayout>
  );
};

export default DetailPageLayout;
