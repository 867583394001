import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  rem,
  Text,
  Button,
  LoadingOverlay,
  useMantineTheme,
  Stack,
} from '@mantine/core';
import { centsToFormattedCurrency } from '@utilities/formatters';
import { DollarAmountTitle } from '@common/composites/dollar-amount-title';
import { useBillpayLOCTransactions } from '@queries/use-bill-pay';
import { PiMailbox } from 'react-icons/pi';
import { getUTCDateFormatFromISO, sortDateFromSQL } from '@utilities/dates';

const ErrorState = ({ onRetry }: { onRetry: () => void }) => (
  <Box p={rem(24)}>
    <Text size="sm" c="neutral.8">
      An error occurred while loading your bill pay data.
    </Text>
    <Button mt="sm" size="compact-md" variant="outline" onClick={onRetry}>
      Try again
    </Button>
  </Box>
);

const EmptyState = () => (
  <Stack align="center" justify="center" h="100%" gap={rem(8)}>
    <PiMailbox size={20} color="neutral.10" />
    <Text size="md" c="neutral.8" ta="center">
      No upcoming bill pay payments
    </Text>
    <Text size="sm" c="neutral.6" ta="center">
      You&apos;re all caught up.
    </Text>
  </Stack>
);

const CreditLinePaymentCard = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const {
    data: billpayTransactionsData,
    isPending,
    refetch,
    error,
  } = useBillpayLOCTransactions();

  // grab the closest due outstanding transaction
  // TODO:replace the use of paymentDate with dueDate once dueDate is available
  const nextDueBill = billpayTransactionsData?.lineTransactions
    ?.filter(
      (transaction) =>
        transaction.status === 'outstanding' && !!transaction.paymentDate,
    )
    ?.sort((a, b) => {
      if (!a.paymentDate || !b.paymentDate) return 0;
      return sortDateFromSQL(a.paymentDate, b.paymentDate);
    })?.[0];

  const formattedDate = nextDueBill?.paymentDate
    ? `Due ${getUTCDateFormatFromISO(nextDueBill.paymentDate)}`
    : 'No payment due';

  return (
    <Box
      h={rem(200)}
      pos="relative"
      bg="neutral.0"
      sx={{
        borderRadius: theme.defaultRadius,
        border: `1px solid ${theme.colors.neutral[2]}`,
      }}
    >
      <LoadingOverlay visible={isPending} zIndex={1} />
      {error ? (
        <ErrorState onRetry={refetch} />
      ) : !nextDueBill ? (
        <EmptyState />
      ) : (
        <Box p={rem(24)} h="100%">
          <Flex direction="column" h="100%" justify="space-between">
            <Box>
              <Flex justify="space-between" align="center">
                <Text size="sm">Flex bill pay next payment</Text>
                <Button
                  size="xs"
                  variant="outline"
                  loading={isPending}
                  disabled={isPending}
                  onClick={() =>
                    navigate(
                      `/cards/pay/bill-pay-credit/outstanding/${nextDueBill.id}`,
                    )
                  }
                >
                  Pay now
                </Button>
              </Flex>
              <DollarAmountTitle
                dollarAmountString={centsToFormattedCurrency(
                  nextDueBill?.amount ?? 0,
                )}
                size="sm"
              />
            </Box>
            <Text size="sm" c="neutral.8">
              {formattedDate}
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default CreditLinePaymentCard;
