import {
  Text,
  Badge,
  Tooltip,
  Skeleton,
  useMantineTheme,
  rem,
  Flex,
  Box,
} from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { CardByUser } from 'services/flexbase/banking.model';
import { UserIdState } from 'recoil-state/application/onboarding-form.state';
import TwoFactorAuthSection from '../two-factor/two-factor-auth-section';
import { createStyles } from '@mantine/emotion';

type Props = {
  card: CardByUser;
  cardStatus: string;
};

declare const VGSShow: any;

const DebitCard = ({ card, cardStatus }: Props) => {
  const theme = useMantineTheme();
  const userId = useRecoilValue(UserIdState);
  const { classes } = useStyles({ cardStatus });
  const [displayData, setDisplayData] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [needsTwoFactor, setNeedsTwoFactor] = useState(false);
  const [bearerToken, setBearerToken] = useState<string | null>(null);

  const formatCardStatus: any = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Unactivated',
    terminated: 'Canceled',
  };

  // Create iframe for CVV2 and Card Number
  const show = VGSShow.create(import.meta.env.VITE_APP_UNIT_VAULT_ID);
  const customerToken = bearerToken;
  const cardId = card.ucCardId.substring(5);

  const cvv2iframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: '/cards/' + cardId + '/secure-data/cvv2',
    headers: {
      Authorization: 'Bearer ' + customerToken,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.cvv2',
  });

  const cardNumberIframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: '/cards/' + cardId + '/secure-data/pan',
    headers: {
      Authorization: 'Bearer ' + customerToken,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.pan',
    serializers: [
      show.SERIALIZERS.replace(
        '(\\d{4})(\\d{4})(\\d{4})(\\d{4})',
        '$1 $2 $3 $4',
      ),
    ],
  });

  const displayCardData = () => {
    if (setDisplayData) {
      setNeedsTwoFactor(true);
      setDisplayData(true);
    }
  };

  const handleDisplayData = () => {
    if (cardStatus === 'active' && userId === card.userId) {
      displayCardData();
    }
  };

  let badgeColor = '';
  switch (cardStatus) {
    case 'active':
      badgeColor = theme.colors.primary[0];
      break;

    case 'suspended':
      badgeColor = theme.colors.promote[1];
      break;
    case 'issued':
      badgeColor = theme.colors.neutral[3];
      break;
    case 'terminated':
      badgeColor = theme.colors.neutral[3];
      break;
  }

  useEffect(() => {
    if (bearerToken) {
      const commonStyles = {
        fontFamily: '"Inter", sans-serif',
        color: theme.colors.neutral[0],
        fontWeight: '200',
      };

      cvv2iframe.render('#cvv2', {
        ...commonStyles,
        fontSize: rem(16),
      });
      cardNumberIframe.render('#cardNumber', {
        ...commonStyles,
        lineHeight: '0.8',
        fontSize: useMobileView ? '20px' : '25px',
      });
    }
  }, [bearerToken]);

  return (
    <Box my="xxl">
      <Box className={classes.debitCardContainer}>
        <Flex justify="end">
          <Badge bg={badgeColor}>{formatCardStatus[cardStatus]}</Badge>
        </Flex>
        <Box
          sx={{
            opacity: cardStatus === 'active' ? 1 : 0.5,
          }}
        >
          {displayData ? (
            <>
              <Skeleton
                h={bearerToken ? 'fit-content' : 'sm'}
                my="sm"
                w={bearerToken ? 'auto' : rem(250)}
                visible={bearerToken ? false : true}
              >
                <Box id="cardNumber" />
              </Skeleton>
              <Flex gap="sm" align="center">
                <Text>EXP</Text>
                <Skeleton
                  h={bearerToken ? 'auto' : 'sm'}
                  width={bearerToken ? 'auto' : rem(42)}
                  visible={bearerToken ? false : true}
                >
                  <Text>{card.expirationDate}</Text>
                </Skeleton>
                <Text ml="xxxl">CVV </Text>
                <Skeleton
                  h={bearerToken ? 'auto' : 'sm'}
                  width={bearerToken ? 'auto' : rem(42)}
                  visible={bearerToken ? false : true}
                >
                  <Box mt={rem(2)} id="cvv2" />
                </Skeleton>
              </Flex>
            </>
          ) : (
            <Tooltip
              label="Click to reveal"
              disabled={userId !== card.userId || cardStatus !== 'active'}
              withArrow
              position="top-start"
            >
              <Box
                onClick={handleDisplayData}
                sx={{
                  cursor:
                    cardStatus === 'active' && userId === card.userId
                      ? 'pointer'
                      : 'auto',
                }}
              >
                <Text fz={useMobileView ? rem(20) : rem(25)} mb={rem(12)}>
                  •••• •••• •••• {card.cardNumber.slice(-4)}
                </Text>
                <Flex gap="xl" align="center">
                  <Text>EXP ••/••</Text>
                  <Text>CVV •••</Text>
                </Flex>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
      {needsTwoFactor && (
        <TwoFactorAuthSection
          isVGSflow
          onSuccess={(accessToken) => {
            if (accessToken) {
              setBearerToken(accessToken);
              setNeedsTwoFactor(false);
            }
          }}
        />
      )}
    </Box>
  );
};

export default DebitCard;

const useStyles = createStyles((theme, { cardStatus }: Partial<Props>) => ({
  debitCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minWidth: '100%',
    height: rem(350),
    padding: `${rem(30)} ${rem(50)}`,
    backgroundImage: 'url(/svg/flex-card.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    color: theme.colors.neutral[0],
    opacity: cardStatus === 'active' ? 1 : 0.5,
    iframe: {
      width: '100%',
      height: rem(22.4),
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.neutral[0],
    },
    '@media (max-width: 767px)': {
      height: rem(250),
    },
  },
}));
