import { ActionIconProps, ButtonProps, MantineTheme, rem } from '@mantine/core';

// shared styles for `Button` and `ActionIcon`
export const getThemedButtonStyles = (
  theme: MantineTheme,
  props: ButtonProps | ActionIconProps,
) => {
  let color; // text color
  let backgroundColor; // background color
  let borderColor; // border color
  let hoverBackgroundColor; // background color on hover
  let hoverBorderColor; // border color on hover
  let activeBackgroundColor; // background color on click
  let focusColor; // focus ring color

  switch (props.variant) {
    // -- PRIMARY BUTTONS --
    case 'default':
    case 'primary-filled':
      color = theme.colors.neutral[0];
      backgroundColor = theme.colors.primary[7];
      hoverBackgroundColor = theme.colors.primary[2];
      activeBackgroundColor = theme.colors.primary[3];
      focusColor = theme.colors.primary[1];
      break;
    case 'primary-light':
      color = theme.colors.neutral[0];
      backgroundColor = theme.colors.primary[2];
      hoverBackgroundColor = theme.colors.primary[3];
      focusColor = theme.colors.primary[1];
      break;
    case 'primary-outline':
      color = theme.colors.primary[4];
      backgroundColor = 'transparent';
      borderColor = theme.colors.primary[4];
      hoverBackgroundColor = 'transparent';
      activeBackgroundColor = theme.colors.neutral[3];
      focusColor = theme.colors.primary[1];
      break;
    case 'primary-subtle':
      color = theme.colors.neutral[9];
      backgroundColor = 'transparent';
      hoverBackgroundColor = theme.colors.neutral[3];
      focusColor = theme.colors.primary[1];
      break;
    //  -- NEUTRAL BUTTONS --
    case 'neutral-light':
      color = theme.colors.neutral[10];
      backgroundColor = theme.colors.neutral[3];
      hoverBackgroundColor = theme.colors.neutral[4];
      focusColor = theme.colors.primary[1];
      break;
    case 'neutral-outline':
      color = theme.colors.neutral[10];
      backgroundColor = 'transparent';
      borderColor = theme.colors.neutral[4];
      hoverBackgroundColor = 'transparent';
      hoverBorderColor = theme.colors.neutral[6];
      activeBackgroundColor = theme.colors.neutral[3];
      focusColor = theme.colors.primary[1];
      break;
    case 'neutral-subtle':
      color = theme.colors.neutral[10];
      backgroundColor = 'transparent';
      hoverBackgroundColor = theme.colors.neutral[3];
      focusColor = theme.colors.primary[1];
      break;
    // -- CRITICAL BUTTONS --
    case 'critical-filled':
      color = theme.colors.neutral[0];
      backgroundColor = theme.colors.critical[6];
      hoverBackgroundColor = theme.colors.critical[7];
      activeBackgroundColor = theme.colors.critical[8];
      focusColor = theme.colors.critical[1];
      break;
    case 'critical-light':
      color = theme.colors.neutral[0];
      backgroundColor = theme.colors.critical[2];
      hoverBackgroundColor = theme.colors.critical[3];
      focusColor = theme.colors.critical[1];
      break;
    case 'critical-outline':
      color = theme.colors.critical[6];
      backgroundColor = 'transparent';
      borderColor = theme.colors.neutral[4];
      hoverBackgroundColor = 'transparent';
      hoverBorderColor = theme.colors.critical[5];
      activeBackgroundColor = theme.colors.neutral[3];
      focusColor = theme.colors.critical[1];
      break;
    case 'critical-subtle':
      color = theme.colors.critical[6];
      backgroundColor = 'transparent';
      hoverBackgroundColor = theme.colors.neutral[3];
      focusColor = theme.colors.critical[1];
      break;
  }
  return {
    root: {
      fontWeight: 500,
      fontSize: rem(14),
      transition: 'all 100ms ease-in-out',
      transitionProperty: 'background-color, border-color, color',
      ...(backgroundColor && {
        backgroundColor,
        '--button-bg': backgroundColor,
      }),
      ...(borderColor && { borderColor }),
      ...(color && { color, '--button-color': color }),
      '&:hover:not(:disabled)': {
        color,
        ...(hoverBackgroundColor && {
          backgroundColor: hoverBackgroundColor,
          '--button-hover': hoverBackgroundColor,
        }),
        ...(hoverBorderColor && { borderColor: hoverBorderColor }),
      },
      '&:active:not(:disabled)': {
        ...(activeBackgroundColor && {
          backgroundColor: activeBackgroundColor,
        }),
        transform: 'none', // avoid mantine's default button jump
      },
      '&:disabled': {
        opacity: 0.5,
      },
      '&:focus': {
        ...(focusColor && {
          outlineColor: focusColor,
        }),
      },
    },
  };
};
