import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { Box, Text, Grid, Badge, Flex } from '@mantine/core';
import { accountDetailsStyles } from './styles';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { useWireInstructions } from '@queries/use-deposit-account';
import AccountNumberToggle from './account-number-toggle';

const getRepaymentSourceBadges = (instantPay: boolean, earlyPay: boolean) => {
  const repaymentSources: string[] = [];
  if (instantPay) {
    repaymentSources.push('Instant pay');
  }
  if (earlyPay) {
    repaymentSources.push('Early pay');
  }

  return (
    <Flex gap="xs">
      {repaymentSources.map((s) => (
        <Badge
          key={s}
          bg="neutral.2"
          sx={(theme) => ({
            border: `1px solid ${theme.colors.neutral[4]}`,
          })}
        >
          {s}
        </Badge>
      ))}
    </Flex>
  );
};

const AccountInformation = () => {
  const { state } = useLocation();
  const account = state;

  const { classes, theme } = accountDetailsStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const {
    data: wireInstructionsData,
    isError: isWireInstructionsError,
    isLoading: isLoadingWireInstructions,
  } = useWireInstructions(account.id);

  const accountName = account?.nickName ?? account?.name ?? 'Deposit account';

  const bankAddress = (address: string) => {
    if (!address || address === '') {
      return '';
    }
    const parts = address.split(', ');
    const [street, city, stateAndZipCode] = parts;

    return (
      <>
        <Text c="primary.8">{street}</Text>
        <Text c="primary.8">
          {city}, {stateAndZipCode}
        </Text>
      </>
    );
  };

  const accountInfo = [
    { label: 'Account name', value: accountName },
    { label: 'Account type', value: account?.accountType },
    { label: 'Account number', value: account?.accountNumber },
    { label: 'Routing number', value: account?.routingNumber },
  ];

  if (account.instantPay || account.earlyPay) {
    accountInfo.splice(2, 0, {
      label: 'Repayment source',
      value: getRepaymentSourceBadges(account.instantPay, account.earlyPay),
    });
  }

  if (isWireInstructionsError) {
    return (
      <Box className={classes.container}>
        <Text>
          We are unable to retrieve your bank account information at the moment.
        </Text>
      </Box>
    );
  }

  const textColor = 'primary.8';

  return (
    <Box className={classes.container}>
      {isLoadingWireInstructions ? (
        <SkeletonLoading />
      ) : (
        <Grid grow>
          <Grid.Col span={{ xs: 12, sm: 7, xl: 4 }}>
            <Text c="primary.8" size="lg">
              Account information
            </Text>
            <Grid mt="xl">
              {accountInfo.map((acc) => (
                <Grid.Col key={acc.value} span={4}>
                  <Text c="neutral.8" size="sm">
                    {acc.label}
                  </Text>
                  {acc.label === 'Account number' ? (
                    <AccountNumberToggle
                      accountNumber={acc.value}
                      textStyles={{
                        color: textColor,
                      }}
                    />
                  ) : (
                    <Text c={textColor} tt="capitalize">
                      {acc.value}
                    </Text>
                  )}
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>

          <Grid.Col span={{ xs: 12, sm: 5 }} mt={useMobileView ? 'xl' : 0}>
            <Text c="primary.8" size="lg" mb="xxl">
              Bank information
            </Text>
            <Text c="neutral.8" size="sm">
              Bank name
            </Text>
            <Text c="primary.8">
              {wireInstructionsData?.beneficiaryBankName}
            </Text>
            <Text c="neutral.8" size="sm" mt="lg">
              Bank address
            </Text>
            {bankAddress(wireInstructionsData?.beneficiaryBankAddress ?? '')}
          </Grid.Col>
        </Grid>
      )}
    </Box>
  );
};

export default AccountInformation;
