import { useState } from 'react';
import { Box, Loader, rem, Stack, Text } from '@mantine/core';
import { useStyles } from './summary.styles';
import OnboardingButtonGroup from '@common/onboarding-button-group';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { useOnboardingStyles } from '../../onboarding.styles';
import SummaryNameForm from './edit-forms/summary-name-form';
import SummaryBusinessPurposeForm from './edit-forms/summary-business-purpose-form';
import SummaryBirthdayForm from './edit-forms/summary-birthday-form';
import { SummaryEditBox } from './edit-forms/summary-edit-box';
import SummaryAddressForm from './edit-forms/summary-address-form';
import SummaryBusinessNameForm from './edit-forms/summary-business-name-form';
import SummaryWebsiteForm from './edit-forms/summary-website-form';
import SummaryCategoryForm from './edit-forms/summary-category-form';
import SummaryEstimatedRevenueForm from './edit-forms/summary-estimated-revenue-form';
import {
  TermsFormProvider,
  useTermsForm,
} from './terms-of-service/terms-form-context';
import {
  ConditionalFieldValidator,
  OptionalFieldValidator,
  RequiredFieldValidator,
  validatePostalCode,
  validateRequired,
  validateUrl,
  validateUSBirthDate,
} from '@utilities/validators';
import { OnboardingUser } from '../../../../types/onboarding-info';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { UnderlinedTabs } from '@common/composites/tabs/underlined-tabs';
import { useHasTerms } from '../../../../utilities/custom-hooks/use-has-terms';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { Analytics } from '@services/analytics/analytics';
import { ApplicationEvents } from '@services/analytics/events';
import { TermsinatorWithModal } from './terms-of-service/termsinator-with-modal';
import { SummaryEditInput } from './edit-forms/summary-edit-input';
import { useForm } from '@mantine/form';
import { formatOnboardingApiDate } from '@utilities/formatters/format-datetime';
import { formatDateForApi } from '@utilities/formatters/format-date-input';
import { validateState } from '@utilities/validators/validate-state';

export type PersonalInfoFormValues = {
  firstName: string;
  lastName: string;
  birthDate: string;
  address: {
    line1: string;
    line2: string;
    state: string;
    postalCode: string;
    city: string;
  };
};

export type CompanyInfoFormValues = {
  companyName: string;
  taxId: string;
  businessPurpose: string;
  website: string;
  category: string;
  annualRevenue: string;
  address: {
    line1: string;
    line2: string;
    state: string;
    postalCode: string;
    city: string;
  };
};

const Summary = () => {
  const { classes, cx } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ user, company, userIsApplicant }, setStatus] =
    useRecoilState(ApplicationState);
  const terms = useHasTerms();
  const {
    goBack,
    refreshProductOnboardingStatus,
    navigateToEndScreen,
    applyingForProducts,
  } = useApplicationFlowContext();
  const [error, setError] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const tabOptions = userIsApplicant
    ? ['Personal Information', 'Business Information']
    : ['Personal Information'];
  const [selectedForm, setSelectedForm] = useState<
    'Personal Information' | 'Business Information' | string
  >('Personal Information');
  const onFormChange = (nextSelectedForm: string) => {
    setSelectedForm(nextSelectedForm);
  };

  const personalInfoForm = useForm<PersonalInfoFormValues>({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: formatOnboardingApiDate(user.birthDate),
      address: {
        line1: user.address.line1 || '',
        line2: user.address.line2 || '',
        state: user.address.state || '',
        postalCode: user.address.postalCode || '',
        city: user.address.city || '',
      },
    },
    validate: {
      firstName: (val) =>
        validateRequired(val) ? null : 'First name is required',
      lastName: (val) =>
        validateRequired(val) ? null : 'Last name is required',
      birthDate: (val) =>
        validateUSBirthDate(val)
          ? null
          : 'Must be at least 18 in format MM/DD/YYYY.',
      address: {
        line1: (value) =>
          validateRequired(value) ? null : 'Street address is required',
        state: (value) => (validateState(value) ? null : 'State is required'),
        postalCode: (value) =>
          validatePostalCode(value) ? null : 'Zip is required',
        city: (value) => (validateRequired(value) ? null : 'City is required'),
      },
    },
  });

  const companyInfoForm = useForm<CompanyInfoFormValues>({
    initialValues: {
      companyName: company.companyName,
      taxId: company.taxId,
      businessPurpose: company.businessPurpose,
      website: company.website || '',
      category: company.category,
      annualRevenue: company.annualRevenue,
      address: {
        line1: company.address.line1 || '',
        line2: company.address.line2 || '',
        state: company.address.state || '',
        postalCode: company.address.postalCode || '',
        city: company.address.city || '',
      },
    },
    validate: {
      companyName: (val) =>
        validateRequired(val) ? null : 'Business name is required',
      taxId: (val) => (validateRequired(val) ? null : 'Tax ID is required'),
      businessPurpose: (val) =>
        validateRequired(val) ? null : 'Purpose is required',
      website: (value) => {
        return applyingForProducts.includes('BANKING')
          ? OptionalFieldValidator(
              'You must enter a valid URL for your company website',
              validateUrl,
              value,
            )
          : validateUrl(value)
            ? null
            : 'You must enter a valid URL for your company website';
      },
      category: (val) =>
        validateRequired(val) ? null : 'Category is required',
      annualRevenue: (val) =>
        validateRequired(val) ? null : 'Annual revenue is required',
      address: {
        line1: (value) =>
          validateRequired(value) ? null : 'Street address is required',
        state: (value) => (validateState(value) ? null : 'State is required'),
        postalCode: (value) =>
          validatePostalCode(value) ? null : 'Zip is required',
        city: (value) => (validateRequired(value) ? null : 'City is required'),
      },
    },
  });

  const onSavePersonalInfo = async () => {
    const validationResult = personalInfoForm.validate();
    if (!validationResult.hasErrors) {
      setIsSaving(true);
      try {
        const { firstName, lastName, birthDate, address } =
          personalInfoForm.values;
        const formattedDate = formatDateForApi(birthDate);
        await flexbaseOnboardingClient.updateUser({
          id: user.id,
          firstName,
          lastName,
          birthDate: formattedDate,
          address,
        });
        setStatus((prev) => ({
          ...prev,
          user: {
            ...user,
            firstName,
            lastName,
            birthDate: formattedDate,
            address,
          },
        }));
        setIsEditMode(false);
      } catch (e) {
        setError('There was an error updating your information');
      } finally {
        setIsSaving(false);
      }
    }
  };

  const onSaveBusinessInfo = async () => {
    const validationResult = companyInfoForm.validate();
    if (!validationResult.hasErrors) {
      setIsSaving(true);
      if (companyInfoForm.values.category.includes('-')) {
        companyInfoForm.values.category = companyInfoForm.values.category
          .split('-')?.[0]
          ?.trim();
      }
      try {
        const {
          companyName,
          taxId,
          businessPurpose,
          address,
          website,
          category,
          annualRevenue,
        } = companyInfoForm.values;
        await flexbaseOnboardingClient.updateCompany({
          id: company.id,
          companyName,
          taxId,
          businessPurpose,
          address,
          website,
          category,
          annualRevenue,
        });
        setStatus((prev) => ({
          ...prev,
          company: {
            ...prev.company,
            companyName,
            taxId,
            businessPurpose,
            address,
            website,
            category,
            annualRevenue,
          },
        }));
        setIsEditMode(false);
      } catch (e) {
        setError(
          'An error occurred while trying to update company information',
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  const termsForm = useTermsForm({
    initialValues: {
      creditTermsOfServiceSigned: user.creditTermsOfServiceSigned,
      bankingTermsOfServiceSigned: user.bankingTermsOfServiceSigned,
      internationalPaymentsTermsOfServiceSigned:
        user.internationalPaymentsTermsOfServiceSigned,
      treasuryTermsOfServiceSigned: user.treasuryTermsOfServiceSigned,
      termsOfServiceSigned: user.termsOfServiceSigned,
      personalGuarantySigned: user.personalGuarantySigned,
      ficoPullSigned: user.ficoPullSigned,
      patriotActSigned: user.patriotActSigned,
      nyTermsSigned: user.nyTermsSigned,
      flTermsSigned: user.flTermsSigned,
    },
    validate: {
      creditTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasCreditTerms,
        RequiredFieldValidator('Credit terms of service must be accepted'),
      ),
      bankingTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasBankingTerms,
        RequiredFieldValidator('Banking terms of service must be accepted'),
      ),
      internationalPaymentsTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasInternationalPaymentsTerms,
        RequiredFieldValidator(
          'International payments terms of service must be accepted',
        ),
      ),
      termsOfServiceSigned: ConditionalFieldValidator(
        terms.hasFlexbaseTerms,
        RequiredFieldValidator('Flexbase terms of service must be accepted'),
      ),
      treasuryTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasTreasuryTerms,
        RequiredFieldValidator('Treasury terms of service must be accepted'),
      ),
      personalGuarantySigned: ConditionalFieldValidator(
        terms.hasPersonalGuaranty,
        RequiredFieldValidator('Your personal guaranty must be accepted'),
      ),
    },
  });

  const onSubmitApplication = async () => {
    setError('');
    const validationResult = termsForm.validate();
    Analytics.track(ApplicationEvents.APPLICATION_SUBMIT_CLICKED);
    if (!validationResult.hasErrors) {
      setIsSubmitting(true);
      try {
        const request: Partial<OnboardingUser> = { id: user.id };
        Object.entries(termsForm.values).forEach(([key, value]) => {
          if (value) {
            request[key as keyof OnboardingUser] = value as any;
          }
        });
        await flexbaseOnboardingClient.updateUser(request);
        await refreshProductOnboardingStatus();
        navigateToEndScreen();
      } catch (e) {
        setError(
          'An error occurred while submitting your application. Please contact support or try again.',
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Box
      w={rem(525)}
      maw={{ base: '100%', sm: 'unset' }}
      p={{ base: 0, sm: 'unset' }}
    >
      <UnderlinedTabs
        tabs={tabOptions.map((val) => ({
          value: val,
          label: val,
          route: val,
        }))}
        selectedTab={selectedForm}
        onTabClick={(val) => onFormChange(val.label)}
        classNames={{ tabsContainer: classes.tabsContainer }}
      />

      {selectedForm === 'Personal Information' && (
        <SummaryEditBox
          isEditMode={isEditMode}
          onEditClick={() => setIsEditMode(true)}
          onSaveClick={onSavePersonalInfo}
          isLoading={isSaving}
          onCancelClick={() => setIsEditMode(false)}
        >
          <SummaryNameForm
            form={personalInfoForm}
            value={`${user.firstName} ${user.lastName}`}
            isEditMode={isEditMode}
          />
          <SummaryBirthdayForm
            form={personalInfoForm}
            value={formatOnboardingApiDate(user.birthDate)}
            isEditMode={isEditMode}
          />
          <SummaryEditInput
            label="Email"
            value={user.email}
            showEdit={false}
            isEditMode={isEditMode}
          />
          <SummaryAddressForm
            label="Address"
            displayLine1={`${user.address.line1} ${user.address.line2 || ''}`}
            displayLine2={`${user.address.city} ${user.address.state} ${user.address.postalCode}`}
            initialSearch={user.address.line1}
            form={personalInfoForm}
            isEditMode={isEditMode}
          />
        </SummaryEditBox>
      )}

      {selectedForm === 'Business Information' && (
        <SummaryEditBox
          isEditMode={isEditMode}
          onEditClick={() => setIsEditMode(true)}
          onSaveClick={onSaveBusinessInfo}
          isLoading={isSaving}
          onCancelClick={() => setIsEditMode(false)}
        >
          <SummaryBusinessNameForm
            value={company.companyName}
            form={companyInfoForm}
            isEditMode={isEditMode}
          />
          {company.taxId && (
            <SummaryEditInput
              label="EIN"
              value={company.taxId}
              showEdit={false}
              isEditMode={isEditMode}
            />
          )}
          <SummaryBusinessPurposeForm
            value={company.businessPurpose}
            form={companyInfoForm}
            isEditMode={isEditMode}
          />
          <SummaryAddressForm
            label="Business address"
            displayLine1={`${company.address.line1} ${company.address.line2 || ''}`}
            displayLine2={`${company.address.city} ${company.address.state} ${company.address.postalCode}`}
            initialSearch={company.address.line1}
            form={companyInfoForm}
            isEditMode={isEditMode}
          />
          {company.website && (
            <SummaryWebsiteForm
              value={company.website}
              form={companyInfoForm}
              isEditMode={isEditMode}
            />
          )}
          <SummaryCategoryForm
            value={company.category}
            form={companyInfoForm}
            isEditMode={isEditMode}
          />
          <SummaryEstimatedRevenueForm
            value={company.annualRevenue}
            form={companyInfoForm}
            isEditMode={isEditMode}
          />
        </SummaryEditBox>
      )}
      <Stack mt={rem(48)} mb="md">
        <TermsFormProvider form={termsForm}>
          <TermsinatorWithModal showTextAgreements={true} termsConfig={terms} />
        </TermsFormProvider>
      </Stack>
      {error && (
        <Text
          className={cx(
            onboardingClasses.stepText,
            onboardingClasses.stepErrorText,
          )}
        >
          {error}
        </Text>
      )}
      <OnboardingButtonGroup
        classNames={{
          buttonGroup: classes.buttonContainer,
          nextButton: classes.summaryContinueButton,
        }}
        nextButtonLabel={
          isSubmitting ? (
            <Loader size="sm" color="white" />
          ) : (
            `Submit${userIsApplicant ? ' Application' : ''}`
          )
        }
        disableAll={isSubmitting}
        onBackClick={() => goBack()}
        onNextClick={() => onSubmitApplication()}
      />
      {userIsApplicant ? (
        false
      ) : (
        <Text fz={'xs'} mt={15}>
          By providing the information above and clicking continue, I hereby
          certify, to the best of my knowledge, that the information provided
          above is complete and correct.
        </Text>
      )}
    </Box>
  );
};

export default Summary;
