import useModal from '@common/composites/modal/modal-hook';
import { useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  SegmentedControl,
  Stack,
  Text,
  rem,
  Select,
} from '@mantine/core';
import { PiX } from 'react-icons/pi';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';
import { UseFormReturnType } from '@mantine/form';
import { TermsFormValues } from './terms-form-context';
import { TermsOfServiceDocWithContents } from '@queries/use-terms-of-service';

type Props = {
  termsDocuments?: TermsOfServiceDocWithContents[];
  onAccept: () => void;
  termsForm: UseFormReturnType<
    TermsFormValues,
    (values: TermsFormValues) => TermsFormValues
  >;
};

const termTypeToFormField: Record<string, string> = {
  banking: 'bankingTermsOfServiceSigned',
  credit: 'creditTermsOfServiceSigned',
  flexbase: 'termsOfServiceSigned',
  internationalPayments: 'internationalPaymentsTermsOfServiceSigned',
  treasury: 'treasuryTermsOfServiceSigned',
  patriotAct: 'patriotActSigned',
  ficoPull: 'ficoPullSigned',
  flTermsAgreement: 'flTermsSigned',
  nyTermsAgreement: 'nyTermsSigned',
};

const ViewAndAgreeTermsModal = ({
  termsDocuments,
  onAccept,
  termsForm,
}: Props) => {
  const modals = useModal();
  const [selectedTerm, setSelectedTerm] = useState(termsDocuments?.[0]?.label);
  const isMobile = useIsMobile();
  const currentDocument = termsDocuments?.find(
    (term) => term.label === selectedTerm,
  );

  const handleAgreeToTerms = () => {
    termsDocuments?.forEach((term) => {
      const formField = termTypeToFormField[term.type];
      if (formField) {
        termsForm.setFieldValue(formField, true);
      }
    });
    onAccept();
    modals.closeAllModals();
  };

  return (
    <Stack p="sm" h={{ base: '100vh', sm: '80vh' }}>
      <Group justify="space-between" align="center">
        <Text size="xl" fw={500}>
          Terms
        </Text>
        <ActionIcon variant="subtle" onClick={modals.closeAllModals}>
          <PiX size={24} />
        </ActionIcon>
      </Group>
      <Flex sx={{ flex: 1 }} gap="lg" direction={isMobile ? 'column' : 'row'}>
        {isMobile ? (
          <Select
            value={selectedTerm}
            onChange={(value) => value && setSelectedTerm(value)}
            data={
              termsDocuments?.map((term) => ({
                label: term.label,
                value: term.label,
              })) ?? []
            }
            placeholder="Select terms document"
          />
        ) : (
          <Box w={rem(180)}>
            <SegmentedControl
              value={selectedTerm}
              onChange={setSelectedTerm}
              data={
                termsDocuments?.map((term) => ({
                  label: term.label,
                  value: term.label,
                })) ?? []
              }
              orientation="vertical"
              w={rem(180)}
              styles={{
                label: {
                  fontSize: rem(14),
                  lineHeight: 1.2,
                  padding: rem(12),
                  whiteSpace: 'normal',
                  textAlign: 'left',
                },
              }}
            />
          </Box>
        )}
        <Flex sx={{ flex: 1 }}>
          {currentDocument &&
            (currentDocument.contents ? (
              <Box
                dangerouslySetInnerHTML={{
                  __html: currentDocument.contents,
                }}
              ></Box>
            ) : (
              <iframe
                id={currentDocument.label}
                src={`${currentDocument.url}#toolbar=0&navpanes=0`}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
                title={currentDocument.label}
              />
            ))}
        </Flex>
      </Flex>
      <Group justify="flex-end">
        <Button
          variant="primary-light"
          onClick={handleAgreeToTerms}
          id="button-agree-to-terms"
        >
          Agree To Terms And Conditions
        </Button>
      </Group>
    </Stack>
  );
};

export default ViewAndAgreeTermsModal;
