import { useState } from 'react';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { Text, Button, rem, TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '@queries/use-users';
import { useStyles } from '../common/styles';
import { FlexbaseTable } from 'components/table';
import { sortDate } from '@utilities/dates';
import PaymentDetailsModal from '@common/payment-details-modal';
import { OverflowTooltip } from '@common/utilities/overflow-tooltip';
import { DepositAccount, MoneyMovement } from 'services/flexbase/banking.model';
import {
  IntlPaymentInfo,
  TablePayment,
  generateRows,
} from '@utilities/payments-rows';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import { TableColumn } from 'react-data-table-component';
import DisplayingPayAmount from '../common/displaying-pay-amount';
import GenericError from '../../../../components/generic-error';
import { PiMagnifyingGlass, PiPaperPlaneTiltBold } from 'react-icons/pi';

const columns: TableColumn<TablePayment>[] = [
  {
    name: 'Date scheduled for',
    cell: (row) =>
      DateTime.fromFormat(row.scheduledFor || '', 'yyyy-MM-dd').toFormat('DD'),
    sortable: true,
    sortFunction: sortDate,
  },
  {
    name: 'Recipient',
    selector: (row) => row.recipient,
    sortable: true,
  },
  {
    name: 'Amount',
    selector: (row) => row.fxAmount ?? '',
    cell: (row) => DisplayingPayAmount({ payment: row }),
    sortable: true,
  },
  {
    name: 'Type',
    selector: (row) => row.type,
    sortable: true,
  },
  {
    name: 'Account',
    cell: (row) => <OverflowTooltip text={row.accountName} />,
    selector: (row) => row.accountName,
    sortable: true,
  },
];

const columnsSm: TableColumn<TablePayment>[] = [
  {
    name: 'Date scheduled for',
    cell: (row) =>
      DateTime.fromFormat(row.scheduledFor || '', 'yyyy-MM-dd').toFormat('DD'),
    sortable: true,
    compact: true,
  },
  {
    name: 'Recipient',
    selector: (row) => row.recipient,
    sortable: true,
    compact: true,
  },
  {
    name: 'Amount',
    selector: (row) => row.fxAmount ?? '',
    cell: (row) => DisplayingPayAmount({ payment: row }),
    sortable: true,
    compact: true,
  },
  {
    name: 'Type',
    selector: (row) => row.type,
    sortable: true,
    compact: true,
  },
  {
    name: 'Account',
    selector: (row) => row.accountName,
    sortable: true,
  },
];

type Props = {
  payments: MoneyMovement[];
  users: User[];
  isLoading: boolean;
  error?: Error;
  recipientName?: string;
  intlPayments: IntlPaymentInfo[];
  depositAccounts: DepositAccount[];
  handleRetry: () => void;
};

const ScheduledPaymentsTable = ({
  payments,
  isLoading,
  error,
  users,
  intlPayments,
  depositAccounts,
}: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const isAdmin = useRecoilValue(IsAdmin);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [searchTerm, setSearchTerm] = useState<string>();

  const handleSearchChange = (val: string) => setSearchTerm(val.toLowerCase());

  // generate table rows from payments, deposit accounts, and users filtered by the search term
  const rows = generateRows({
    users,
    payments,
    intlPayments,
    depositAccounts,
  }).filter((payment) => {
    if (!searchTerm) {
      return payment;
    } else {
      return (
        payment.amount.toLowerCase().includes(searchTerm) ||
        payment.recipient?.toLowerCase().includes(searchTerm) ||
        payment.accountName?.toLowerCase().includes(searchTerm) ||
        payment?.scheduledFor?.toLowerCase().includes(searchTerm) ||
        payment.type.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    }
  });

  if (error) {
    return (
      <GenericError>
        <p>{error?.message}</p>
        <Button
          variant="primary-light"
          onClick={() => navigate('/payments/scheduled')}
          mt={20}
        >
          Please try again
        </Button>
      </GenericError>
    );
  }

  // fetch an open payment if one exists from the URL param
  const openPayment = rows.find((row) => row.id === id);

  const scheduledRows = rows
    .filter((row) => row.status === 'Scheduled')
    .map((row) => ({
      ...row,
      amount: row.amount || 'pending',
    }));

  return (
    <>
      {openPayment && <PaymentDetailsModal openPayment={openPayment} />}
      <div className={classes.container}>
        <div className={classes.header}>
          <TextInput
            w={isMobile ? '100%' : rem(300)}
            placeholder="Search all fields"
            onChange={(e) => handleSearchChange(e.target.value)}
            leftSection={
              <PiMagnifyingGlass
                size={'1rem'}
                color={theme.colors.neutral[6]}
              />
            }
          />
          <Button
            variant="primary-filled"
            leftSection={<PiPaperPlaneTiltBold />}
            disabled={!isAdmin}
            onClick={() => {
              navigate('/payments/outgoing/recipient');
            }}
            data-testid={'send-payment'}
          >
            Send a payment
          </Button>
        </div>
        <FlexbaseTable
          columns={isMobile ? (columnsSm as any[]) : (columns as any[])}
          data={scheduledRows}
          pagination={scheduledRows && scheduledRows?.length > 8}
          onRowClicked={(row) => navigate(`${row.id}`)}
          isFetchingData={isLoading}
          noDataComponent={
            <Text fz={rem(24)} fw={500} mt="lg">
              No scheduled payments
            </Text>
          }
        />
      </div>
    </>
  );
};

export default ScheduledPaymentsTable;
