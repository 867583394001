import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  optionPaymentBox: {
    border: `1px solid ${theme.colors.neutral[2]}`,
    borderRadius: theme.defaultRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: rem(70),
    padding: rem(20),
  },
  enabled: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.neutral[3],
    },
  },
  disclaimer: {
    marginTop: theme.spacing.md,
    fontWeight: 400,
    fontSize: rem(12),
    color: theme.colors.primary[8],
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  optionText: {
    color: theme.primaryColor,
    fontSize: rem(16),
    fontWeight: 500,
  },
  optionInfoContainer: {
    color: theme.primaryColor,
    fontWeight: 400,
    display: 'flex',
    fontSize: rem(14),
  },
  optionSpeed: {
    color: theme.primaryColor,
    fontWeight: 400,
    fontSize: rem(12),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(10),
  },
  priceText: {
    fontSize: rem(16),
    fontWeight: 500,
    color: theme.colors.primary[1],
  },
  lightText: {
    color: theme.colors.neutral[0],
  },
  circle: {
    width: rem(24),
    height: rem(24),
    display: 'flex',
    borderRadius: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    color: theme.primaryColor,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
  recipientRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: rem(10),
    border: `1px solid ${theme.colors.neutral[2]}`,
    borderRadius: rem(2),
    cursor: 'pointer',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.colors.neutral[3],
    },
  },
  containerIconName: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    color: theme.colors.neutral[8],
    fontSize: rem(14),
    fontWeight: 400,
    marginLeft: rem(8),
  },
  selectList: {
    zIndex: 1,
    position: 'absolute',
    maxHeight: rem(250),
    width: rem(356),
    overflow: 'scroll',
    marginTop: rem(4),
    backgroundColor: theme.colors.neutral[0],
    boxShadow: theme.shadows.sm,
    borderRadius: theme.defaultRadius,
  },
  containerIcon: {
    borderRadius: rem(2),
    div: {
      backgroundColor: 'transparent',
    },
  },
  newAccount: {
    marginTop: rem(8),
    fontWeight: 400,
    fontSize: rem(14),
    lineHeight: rem(19),
    cursor: 'pointer',
    color: theme.colors.primary[2],
  },
}));
