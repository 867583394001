import { createStyles } from '@mantine/emotion';
import { useBillPayPayments } from './use-bill-pay';
import { Badge } from '@mantine/core';

const ActionRequiredBadge = () => {
  const { classes } = useStyles();
  const { data } = useBillPayPayments();

  const count = (data.payments ?? []).filter(
    (cc) =>
      cc.status === 'AwaitingApproval' || cc.status === 'AwaitingConfirmation',
  ).length;

  return (
    <Badge
      classNames={{
        label: classes.badgeInner,
      }}
      bg="primary.3"
    >
      {count}
    </Badge>
  );
};

export default ActionRequiredBadge;

const useStyles = createStyles((theme) => ({
  badgeInner: {
    color: `${theme.colors.neutral[0]} !important`,
  },
}));
