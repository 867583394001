import {
  Box,
  Button,
  ComboboxItem,
  Flex,
  rem,
  Skeleton,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useStyles } from './transaction-details.styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import { formatCurrency } from '@utilities/formatters';
import { showNotification } from '@mantine/notifications';
import filetype from 'magic-bytes.js';
import { useRecoilValue } from 'recoil';
import {
  IsAccountant,
  IsAdmin,
} from '../../../../../recoil-state/application/product-onboarding';
import { useDetailsStyles } from './details-styles';
import { formatDatetime } from 'utilities/formatters/format-datetime';
import { useCreateExpenseReceipt } from '@queries/use-create-receipt';
import { getPdfThumbnailUrlFromUrl } from '@utilities/pdf-thumbnail';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';
import {
  ExpensesStatusEnum,
  SpendPlanAdminViewUsageEnum,
  SpendPlanViewUsageEnum,
} from '@flexbase-eng/types/dist/accounting';
import FlexSelect from '@common/composites/flex-select';
import { useSyncedTransactionsQuery } from '@queries/use-integrations';
import { useForm } from '@mantine/form';
import { useSyncTransactionExpenses } from '@utilities/custom-hooks/use-sync-transaction-expenses';
import { SyncIcon } from 'assets/svg';
import {
  useActiveExpenseLink,
  useSyncExpenseAllowed,
} from '@utilities/integrations/accounting';
import {
  DetailsBody,
  DetailsFieldsRow,
  DetailsFieldsSection,
  DetailsHeader,
} from './components/details-components';
import { DropZone } from '../../../../../components/drop-zone';
import { DisputeTransaction } from './components/dispute-transaction';
import { useGetSpendPlans } from '@queries/use-spend-plans';
import { uselinkInvoiceToSpendPlan } from '@queries/use-credit-transactions';
import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import { usePlatformPersonContext } from 'providers/platform-person.context';
import { DateTime } from 'luxon';
import { LineOfCredit } from '@services/flexbase/credit.model';
import {
  usePatchBankingCardSwipe,
  useUploadCardSwipeDoc,
} from '@queries/use-card-swipes';
import { platformClient } from '@services/platform/platform-client';

type Props = {
  tableRow: CreditTransactionsTableRow;
  focusElement?: FocusElement;
  lineOfCredit: LineOfCredit;
  closeModal: () => void;
  onTransactionUpdate?: () => void;
};

export type FocusElement = 'receipt' | 'memo' | 'flag';

type DropDownItemProps = {
  handleSpendPlanSelect: (spendPlanId: string) => void;
  usage: string;
  value: string;
  label: string;
  remaining: number;
};

const DropDownSpendPlanItem = ({
  handleSpendPlanSelect,
  label,
  usage,
  value,
  remaining,
}: DropDownItemProps) => {
  const normalUsage = usage !== 'no funds remaining' && usage !== 'overspent';
  return (
    <Flex
      fz={rem(14)}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (normalUsage) {
          handleSpendPlanSelect(value);
        }
      }}
      justify="space-between"
      p={rem(8)}
    >
      <Text c={normalUsage ? 'neutral.10' : 'neutral.7'}>{label}</Text>
      <Flex align="center">
        {normalUsage ? (
          <Flex align="center">
            <Text> {formatCurrency(remaining)} </Text>
            <Text c="neutral.7" fz={rem(12)} ml={rem(5)}>
              Remaining
            </Text>
          </Flex>
        ) : (
          <Box bg="critical.0" p={rem(4)} style={{ borderRadius: rem(4) }}>
            <Text c="critical.6" size={'xs'}>
              {usage === 'no funds remaining'
                ? 'Not enough funds'
                : 'Overspent'}
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

// TODO: Figure out how to do functionality the ref props on Menu and Tooltip do since they were removed in mantine v5
const TransactionDetails = ({
  focusElement,
  closeModal,
  onTransactionUpdate,
  tableRow,
  lineOfCredit,
}: Props) => {
  const {
    id,
    status,
    purchaseDate,
    settledAt: settledDate,
    description,
    toFrom: storeName,
    name: employee,
    cardName: transactionCardName = '',
    storeId,
    storeCity,
    storeState,
    storePostalCode,
    docId,
    authInfo,
    spendName,
  } = tableRow;

  const theme = useMantineTheme();
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [memo, setMemo] = useState(description);
  const [cardName, setCardName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [loadingMemo, setLoadingMemo] = useState(false);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [receiptInfo, setReceiptInfo] = useState<{
    url: string;
    thumbnailUrl: string | null;
    extension: string | undefined;
  }>();
  const { mutateAsync: patchChargeCardSwipe } = usePatchBankingCardSwipe();
  const { mutateAsync: uploadCardSwipeDoc } = useUploadCardSwipeDoc();
  const [disputeOpen, setDisputeOpen] = useState(true); //TODO: Set to false by default when bringing "Flag Transaction" back
  const receiptRef = useRef<HTMLDivElement>(null);
  const memoRef = useRef<HTMLTextAreaElement>(null);
  const flagRef = useRef<HTMLDivElement>(null);
  const isAccountant = useRecoilValue(IsAccountant);

  const [actionButtonMenuOpen, setActionButtonMenuOpen] = useState(false);
  const { mutate: createExpenseReceipt } = useCreateExpenseReceipt();
  const isSyncExpenseAllowed = useSyncExpenseAllowed();
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();
  const spendPlansEnabled =
    useSpendPlansFeatureFlag() && lineOfCredit === 'lithic';

  const expenseCategorySelectItems = useMemo<ComboboxItem[]>(() => {
    return (
      expenseLink?.accounts?.map((category) => ({
        key: category.id,
        label: category.displayName ?? category.name,
        value: category.id,
      })) || []
    );
  }, [expenseLink?.accounts]);
  const { data: syncedExpenses, refetch: refetchSyncedExpenses } =
    useSyncedTransactionsQuery({
      connectionId,
      transactionIds: [id],
      updateCache: true,
    });
  const { syncTransactions } = useSyncTransactionExpenses({
    connectionId,
    syncedExpenses,
  });
  const syncedExpense = syncedExpenses?.expenses.find(
    (e) => e.transactionId === id,
  );

  const [waitingForSyncUpdate, setWaitingForSyncUpdate] = useState(false);

  useEffect(() => {
    setWaitingForSyncUpdate(false);
  }, [syncedExpense]);

  const expenseForm = useForm<{
    categoryId: string;
    status: ExpensesStatusEnum | undefined;
  }>({
    /**
     * tableRow.syncedExpense is preloaded and available during mount
     * Don't use the live syncedExpense for the initialValues or it will init to undefined
     */
    initialValues: {
      categoryId: tableRow.syncedExpense?.expense?.account?.id || '',
      status: tableRow.syncedExpense?.status,
    },
  });

  const notifyError = (error: string) => {
    showNotification({
      title: 'Oops',
      message: error,
      color: 'red',
    });
  };

  const notifySuccess = (msg: string) => {
    showNotification({
      title: 'Success!',
      message: msg,
      color: 'green',
    });
  };

  const { cx, classes } = useStyles({
    flagMenuHeight: disputeOpen ? '235px' : '115px',
  });
  const { classes: detailsClasses } = useDetailsStyles();

  const getLocationFromStoreId = async (givenId: string) => {
    try {
      const result = await flexbaseClient.getMerchant(givenId);
      if (result) {
        // if (result.category) {
        //   const words = result.category.split('_').join(' ');
        //   await setCategory(capitalizeOnlyFirstLetter(words));
        // } else {
        //   await setCategory('N/A');
        // }
        const parsedLocation = `${
          result.city && !result.city.match(/\d/) ? result.city + ', ' : ''
        }${result.state ? result.state + ', ' : ''}${
          result.country ? result.country + ' ' : ''
        }${result.postalCode ? result.postalCode : ''}`;
        setLocation(parsedLocation);
      }
    } catch (error) {
      console.error(error);
      setLocation('N/A');
    }
  };
  const addFile = async (files: File[]) => {
    const imageUri = URL.createObjectURL(files[0]);
    const extension = files[0].name.split('.').at(-1);

    let thumbnailUrl: string | null = imageUri;

    if (files[0].type.includes('pdf')) {
      thumbnailUrl = await getPdfThumbnailUrlFromUrl(imageUri);
    }

    setReceiptInfo({
      url: imageUri,
      thumbnailUrl,
      extension,
    });
    uploadReceiptFile(files[0]);
  };

  const getReceiptFile = async (doc: string) => {
    setLoadingReceipt(true);
    try {
      const result =
        lineOfCredit === 'unit'
          ? await platformClient.downloadPlatformDocument(doc)
          : await flexbaseClient.getDocumentImage(doc);
      if (result) {
        const blobURL = URL.createObjectURL(
          result instanceof Blob ? result : new Blob([result]),
        );
        const extension =
          result instanceof Blob
            ? result.type
            : filetype(new Uint8Array(result))[0].extension;

        let thumbnailUrl: string | null = blobURL;

        if (extension?.includes('pdf')) {
          thumbnailUrl = await getPdfThumbnailUrlFromUrl(blobURL);
        }

        setReceiptInfo({
          url: blobURL,
          thumbnailUrl,
          extension,
        });
      } else {
        setReceiptInfo(undefined);
      }
    } catch (error) {
      console.error(error);
      notifyError('Unable to display the invoice image');
    } finally {
      setLoadingReceipt(false);
    }
  };

  const uploadReceiptFile = async (file: File) => {
    try {
      setLoadingReceipt(true);

      const fileResult =
        lineOfCredit === 'lithic'
          ? await flexbaseClient.uploadInvoiceFile(id, file)
          : await uploadCardSwipeDoc({
              cardSwipeId: id,
              document: { file, type: 'receipt', tags: [] },
            });

      if (syncedExpense?.status === ExpensesStatusEnum.Synced) {
        createExpenseReceipt({
          connectionId,
          invoiceId: id,
          file,
        });
      }

      if (fileResult && onTransactionUpdate) {
        notifySuccess('Receipt uploaded');
        onTransactionUpdate();
      } else {
        notifyError('Unable to upload your receipt.');
      }
    } catch (error) {
      notifyError('An error occurred when uploading your receipt.');
      console.error('Error updating the invoice', error);
    } finally {
      setLoadingReceipt(false);
    }
  };

  const unlinkReceiptFile = async () => {
    try {
      setLoadingReceipt(true);
      const fileResult = await flexbaseClient.unlinkInvoiceFile(id);
      if (fileResult && onTransactionUpdate) {
        notifySuccess('Receipt deleted');
        onTransactionUpdate();
        setReceiptInfo(undefined);
      }
    } catch (error) {
      console.error('Error updating the invoice', error);
      notifyError(error);
    } finally {
      setLoadingReceipt(false);
    }
  };

  const updateMemo = async (text: string) => {
    try {
      setLoadingMemo(true);
      const updateResult =
        lineOfCredit === 'unit'
          ? await patchChargeCardSwipe({
              cardSwipeId: id,
              request: { memo: text },
            })
          : await flexbaseClient.updateInvoice(id, {
              description: text,
            } as any);
      if (updateResult && onTransactionUpdate) {
        notifySuccess('Memo added');
        onTransactionUpdate();
        setUpdatedDescription(text);
      } else {
        notifyError('Unable to update the memo.');
      }
    } catch (error) {
      console.error('Error updating the invoice', error);
      setMemo(updatedDescription);
    } finally {
      setLoadingMemo(false);
    }
  };

  const getDeclinedReason = () => {
    switch (authInfo?.reason) {
      case 'AMOUNT.negativePurchaseAmount':
        return 'Negative purchase amount';
      case 'MERCHANT.excludedMerchantCountry':
        return 'Restricted country';
      case 'MERCHANT.excludedMerchantCategory':
        return 'Restricted MCC group';
      case 'CARD.notActivated':
        return 'Card is not activated';
      case 'CARD.merchantCategoryNotAllowed':
        return 'Merchant category not allowed';
      case 'CARD.exceedDailyBankLimit':
        return 'Card declined. Please contact support.';
      case 'CARD.exceedMonthlyBankLimit':
        return 'Card declined. Please contact support.';
      case 'COMPANY.deactivated':
        return 'Company is deactivated';
      case 'COMPANY.frozen':
        return 'Company is frozen';
      case 'COMPANY.noCreditLimit':
        return 'Company has no credit limit';
      case 'CARD.exceedSpendingLimit':
        return 'Card spend limit exceeded';
      case 'COMPANY.availableCredit':
        return 'Insufficient credit available';
      case 'CARD.blindAuthorization':
        return 'Blind authorization failed';
      default:
        return 'Card declined. Please contact support.';
    }
  };

  const syncExpenseCategory = (categoryId: string) => {
    setWaitingForSyncUpdate(true);

    syncTransactions(
      [
        {
          transactionId: id,
          accountId: categoryId,
        },
      ],
      {
        successMessage: () => `Transaction updated`,
        partialSuccessMessage: () => `Transaction updated`,
      },
    )
      .then(() => refetchSyncedExpenses())
      .then(() => expenseForm.resetDirty());
  };

  const handleExpenseCategoryChange = (categoryId: string | null) => {
    if (!categoryId) {
      return;
    }

    expenseForm.setValues({ categoryId });

    syncExpenseCategory(categoryId);
  };

  useEffect(() => {
    if (docId) {
      getReceiptFile(docId);
    }
    if (transactionCardName) {
      setCardName(transactionCardName);
    } else {
      setCardName('N/A');
    }
    if (storeId) {
      getLocationFromStoreId(storeId);
    } else {
      setLocation(`${
        storeCity && !storeCity.match(/\d/) ? storeCity + ', ' : ''
      }${storeState ? storeState + ', ' : ''}${'USA' + ' '}
      ${storePostalCode ? storePostalCode : ''}`);
    }
  }, []);

  useEffect(() => {
    switch (focusElement) {
      case 'receipt':
        receiptRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'memo':
        memoRef?.current?.scrollIntoView({ behavior: 'smooth' });
        memoRef?.current?.focus();
        break;
      case 'flag':
        flagRef?.current?.scrollIntoView({ behavior: 'smooth' });
        setActionButtonMenuOpen(true);
        break;
      default:
        break;
    }
  }, [flagRef, receiptRef, memoRef, focusElement]);

  const { person } = usePlatformPersonContext();
  const isAdmin = useRecoilValue(IsAdmin);
  const accountId = person.accountId;
  const { data: spendPlans } = useGetSpendPlans({
    accountId,
    isAdmin,
    enabled: spendPlansEnabled,
  });
  const { mutate } = uselinkInvoiceToSpendPlan();

  const initialSpendPlan = useMemo(() => {
    return spendPlans?.find((item) => item.name === spendName);
  }, [spendName]);

  const [spendPlan, setSpendPlan] = useState<string>(
    initialSpendPlan?.id || '',
  );

  const handleSpendPlanSelect = (spendPlanId: string | null) => {
    if (!spendPlanId) return;
    setSpendPlan(spendPlanId);
    mutate(
      {
        accountId: accountId,
        invoiceId: id,
        spendPlanId: spendPlanId,
      },
      {
        onSuccess: () => {
          showNotification({
            color: 'sage.4',
            message: 'Spend plan successfully linked',
          });
        },
        onError: () => {
          showNotification({
            color: 'red',
            message: 'Unable to link Spend plan',
          });
        },
      },
    );
  };

  const spendPlansDropdownFormat = useMemo(() => {
    const purchaseDateParsed = DateTime.fromFormat(
      purchaseDate || '',
      'yyyy-MM-dd',
    );

    if (spendPlans) {
      return spendPlans
        ?.map((item) => {
          const formattedCreatedAt = DateTime.fromISO(item.createdAt);
          return {
            value: item.id,
            label: item.name,
            remaining: item.remaining,
            usage: item.usage,
            createdAt: formattedCreatedAt,
          };
        })
        .filter((item) => purchaseDateParsed > item.createdAt);
    } else {
      return [];
    }
  }, [spendPlans]);

  return (
    <div className={detailsClasses.baseContainer}>
      <DetailsHeader tableRow={tableRow} onCloseClick={closeModal} />

      <DetailsBody>
        <DetailsFieldsSection>
          <DetailsFieldsRow>
            <div>
              <Text className={detailsClasses.infoTitle}>Cardholder</Text>
              <Text className={detailsClasses.infoValue}>{employee}</Text>
            </div>
            <div>
              <Text className={detailsClasses.infoTitle}>Card name</Text>
              {cardName ? (
                <Text className={detailsClasses.infoValue}>{cardName}</Text>
              ) : (
                <Skeleton
                  className={detailsClasses.infoValue}
                  height={20}
                  width={100}
                  radius="xl"
                />
              )}
            </div>
          </DetailsFieldsRow>

          <DetailsFieldsRow>
            <div className={detailsClasses.infoCluster}>
              <Text className={detailsClasses.infoTitle}>Merchant name</Text>
              <Text className={detailsClasses.infoValue}>{storeName}</Text>
            </div>
            {purchaseDate && (
              <div className={detailsClasses.infoCluster}>
                <Text className={detailsClasses.infoTitle}>
                  Transaction Date
                </Text>
                <Text className={detailsClasses.infoValue}>
                  {formatDatetime(purchaseDate)}
                </Text>
              </div>
            )}
          </DetailsFieldsRow>

          <DetailsFieldsRow>
            <div className={detailsClasses.infoCluster}>
              <Text className={detailsClasses.infoTitle}>Location</Text>
              {location ? (
                <Text className={detailsClasses.infoValue}>{location}</Text>
              ) : (
                <Skeleton
                  className={detailsClasses.infoValue}
                  height={20}
                  width={200}
                  radius="xl"
                />
              )}
            </div>
            {settledDate && (
              <div className={detailsClasses.infoCluster}>
                <Text className={detailsClasses.infoTitle}>Settled Date</Text>
                <Text className={detailsClasses.infoValue}>
                  {formatDatetime(settledDate)}
                </Text>
              </div>
            )}
          </DetailsFieldsRow>
          {spendPlansEnabled && (
            <div>
              {expenseForm.values.categoryId && spendName ? (
                <div className={detailsClasses.infoCluster}>
                  <Text className={detailsClasses.infoTitle}>Spend plan</Text>
                  <Text className={detailsClasses.infoValue}>{spendName}</Text>
                </div>
              ) : (
                <FlexSelect
                  label="Spend plan"
                  data={spendPlansDropdownFormat}
                  value={spendPlan || ''}
                  searchable
                  onChange={handleSpendPlanSelect}
                  placeholder="Select a spend plan"
                  itemComponent={({ label, remaining, value, usage }) => {
                    return (
                      <DropDownSpendPlanItem
                        {...{
                          label,
                          remaining: remaining as number,
                          value,
                          usage: usage as
                            | SpendPlanAdminViewUsageEnum
                            | SpendPlanViewUsageEnum,
                        }}
                        handleSpendPlanSelect={handleSpendPlanSelect}
                      />
                    );
                  }}
                />
              )}
            </div>
          )}
          {status?.toLowerCase() === 'declined' && !authInfo?.authorize && (
            <DetailsFieldsRow>
              <div className={detailsClasses.infoCluster}>
                <Text className={detailsClasses.infoTitle}>
                  Declined reason
                </Text>
                <Text className={detailsClasses.infoValue}>
                  {getDeclinedReason()}
                </Text>
              </div>
            </DetailsFieldsRow>
          )}

          {!!expenseLink?.enabledExpenses &&
          isSyncExpenseAllowed &&
          tableRow.syncedExpense ? (
            <Stack>
              <Text color={theme.primaryColor}>Category</Text>

              <FlexSelect
                label={
                  <>
                    Account
                    {syncedExpense?.status === ExpensesStatusEnum.Queued ? (
                      <SyncIcon
                        fill="currentColor"
                        height={12}
                        width={12}
                        className={cx(classes.syncIcon, classes.spin)}
                      />
                    ) : null}
                  </>
                }
                data={expenseCategorySelectItems}
                searchable
                inputProps={{
                  disabled: waitingForSyncUpdate || syncedExpense?.lock,
                }}
                placeholder={`Choose ${
                  syncedExpenses?.accounts?.type || 'Accounts'
                }`}
                value={expenseForm.values.categoryId}
                onChange={handleExpenseCategoryChange}
              />
            </Stack>
          ) : null}
        </DetailsFieldsSection>
        <DropZone
          {...{
            addFile,
            disputeOpen,
            loadingReceipt,
            receiptInfo,
            unlinkReceiptFile,
            receiptRef,
            hideDelete: lineOfCredit === 'unit',
          }}
        />
        <div>
          <Box mb="xs">
            <Text className={detailsClasses.infoTitle}>Memo</Text>
          </Box>
          <Textarea
            size="6rem"
            className={classes.containerMemoInput}
            classNames={{ input: classes.memoInput }}
            minRows={5}
            value={memo}
            onChange={(event) => {
              setMemo(event.target.value);
            }}
            ref={memoRef}
            disabled={loadingMemo || isAccountant}
          />
          <div
            className={classes.memoButtons}
            style={{
              maxHeight: memo !== updatedDescription ? '90px' : 0,
            }}
          >
            <Button
              variant="neutral-outline"
              onClick={() => {
                setMemo(updatedDescription);
              }}
              disabled={loadingMemo}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateMemo(memo);
              }}
              disabled={loadingMemo}
              variant="primary-filled"
            >
              Save
            </Button>
          </div>
        </div>
        <div className={classes.actionButtons} ref={flagRef}>
          <DisputeTransaction
            {...{
              actionButtonMenuOpen,
              setActionButtonMenuOpen,
              setDisputeOpen,
              disputeOpen,
            }}
          />
        </div>
      </DetailsBody>
    </div>
  );
};

export default TransactionDetails;
