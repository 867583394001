import { Flex, Text } from '@mantine/core';
import { PiListDashesBold } from 'react-icons/pi';
import { CardByUser } from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import BillingAddress from './billing-address';
import DebitCard from './debit-card';
import { getATMSpendLimitByTier } from '../util';
import { closeAllModals } from '@mantine/modals';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';
import { RightContentModal } from '@common/composites/modal/right-content.modal';

type Props = {
  limit: number;
  card: CardByUser;
  limitType: string;
  cardStatus: string;
};

const TerminatedDebitCard = ({ card, limitType, cardStatus, limit }: Props) => {
  const { data } = useGetDepositAccounts();
  const depositAccounts = data?.accounts || [];
  const navigate = useNavigate();
  const cardDepositAccount = depositAccounts.find(
    (account) => account.id === card.depositAccountId,
  );
  const unitcoCardId = card.ucCardId.substring(5);
  const currentDailyWithdrawalLimit =
    card.expensesTypes?.dailyWithdrawal ??
    getATMSpendLimitByTier(depositAccounts, card.depositAccountId);

  const footerData = [
    {
      title: 'Account',
      value: cardDepositAccount?.nickName || cardDepositAccount?.name,
      isVisible: true,
    },
    {
      title: 'Card type',
      value: card.cardSubtype.includes('Virtual') ? 'Virtual' : 'Physical',
      isVisible: true,
    },
    {
      title: 'Daily ATM withdrawal limit',
      value: formatCurrency(currentDailyWithdrawalLimit),
      isVisible: !card.cardSubtype.includes('Virtual'),
    },
    {
      title:
        limitType !== 'unlimited'
          ? `${capitalizeOnlyFirstLetter(limitType)} spending limit`
          : 'Spending limit',
      value: limitType !== 'unlimited' ? formatCurrency(limit) : 'Unlimited',
      isVisible: true,
    },
  ];

  const handleViewTransactions = () => {
    closeAllModals();
    navigate({
      pathname: `/banking/transactions`,
      search: createSearchParams({ cardId: `${unitcoCardId}` }).toString(),
    });
  };

  return (
    <RightContentModal>
      <RightContentModal.Header
        title={card.cardName || null}
        subtitle={card.holder}
      />

      <RightContentModal.Body>
        <DebitCard {...{ card, cardStatus }} />

        <Flex
          gap="xs"
          justify="end"
          align="center"
          sx={{ cursor: 'pointer' }}
          onClick={handleViewTransactions}
        >
          <PiListDashesBold size="1.2rem" />
          <Text>Transactions</Text>
        </Flex>

        {card.address && (
          <>
            <RightContentModal.Divider />
            <BillingAddress card={card} />
          </>
        )}

        <RightContentModal.Divider />
        {footerData.map(
          ({ title, value, isVisible }) =>
            isVisible && (
              <Flex mb="sm" align="center" justify="space-between" key={title}>
                <Text fz="sm">{title}</Text>
                <Text fz="sm">{value}</Text>
              </Flex>
            ),
        )}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default TerminatedDebitCard;
