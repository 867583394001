import FlagIcon from '../../../areas/payments/components/send-payment/flag-icon';
import {
  Combobox,
  Group,
  rem,
  Stack,
  Text,
  TextInput,
  TextInputProps,
  UnstyledButton,
  useCombobox,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useClickOutside } from '@mantine/hooks';
import {
  AsYouType,
  formatIncompletePhoneNumber,
  getCountries,
  getCountryCallingCode,
  Metadata,
} from 'libphonenumber-js/min';
import {
  ChangeEventHandler,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import {
  NumberFormatBase,
  NumberFormatBaseProps,
  NumberFormatValues,
} from 'react-number-format';
import { Country } from 'react-phone-number-input';
import localeEn from 'react-phone-number-input/locale/en.json';
import { supportedCountryCodes } from './supported-country-codes';

/**
 * Contains the metadata for every country's phone number rules.
 */
const phoneNumberMetadata = new Metadata();

/**
 * Gets the max possible phone number length for the given country.
 */
const maxNumberLengthForCountry = (country: Country) => {
  phoneNumberMetadata.selectNumberingPlan(country);

  // If for some reason this list is empty, return undefined
  return phoneNumberMetadata.numberingPlan
    ?.possibleLengths()
    .reduce<number | undefined>((max = 0, cur) => {
      return Math.max(cur, max);
    }, undefined);
};

const useDropdownBlur = (handler: () => any) => {
  const [targetNode, setTargetNode] = useState<HTMLElement | null>(null);
  const [dropdownNode, setDropdownNode] = useState<HTMLElement | null>(null);

  useClickOutside(handler, null, [dropdownNode, targetNode]);

  return {
    targetRef: setTargetNode,
    dropdownRef: setDropdownNode,
  };
};

/**
 * Width of the US flag icon and country code.
 */
const DEFAULT_LEFT_WIDTH = 64;

const SELECT_ITEM_HEIGHT = 32;

const RECENT_COUNTRIES_MAX = 3;

// hack because this isn't exposed for some reason
type CountryCallingCode = ReturnType<typeof getCountryCallingCode>;

type CountryPhoneData = {
  /**
   * The label to display in the dropdown.
   */
  label: string;

  /**
   * A normalized label used for filtering.
   *
   * @example
   * 'Åland Islands' becomes 'aland islands'
   */
  normalizedLabel: string;

  /**
   * The flag icon associated with this country code.
   */
  icon: JSX.Element;

  /**
   * The country code.
   *
   * @example
   * 'US'
   */
  countryCode: Country;

  /**
   * The country calling code.
   *
   * @example
   * '1'
   */
  countryCallingCode: CountryCallingCode;
};

/**
 * Static list of all countries and their associated phone data (countryCode, countryCallingCode, label, normalizedLabel, icon).
 */
const CountryPhoneList: CountryPhoneData[] = getCountries()
  .filter((countryCode) => (countryCode as string) in supportedCountryCodes)
  .map((countryCode) => {
    return {
      label: localeEn[countryCode],
      // normalize so things like 'Åland Islands' can be searched by 'aland islands'
      normalizedLabel: localeEn[countryCode]
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase(),
      icon: <FlagIcon flagNationCode={countryCode} />,
      countryCode,
      countryCallingCode: getCountryCallingCode(countryCode),
    };
  });
CountryPhoneList.sort((a, b) => a.label.localeCompare(b.label));

type CountryOptionProps = {
  item: CountryPhoneData;
};

const CountryOption = ({ item }: CountryOptionProps) => {
  const { classes } = useStyles();

  return (
    <Combobox.Option
      value={item.countryCode}
      className={classes.comboboxOption}
    >
      <Group className={classes.itemGroup} px={8} py={4}>
        <Stack className={classes.itemStaticColumn}>{item.icon}</Stack>

        <Text fz="sm" className={classes.itemLabel}>
          {item.label}
        </Text>

        <Text fz="sm" className={classes.itemStaticColumn}>
          +{item.countryCallingCode}
        </Text>
      </Group>
    </Combobox.Option>
  );
};

type LeftSectionProps = PropsWithChildren<{
  countryCallingCode: CountryCallingCode;
}>;

/**
 * This is a wrapper for the left side flag button where we also render the calling code prefix.
 */
export const LeftSection = forwardRef<HTMLDivElement, LeftSectionProps>(
  function LeftSection({ children, countryCallingCode }, ref) {
    return (
      <Group
        ref={ref}
        justify="apart"
        gap="xxs"
        p="xs"
        pr={countryCallingCode ? 0 : undefined}
        wrap="nowrap"
      >
        {children}

        {!!countryCallingCode && (
          <Text fz="sm" c="neutral.6" lh="normal">
            +{countryCallingCode}
          </Text>
        )}
      </Group>
    );
  },
);

type FlagButtonProps = {
  selectedCountry: Country;
  opened: boolean;
  onClick: () => void;
};

export const FlagButton = forwardRef<HTMLButtonElement, FlagButtonProps>(
  function FlagButton({ selectedCountry, opened, onClick }, ref) {
    const { classes } = useFlagButtonStyles({ dropdownOpen: opened });
    const handleClick = () => onClick();

    return (
      <UnstyledButton
        ref={ref}
        p={4}
        onClick={handleClick}
        className={classes.flagButton}
      >
        <FlagIcon flagNationCode={selectedCountry} />

        <FiChevronDown
          style={{
            height: 12,
            width: 12,
            marginLeft: 4,
          }}
        />
      </UnstyledButton>
    );
  },
);

/**
 * Have to wrap this in a forwardRef because Combobox.DropdownTarget requires a ref attribute, so we have to map it to the getInputRef attribute.
 */
const NumberFormatBaseWithRef = forwardRef<
  HTMLInputElement,
  NumberFormatBaseProps<TextInputProps>
>(function NumberFormatBaseWithRef(props, ref) {
  return <NumberFormatBase {...props} getInputRef={ref} />;
});

type FlexIntlPhoneInputProps = Omit<
  NumberFormatBaseProps<TextInputProps>,
  | 'format'
  | 'leftSection'
  | 'leftSectionPointerEvents'
  | 'leftSectionProps'
  | 'leftSectionWidth'
  | 'onValueChange'
> & {
  countryCode?: Country;
  onCountryCodeChange?: (countryCode: Country) => void;
  onValueChange?: (e: {
    /**
     * The selected country's calling code.
     *
     * @example
     * '1'
     */
    countryCallingCode: CountryCallingCode;

    /**
     * The raw text value.
     *
     * @example
     * '4045555555'
     */
    value: string;

    /**
     * The value formatted according to the country code.
     *
     * @example
     * '(404) 555-5555'
     */
    formattedValue: string;

    /**
     * The value in E.164 format.
     *
     * @example
     * '+14045555555'
     */
    e164Value: string;
  }) => void;
};

/**
 * An input that automatically formats phone numbers based on an international country code.
 *
 * @example
 * <FlexIntlPhoneInput
 *   {...form.getInputProps('phone')}
 *   countryCode={form.values.countryCode}
 *   onCountryCodeChange={(code) => handleCountryCodeChange(code)}
 *   onValueChange={(e) => handleValueChange(
 *     e.countryCallingCode,
 *     e.value,
 *     e.formattedValue,
 *     e.e164Value,
 *   )}
 * />
 */
export const FlexIntlPhoneInput = ({
  onValueChange,
  onCountryCodeChange,
  countryCode,
  ...props
}: FlexIntlPhoneInputProps) => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedCountryUncontrolled, setSelectedCountryUncontrolled] =
    useState<Country>(countryCode ?? 'US');
  const [mostRecent, setMostRecent] = useState<CountryPhoneData[]>([]);
  const [leftSectionWidth, setLeftSectionWidth] = useState(DEFAULT_LEFT_WIDTH);
  const inputRef = useRef<HTMLInputElement>(null);
  const { dropdownRef, targetRef } = useDropdownBlur(() => setOpened(false));
  const leftSectionRef = useRef<HTMLDivElement | null>(null);
  const combobox = useCombobox({
    defaultOpened: false,
    loop: true,
    opened,
  });

  const normalizedSearchValue = searchValue.toLowerCase().trim();
  const selectedCountry = countryCode ?? selectedCountryUncontrolled;
  const countryCallingCode = getCountryCallingCode(selectedCountry);

  const handleFormat = useCallback(
    (value: string) => {
      const formatter = new AsYouType(selectedCountry);
      formatter.input(value);

      const asNational = formatter.getNumber()?.nationalNumber || value;
      const maxLength = maxNumberLengthForCountry(selectedCountry);
      const trimmed = maxLength ? asNational.slice(0, maxLength) : asNational;

      return formatIncompletePhoneNumber(trimmed, selectedCountry);
    },
    [selectedCountry],
  );

  const handleFlagButtonClick = () => {
    setOpened((x) => !x);
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const handleComboOpen = () => {
    combobox.focusSearchInput();
  };

  const handleComboClose = () => {
    combobox.resetSelectedOption();
  };

  const handleCountrySelected = (val: string) => {
    setSelectedCountryUncontrolled(val as Country);
    setOpened(false);
    setSearchValue('');
    inputRef.current?.focus();
    onCountryCodeChange?.(val as Country);
    combobox.updateSelectedOptionIndex();

    setMostRecent((prev) => {
      const countryData = CountryPhoneList.find((c) => c.countryCode === val);
      const next = prev.filter((c) => c.countryCode !== val);

      if (countryData) {
        next.unshift(countryData);
      }

      return next.slice(0, RECENT_COUNTRIES_MAX);
    });
  };

  const handleValueChange = (e: NumberFormatValues) => {
    const formatter = new AsYouType(selectedCountry);
    formatter.input(e.value);

    onValueChange?.({
      countryCallingCode,
      value: e.value,
      formattedValue: e.formattedValue,
      e164Value: formatter.getNumberValue() ?? '',
    });
  };

  // Recalc the width of the leftSection element whenever the callingCode length changes.
  // Using useLayoutEffect instead of useEffect to avoid render flickering, performance shouldn't be too bad...
  useLayoutEffect(() => {
    const gap = 6; // gap between leftSection and input
    const width = leftSectionRef.current?.offsetWidth ?? DEFAULT_LEFT_WIDTH;

    setLeftSectionWidth(width + gap);
  }, [countryCallingCode.length]);

  const options = useMemo(() => {
    combobox.updateSelectedOptionIndex();

    return CountryPhoneList.filter((item) => {
      const matchSearch =
        !normalizedSearchValue ||
        item.normalizedLabel.includes(normalizedSearchValue);
      const notRecent = !mostRecent.some(
        (c) => c.countryCode === item.countryCode,
      );

      return matchSearch && notRecent;
    }).map((item) => <CountryOption key={item.countryCode} item={item} />);
  }, [normalizedSearchValue, mostRecent]);

  const recentOptions = useMemo(() => {
    return mostRecent
      .filter((item) => {
        const matchSearch =
          !normalizedSearchValue ||
          item.normalizedLabel.includes(normalizedSearchValue);

        return matchSearch;
      })
      .map((item) => <CountryOption key={item.countryCode} item={item} />);
  }, [normalizedSearchValue, mostRecent]);

  return (
    <Combobox
      store={combobox}
      onOpen={handleComboOpen}
      onClose={handleComboClose}
      onOptionSubmit={handleCountrySelected}
    >
      <Combobox.DropdownTarget>
        <NumberFormatBaseWithRef
          {...props}
          ref={inputRef}
          format={handleFormat}
          onValueChange={handleValueChange}
          customInput={TextInput}
          leftSection={
            <Combobox.EventsTarget targetType="button">
              <LeftSection
                ref={leftSectionRef}
                countryCallingCode={countryCallingCode}
              >
                <FlagButton
                  ref={targetRef}
                  opened={combobox.dropdownOpened}
                  onClick={handleFlagButtonClick}
                  selectedCountry={selectedCountry}
                />
              </LeftSection>
            </Combobox.EventsTarget>
          }
          leftSectionWidth={leftSectionWidth}
        />
      </Combobox.DropdownTarget>

      <Combobox.Dropdown p="xs" ref={dropdownRef}>
        <Combobox.Search
          value={searchValue}
          placeholder="Search for a country"
          leftSection={<PiMagnifyingGlassBold />}
          onChange={handleSearchChange}
          className={classes.searchBox}
        />

        <Combobox.Options className={classes.comboboxOptions}>
          {recentOptions.length ? (
            <>
              {recentOptions}
              <Combobox.Group label="All countries">{options}</Combobox.Group>
            </>
          ) : options.length ? (
            options
          ) : (
            <Combobox.Empty>No countries found.</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

const useFlagButtonStyles = createStyles(
  (t, { dropdownOpen }: { dropdownOpen: boolean }) => ({
    flagButton: {
      borderRadius: 2,
      color: t.colors.neutral[9],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...(dropdownOpen && {
        backgroundColor: t.colors.neutral[1],
      }),
      ['&:hover']: {
        backgroundColor: t.colors.neutral[1],
      },
    },
  }),
);

const useStyles = createStyles((t) => ({
  searchBox: {
    marginBottom: rem(8),
    '& input': {
      width: '100%',
      margin: 'unset',
    },
  },
  comboboxOptions: {
    maxHeight: rem(150),
    overflowY: 'auto',
  },
  comboboxOption: {
    color: t.colors.neutral[9],
    height: SELECT_ITEM_HEIGHT,
    padding: 0,

    '&[data-combobox-selected]': {
      backgroundColor: t.colors.neutral[1],
    },
  },
  itemGroup: {
    alignItems: 'center',
    borderRadius: 4,
    flexWrap: 'nowrap',
    gap: 4,
    overflow: 'hidden',
  },
  itemLabel: {
    flexShrink: 1,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  itemStaticColumn: {
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'center',
  },
}));
