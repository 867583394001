import { RightContentModal } from '@common/composites/modal/right-content.modal';
import {
  Button,
  Checkbox,
  Group,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { useState } from 'react';
import {
  PiAirplaneTilt,
  PiBarbell,
  PiCallBell,
  PiCastleTurret,
  PiChampagne,
  PiCoffeeLight,
  PiForkKnife,
  PiPersonSimpleSki,
} from 'react-icons/pi';

const benefitFilters = [
  {
    filter: 'Featured',
    icon: PiChampagne,
  },
  {
    filter: 'Travel',
    icon: PiAirplaneTilt,
  },
  {
    filter: 'Lounges',
    icon: PiCoffeeLight,
  },
  {
    filter: 'Dining',
    icon: PiForkKnife,
  },
  {
    filter: 'Experiences',
    icon: PiPersonSimpleSki,
  },
  {
    filter: 'Concierge',
    icon: PiCallBell,
  },
  {
    filter: 'Wellness',
    icon: PiBarbell,
  },
  {
    filter: 'Protection',
    icon: PiCastleTurret,
  },
];

type FilterState = {
  rewardType: string;
  categories: string[];
};

const FilterDrawer = ({
  onClose,
  onApply,
  activeFilters,
}: {
  onClose: () => void;
  onApply: (filters: FilterState) => void;
  activeFilters: FilterState;
}) => {
  const [rewardType, setRewardType] = useState(activeFilters.rewardType);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    activeFilters.categories,
  );

  const segmentedControlData = [
    { label: 'All', value: 'all' },
    { label: 'Visa Infinite', value: 'visa-infinite' },
    { label: 'Visa Business', value: 'visa-business' },
  ];

  const handleApplyFilters = () => {
    const categories = selectedCategories.map((item) => item.toLowerCase());
    onApply({ rewardType, categories });
    onClose();
  };

  const checkboxCards = benefitFilters.map((item) => {
    const Icon = item.icon;
    return (
      <Checkbox.Card p={'md'} value={item.filter} key={item.filter}>
        <Group wrap="nowrap" align="flex-start">
          <Checkbox.Indicator />
          <Icon size={20} />
          <Text size="md">{item.filter}</Text>
        </Group>
      </Checkbox.Card>
    );
  });

  return (
    <RightContentModal>
      <RightContentModal.SingleTitleHeader title="Filters" />
      <RightContentModal.Body>
        <Stack gap="xl">
          <Stack gap="md">
            <Text fw={500}>Reward type</Text>
            <SegmentedControl
              value={rewardType}
              onChange={setRewardType}
              data={segmentedControlData}
              variant="secondary"
            />
          </Stack>

          <Stack gap="sm">
            <Text fw={500}>Benefit category</Text>
            <Checkbox.Group
              value={selectedCategories}
              onChange={setSelectedCategories}
            >
              <SimpleGrid cols={2}>{checkboxCards}</SimpleGrid>
            </Checkbox.Group>

            <Group justify="flex-end" mt="xl">
              <Button variant="primary-filled" onClick={handleApplyFilters}>
                Apply Filters
              </Button>
            </Group>
          </Stack>
        </Stack>
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default FilterDrawer;
