import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

type Props = {
  rotateArrow?: boolean;
};

export const useStyles = createStyles((theme, { rotateArrow }: Props) => ({
  sendToBox: {
    height: rem(64),
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.colors.neutral[4]}`,
    borderRadius: theme.defaultRadius,
    padding: `0 ${rem(12)}`,
    gap: `0 ${rem(12)}`,
    cursor: 'pointer',
    background: theme.colors.neutral[0],
  },
  error: {
    border: `1px solid ${theme.colors.critical[3]}`,
  },
  initials: {
    backgroundColor: theme.colors.primary[0],
    borderRadius: '100%',
    width: rem(40),
    height: rem(40),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionText: {
    color: theme.colors.neutral[10],
    fontSize: rem(16),
    fontWeight: 400,
    maxWidth: rem(300),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: rem(20),
    textOverflow: 'ellipsis',
    '@media(max-width: 400px)': {
      whiteSpace: 'normal',
    },
  },
  optionInfoContainer: {
    color: theme.colors.neutral[8],
    fontSize: rem(14),
    fontWeight: 400,
    lineHeight: rem(18),
  },
  arrowIcon: {
    marginLeft: 'auto',
    transition: '0.2s ease-out',
    transform: rotateArrow ? 'rotate(-180deg)' : 'rotate(0deg)',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.colors.neutral[3],
      '#icon': {
        backgroundColor: theme.colors.neutral[0],
      },
    },
  },
}));
