import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { usePlaidBankingComponent } from '@utilities/custom-hooks/plaid-banking-component';
import PlaidContext from 'providers/plaid-context';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { useState } from 'react';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import flexbaseClient from 'services/flexbase-client';
import { PlaidAccount } from '../../../types/move-funds.model';
import AccountsReconnectAlert from './accounts-reconnect-alert';
import ExternalAccountsContent from './external-accounts-content';
import PlaidLink from './plaid-link';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { PiCheckBold, PiPlusBold } from 'react-icons/pi';
import { PlaidToken } from 'types/plaid';

const getUniqueUnlinkedAccounts = (accts: PlaidAccount[]) => {
  const uniqueInstitutionId = new Set();

  const filteredAccounts = accts.filter((acct) => {
    if (acct.unlinked && !uniqueInstitutionId.has(acct.institutionId)) {
      uniqueInstitutionId.add(acct.institutionId);
      return true;
    }
    return false;
  });

  return filteredAccounts;
};

const ExternalAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState<PlaidToken[]>([]);
  const [openPlaid, setOpenPlaid] = useState(false);

  const {
    data: parsedPlaidCompanyAccounts,
    isLoading,
    refetch,
  } = useExternalAccounts();

  // on success of plaid link, make the counterparty link call to unitco
  const onSuccess = async () => {
    showNotification({
      color: 'sage.4',
      title: 'Plaid Link successful!',
      message: 'Plaid is now linked to fund your new Flex deposit account.',
    });
    refetch();
  };

  const onError = () => {
    showNotification({
      color: 'red',
      title: 'Error linking Plaid',
      message:
        'Plaid linking is unavailable at this time, please try again later!',
    });
  };

  const { open } = usePlaidBankingComponent({
    onSuccess,
    onError,
    setLoading,
  });

  const onSuccessPlaid: PlaidLinkOnSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    try {
      setOpenPlaid(false);
      setLoading(true);
      PlaidContext.clearPlaidContext();

      const result = await flexbaseClient.exchangePlaidPublicToken(
        publicToken,
        metadata,
      );

      // strip the processed token from the queue
      const [processedToken, ...nextTokens] = tokens;
      const bank = processedToken?.bankName || 'Bank';

      if (result.success) {
        showNotification({
          title: 'Success!',
          message: `${bank} linked.`,
          color: 'sage.4',
          icon: <PiCheckBold size={'1rem'} />,
        });
      } else {
        showNotification({
          title: 'Failure',
          message: `Unable to link ${bank}.`,
          color: 'red',
        });
      }

      // reset the link token queue until it's empty
      setTokens(nextTokens);
      refetch();
    } catch (error) {
      console.error('An error occurred:', error);
      showNotification({
        title: 'Error',
        message: 'An error occurred while processing the request.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return isLoading || loading ? (
    <SkeletonLoading />
  ) : (
    <>
      <AccountsReconnectAlert
        {...{
          accountsReqConn: getUniqueUnlinkedAccounts(
            parsedPlaidCompanyAccounts,
          ),
          setLoading,
          setOpenPlaid,
          setTokens,
        }}
      />
      <ExternalAccountsContent
        tableData={parsedPlaidCompanyAccounts}
        primaryButton={
          <Button
            variant="primary-filled"
            leftSection={<PiPlusBold size={'1rem'} />}
            onClick={() => open()}
          >
            Connect a bank account
          </Button>
        }
      >
        {openPlaid && (
          <PlaidLink token={tokens[0]?.linkToken} onSuccess={onSuccessPlaid} />
        )}
      </ExternalAccountsContent>
    </>
  );
};

export default ExternalAccounts;
